import { Box } from "@mui/material";
import { SBMainListGrid } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBMainListWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        ...SBMainListGrid,
      }}
    >
      {children as any}
    </Box>
  );
};

export default SBMainListWrapper;
