import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { reportsApi } from "../../../../api/apiTanStack";

export const useCreateBeachChairReports = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ["createBeachChairReports"],
    (data: {
      startDate: string;
      endDate: string;
      reportType: string;
      sortBy: string;
      createdArPeriod: boolean;
    }) => {
      const startDateString: string = format(
        new Date(data.startDate),
        "yyyy-MM-dd"
      );
      const endDateString: string = format(
        new Date(data.endDate),
        "yyyy-MM-dd"
      );
      return reportsApi.reportsControllerGenerateReportPDF(
        {
          startDate: startDateString,
          endDate: endDateString,
          reportType: data.reportType,
          sortBy: data.sortBy,
          createdAtPeriod: `${data.createdArPeriod}`,
        },
        { headers: { "x-custom-lang": "de" } }
      );
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["closingReports"]);
      },
    }
  );
};
