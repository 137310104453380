import { ICustomer } from "./../../models/customers/ICustomer";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "./rootReducer";
import {
  DeleteCustomerFailurePayload,
  DeleteCustomerRequestPayload,
  DeleteCustomerSuccessPayload,
  FetchCustomerFailurePayload,
  FetchCustomerRequestPayload,
  FetchCustomerSuccessPayload,
  FetchCustomersPageFailurePayload,
  FetchCustomersPageRequestPayload,
  FetchCustomersPageSuccessPayload,
  PushCustomerFailurePayload,
  PushCustomerRequestPayload,
  PushCustomerSuccessPayload,
  RouterRedirectCustomerPayload,
  SearchBookingCustomerFailurePayload,
  SearchBookingCustomerRequestPayload,
  SearchBookingCustomerSuccessPayload,
  SetFilterPayload,
} from "../actionPayload/customerPayload";
import { IFilterData } from "../../models/dataTable/IFilterData";

interface IPage {
  key: string;
  dataIds: number[];
  count: number;
}

interface ICustomerState {
  pending: boolean;
  error: string;
  draftFilterData: IFilterData[];
  appliedFilterData: IFilterData[];
  page: EntityState<IPage>;
  search: EntityState<IPage>;
}

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const searchAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const customerAdapter = createEntityAdapter<ICustomer>({
  selectId: (customer) => customer.id,
});

const INITIAL_STATE = customerAdapter.getInitialState<ICustomerState>({
  pending: false,
  error: "",
  appliedFilterData: [],
  draftFilterData: [],
  page: pageAdapter.getInitialState({}),
  search: searchAdapter.getInitialState({}),
})

const customerSlice = createSlice({
  name: "customer",
  initialState: INITIAL_STATE,
  reducers: {
    resetCustomerRequest(){
      return INITIAL_STATE;
    },
    fetchCustomerRequest(
      state,
      action: PayloadAction<FetchCustomerRequestPayload>
    ) {
      state.pending = true;
    },
    fetchCustomerSuccess(
      state,
      action: PayloadAction<FetchCustomerSuccessPayload>
    ) {
      state.pending = false;
      customerAdapter.upsertOne(state, action.payload.customer);
    },
    deleteCustomerFailure(
      state,
      action: PayloadAction<DeleteCustomerFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    setFilters(state, action: PayloadAction<SetFilterPayload>) {
      state.appliedFilterData = action.payload.appliedFilterData;
      state.draftFilterData = action.payload.pendingFilterData;
    },
    deleteCustomerRequest(
      state,
      action: PayloadAction<DeleteCustomerRequestPayload>
    ) {
      state.pending = true;
    },
    deleteCustomerSuccess(
      state,
      action: PayloadAction<DeleteCustomerSuccessPayload>
    ) {
      state.pending = false;
      pageAdapter.removeAll(state.page);
      customerAdapter.removeOne(state, action.payload.customerId);
    },
    fetchCustomerFailure(
      state,
      action: PayloadAction<FetchCustomerFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    fetchCustomersPageRequest(
      state,
      action: PayloadAction<FetchCustomersPageRequestPayload>
    ) {
      state.pending = true;
    },
    fetchCustomersPageSuccess(
      state,
      action: PayloadAction<FetchCustomersPageSuccessPayload>
    ) {
      state.pending = false;

      customerAdapter.upsertMany(state, action.payload.customers);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.filterPhrase ? 'filter:' + action.payload.filterPhrase + ':': ''}${
          action.payload.page}:${action.payload.itemsPerPage}${action.payload.orderBy ? ':order:' + action.payload.orderBy + ':' + action.payload.order : ''}`,
        dataIds: [...action.payload.customers.map((customer) => customer.id)],
        count: action.payload.count
      });
    },
    fetchCustomersPageFailure(
      state,
      action: PayloadAction<FetchCustomersPageFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    pushCustomerRequest(
      state,
      action: PayloadAction<PushCustomerRequestPayload>
    ) {
      state.pending = true;
    },
    pushCustomerSuccess(
      state,
      action: PayloadAction<PushCustomerSuccessPayload>
    ) {
      pageAdapter.removeAll(state.page);
      customerAdapter.upsertOne(state, action.payload.customer);
      state.pending = false;
    },
    pushCustomerFailure(
      state,
      action: PayloadAction<PushCustomerFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    searchBookingCustomerRequest(
      state,
      action: PayloadAction<SearchBookingCustomerRequestPayload>
    ) {
      state.pending = true;
    },
    searchBookingCustomerSuccess(
      state,
      action: PayloadAction<SearchBookingCustomerSuccessPayload>
    ) {
      state.pending = false;
      searchAdapter.upsertOne(state.search, {
        key: `sp:${action.payload.searchPhrase}`,
        dataIds: [...action.payload.customers.map((customer) => customer.id)],
        count: action.payload.customers.length
      });
      customerAdapter.upsertMany(state, action.payload.customers);
    },
    searchBookingCustomerFailure(
      state,
      action: PayloadAction<SearchBookingCustomerFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    routerRedirectCustomer(
      state,
      action: PayloadAction<RouterRedirectCustomerPayload>
    ) {},
    changeDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    }
  },
});

export const {
  selectAll: getAllCustomersPages,
  selectById: getCustomerPages,
} = pageAdapter.getSelectors<AppState>((state) => state.customers.page);

export const {
  selectAll: getAllCustomersSearch,
  selectById: getCustomerSearchById,
} = searchAdapter.getSelectors<AppState>((state) => state.customers.search);

export const {
  selectAll: getAllCustomers,
  selectById: getCustomerById,
  selectTotal: getCustomerTotalCount,
} = customerAdapter.getSelectors<AppState>((state) => state.customers);

export const {
  resetCustomerRequest,
  fetchCustomerRequest,
  fetchCustomerSuccess,
  fetchCustomerFailure,
  deleteCustomerFailure,
  deleteCustomerRequest,
  setFilters,
  deleteCustomerSuccess,
  fetchCustomersPageRequest,
  fetchCustomersPageSuccess,
  fetchCustomersPageFailure,
  pushCustomerRequest,
  pushCustomerSuccess,
  pushCustomerFailure,
  searchBookingCustomerRequest,
  searchBookingCustomerSuccess,
  searchBookingCustomerFailure,
  routerRedirectCustomer,
  changeDataRequest
} = customerSlice.actions;

export default customerSlice.reducer;
