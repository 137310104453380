import BeachChairListHeader from "../beachChairListHeader/beachChairListHeader";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import NotesIcon from "@mui/icons-material/Notes";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { EColor } from "../../../models/beachChairs/EColors";
import { Box, Tooltip } from "@mui/material";
import { useGetBeachChairsFilters } from "../hooks/useGetBeachChairsFilters";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import SBTextAreaDatePicker from "../../SBComponents/SBForms/SBTextAreaDatePicker";
import SBTextAreaStopSaleDates from "../../SBComponents/SBForms/SBTextAreaStopSaleDates";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import { SBButtonGridStyle, SBColor } from "../../SBComponents/styles/SBStyles";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBToggleButtonGroup from "../../SBComponents/SBForms/SBToggleButtonGroup";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBImageUploader from "../../SBComponents/SBForms/SBImageUploader";
import SBCheckboxGrid from "../../SBComponents/SBForms/SBCheckboxGrid";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import { useBeachChairBulkForm } from "./hooks/useBeachChairBulkForm";
import { Save } from "@mui/icons-material";
import { Control, FieldValues } from "react-hook-form";
import { useState } from "react";
import BulkSaveDialog from "../../dialog/BulkSaveDialog";
import { toast } from "react-toastify";
const type = "edit";
const isLoading = false;

const SBBulkCheckBoxLabel: React.FC<{
  children?: React.ReactNode;
  control: Control<FieldValues, any>;
  name: string;
}> = ({ children, control, name }) => {
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "50px auto" }}>
      <SBCheckBox
        control={control}
        name={name}
        label=""
        color={SBColor.orange}
      />
      {children}
    </Box>
  );
};

const BeachChairBulk: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const [bulkDialog, setBulkDialog] = useState(false);
  const { data: filters, isLoading: filterIsLoading } =
    useGetBeachChairsFilters();
  const {
    watch,
    control,
    setValue,
    getValues,
    onSubmit,
    fields,
    append,
    remove,
    update,
    imagesAppend,
    imagesRemove,
    imagesFields,
    count,
    errors,
  } = useBeachChairBulkForm();
  const selectedSectionId = watch("sectionId");

  return (
    <SBMainComponentGridWrapper>
      <BeachChairListHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBBulkCheckBoxLabel control={control} name="Check.buyingDate">
              <SBTextAreaDatePicker
                name="buyingDate"
                label={t("buyingDate")}
                control={control}
                type={type}
                icon={<CalendarTodayIcon />}
                isLoading={isLoading}
                error={
                  errors?.buyingDate && t((errors.buyingDate as any)?.message)
                }
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.sectionId">
              <SBSelect
                name="sectionId"
                label={t("section")}
                noOptionLabel={t("NO_SECTION")}
                control={control}
                type={type}
                icon={<ViewModuleIcon />}
                isLoading={isLoading || filterIsLoading}
                setValue={setValue}
                setValueId="rowId"
                additionalOptions={filters?.sections}
                defaultValue={0}
                error={
                  errors?.sectionId && t((errors.sectionId as any)?.message)
                }
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.rowId">
              <SBSelect
                name="rowId"
                label={
                  <Tooltip title={`${t("section")} ${t("isMandatory")}`}>
                    <Box
                      sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
                    >
                      {t("row")}
                      <Box color={SBColor.red}>*</Box>
                    </Box>
                  </Tooltip>
                }
                noOptionLabel={t("NO_ROW")}
                control={control}
                type={type}
                icon={<MenuOpenIcon />}
                isLoading={isLoading || filterIsLoading}
                additionalOptions={filters?.sections
                  .filter((section) => section.id === selectedSectionId)
                  .flatMap((section) => {
                    return section.rows
                      .slice()
                      .sort((r, o) => {
                        return r.value.localeCompare(o.value);
                      })
                      .flatMap((row) => {
                        return { id: row.id, value: row.value };
                      });
                  })}
                defaultValue={0}
                error={errors?.rowId && t((errors.rowId as any)?.message)}
              />
            </SBBulkCheckBoxLabel>
            <div hidden>
              <SBTextField
                name="geoJson"
                label={t("geoJson")}
                control={control}
                type={type}
                isLoading={isLoading}
                error={errors?.geoJson && t((errors.geoJson as any)?.message)}
              />
            </div>
            <SBBulkCheckBoxLabel control={control} name="Check.status">
              <SBSelect
                name="status"
                label={t("status")}
                noOptionLabel={t("NO_ROW")}
                control={control}
                type={type}
                icon={<AssignmentTurnedInIcon />}
                isLoading={isLoading || filterIsLoading}
                additionalOptions={[
                  { id: "ACTIVE", value: `${t(`states.ACTIVE`)}` },
                  { id: "INACTIVE", value: `${t(`states.INACTIVE`)}` },
                ]}
                defaultValue={"ACTIVE"}
                error={errors?.status && t((errors.status as any)?.message)}
              />
            </SBBulkCheckBoxLabel>
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBBulkCheckBoxLabel control={control} name="Check.model">
              <SBSelect
                name="model"
                label={t("model")}
                noOptionLabel={t("noModel")}
                control={control}
                type={type}
                icon={<BeachAccessIcon />}
                isLoading={isLoading || filterIsLoading}
                additionalOptions={filters?.models.flatMap((option) => {
                  return { id: option, value: `${t(`models.${option}`)}` };
                })}
                defaultValue={0}
                error={errors?.model && t((errors.model as any)?.message)}
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.attributes">
              <SBSelect
                name="attributes.color"
                label={t("color")}
                noOptionLabel={t("no_color")}
                control={control}
                type={type}
                icon={<ColorLensIcon />}
                isLoading={isLoading || filterIsLoading}
                additionalOptions={Object.keys(EColor).flatMap((option) => {
                  return { id: option, value: `${t(`colors.${option}`)}` };
                })}
                defaultValue={0}
                error={
                  errors?.attributes &&
                  (errors.attributes as any)?.color &&
                  t((errors.attributes as any)?.color?.message)
                }
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.description">
              <SBTextArea
                name="description"
                label={t("description")}
                control={control}
                type={type}
                icon={<DescriptionIcon />}
                isLoading={isLoading}
                error={
                  errors?.description && t((errors.description as any)?.message)
                }
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.internalNote">
              <SBTextArea
                name="internalNote"
                label={t("internalNote")}
                control={control}
                type={type}
                icon={<NotesIcon />}
                isLoading={isLoading}
                error={
                  errors?.internalNote &&
                  t((errors.internalNote as any)?.message)
                }
              />
            </SBBulkCheckBoxLabel>
            <SBBulkCheckBoxLabel control={control} name="Check.details">
              <Box>
                <SBToggleButtonGroup
                  optionFirstName="details.extraInformation.de"
                  optionSecondName="details.extraInformation.en"
                  label={t("extraInformation")}
                  control={control}
                  type={type}
                  icon={<EmailIcon />}
                />
              </Box>
            </SBBulkCheckBoxLabel>

            <SBBulkCheckBoxLabel control={control} name="Check.images">
              <Box>
                <SBImageUploader
                  label={t("destinations.images")}
                  imagesFields={imagesFields}
                  imagesAppend={imagesAppend}
                  imagesRemove={imagesRemove}
                  type={type}
                />
              </Box>
            </SBBulkCheckBoxLabel>

            {(type === "edit" || type === "new") && (
              <SBBulkCheckBoxLabel control={control} name="Check.stopSaleDates">
                <Box
                  sx={{
                    ...SBButtonGridStyle,
                  }}
                >
                  <SBLoadingButton
                    label={t("addNewStopSaleDate")}
                    type="blue"
                    icon={<AddIcon />}
                    onClick={() => {
                      append({
                        start: new Date().toISOString(),
                        end: new Date().toISOString(),
                      });
                    }}
                  />
                </Box>
              </SBBulkCheckBoxLabel>
            )}
            {fields.map((item, index) => (
              <SBTextAreaStopSaleDates
                label={`${t("stopSale")}`}
                item={item}
                index={index}
                remove={remove}
                update={update}
                icon={<DateRangeIcon />}
                type={type}
                key={`SBTextAreaStopSaleDates${index}`}
              />
            ))}
            <SBCheckboxGrid>
              <SBBulkCheckBoxLabel
                control={control}
                name="Check.afterHourBooking"
              >
                <SBCheckBox
                  name="afterHourBooking"
                  label={t("afterHourBooking")}
                  control={control}
                  type={type}
                  isLoading={isLoading}
                  error={
                    errors?.afterHourBooking &&
                    t((errors.afterHourBooking as any)?.message)
                  }
                />
              </SBBulkCheckBoxLabel>
              <SBBulkCheckBoxLabel control={control} name="Check.onlineBooking">
                <SBCheckBox
                  name="onlineBooking"
                  label={t("onlineBooking")}
                  control={control}
                  type={type}
                  isLoading={isLoading}
                  error={
                    errors?.onlineBooking &&
                    t((errors.onlineBooking as any)?.message)
                  }
                />
              </SBBulkCheckBoxLabel>
              <SBBulkCheckBoxLabel
                control={control}
                name="Check.seasonBeachChair"
              >
                <SBCheckBox
                  name="seasonBeachChair"
                  label={t("seasonBeachChair")}
                  control={control}
                  type={type}
                  isLoading={isLoading}
                  error={
                    errors?.seasonBeachChair &&
                    t((errors.seasonBeachChair as any)?.message)
                  }
                />
              </SBBulkCheckBoxLabel>
            </SBCheckboxGrid>
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        {bulkDialog ? (
          <BulkSaveDialog
            open={bulkDialog}
            onClose={() => {
              setBulkDialog(false);
            }}
            onClick={() => {
              onSubmit();
            }}
            count={count}
          />
        ) : null}
        <Box
          sx={{
            ...SBButtonGridStyle,
          }}
        >
          <SBLoadingButton
            onClick={() => {
              if (
                Object.keys(getValues("Check")).filter(
                  (prop) => getValues("Check")[prop]
                ).length > 0
              ) {
                setBulkDialog(true);
              } else {
                toast.info(t("bulkSelectInfo"));
              }
            }}
            label={t("save")}
            type="blue"
            icon={<Save />}
            isLoading={isLoading}
          />
        </Box>
      </form>
    </SBMainComponentGridWrapper>
  );
};

export default BeachChairBulk;
