import { useSearchBeachChairs } from "./useSearchBeachChairs";
import { useSearchCustomers } from "./useSearchCustomers";
import { BookingSearchItem, useSearchBookings } from "./useSearchBookings";
import {
  CustomerResponseDto,
  ExtendedBeachChairResponseDto,
} from "../../../api-client/generated";

interface UseSearch {
  customers: CustomerResponseDto[];
  isFetchingCustomers: boolean;
  customersById: CustomerResponseDto[];
  isFetchingCustomersById: boolean;
  beachChairs: ExtendedBeachChairResponseDto[];
  isFetchingBeachChairs: boolean;
  beachChairsById: ExtendedBeachChairResponseDto[];
  isFetchingBeachChairsById: boolean;
  bookings: BookingSearchItem[];
  isFetchingBookings: boolean;
}

export interface SearchVariables {
  itemsPerPage: number;
  search: string;
}

export const useSearch = (searchPhrase: string): UseSearch => {
  // Search Results
  const searchVariables: SearchVariables = {
    itemsPerPage: 25,
    search: searchPhrase,
  };

  // Search for customers
  const { customers, isFetching: isFetchingCustomers } = useSearchCustomers(
    searchVariables
  );
  const {
    customers: customersById,
    isFetching: isFetchingCustomersById,
  } = useSearchCustomers(searchVariables, true);

  // Search for beach chairs
  const {
    beachChairs,
    isFetching: isFetchingBeachChairs,
  } = useSearchBeachChairs(searchVariables);
  const {
    beachChairs: beachChairsById,
    isFetching: isFetchingBeachChairsById,
  } = useSearchBeachChairs(searchVariables, true);

  // Search for bookings
  const { bookings, isFetching: isFetchingBookings } = useSearchBookings(
    searchVariables.search
  );

  return {
    customers,
    isFetchingCustomers,
    customersById,
    isFetchingCustomersById,
    beachChairs,
    isFetchingBeachChairs,
    beachChairsById,
    isFetchingBeachChairsById,
    bookings,
    isFetchingBookings,
  };
};
