import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { LocationResponseDto } from "../../../api-client/generated";
import { locationApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";

interface UseGetLocation {
  location?: LocationResponseDto;
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<LocationResponseDto, AxiosError>;
}

export const useGetLocation = (locationId?: string | number): UseGetLocation => {
  const { data: location, isLoading, isFetching, isFetched, refetch } = useQuery<
    LocationResponseDto,
    AxiosError
  >(
    ["vendor", Number(locationId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return locationApi
        .locationsControllerFindOne({ id: Number(locationId) ?? 0 })
        .then(({ data }) => data);
    },
    {
      enabled: !!Number(locationId),
    }
  );

  return {
    location,
    isLoading,
    isFetching,
    isFetched,
    refetch
  };
};
