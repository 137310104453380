import { Box, Card, IconButton } from "@mui/material";
import {
  FieldValues,
  UseFieldArrayAppend,
  UseFieldArrayRemove,
} from "react-hook-form";
import {
  SBImageCardIconButtonStyle,
  SBImageCardStyle,
  SBImageGridEditStyle,
  SBImageGridShowStyle,
  SBImageGridStyle,
} from "../styles/SBStyles";
import SBLabel from "./SBLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageUploader from "./SBImageUpload";

export interface SBTextFieldPrimary {
  imagesFields: Record<"id", string>[];
  imagesAppend: UseFieldArrayAppend<FieldValues, "images">;
  imagesRemove: UseFieldArrayRemove;
  label: React.ReactNode;
  type?: string;
}

export type SBTextFieldType = SBTextFieldPrimary;

const SBImageUploader: React.FC<SBTextFieldType> = ({
  imagesFields,
  imagesAppend,
  imagesRemove,
  label,
  type,
}) => {
  return (
    <>
      {(type === "edit" || imagesFields.length > 0) && (
        <SBLabel label={label} />
      )}
      <Box
        sx={{
          ...SBImageGridStyle,
          ...(type === "show" ? SBImageGridShowStyle : SBImageGridEditStyle),
        }}
      >
        {imagesFields.map((item, index) => (
          <Card
            key={item.id}
            sx={{
              ...SBImageCardStyle,
            }}
          >
            <IconButton
              sx={{
                ...SBImageCardIconButtonStyle,
              }}
              aria-label="delete"
              onClick={() => imagesRemove(index)}
              disabled={type === "show"}
            >
              <DeleteIcon
                sx={{
                  height: "30px",
                  width: "30px",
                }}
              />
            </IconButton>
            <img
              className="imageSquare"
              alt={`${(item as any).value}`}
              src={`${(item as any).value}`}
            />
          </Card>
        ))}
        {(type === "edit" || type === "new") && (
          <ImageUploader
            disabled={false}
            append={imagesAppend}
            width="250px"
            height="250px"
          />
        )}
      </Box>
    </>
  );
};

export default SBImageUploader;
