import React from "react";
import {
  MaterialReactTable,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { useColumnVisibility } from "../../utils/customHooks/useColumnVisibility";
import { SBColor } from "../SBComponents/styles/SBStyles";

interface ITableProps {
  //DATA TO DISPLAY
  data?: any;

  //COLUMN DEFINITION
  columns: any;

  //DATA FETCHING CONTROLLERS PART
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;

  //CONTROL STATES TABLE PART
  columnFilters?: MRT_ColumnFiltersState;
  setColumnFilters: React.Dispatch<
    React.SetStateAction<MRT_ColumnFiltersState>
  >;
  globalFilter?: any;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  pagination?: MRT_PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
  sorting?: MRT_SortingState;
  setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
  columnVisibilityStoreName: string;

  //CHILDREN PART
  renderRowActions: (cell: any) => JSX.Element; //TABLE BUTTONS
  renderTopToolbarCustomActions: () => JSX.Element; //REFRESH
  renderDetailPanel?: (cell: any) => JSX.Element; //DETAIL
}

const SBTable: React.FC<ITableProps> = ({
  data,
  columns,
  isLoading,
  isError,
  columnFilters,
  setColumnFilters,
  globalFilter,
  setGlobalFilter,
  pagination,
  setPagination,
  sorting,
  setSorting,
  columnVisibilityStoreName,
  renderRowActions,
  renderTopToolbarCustomActions,
  renderDetailPanel,
}) => {
  const { columnVisibility, setColumnVisibility } = useColumnVisibility(
    columnVisibilityStoreName
  );
  return (
    <MaterialReactTable
      columns={columns}
      data={data?.items ?? []}
      initialState={{
        showColumnFilters: false,
        density: "compact",
      }}
      displayColumnDefOptions={{
        "mrt-row-actions": { maxSize: 8, minSize: 8 },
        "mrt-row-expand": { maxSize: 1, minSize: 1 },
      }}
      muiTableProps={{
        sx: {
          tableLayout: "initial",
          overflow: "auto",
        },
      }}
      muiTablePaperProps={{
        sx: {
          width: {
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "calc(90vw - 60px)",
          },
          overflow: "auto",
        },
      }}
      muiPaginationProps={{
        sx: {
          overflow: "auto",
          "&::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: SBColor.scroll,
          },
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: "calc(100vh - 380px)",
          minHeight: "calc(100vh - 380px)",
          "&::-webkit-scrollbar": {
            width: "5px!important",
            height: "5px!important",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: SBColor.scroll,
            borderRadius: "5px",
          },
        },
      }}
      muiDetailPanelProps={{ sx: { padding: 0 } }}
      enableRowActions
      renderRowActions={renderRowActions}
      positionActionsColumn="last"
      positionExpandColumn="last"
      enableStickyHeader
      manualFiltering
      manualPagination
      manualSorting
      onColumnVisibilityChange={setColumnVisibility}
      positionToolbarAlertBanner="none"
      onColumnFiltersChange={setColumnFilters}
      onGlobalFilterChange={setGlobalFilter}
      onPaginationChange={setPagination}
      onSortingChange={setSorting}
      renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      renderDetailPanel={renderDetailPanel}
      rowCount={data?.count ?? 0}
      localization={{
        ...MRT_Localization_DE,
        ...{ toggleDensity: "Zeilendichte ändern" },
      }}
      state={{
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        showAlertBanner: isError,
        showProgressBars: false,
        sorting,
        columnVisibility,
      }}
    />
  );
};

export default SBTable;
