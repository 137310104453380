import axios from "axios";
import { apiConfig } from "../utils/cfg/config";
import {
  FeaturesApi,
  LocationApi,
  PublicApi,
  BeachApi,
  RegionApi,
  CityApi,
  SectionApi,
  VendorApi,
  PricesApi,
  BookingsApi,
  PaymentsApi,
  BeachChairsApi,
  Configuration,
  GeneratorApi,
  GeneratorsApi,
  UsersApi,
  CustomerApi,
  CashPointApi,
  ExtrasApi,
  ReportsApi,
  LocksApi,
  BillingApi,
  VendorStatsApi,
} from "../api-client/generated";

const { publicUrl, apiPrefix } = apiConfig;
const baseUrl = `${
  apiPrefix?.includes("http") ? "" : publicUrl
}${apiPrefix?.replace("/api/v1", "")}`;
const config = new Configuration();
const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    appVersion: "Backoffice",
  },
});

const axiosRefreshInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const locationApi = new LocationApi(config, `${baseUrl}`, axiosInstance);
const featuresApi = new FeaturesApi(config, `${baseUrl}`, axiosInstance);
const billingsApi = new BillingApi(config, `${baseUrl}`, axiosInstance);
const cityApi = new CityApi(config, `${baseUrl}`, axiosInstance);
const regionApi = new RegionApi(config, `${baseUrl}`, axiosInstance);
const beachApi = new BeachApi(config, `${baseUrl}`, axiosInstance);
const sectionApi = new SectionApi(config, `${baseUrl}`, axiosInstance);
const publicApi = new PublicApi(config, `${baseUrl}`, axiosInstance);
const vendorApi = new VendorApi(config, `${baseUrl}`, axiosInstance);
const pricesApi = new PricesApi(config, `${baseUrl}`, axiosInstance);
const bookingsApi = new BookingsApi(config, `${baseUrl}`, axiosInstance);
const beachChairApi = new BeachChairsApi(config, `${baseUrl}`, axiosInstance);
const paymentApi = new PaymentsApi(config, `${baseUrl}`, axiosInstance);
const generatorApi = new GeneratorApi(config, `${baseUrl}`, axiosInstance);
const generatorsApi = new GeneratorsApi(config, `${baseUrl}`, axiosInstance);
const usersApi = new UsersApi(config, `${baseUrl}`, axiosInstance);
const customerApi = new CustomerApi(config, `${baseUrl}`, axiosInstance);
const cashPointApi = new CashPointApi(config, `${baseUrl}`, axiosInstance);
const locksApi = new LocksApi(config, baseUrl, axiosInstance);
const extrasApi = new ExtrasApi(config, `${baseUrl}`, axiosInstance);
const reportsApi = new ReportsApi(config, `${baseUrl}`, axiosInstance);
const vendorStatisticsApi = new VendorStatsApi(
  config,
  `${baseUrl}`,
  axiosInstance
);

export {
  axiosInstance,
  axiosRefreshInstance,
  billingsApi,
  locationApi,
  featuresApi,
  publicApi,
  regionApi,
  cityApi,
  beachApi,
  sectionApi,
  vendorApi,
  pricesApi,
  bookingsApi,
  beachChairApi,
  paymentApi,
  generatorApi,
  generatorsApi,
  usersApi,
  customerApi,
  cashPointApi,
  extrasApi,
  reportsApi,
  locksApi,
  vendorStatisticsApi,
};
