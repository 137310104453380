import React, { useCallback, useState } from "react";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import PricesHeader from "./pricesHeader";
import ReactJson from "react-json-view";
import { Card, InputLabel } from "@mui/material";
import { SBLabelStyle } from "../SBComponents/styles/SBStyles";
import { useTranslation } from "react-i18next";
import SBHeaderTypography from "../SBComponents/SBLayout/SBHeaderTypography";
import SBSelect from "../SBComponents/SBForms/SBSelect";
import { CalendarToday, People } from "@mui/icons-material";
import { useGetAdminVendors } from "../vendor";
import SBTextAreaDatePicker from "../SBComponents/SBForms/SBTextAreaDatePicker";
import { usePriceForm } from "./hooks/usePriceForm";
import SBButtonActionGrid from "../SBComponents/SBForms/SBButtonActionGrid";
import { useHistory, useParams } from "react-router";
import DeleteDialog from "../dialog/DeleteDialog";
import { useDeletePrice } from "./hooks/useDeletePrice";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

const Prices = () => {
  const queryClient = useQueryClient();
  const { id, type } = useParams<{ id: string; type: string }>();
  const deletePrice = useDeletePrice();
  const { isLoading, control, setValue, watch, onReset, onSubmit, errors } =
    usePriceForm(+id, type);
  const { data: vendors, isLoading: filterIsLoading } = useGetAdminVendors(
    [],
    "",
    {
      pageIndex: 0,
      pageSize: 0,
    },
    []
  );
  const { t } = useTranslation(["common"]);
  const handleChange = async ({ updated_src: newContent }) => {
    setValue("rules", newContent);
  };
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const jsonData = watch("rules");

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deletePrice
        .mutateAsync(
          { id: id },
          {
            onSuccess: (_) => {
              toast.success(t(`toastMessages.deleteSuccess`));
              queryClient.refetchQueries(["getPrices"]);
            },
            onError: (e) => {
              toast.error(t(`toastMessages.deleteFailure`));
            },
          }
        )
        .finally(() => {
          setDialog(false);
          history.push("/prices");
        });
    },
    [history, deletePrice]
  );

  return (
    <SBMainComponentGridWrapper>
      <PricesHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="vendorId"
              label={t("vendor")}
              noOptionLabel={t("NO_VENDOR")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={vendors?.items.flatMap((vendor) => {
                return { id: vendor.id, value: vendor.name };
              })}
              defaultValue={0}
            />
            <SBTextAreaDatePicker
              name="bookingStart"
              label={t("bookingStart")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={isLoading}
              error={
                errors?.bookingStart && t((errors.bookingStart as any)?.message)
              }
            />
            <SBTextAreaDatePicker
              name="bookingEnd"
              label={t("bookingEnd")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={isLoading}
              error={
                errors?.bookingEnd && t((errors.bookingEnd as any)?.message)
              }
            />
            <SBTextAreaDatePicker
              name="offerStart"
              label={t("OfferStart")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={isLoading}
              error={
                errors?.offerStart && t((errors.offerStart as any)?.message)
              }
            />
            <SBTextAreaDatePicker
              name="offerEnd"
              label={t("OfferEnd")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={isLoading}
              error={errors?.offerEnd && t((errors.offerEnd as any)?.message)}
            />
            <InputLabel sx={{ ...SBLabelStyle, marginLeft: "30px" }}>
              {t("editor")}
            </InputLabel>
            <Card>
              <ReactJson
                displayObjectSize={false}
                displayDataTypes={false}
                shouldCollapse={false}
                collapsed={false}
                enableClipboard
                style={{ width: "100%", height: "100%" }}
                onEdit={handleChange}
                onAdd={handleChange}
                onDelete={handleChange}
                src={jsonData}
              />
            </Card>
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBHeaderTypography label={t("preview")} />
            <Card>
              <pre>{JSON.stringify(jsonData, null, 2)}</pre>
            </Card>
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/prices/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/prices/${id}/show`)}
          type={type}
          isLoading={isLoading}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="prices"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Prices;
