import React from "react";
import { Footer } from "../index";
import { Search } from "../../search";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { IAuxProps } from "../../../models/common/IAuxProps";
import { useMenuZustand } from "../Navigation/hooks/useMenuZustand";
import { Box } from "@mui/material";

export const MainWrapper = ({ children }: IAuxProps) => {
  const { open } = useMenuZustand();

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: "70px auto 70px",
        width: open ? "calc(100% - 230px)" : "calc(100% - 70px)",
        height: "100vh !important",
        maxHeight: "100vh !important",
        minHeight: "100vh !important",
      }}
    >
      <Search />
      <Box
        sx={{
          backgroundColor: customStyle.contentColor,
        }}
      >
        {children as any}
      </Box>
      <Footer />
    </Box>
  );
};
