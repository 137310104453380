import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useDispatch } from "react-redux";
import { BookingDetailsResponseDto } from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";
import { removeRows } from "../../../../store/reducers/availabilityReducer";
import { removePage } from "../../../../store/reducers/bookingsReducer";
import { ReactQueryRefetch } from "../../../../types";

interface UseGetBooking {
  booking?: BookingDetailsResponseDto;
  isLoading: boolean;
  isFetched: boolean;
  isFetching: boolean;
  refetch: ReactQueryRefetch<BookingDetailsResponseDto, AxiosError>;
}

export const useGetBooking = (bookingId: number): UseGetBooking => {
  const dispatch = useDispatch();
  const {
    data: booking,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  } = useQuery<BookingDetailsResponseDto, AxiosError>(
    ["booking", Number(bookingId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return featuresApi
        .bookingDetailsControllerFindOne({ id: String(bookingId) ?? "" })
        .then(({ data }) => data);
    },
    {
      enabled: !!bookingId,
      onSuccess: () => {
        dispatch(removePage());
        dispatch(removeRows());
      },
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  return {
    booking,
    isLoading,
    isFetched,
    isFetching,
    refetch,
  };
};
