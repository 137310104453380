import { IBeachChair } from "./../../models/beachChairs/IBeachChair";
import { HideSearchPayload } from "./../actionPayload/searchPayloads";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SearchFailurePayload,
  SearchRequestPayload,
  SearchSuccessPayload,
  ShowSearchPayload,
} from "../actionPayload/searchPayloads";
import { ICustomer } from "../../models/customers/ICustomer";

interface ISearchState {
  searchPhrase: string;
  searchedCustomers: ICustomer[];
  searchedBookings: any[];
  searchedBeachChairs: IBeachChair[];
  showSearch: boolean;
  error: string;
}

const INITIAL_STATE: ISearchState = {
  searchPhrase: "",
  searchedCustomers: [],
  searchedBookings: [],
  searchedBeachChairs: [],
  showSearch: false,
  error: "",
};

const searchSlice = createSlice({
  name: "search",
  initialState: INITIAL_STATE,
  reducers: {
    resetSearchRequest() {
      return INITIAL_STATE;
    },
    searchRequest(state, action: PayloadAction<SearchRequestPayload>) {
      state.searchPhrase = action.payload.searchPhrase;
    },
    searchSuccess(state, action: PayloadAction<SearchSuccessPayload>) {
      state.searchedBeachChairs = action.payload.searchedBeachChairs;
      state.searchedBookings = action.payload.searchedBookings;
      state.searchedCustomers = action.payload.searchedCustomers;
      state.showSearch = true;
    },
    searchFailure(state, action: PayloadAction<SearchFailurePayload>) {
      state.error = action.payload.error;
      state.showSearch = false;
    },
    showSearch(state, action: PayloadAction<ShowSearchPayload>) {
      state.showSearch = true;
    },
    hideSearch(state, action: PayloadAction<HideSearchPayload>) {
      state.showSearch = false;
    },
  },
});

export const {
  resetSearchRequest,
  searchRequest,
  searchSuccess,
  searchFailure,
  showSearch,
  hideSearch,
} = searchSlice.actions;

export default searchSlice.reducer;
