const isPresent = (check?: string) => {
  let isPresent = false;
  if (check && check !== "") {
    isPresent = true;
  }
  return isPresent;
};

export const customerText = (customer?: any) => {
  let customerText = customer?.email || "";
  if (
    customer &&
    isPresent(customer?.firstName) &&
    !isPresent(customer?.lastName)
  ) {
    customerText = `${customer?.firstName}`;
  }
  if (
    customer &&
    !isPresent(customer?.firstName) &&
    isPresent(customer?.lastName)
  ) {
    customerText = `${customer?.lastName}`;
  }
  if (
    customer &&
    isPresent(customer?.firstName) &&
    isPresent(customer?.lastName)
  ) {
    customerText = `${customer?.firstName} ${customer?.lastName}`;
  }
  return customerText;
};
