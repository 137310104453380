import React from "react";
import { useHistory, useParams } from "react-router";
import { setGlobalBookingId } from "../../../../store/reducers/globalBookingReducer";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { LocalOffer, Edit, Delete, Repeat } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";
import { useDispatch } from "react-redux";
import RightDrawer from "../../../rightDrawer/rightDrawer";
import {
  useDeleteBookingBeachChair,
  useUpdateBeachChairBooking,
} from "../hooks";
import { SelectedBookingBeachChair } from "./selectedBookingBeachChair";
import { useTranslation } from "react-i18next";
import ChangeDialog from "../../../dialog/ChangeDialog";
import ReturnDialog from "../../../dialog/ReturnDialog";
import { useReturnBeachChairMutation } from "./useReturnBeachChairMutation";
import { Box, IconButton, Link, Tooltip } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { bookingsDateToProperFormatDayjs } from "../../../../utils/conversions/dataConversion";
import { useAddBeachChairToBooking } from "../../../availabilities/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { SBColor } from "../../../SBComponents/styles/SBStyles";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import {
  BookingDetailsResponseDto,
  BookingStatus,
} from "../../../../api-client/generated";
import SBLabel from "../../../SBComponents/SBForms/SBLabel";
import dayjs from "dayjs";
import { MRT_Localization_DE } from "material-react-table/locales/de";
import { useLocationData } from "../../../../utils/customHooks/useLocationData";
import SelectedAvailableBeachChairExtend from "../../../availabilities/selectedAvailableBeachChair/selectedAvailableBeachChairExtend";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { useSelectedBeachChairDates } from "../../store/useSelectedBeachChairDates";
import { setBookingDateTime } from "../../../../utils/conversions/locationsTimes";

export const BookingBeachChairs: React.FC<{
  booking?: BookingDetailsResponseDto;
}> = ({ booking }) => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const [dialog, setDialog] = React.useState(false);
  const [returnDialog, setReturnDialog] = React.useState(false);
  const returnBeachChairMutation = useReturnBeachChairMutation();
  const { addBeachChairToBooking } = useAddBeachChairToBooking();
  const queryClient = useQueryClient();
  const [selectedBeachChairId, setSelectedBeachChairId] = React.useState<
    number | undefined
  >(undefined);
  const [selectedAction, setSelectedAction] = React.useState<"edit" | "show">();
  const [rightDrawerState, setRightDrawerState] = React.useState(false);
  const [
    rightDrawerStateSelectedAvailableBeachChair,
    setRightDrawerStateSelectedAvailableBeachChair,
  ] = React.useState(false);
  const { setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const deleteBookingBeachChair = useDeleteBookingBeachChair();
  const { updateBeachChairBooking } = useUpdateBeachChairBooking();
  const { locationData } = useLocationData(booking?.locationId);
  const handleOnEditClick = React.useCallback((beachChairId: number) => {
    setSelectedBeachChairId(beachChairId);
    setSelectedAction("edit");
    setRightDrawerState(true);
  }, []);

  const handleOnDeleteClick = React.useCallback(
    (beachChairBookingId: number) => {
      deleteBookingBeachChair.mutate(
        { id: Number(id), beachChairBookingId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["booking"]);
          },
        }
      );
    },
    [deleteBookingBeachChair, id]
  );

  const handleOnChangeClick = React.useCallback(
    (id: number) => {
      setSelectedBeachChairId(id);
      setDialog(true);
    },
    [setDialog, setSelectedBeachChairId]
  );

  const handleOnReturnBeachChairClick = React.useCallback((id: number) => {
    setSelectedBeachChairId(id);
    setReturnDialog(true);
  }, []);

  const handleChangeAfterModalConfirm = React.useCallback(
    (
      previousBeachChairId?: number,
      beachChairId?: number,
      start?: string,
      end?: string,
      price?: number,
      hasStarted?: boolean
    ) => {
      if (hasStarted) {
        if (beachChairId && previousBeachChairId && start && end) {
          returnBeachChairMutation
            .mutateAsync({ id: previousBeachChairId, end: start })
            .finally(() => {
              const beachChairBookingPayload = {
                beachChairId,
                price: 0,
                start,
                end,
                discount: false,
              };
              addBeachChairToBooking(
                {
                  bookingId: Number(id),
                  isExtended: true,
                  ...beachChairBookingPayload,
                },
                {
                  onSuccess: () => {
                    queryClient.refetchQueries(["booking", Number(id)]);
                    toast.success(
                      t(`toastMessages.ChangeBookingBeachChairsSuccess`)
                    );
                  },
                  onError: () => {
                    toast.error(
                      t(`toastMessages.ChangeBookingBeachChairsSuccess`)
                    );
                  },
                }
              );
            })
            .catch((_) => {});
        }
      } else {
        if (beachChairId && previousBeachChairId && price !== undefined) {
          updateBeachChairBooking(
            {
              id: previousBeachChairId,
              data: { beachChairId, price },
            },
            {
              onSuccess: () => {
                toast.success(
                  t(`toastMessages.ChangeBookingBeachChairsSuccess`)
                );
                queryClient.invalidateQueries(["booking"]);
              },
              onError: () => {
                toast.error(t(`toastMessages.ChangeBookingBeachChairsSuccess`));
              },
            }
          );
        }
      }
    },
    // eslint-disable-next-line
    [dispatch, t, returnBeachChairMutation, addBeachChairToBooking, id]
  );

  const onAddBeachChairClick = () => {
    dispatch(setGlobalBookingId({ id: Number(id) }));
    history.push("/availabilities/box");
  };

  const renderDetailPanel = React.useCallback(() => {
    const beachChairBookings = booking?.beachChairBookings ?? [];

    const renderRowActions = (cell: any) => {
      const beachChairId = beachChairBookings[cell.row.index].id;
      const id = beachChairBookings[cell.row.index].beachChair?.id;
      const status = booking?.status;
      return (
        <Box
          sx={{
            float: "left",
            display: "flex",
            justifyContent: "end",
          }}
        >
          {(status === "PENDING" || status === "OPEN") &&
            !disabledRef.current && (
              <Tooltip title={t("delete")}>
                <IconButton
                  sx={{ color: "#000000" }}
                  onClick={() => {
                    if (beachChairId) {
                      handleOnDeleteClick(beachChairId);
                    }
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
          {(status === "CONFIRMED" ||
            status === "PENDING" ||
            status === "CANCELED" ||
            status === "EXPIRED" ||
            status === "OPEN" ||
            status === "RESERVED") && (
            <Tooltip title={t("editTooltip")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (beachChairId) {
                    const start = dayjs(
                      beachChairBookings[cell.row.index].start
                    );
                    const end = dayjs(beachChairBookings[cell.row.index].end);
                    setBookingStartDateTime(start);
                    setBookingEndDateTime(end);
                    handleOnEditClick(beachChairId);
                  }
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
          )}
          {(status === "CONFIRMED" ||
            status === "CANCELED" ||
            status === "EXPIRED" ||
            status === "RESERVED") && (
            <Tooltip title={t("keyTooltip")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (beachChairId) {
                    handleOnReturnBeachChairClick(beachChairId);
                  }
                }}
              >
                <KeyIcon />
              </IconButton>
            </Tooltip>
          )}
          {(status === "PENDING" ||
            status === "CONFIRMED" ||
            status === "EXPIRED" ||
            status === "CANCELED" ||
            status === "RESERVED") && (
            <Tooltip title={t("repeatTooltip")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (beachChairId) {
                    handleOnChangeClick(beachChairId);
                  }
                }}
              >
                <Repeat />
              </IconButton>
            </Tooltip>
          )}
          {status === "CONFIRMED" && (
            <Tooltip title={t("moreTimeTooltip")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (id) {
                    const start = dayjs(
                      beachChairBookings[cell.row.index].end
                    ).add(1, "day");
                    const end = dayjs(
                      beachChairBookings[cell.row.index].end
                    ).add(1, "day");
                    const { bookingStartDateTime } = setBookingDateTime(
                      [start, end],
                      locationData,
                      beachChairBookings[cell.row.index].beachChair
                        ?.afterHourBooking
                    );
                    setBookingStartDateTime(
                      bookingStartDateTime || start.startOf("day")
                    );
                    setBookingEndDateTime(end);
                    setSelectedBeachChairId(id);
                    setRightDrawerStateSelectedAvailableBeachChair(true);
                  }
                }}
              >
                <MoreTimeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    };

    const columns: any[] = [
      {
        accessorKey: "publicNumber",
        header: `${t("publicNumberLabel")}`,
        accessorFn: (originalRow: any) => {
          return {
            publicNumber: originalRow.publicNumber,
            id: originalRow.beachChair.id,
          };
        },
        maxSize: 20,
        minSize: 10,
        Cell: (row: any) => (
          <Link
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            href={`/beach_chairs/${row.renderedCellValue.id}/show`}
          >
            {row.renderedCellValue.publicNumber}
          </Link>
        ),
      },
      {
        accessorKey: "model",
        header: `${t("model")}`,
        accessorFn: (originalRow: any) => `${t(`models.${originalRow.model}`)}`,
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "sectionId",
        header: `${t("section")}`,
        accessorFn: (originalRow: any) =>
          originalRow.beachChair?.section?.value,
        maxSize: 60,
        minSize: 20,
      },
      {
        accessorKey: "rowId",
        header: `${t("row")}`,
        accessorFn: (originalRow: any) => originalRow.beachChair?.row?.value,
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "start",
        header: `${t("pickup")}`,
        accessorFn: (originalRow: any) =>
          bookingsDateToProperFormatDayjs(originalRow.start),
        maxSize: 60,
        minSize: 10,
      },
      {
        accessorKey: "end",
        header: `${t("dropoff")}`,
        accessorFn: (originalRow: any) =>
          bookingsDateToProperFormatDayjs(originalRow.end),
        maxSize: 60,
        minSize: 10,
      },
      {
        accessorKey: "price",
        header: `${t("price")}`,
        accessorFn: (originalRow: any) =>
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(originalRow.price),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "days",
        header: `${t("daysLabel")}`,
        accessorFn: (originalRow: any) => {
          const date = dayjs(originalRow.end);
          const dayCount = date.diff(dayjs(originalRow.start), "day");
          return `${dayCount + 1} ${dayCount >= 1 ? t("days") : t("day")}`;
        },
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "discount",
        accessorFn: (originalRow: any) => originalRow.discount,
        header: `${t("discountLabel")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue && <LocalOffer />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
    ];

    return (
      <Box sx={{ height: "100%" }}>
        {beachChairBookings && beachChairBookings.length > 0 ? (
          <MaterialReactTable
            initialState={{
              showColumnFilters: false,
              density: "compact",
            }}
            muiTableProps={{
              sx: {
                tableLayout: "auto",
                overflow: "auto",
              },
            }}
            muiTablePaperProps={{
              sx: {
                width: {
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "calc(90vw - 60px)",
                },
                overflow: "auto",
              },
            }}
            muiPaginationProps={{
              sx: {
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: SBColor.scroll,
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: SBColor.scroll,
                },
              },
            }}
            data={beachChairBookings}
            columns={columns}
            enableEditing={true}
            positionActionsColumn="last"
            renderRowActions={renderRowActions}
            enableSorting={false}
            enableGlobalFilter={false}
            enableFilters={false}
            enableTopToolbar={false}
            enablePagination={false}
            enableHiding={false}
            enableBottomToolbar={false}
            enableTableFooter={false}
            enableColumnActions={false}
            localization={{
              ...MRT_Localization_DE,
            }}
          />
        ) : (
          <SBLabel label={t("filterNoOption")} />
        )}
      </Box>
    );
    // eslint-disable-next-line
  }, [t, booking, history]);

  const disabledRef = React.useRef(type === "show");

  return (
    <Box
      sx={{
        padding: "10px",
        display: "grid",
      }}
    >
      <Box>
        {rightDrawerStateSelectedAvailableBeachChair && (
          <RightDrawer
            state={rightDrawerStateSelectedAvailableBeachChair}
            setState={setRightDrawerStateSelectedAvailableBeachChair}
          >
            <SelectedAvailableBeachChairExtend
              id={selectedBeachChairId ?? 0}
              handleClose={() =>
                setRightDrawerStateSelectedAvailableBeachChair(false)
              }
              locationData={locationData}
              booking={booking}
            />
          </RightDrawer>
        )}
        {renderDetailPanel()}
        {returnDialog ? (
          <ReturnDialog
            onClose={(id) => {
              if (id) {
                const date = new Date();
                returnBeachChairMutation
                  .mutateAsync({ id: id, end: date.toISOString() })
                  .finally(() => {
                    setReturnDialog(false);
                  })
                  .catch((_) => {});
              } else {
                setReturnDialog(false);
              }
            }}
            open={returnDialog}
            selectedValue={selectedBeachChairId}
          />
        ) : null}
        {dialog ? (
          <ChangeDialog
            namespace="bookings"
            open={dialog}
            bookingId={Number(id)}
            selectedId={selectedBeachChairId}
            onClose={(
              previousBeachChairId,
              beachChairId,
              start,
              end,
              price,
              hasStarted
            ) => {
              setDialog(false);
              if (beachChairId) {
                handleChangeAfterModalConfirm(
                  previousBeachChairId,
                  beachChairId,
                  start,
                  end,
                  price,
                  hasStarted
                );
              }
            }}
          />
        ) : null}
        {rightDrawerState ? (
          <RightDrawer state={rightDrawerState} setState={setRightDrawerState}>
            {selectedBeachChairId && (
              <SelectedBookingBeachChair
                beachChairBookingId={selectedBeachChairId}
                viewType={selectedAction}
                onClose={() => {
                  setRightDrawerState(false);
                  setSelectedBeachChairId(undefined);
                }}
              />
            )}
          </RightDrawer>
        ) : null}
        {booking?.status === BookingStatus.Open && !disabledRef.current && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <SBLoadingButton
              label={t("bookBeachChair")}
              type="blue"
              icon={<AddIcon />}
              onClick={onAddBeachChairClick}
              isLoading={false}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
