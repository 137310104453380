import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import { useCallback, useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import SBTextArea from "./SBTextArea";

enum Language {
  EN = "en",
  DE = "de",
}
export interface SBToggleButtonGroupPrimary {
  optionFirstName: string;
  optionSecondName: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
}

export type SBToggleButtonGroupType = SBToggleButtonGroupPrimary;

const SBToggleButtonGroup: React.FC<SBToggleButtonGroupType> = ({
  optionFirstName,
  optionSecondName,
  type,
  icon,
  label,
  isLoading,
  control,
}) => {
  const [language, setLanguage] = useState<Language>(Language.DE);
  const handleLanguage = useCallback(
    (
      event: React.MouseEvent<HTMLElement>,
      newLanguage: Language | Language.DE
    ) => {
      if (newLanguage !== null) {
        setLanguage(newLanguage);
      }
    },
    []
  );
  const hide = useMemo(() => language.includes("de"), [language]);

  return (
    <>
      <ToggleButtonGroup
        sx={{ marginLeft: "50px" }}
        value={language}
        exclusive
        onChange={handleLanguage}
      >
        <ToggleButton value={Language.DE} aria-label="Deutsch">
          <ReactCountryFlag countryCode="DE" svg />
        </ToggleButton>
        <ToggleButton value={Language.EN} aria-label="Englisch">
          <ReactCountryFlag countryCode="US" svg />
        </ToggleButton>
      </ToggleButtonGroup>
      <SBTextArea
        name={optionFirstName}
        label={label}
        control={control}
        type={type}
        icon={icon}
        hidden={!hide}
        isLoading={isLoading}
      />
      <SBTextArea
        name={optionSecondName}
        label={label}
        control={control}
        type={type}
        icon={icon}
        hidden={hide}
        isLoading={isLoading}
      />
    </>
  );
};

export default SBToggleButtonGroup;
