import React from "react";
import { Box } from "@mui/material";
import { SBCheckboxGridStyle } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBCheckboxGrid: React.FC<Props> = ({ children }) => {
  return (
    <Box sx={{ ...SBCheckboxGridStyle }} data-testid="inside-card">
      {children}
    </Box>
  );
};

export default SBCheckboxGrid;
