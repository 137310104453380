import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ResponseUserDto } from "../api-client/generated";
import { usersApi } from "../api/apiTanStack";

interface UseGetCurrentUser {
  currentUser?: ResponseUserDto;
  hasRole: (roles: UserRoles | UserRoles[]) => boolean;
  userRoles: UserRoles[];
  isLoading: boolean;
}

export enum UserRoles {
  SUPER_ADMIN = "SUPER_ADMIN",
  VENDOR_SUPER_ADMIN = "VENDOR_SUPER_ADMIN",
  VENDOR_ADMIN = 'VENDOR_ADMIN',
  WEBSITE_USER = 'WEBSITE_USER'
}

export const useGetCurrentUser = (): UseGetCurrentUser => {
  const { data: currentUser, isLoading } = useQuery<
    ResponseUserDto,
    AxiosError
  >(
    ["current-user"],
    () => usersApi.usersControllerGetCurrentUser().then((data) => data.data),
    {
      cacheTime: Infinity,
      refetchOnMount: true,
    }
  );

  const hasRole = (roles: UserRoles | UserRoles[]) => {
    const userRoles = currentUser?.roles ?? [];

    if (Array.isArray(roles)) {
      return roles.some((role) => userRoles.includes(role));
    } else {
      return userRoles.includes(roles);
    }
  }

  return {
    currentUser,
    userRoles: (currentUser?.roles ?? []) as UserRoles[],
    hasRole,
    isLoading,
  };
};
