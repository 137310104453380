import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBFormControlTextAreaStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBTextFieldPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
  history?: boolean;
}

export type SBTextFieldType = SBTextFieldPrimary & SBFormHelperTextPrimary;

const SBTextArea: React.FC<SBTextFieldType> = ({
  type,
  icon,
  label,
  isLoading,
  name,
  control,
  hidden,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box
      sx={{
        ...SBTextFieldGridStyle,
        display: hidden ? "none" : "grid",
        maxHeight: history ? "500px" : "200px",
      }}
    >
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlTextAreaStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  hidden={hidden}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  label=""
                  sx={{
                    ...SBTextFieldStyle,

                    ...{
                      display: hidden ? "none" : "",
                    },
                    "& .MuiOutlinedInput-root": {
                      padding: "0",
                    },
                  }}
                  multiline
                  maxRows={4}
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBTextArea;
