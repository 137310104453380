import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { EDestination } from "../model/EDestination";
import AddIcon from "@mui/icons-material/Add";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";

export interface IConfigurationHeaderProps {
  type: EDestination;
}

const ConfigurationHeader = ({ type }: IConfigurationHeaderProps) => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const lowerCaseType = type.toLocaleLowerCase();

  return (
    <SubPageHeader headerTitle={t("adminConfiguration")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {!id && location.pathname.includes(lowerCaseType) ? (
          <SBHeaderTypography label={t(`configurationMenu.${lowerCaseType}`)} />
        ) : (
          <SBHeaderLink
            label={t(`configurationMenu.${lowerCaseType}`)}
            onClick={() => {
              history.push(`/${lowerCaseType}`);
            }}
          />
        )}
        {id && location.pathname.includes(lowerCaseType) && (
          <SBHeaderTypography label={t("overview")} />
        )}
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {location.pathname.includes(lowerCaseType) &&
          !location.pathname.includes("destination") && (
            <SBLoadingButton
              label={t(`destinations.new.${lowerCaseType}`)}
              type="blue"
              icon={<AddIcon />}
              onClick={() => {
                history.push(`/${lowerCaseType}/new`);
              }}
            />
          )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default ConfigurationHeader;
