import { useMutation } from "@tanstack/react-query";
import { FeaturesApiBookingDetailsControllerCancelBookingRequest as Variables } from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";

export function useRecoverBooking() {
  const { mutate: recoverBooking, isLoading } = useMutation({
    mutationFn: (variables: Variables) =>
      featuresApi.bookingDetailsControllerRecoverBooking(variables),
  });

  return {
    recoverBooking,
    isLoading,
  };
}
