import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../../SBTable/SBTable";
import { useHistory } from "react-router";
import { EDestination } from "../model/EDestination";
import ConfigurationHeader from "../header/configurationHeader";
import { useDeleteSection } from "./hooks/useDeleteSection";
import { useGetAdminSections } from "./hooks/useGetSections";
import Status from "../../status/status";
import { useGetVendors } from "../../vendor";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";

const SectionList = () => {
  const { t } = useTranslation(["common"]);

  // TODO: When free search text is available (search param)
  // we should switch from FE filtering to BE filtering
  const { vendors } = useGetVendors({
    itemsPerPage: 250,
  });

  const history = useHistory();
  const deleteSection = useDeleteSection();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeletesectionModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("section");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetAdminSections(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleDeletesection = (id: number) => {
    deleteSection.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "name",
        header: `${t("destinations.name")}`,
        maxSize: 50,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/section/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "description",
        header: `${t("description")}`,
        maxSize: 80,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                maxWidth: "200px",
                minWidth: "150px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {row.renderedCellValue}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: "vendorId",
        accessorFn: (originalRow: any) =>
          `${originalRow.vendorId} ${originalRow.vendorName ? `- ${originalRow.vendorName}` : ""
          }`,
        header: `${t("vendorName")}`,
        filterVariant: "select",
        filterSelectOptions: vendors
          ?.flatMap((vendorPage) => {
            return vendorPage.data.flatMap((vendor) => {
              return {
                text: `${vendor.id} - ${vendor.name}`,
                value: vendor.id,
              };
            });
          })
          .sort((a, b) => a.value - b.value),
        maxSize: 80,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "active",
        header: `${t("destinations.active")}`,
        maxSize: 60,
        filterVariant: "select",
        filterSelectOptions: [
          { text: `${t(`filters.ACTIVE`)}`, value: "true" },
          { text: `${t(`filters.INACTIVE`)}`, value: "false" },
        ],
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]
                    ? "ACTIVE"
                    : "INACTIVE"
                  }`
                )}`}
              />
            ) : (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue ? "ACTIVE" : "INACTIVE"}`
                )}`}
              />
            )}
          </Box>
        ),
      },
    ];
  }, [t, vendors]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("destinations.allSections")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const sectionId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (sectionId) {
                  history.push(`/section/${sectionId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (sectionId) {
                  history.push(`/section/${sectionId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (sectionId) {
                  openDeletesectionModal(sectionId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <ConfigurationHeader type={EDestination.SECTION} />
      <SBTable
        columnVisibilityStoreName="SectionListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="section"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeletesection(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default SectionList;
