import { 
    createSlice, 
    PayloadAction, 
} from "@reduxjs/toolkit";
import { SetLocaleRequestPayload as SetLocalePayload } from "../actionPayload/localesPayload";

const localeSlice = createSlice({
  name: "locale",
  initialState: {
    shortCode: '',
  },
  reducers: {
    setLocale(
        state, 
        action: PayloadAction<SetLocalePayload>
    ) {
        state.shortCode = action.payload.shortCode;
    },

  },
});

export const {
  setLocale
} = localeSlice.actions;

export default localeSlice.reducer;
