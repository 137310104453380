import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  UseFormSetValue,
} from "react-hook-form";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBSelectGridStyle,
  SBSelectStyle,
  SBTextFieldIconStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";
import { collator } from "../../../utils/comparator/comparator";

export interface SBSelectPrimary {
  name: string;
  label: React.ReactNode;
  noOptionLabel?: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  setValue?: UseFormSetValue<FieldValues>;
  setValueId?: string;
  additionalOptions?: any[];
  filterAdditionalOptionsBy?: number;
  defaultValue?: string | number;
}

export type SBSelectType = SBSelectPrimary & SBFormHelperTextPrimary;

const SBSelect: React.FC<SBSelectType> = ({
  name,
  label,
  noOptionLabel,
  isLoading,
  control,
  type,
  icon,
  setValue,
  setValueId,
  additionalOptions,
  defaultValue,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBSelectGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ field }) => (
                <Select
                  {...field}
                  fullWidth
                  variant="filled"
                  disabled={isDisabled}
                  label=""
                  sx={{
                    ...SBSelectStyle,
                    "& .MuiSelect-icon": {
                      display: isDisabled ? "none" : "auto",
                    },
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue && setValueId && setValue(setValueId, 0);
                  }}
                  input={
                    <OutlinedInput
                      sx={{
                        ...SBSelectStyle,
                      }}
                    />
                  }
                >
                  {noOptionLabel && (
                    <MenuItem value={defaultValue} key={`optionId-empty-0`}>
                      {noOptionLabel}
                    </MenuItem>
                  )}
                  {additionalOptions &&
                    additionalOptions.sort((a, b) => collator.compare(a.value, b.value)).map((option) => (
                      <MenuItem value={option.id} key={`optionId-${option.id}`}>
                        {option.value}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBSelect;
