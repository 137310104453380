import React, { useState } from "react";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import VendorUserListHeader from "../vendorUserListHeader/vendorUserListHeader";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";
import { LocationsAutocomplete, VendorAutocomplete } from "../../common";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import { AccessibilityNew, AccountCircle, Email, LocationCity, People } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import { useHistory, useParams } from "react-router";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import { useDeleteVendorUser } from "../hooks";
import DeleteDialog from "../../dialog/DeleteDialog";
import { useVendorUserForm } from "../components/vendorUserForm/useVendorUserForm";
import { VendorUserStatus } from "./vendorUserStatus";

export const VendorUser: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { id, type } = useParams<{ id: string, type: string }>();
  const history = useHistory();
  const { control, errors, handleReset, onSubmit, watch, isLoading, isSuperAdmin} = useVendorUserForm(id, type);
  const selectedVendorId = watch("vendorId");
  const deleteVendorUser = useDeleteVendorUser();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<string>("0");
  const openDeleteVendorUserModal = (deleteId: string) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(deleteId);
  };
  const handleDeleteCustomer = (userId: number | string) => {
    deleteVendorUser.mutateAsync({ id: `${userId}` }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  return (
    <SBMainComponentGridWrapper>
      <VendorUserListHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
        <SBColumnGridWrapper>
        <SBSelect
              name="salutation"
              label={t("salutation")}
              control={control}
              type={type}
              icon={<AccountCircle />}
              isLoading={isLoading}
              additionalOptions={[
                { id: "MR", value: `${t(`salutations.MR`)}` },
                { id: "MS", value: `${t(`salutations.MS`)}` },
                { id: "FM", value: `${t(`salutations.FM`)}` },
                { id: "MX", value: `${t(`salutations.MX`)}` },
              ]}
              defaultValue={"MR"}
              error={
                errors?.salutation && t((errors.salutation as any)?.message)
              }
            />
            <SBTextField
              name="firstName"
              label={t("firstname")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={isLoading}
              error={errors?.firstName && t((errors.firstName as any)?.message)}
            />
            <SBTextField
              name="lastName"
              label={t("lastname")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={isLoading}
              error={errors?.lastName && t((errors.lastName as any)?.message)}
            />
            <SBTextField
              name="email"
              label={t("email")}
              control={control}
              type={type}
              icon={<Email />}
              isLoading={isLoading}
              error={errors?.email && t((errors.email as any)?.message)}
            />
            {isSuperAdmin && (
              <FormControl error={!!errors.vendorId}>
                <Controller
                  control={control}
                  name="vendorId"
                  render={({ field }) => (
                    <VendorAutocomplete
                      type={type}
                      label={t("vendor")}
                      icon={<AccessibilityNew />}
                      value={field.value?.toString()}
                      onChange={(vendorId) =>
                        field.onChange(vendorId.toString())
                      }
                      onBlur={field.onBlur}
                      autoComplete={true}
                    />
                  )}
                />
              </FormControl>
            )}
            {isSuperAdmin && (
              <FormControl>
                <Controller
                  control={control}
                  name="defaultLocationId"
                  render={({ field }) => (
                    <LocationsAutocomplete
                      type={type}
                      label={t("location")}
                      icon={<LocationCity />}
                      disabled={!selectedVendorId}
                      value={field.value?.toString()}
                      vendorId={selectedVendorId}
                      onChange={(vendorId) =>
                        field.onChange(vendorId.toString())
                      }
                      onBlur={field.onBlur}
                      autoComplete={true}
                    />
                  )}
                />
              </FormControl>
            )}
        </SBColumnGridWrapper>
        <VendorUserStatus/>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/vendor_users/${id}/edit`)}
          deleteOnClick={() => {
            openDeleteVendorUserModal(id);
          }}
          resetOnClick={() => {
            handleReset();
          }}
          watchOnClick={() => history.push(`/vendor_users/${id}/show`)}
          type={type}
          isLoading={isLoading}
        />
      </form>
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="vendorUsers"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteCustomer(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainComponentGridWrapper>
  );
};