import { EInstantBooking } from "./../../components/bookingsList/instantBooking/instantBookingCard/instantBookingCard";
import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { createInstantBookingRequest } from "../../store/reducers/bookingsReducer";

export function useInstantBooking(type: EInstantBooking) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        model: yup.string().nullable(true),
        id: yup
          .number()
          .required(t(`instantBooking.${type}.select`) + t("isMandatory")),
        secondId: yup.number().nullable(true),
        amount: yup.number().nullable(true),
        cashPayment: yup.boolean().nullable(true),
      }),
    [t, type]
  );

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (formValues: any) => {
      dispatch(createInstantBookingRequest({ type: type, formValues }));
    },
    // eslint-disable-next-line
    [type]
  );

  return {
    watch,
    control,
    errors,
    setValue,
    getValues,
    onSubmit: handleSubmit(onSubmit),
  };
}
