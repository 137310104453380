import { all, call, put, select, takeLatest } from "typed-redux-saga";
import { Action } from "@reduxjs/toolkit";
import {
  changeBeachChairReportDataRequest,
  changeClosingReportsDataRequest,
  changeReportStatusFailure,
  changeReportStatusRequest,
  changeReportStatusSuccess,
  changeSepaXMLsDataRequest,
  getBeachChairReportsFailure,
  getBeachChairReportsRequest,
  getBeachChairReportsSuccess,
  getClosingReportsFailure,
  getClosingReportsRequest,
  getClosingReportsSuccess,
  getReportsKeyStatusFailure,
  getReportsKeyStatusRequest,
  getReportsKeyStatusSuccess,
  getSepaXMLsFailure,
  getSepaXMLsRequest,
  getSepaXMLsSuccess,
  putBeachChairReportFailure,
  putBeachChairReportRequest,
  putClosingReportFailure,
  putClosingReportRequest,
  putSepaXMLFailure,
  putSepaXMLRequest,
  routerRedirectReports,
} from "../reducers/reportsReducer";
import history from "../../utils/history/history";
import {
  changeReportKeyStatus,
  getReportKeyStatus,
  getReports,
  putBeachChairReports,
  putClosingReports,
} from "../../api/reportsApi";
import { format } from "date-fns";
import { toast } from "react-toastify";
import {
  getBeachChairReportCreatedAtPeriodSelector,
  getBeachChairReportEndDateSelector,
  getBeachChairReportStartDateSelector,
  getReportStatusByIdSelector,
} from "../selectors/reportSelectors";
import { IReportKeyStatus } from "../../models/reports/IReport";
import { getCurrentUserSelector } from "../selectors/authSelectors";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import i18next from "i18next";

function* getReportKeyStatusSaga(action: Action) {
  try {
    if (getReportsKeyStatusRequest.match(action)) {
      const { page, itemsPerPage, date, field, order, filterData } =
        action.payload;
      const filterString: string = `filters[date]=${format(
        new Date(date),
        "yyyy-MM-dd"
      )}${filterData && `&${filterDataToString(filterData)}`}`;
      const { data } = yield* call(
        getReportKeyStatus,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getReportsKeyStatusSuccess({
          keyStatuses: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getReportsKeyStatusFailure({
        error: e.message,
      })
    );
  }
}

function* changeReportStatusSaga(action: Action) {
  try {
    if (changeReportStatusRequest.match(action)) {
      const { keyStatusId, checked } = action.payload;
      yield* call(changeReportKeyStatus, keyStatusId, {
        status: checked ? "NOT_RETURNED" : "RETURNED",
      });
      var keyStatus = yield* select(getReportStatusByIdSelector(keyStatusId));
      const keyStatusTab = { ...keyStatus } as IReportKeyStatus;
      keyStatusTab.status = checked ? "NOT_RETURNED" : "RETURNED";
      yield* put(
        changeReportStatusSuccess({
          keyStatus: keyStatusTab,
        })
      );
      toast.success(
        `${i18next.t(`toastMessages.PutChangeReportStatusSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* put(
      changeReportStatusFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutChangeReportStatusFailure`)}`);
  }
}

function* putClosingReportSaga(action: Action) {
  try {
    if (putClosingReportRequest.match(action)) {
      const { startDate, endDate } = action.payload;
      const currentUser = yield* select(getCurrentUserSelector);
      const startDateString: string = format(new Date(startDate), "yyyy-MM-dd");
      const endDateString: string = format(new Date(endDate), "yyyy-MM-dd");
      yield* call(
        putClosingReports,
        startDateString,
        endDateString,
        "CASH_BALANCE",
        "de",
        currentUser?.id
      );
      yield* put(changeClosingReportsDataRequest());
    }
  } catch (e: any) {
    yield* put(
      putClosingReportFailure({
        error: e.message,
      })
    );
  }
}

function* getClosingReportSaga(action: Action) {
  try {
    if (getClosingReportsRequest.match(action)) {
      const { page, itemsPerPage, type, field, order } = action.payload;
      const filterString: string = `filters[type]=${type}`;
      const { data } = yield* call(
        getReports,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getClosingReportsSuccess({
          closingReports: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getClosingReportsFailure({
        error: e.message,
      })
    );
  }
}

function* putSepaXMLSaga(action: Action) {
  try {
    if (putSepaXMLRequest.match(action)) {
      const { startDate, endDate } = action.payload;
      const startDateString: string = format(new Date(startDate), "yyyy-MM-dd");
      const endDateString: string = format(new Date(endDate), "yyyy-MM-dd");
      yield* call(
        putClosingReports,
        startDateString,
        endDateString,
        "SEPA_XML",
        "de"
      );
      yield* put(changeSepaXMLsDataRequest());
    }
  } catch (e: any) {
    yield* put(
      putSepaXMLFailure({
        error: e.message,
      })
    );
  }
}

function* getSepaXMLsSaga(action: Action) {
  try {
    if (getSepaXMLsRequest.match(action)) {
      const { page, itemsPerPage, field, order } = action.payload;
      const filterString: string = `filters[type]=SEPA_XML_REPORT`;
      const { data } = yield* call(
        getReports,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getSepaXMLsSuccess({
          sepaXMLs: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getSepaXMLsFailure({
        error: e.message,
      })
    );
  }
}

function* putBeachChairReportSaga(action: Action) {
  try {
    if (putBeachChairReportRequest.match(action)) {
      const { sortBy } = action.payload;
      const startDate = yield* select(getBeachChairReportStartDateSelector);
      const endDate = yield* select(getBeachChairReportEndDateSelector);
      const createdArPeriod = yield* select(
        getBeachChairReportCreatedAtPeriodSelector
      );
      const startDateString: string = format(new Date(startDate), "yyyy-MM-dd");
      const endDateString: string = format(new Date(endDate), "yyyy-MM-dd");
      yield* call(
        putBeachChairReports,
        startDateString,
        endDateString,
        "BEACH_CHAIR_BOOKING",
        "de",
        sortBy,
        createdArPeriod
      );
      yield* put(changeBeachChairReportDataRequest());
    }
  } catch (e: any) {
    yield* put(
      putBeachChairReportFailure({
        error: e.message,
      })
    );
  }
}

function* getBeachChairReportsSaga(action: Action) {
  try {
    if (getBeachChairReportsRequest.match(action)) {
      const { page, itemsPerPage, field, order } = action.payload;
      const filterString: string = `filters[type]=BEACH_CHAIR_BOOKING_REPORT`;
      const { data } = yield* call(
        getReports,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getBeachChairReportsSuccess({
          beachChairReports: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getBeachChairReportsFailure({
        error: e.message,
      })
    );
  }
}

function* routerRedirectReportsSaga(action: Action) {
  if (routerRedirectReports.match(action)) {
    const { name } = action.payload;
    yield* call([history, history.push], `/reports/${name}`);
  }
}

function* ReportsSaga() {
  yield* all([
    takeLatest(getReportsKeyStatusRequest.type, getReportKeyStatusSaga),
    takeLatest(getClosingReportsRequest.type, getClosingReportSaga),
    takeLatest(changeReportStatusRequest.type, changeReportStatusSaga),
    takeLatest(putSepaXMLRequest.type, putSepaXMLSaga),
    takeLatest(getSepaXMLsRequest.type, getSepaXMLsSaga),
    takeLatest(putBeachChairReportRequest.type, putBeachChairReportSaga),
    takeLatest(getBeachChairReportsRequest.type, getBeachChairReportsSaga),
    takeLatest(routerRedirectReports.type, routerRedirectReportsSaga),
    takeLatest(putClosingReportRequest.type, putClosingReportSaga),
  ]);
}

export default ReportsSaga;
