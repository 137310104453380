import { Box, Grid, Typography, Divider, Stack } from "@mui/material";
import React from "react";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { Props } from "../../../utils/types/Props";

export const SearchResultsList: React.FC<{ title: string } & Props> = ({
  children,
  title,
}) => {
  return (
    <Grid item xs={4} sx={{ overflow: "hidden", height: "100%" }}>
      <Stack
        sx={{
          height: "100%",
          borderLeft: (theme) => `1px solid ${theme.palette.divider}`,
          position: "relative",
        }}
        spacing={1}
      >
        <Stack
          sx={{
            position: "sticky",
            top: 0,
            background: "#ffffff",
            px: 2,
            pt: 2,
          }}
          spacing={1}
        >
          <Typography fontWeight="bold">{title}</Typography>
          <Divider light />
        </Stack>

        <Box
          flex={1}
          sx={{
            px: 2,
            overflowX: "hidden",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: customStyle.scrollColor,
            },
          }}
        >
          <Box>{children}</Box>
        </Box>
      </Stack>
    </Grid>
  );
};
