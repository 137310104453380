import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useGetBeachChairsFilters } from "../../beachChairList/hooks/useGetBeachChairsFilters";
import { ExtendBeachChairBookingResponseDto } from "../../../api-client/generated";

export function useSectionRow(
  extendBeachChairBookingResponseDto?: ExtendBeachChairBookingResponseDto
) {
  const { t } = useTranslation(["common"]);
  const { data: filters, isLoading: filterIsLoading } =
    useGetBeachChairsFilters();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        sectionId: yup
          .number()
          .typeError(t("section") + t("isMandatory"))
          .moreThan(0, t("section") + t("isMandatory"))
          .required(t("section") + t("isMandatory")),
        rowId: yup
          .number()
          .typeError(t("row") + t("isMandatory"))
          .moreThan(0, t("section") + t("isMandatory"))
          .required(t("row") + t("isMandatory")),
      }),
    [t]
  );

  const {
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (extendBeachChairBookingResponseDto) {
      setValue(
        "sectionId",
        extendBeachChairBookingResponseDto.beachChair.section.id
      );
      setValue("rowId", extendBeachChairBookingResponseDto.beachChair.row.id);
    }
    // eslint-disable-next-line
  }, [extendBeachChairBookingResponseDto]);

  const handleReset = useCallback(() => {
    setValue("sectionId", 0);
    setValue("rowId", 0);
  }, [setValue]);

  const selectedSectionId = watch("sectionId");
  const selectedRowId = watch("rowId");

  return {
    control,
    errors,
    setValue,
    onReset: handleReset,
    filters,
    filterIsLoading,
    selectedSectionId,
    selectedRowId,
  };
}
