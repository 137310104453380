import dayjs, { Dayjs } from "dayjs";
import { IOpeningHour } from "../../models/common/IOpeningHour";
import { ILocation } from "../../models/vendor/ILocation";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";

export const selectHour = (
  openingHours: IOpeningHour[],
  weekDay: number,
  start: boolean
) => {
  const hours = openingHours.find(
    (openingHour) => openingHour.weekday === (weekDay === 0 ? 6 : weekDay - 1)
  );
  return hours && (start ? hours.opening : hours.closing);
};

export const createDateFromHour = (time: string, dateFromHour: Date) => {
  const splitTime = time.split(`:`);
  const day = dayjs(dateFromHour)
    .set("hour", +splitTime[0])
    .set("minute", +splitTime[1]);
  return day;
};

export const locationTimes = (
  dateTime: DateRange<Dayjs>,
  locationData?: ILocation | undefined,
  afterHourBooking?: boolean | undefined
) => {
  const isToday = dayjs(dateTime[0]).isSame(dayjs(), "day");
  const startTimeWeekDay = dateTime[0]?.day() ?? 0;
  const endTimeWeekDay = dateTime[1]?.day() ?? 0;
  let locationStartTime = afterHourBooking
    ? "00:00"
    : locationData?.details?.openingHours &&
      selectHour(locationData.details.openingHours, startTimeWeekDay, true);
  let locationStartTimeEnd = afterHourBooking
    ? "23:59"
    : locationData?.checkoutTime ||
      (locationData?.details?.openingHours &&
        selectHour(locationData.details.openingHours, startTimeWeekDay, false));
  if (isToday) {
    const actualHour = dayjs().get("hour");
    const startHour = +(locationStartTime?.split(":")[0] ?? "0");
    const endHour = +(locationStartTimeEnd?.split(":")[0] ?? "23");
    const isBetweenHours = startHour < actualHour && actualHour < endHour;
    if (isBetweenHours) {
      locationStartTime = locationStartTime = `${dayjs().get(
        "hour"
      )}:${dayjs().get("minutes")}`;
    }
  }
  return {
    locationStartTime,
    locationStartTimeEnd,
    locationEndTime: afterHourBooking
      ? "23:59"
      : locationData?.checkoutTime ||
        (locationData?.details?.openingHours &&
          selectHour(locationData.details.openingHours, endTimeWeekDay, false)),
    locationEndTimeStart: afterHourBooking
      ? "23:59"
      : locationData?.details?.openingHours &&
        selectHour(locationData.details.openingHours, endTimeWeekDay, true),
  };
};

export const setBookingDateTime = (
  dateTime: DateRange<Dayjs>,
  locationData?: ILocation | undefined,
  afterHourBooking?: boolean
) => {
  const {
    locationStartTime,
    locationStartTimeEnd,
    locationEndTime,
    locationEndTimeStart,
  } = locationTimes(dateTime, locationData, afterHourBooking);
  return {
    bookingStartDateTime:
      dateTime[0] &&
      locationStartTime &&
      createDateFromHour(
        locationStartTime,
        new Date(dateTime[0].toISOString())
      ),
    bookingStartDateTimeEnd:
      dateTime[0] &&
      locationStartTimeEnd &&
      createDateFromHour(
        locationStartTimeEnd,
        new Date(dateTime[0].toISOString())
      ),
    bookingEndDateTime:
      dateTime[1] &&
      locationEndTime &&
      createDateFromHour(locationEndTime, new Date(dateTime[1].toISOString())),
    bookingEndDateTimeStart:
      dateTime[1] &&
      locationEndTimeStart &&
      createDateFromHour(
        locationEndTimeStart,
        new Date(dateTime[1].toISOString())
      ),
  };
};

export const setInitialBookingDateTime = (
  dateTime: DateRange<Dayjs>,
  locationData?: ILocation | undefined
) => {
  const {
    locationStartTime,
    locationStartTimeEnd,
    locationEndTime,
    locationEndTimeStart,
  } = locationTimes(dateTime, locationData);
  return {
    bookingStartDateTime:
      dateTime[0] &&
      locationStartTime &&
      createDateFromHour(
        locationStartTime,
        new Date(dateTime[0].toISOString())
      ),
    bookingStartDateTimeEnd:
      dateTime[0] &&
      locationStartTimeEnd &&
      createDateFromHour(
        locationStartTimeEnd,
        new Date(dateTime[0].toISOString())
      ),
    bookingEndDateTime:
      dateTime[1] &&
      locationEndTime &&
      createDateFromHour(locationEndTime, new Date(dateTime[1].toISOString())),
    bookingEndDateTimeStart:
      dateTime[1] &&
      locationEndTimeStart &&
      createDateFromHour(
        locationEndTimeStart,
        new Date(dateTime[1].toISOString())
      ),
  };
};
