import {
  GetAllAvailabileBeachChairsRequestPayload,
  GetAllAvailabileBeachChairsSuccessPayload,
  GetAvailabilitiesBeachChairsChangeFailurePayload,
  GetAvailabilitiesBeachChairsChangeRequestPayload,
  GetAvailabilitiesBeachChairsChangeSuccessPayload,
  PutPositionFailurePayload,
  PutPositionRequestPayload,
  PutPositionSuccessPayload,
  SetAvabilityLayoutPayload,
  SetPositionPayload,
} from "./../actionPayload/availabilitiesPayloads";
import { IGridView } from "./../../models/availability/IGrid";
import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IAvailableBeachChairs } from "../../models/availability/IAvailableBeachChairs";
import { IAvailability } from "../../models/availability/IAvailibility";
import {
  ClearGridPayload,
  FetchAvailabilitiesBeachChairsFailurePayload,
  FetchAvailabilitiesBeachChairsRequestPayload,
  FetchAvailabilitiesBeachChairsSuccessPayload,
  FetchAvailabilitiesFailurePayload,
  FetchAvailabilitiesRequestPayload,
  FetchAvailabilitiesSuccessPayload,
  GetGridFailurePayload,
  GetGridRequestPayload,
  GetGridSuccessPayload,
  RouteAvailabilitiesBeachChairPayload,
  RouteAvailabilitiesSectionPayload,
  SetAvailabilitiesBeachChairFiltersPayload,
  SetCurrentDatePayload,
  SetGridPayload,
  SetGridTimePayload,
} from "../actionPayload/availabilitiesPayloads";
import { AppState } from "./rootReducer";

const availabilityAdapter = createEntityAdapter<IAvailability>({
  selectId: (availability) => availability.id,
});

const allAvailabileBeachChairsAdapter = createEntityAdapter<IAvailableBeachChairs>(
  {
    selectId: (availability) => availability.id,
  }
);

const rowAdapter = createEntityAdapter<IAvailableBeachChairs>({
  selectId: (row) => row.name,
});

const rowAllAdapter = createEntityAdapter<IAvailableBeachChairs>({
  selectId: (row) => row.id,
});

const INITIAL_STATE = availabilityAdapter.getInitialState({
  pending: false,
  rows: rowAdapter.getInitialState(),
  changeAvailabilityBeachChairs: rowAllAdapter.getInitialState(),
  allAvailabileBeachChairs: allAvailabileBeachChairsAdapter.getInitialState(),
  error: "",
  currentDate: new Date().toISOString(),
  availabilitySectionStartDate: new Date().toISOString(),
  availabilitySectionEndDate: new Date().toISOString(),
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  startTime: new Date(0, 0, 0, 8, 0).toISOString(),
  endTime: new Date(0, 0, 0, 22, 0).toISOString(),
  isGrid: false,
  isPosition: false,
  avabilityLayout: false,
  grid: { groups: [], items: [] } as IGridView,
  gridTime: 0,
  beachchairFilters: {
    row: "",
    model: "",
    free: false,
    hasLock: false,
  },
});

const availabilitySlice = createSlice({
  name: "availability",
  initialState: INITIAL_STATE,
  reducers: {
    resetAvailabilityRequest() {
      return INITIAL_STATE;
    },
    fetchAvailabilitiesRequest(
      state,
      action: PayloadAction<FetchAvailabilitiesRequestPayload>
    ) {
      state.pending = true;
    },
    fetchAvailabilitiesSuccess(
      state,
      action: PayloadAction<FetchAvailabilitiesSuccessPayload>
    ) {
      availabilityAdapter.removeAll(state);
      state.pending = false;
      rowAdapter.removeAll(state.rows);
      availabilityAdapter.upsertMany(state, action.payload.availabilities);
    },
    fetchAvailabilitiesFailure(
      state,
      action: PayloadAction<FetchAvailabilitiesFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getAllAvailableBeachChairsRequest(
      state,
      action: PayloadAction<GetAllAvailabileBeachChairsRequestPayload>
    ) {
      //allAvailabileBeachChairsAdapter.removeAll(state.allAvailabileBeachChairs);
    },
    getAllAvailableBeachChairsSuccess(
      state,
      action: PayloadAction<GetAllAvailabileBeachChairsSuccessPayload>
    ) {
      allAvailabileBeachChairsAdapter.upsertMany(
        state.allAvailabileBeachChairs,
        action.payload.availableBeachChairs
      );
    },
    routeAvailabilitiesSection(
      state,
      action: PayloadAction<RouteAvailabilitiesSectionPayload>
    ) {
      rowAdapter.removeAll(state.rows);
    },
    setAvailabilitiesBeachChairFilters(
      state,
      action: PayloadAction<SetAvailabilitiesBeachChairFiltersPayload>
    ) {
      rowAdapter.removeAll(state.rows);
      state.beachchairFilters = action.payload;
    },
    routeAvailabilitiesBeachChair(
      state,
      action: PayloadAction<RouteAvailabilitiesBeachChairPayload>
    ) {},
    fetchAvailabilitiesBeachChairsRequest(
      state,
      action: PayloadAction<FetchAvailabilitiesBeachChairsRequestPayload>
    ) {
      state.pending = true;
    },
    fetchAvailabilitiesBeachChairsSuccess(
      state,
      action: PayloadAction<FetchAvailabilitiesBeachChairsSuccessPayload>
    ) {
      rowAdapter.removeAll(state.rows);
      rowAdapter.upsertMany(state.rows, action.payload.availableBeachChairs);
      state.grid = { groups: [], items: [] } as IGridView;
      state.pending = false;
    },
    fetchAvailabilitiesBeachChairsFailure(
      state,
      action: PayloadAction<FetchAvailabilitiesBeachChairsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    setAvailabilitiesCurrentDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.currentDate = action.payload.date;
    },
    setAvailabilitiesSectionStartDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.availabilitySectionStartDate = action.payload.date;
    },
    setAvailabilitiesSectionEndDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.availabilitySectionEndDate = action.payload.date;
    },
    setAvailabilitiesStartDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.startDate = action.payload.date;
    },
    setAvailabilitiesEndDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.endDate = action.payload.date;
    },
    setAvailabilitiesStartTime(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.startTime = action.payload.date;
    },
    setAvailabilitiesEndTime(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.endTime = action.payload.date;
    },
    setGridView(state, action: PayloadAction<SetGridPayload>) {
      state.isGrid = action.payload.isGrid;
    },
    setPositionView(state, action: PayloadAction<SetPositionPayload>) {
      state.isPosition = action.payload.isPosition;
      if (!state.isPosition) {
        rowAdapter.removeAll(state.rows);
      }
    },
    setAvabilityLayout(
      state,
      action: PayloadAction<SetAvabilityLayoutPayload>
    ) {
      state.avabilityLayout = action.payload.avabilityLayout;
    },
    putPositionRequest(
      state,
      action: PayloadAction<PutPositionRequestPayload>
    ) {},
    putPositionSuccess(
      state,
      action: PayloadAction<PutPositionSuccessPayload>
    ) {
      state.pending = false;
    },
    putPositionFailure(
      state,
      action: PayloadAction<PutPositionFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getGridViewRequest(state, action: PayloadAction<GetGridRequestPayload>) {
      state.pending = true;
    },
    getGridViewSuccess(state, action: PayloadAction<GetGridSuccessPayload>) {
      state.pending = false;
      state.grid = action.payload.grid;
    },
    getGridViewFailure(state, action: PayloadAction<GetGridFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    setGridTime(state, action: PayloadAction<SetGridTimePayload>) {
      state.gridTime = action.payload.time;
    },
    clearGrid(state, action: PayloadAction<ClearGridPayload>) {
      state.gridTime = 0;
      state.grid = { groups: [], items: [] } as IGridView;
    },
    getAvailabilitiesBeachChairsChangeRequest(
      state,
      action: PayloadAction<GetAvailabilitiesBeachChairsChangeRequestPayload>
    ) {
      state.pending = true;
    },
    getAvailabilitiesBeachChairsChangeSuccess(
      state,
      action: PayloadAction<GetAvailabilitiesBeachChairsChangeSuccessPayload>
    ) {
      rowAllAdapter.removeAll(state.changeAvailabilityBeachChairs);
      rowAllAdapter.upsertMany(
        state.changeAvailabilityBeachChairs,
        action.payload.availableBeachChairs
      );
      state.pending = false;
    },
    getAvailabilitiesBeachChairsChangeFailure(
      state,
      action: PayloadAction<GetAvailabilitiesBeachChairsChangeFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    removeRows(state) {
      rowAdapter.removeAll(state.rows);
    },
  },
});

export const {
  selectAll: getAllAvailabilities,
  selectById: getAvailability,
  selectTotal: getAvailabilitiesTotalCount,
} = availabilityAdapter.getSelectors<AppState>((state) => state.availabilities);

export const {
  selectAll: getAllRows,
  selectById: getRowById,
  selectTotal: getRowsCount,
} = rowAdapter.getSelectors<AppState>((state) => state.availabilities.rows);

export const {
  selectAll: getAllChangeBeachChairRows,
} = rowAllAdapter.getSelectors<AppState>(
  (state) => state.availabilities.changeAvailabilityBeachChairs
);

export const {
  selectAll: allAvailabileBeachChairs,
  selectById: getAvailabileBeachChairById,
} = allAvailabileBeachChairsAdapter.getSelectors<AppState>(
  (state) => state.availabilities.allAvailabileBeachChairs
);

export const {
  resetAvailabilityRequest,
  fetchAvailabilitiesRequest,
  fetchAvailabilitiesSuccess,
  fetchAvailabilitiesFailure,
  setAvailabilitiesBeachChairFilters,
  setGridView,
  setPositionView,
  setAvabilityLayout,
  putPositionRequest,
  putPositionSuccess,
  putPositionFailure,
  getGridViewRequest,
  getGridViewSuccess,
  getGridViewFailure,
  setGridTime,
  clearGrid,
  routeAvailabilitiesSection,
  setAvailabilitiesCurrentDate,
  setAvailabilitiesSectionStartDate,
  setAvailabilitiesSectionEndDate,
  setAvailabilitiesStartDate,
  setAvailabilitiesEndDate,
  setAvailabilitiesStartTime,
  setAvailabilitiesEndTime,
  routeAvailabilitiesBeachChair,
  fetchAvailabilitiesBeachChairsRequest,
  fetchAvailabilitiesBeachChairsSuccess,
  fetchAvailabilitiesBeachChairsFailure,
  getAvailabilitiesBeachChairsChangeRequest,
  getAvailabilitiesBeachChairsChangeSuccess,
  getAvailabilitiesBeachChairsChangeFailure,
  getAllAvailableBeachChairsRequest,
  getAllAvailableBeachChairsSuccess,
  removeRows,
} = availabilitySlice.actions;

export default availabilitySlice.reducer;
