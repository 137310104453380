import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AddIcon from "@mui/icons-material/Add";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EmailIcon from "@mui/icons-material/Email";
import { PaymentFlow } from "../../../../api-client/generated";
import { toast } from "react-toastify";
import {
  buttonColor,
  buttonGroup,
} from "../../../../utils/customStyles/globalStyles";
import {
  useConfirmBooking,
  useCreateStripeCheckoutSession,
  useGetBooking,
  useReserveBooking,
  usePayForBooking,
  useUpdateBooking,
} from "../hooks";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";

const PaymentActionOptions = ["confirm", "reserve"];

export const PaymentActionButton: React.FC<{ id: number }> = ({ id }) => {
  const { t } = useTranslation(["common"]);
  const { booking, isFetching } = useGetBooking(id);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const { payForBooking } = usePayForBooking();
  const { reserveBooking } = useReserveBooking();
  const { confirmBooking } = useConfirmBooking();
  const { createStripeCheckoutSession } = useCreateStripeCheckoutSession();
  const queryClient = useQueryClient();
  const mutationState = useIsMutating({ mutationKey: ["updateBooking"] });
  const onConfirmBooking = useCallback(() => {
    if (booking?.paymentMethod.includes("STRIPE_PAYMENT_LINK")) {
      if (!booking) return;

      createStripeCheckoutSession(
        {
          bookingRef: booking.bookingRef,
          email: booking.customer?.email || "",
          flow: PaymentFlow.Email,
        },
        {
          onSuccess: () => {
            toast.success(t(`toastMessages.SendPaymentLinkSuccess`));
            queryClient.invalidateQueries(["booking", id]);
          },
          onError: () => {
            toast.error(t(`toastMessages.SendPaymentLinkFailure`));
          },
        }
      );
    } else {
      if (
        booking?.paymentMethod.includes("CASH") &&
        booking?.status.includes("OPEN")
      ) {
        payForBooking(
          { id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["booking", id]);
              toast.success(t(`toastMessages.ChangeBookingStatusSuccess`));
            },
            onError: (e: any) => {
              const errorCode = e?.response?.data?.errorCode;
              if (errorCode && errorCode === "CASH_POINT_STATE_ERROR") {
                toast.error(t(`toastMessages.${errorCode}`));
              } else {
                toast.error(t(`toastMessages.ChangeBookingStatusFailure`));
              }
            },
          }
        );
      } else {
        confirmBooking(
          { id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["booking", id]);
              toast.success(t(`toastMessages.ChangeBookingStatusSuccess`));
            },
            onError: () => {
              toast.error(t(`toastMessages.ChangeBookingStatusFailure`));
            },
          }
        );
      }
    }
  }, [booking, confirmBooking, id, payForBooking, queryClient, t]);

  const onReserveBooking = useCallback(() => {
    reserveBooking(
      { id },
      {
        onSuccess: async () => {
          toast.success(t(`toastMessages.ChangeBookingStatusSuccess`));
          queryClient.invalidateQueries(["booking", id]);
        },
        onError: () => {
          toast.error(t(`toastMessages.ChangeBookingStatusFailure`));
        },
      }
    );
  }, [id, reserveBooking, t, queryClient]);

  const handleClick = React.useCallback(() => {
    switch (PaymentActionOptions[selectedIndex]) {
      case "reserve": {
        onReserveBooking();
        break;
      }
      default:
      case "confirm": {
        onConfirmBooking();
        break;
      }
    }
  }, [selectedIndex, onReserveBooking, onConfirmBooking]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        disabled={isFetching || mutationState === 1}
        sx={{
          ...buttonGroup,
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={handleClick}
          sx={{ ...buttonColor }}
          startIcon={
            <>
              {PaymentActionOptions[selectedIndex] === "confirm" ? (
                <AddIcon />
              ) : null}
              {PaymentActionOptions[selectedIndex] === "reserve" ? (
                <EventAvailableIcon />
              ) : null}
            </>
          }
        >
          {t(`${PaymentActionOptions[selectedIndex]}Booking`)}
        </Button>
        <Button
          sx={{ ...buttonColor }}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {PaymentActionOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={
                        option === "reserve" &&
                        !(
                          booking?.paymentMethod.includes("CARD_TERMINAL") ||
                          booking?.paymentMethod.includes("CASH")
                        )
                      }
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {t(`${option}Booking`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};
