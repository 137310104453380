import { IFilterData } from "./../../models/dataTable/IFilterData";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  FetchPricesFailurePayload,
  FetchPricesRequestPayload,
  FetchPricesSuccessPayload,
  FilterPricesFailurePayload,
  FilterPricesRequestPayload,
  FilterPricesSuccessPayload,
  FilterDataPricesChangeRequestPayload,
  PushPriceFailurePayload,
  PushPriceRequestPayload,
  PushPriceSuccessPayload,
  RouterRedirectPricePayload,
  GetPriceCalculatesSuccessPayload,
  GetPriceCalculateFailurePayload,
  GetPriceCalculateRequestPayload,
} from "../actionPayload/pricePayloads";
import { AppState } from "./rootReducer";
import { IPrice } from "../../models/prices/IPrice";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IPriceState {
  pending: boolean;
  error: string;
  count: number;
  filterData: IFilterData[];
  page: EntityState<IPage>;
  calculatedPrice: number;
}

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const priceAdapter = createEntityAdapter<IPrice>({
  selectId: (price) => price.id,
});

const INITIAL_STATE = priceAdapter.getInitialState<IPriceState>({
  pending: false,
  error: "",
  count: 0,
  filterData: [],
  page: pageAdapter.getInitialState({}),
  calculatedPrice: 0,
});

const priceSlice = createSlice({
  name: "price",
  initialState: INITIAL_STATE,
  reducers: {
    resetPriceRequest() {
      return INITIAL_STATE;
    },
    fetchPricesRequest(
      state,
      action: PayloadAction<FetchPricesRequestPayload>
    ) {
      state.pending = true;
    },
    fetchPricesSuccess(
      state,
      action: PayloadAction<FetchPricesSuccessPayload>
    ) {
      state.pending = false;
      state.count = action.payload.count;
      priceAdapter.upsertMany(state, action.payload.prices);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.prices.map((price) => price.id)],
      });
    },
    fetchPricesFailure(
      state,
      action: PayloadAction<FetchPricesFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterPricesRequest(
      state,
      action: PayloadAction<FilterPricesRequestPayload>
    ) {
      state.pending = true;
    },
    filterPricesSuccess(
      state,
      action: PayloadAction<FilterPricesSuccessPayload>
    ) {
      state.pending = false;
      priceAdapter.upsertMany(state, action.payload.prices);
      pageAdapter.upsertOne(state.page, {
        key: `filter:${action.payload.filterPhrase}:${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.prices.map((price) => price.id)],
      });
    },
    filterPricesFailure(
      state,
      action: PayloadAction<FilterPricesFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterCancelPricesSuccess(state, action) {},
    filterDataPricesChangeRequest(
      state,
      action: PayloadAction<FilterDataPricesChangeRequestPayload>
    ) {
      state.filterData = state.filterData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    pushPriceRequest(state, action: PayloadAction<PushPriceRequestPayload>) {
      state.pending = true;
    },
    pushPriceSuccess(state, action: PayloadAction<PushPriceSuccessPayload>) {
      state.pending = false;
      priceAdapter.upsertOne(state, action.payload.price);
    },
    pushPriceFailure(state, action: PayloadAction<PushPriceFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getPriceCalculateRequest(
      state,
      action: PayloadAction<GetPriceCalculateRequestPayload>
    ) {
      state.pending = true;
    },
    getPriceCalculateSuccess(
      state,
      action: PayloadAction<GetPriceCalculatesSuccessPayload>
    ) {
      state.pending = false;
      state.calculatedPrice = action.payload.calculatedPrice;
    },
    getPriceCalculateFailure(
      state,
      action: PayloadAction<GetPriceCalculateFailurePayload>
    ) {
      state.pending = false;
      state.calculatedPrice = 0;
      state.error = action.payload.error;
    },
    routerRedirectPrice(
      state,
      action: PayloadAction<RouterRedirectPricePayload>
    ) {},
  },
});

export const {
  selectAll: getAllPricesPages,
  selectById: getPricePages,
} = pageAdapter.getSelectors<AppState>((state) => state.prices.page);

export const {
  selectAll: getAllPrices,
  selectById: getPriceById,
  selectTotal: getPriceTotal,
} = priceAdapter.getSelectors<AppState>((state) => state.prices);

export const {
  resetPriceRequest,
  fetchPricesRequest,
  fetchPricesSuccess,
  fetchPricesFailure,
  filterPricesRequest,
  filterPricesSuccess,
  filterPricesFailure,
  filterCancelPricesSuccess,
  filterDataPricesChangeRequest,
  pushPriceRequest,
  pushPriceSuccess,
  pushPriceFailure,
  getPriceCalculateRequest,
  getPriceCalculateSuccess,
  getPriceCalculateFailure,
  routerRedirectPrice,
} = priceSlice.actions;

export default priceSlice.reducer;
