import { IStatusProps } from "../../models/dataTable/IStatusProps";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const Status = ({ value }: IStatusProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <>
      {(value === t("states.ACTIVE") || value === "confirmed") && (
        <Box
          data-testid="confirmed"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "green",
          }}
        >
          {value === t("states.ACTIVE") ? t("states.ACTIVE") : t("states.PAID")}
        </Box>
      )}
      {(value === t("states.OPEN") || value === "confirmed") && (
        <Box
          data-testid="confirmed"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "orange",
          }}
        >
          {t("states.OPEN")}
        </Box>
      )}
      {(value === t("states.CONFIRMED") || value === "confirmed") && (
        <Box
          data-testid="confirmed"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "green",
          }}
        >
          {t("states.CONFIRMED")}
        </Box>
      )}
      {(value === t("states.INACTIVE") || value === t("states.CANCELED")) && (
        <Box
          data-testid="canceled"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "red",
          }}
        >
          {value === t("states.INACTIVE")
            ? t("states.INACTIVE")
            : t("states.CANCELED")}
        </Box>
      )}
      {value === t("states.PENDING") && (
        <Box
          data-testid="pending"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "blue",
          }}
        >
          {t("states.PENDING")}
        </Box>
      )}
      {value === t("states.RESERVED") && (
        <Box
          data-testid="pending"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            width: 80,
            borderRadius: 20,
            backgroundColor: "white",
            border: "1px solid green",
            color: "green",
          }}
        >
          {t("states.RESERVED")}
        </Box>
      )}
      {value === "ONLINE" && (
        <Box
          data-testid="pending"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "green",
          }}
        >
          {t("locks.online")}
        </Box>
      )}
      {value === "OFFLINE" && (
        <Box
          data-testid="pending"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            color: "white",
            width: 80,
            borderRadius: 20,
            backgroundColor: "red",
          }}
        >
          {t("locks.offline")}
        </Box>
      )}
      {value !== t("states.ACTIVE") &&
        value !== t("states.OPEN") &&
        value !== t("states.CONFIRMED") &&
        value !== t("states.INACTIVE") &&
        value !== t("states.CONFIRMED") &&
        value !== t("states.PENDING") &&
        value !== "confirmed" &&
        value !== t("states.CANCELED") &&
        value !== t("states.RESERVED") &&
        value !== "OFFLINE" &&
        value !== "ONLINE" && (
          <Box
            data-testid="default"
            sx={{
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              color: "white",
              width: 80,
              borderRadius: 20,
              backgroundColor: "gray",
            }}
          >
            {value}
          </Box>
        )}
    </>
  );
};

export default Status;
