import React, { useMemo, useState } from "react";
import { Box, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ReportKeyStatusHeader from "./reportKeyStatusHeader";
import { getReportKeyStatusCurrentDateSelector } from "../../../store/selectors/reportSelectors";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import SBTable from "../../SBTable/SBTable";
import { dateToProperFormat } from "../../../utils/conversions/dataConversion";
import { useGetReportKeyStatuses } from "./hooks/useGetReportKeyStatuses";
import { useUpdateReportKeyStatus } from "./hooks/useUpdateReportKeyStatus";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";

const ReportKeyStatus = () => {
  const { t } = useTranslation(["common"]);
  const updateReportKeyStatus = useUpdateReportKeyStatus();
  const date = useSelector(getReportKeyStatusCurrentDateSelector);
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("reports/key_status");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetReportKeyStatuses(
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      date
    );

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "publicNumber",
        header: `${t("publicNumber")}`,
        maxSize: 60,
      },
      {
        accessorKey: "start",
        header: `${t("start")}`,
        accessorFn: (originalRow: any) =>
          `${dateToProperFormat(originalRow.start)}`,
        maxSize: 60,
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
      },
      {
        accessorKey: "end",
        header: `${t("end")}`,
        accessorFn: (originalRow: any) =>
          `${dateToProperFormat(originalRow.end)} `,
        maxSize: 60,
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
      },
      {
        accessorKey: "description",
        header: `${t("notes")}`,
        accessorFn: (originalRow: any) =>
          `${
            originalRow.description && originalRow.description.length > 32
              ? originalRow.description.slice(0, 32) + "…"
              : originalRow.description
          }`,
        maxSize: 50,
      },
      {
        accessorKey: "locationId",
        header: `${t("locationId")}`,
        accessorFn: (originalRow: any) => `${originalRow.location?.name || ""}`,
        maxSize: 50,
      },
      {
        accessorKey: "bookingRef",
        header: `${t("bookingRef")}`,
        maxSize: 50,
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("keyStatus")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const handleOnSwitchClick = React.useCallback(
    (keyStatusId: number, checked: boolean) => {
      updateReportKeyStatus.mutateAsync({ keyStatusId, checked });
    },
    [updateReportKeyStatus]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const keyStatusId = data?.items[cell.row.index].id ?? 0;
      const checked =
        data?.items[cell.row.index].status === "RETURNED" ?? false;
      return (
        <Box sx={{ float: "right" }}>
          <Switch
            checked={checked}
            onChange={() => handleOnSwitchClick(keyStatusId, checked)}
          />
        </Box>
      );
    },
    [data, handleOnSwitchClick]
  );

  return (
    <SBMainListWrapper>
      <ReportKeyStatusHeader />
      <SBTable
        columnVisibilityStoreName="reportKeyStatusColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
    </SBMainListWrapper>
  );
};

export default ReportKeyStatus;
