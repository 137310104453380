import API from "./api";
import { IVendor } from "../models/vendor/IVendor";
import { ILocation } from "../models/vendor/ILocation";
import { IResponse } from "./destinationApi";

const BASE_URL = "/vendor/vendors";
const LOCATIONS_BASE_URL = "/vendor/locations";
const LOCATIONS_FEATURE_URL = "/features/locations";

export interface IVendorsResponse {
  count: number;
  items: IVendor[];
}

export const getVendor = (vendorId: number) => {
  return API.get<IVendor>(`${BASE_URL}/${vendorId}`);
};

export const getVendors = () => {
  const page = 0,
    itemsPerPage = 0;
  return API.get<IVendorsResponse>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}`
  );
};
//!!
export const putVendor = (vendorId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IVendor>(`${BASE_URL}/${vendorId}`, data, config);
};

export const getAdminVendors = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<IVendor>>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getAdminVendor = (id: number) =>
  API.get<IVendor>(`${BASE_URL}/${id}`);

export const putAdminVendor = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IVendor>(`${BASE_URL}/${id}`, data, config);
};

export const postAdminVendor = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IVendor>(`${BASE_URL}`, data, config);
};

export const deleteAdminVendor = (id: number) =>
  API.delete(`${BASE_URL}/${id}`);

export const getLocation = (locationId: number) => {
  return API.get<ILocation>(`${LOCATIONS_BASE_URL}/${locationId}`);
};

export const putLocation = (locationId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<ILocation>(
    `${LOCATIONS_FEATURE_URL}/${locationId}`,
    data,
    config
  );
};

export const deleteLocation = (locationId: number) => {
  return API.delete<ILocation>(`${LOCATIONS_BASE_URL}/${locationId}`);
};

export interface ILocationsRequest {
  count: number;
  items: ILocation[];
}

export const getLocations = () => {
  return API.get<ILocationsRequest>(`${LOCATIONS_BASE_URL}`);
};

export const postLocations = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<ILocation>(`${LOCATIONS_FEATURE_URL}`, data, config);
};
