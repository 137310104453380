import React, { Fragment } from "react";
import { IAuxProps } from "../../models/common/IAuxProps";
import { Box, Divider, Typography } from "@mui/material";

export interface ISubPageHeaderProps extends IAuxProps {
  headerTitle: string;
}

const SubPageHeader = ({ children, headerTitle }: ISubPageHeaderProps) => {
  return (
    <Fragment>
      <Box
        sx={{
          height: "70px",
          display: "grid",
          gridTemplateColumns: "auto auto",
          width: "100%",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          display="inline"
          data-testid="title"
          sx={{
            fontSize: {
              lg: "26px",
              md: "26px",
              sm: "20px",
              xs: "20px",
            },
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "35px",
            gridColumn: 1,
            gridRow: 1,
          }}
        >
          {headerTitle}
        </Typography>
        {children as any}
      </Box>
      <Divider orientation="horizontal" data-testid="divider" />
    </Fragment>
  );
};

export default SubPageHeader;
