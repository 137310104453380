import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  buttonColor,
  buttonSplitReport,
} from "../../utils/customStyles/globalStyles";
import { useSelector } from "react-redux";
import { useCreateBeachChairReports } from "../reports/beachChairs/hooks/useCreateBeachChairReports";
import {
  getBeachChairReportCreatedAtPeriodSelector,
  getBeachChairReportEndDateSelector,
  getBeachChairReportStartDateSelector,
} from "../../store/selectors/reportSelectors";

export default function BeachChairSplitButton() {
  const { t } = useTranslation(["common"]);
  const options = React.useMemo(() => ["createdAt", "publicNumber"], []);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const startDate = useSelector(getBeachChairReportStartDateSelector);
  const endDate = useSelector(getBeachChairReportEndDateSelector);
  const createdArPeriod = useSelector(
    getBeachChairReportCreatedAtPeriodSelector
  );
  const createBeachChairReports = useCreateBeachChairReports();

  const handleClick = React.useCallback(() => {
    if (options[selectedIndex] === "createdAt") {
      createBeachChairReports.mutateAsync({
        startDate,
        endDate,
        reportType: "BEACH_CHAIR_BOOKING",
        sortBy: options[0],
        createdArPeriod,
      });
    } else {
      createBeachChairReports.mutateAsync({
        startDate,
        endDate,
        reportType: "BEACH_CHAIR_BOOKING",
        sortBy: options[1],
        createdArPeriod,
      });
    }
    // eslint-disable-next-line
  }, [options, selectedIndex, startDate, endDate, createdArPeriod]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        sx={{
          ...buttonSplitReport,
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button onClick={handleClick} sx={{ ...buttonColor }}>
          {t(`${options[selectedIndex]}`)}
        </Button>
        <Button
          sx={{ ...buttonColor }}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper sx={{ backgroundColor: "white", zIndex: 9999 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {t(`${option}`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}
