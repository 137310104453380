import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { featuresApi } from "../../../../api/apiTanStack";
import { toast } from "react-toastify";

export function useResendPaymentLink() {
  const { t } = useTranslation(["common"]);
  const { mutate: resendPaymentLink, isLoading } = useMutation({
    mutationFn: ({ id }: { id: number }) =>
      featuresApi.bookingDetailsControllerResendPaymentLink({ id }),
    onSuccess: () => {
      toast.success(t(`toastMessages.resendPaymentLinkSuccess`));
    },
    onError: () => {
      toast.error(t(`toastMessages.resendPaymentLinkError`));
    },
  });

  return {
    resendPaymentLink,
    isLoading,
  };
}
