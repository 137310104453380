export type FormatPriceOptions = Pick<
  Intl.NumberFormatOptions,
  "currency" | "minimumFractionDigits" | "maximumFractionDigits"
>;

export const formatPrice = (
  price: number | null,
  language: string = "de-DE",
  options: FormatPriceOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: "EUR",
  }
): string => {
  if (price === null) return "";

  return new Intl.NumberFormat(language, {
    style: "currency",
    ...options,
  }).format(price);
};
