import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import logo_sbl from "./../../assets/logo_sbl.png";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { usePasswordResetConfirm } from "./hooks/useAuthQuery";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridLoginStyle,
  SBTextFieldStyle,
} from "../SBComponents/styles/SBStyles";
import SBFormHelperText from "../SBComponents/SBForms/SBFormHelperText";
import { useAuthStore } from "./hooks/authStore";

const ForgetPasswordCode = () => {
  const { t } = useTranslation(["common"]);
  const { email } = useAuthStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const passwordResetConfirm = usePasswordResetConfirm();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleTogglePassword = React.useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onSubmit = useCallback(
    (data: any) => {
      if (email) {
        passwordResetConfirm.mutate({
          email: email,
          password: data.password,
          code: data.code,
        });
      }
    },
    [email]
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          minWidth: "200px",
          maxWidth: "400px",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img
          src={logo_sbl}
          alt={"logo_sbl"}
          style={{ border: "0", width: "100%" }}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            marginTop: "50px",
            display: "grid",
            padding: "20px",
            gap: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <Box sx={{ ...SBTextFieldGridLoginStyle }}>
            <InputLabel sx={{ ...SBLabelStyle }}>{t("password")}</InputLabel>
            <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
              <TextField
                type={showPassword ? "text" : "password"}
                sx={{ ...SBTextFieldStyle }}
                fullWidth
                {...register("password", {
                  required: `${t("Password is required")}`,
                  minLength: {
                    value: 6,
                    message: `${t(
                      "Password should have at least 6 characters"
                    )}`,
                  },
                })}
                error={errors.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <SBFormHelperText
                error={errors.password && (errors.password.message as any)}
              />
            </FormControl>
          </Box>
          <Box sx={{ ...SBTextFieldGridLoginStyle }}>
            <InputLabel sx={{ ...SBLabelStyle }}>{t("code")}</InputLabel>
            <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
              <TextField
                sx={{ ...SBTextFieldStyle }}
                fullWidth
                {...register("code", {
                  required: `${t("code is required")}`,
                })}
                error={errors.code ? true : false}
              />
              <SBFormHelperText
                error={errors.code && (errors.code.message as any)}
              />
            </FormControl>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            {`${t("Change Password")}`}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ForgetPasswordCode;
