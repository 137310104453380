import React from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export interface IReturnDialogProps {
  open: boolean;
  selectedValue?: number;
  onClose: (id?: number) => void;
}

const ReturnDialog = ({ open, onClose, selectedValue }: IReturnDialogProps) => {
  const { t } = useTranslation(["common"]);
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(selectedValue);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {t(`RETURN_BEACH_CHAIR`)}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            height: "70px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t(`RETURN_BEACH_CHAIR_MODAL_TEXT`)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleOk} color="primary">
            {t("RETURN_BEACH_CHAIR")}
          </Button>
        </DialogActions>
      </Dialog>
    </I18nextProvider>
  );
};

export default ReturnDialog;
