import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CustomerResponseDto } from "../../../api-client/generated";
import { customerApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";

interface UseGetCustomer {
  customer?: CustomerResponseDto;
  isLoading: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<CustomerResponseDto, AxiosError>;
}

export const useGetCustomer = (
  customerId?: string | number
): UseGetCustomer => {
  const { data: customer, isLoading, isFetched, refetch } = useQuery<
    CustomerResponseDto,
    AxiosError
  >(
    ["customer", Number(customerId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return customerApi
        .customersControllerFindOne({ id: Number(customerId) ?? 0 })
        .then(({ data }) => data);
    },
    {
      enabled: !!customerId,
    }
  );

  return {
    customer,
    isLoading,
    isFetched,
    refetch,
  };
};
