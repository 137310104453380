import { IShortUrl, IShortUrlRow } from "../../models/shortUrl/IShortUrl";
import { dateToProperFormat } from "./dataConversion";

export const convertToId = (input: string): number => {
  let output = 0;
  for (var i = 0, len = input.length; i < len; i++) {
    output += input[i].charCodeAt(0);
  }
  return output;
};

export const shortUrlToRow = (shortUrl: IShortUrl): IShortUrlRow => {
  return {
    uid: shortUrl.uid,
    updatedAt: dateToProperFormat(
      shortUrl.updatedAt || new Date().toISOString()
    ),
    tagId: shortUrl.tagId,
    url: shortUrl.url,
    counter: shortUrl.counter,
  };
};
