import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useGetCurrentUser } from "../../../../hooks";

export const UserMenu: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { currentUser, isLoading } = useGetCurrentUser();

  return (
    <List key="listMenuUser" data-testid="listMenuUser">
      <ListItem
        sx={{
          "&.MuiListItem-root": {
            height: "70px",
            width: customStyle.menuWidth,
            backgroundColor: customStyle.secondaryColor,
            color: customStyle.textColor,
          },
        }}
        key="listItemMenuUser"
        data-testid="listItemMenuUser"
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "auto auto auto",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <CircularProgress sx={{ color: "white" }} />
          ) : (
            <>
              <Typography
                data-testid="listItemTextMenuUserEmail"
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {currentUser?.email}
              </Typography>
              <Typography
                data-testid="listItemTextMenuUserNames"
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {`${currentUser?.firstName} ${currentUser?.lastName}`}
              </Typography>
              <Typography
                data-testid="listItemTextMenuUserRoles"
                sx={{ fontWeight: "bold", fontSize: "14px" }}
              >
                {t(`roles.${currentUser?.roles[0]}`)}
              </Typography>
            </>
          )}
        </Box>
      </ListItem>
    </List>
  );
};
