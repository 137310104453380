import React, { useMemo } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  iconItemMainLabel,
  instantBookingsCard,
  instantBookingsCardTypography,
} from "../../../../utils/customStyles/globalStyles";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { useInstantBooking } from "../../../../utils/customHooks/useInstantBooking";
import { getInstantBookingPendingSelector } from "../../../../store/selectors/bookingsSelectors";
import { useSelector } from "react-redux";
import { IAvailableBeachChairs } from "../../../../models/availability/IAvailableBeachChairs";
import { IExtras } from "../../../../models/extras/IExtras";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import AddIcon from "@mui/icons-material/Add";
import {
  SBAutompleteTextFieldStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../../SBComponents/styles/SBStyles";
import SBNumberField from "../../../SBComponents/SBForms/SBNumberField";
import CalculateIcon from "@mui/icons-material/Calculate";
import SBCheckBox from "../../../SBComponents/SBForms/SBCheckBox";
import { BeachAccess, Storefront } from "@mui/icons-material";
import { collator } from "../../../../utils/comparator/comparator";
import SBSelect from "../../../SBComponents/SBForms/SBSelect";
import { useGetBeachChairsFilters } from "../../../beachChairList/hooks/useGetBeachChairsFilters";

export enum EInstantBooking {
  beachChairBooking = "beachChairBooking",
  extraBooking = "extraBooking",
  beachChairAndExtraBooking = "beachChairAndExtraBooking",
}

export interface IExtrasSet {
  id: number;
  name: string;
}

export interface IInstantBookingCard {
  type: EInstantBooking;
  storedBeachChairs?: IAvailableBeachChairs[];
  storedExtras?: IExtras[];
  set?: IExtrasSet[];
}

const InstantBookingCard = ({
  type,
  storedBeachChairs,
  storedExtras,
}: IInstantBookingCard) => {
  const pending = useSelector(getInstantBookingPendingSelector);
  const { t } = useTranslation(["common"]);
  const { control, onSubmit, watch, errors, setValue } = useInstantBooking(type);
  const watchModel = watch("model");
  const { data: filters, isLoading: filterIsLoading } =
    useGetBeachChairsFilters();
  const extras = React.useMemo(() => {
    return storedExtras
      ? storedExtras.map((extra) => {
        return {
          label: `${extra.name} ${new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(extra.price)}`,
          value: extra.id,
        };
      }).sort((a, b) => collator.compare(a.label, b.label))
      : [];
  }, [storedExtras]);

  const beachChairs = React.useMemo(() => {
    return storedBeachChairs && storedBeachChairs?.length > 0
      ? storedBeachChairs.flatMap((availability) =>
        availability.rowData
          .filter((beachChair) => beachChair.model === watchModel)
          .map((beachChair) => {
            return {
              label: `${beachChair.publicNumber} (${availability.name})`,
              value: beachChair.id,
            };
          })
          .sort((a, b) => collator.compare(a.label, b.label))
      )
      : [];
  }, [storedBeachChairs, t, watchModel]);

  const models = useMemo(() => {
    if (!storedBeachChairs) {
      return [];
    } else {
      const allRowData = storedBeachChairs?.flatMap((availability) =>
        availability.rowData.flatMap(item => item.model) ?? []);
      const modelSet = [...new Set(allRowData)];
      if (modelSet.length === 1) {
        setValue("model", modelSet[0]);
      }
      return modelSet;
    }

  }, [storedBeachChairs]);

  const isDisabled = useMemo(() => {
    let isDisabled = true;
    switch (type) {
      case "beachChairBooking": {
        isDisabled = watch("id") === 0 || watch("id") === undefined;
        break;
      }
      case "extraBooking": {
        isDisabled =
          watch("id") === 0 ||
          watch("id") === undefined ||
          watch("amount") <= 0;
        break;
      }
      case "beachChairAndExtraBooking": {
        isDisabled =
          watch("id") === 0 ||
          watch("id") === undefined ||
          watch("secondId") === 0 ||
          watch("secondId") === undefined ||
          watch("amount") <= 0;
        break;
      }
    }
    return isDisabled;
  }, [watch("id"), watch("secondId"), watch("amount"), type]);

  return (
    <Card sx={{ ...instantBookingsCard }}>
      <Typography sx={{ ...instantBookingsCardTypography }}>
        {t(`instantBooking.${type}.title`)}
      </Typography>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        {type === "beachChairBooking" ||
          type === "beachChairAndExtraBooking" ? (
          <SBSelect
            name="model"
            label={t("model")}
            noOptionLabel={t("noModel")}
            control={control}
            type={"edit"}
            icon={<BeachAccess />}
            isLoading={filterIsLoading}
            additionalOptions={models.length > 0 ? models.flatMap((option) => {
              return { id: option, value: `${t(`models.${option}`)}` };
            }).sort((a, b) => collator.compare(a.value, b.value)) : filters?.models.flatMap((option) => {
              return { id: option, value: `${t(`models.${option}`)}` };
            }).sort((a, b) => collator.compare(a.value, b.value))}
            defaultValue={0}
            error={errors?.model && t((errors.model as any)?.message)}
          />
        ) : null}

        <Box sx={{ ...SBTextFieldGridStyle }}>
          <Box sx={{ ...SBTextFieldIconStyle }}>
            {type === EInstantBooking.extraBooking ? (
              <Storefront />
            ) : (
              <BeachAccess />
            )}
          </Box>
          <Box sx={{ display: "grid" }}>
            <InputLabel
              sx={{
                ...SBLabelStyle,
                width: "100%",
                position: "static",
                transform: "none",
              }}
            >
              {t(
                `instantBooking.${type}.${type === EInstantBooking.extraBooking
                  ? `selectExtra`
                  : `select`
                }`
              )}
            </InputLabel>
            <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
              <Controller
                control={control}
                name="id"
                defaultValue={0}
                render={({ field }) => (
                  <Autocomplete
                    options={
                      type === EInstantBooking.extraBooking
                        ? extras
                        : beachChairs
                    }
                    getOptionLabel={(option) => `${option.label}`}
                    onChange={(_, data) => field.onChange(data?.value || 0)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label=""
                        placeholder={t("instantBooking.pleaseChoose")}
                        variant="outlined"
                        sx={{
                          ...SBTextFieldStyle,
                          ...SBAutompleteTextFieldStyle,
                        }}
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </Box>
        </Box>
        {type === EInstantBooking.beachChairAndExtraBooking && (
          <Box sx={{ ...SBTextFieldGridStyle }}>
            <Box sx={{ ...SBTextFieldIconStyle }}>
              <Storefront />
            </Box>
            <Box sx={{ display: "grid" }}>
              <InputLabel
                sx={{
                  ...SBLabelStyle,
                  width: "100%",
                  position: "static",
                  transform: "none",
                }}
              >
                {t(
                  `instantBooking.${type}.${type === EInstantBooking.beachChairAndExtraBooking
                    ? `selectExtra`
                    : `select`
                  }`
                )}
              </InputLabel>
              <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
                <Controller
                  control={control}
                  name="secondId"
                  render={({ field }) => (
                    <Autocomplete
                      options={extras}
                      getOptionLabel={(option) => `${option.label}`}
                      onChange={(_, data) => field.onChange(data?.value || 0)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t("instantBooking.pleaseChoose")}
                          variant="outlined"
                          label=""
                          sx={{
                            ...SBTextFieldStyle,
                            ...SBAutompleteTextFieldStyle,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
        )}
        {(type === EInstantBooking.extraBooking ||
          type === EInstantBooking.beachChairAndExtraBooking) && (
            <SBNumberField
              name="amount"
              label={t(`instantBooking.amount`)}
              control={control}
              type={type}
              isLoading={false}
              icon={<CalculateIcon />}
              defaultValue={1}
              error={errors?.amount && t((errors.amount as any)?.message)}
            />
          )}
        <SBCheckBox
          name="cashPayment"
          label={t(`instantBooking.cashCheckBox`)}
          control={control}
          type={type}
          isLoading={false}
          defaultValue={true}
          error={errors?.cashPayment && t((errors.cashPayment as any)?.message)}
        />
        <Box sx={{ display: "grid", alignContent: "center" }}>
          {pending.type ? (
            pending.type === type ? (
              <Box sx={{ ...iconItemMainLabel, height: "35px" }}>
                <CircularProgress />
              </Box>
            ) : (
              <SBLoadingButton
                label={t("bookings")}
                type="blue"
                icon={<AddIcon />}
                disabled={isDisabled}
              />
            )
          ) : (
            <SBLoadingButton
              label={t("bookings")}
              type="blue"
              icon={<AddIcon />}
              disabled={isDisabled}
            />
          )}
        </Box>
      </form>
    </Card>
  );
};

export default InstantBookingCard;
