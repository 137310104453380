import { ListItem, ListItemProps, Typography } from "@mui/material";
import React from "react";
import { Props } from "../../../utils/types/Props";

export const SearchResultItem: React.FC<
  Pick<ListItemProps, "onClick"> & Props
> = ({ children, onClick }) => {
  return (
    <ListItem
      sx={{
        "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Typography sx={{ width: "inherit" }}>{children as any}</Typography>
    </ListItem>
  );
};
