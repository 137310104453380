import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { locksApi } from "../../../api/apiTanStack";
import { OrderByDirection } from "../../../api-client/generated";

export const useGetAdminLocks = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "adminLocks",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .flatMap((filter) =>
            filter.id.includes("updatedAt")
              ? `filters[from]=${new Date(
                  (filter.value as any)[0] || undefined
                ).toISOString()}&filters[to]=${new Date(
                  (filter.value as any)[1] || undefined
                ).toISOString()}`
              : `filters[${filter.id}]=${filter.value}`
          )
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return locksApi
        .locksControllerFindMany(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
