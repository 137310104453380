import { AuthState, useAuthStore } from "./hooks/authStore";
import Auth from "./Auth";
import ForgetPasswordCode from "./ForgetPasswordCode";
import ResetPassword from "./ResetPassword";

const AuthWrapper = () => {
  const { authState } = useAuthStore();
  if (authState === AuthState.LOGIN) return <Auth />;
  if (authState === AuthState.FORGET) return <ForgetPasswordCode />;
  if (authState === AuthState.FIRST_LOG_IN) return <ResetPassword />;
  return <Auth />;
};

export default AuthWrapper;
