import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import {
  changeBeachChairReportCreatedAtPeriodRequest,
  changeBeachChairReportEndDateRequest,
  changeBeachChairReportStartDateRequest,
} from "../../../store/reducers/reportsReducer";
import {
  getSepaXMLEndDateSelector,
  getSepaXMLStartDateSelector,
} from "../../../store/selectors/reportSelectors";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BeachChairSplitButton from "../../splitButton/beachChairSplitButton";
import { Box, FormControlLabel, FormGroup, Switch } from "@mui/material";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models/range";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import dayjs, { Dayjs } from "dayjs";
import {
  SBComponentHeightStyle,
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";

const BeachChairReportsHeader = () => {
  const { t } = useTranslation(["common"]);
  const start = useSelector(getSepaXMLStartDateSelector);
  const end = useSelector(getSepaXMLEndDateSelector);
  const [datePicker, setDatePicker] = React.useState<DateRange<Dayjs>>([
    dayjs(new Date(start)),
    dayjs(new Date(end)),
  ]);
  const dispatch = useDispatch();
  const history = useHistory();

  const onDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    if (newDateRange && newDateRange[0] && newDateRange[1]) {
      setDatePicker(newDateRange);
      dispatch(
        changeBeachChairReportStartDateRequest({
          date: newDateRange[0].toISOString(),
        })
      );
      dispatch(
        changeBeachChairReportEndDateRequest({
          date: newDateRange[1].toISOString(),
        })
      );
    }
  };

  return (
    <SubPageHeader headerTitle={t("reports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("reports")}
          onClick={() => {
            history.push(`/reports`);
          }}
        />
        <SBHeaderTypography label={t("beachChairReports")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
          <MobileDateRangePicker
            onChange={onDateRangeChange}
            value={datePicker}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
                ...SBComponentHeightStyle,
              },
            }}
          />
        </LocalizationProvider>
        <BeachChairSplitButton />
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignContent: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{ color: customStyle.mainColor, fontWeight: "bold" }}
              control={
                <Switch
                  onChange={(e) => {
                    dispatch(
                      changeBeachChairReportCreatedAtPeriodRequest({
                        toggle: e.target.checked,
                      })
                    );
                  }}
                />
              }
              label={`${t("createdAtPeriod")}`}
            />
          </FormGroup>
        </Box>
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default BeachChairReportsHeader;
