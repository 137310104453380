import {
  IDestinationBeach,
  IDestinationCity,
  IDestinationRegion,
  IDestinationRows,
  IDestinationSection,
} from "../components/configuration/model/IDestinations";
import API from "./api";


const BASE_URL = `/destinations`;
const DESTINATIONS_SECTIONS_BASE_URL = `${BASE_URL}/sections`;
const DESTINATIONS_ROWS_BASE_URL = `${BASE_URL}/rows`;
const BASE_URL_FEATURES = `/features`;

export const getRows = (sectionId: number) =>
  API.get(`${DESTINATIONS_SECTIONS_BASE_URL}/${sectionId}/rows`);

export const putBeachChairsOrder = (rowId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put(`${DESTINATIONS_ROWS_BASE_URL}/${rowId}`, data, config);
};

export interface IResponse<T> {
  count: number;
  items: T[];
}

/**
 * Regions API CRUD
 */
export const getRegions = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<IDestinationRegion>>(
    `${BASE_URL}/regions?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getRegion = (id: number) =>
  API.get<IDestinationRegion>(`${BASE_URL}/regions/${id}`);

export const putRegion = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IDestinationRegion>(`${BASE_URL}/regions/${id}`, data, config);
};

export const postRegion = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IDestinationRegion>(`${BASE_URL}/regions`, data, config);
};

export const deleteRegion = (id: number) =>
  API.delete(`${BASE_URL}/regions/${id}`);

/**
 * Cities API CRUD
 */
export const getCities = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<IDestinationCity>>(
    `${BASE_URL}/cities?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getCity = (id: number) =>
  API.get<IDestinationCity>(`${BASE_URL}/cities/${id}`);

export const putCity = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IDestinationCity>(`${BASE_URL}/cities/${id}`, data, config);
};

export const postCity = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IDestinationCity>(`${BASE_URL}/cities`, data, config);
};

export const deleteCity = (id: number) =>
  API.delete(`${BASE_URL}/cities/${id}`);

/**
 * Beaches API CRUD
 */
export const getBeaches = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<IDestinationBeach>>(
    `${BASE_URL}/beaches?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getBeach = (id: number) =>
  API.get<IDestinationBeach>(`${BASE_URL}/beaches/${id}`);

export const putBeach = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IDestinationBeach>(`${BASE_URL}/beaches/${id}`, data, config);
};

export const postBeach = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IDestinationBeach>(`${BASE_URL}/beaches`, data, config);
};

export const deleteBeach = (id: number) =>
  API.delete(`${BASE_URL}/beaches/${id}`);

/**
 * Sections API CRUD
 */
export const getSections = () => API.get(`${DESTINATIONS_SECTIONS_BASE_URL}`);

export const getDestinationSections = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<IDestinationSection>>(
    `${BASE_URL}/sections?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getSection = (id: number) =>
  API.get<IDestinationSection>(`${BASE_URL}/sections/${id}`);

export const putSection = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IDestinationSection>(
    `${BASE_URL}/sections/${id}`,
    data,
    config
  );
};

export const postSection = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IDestinationSection>(`${BASE_URL}/sections`, data, config);
};

export const deleteSection = (id: number) =>
  API.delete(`${BASE_URL}/sections/${id}`);

export const postRow = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IDestinationRows>(`${BASE_URL}/rows`, data, config);
};

export const putRow = (id: number, name: string) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IDestinationRows>(
    `${BASE_URL}/rows/${id}`,
    { name: name },
    config
  );
};

export const deleteRow = (id: number) => {
  return API.delete<IDestinationRows>(`${BASE_URL_FEATURES}/rows/${id}`);
};
