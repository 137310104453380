import { Box } from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import { SBTimeFromToFieldsStyle } from "../styles/SBStyles";
import SBTimeField from "./SBTimeField";

export interface SBTimeFromToFieldsPrimary {
  nameFrom: string;
  nameTo: string;
  labelFrom: React.ReactNode;
  labelTo: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
}

export type SBTimeFromToFieldsType = SBTimeFromToFieldsPrimary;

const SBTimeFromToFields: React.FC<SBTimeFromToFieldsType> = ({
  nameFrom,
  nameTo,
  labelFrom,
  labelTo,
  isLoading,
  control,
  type,
  icon,
}) => {
  return (
    <Box sx={{ ...SBTimeFromToFieldsStyle }}>
      <SBTimeField
        name={nameFrom}
        label={labelFrom}
        control={control}
        type={type}
        icon={icon}
        isLoading={isLoading}
      />
      <SBTimeField
        name={nameTo}
        label={labelTo}
        control={control}
        type={type}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default SBTimeFromToFields;
