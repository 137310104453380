import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import {
  deleteShortUrl,
  getShortUrl,
  getShortUrls,
  postShortUrl,
  putShortUrl,
} from "../../api/shortUrlsApi";
import {
  deleteShortUrlRequest,
  deleteShortUrlSuccess,
  getShortUrlRequest,
  getShortUrlsRequest,
  getShortUrlsSuccess,
  getShortUrlSuccess,
  shortUrlsFailure,
  postShortUrlRequest,
  postShortUrlSuccess,
  putShortUrlRequest,
  putShortUrlSuccess,
} from "../reducers/shortUrlsReducer";
import history from "../../utils/history/history";
import { toast } from "react-toastify";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import i18next from "i18next";

function* getShortUrlsSaga(action: Action) {
  try {
    if (getShortUrlsRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getShortUrls,
        page,
        itemsPerPage,
        filterString,
        field,
        order
      );
      yield* put(
        getShortUrlsSuccess({
          shortUrls: data.redirects,
          page,
          itemsPerPage,
          count: data.count,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      shortUrlsFailure({
        error: e.message,
      })
    );
  }
}

function* getShortUrlSaga(action: Action) {
  try {
    if (getShortUrlRequest.match(action)) {
      const { uid } = action.payload;
      const { data } = yield* call(getShortUrl, uid);
      yield* put(getShortUrlSuccess({ shortUrl: data }));
    }
  } catch (e: any) {
    yield* put(
      shortUrlsFailure({
        error: e.message,
      })
    );
  }
}

function* postShortUrlSaga(action: Action) {
  try {
    if (postShortUrlRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postShortUrl, formData);
      yield* put(postShortUrlSuccess({ shortUrl: data }));
      yield* call([history, history.push], `/shortUrls`);
      toast.success(`${i18next.t(`toastMessages.PostShortUrlSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      shortUrlsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostShortUrlFailure`)}`);
  }
}

function* putShortUrlSaga(action: Action) {
  try {
    if (putShortUrlRequest.match(action)) {
      const { uid, formData } = action.payload;
      const { data } = yield* call(putShortUrl, uid, formData);
      yield* put(putShortUrlSuccess({ shortUrl: data }));
      if (uid !== formData.uid)
        yield* call([history, history.push], `/shortUrls`);
      toast.success(`${i18next.t(`toastMessages.PutShortUrlSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      shortUrlsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutShortUrlFailure`)}`);
  }
}

function* deleteShortUrlSaga(action: Action) {
  try {
    if (deleteShortUrlRequest.match(action)) {
      const { uid } = action.payload;
      yield* call(deleteShortUrl, uid);
      yield* put(deleteShortUrlSuccess({}));
      yield* call([history, history.push], `/shortUrls`);
      toast.success(`${i18next.t(`toastMessages.DeleteShortUrlSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      shortUrlsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteShortUrlFailure`)}`);
  }
}

function* ShortUrlsSaga() {
  yield* all([
    takeLatest(getShortUrlsRequest.type, getShortUrlsSaga),
    takeLatest(getShortUrlRequest.type, getShortUrlSaga),
    takeLatest(postShortUrlRequest.type, postShortUrlSaga),
    takeLatest(putShortUrlRequest.type, putShortUrlSaga),
    takeLatest(deleteShortUrlRequest.type, deleteShortUrlSaga),
  ]);
}

export default ShortUrlsSaga;
