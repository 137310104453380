import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import VendorHeader from "../vendorHeader/vendorHeader";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import { useBillingForm } from "./hooks/useCommissionForm";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBNumberField from "../../SBComponents/SBForms/SBNumberField";
import { CalendarMonth, ChairAlt, ChairRounded, Wifi, WifiOff } from "@mui/icons-material";

const Billing = () => {
    const { t } = useTranslation(["common"]);
    const history = useHistory();
    const { id, type } = useParams<{ id: string; type: string }>();
    const { control, onSubmit, onReset, errors } =
        useBillingForm(isNaN(+id) ? undefined : +id, type);
    const checkType = type === undefined ? "edit" : type;
    const isLoading = false;

    return (
        <SBMainComponentGridWrapper>
            <VendorHeader />
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <SBFormGridWrapper>
                    <SBColumnGridWrapper>
                        <SBCheckBox
                            name="billingEnabled"
                            label={t("billingEnabled")}
                            control={control}
                            type={checkType}
                            isLoading={isLoading}
                            error={errors?.billingEnabled && t((errors.billingEnabled as any)?.message)}
                        />
                        <SBNumberField
                            name="serviceFee.feePerChair"
                            label={t("serviceFee.feePerChair")}
                            control={control}
                            type={checkType}
                            icon={<ChairAlt />}
                            isLoading={isLoading}
                            error={
                                errors?.serviceFee &&
                                (errors.serviceFee as any)?.feePerChair &&
                                t(((errors.serviceFee as any)?.feePerChair as any)
                                    ?.message)
                            }
                        />
                        <SBNumberField
                            name="serviceFee.contractedChairs"
                            label={t("serviceFee.contractedChairs")}
                            control={control}
                            type={checkType}
                            icon={<ChairRounded />}
                            isLoading={isLoading}
                            error={
                                errors?.serviceFee &&
                                (errors.serviceFee as any)?.contractedChairs &&
                                t(((errors.serviceFee as any)?.contractedChairs as any)
                                    ?.message)
                            }
                        />
                        <SBNumberField
                            name="bookingDays.feePerDay"
                            label={t("bookingDays.feePerDay")}
                            control={control}
                            type={checkType}
                            icon={<CalendarMonth />}
                            isLoading={isLoading}
                            error={
                                errors?.bookingDays &&
                                (errors.bookingDays as any)?.feePerDay &&
                                t(((errors.bookingDays as any)?.feePerDay as any)
                                    ?.message)
                            }
                        />
                        <SBNumberField
                            name="commission.onlineRate"
                            label={t("commission.onlineRate")}
                            control={control}
                            type={checkType}
                            icon={<Wifi />}
                            isLoading={isLoading}
                            error={
                                errors?.commission &&
                                (errors.commission as any)?.onlineRate &&
                                t(((errors.commission as any)?.onlineRate as any)
                                    ?.message)
                            }
                        />
                        <SBNumberField
                            name="commission.offlineRate"
                            label={t("commission.offlineRate")}
                            control={control}
                            type={checkType}
                            icon={<WifiOff />}
                            isLoading={isLoading}
                            error={
                                errors?.commission &&
                                (errors.commission as any)?.offlineRate &&
                                t(((errors.commission as any)?.offlineRate as any)
                                    ?.message)
                            }
                        />


                    </SBColumnGridWrapper>
                </SBFormGridWrapper>
                <SBButtonActionGrid
                    editOnClick={() => history.push(`/vendors/billing/${+id}/edit`)}
                    resetOnClick={() => {
                        onReset();
                    }}
                    watchOnClick={() => history.push(`/vendors/billing/${+id}/show`)}
                    type={type}
                    isLoading={isLoading}
                />
            </form>

        </SBMainComponentGridWrapper>
    );
};

export default Billing;
