import React from "react";
import { Box } from "@mui/material";
import { SBMainGrid } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBMainComponentGridWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{
        ...SBMainGrid,
      }}
      data-testid="inside-card"
    >
      {children}
    </Box>
  );
};

export default SBMainComponentGridWrapper;
