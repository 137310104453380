import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import {
  changeSepaXMLEndDateRequest,
  changeSepaXMLStartDateRequest,
} from "../../../store/reducers/reportsReducer";
import {
  getSepaXMLEndDateSelector,
  getSepaXMLStartDateSelector,
} from "../../../store/selectors/reportSelectors";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useUpdateClosingReports } from "../closingReports/hooks/useCreateClosingReport";
import { Button } from "@mui/material";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models/range";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import {
  SBComponentHeightStyle,
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";
import dayjs, { Dayjs } from "dayjs";

const SepaXMLHeader = () => {
  const { t } = useTranslation(["common"]);
  const start = useSelector(getSepaXMLStartDateSelector);
  const end = useSelector(getSepaXMLEndDateSelector);
  const [datePicker, setDatePicker] = React.useState<DateRange<Dayjs>>([
    dayjs(new Date(start)),
    dayjs(new Date(end)),
  ]);
  const updateClosingReports = useUpdateClosingReports();
  const dispatch = useDispatch();
  const history = useHistory();

  const onDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    if (newDateRange && newDateRange[0] && newDateRange[1]) {
      setDatePicker(newDateRange);
      dispatch(
        changeSepaXMLStartDateRequest({
          date: newDateRange[0].toISOString(),
        })
      );
      dispatch(
        changeSepaXMLEndDateRequest({
          date: newDateRange[1].toISOString(),
        })
      );
    }
  };

  return (
    <SubPageHeader headerTitle={t("reports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("reports")}
          onClick={() => {
            history.push(`/reports`);
          }}
        />
        <SBHeaderTypography label={t("sepaXMLs")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
          <MobileDateRangePicker
            maxDate={dayjs(new Date())}
            onChange={onDateRangeChange}
            value={datePicker as any}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
                ...SBComponentHeightStyle,
              },
            }}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          sx={{
            gridColumn: 2,
            gridRow: 1,
            height: "35px",
            fontSize: "12px",
            color: "white",
            backgroundColor: customStyle.mainColor,
          }}
          data-testid="date-button"
          onClick={() => {
            updateClosingReports.mutateAsync({
              startDate: datePicker[0]!.toISOString(),
              endDate: datePicker[1]!.toISOString(),
              reportType: "SEPA_XML",
            });
          }}
        >
          {t("genereateSepaXML")}
        </Button>
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default SepaXMLHeader;
