import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getVendorSelector } from "../../store/selectors/vendorsSelectors";
import {
  fetchVendorRequest,
  pushVendorRequest,
} from "../../store/reducers/vendorsReducer";
import { useCallback, useEffect, useMemo } from "react";
import { getCurrentUserSelector } from "../../store/selectors/authSelectors";
import { getVendorIdSelector } from "../../store/selectors/adminSelectors";

export function useVendorExtraTextsForm() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector);
  const adminvendorId = useSelector(getVendorIdSelector);
  const vendorData = useSelector(getVendorSelector);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        extraText: yup.object().shape({
          emailText: yup.string().nullable(true),
          invoiceText: yup.string().nullable(true),
          cancellationText: yup.string().nullable(true),
        }),
      }),
    []
  );

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!vendorData && currentUser) {
      dispatch(
        fetchVendorRequest({
          vendorId: adminvendorId ? adminvendorId : currentUser.vendorId,
        })
      );
    }
    // eslint-disable-next-line
  }, [vendorData, currentUser, adminvendorId]);

  const onSubmit = useCallback(
    (formValues) => {
      if (currentUser && vendorData) {
        const extraText = {
          emailText: {
            DE: formValues.extraText.emailText,
            EN: "",
          },
          invoiceText: {
            DE: formValues.extraText.invoiceText,
            EN: "",
          },
          cancellationText: {
            DE: formValues.extraText.cancellationText,
            EN: "",
          },
        };
        const additionalFormValues = {
          ...vendorData,
          details: {
            ...vendorData.details,
            taxNumber: vendorData.details.taxNumber || "",
            whatsAppNumber: vendorData.details.whatsAppNumber || "",
            extraText: extraText,
          },
        };
        const { publicReference, ...vendorFormValues } = additionalFormValues;
        dispatch(
          pushVendorRequest({
            vendorId: adminvendorId ? adminvendorId : currentUser.vendorId,
            formData: vendorFormValues,
          })
        );
      }
    },
    // eslint-disable-next-line
    [currentUser, adminvendorId, vendorData]
  );

  useEffect(() => {
    if (vendorData) {
      setValue(
        "extraText.emailText",
        vendorData.details?.extraText?.emailText?.DE || ""
      );
      setValue(
        "extraText.invoiceText",
        vendorData.details?.extraText?.invoiceText?.DE || ""
      );
      setValue(
        "extraText.cancellationText",
        vendorData.details?.extraText?.cancellationText?.DE || ""
      );
    }
    // eslint-disable-next-line
  }, [vendorData]);

  const handleReset = useCallback(() => {
    setValue("extraText.emailText", "");
    setValue("extraText.invoiceText", "");
    setValue("extraText.cancellationText", "");
    // eslint-disable-next-line
  }, []);

  return {
    register,
    control,
    errors,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
