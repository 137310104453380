import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  CreateCustomerBodyDto as Variables,
  CustomerResponseDto as Response,
} from "../../../api-client/generated";
import { customerApi } from "../../../api/apiTanStack";

interface UpdateBooking {
  createCustomer: UseMutateFunction<
    AxiosResponse<Response>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useCreateCustomer = (): UpdateBooking => {
  const { mutate: createCustomer, isLoading } = useMutation<
    AxiosResponse<Response>,
    AxiosError,
    Variables
  >(["createCustomer"], (createCustomerBodyDto: Variables = {} as Variables) =>
    customerApi.customersControllerCreate({ createCustomerBodyDto })
  );

  return {
    createCustomer,
    isLoading,
  };
};
