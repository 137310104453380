import { create } from "zustand";
import { persist } from "zustand/middleware";

interface MenuStore {
  open: boolean;
  selectedMenuItem: string;
  changeMenuState: (toggle: boolean) => void;
  changeMenuItem: (selectedMenuItem: string) => void;
}

export const useMenuZustand = create<MenuStore>()(
  persist(
    (set) => {
      return {
        open: true,
        selectedMenuItem: "/",
        changeMenuState: (toggle: boolean) =>
          set((state) => ({
            ...state,
            open: toggle,
          })),
        changeMenuItem: (selectedMenuItem: string) =>
          set((state) => ({
            ...state,
            selectedMenuItem,
          })),
      };
    },
    {
      name: "useMenuZustandStore",
    }
  )
);
