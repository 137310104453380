import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  FeaturesApiBookingDetailsControllerCreateShortInvoiceRequest as Payload,
  InvoicesResponseDto,
} from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";
import { Buffer } from "buffer";
interface UseGetBooking {
  booking?: InvoicesResponseDto;
  isLoading: boolean;
  getShortInvoice: () => Promise<QueryObserverResult<Blob, AxiosError>>;
}

export const useGetShortInvoice = (payload: Payload): UseGetBooking => {
  const { isLoading, refetch: getShortInvoice } = useQuery<Blob, AxiosError>(
    ["getShortInvoice", payload.id],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return featuresApi
        .bookingDetailsControllerCreateShortInvoice({
          id: payload.id,
          buffer: payload.buffer ?? "true",
          timeZone: payload?.timeZone,
        })
        .then(({ data }) => {
          // Response type is invalid.
          // The encoded PDF file is returned instead of void
          // @ts-ignore
          const buff = Buffer.from(data.data);
          return new Blob([buff], { type: "application/pdf" });
        });
    },
    { enabled: false }
  );

  return {
    isLoading,
    getShortInvoice,
  };
};
