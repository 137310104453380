import { useQuery } from "@tanstack/react-query";
import { vendorStatisticsApi } from "../../../../api/apiTanStack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getVendorIdSelector } from "../../../../store/selectors/adminSelectors";

export const useStatistics = (dateStart: string, dateEnd: string) => {
  const { t } = useTranslation(["common"]);
  const selectedVendorId = useSelector(getVendorIdSelector);
  const dateStartFormat = dayjs(dateStart).format("YYYY-MM-DD");
  const dateEndFormat = dayjs(dateEnd).format("YYYY-MM-DD");
  return useQuery(
    [
      "useStatistics",
      String(dateStartFormat),
      String(dateEndFormat),
      Number(selectedVendorId),
    ],
    () =>
      vendorStatisticsApi
        .vendorStatsControllerGetSections({
          dateStart: dateStartFormat,
          dateEnd: dateEndFormat,
        })
        .then((res) => {
          return res.data.stats;
        }),
    {
      cacheTime: Infinity,
      enabled: !!selectedVendorId,
    }
  );
};
