import API from "./api";
import { ICustomer } from "../models/customers/ICustomer";

const BASE_URL = `/bookings/customers`;

export interface ICustomerResponse {
  count: number;
  items: ICustomer[];
}

export const getCustomer = (customerId: number) =>
  API.get<ICustomer>(`${BASE_URL}/${customerId}`);

export const getFilteredCustomers = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  order?: string
) =>
  API.get<ICustomerResponse>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      order ? "&orderDir=" + order : ""
    }${order ? "&orderBy=" + orderBy : ""}${filter && `&${filter}`}`
  );

export const searchCustomers = (searchPhrase: string) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.get<ICustomerResponse>(
    `${BASE_URL}?search=${searchPhrase}`,
    config
  );
};

export const postCustomer = (customer: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<ICustomer>(`${BASE_URL}`, customer, config);
};

export const putCustomer = (customerId: number, customer: ICustomer) => {
  return API.put<ICustomer>(`${BASE_URL}/${customerId}`, customer, {
    headers: { "Content-Type": "application/json" },
  });
};

export const deleteCustomer = (customerId: number) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.delete<void>(`${BASE_URL}/${customerId}`, config);
};
