import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useEffect } from "react";
import MarkdownDeFile from "../../assets/markdown/faq.de.md";
import MarkdownEnFile from "../../assets/markdown/faq.en.md";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { privacyRoot } from "../../utils/customStyles/globalStyles";

function Faq() {
  const { i18n } = useTranslation(["common"]);
  const [text, setText] = useState("");

  useEffect(() => {
    const userLanguage = i18n.language;

    fetch(userLanguage === "de" ? MarkdownDeFile : MarkdownEnFile)
      .then((res) => res.text())
      .then((text) => setText(text));
  }, [i18n.language, i18n.languages]);

  return (
    <Box sx={{ ...privacyRoot }}>
      <Card>
        <CardContent>
          <Typography variant="body2" gutterBottom>
            <ReactMarkdown>{text}</ReactMarkdown>
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

export default Faq;
