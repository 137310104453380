import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getLockRequest,
  postLockRequest,
  putLockRequest,
} from "../../store/reducers/lockReducer";
import { getLockSelector } from "../../store/selectors/lockSelectors";
import { useForm } from "react-hook-form";
import { batteryLevelMap } from "../../utils/conversions/lockConversion";
import { dateToProperFormat } from "../conversions/dataConversion";

export function useLockForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const data = useSelector(getLockSelector(id));

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        vendorId: yup.number(),
        id: yup.number().nullable(true),
        number: yup.string().required(t("locks.number") + t("isMandatory")),
        bcd: yup.string().nullable(true),
        mac: yup.string().nullable(true),
        password: yup.string().nullable(true),
        secondaryPassword: yup.string().nullable(true),
        status: yup.string().required(t("locks.status") + t("isMandatory")),
        batteryLevel: yup.string().nullable(true),
        updatedAt: yup.string().nullable(false),
        opened: yup.boolean().nullable(true),
        details: yup.object().shape({
          model: yup.string().required(t("locks.model") + t("isMandatory")),
          openCount: yup.number().nullable(true),
          buyDate: yup
            .string()
            .required(t("details.buyDate") + t("isMandatory")),
          manufactor: yup.string().nullable(true),
        }),
      }),
    [t]
  );

  const {
    control,
    watch,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!data && type !== "new") {
      dispatch(getLockRequest({ id: id }));
    }
    // eslint-disable-next-line
  }, [data, id, type]);

  useEffect(() => {
    setValue("vendorId", data?.vendorId || undefined);
    setValue("id", data?.id || undefined);
    setValue("number", data?.number || "");
    setValue("bcd", data?.bcd || "");
    setValue("mac", data?.mac || "");
    setValue("password", data?.password || undefined);
    setValue("secondaryPassword", data?.secondaryPassword || undefined);
    setValue("status", data?.status || "OFFLINE");
    setValue("opened", data?.opened || false);
    setValue("batteryLevel", `${batteryLevelMap(data?.batteryLevel || 0)} %`);
    setValue(
      "updatedAt",
      (data?.updatedAt ? dateToProperFormat(data?.updatedAt) : "") || ""
    );
    if(data?.details?.model === "Generic") {
      setValue("details.model", "Solar lock V1");
    } else {
      setValue("details.model", data?.details?.model || "Solar lock V1");
    }
    
    setValue("details.openCount", data?.details?.openCount || 0);
    setValue(
      "details.buyDate",
      data?.details?.buyDate ? new Date(data?.details?.buyDate) : new Date()
    );
    setValue("details.manufactor", data?.details?.manufactor || "");
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = useCallback(
    (formValues) => {
      const { id, batteryLevel, opened, updatedAt, ...myFormValues } =
        formValues;
      myFormValues.details.buyDate = new Date(
        myFormValues.details.buyDate
      ).toISOString();
      delete myFormValues.details.openCount;
      if (type === "new") {
        myFormValues.batteryLevel = 4200;
        myFormValues.opened = false;
        dispatch(postLockRequest({ formData: myFormValues }));
      } else {
        const { batteryLevel, opened, ...putMyFormValues } = myFormValues;
        dispatch(putLockRequest({ id: id, formData: putMyFormValues }));
      }
    },
    // eslint-disable-next-line
    [id, type]
  );

  const handleReset = useCallback(() => {
    setValue("vendorId", undefined);
    setValue("id", undefined);
    setValue("number", "");
    setValue("bcd", undefined);
    setValue("mac", undefined);
    setValue("password", undefined);
    setValue("secondaryPassword", undefined);
    setValue("status", "OFFLINE");
    setValue("opened", false);
    setValue("batteryLevel", `0 %`);
    setValue("updatedAt", "");
    setValue("details.model", "Solar lock V1");
    setValue("details.openCount", 0);
    setValue("details.buyDate", new Date());
    setValue("details.manufactor", "");
    // eslint-disable-next-line
  }, []);

  return {
    control,
    watch,
    errors,
    setValue,
    getValues,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
