import moment from "moment";
import "moment/locale/de";
import React from "react";
import Timeline, {
  DateHeader,
  ReactCalendarGroupRendererProps,
  SidebarHeader,
  TimelineHeaders,
} from "react-calendar-timeline";
import "react-calendar-timeline/lib/Timeline.css";
import { I18nextProvider, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getGridViewRequest } from "../../../../store/reducers/availabilityReducer";
import {
  getAvailabilitiesCurrentDate,
  getBeachChairFilters,
  getGridDataSelector,
} from "../../../../store/selectors/availabilitySelector";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import i18n from "../../../../utils/i18n/i18n";
import { useReactToPrint } from "react-to-print";
import { Box, Button, Dialog, DialogActions } from "@mui/material";
import dayjs from "dayjs";

export interface IDialogAvailabilitiesBeachChairsGridPrintProps {
  open: boolean;
  setGridLayout: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDate: Date;
  option: boolean;
}

const addSpaces = (groups: Array<{ id: number; title: string }>) => {
  let addLines = 22;
  const times = Math.abs(groups.length / addLines);
  for (let i = 1; i <= times; i++) {
    addLines = i === 1 ? 19 : 22;
    groups.splice(
      i * addLines + i + 1,
      0,
      { id: i * addLines * 992302, title: "" },
      { id: i * addLines * 992302 + 1, title: "" }
    );
  }
};

const detect = (id: number): boolean => `${id}`.includes("00");

const DialogAvailabilitiesBeachChairsGridPrint = ({
  open,
  setGridLayout,
  selectedDate,
  option,
}: IDialogAvailabilitiesBeachChairsGridPrintProps) => {
  moment.locale("de");
  const { t } = useTranslation(["common"]);
  const { sectionId } = useParams<{ sectionId: string }>();
  const dispatch = useDispatch();
  const { groups, items } = useSelector(getGridDataSelector);
  const date = useSelector(getAvailabilitiesCurrentDate);
  const filters = useSelector(getBeachChairFilters);
  const printRef = React.useRef<HTMLDivElement>(null);
  const [startDateToDisplay, setStartDateToDisplay] = React.useState<number>(0);
  const [endDateToDisplay, setEndDateToDisplay] = React.useState<number>(0);

  React.useEffect(() => {
    addSpaces([...groups]);
  }, [groups]);

  React.useEffect(() => {
    const dayJSselectedDate = dayjs(selectedDate).toDate();
    setStartDateToDisplay(
      option
        ? moment(dayJSselectedDate).startOf("month").valueOf()
        : moment(dayJSselectedDate).startOf("week").valueOf()
    );
    setEndDateToDisplay(
      option
        ? moment(dayJSselectedDate).endOf("month").valueOf()
        : moment(dayJSselectedDate).endOf("week").valueOf()
    );
  }, [selectedDate, option]);

  const handleClose = React.useCallback(() => {
    setGridLayout(false);
    // eslint-disable-next-line
  }, []);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "react-calendar-timeline",
  });

  React.useEffect(() => {
    if (groups.length === 0 && items.length === 0) {
      dispatch(
        getGridViewRequest({
          sectionId: +sectionId,
          date: date,
          row: filters.row,
          model: filters.model,
          free: filters.free,
          hasLock: filters.hasLock,
        })
      );
    }
  }, [sectionId, groups, items, dispatch, date, filters]);

  const groupRenderer = ({
    group,
  }: ReactCalendarGroupRendererProps<{
    id: number;
    title: string;
  }>) => {
    const row = !detect(group.id)
      ? {
        backgroundColor: "gray",
        color: customStyle.whiteColor,
        marginLeft: "-5px",
        marginRight: "-5px",
        paddingLeft: "5px",
      }
      : {
        paddingLeft: "5px",
      };
    return (
      <Box sx={row}>
        <Box
          sx={{
            color: customStyle.mainColor,
            fontWeight: "bolder",
          }}
        >
          {detect(group.id) && group.title.split("/")[0]}{" "}
        </Box>
        {!detect(group.id)
          ? group.title
          : t(`shortModels.${group.title.split("/")[1]}`)}
      </Box>
    );
  };

  const itemRenderer = ({ item, getItemProps }: any) => {
    return (
      <Box {...getItemProps()}>
        <Box
          sx={{
            backgroundColor: customStyle.secondaryColor,
            textAlign: "center",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "",
          }}
        >
          {item.title}
        </Box>
      </Box>
    );
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Dialog
        transitionDuration={0}
        open={open}
        fullWidth
        maxWidth="xl"
        scroll="paper"
        sx={{
          ".MuiDialog-scrollPaper": {
            overflowY: "scroll",
            boxSizing: "content-box",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: customStyle.scrollColor,
            },
          },
        }}
      >
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
              const zoom = endDateToDisplay - startDateToDisplay;
              setStartDateToDisplay(startDateToDisplay + zoom);
              setEndDateToDisplay(endDateToDisplay + zoom);
              setTimeout(() => {
                handlePrint();
                setStartDateToDisplay(startDateToDisplay);
                setEndDateToDisplay(endDateToDisplay);
              }, 1);
            }}
            color="primary"
            sx={{
              fontSize: "12px",
              height: "35px",
              color: "white",
              backgroundColor: customStyle.mainColor,
            }}
          >
            {t("print")}
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            sx={{
              fontSize: "12px",
              height: 35,
              color: "red",
              border: "1px solid",
              borderColor: "red",
            }}
          >
            {t("cancel")}
          </Button>
        </DialogActions>
        <Box
          sx={{
            alignSelf: "center",
            width: "287mm",
            height: "auto",
            margin: "5mm",
            marginTop: "10mm",
          }}
          ref={printRef}
        >
          {groups.length !== 0 && (
            <Box className="box">
              <Timeline
                canMove={false}
                canResize={false}
                lineHeight={31}
                groups={groups}
                itemHeightRatio={0.9}
                items={items}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                horizontalLineClassNamesForGroup={(group) =>
                  !detect(group.id)
                    ? group.title === ""
                      ? ["normal"]
                      : ["highlight"]
                    : ["normal"]
                }
                sidebarWidth={150}
                visibleTimeStart={startDateToDisplay}
                visibleTimeEnd={endDateToDisplay}
              >
                <TimelineHeaders className="headerTimeline">
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return (
                        <div
                          {...getRootProps()}
                          className="headerTimeline"
                        ></div>
                      );
                    }}
                  </SidebarHeader>
                  <DateHeader unit="primaryHeader" className="headerTimeline" />
                  <DateHeader unit="day" labelFormat="DD" />
                </TimelineHeaders>
              </Timeline>
            </Box>
          )}
        </Box>
      </Dialog>
    </I18nextProvider>
  );
};

export default DialogAvailabilitiesBeachChairsGridPrint;
