import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { SectionDetailsDto } from "../../../../api-client/generated";
import { SBColor } from "../../../SBComponents/styles/SBStyles";

export const AvailabilityCard: React.FC<{
  availability: SectionDetailsDto;
}> = ({ availability }) => {
  const history = useHistory();

  const { type } = useParams<{ type: string }>();
  const { t } = useTranslation(["common"]);

  return (
    <Box
      sx={{
        padding: 4,
        backgroundColor: "white",
        width: "250px",
        height: 200,
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        borderRadius: "5px",
        boxShadow: "0 0 11px rgba(33,33,33,.2)",
        "&:hover": { boxShadow: `0 0 11px ${SBColor.blue}` },
      }}
      data-testid="availabilities-box"
      key={`availabilities-${availability.id}`}
      onClick={() =>
        history.push(`/availabilities/${type}/${availability.id}/beachChairs`)
      }
    >
      <Typography
        sx={{
          color: customStyle.grayColor,
          fontWeight: "bolder",
          fontSize: 20,
          margin: 0,
        }}
        data-testid="availabilities-title"
      >
        {availability.name}
      </Typography>

      <Typography
        data-testid="availabilities-count"
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 0.5,
          flex: 1,
          my: 2,
          fontWeight: "bolder",
          fontSize: 40,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>{availability.booked}</span>
        <span>/</span>
        <span style={{ color: "orange" }}>{availability.partly}</span>
        <span>/</span>
        <span>{availability.total}</span>
      </Typography>

      <Button
        fullWidth
        data-testid="availabilities-button"
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: customStyle.mainColor,
          textTransform: "none",
        }}
      >
        {t("chooseBeachChair")}
      </Button>
    </Box>
  );
};
