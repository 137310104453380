import { IFilterData } from "./../../models/dataTable/IFilterData";
import { createSelector } from "reselect";
import { beachChairToFormData } from "../../utils/conversions/beachChairToFormData";
import { beachChairToRow } from "../../utils/conversions/beachChairToRow";
import {
  getAllBeachChairs,
  getBeachChairById,
  getBeachChairPages,
  getCalendarBeachChairById,
} from "../reducers/beachChairsReducer";

import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.beachChairs.pending;

const getBeachChairsCount = (state: AppState) => state.beachChairs.count;

const getFilterData = (state: AppState) => state.beachChairs.filterData;

const getFilter = (state: AppState) => state.beachChairs.filter;

const getError = (state: AppState) => state.beachChairs.error;

const getBeachChairCalendarMonth = (state: AppState) =>
  state.beachChairs.beachChairCalendarMonth;

export const getBeachChairsSelector = createSelector(
  getAllBeachChairs,
  (beachChairs) => beachChairs
);

export const getBeachChairsCountSelector = createSelector(
  getBeachChairsCount,
  (count) => count
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getFilterDataSelector = createSelector(
  getFilterData,
  (filterData) => filterData
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getBeachChairSelector = (id: number) =>
  createSelector(getAllBeachChairs, (beachChairs) => {
    const data = beachChairs.find((beachChair) => beachChair.id === id);
    return data ? beachChairToFormData(data) : undefined;
  });

export const getBeachChairPage = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getBeachChairPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.dataIds
        .map((id) => getBeachChairById(state, id)!)
        .map(beachChairToRow)
    );
  });

export const getFiltersBeachChairsSelector = () =>
  createSelector(getFilter, (filters) => filters && filters.filter);

export const getBeachChairCalendarMonthSelector = createSelector(
  getBeachChairCalendarMonth,
  (month) => month
);

export const getBeachChairCalendarSelector = (key: string) =>
  createSelector(getState, (state) => getCalendarBeachChairById(state, key));
