import { Box, CircularProgress, List as MuiList } from "@mui/material";
import { ListProps as MuiListProps } from "@mui/material/List/List";
import React, { forwardRef } from "react";

interface ListProps extends MuiListProps {
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
}

export const InfiniteScrollList: React.FC<ListProps> = forwardRef(
  (
    { hasNextPage, isFetchingNextPage, fetchNextPage, children, ...listProps },
    ref
  ) => {
    const ladMoreDivRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
      if (!hasNextPage || !ladMoreDivRef.current || isFetchingNextPage) return;

      const observer = new IntersectionObserver(
        (entries) => entries[0].isIntersecting && fetchNextPage(),
        { threshold: 1 }
      );

      observer.observe(ladMoreDivRef.current);
      return () => observer.disconnect();
    }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

    return (
      <MuiList {...listProps} ref={ref}>
        {children}
        {hasNextPage && (
          <div ref={ladMoreDivRef}>
            {isFetchingNextPage && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding={2}
              >
                <CircularProgress />
              </Box>
            )}
          </div>
        )}
      </MuiList>
    );
  }
);
