import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsRequest } from "../../store/reducers/vendorsReducer";
import { getLocationSelector } from "../../store/selectors/vendorsSelectors";

export function useLocationData(id?: number) {
  const dispatch = useDispatch();
  const locationData = useSelector(getLocationSelector(id));
  React.useEffect(() => {
    if (!locationData && id) {
      dispatch(getLocationsRequest({}));
    }
    // eslint-disable-next-line
  }, [locationData, id]);
  return {
    locationData,
  };
}
