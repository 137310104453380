import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router";
import { useDeleteVendorUser, useGetVendorUsers } from "../hooks";
import DeleteDialog from "../../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SBTable from "../../SBTable/SBTable";
import VendorUserListHeader from "../vendorUserListHeader/vendorUserListHeader";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";
import VisibilityIcon from "@mui/icons-material/Visibility";

export const VendorUsersList: React.FC<
  RouteComponentProps<{ userId?: string }>
> = ({ history, match }) => {
  const { t } = useTranslation(["common"]);
  const deleteVendorUser = useDeleteVendorUser();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<string>("0");
  const openDeleteVendorUserModal = (customerId: string) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(customerId);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("vendor_users");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetVendorUsers(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleDeleteCustomer = (userId: number | string) => {
    deleteVendorUser.mutateAsync({ id: `${userId}` }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `#`,
        maxSize: 80,
      },
      {
        accessorKey: "firstName",
        header: `${t("firstname")}`,
        maxSize: 50,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/vendor_users/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "lastName",
        header: `${t("lastname")}`,
        maxSize: 50,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/vendor_users/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "email",
        header: `${t("email")}`,
        maxSize: 50,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/vendor_users/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "vendorId",
        accessorFn: (originalRow: any) =>
          `${originalRow.vendorId} ${originalRow.vendorName ? `- ${originalRow.vendorName}` : ""
          }`,
        header: `${t("vendorName")}`,
        maxSize: 120,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("allVendorUsers")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const customerId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (customerId) {
                  history.push(`/vendor_users/${customerId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (customerId) {
                  history.push(`/vendor_users/${customerId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (customerId) {
                  openDeleteVendorUserModal(customerId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history, match]
  );

  return (
    <SBMainListWrapper>
      <VendorUserListHeader />
      <SBTable
        columnVisibilityStoreName="VendorUsersListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="vendorUsers"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteCustomer(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};
