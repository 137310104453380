import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  useLocationCreateMutation,
  useLocationUpdateMutation,
  useLocationQuery,
} from "../tanstackHooks/useLocationsTanStack";

export function useLocationForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const { data, isLoading, isError } = useLocationQuery(id);
  const { locationUpdateMutation } = useLocationUpdateMutation();
  const { locationCreateMutation } = useLocationCreateMutation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        address: yup.object().shape({
          zip: yup.string(),
          city: yup.string(),
          street: yup.string(),
          country: yup.string(),
        }),
        checkoutTime: yup.string(),
        name: yup.string(),
        description: yup.string(),
        geoJson: yup
          .string()
          .test(
            "test-type-coordinates",
            `${t("destinations.type")} ${t("destinations.coordinates")} ${t(
              "isMandatory"
            )}`,
            (value) =>
              value
                ? value?.includes("type") && value?.includes("coordinates")
                : false
          )
          .required(t("destinations.geoJson") + t("isMandatory")),
        details: yup.object().shape({
          openingHours: yup
            .array()
            .of(
              yup.object().shape({
                closing: yup.string().nullable(true),
                opening: yup.string().nullable(true),
                weekday: yup.number(),
              })
            )
            .nullable(true),
          exceptionOpeningHours: yup
            .array()
            .of(
              yup.object().shape({
                closing: yup.string().nullable(true),
                opening: yup.string().nullable(true),
                weekday: yup.number(),
              })
            )
            .nullable(true),
        }),
        leadTimeHours: yup
          .number()
          .notRequired()
          .typeError(t("numberTypeError"))
          .min(0, t("minHour"))
          .nullable(true)
          .transform((_, val) => (val !== "" ? +val : null)),
        entranceName: yup.string(),
        beachId: yup.number(),
        seasonStart: yup
          .string()
          .nullable(true)
          .test((value) => {
            if (value) {
              return /^([0-2][0-9]|(3)[0-1])-(((0)[1-9])|((1)[0-2]))$/.test(
                value
              );
            }
            return true;
          }),
        seasonEnd: yup
          .string()
          .nullable(true)
          .test((value) => {
            if (value) {
              return /^([0-2][0-9]|(3)[0-1])-(((0)[1-9])|((1)[0-2]))$/.test(
                value
              );
            }
            return true;
          }),
        priceText: yup.object().shape({
          de: yup.string().nullable(),
          en: yup.string().nullable(),
        }),
        terms: yup.object().shape({
          de: yup.string().nullable(),
          en: yup.string().nullable(),
        }),
      }),
    [t]
  );
  //
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (formValues) => {
      formValues.geoJson = JSON.parse(formValues.geoJson || {});
      formValues.seasonStart =
        formValues.seasonStart === "" ? null : formValues.seasonStart;
      formValues.seasonEnd =
        formValues.seasonEnd === "" ? null : formValues.seasonEnd;
      if (type === "new") {
        (formValues as any).foodDelivery = false;
        (formValues as any).mapImageUrl = "";
        (formValues as any).details.openingHours[0].weekday = 0;
        (formValues as any).details.openingHours[1].weekday = 1;
        (formValues as any).details.openingHours[2].weekday = 2;
        (formValues as any).details.openingHours[3].weekday = 3;
        (formValues as any).details.openingHours[4].weekday = 4;
        (formValues as any).details.openingHours[5].weekday = 5;
        (formValues as any).details.openingHours[6].weekday = 6;
        (formValues as any).details.exceptionOpeningHours = [];
        locationCreateMutation.mutate({ createLocationDto: formValues });
      } else {
        (formValues as any).details.openingHours[0].weekday = 0;
        (formValues as any).details.openingHours[1].weekday = 1;
        (formValues as any).details.openingHours[2].weekday = 2;
        (formValues as any).details.openingHours[3].weekday = 3;
        (formValues as any).details.openingHours[4].weekday = 4;
        (formValues as any).details.openingHours[5].weekday = 5;
        (formValues as any).details.openingHours[6].weekday = 6;
        locationUpdateMutation.mutate({ id, updateLocationDto: formValues });
      }
    },
    // eslint-disable-next-line
    [id, type]
  );

  useEffect(() => {
    if (data !== undefined) {
      setValue(
        "address",
        data?.address || { city: "", zip: "", country: "", street: "" }
      );
      setValue("name", data?.name || "");
      setValue("description", data?.description || "");
      setValue("geoJson", `${JSON.stringify(data?.geoJson || {})}`);
      setValue("details.openingHours", data?.details?.openingHours || []);
      setValue(
        "details.exceptionOpeningHours",
        data?.details?.exceptionOpeningHours || []
      );
      setValue("leadTimeHours", data?.leadTimeHours);
      setValue("entranceName", data?.entranceName || "");
      setValue("beachId", data?.beachId || 0);
      setValue("seasonStart", data?.seasonStart || "");
      setValue("seasonEnd", data?.seasonEnd || "");
      setValue("checkoutTime", data?.checkoutTime || "");
      setValue("priceText.de", (data?.priceText as any)?.de || "");
      setValue("priceText.en", (data?.priceText as any)?.en || "");
      setValue("terms.de", (data as any)?.terms?.de || "");
      setValue("terms.en", (data as any)?.terms?.en || "");
    }
    // eslint-disable-next-line
  }, [data]);

  const handleReset = useCallback(() => {
    setValue("address", { city: "", zip: "", country: "", street: "" });
    setValue("name", "");
    setValue("description", "");
    setValue("geoJson", "");
    setValue("details.openingHours", []);
    setValue("details.exceptionOpeningHours", []);
    setValue("leadTimeHours", 0);
    setValue("entranceName", "");
    setValue("beachId", 0);
    setValue("seasonStart", "");
    setValue("seasonEnd", "");
    setValue("checkouttime", "");
    setValue("priceText.de", "");
    setValue("priceText.en", "");
    setValue("terms.de", "");
    setValue("terms.en", "");
    // eslint-disable-next-line
  }, []);

  return {
    register,
    setValue,
    control,
    errors,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
    isLoading,
    isError,
  };
}
