import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import FeedIcon from "@mui/icons-material/Feed";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  TextField,
  debounce,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BookingDetailsResponseDto,
  CreateCustomerBodyDto,
  CustomerResponseDto,
} from "../../../../api-client/generated";
import { useGetCurrentUser, UserRoles } from "../../../../hooks";
import { EBookingCustomerStage } from "../../../../models/bookings/EBookingCustomerStage";
import { toast } from "react-toastify";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import {
  useCreateCustomer,
  useCustomerForm,
  useGetSearchCustomers,
  useUpdateCustomer,
} from "../../../customers";
import { CustomerFormValues } from "../../../customers/components";
import TabPanel from "../../../tabPanel/tabPanel";
import { useAssignCustomerToBooking, useGetBooking } from "../hooks";
import SBSelect from "../../../SBComponents/SBForms/SBSelect";
import SBTextField from "../../../SBComponents/SBForms/SBTextField";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useQueryClient } from "@tanstack/react-query";

export interface BookingCustomerProps {
  bookingId: number;
  type: string;
  booking?: BookingDetailsResponseDto;
}

const a11yProps = (index: any) => {
  return {
    id: `skj-tab-${index}`,
    "aria-controls": `skj-tabpanel-${index}`,
  };
};

const BookingCustomer = ({
  bookingId,
  type,
  booking,
}: BookingCustomerProps) => {
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const { userRoles } = useGetCurrentUser();
  const isAdmin = userRoles.includes(UserRoles.SUPER_ADMIN);
  const [stage, setStage] = useState<EBookingCustomerStage>(
    type === "show"
      ? EBookingCustomerStage.DISABLE
      : EBookingCustomerStage.PRESELECT_CUSTOMER
  );
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [searchPhraseDebounced, setSearchPhraseDebounced] = useState("");
  const { t } = useTranslation(["common"]);
  const { updateCustomer, isLoading: isUserUpdating } = useUpdateCustomer();
  const { createCustomer, isLoading: isUserCreating } = useCreateCustomer();
  const { assignCustomerToBooking, isLoading: isUserAssigning } =
    useAssignCustomerToBooking();
  const queryClient = useQueryClient();
  const debouncedOnChange = debounce((value: string) => {
    setSearchPhraseDebounced(value);
  }, 500);

  useEffect(() => {
    debouncedOnChange(searchPhrase as string);
    return () => {
      debouncedOnChange.clear();
    };
  }, [searchPhrase, debouncedOnChange]);

  const { data: customers } = useGetSearchCustomers(
    searchPhraseDebounced,
    isSearchActive && !!searchPhraseDebounced.trim().length
  );

  const { control, setValue, isValid, handleSubmit, watch, errors } =
    useCustomerForm(booking?.customer);

  const isDefaultUser = !!watch("default");

  const [tabValue, setTabValue] = React.useState<number>(0);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const onSubmit = (formValues: CustomerFormValues) => {
    // Block submitting the form when booking is not fetched yet
    // and when the user is not an admin and wants to create or update a default customer
    if (!booking || (isDefaultUser && !isAdmin)) {
      return;
    }

    // Prevents overriding the default customer
    const { default: defaultFlat, ...safeCustomerDetails } = formValues;

    if (isNewCustomer) {
      createCustomer(safeCustomerDetails as CreateCustomerBodyDto, {
        onSuccess: async ({ data }) => {
          toast.success(t(`toastMessages.PushCustomerSuccess`));

          await assignCustomerToBooking(
            { customerId: data.id, bookingId },
            {
              onSuccess: async () => {
                toast.success(
                  t(`toastMessages.BookingsCustomersChangedSuccess`)
                );
                queryClient.invalidateQueries(["booking"]);
              },
              onError: () => {
                toast.error(t(`toastMessages.BookingsCustomersChangedError`));
              },
            }
          );
        },
      });
    } else {
      updateCustomer(
        {
          id: Number(booking.customerId),
          ...safeCustomerDetails,
        },
        {
          onSuccess: async () => {
            toast.success(t(`toastMessages.PutCustomerSuccess`));
            queryClient.invalidateQueries(["booking"]);
          },
        }
      );
    }
  };

  const onSearchResultClick = (customer: CustomerResponseDto) => {
    setStage(EBookingCustomerStage.EDIT_CUSTOMER);
    setIsNewCustomer(false);

    assignCustomerToBooking(
      { customerId: customer.id, bookingId },
      {
        onSuccess: async () => {
          toast.success(t(`toastMessages.BookingsCustomersChangedSuccess`));
          queryClient.invalidateQueries(["booking"]);
        },
        onError: () => {
          toast.error(t(`toastMessages.BookingsCustomerChangedFailure`));
        },
      }
    );
  };

  const onNewCustomerClick = () => {
    setSearchPhrase("");
    setIsNewCustomer(true);
    setStage(EBookingCustomerStage.EDIT_CUSTOMER);

    setValue("firstName", "");
    setValue("lastName", "");
    setValue("address.city", "");
    setValue("address.country", "");
    setValue("address.street", "");
    setValue("address.zip", "");
    setValue("salutation", "MR");
    setValue("telephone", "");
    setValue("email", "");
    setValue("bankDetails.iban", "");
    setValue("bankDetails.bic", "");
    setValue("bankDetails.accountHolder", "");
    setValue("bankDetails.bankName", "");
    setValue("default", false);
  };

  const formDisabled =
    type === "show" ||
    [
      EBookingCustomerStage.PRESELECT_CUSTOMER,
      EBookingCustomerStage.DISABLE,
      EBookingCustomerStage.SEARCH_CUSTOMER,
    ].includes(stage) ||
    isUserUpdating ||
    isUserCreating ||
    isUserAssigning ||
    isDefaultUser; // Default user can't be edited
  const isFormDisabled = formDisabled ? "show" : "edit";
  const disabledShow = type === "show";

  const tableData: CustomerResponseDto[] = useMemo(() => {
    if (customers?.items.length === 0) {
      return [];
    }
    return customers?.items.map((customer) => customer) ?? [];
  }, [customers]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
        setStage(EBookingCustomerStage.PRESELECT_CUSTOMER);
      }}
      onKeyDown={(e) => {
        e.key === "Enter" && e.preventDefault();
      }}
    >
      <Box
        sx={{
          padding: "10px",
          display: "grid",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={(e, newTabIndex) => setTabValue(newTabIndex)}
          aria-label="Booking Customer Tabs"
          sx={{
            ".MuiTab-root": {
              width: "20%",
              color: customStyle.mainColor,
              fontWeight: "bold",
              borderRight: `2px solid ${customStyle.mainColor}`,
              boxShadow: "none",
              borderTop: `2px solid ${customStyle.mainColor}`,
              borderBottom: `2px solid ${customStyle.mainColor}`,
            },
            ".MuiTabs-indicator": {
              height: "0px",
              backgroundColor: customStyle.secondaryColor,
            },
            ".Mui-selected": {
              color: `${customStyle.whiteColor}!important`,
              backgroundColor: customStyle.mainColor,
            },
            ".MuiButtonBase-root:first-of-type": {
              borderLeft: `2px solid ${customStyle.mainColor}`,
            },
          }}
          data-testid="customer-tabs"
        >
          <Tab
            label={t("submenuCustomerInfo")}
            {...a11yProps(0)}
            data-testid="tab-0"
          />
          <Tab
            label={t("submenuCustomerBankDetails")}
            {...a11yProps(1)}
            data-testid="tab-1"
          />
        </Tabs>
        <Box
          sx={{
            marginTop: "20px",
            marginBottom: "20px",
            width: "100%",
            height: "100%",
            display: "grid",
            gridTemplateColumns: "60% 40%",
          }}
          border={0}
        >
          <Box
            sx={{
              height: "100%",
            }}
          >
            <TabPanel value={tabValue} index={0}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "50% 50%",
                  gap: "10px",
                }}
              >
                <Box>
                  <SBSelect
                    name="salutation"
                    label={t("salutation")}
                    control={control}
                    type={isFormDisabled}
                    icon={<AccountCircleIcon />}
                    additionalOptions={[
                      { id: "MR", value: `${t(`salutations.MR`)}` },
                      { id: "MS", value: `${t(`salutations.MS`)}` },
                      { id: "MX", value: `${t(`salutations.MX`)}` },
                      { id: "FM", value: `${t(`salutations.FM`)}` },
                    ]}
                    defaultValue={"MR"}
                    error={
                      errors?.salutation &&
                      t((errors.salutation as any)?.message)
                    }
                  />
                  <SBTextField
                    name="firstName"
                    label={t("firstname")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.firstName && t((errors.firstName as any)?.message)
                    }
                  />
                  <SBTextField
                    name="lastName"
                    label={t("lastname")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.lastName && t((errors.lastName as any)?.message)
                    }
                  />

                  <SBTextField
                    name="telephone"
                    label={t("telephone")}
                    control={control}
                    type={isFormDisabled}
                    icon={<PhoneIcon />}
                    error={
                      errors?.telephone && t((errors.telephone as any)?.message)
                    }
                  />

                  <SBTextField
                    name="email"
                    label={t("email")}
                    control={control}
                    type={isFormDisabled}
                    icon={<EmailIcon />}
                    error={errors?.email && t((errors.email as any)?.message)}
                  />
                </Box>

                <Box>
                  <SBTextField
                    name="address.additionalInfo"
                    label={t("additionalInfo")}
                    control={control}
                    type={isFormDisabled}
                    icon={<FeedIcon />}
                    error={
                      errors?.address &&
                      (errors.address as any)?.additionalInfo &&
                      t((errors.address as any)?.additionalInfo?.message)
                    }
                  />
                  <SBTextField
                    name="address.street"
                    label={t("street")}
                    control={control}
                    type={isFormDisabled}
                    icon={<RoomIcon />}
                    error={
                      errors?.address &&
                      (errors.address as any)?.street &&
                      t((errors.address as any)?.street?.message)
                    }
                  />

                  <SBTextField
                    name="address.zip"
                    label={t("zip")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.address &&
                      (errors.address as any)?.zip &&
                      t((errors.address as any)?.zip?.message)
                    }
                  />
                  <SBTextField
                    name="address.city"
                    label={t("city")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.address &&
                      (errors.address as any)?.city &&
                      t((errors.address as any)?.city?.message)
                    }
                  />
                  <SBTextField
                    name="address.country"
                    label={t("country")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.address &&
                      (errors.address as any)?.country &&
                      t((errors.address as any)?.country?.message)
                    }
                  />
                </Box>
              </Box>
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "50% 50%",
                  gap: "10px",
                }}
              >
                <Box>
                  <SBTextField
                    name="bankDetails.iban"
                    label={t("iban")}
                    control={control}
                    type={isFormDisabled}
                    icon={<AccountBalanceIcon />}
                    error={
                      errors?.bankDetails &&
                      (errors.bankDetails as any)?.iban &&
                      t((errors.bankDetails as any)?.iban?.message)
                    }
                  />
                  <SBTextField
                    name="bankDetails.bic"
                    label={t("bic")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.bankDetails &&
                      (errors.bankDetails as any)?.bic &&
                      t((errors.bankDetails as any)?.bic?.message)
                    }
                  />
                  <SBTextField
                    name="bankDetails.accountHolder"
                    label={t("accountHolder")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.bankDetails &&
                      (errors.bankDetails as any)?.accountHolder &&
                      t((errors.bankDetails as any)?.accountHolder?.message)
                    }
                  />
                  <SBTextField
                    name="bankDetails.bankName"
                    label={t("bankName")}
                    control={control}
                    type={isFormDisabled}
                    icon={<Box />}
                    error={
                      errors?.bankDetails &&
                      (errors.bankDetails as any)?.bankName &&
                      t((errors.bankDetails as any)?.bankName?.message)
                    }
                  />
                </Box>
              </Box>
            </TabPanel>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50% 50%",
                gap: "20px",
              }}
            >
              <Grid container justifyContent="flex-start">
                {!disabledShow && (
                  <SBLoadingButton
                    label={t("newCustomer")}
                    type="blue"
                    icon={<PersonAddIcon />}
                    onClick={onNewCustomerClick}
                  />
                )}
              </Grid>

              <Grid container justifyContent="flex-end">
                <Grid
                  sx={{
                    display: "grid",
                    gridTemplateRows: "auto auto",
                    alignItems: "right",
                    justifyItems: "right",
                    marginRight: "30px",
                  }}
                >
                  {!disabledShow &&
                    booking?.customer.default &&
                    stage === EBookingCustomerStage.SEARCH_CUSTOMER && (
                      <SBLoadingButton
                        label={t("editCustomer")}
                        type="blue"
                        icon={<EditIcon />}
                        disabled={isDefaultUser && !isAdmin}
                        onClick={() => {
                          setSearchPhrase("");
                          setStage(EBookingCustomerStage.EDIT_CUSTOMER);
                        }}
                      />
                    )}

                  {!disabledShow &&
                    stage === EBookingCustomerStage.EDIT_CUSTOMER && (
                      <SBLoadingButton
                        label={t("saveCustomer")}
                        type="blue"
                        icon={<SaveIcon />}
                        disabled={!isValid || (isDefaultUser && !isAdmin)}
                      />
                    )}

                  {!disabledShow &&
                    stage === EBookingCustomerStage.PRESELECT_CUSTOMER && (
                      <SBLoadingButton
                        label={t("searchCustomer")}
                        type="blue"
                        icon={<SearchIcon />}
                        onClick={() => {
                          setStage(EBookingCustomerStage.SEARCH_CUSTOMER);
                          setIsSearchActive(true);
                          searchRef?.current?.focus();
                        }}
                      />
                    )}
                </Grid>
              </Grid>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "grid",
              gridTemplateRows: "32px auto",
            }}
          >
            <Box>
              <IconButton
                type="submit"
                sx={{
                  padding: "10px",
                }}
                aria-label="search"
                data-testid="search-icon"
              >
                <SearchIcon />
              </IconButton>
              <TextField
                inputRef={searchRef}
                sx={{
                  marginLeft: "1px",
                  flex: 1,
                  width: "80%",
                }}
                placeholder={t("searchCustomer")}
                value={searchPhrase}
                onChange={(e) => setSearchPhrase(e.target.value)}
                data-testid="input-base"
                disabled={!isSearchActive}
                variant="standard"
              />
            </Box>

            <Box sx={{ padding: "10px" }}>
              <TableContainer
                sx={{
                  height: "calc(40vh)",
                  overflow: "auto",
                  "&::-webkit-scrollbar": {
                    width: "2px",
                    height: "2px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: customStyle.scrollColor,
                  },
                }}
              >
                <Table>
                  <TableBody>
                    {tableData.map((customer) => (
                      <TableRow
                        key={customer.id}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "30% 69%",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow: "0 0 11px rgba(33,33,33,.2)",
                          },
                        }}
                        onClick={() => onSearchResultClick(customer)}
                      >
                        <TableCell>{`${customer.firstName} ${customer.lastName}`}</TableCell>
                        <TableCell>{`${customer.email}`}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default BookingCustomer;
