import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

export interface IDialogProps {
  open: boolean;
  namespace: string;
  selectedValue?: number | string;
  onClose: (id?: number | string) => void;
}

const RecoverDialog = ({
  open,
  onClose,
  selectedValue,
  namespace,
}: IDialogProps) => {
  const { t } = useTranslation(["common"]);
  const handleRecover = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(selectedValue);
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {t(`recoverDialog.${namespace}.recoverDialogTitle`)}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            height: 70,
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t(`recoverDialog.${namespace}.recoverDialogContent`)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleRecover} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleOk} color="primary">
            {t("recover")}
          </Button>
        </DialogActions>
      </Dialog>
    </I18nextProvider>
  );
};

export default RecoverDialog;
