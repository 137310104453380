import React from "react";
import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../dialog/DeleteDialog";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { deDE } from "@mui/x-date-pickers/locales";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ContentCopy, Visibility } from "@mui/icons-material";
import SBTable from "../SBTable/SBTable";
import { bookingsDateToProperFormat } from "../../utils/conversions/dataConversion";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import { useGetPrices } from "./hooks/useGetPrices";
import { useDeletePrice } from "./hooks/useDeletePrice";
import PricesHeader from "./pricesHeader";
import dayjs from "dayjs";
import { useGetVendors } from "../vendor";
import { useDuplicatePriceMutation } from "./hooks/useDuplicatePriceMutation";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const PricesList: React.FC = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deletePrice = useDeletePrice();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const duplicate = useDuplicatePriceMutation();
  const openDeleteBookingModal = (diablogId: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(diablogId);
  };
  const { vendors } = useGetVendors({
    itemsPerPage: 250,
  });
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("prices");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetPrices(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deletePrice.mutateAsync(
        { id: id },
        {
          onSuccess: (_) => {
            toast.success(t(`toastMessages.deleteSuccess`));
            queryClient.refetchQueries(["getPrices"]);
          },
          onError: (e) => {
            toast.error(t(`toastMessages.deleteFailure`));
          },
        }
      );
    },
    // eslint-disable-next-line
    []
  );

  const columns = React.useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/prices/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "vendorId",
        accessorFn: (originalRow: any) => {
          const vendorName = vendors.flatMap((vendorPage) =>
            vendorPage.data.filter(
              (vendor) => vendor.id === originalRow.vendorId
            )
          );
          return `${originalRow.vendorId} ${vendorName.length > 0 ? vendorName[0]?.name : ""
            }`;
        },
        header: `${t("vendorName")}`,
        filterVariant: "select",
        filterSelectOptions: vendors
          ?.flatMap((vendorPage) => {
            return vendorPage.data.flatMap((vendor) => {
              return {
                text: `${vendor.id} - ${vendor.name}`,
                value: vendor.id,
              };
            });
          })
          .sort((a, b) => a.value - b.value),
        maxSize: 80,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/prices/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "bookingStart",
        accessorFn: (originalRow: any) => originalRow.bookingStart,
        header: `${t("bookingStart")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>
              {bookingsDateToProperFormat(row.renderedCellValue)}
            </Typography>
          </Box>
        ),
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
        maxSize: 50,
        minSize: 10,
      },
      {
        accessorKey: "bookingEnd",
        accessorFn: (originalRow: any) => originalRow.bookingEnd,
        header: `${t("bookingEnd")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>
              {bookingsDateToProperFormat(row.renderedCellValue)}
            </Typography>
          </Box>
        ),
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
        maxSize: 50,
        minSize: 10,
      },
    ];
  }, [t, vendors]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("pricesList")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const priceId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (priceId) {
                  history.push(`/prices/${priceId}/show`);
                }
              }}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (priceId) {
                  history.push(`/prices/${priceId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("duplicate")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (priceId) {
                  const {
                    id,
                    bookingStart,
                    bookingEnd,
                    createdAt,
                    updatedAt,
                    ...createPriceDto
                  } = data?.items[cell.row.index];
                  const newBookingStart = dayjs(bookingStart).add(1, "years");
                  const newBookingEnd = dayjs(bookingEnd).add(1, "years");
                  const changes = {
                    bookingStart: newBookingStart.toISOString(),
                    bookingEnd: newBookingEnd.toISOString(),
                  };
                  const duplicatePrice = { ...createPriceDto, ...changes };
                  duplicate.mutate({ createPriceDto: duplicatePrice });
                }
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (priceId) {
                  openDeleteBookingModal(priceId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <PricesHeader />
      <SBTable
        columnVisibilityStoreName="pricesColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="prices"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteAfterModalConfirm(+deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default React.memo(PricesList);
