import { useQuery } from "@tanstack/react-query";
import { OrderByDirection } from "../../../api-client/generated";
import { featuresApi } from "../../../api/apiTanStack";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { ColumnFilter } from "@tanstack/react-table";

const checkInFilter = (filter: ColumnFilter) => {
  let identification = "";
  switch (filter.id) {
    case "internalNote":
      identification = "hasInternalNote";
      break;
    case "images":
      identification = "hasImages";
      break;
    case "stopSaleDates":
      identification = "hasStopSaleDates";
      break;
    default:
      identification = filter.id;
      break;
  }
  return `filters[${identification}]=${filter.value}`;
};

export const useGetBeachChairs = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "beachChairList",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => {
            if (
              filter.id === "buyingDate" &&
              (filter.value as any)[0] !== undefined &&
              (filter.value as any)[1] !== undefined
            ) {
              return `filters[buyingDateFrom]=${new Date(
                (filter.value as any)[0] || undefined
              ).toISOString()}&filters[buyingDateTo]=${new Date(
                (filter.value as any)[1] || undefined
              ).toISOString()}`;
            }
            if (String(filter.value) === ",") {
              return;
            }
            return checkInFilter(filter);
          })
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return featuresApi
        .beachChairsControllerGetChairsByVendorId(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
