import { Button } from "@mui/material";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Delete from "@mui/icons-material/Delete";

import { buttonColor } from "../../../../utils/customStyles/globalStyles";
import { useDeleteBooking } from "../hooks";
import { useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { routerRedirectBookings } from "../../../../store/reducers/bookingsReducer";
import DeleteDialog from "../../../dialog/DeleteDialog";
import { useBookingInProgressStore } from "../../store/useBookingZustand";

export const BookingDeleteButton: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { id: bookingId } = useParams<{ id: string }>();
  const deleteBooking = useDeleteBooking();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [dialog, setDialog] = useState<boolean>(false);
  const { bookingInProgress, clearBookingInProgress } =
    useBookingInProgressStore();

  const onSubmit = () => {
    deleteBooking.mutate(
      {
        id: Number(bookingId),
      },
      {
        onSuccess: () => {
          toast.success(t(`toastMessages.deleteSuccess`));
          queryClient.invalidateQueries(["booking", Number(bookingId)]);
          queryClient.refetchQueries(["BeachChairBookings"]);
          dispatch(
            routerRedirectBookings({
              path: "/bookings",
            })
          );
          // Remove Booking in Progress if its the same id
          if (bookingInProgress && bookingInProgress.id === Number(bookingId)) {
            clearBookingInProgress();
          }
        },
        onError: (e) => {
          toast.error(t(`toastMessages.deleteFailure`));
        },
      }
    );
  };

  return (
    <>
      <DeleteDialog
        namespace="bookings"
        open={dialog}
        selectedValue={bookingId}
        onClose={() => {
          setDialog(false);
        }}
        onAccept={() => {
          setDialog(false);
          if (bookingId) {
            onSubmit();
          }
        }}
      />
      <Button
        variant="contained"
        sx={{
          fontSize: 12,
          height: 35,
          color: "white",
          backgroundColor: buttonColor,
        }}
        onClick={() => setDialog(true)}
        disabled={deleteBooking.isLoading}
        startIcon={<Delete />}
      >
        {t("delete")}
      </Button>
    </>
  );
};
