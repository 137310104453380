import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { featuresApi } from "../../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useUpdateReportKeyStatus = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common"]);
  return useMutation(
    ["updateReportKeyStatus"],
    (data: { keyStatusId: number; checked: boolean }) =>
      featuresApi.bookingDetailsControllerUpdateBeachChairBooking({
        id: data.keyStatusId,
        updateBeachChairBookingBodyDto: {
          status: data.checked ? "NOT_RETURNED" : "RETURNED",
        },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["getReportKeyStatuses"]);
        toast.success(t(`toastMessages.PutChangeReportStatusSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.PutChangeReportStatusFailure`));
      },
    }
  );
};
