import { FetchAvailabilitiesRequestPayload } from "../store/actionPayload/availabilitiesPayloads";
import { IGrid } from "./../models/availability/IGrid";
import API from "./api";
import { IAvailableBeachChairs } from "../models/availability/IAvailableBeachChairs";
import { IAvailability } from "../models/availability/IAvailibility";
import moment from "moment";

const BASE_URL = "features";
const timeZone = "Europe/Berlin";

export const getAvailabilities = ({
  startDate,
  endDate,
}: FetchAvailabilitiesRequestPayload) => {
  const params = new URLSearchParams();
  if (startDate) params.append("startDate", startDate);
  if (endDate) params.append("endDate", endDate);

  return API.get<IAvailability[]>(
    `/${BASE_URL}/availabilities/sections?${params}`
  );
};

export const getAvailableBeachChairs = (
  sectionId: string,
  start: string,
  end: string,
  row: string,
  model: string,
  free: boolean,
  hasLock: boolean
) =>
  API.get<IAvailableBeachChairs[]>(
    `/${BASE_URL}/sections/${sectionId}/beach_chairs_availability?filters[start]=${start}&filters[end]=${end}${
      row.length !== 0 ? "&filters[row]=" + row : ""
    }${
      model.length !== 0 ? "&filters[model]=" + model : ""
    }&filters[free]=${free}&filters[lock]=${hasLock}&filters[timeZone]=${timeZone}`
  );

export const getAvailabilitiesGrid = (
  sectionId: number,
  date: Date,
  row: string,
  model: string,
  free: boolean,
  hasLock: boolean
) => {
  const previousDates = moment(date).startOf("year").toISOString();
  const nextDates = moment(date).add(1, "year").endOf("year").toISOString();
  return API.get<IGrid>(
    `/${BASE_URL}/sections/${sectionId}/beach_chairs_bookings?filters[start]=${previousDates}&filters[end]=${nextDates}${
      row.length !== 0 ? "&filters[row]=" + row : ""
    }${
      model.length !== 0 ? "&filters[model]=" + model : ""
    }&filters[free]=${free}&filters[lock]=${hasLock}&timeZone=${timeZone}`
  );
};
