import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { generatorsApi } from "../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteShortUrl = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteShortUrl"],
    ({ uid }: { uid: string }) =>
      generatorsApi.redirectsControllerDelete({ uid: uid }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteShortUrlSuccess`));
        queryClient.refetchQueries(["adminShortUrls"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteShortUrlFailure`));
      },
    }
  );
};
