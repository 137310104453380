import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

interface SBTablePersistState {
  columnFilters: MRT_ColumnFiltersState;
  setColumnFiltersPersits: (columnFilters: MRT_ColumnFiltersState) => void;
  sorting: MRT_SortingState;
  setSortingPersits: (sorting: MRT_SortingState) => void;
  pagination: MRT_PaginationState;
  setPaginationPersists: (pagination: MRT_PaginationState) => void;
  clear: () => void;
}

export const useSBTablePersistState = create<SBTablePersistState>()(
  persist(
    (set) => {
      return {
        columnFilters: [],
        setColumnFiltersPersits: (columnFilters: MRT_ColumnFiltersState) =>
          set((state) => ({
            ...state,
            columnFilters,
          })),
        sorting: [],
        setSortingPersits: (sorting: MRT_SortingState) =>
          set((state) => ({
            ...state,
            sorting,
          })),
        pagination: {
          pageIndex: 0,
          pageSize: 15,
        },
        setPaginationPersists: (pagination: MRT_PaginationState) =>
          set((state) => ({
            ...state,
            pagination,
          })),
        clear: () =>
          set((state) => ({
            ...state,
            sorting: [],
            columnFilters: [],
          })),
      };
    },
    {
      name: "useSBTablePersistState",
    }
  )
);
