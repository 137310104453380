import { useMutation, useQueryClient } from "@tanstack/react-query";
import { bookingsApi } from "../../../../api/apiTanStack";

interface Variables {
  id: number;
  extrasBookingId: number;
  amount: number;
}

export function useUpdateBookingExtrasAmount() {
  const queryClient = useQueryClient();
  const { mutate: updateBookingExtrasAmount, isLoading } = useMutation(
    ({ extrasBookingId, id, amount }: Variables) =>
      bookingsApi.bookingsControllerSetAmountExtrasInBooking({
        extrasBookingId,
        id,
        updateExtrasBookingDto: { amount },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );

  return {
    updateBookingExtrasAmount,
    isLoading,
  };
}
