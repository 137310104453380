import { Button } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SupportIcon from "@mui/icons-material/Support";
import { useRecoverBooking } from "../hooks";
import { useParams } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ApiError } from "../../../../types/";
import RecoverDialog from "../../../dialog/RecoverDialog";
import { buttonColor } from "../../../../utils/customStyles/globalStyles";

export const BookingRecoverButton: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { id: bookingId } = useParams<{ id: string }>();
  const { recoverBooking, isLoading: isRecovering } = useRecoverBooking();
  const queryClient = useQueryClient();
  const [dialog, setDialog] = useState<boolean>(false);

  const onSubmit = () => {
    recoverBooking(
      {
        id: Number(bookingId),
      },
      {
        onSuccess: () => {
          toast.success(t(`toastMessages.recoverSuccess`));
          queryClient.refetchQueries(["booking", Number(bookingId)]);
          queryClient.refetchQueries(["bookings"]);
        },
        onError: (error) => {
          toast.error(
            t("toastMessages.recoverFailure", {
              publicNumber: (error as ApiError).response?.data.payload
                .publicNumber,
            })
          );
        },
      }
    );
  };

  return (
    <>
      <RecoverDialog
        namespace="bookings"
        open={dialog}
        selectedValue={bookingId}
        onClose={(bookingId) => {
          setDialog(false);
          if (bookingId) {
            onSubmit();
          }
        }}
      />
      <Button
        variant="contained"
        size="small"
        color="primary"
        sx={{
          fontSize: 12,
          height: 35,
          color: "white",
          backgroundColor: buttonColor,
        }}
        onClick={() => setDialog(true)}
        disabled={isRecovering}
        startIcon={<SupportIcon />}
      >
        {t("recover")}
      </Button>
    </>
  );
};
