import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  IDestinationBeach,
  IDestinationCity,
  IDestinationRegion,
  IDestinationSection,
  IFiltersDestinationRegion,
} from "../../components/configuration/model/IDestinations";
import { IFilterData } from "../../models/dataTable/IFilterData";
import {
  ChangeImagesOrderRequestPayload,
  ChangeRowRequestPayload,
  ChangeRowSuccessPayload,
  DeleteBeachRequestPayload,
  DeleteBeachSuccessPayload,
  DeleteCityRequestPayload,
  DeleteCitySuccessPayload,
  DeleteRegionRequestPayload,
  DeleteRegionSuccessPayload,
  DeleteRowRequestPayload,
  DeleteRowSuccessPayload,
  DeleteSectionRequestPayload,
  DeleteSectionSuccessPayload,
  DestinationFailurePayload,
  FilterDataDestinationBeachesChangeRequestPayload,
  FilterDataDestinationCitiesChangeRequestPayload,
  FilterDataDestinationRegionsChangeRequestPayload,
  FilterDataDestinationSectionsChangeRequestPayload,
  GetDestinationBeachesRequestPayload,
  GetDestinationBeachesSuccessPayload,
  GetDestinationBeachRequestPayload,
  GetDestinationBeachSuccessPayload,
  GetDestinationCitiesRequestPayload,
  GetDestinationCitiesSuccessPayload,
  GetDestinationCityRequestPayload,
  GetDestinationCitySuccessPayload,
  GetDestinationRegionRequestPayload,
  GetDestinationRegionsRequestPayload,
  GetDestinationRegionsSuccessPayload,
  GetDestinationRegionSuccessPayload,
  GetDestinationSectionRequestPayload,
  GetDestinationSectionsRequestPayload,
  GetDestinationSectionsSuccessPayload,
  GetDestinationSectionSuccessPayload,
  PostBeachRequestPayload,
  PostBeachSuccessPayload,
  PostCityRequestPayload,
  PostCitySuccessPayload,
  PostRegionRequestPayload,
  PostRegionSuccessPayload,
  PostRowRequestPayload,
  PostRowSuccessPayload,
  PostSectionRequestPayload,
  PostSectionSuccessPayload,
  PutBeachRequestPayload,
  PutBeachSuccessPayload,
  PutCityRequestPayload,
  PutCitySuccessPayload,
  PutRegionRequestPayload,
  PutRegionSuccessPayload,
  PutSectionRequestPayload,
  PutSectionSuccessPayload,
} from "../actionPayload/destinationPayloads";
import { AppState } from "./rootReducer";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IDestinationState {
  pending: boolean;
  error: string;
  filterDestinationRegionsData: IFilterData[];
  filterRegions?: IFiltersDestinationRegion;
  destinationRegionsCount: number;
  destinationRegions: EntityState<IDestinationRegion>;
  pageDestinationRegions: EntityState<IPage>;
  filterDestinationCitiesData: IFilterData[];
  filterCities?: IFiltersDestinationRegion;
  destinationCitiesCount: number;
  destinationCities: EntityState<IDestinationCity>;
  pageDestinationCities: EntityState<IPage>;
  filterDestinationBeachesData: IFilterData[];
  filterBeaches?: IFiltersDestinationRegion;
  destinationBeachesCount: number;
  destinationBeaches: EntityState<IDestinationBeach>;
  pageDestinationBeaches: EntityState<IPage>;
  filterDestinationSectionsData: IFilterData[];
  filterSections?: IFiltersDestinationRegion;
  destinationSectionsCount: number;
  destinationSections: EntityState<IDestinationSection>;
  pageDestinationSections: EntityState<IPage>;
  imagesOrder: string[];
}

const destinationRegionsAdapter = createEntityAdapter<IDestinationRegion>({
  selectId: (region: IDestinationRegion) => region.id,
});

const pageDestinationRegionsAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const destinationCitiesAdapter = createEntityAdapter<IDestinationCity>({
  selectId: (city: IDestinationCity) => city.id,
});

const pageDestinationCitiesAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const destinationBeachesAdapter = createEntityAdapter<IDestinationBeach>({
  selectId: (beach: IDestinationBeach) => beach.id,
});

const pageDestinationBeachesAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const destinationSectionsAdapter = createEntityAdapter<IDestinationSection>({
  selectId: (section: IDestinationSection) => section.id,
});

const pageDestinationSectionsAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const INITIAL_STATE: IDestinationState = {
  pending: false,
  error: "",
  filterDestinationRegionsData: [],
  filterRegions: undefined,
  destinationRegionsCount: 0,
  destinationRegions: destinationRegionsAdapter.getInitialState({}),
  pageDestinationRegions: pageDestinationRegionsAdapter.getInitialState({}),
  filterDestinationCitiesData: [],
  filterCities: undefined,
  destinationCitiesCount: 0,
  destinationCities: destinationCitiesAdapter.getInitialState({}),
  pageDestinationCities: pageDestinationCitiesAdapter.getInitialState({}),
  filterDestinationBeachesData: [],
  filterBeaches: undefined,
  destinationBeachesCount: 0,
  destinationBeaches: destinationBeachesAdapter.getInitialState({}),
  pageDestinationBeaches: pageDestinationBeachesAdapter.getInitialState({}),
  filterDestinationSectionsData: [],
  filterSections: undefined,
  destinationSectionsCount: 0,
  destinationSections: destinationSectionsAdapter.getInitialState({}),
  pageDestinationSections: pageDestinationSectionsAdapter.getInitialState({}),
  imagesOrder: [],
};

const destinationSlice = createSlice({
  name: "destination",
  initialState: INITIAL_STATE,
  reducers: {
    //Regions
    getRegionsRequest(
      state,
      action: PayloadAction<GetDestinationRegionsRequestPayload>
    ) {
      state.pending = true;
    },
    getRegionsSuccess(
      state,
      action: PayloadAction<GetDestinationRegionsSuccessPayload>
    ) {
      state.pending = false;
      state.destinationRegionsCount = action.payload.count;
      destinationRegionsAdapter.removeAll(state.destinationRegions);
      pageDestinationRegionsAdapter.removeAll(state.pageDestinationRegions);
      destinationRegionsAdapter.upsertMany(
        state.destinationRegions,
        action.payload.destinationRegions
      );
      pageDestinationRegionsAdapter.upsertOne(state.pageDestinationRegions, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.destinationRegions.map((region) => region.id),
        ],
      });
    },
    changeRegionsDataRequest(state) {
      state.pending = true;
      pageDestinationRegionsAdapter.removeAll(state.pageDestinationRegions);
    },
    getRegionRequest(
      state,
      action: PayloadAction<GetDestinationRegionRequestPayload>
    ) {
      state.pending = true;
    },
    getRegionSuccess(
      state,
      action: PayloadAction<GetDestinationRegionSuccessPayload>
    ) {
      state.pending = false;
      destinationRegionsAdapter.upsertOne(
        state.destinationRegions,
        action.payload.destinationRegion
      );
    },
    putRegionRequest(state, action: PayloadAction<PutRegionRequestPayload>) {
      state.pending = true;
    },
    putRegionSuccess(state, action: PayloadAction<PutRegionSuccessPayload>) {
      state.pending = false;
      destinationRegionsAdapter.upsertOne(
        state.destinationRegions,
        action.payload.destinationRegion
      );
      pageDestinationRegionsAdapter.removeAll(state.pageDestinationRegions);
    },
    postRegionRequest(state, action: PayloadAction<PostRegionRequestPayload>) {
      state.pending = true;
    },
    postRegionSuccess(state, action: PayloadAction<PostRegionSuccessPayload>) {
      state.pending = false;
      state.destinationRegionsCount = state.destinationRegionsCount + 1;
      destinationRegionsAdapter.upsertOne(
        state.destinationRegions,
        action.payload.destinationRegion
      );
    },
    deleteRegionRequest(
      state,
      action: PayloadAction<DeleteRegionRequestPayload>
    ) {
      state.pending = true;
    },
    deleteRegionSuccess(
      state,
      action: PayloadAction<DeleteRegionSuccessPayload>
    ) {
      state.pending = false;
      destinationRegionsAdapter.removeOne(
        state.destinationRegions,
        action.payload.regionId
      );
      pageDestinationRegionsAdapter.removeAll(state.pageDestinationRegions);
    },
    filterDataDestinationRegionsChangeRequest(
      state,
      action: PayloadAction<FilterDataDestinationRegionsChangeRequestPayload>
    ) {
      state.filterDestinationRegionsData = state.filterDestinationRegionsData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    //Cities
    getCitiesRequest(
      state,
      action: PayloadAction<GetDestinationCitiesRequestPayload>
    ) {
      state.pending = true;
    },
    getCitiesSuccess(
      state,
      action: PayloadAction<GetDestinationCitiesSuccessPayload>
    ) {
      state.pending = false;
      state.destinationCitiesCount = action.payload.count;
      destinationCitiesAdapter.removeAll(state.destinationCities);
      pageDestinationCitiesAdapter.removeAll(state.pageDestinationCities);
      destinationCitiesAdapter.upsertMany(
        state.destinationCities,
        action.payload.destinationCities
      );
      pageDestinationCitiesAdapter.upsertOne(state.pageDestinationCities, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.destinationCities.map((citiy) => citiy.id)],
      });
    },
    changeCitiesDataRequest(state) {
      state.pending = true;
      pageDestinationCitiesAdapter.removeAll(state.pageDestinationCities);
    },
    getCityRequest(
      state,
      action: PayloadAction<GetDestinationCityRequestPayload>
    ) {
      state.pending = true;
    },
    getCitySuccess(
      state,
      action: PayloadAction<GetDestinationCitySuccessPayload>
    ) {
      state.pending = false;
      destinationCitiesAdapter.upsertOne(
        state.destinationCities,
        action.payload.destinationCity
      );
    },
    putCityRequest(state, action: PayloadAction<PutCityRequestPayload>) {
      state.pending = true;
    },
    putCitySuccess(state, action: PayloadAction<PutCitySuccessPayload>) {
      state.pending = false;
      destinationCitiesAdapter.upsertOne(
        state.destinationCities,
        action.payload.destinationCity
      );
      pageDestinationCitiesAdapter.removeAll(state.pageDestinationCities);
    },
    postCityRequest(state, action: PayloadAction<PostCityRequestPayload>) {
      state.pending = true;
    },
    postCitySuccess(state, action: PayloadAction<PostCitySuccessPayload>) {
      state.pending = false;
      state.destinationCitiesCount = state.destinationCitiesCount + 1;
      destinationCitiesAdapter.upsertOne(
        state.destinationCities,
        action.payload.destinationCity
      );
    },
    deleteCityRequest(state, action: PayloadAction<DeleteCityRequestPayload>) {
      state.pending = true;
    },
    deleteCitySuccess(state, action: PayloadAction<DeleteCitySuccessPayload>) {
      state.pending = false;
      destinationCitiesAdapter.removeOne(
        state.destinationCities,
        action.payload.cityId
      );
      pageDestinationCitiesAdapter.removeAll(state.pageDestinationCities);
    },
    filterDataDestinationCitiesChangeRequest(
      state,
      action: PayloadAction<FilterDataDestinationCitiesChangeRequestPayload>
    ) {
      state.filterDestinationCitiesData = state.filterDestinationCitiesData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    //Beaches
    getBeachesRequest(
      state,
      action: PayloadAction<GetDestinationBeachesRequestPayload>
    ) {
      state.pending = true;
    },
    getBeachesSuccess(
      state,
      action: PayloadAction<GetDestinationBeachesSuccessPayload>
    ) {
      state.pending = false;
      state.destinationBeachesCount = action.payload.count;
      destinationBeachesAdapter.removeAll(state.destinationBeaches);
      pageDestinationBeachesAdapter.removeAll(state.pageDestinationBeaches);
      destinationBeachesAdapter.upsertMany(
        state.destinationBeaches,
        action.payload.destinationBeaches
      );
      pageDestinationBeachesAdapter.upsertOne(state.pageDestinationBeaches, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.destinationBeaches.map((beach) => beach.id),
        ],
      });
    },
    changeBeachesDataRequest(state) {
      state.pending = true;
      pageDestinationBeachesAdapter.removeAll(state.pageDestinationBeaches);
    },
    getBeachRequest(
      state,
      action: PayloadAction<GetDestinationBeachRequestPayload>
    ) {
      state.pending = true;
    },
    getBeachSuccess(
      state,
      action: PayloadAction<GetDestinationBeachSuccessPayload>
    ) {
      state.pending = false;
      destinationBeachesAdapter.upsertOne(
        state.destinationBeaches,
        action.payload.destinationBeach
      );
    },
    putBeachRequest(state, action: PayloadAction<PutBeachRequestPayload>) {
      state.pending = true;
    },
    putBeachSuccess(state, action: PayloadAction<PutBeachSuccessPayload>) {
      state.pending = false;
      destinationBeachesAdapter.upsertOne(
        state.destinationBeaches,
        action.payload.destinationBeach
      );
      pageDestinationBeachesAdapter.removeAll(state.pageDestinationBeaches);
    },
    postBeachRequest(state, action: PayloadAction<PostBeachRequestPayload>) {
      state.pending = true;
    },
    postBeachSuccess(state, action: PayloadAction<PostBeachSuccessPayload>) {
      state.pending = false;
      state.destinationBeachesCount = state.destinationBeachesCount + 1;
      destinationBeachesAdapter.upsertOne(
        state.destinationBeaches,
        action.payload.destinationBeach
      );
    },
    deleteBeachRequest(
      state,
      action: PayloadAction<DeleteBeachRequestPayload>
    ) {
      state.pending = true;
    },
    deleteBeachSuccess(
      state,
      action: PayloadAction<DeleteBeachSuccessPayload>
    ) {
      state.pending = false;
      destinationBeachesAdapter.removeOne(
        state.destinationBeaches,
        action.payload.beachId
      );
      pageDestinationBeachesAdapter.removeAll(state.pageDestinationBeaches);
    },
    filterDataDestinationBeachesChangeRequest(
      state,
      action: PayloadAction<FilterDataDestinationBeachesChangeRequestPayload>
    ) {
      state.filterDestinationBeachesData = state.filterDestinationBeachesData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    //Sections
    getSectionsRequest(
      state,
      action: PayloadAction<GetDestinationSectionsRequestPayload>
    ) {
      state.pending = true;
    },
    getSectionsSuccess(
      state,
      action: PayloadAction<GetDestinationSectionsSuccessPayload>
    ) {
      state.pending = false;
      state.destinationSectionsCount = action.payload.count;
      destinationSectionsAdapter.removeAll(state.destinationSections);
      pageDestinationSectionsAdapter.removeAll(state.pageDestinationSections);
      destinationSectionsAdapter.upsertMany(
        state.destinationSections,
        action.payload.destinationSections
      );
      pageDestinationSectionsAdapter.upsertOne(state.pageDestinationSections, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.destinationSections.map((section) => section.id),
        ],
      });
    },
    changeSectionsDataRequest(state) {
      state.pending = true;
      pageDestinationSectionsAdapter.removeAll(state.pageDestinationSections);
    },
    getSectionRequest(
      state,
      action: PayloadAction<GetDestinationSectionRequestPayload>
    ) {
      state.pending = true;
    },
    getSectionSuccess(
      state,
      action: PayloadAction<GetDestinationSectionSuccessPayload>
    ) {
      state.pending = false;
      destinationSectionsAdapter.upsertOne(
        state.destinationSections,
        action.payload.destinationSection
      );
    },
    putSectionRequest(state, action: PayloadAction<PutSectionRequestPayload>) {
      state.pending = true;
    },
    putSectionSuccess(state, action: PayloadAction<PutSectionSuccessPayload>) {
      state.pending = false;
      destinationSectionsAdapter.updateOne(state.destinationSections, {
        id: action.payload.destinationSection.id,
        changes: action.payload.destinationSection,
      });
      pageDestinationSectionsAdapter.removeAll(state.pageDestinationSections);
    },
    postSectionRequest(
      state,
      action: PayloadAction<PostSectionRequestPayload>
    ) {
      state.pending = true;
    },
    postSectionSuccess(
      state,
      action: PayloadAction<PostSectionSuccessPayload>
    ) {
      state.pending = false;
      state.destinationCitiesCount = state.destinationCitiesCount + 1;
      destinationSectionsAdapter.upsertOne(
        state.destinationSections,
        action.payload.destinationSection
      );
      pageDestinationSectionsAdapter.removeAll(state.pageDestinationSections);
    },
    postRowRequest(state, action: PayloadAction<PostRowRequestPayload>) {
      state.pending = true;
    },
    postRowSuccess(state, action: PayloadAction<PostRowSuccessPayload>) {
      state.pending = false;
      destinationSectionsAdapter.updateOne(state.destinationSections, {
        id: action.payload.destinationSection.id,
        changes: action.payload.destinationSection,
      });
    },
    deleteSectionRequest(
      state,
      action: PayloadAction<DeleteSectionRequestPayload>
    ) {
      state.pending = true;
    },
    deleteSectionSuccess(
      state,
      action: PayloadAction<DeleteSectionSuccessPayload>
    ) {
      state.pending = false;
      destinationSectionsAdapter.removeOne(
        state.destinationSections,
        action.payload.sectionId
      );
      pageDestinationSectionsAdapter.removeAll(state.pageDestinationSections);
    },
    filterDataDestinationSectionsChangeRequest(
      state,
      action: PayloadAction<FilterDataDestinationSectionsChangeRequestPayload>
    ) {
      state.filterDestinationSectionsData = state.filterDestinationSectionsData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    changeImageOrderRequest(
      state,
      action: PayloadAction<ChangeImagesOrderRequestPayload>
    ) {
      state.imagesOrder = action.payload.images;
    },
    changeRowRequest(state, action: PayloadAction<ChangeRowRequestPayload>) {},
    changeRowSuccess(state, action: PayloadAction<ChangeRowSuccessPayload>) {},
    deleteRowRequest(state, action: PayloadAction<DeleteRowRequestPayload>) {},
    deleteRowSuccess(state, action: PayloadAction<DeleteRowSuccessPayload>) {},
    destinationFailure(
      state,
      action: PayloadAction<DestinationFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: getAllDestinationRegionsPages,
  selectById: getDestinationRegionPages,
} = pageDestinationRegionsAdapter.getSelectors<AppState>(
  (state) => state.destination.pageDestinationRegions
);

export const {
  selectAll: getAllDestinationRegions,
  selectById: getDestinationRegionById,
  selectTotal: getDestinationRegionsTotal,
} = destinationRegionsAdapter.getSelectors<AppState>(
  (state) => state.destination.destinationRegions
);

export const {
  selectAll: getAllDestinationCitiesPages,
  selectById: getDestinationCityPages,
} = pageDestinationCitiesAdapter.getSelectors<AppState>(
  (state) => state.destination.pageDestinationCities
);

export const {
  selectAll: getAllDestinationCities,
  selectById: getDestinationCityById,
  selectTotal: getDestinationCitiesTotal,
} = destinationCitiesAdapter.getSelectors<AppState>(
  (state) => state.destination.destinationCities
);

export const {
  selectAll: getAllDestinationBeachesPages,
  selectById: getDestinationBeachPages,
} = pageDestinationBeachesAdapter.getSelectors<AppState>(
  (state) => state.destination.pageDestinationBeaches
);

export const {
  selectAll: getAllDestinationBeaches,
  selectById: getDestinationBeachById,
  selectTotal: getDestinationBeachesTotal,
} = destinationBeachesAdapter.getSelectors<AppState>(
  (state) => state.destination.destinationBeaches
);

export const {
  selectAll: getAllDestinationSectionsPages,
  selectById: getDestinationSectionPages,
} = pageDestinationSectionsAdapter.getSelectors<AppState>(
  (state) => state.destination.pageDestinationSections
);

export const {
  selectAll: getAllDestinationSections,
  selectById: getDestinationSectionById,
  selectTotal: getDestinationSectionsTotal,
} = destinationSectionsAdapter.getSelectors<AppState>(
  (state) => state.destination.destinationSections
);

export const {
  getRegionsRequest,
  getRegionsSuccess,
  getRegionRequest,
  getRegionSuccess,
  changeRegionsDataRequest,
  putRegionRequest,
  putRegionSuccess,
  postRegionRequest,
  postRegionSuccess,
  deleteRegionRequest,
  deleteRegionSuccess,
  filterDataDestinationRegionsChangeRequest,
  getCitiesRequest,
  getCitiesSuccess,
  getCityRequest,
  getCitySuccess,
  changeCitiesDataRequest,
  putCityRequest,
  putCitySuccess,
  postCityRequest,
  postCitySuccess,
  deleteCityRequest,
  deleteCitySuccess,
  filterDataDestinationCitiesChangeRequest,
  getBeachesRequest,
  getBeachesSuccess,
  getBeachRequest,
  getBeachSuccess,
  changeBeachesDataRequest,
  putBeachRequest,
  putBeachSuccess,
  postBeachRequest,
  postBeachSuccess,
  deleteBeachRequest,
  deleteBeachSuccess,
  filterDataDestinationBeachesChangeRequest,
  getSectionsRequest,
  getSectionsSuccess,
  getSectionRequest,
  getSectionSuccess,
  changeSectionsDataRequest,
  putSectionRequest,
  putSectionSuccess,
  postSectionRequest,
  postSectionSuccess,
  deleteSectionRequest,
  deleteSectionSuccess,
  postRowRequest,
  postRowSuccess,
  filterDataDestinationSectionsChangeRequest,
  changeImageOrderRequest,
  changeRowRequest,
  changeRowSuccess,
  deleteRowRequest,
  deleteRowSuccess,
  destinationFailure,
} = destinationSlice.actions;

export default destinationSlice.reducer;
