import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useUpdateBooking } from "../../components/bookingsList/booking/hooks";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { BookingDetailsResponseDto } from "../../api-client/generated";
import { useQueryClient } from "@tanstack/react-query";

export interface IBookingDetailFormData {
  status: string;
  bookingRef: string;
  createdAt: string;
  comment: string;
  customerComment: string;
}

export function useBookingDetailForm(booking?: BookingDetailsResponseDto) {
  const queryClient = useQueryClient();
  const updateBooking = useUpdateBooking();
  const { t } = useTranslation(["common"]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        status: yup.string(),
        bookingRef: yup.string(),
        createdAt: yup.date(),
        comment: yup.string().nullable(true),
        customerComment: yup.string().nullable(true),
      }),
    []
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (booking) {
      setValue("status", booking.status);
      setValue("bookingRef", booking.bookingRef);
      setValue("createdAt", booking.createdAt);
      setValue("comment", booking.comment ?? "");
      setValue("customerComment", booking.customerComment ?? "");
    }
  }, [booking, setValue]);

  const onSubmit = useCallback(
    (formValues: any) => {
      booking &&
        updateBooking.mutate(
          {
            id: Number(booking.id),
            customerComment: formValues.customerComment,
            comment: formValues.comment,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["booking"]);
              toast.success(t(`toastMessages.ChangeBookingCommentSuccess`));
            },
            onError: () => {
              toast.error(t(`toastMessages.ChangeBookingCommentFailure`));
            },
          }
        );
    },
    [booking]
  );

  return {
    control,
    setValue,
    watch,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
