import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCashPointDeposit, useCashPointWithdraw } from "../hooks/";

const CashOperationModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (amount: number) => void;
  title: string;
  isLoading: boolean;
}> = ({ isOpen, onClose, onConfirm, title, isLoading }) => {
  const [amount, setAmount] = React.useState(0);
  const { t } = useTranslation(["common"]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="cash-operation-modal-title"
      aria-describedby="cash-operation-modal-description"
      fullWidth
      disableEscapeKeyDown={isLoading}
      maxWidth="sm"
    >
      <DialogTitle id="cash-operation-modal-title">{title}</DialogTitle>

      <DialogContent>
        <DialogContentText id="cash-operation-modal-description">
          <TextField
            autoFocus
            margin="dense"
            id="amount"
            label={t("amount")}
            type="number"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
            variant="standard"
          />
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={isLoading}>
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          onClick={() => onConfirm(amount)}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export const WithdrawModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { locationId } = useParams<{ locationId?: string }>();
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();

  const { withdraw, isLoading: isWihtdrawing } = useCashPointWithdraw();

  const handleWithdraw = async (amount: number) => {
    await withdraw(
      { cashPointWithdraw: { amount, locationId: Number(locationId) } },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            "cashPointBalance",
            Number(locationId),
          ]);

          onClose();
        },
      }
    );
  };

  return (
    <CashOperationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleWithdraw}
      title={t("cashPointBalancePage.CASH_POINT_DATA_WITHDRAW")}
      isLoading={isWihtdrawing}
    />
  );
};

export const DepositModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { locationId } = useParams<{ locationId?: string }>();
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();

  const { deposit, isLoading: isDepositing } = useCashPointDeposit();

  const handleDeposit = async (amount: number) => {
    await deposit(
      { cashPointWithdraw: { amount, locationId: Number(locationId) } },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries([
            "cashPointBalance",
            Number(locationId),
          ]);

          onClose();
        },
      }
    );
  };

  return (
    <CashOperationModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={handleDeposit}
      title={t("cashPointBalancePage.CASH_POINT_DATA_DEPOSIT")}
      isLoading={isDepositing}
    />
  );
};
