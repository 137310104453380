import { useMutation, useQueryClient } from "@tanstack/react-query";
import { extrasApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CreateExternalServicesDto } from "../../../api-client/generated";

export const useDuplicateExtraMutation = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["DuplicateExtraMutation"],
    (data: { createExternalServicesDto: CreateExternalServicesDto }) =>
      extrasApi.externalServicesControllerCreate({
        createExternalServicesDto: data.createExternalServicesDto,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["extras"]);
        toast.success(t(`toastMessages.PutExtraSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.PutExtraFailure`));
      },
    }
  );
};
