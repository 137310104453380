import React from "react";
import { useTranslation } from "react-i18next";
import { useLocationForm } from "../../utils/customHooks/useLocationForm";
import { customStyle } from "../../utils/customStyles/customStyles";
import LocationHeader from "./locationHeader/locationHeader";
import { Controller } from "react-hook-form";
import RoomIcon from "@mui/icons-material/Room";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getBeachesRequest } from "../../store/reducers/destinationReducer";
import { getAllDestinationBeachesSelector } from "../../store/selectors/destinationSelectors";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import MDEditor from "@uiw/react-md-editor";
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
} from "@mui/material";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import { Numbers, Save } from "@mui/icons-material";
import SBTextField from "../SBComponents/SBForms/SBTextField";
import SBSelect from "../SBComponents/SBForms/SBSelect";
import FilterFramesIcon from "@mui/icons-material/FilterFrames";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SBTextArea from "../SBComponents/SBForms/SBTextArea";
import DescriptionIcon from "@mui/icons-material/Description";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  SBButtonGridStyle,
  SBLabelStyle,
} from "../SBComponents/styles/SBStyles";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import ReactCountryFlag from "react-country-flag";
import SBTimeField from "../SBComponents/SBForms/SBTimeField";
import SBTimeFromToFields from "../SBComponents/SBForms/SBTimeFromToFields";

enum Language {
  EN = "en",
  DE = "de",
}

const Location = () => {
  const dispatch = useDispatch();
  const { id, type } = useParams<{ id: string; type: string }>();
  const { t } = useTranslation(["common"]);
  const { control, errors, onSubmit, isLoading, setValue } = useLocationForm(
    +id,
    type
  );
  const beaches = useSelector(getAllDestinationBeachesSelector);
  const [language, setLanguage] = React.useState<Language>(Language.DE);
  const handleLanguage = (
    event: React.MouseEvent<HTMLElement>,
    newLanguage: Language | Language.DE
  ) => {
    if (newLanguage !== null) {
      setLanguage(() => newLanguage);
    }
  };

  React.useEffect(() => {
    dispatch(
      getBeachesRequest({
        itemsPerPage: 0,
        page: 0,
      })
    );
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <CircularProgress sx={{ color: customStyle.mainColor }} />
      </Box>
    );
  }
  return (
    <SBMainComponentGridWrapper>
      <LocationHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="name"
              label={t("name")}
              control={control}
              type={type}
              icon={<Numbers />}
              isLoading={isLoading}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBSelect
              name="beachId"
              label={t("beach")}
              noOptionLabel={t("NO_BEACH")}
              control={control}
              type={type}
              icon={<BeachAccessIcon />}
              isLoading={isLoading}
              additionalOptions={beaches.flatMap((beach) => {
                return { id: beach.id, value: beach.name };
              })}
              defaultValue={0}
              error={errors?.beachId && t((errors.beachId as any)?.message)}
            />
            <SBTextField
              name="address.street"
              label={t("street")}
              control={control}
              type={type}
              icon={<RoomIcon />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.street &&
                t((errors.address as any)?.street?.message)
              }
            />
            <SBTextField
              name="address.zip"
              label={t("zip")}
              control={control}
              type={type}
              icon={<RoomIcon />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.zip &&
                t((errors.address as any)?.zip?.message)
              }
            />
            <SBTextField
              name="address.city"
              label={t("city")}
              control={control}
              type={type}
              icon={<RoomIcon />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.city &&
                t((errors.address as any)?.city?.message)
              }
            />
            <SBTextField
              name="address.country"
              label={t("country")}
              control={control}
              type={type}
              icon={<RoomIcon />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.country &&
                t((errors.address as any)?.country?.message)
              }
            />
            <SBTextField
              name="entranceName"
              label={t("entranceName")}
              control={control}
              type={type}
              icon={<FilterFramesIcon />}
              isLoading={isLoading}
              error={
                errors?.entranceName && t((errors.entranceName as any)?.message)
              }
            />
            <SBTextField
              name="leadTimeHours"
              label={t("leadTimeHours")}
              control={control}
              type={type}
              icon={<HourglassBottomIcon />}
              isLoading={isLoading}
              error={
                errors?.leadTimeHours &&
                t((errors.leadTimeHours as any)?.message)
              }
            />
            <SBTextField
              name="geoJson"
              label={t("destinations.geoJson")}
              control={control}
              type={type}
              icon={<LocationOnIcon />}
              isLoading={isLoading}
              error={errors?.geoJson && t((errors.geoJson as any)?.message)}
            />
            <SBTextArea
              name="description"
              label={t("description")}
              control={control}
              type={type}
              icon={<DescriptionIcon />}
              isLoading={isLoading}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBTextField
              name="seasonStart"
              label={t("seasonStart")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
              error={
                errors?.seasonStart && t((errors.seasonStart as any)?.message)
              }
            />
            <SBTextField
              name="seasonEnd"
              label={t("seasonEnd")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
              error={errors?.seasonEnd && t((errors.seasonEnd as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBTimeFromToFields
              nameFrom="details.openingHours.0.opening"
              nameTo="details.openingHours.0.closing"
              labelFrom={t("details.Monday_OPEN")}
              labelTo={t("details.Monday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.1.opening"
              nameTo="details.openingHours.1.closing"
              labelFrom={t("details.Tuesday_OPEN")}
              labelTo={t("details.Tuesday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.2.opening"
              nameTo="details.openingHours.2.closing"
              labelFrom={t("details.Wednesday_OPEN")}
              labelTo={t("details.Wednesday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.3.opening"
              nameTo="details.openingHours.3.closing"
              labelFrom={t("details.Thursday_OPEN")}
              labelTo={t("details.Thursday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.4.opening"
              nameTo="details.openingHours.4.closing"
              labelFrom={t("details.Friday_OPEN")}
              labelTo={t("details.Friday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.5.opening"
              nameTo="details.openingHours.5.closing"
              labelFrom={t("details.Saturday_OPEN")}
              labelTo={t("details.Saturday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />
            <SBTimeFromToFields
              nameFrom="details.openingHours.6.opening"
              nameTo="details.openingHours.6.closing"
              labelFrom={t("details.Sunday_OPEN")}
              labelTo={t("details.Sunday_CLOSE")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
            />

            <SBTimeField
              name="checkoutTime"
              label={t("checkoutTime")}
              control={control}
              type={type}
              icon={<AccessTimeIcon />}
              isLoading={isLoading}
              error={
                errors?.checkoutTime && t((errors.checkoutTime as any)?.message)
              }
            />
            <Box
              data-color-mode="light"
              sx={{
                marginTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <ToggleButtonGroup
                value={language}
                exclusive
                onChange={handleLanguage}
                aria-label="text alignment"
              >
                <ToggleButton value={Language.DE} aria-label="left aligned">
                  <ReactCountryFlag countryCode="DE" svg />
                </ToggleButton>
                <ToggleButton value={Language.EN} aria-label="centered">
                  <ReactCountryFlag countryCode="US" svg />
                </ToggleButton>
              </ToggleButtonGroup>
              <InputLabel sx={{ ...SBLabelStyle }}>
                {t("termsTitle")}
              </InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  defaultValue={""}
                  control={control}
                  name="terms.de"
                  render={({ field }) => (
                    <Box
                      hidden={language.includes("en")}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <MDEditor {...field} />
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  defaultValue={""}
                  control={control}
                  name="terms.en"
                  render={({ field }) => (
                    <Box
                      hidden={language.includes("de")}
                      sx={{
                        marginTop: "-11px",
                        marginBottom: "21px",
                        width: "100%",
                      }}
                    >
                      <MDEditor {...field} />
                    </Box>
                  )}
                />
              </FormControl>
              <InputLabel sx={{ ...SBLabelStyle }}>{t("priceText")}</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  defaultValue={""}
                  control={control}
                  name="priceText.de"
                  render={({ field }) => (
                    <Box
                      hidden={language.includes("en")}
                      sx={{
                        marginTop: "10px",
                        width: "100%",
                      }}
                    >
                      <MDEditor {...field} />
                    </Box>
                  )}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  defaultValue={""}
                  control={control}
                  name="priceText.en"
                  render={({ field }) => (
                    <Box
                      hidden={language.includes("de")}
                      sx={{
                        marginTop: "-11px",
                        width: "100%",
                      }}
                    >
                      <MDEditor {...field} />
                    </Box>
                  )}
                />
              </FormControl>
            </Box>
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <Box
          sx={{
            ...SBButtonGridStyle,
          }}
        >
          <SBLoadingButton
            label={t("save")}
            type="blue"
            icon={<Save />}
            isLoading={false}
          />
        </Box>
      </form>
    </SBMainComponentGridWrapper>
  );
};

export default Location;
