import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BookingsApiBookingsControllerRemoveExtrasFromBookingRequest as Variables } from "../../../../api-client/generated";
import { bookingsApi } from "../../../../api/apiTanStack";

export function useRemoveBookingExtras() {
  const queryClient = useQueryClient();
  const { mutate: removeBookingExtras, isLoading } = useMutation(
    ({ extrasBookingId, id }: Variables) =>
      bookingsApi.bookingsControllerRemoveExtrasFromBooking({
        extrasBookingId,
        id,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );

  return {
    removeBookingExtras,
    isLoading,
  };
}
