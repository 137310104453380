import {
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { bookingsApi } from "../../../../api/apiTanStack";

interface UseAssignCustomerToBooking {
  assignCustomerToBooking: UseMutateFunction<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

interface Variables {
  customerId: number;
  bookingId: number;
}

export const useAssignCustomerToBooking = (): UseAssignCustomerToBooking => {
  const queryClient = useQueryClient();
  const { mutate: assignCustomerToBooking, isLoading } = useMutation<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >(
    ["assignCustomerToBooking"],
    ({ customerId, bookingId }: Variables) =>
      bookingsApi.bookingsControllerAddCustomerToBooking({
        id: Number(bookingId),
        id2: { id: Number(customerId) },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );

  return {
    assignCustomerToBooking,
    isLoading,
  };
};
