import { useFieldArray, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getRegionRequest,
  postRegionRequest,
  putRegionRequest,
} from "../../store/reducers/destinationReducer";
import { getDestinationsRegionSelector } from "../../store/selectors/destinationSelectors";

export function useRegionForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const data = useSelector(getDestinationsRegionSelector(id));

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        countryId: yup
          .number()
          .moreThan(0, t("destinations.countryId") + t("isMandatory"))
          .required(t("destinations.countryId") + t("isMandatory")),
        name: yup.string().required(t("destinations.name") + t("isMandatory")),
        shortDescription: yup
          .string()
          .required(t("destinations.shortDescription") + t("isMandatory")),
        description: yup
          .string()
          .required(t("destinations.description") + t("isMandatory")),
        geoJson: yup
          .string()
          .test(
            "test-type-coordinates",
            `${t("destinations.type")} ${t("destinations.coordinates")} ${t(
              "isMandatory"
            )}`,
            (value) =>
              value
                ? value?.includes("type") && value?.includes("coordinates")
                : false
          )
          .required(t("destinations.geoJson") + t("isMandatory")),
        active: yup.boolean().nullable(),
      }),
    [t]
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({ name: "images", control });

  useEffect(() => {
    if (!data && type !== "new") {
      dispatch(getRegionRequest({ regionId: id }));
    }
    // eslint-disable-next-line
  }, [data, id, type]);

  useEffect(() => {
    setValue("countryId", data?.countryId || 1);
    setValue("name", data?.name || "");
    setValue("shortDescription", data?.shortDescription || "");
    setValue("description", data?.description || "");
    setValue("geoJson", `${JSON.stringify(data?.geoJson || {})}`);
    setValue("active", data?.active || false);
    setValue(
      "images",
      data?.images?.map((element: string) => {
        return { value: element };
      }) || []
    );
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = useCallback(
    (formValues) => {
      formValues.geoJson = JSON.parse(formValues.geoJson);
      formValues.images = formValues.images.map(
        (items: { value: string }) => items.value
      );
      if (type === "new") {
        const { regionId, ...newFormValues } = formValues;
        dispatch(postRegionRequest({ formData: newFormValues }));
      } else {
        const { regionId, ...newFormValues } = formValues;
        dispatch(putRegionRequest({ regionId: id, formData: newFormValues }));
      }
    },
    // eslint-disable-next-line
    [id, type]
  );

  const handleReset = useCallback(() => {
    setValue("countryId", 1);
    setValue("name", "");
    setValue("shortDescription", "");
    setValue("description", "");
    setValue("geoJson", `${JSON.stringify({})}`);
    setValue("active", false);
    setValue("images", []);
    // eslint-disable-next-line
  }, []);

  return {
    control,
    errors,
    fields,
    remove,
    append,
    setValue,
    getValues,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
