import { useQuery } from "@tanstack/react-query";
import { publicApi } from "../../../api/apiTanStack";

export const useCheckBeachChairAvailable = (
  id: number,
  start?: string,
  end?: string,
  publicReference?: string
) =>
  useQuery({
    queryKey: [
      "useCheckBeachChairAvailable",
      Number(id),
      String(start),
      String(end),
      String(publicReference),
    ],
    queryFn: async () => {
      return publicApi
        .publicControllerCheckBeachChairAvailability({
          beachChairId: id,
          start: start ?? "",
          end: end ?? "",
          publicReference: publicReference ?? "",
        })
        .then((response) => response.data.available);
    },
    enabled: !!start && !!end && id > 0,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
