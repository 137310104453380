import { create } from "zustand";
import { MRT_ColumnFiltersState } from "material-react-table";

interface SBTablePersistState {
  columnFiltersPersits: MRT_ColumnFiltersState;
  setColumnFiltersPersits: (columnFilters: MRT_ColumnFiltersState) => void;
  clear: () => void;
}

export const useBookingsFiltersPersists = create<SBTablePersistState>((set) => {
  return {
    columnFiltersPersits: [],
    setColumnFiltersPersits: (columnFiltersPersits: MRT_ColumnFiltersState) =>
      set((state) => ({
        ...state,
        columnFiltersPersits,
      })),
    clear: () =>
      set((state) => ({
        ...state,
        columnFiltersPersits: [],
      })),
  };
});
