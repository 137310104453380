import { IFilterData } from "../../models/dataTable/IFilterData";

export const filterDataToString = (filterData: IFilterData[]): string => {
  return (
    filterData &&
    filterData
      .filter((filter) => `${filter.value}`.length > 0)
      .map((filter) => `filters%5B${filter.name}%5D=${filter.value}`)
      .join("&")
  );
};