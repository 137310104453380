import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  TextField,
  Button,
  Box,
  InputAdornment,
  IconButton,
  InputLabel,
  FormControl,
} from "@mui/material";
import logo_sbl from "./../../assets/logo_sbl.png";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLoginMutation } from "./hooks/useAuthQuery";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridLoginStyle,
  SBTextFieldStyle,
} from "../SBComponents/styles/SBStyles";
import SBFormHelperText from "../SBComponents/SBForms/SBFormHelperText";
import { useAuthStore } from "./hooks/authStore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ResetPassword = () => {
  const { t } = useTranslation(["common"]);
  const { email, oldPassword } = useAuthStore();

  const schema = useMemo(
    () =>
      yup.object().shape({
        newPassword: yup
          .string()
          .required(`${t("Password is required")}`)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            `${t(
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )}`
          ),
        confirmPassword: yup
          .string()
          .required(`${t("Confirm password is required")}`)
          .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            `${t(
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            )}`
          )
          .test(
            "match",
            `${t("Passwords do not match")}`,
            (value, { parent }) => value === parent.newPassword
          ),
      }),
    [t]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const loginMutation = useLoginMutation();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const handleTogglePassword = React.useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  const onSubmit = useCallback(
    (data: any) => {
      if (email && oldPassword) {
        loginMutation.mutate({
          email: email,
          password: oldPassword,
          stayLoggedIn: false,
          newPassword: data.newPassword,
        });
      }
    },
    [email, oldPassword]
  );

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          minWidth: "200px",
          maxWidth: "400px",
          alignItems: "center",
          justifyItems: "center",
        }}
      >
        <img
          src={logo_sbl}
          alt={"logo_sbl"}
          style={{ border: "0", width: "100%" }}
        />
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            marginTop: "20px",
            display: "grid",
            padding: "20px",
            gap: "10px",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
          }}
        >
          <Box sx={{ ...SBTextFieldGridLoginStyle, marginBottom: "20px" }}>
            <InputLabel sx={{ ...SBLabelStyle }}>{t("newPassword")}</InputLabel>
            <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
              <TextField
                type={showPassword ? "text" : "password"}
                sx={{ ...SBTextFieldStyle }}
                fullWidth
                {...register("newPassword")}
                error={errors.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <SBFormHelperText
                error={
                  errors.newPassword && (errors.newPassword.message as any)
                }
              />
            </FormControl>
          </Box>
          <Box sx={{ ...SBTextFieldGridLoginStyle, marginBottom: "20px" }}>
            <InputLabel sx={{ ...SBLabelStyle }}>
              {t("confirmPassword")}
            </InputLabel>
            <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
              <TextField
                type={showPassword ? "text" : "password"}
                sx={{ ...SBTextFieldStyle }}
                fullWidth
                {...register("confirmPassword")}
                error={errors.password ? true : false}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleTogglePassword} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <SBFormHelperText
                error={
                  errors.confirmPassword &&
                  (errors.confirmPassword.message as any)
                }
              />
            </FormControl>
          </Box>
          <Button type="submit" variant="contained" color="primary">
            {`${t("Set Password")}`}
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPassword;
