import { IShortUrl, IShortUrlResponse } from "../models/shortUrl/IShortUrl";
import API from "./api";

const GENERATOR_BASE_URL = `generators/redirects`;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
  crossdomain: true,
};

export const getShortUrls = (
  page = 0,
  itemsPerPage: number,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IShortUrlResponse>(
    `${GENERATOR_BASE_URL}?itemsPerPage=${itemsPerPage}&page=${page}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`,
    config
  );

export const getShortUrl = (uid: string) =>
  API.get<IShortUrl>(`${GENERATOR_BASE_URL}/${uid}`, config);

export const putShortUrl = (uid: string, data: IShortUrl) =>
  API.put<IShortUrl>(`${GENERATOR_BASE_URL}/${uid}`, data, config);

export const deleteShortUrl = (uid: string) =>
  API.delete<IShortUrl>(`${GENERATOR_BASE_URL}/${uid}`, config);

export const postShortUrl = (data: IShortUrl) =>
  API.post<IShortUrl>(`${GENERATOR_BASE_URL}`, data, config);
