import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IFilterData } from "../../models/dataTable/IFilterData";
import { IGeneratedReport } from "../../models/reports/IClosingReport";
import { IReportKeyStatus } from "../../models/reports/IReport";
import {
  ChangeBeachChairReportCreatedAtPeriodPayload,
  ChangeReportStatusFailurePayload,
  ChangeReportStatusRequestPayload,
  ChangeReportStatusSuccessPayload,
  FilterDatasChangeRequestPayload,
  GetBeachChairReportsFailurePayload,
  GetBeachChairReportsRequestPayload,
  GetBeachChairReportsSuccessPayload,
  GetClosingReportsFailurePayload,
  GetClosingReportsRequestPayload,
  GetClosingReportsSuccessPayload,
  GetReportsFailurePayload,
  GetReportsRequestPayload,
  GetReportsSuccessPayload,
  GetSepaXMLsFailurePayload,
  GetSepaXMLsRequestPayload,
  GetSepaXMLsSuccessPayload,
  PutBeachChairReportFailurePayload,
  PutBeachChairReportRequestPayload,
  PutBeachChairReportSuccessPayload,
  PutClosingReportFailurePayload,
  PutClosingReportRequestPayload,
  PutClosingReportSuccessPayload,
  PutSepaXMLFailurePayload,
  PutSepaXMLRequestPayload,
  PutSepaXMLSuccessPayload,
  RouterRedirectReportsPayload,
  SetCurrentDatePayload,
} from "../actionPayload/reportPayloads";
import { AppState } from "./rootReducer";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IClosingReportPage {
  key: string;
  closingReportsNames: string[];
}

interface ISepaXMLPage {
  key: string;
  sepaXMLNames: string[];
}

interface IBeachChairReportsPage {
  key: string;
  beachChairReportsNames: string[];
}

interface IReportsState {
  pending: boolean;
  error: string;
  currentDate: string;
  beachChairReportCreatedAtPeriod: boolean;
  beachChairReportStartDate: string;
  beachChairReportEndDate: string;
  pageBeachChairReports: EntityState<IBeachChairReportsPage>;
  beachChairReports: EntityState<IGeneratedReport>;
  beachChairReportsMaxCount: number;
  closingPickupCurrentDate: string;
  closingReportsMaxCount: number;
  keyStatus: EntityState<IReportKeyStatus>;
  keyStatusCount: number;
  filterData: IFilterData[];
  page: EntityState<IPage>;
  pageClosingReports: EntityState<IClosingReportPage>;
  closingRepots: EntityState<IGeneratedReport>;
  sepaXMLStartDate: string;
  sepaXMLEndDate: string;
  sepaXMLMaxCount: number;
  pageSepaXMLReports: EntityState<ISepaXMLPage>;
  sepaXMLs: EntityState<IGeneratedReport>;
}

const reportsAdapter = createEntityAdapter();

const keyStatusAdapter = createEntityAdapter<IReportKeyStatus>({
  selectId: (reportKeyStatus) => reportKeyStatus.id,
});

const closingReportAdapter = createEntityAdapter<IGeneratedReport>({
  selectId: (closingReport) => closingReport.name,
});

const pageSepaXMLAdapter = createEntityAdapter<ISepaXMLPage>({
  selectId: (sepaXML) => sepaXML.key,
  sortComparer: false,
});

const sepaXMLAdapter = createEntityAdapter<IGeneratedReport>({
  selectId: (sepaXML) => sepaXML.name,
});

const pageBeachChairReportsAdapter = createEntityAdapter<IBeachChairReportsPage>(
  {
    selectId: (beachChairReport) => beachChairReport.key,
    sortComparer: false,
  }
);

const beachChairReportsAdapter = createEntityAdapter<IGeneratedReport>({
  selectId: (beachChairReport) => beachChairReport.name,
});

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const pageClosingReportsAdapter = createEntityAdapter<IClosingReportPage>({
  selectId: (page: IClosingReportPage) => page.key,
  sortComparer: false,
});

const yesterday = (): string => {
  var date = new Date();
  date.setDate(date.getDate() - 1);
  return date.toISOString();
};

const INITIAL_STATE = reportsAdapter.getInitialState<IReportsState>({
  pending: false,
  error: "",
  currentDate: yesterday(),
  beachChairReportCreatedAtPeriod: false,
  beachChairReportStartDate: yesterday(),
  beachChairReportEndDate: yesterday(),
  pageBeachChairReports: pageBeachChairReportsAdapter.getInitialState({}),
  beachChairReports: beachChairReportsAdapter.getInitialState({}),
  beachChairReportsMaxCount: 0,
  closingPickupCurrentDate: yesterday(),
  pageClosingReports: pageClosingReportsAdapter.getInitialState({}),
  closingRepots: closingReportAdapter.getInitialState({}),
  closingReportsMaxCount: 0,
  keyStatus: keyStatusAdapter.getInitialState(),
  keyStatusCount: 0,
  filterData: [],
  page: pageAdapter.getInitialState({}),
  sepaXMLStartDate: yesterday(),
  sepaXMLEndDate: yesterday(),
  sepaXMLMaxCount: 0,
  pageSepaXMLReports: pageSepaXMLAdapter.getInitialState({}),
  sepaXMLs: sepaXMLAdapter.getInitialState({}),
});

const reportSlice = createSlice({
  name: "reports",
  initialState: INITIAL_STATE,
  reducers: {
    resetReportRequest() {
      return INITIAL_STATE;
    },
    getReportsKeyStatusRequest(
      state,
      action: PayloadAction<GetReportsRequestPayload>
    ) {
      state.pending = true;
    },
    getReportsKeyStatusSuccess(
      state,
      action: PayloadAction<GetReportsSuccessPayload>
    ) {
      state.pending = false;
      keyStatusAdapter.upsertMany(state.keyStatus, action.payload.keyStatuses);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.keyStatuses.map((keyStatus) => keyStatus.id),
        ],
      });
      state.keyStatusCount = action.payload.count;
    },
    getReportsKeyStatusFailure(
      state,
      action: PayloadAction<GetReportsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterDataChangeRequest(
      state,
      action: PayloadAction<FilterDatasChangeRequestPayload>
    ) {
      state.filterData = state.filterData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    changeReportStatusRequest(
      state,
      action: PayloadAction<ChangeReportStatusRequestPayload>
    ) {},
    changeReportStatusSuccess(
      state,
      action: PayloadAction<ChangeReportStatusSuccessPayload>
    ) {
      keyStatusAdapter.updateOne(state.keyStatus, {
        id: action.payload.keyStatus.id,
        changes: action.payload.keyStatus,
      });
    },
    changeReportStatusFailure(
      state,
      action: PayloadAction<ChangeReportStatusFailurePayload>
    ) {
      state.error = action.payload.error;
    },
    changeDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    },
    setReportKeyStatusCurrentDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.currentDate = action.payload.date;
      pageAdapter.removeAll(state.page);
    },
    setClosingReportPickupCurrentDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.closingPickupCurrentDate = action.payload.date;
      pageClosingReportsAdapter.removeAll(state.pageClosingReports);
    },
    putClosingReportRequest(
      state,
      action: PayloadAction<PutClosingReportRequestPayload>
    ) {
      state.pending = true;
    },
    putClosingReportSuccess(
      state,
      action: PayloadAction<PutClosingReportSuccessPayload>
    ) {
      state.pending = false;
    },
    putClosingReportFailure(
      state,
      action: PayloadAction<PutClosingReportFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getClosingReportsRequest(
      state,
      action: PayloadAction<GetClosingReportsRequestPayload>
    ) {
      state.pending = true;
    },
    getClosingReportsSuccess(
      state,
      action: PayloadAction<GetClosingReportsSuccessPayload>
    ) {
      state.pending = false;
      closingReportAdapter.upsertMany(
        state.closingRepots,
        action.payload.closingReports
      );
      pageClosingReportsAdapter.upsertOne(state.pageClosingReports, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        closingReportsNames: [
          ...action.payload.closingReports.map(
            (closingReport) => closingReport.name
          ),
        ],
      });
      state.closingReportsMaxCount = action.payload.count;
    },
    getClosingReportsFailure(
      state,
      action: PayloadAction<GetClosingReportsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeClosingReportsDataRequest(state) {
      state.pending = true;
      pageClosingReportsAdapter.removeAll(state.pageClosingReports);
    },
    /**SEPA XML */
    changeSepaXMLsDataRequest(state) {
      state.pending = true;
      pageSepaXMLAdapter.removeAll(state.pageSepaXMLReports);
    },
    changeSepaXMLStartDateRequest(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.sepaXMLStartDate = action.payload.date;
    },
    changeSepaXMLEndDateRequest(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.sepaXMLEndDate = action.payload.date;
    },
    getSepaXMLsRequest(
      state,
      action: PayloadAction<GetSepaXMLsRequestPayload>
    ) {
      state.pending = true;
    },
    getSepaXMLsSuccess(
      state,
      action: PayloadAction<GetSepaXMLsSuccessPayload>
    ) {
      state.pending = false;
      sepaXMLAdapter.upsertMany(state.sepaXMLs, action.payload.sepaXMLs);
      pageSepaXMLAdapter.upsertOne(state.pageSepaXMLReports, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        sepaXMLNames: [
          ...action.payload.sepaXMLs.map((sepaXML) => sepaXML.name),
        ],
      });
      state.sepaXMLMaxCount = action.payload.count;
    },
    getSepaXMLsFailure(
      state,
      action: PayloadAction<GetSepaXMLsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    putSepaXMLRequest(state, action: PayloadAction<PutSepaXMLRequestPayload>) {
      state.pending = true;
    },
    putSepaXMLSuccess(state, action: PayloadAction<PutSepaXMLSuccessPayload>) {
      state.pending = false;
    },
    putSepaXMLFailure(state, action: PayloadAction<PutSepaXMLFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    /**END SEPA XML */
    /**BEACH CHAIR */
    changeBeachChairReportCreatedAtPeriodRequest(
      state,
      action: PayloadAction<ChangeBeachChairReportCreatedAtPeriodPayload>
    ) {
      state.beachChairReportCreatedAtPeriod = action.payload.toggle;
    },
    changeBeachChairReportDataRequest(state) {
      state.pending = true;
      pageBeachChairReportsAdapter.removeAll(state.pageBeachChairReports);
    },
    changeBeachChairReportStartDateRequest(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.beachChairReportStartDate = action.payload.date;
    },
    changeBeachChairReportEndDateRequest(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.beachChairReportEndDate = action.payload.date;
    },
    getBeachChairReportsRequest(
      state,
      action: PayloadAction<GetBeachChairReportsRequestPayload>
    ) {
      state.pending = true;
    },
    getBeachChairReportsSuccess(
      state,
      action: PayloadAction<GetBeachChairReportsSuccessPayload>
    ) {
      state.pending = false;
      beachChairReportsAdapter.upsertMany(
        state.beachChairReports,
        action.payload.beachChairReports
      );
      pageBeachChairReportsAdapter.upsertOne(state.pageBeachChairReports, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        beachChairReportsNames: [
          ...action.payload.beachChairReports.map(
            (beachChairReports) => beachChairReports.name
          ),
        ],
      });
      state.beachChairReportsMaxCount = action.payload.count;
    },
    getBeachChairReportsFailure(
      state,
      action: PayloadAction<GetBeachChairReportsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    putBeachChairReportRequest(
      state,
      action: PayloadAction<PutBeachChairReportRequestPayload>
    ) {
      state.pending = true;
    },
    putBeachChairReportSuccess(
      state,
      action: PayloadAction<PutBeachChairReportSuccessPayload>
    ) {
      state.pending = false;
    },
    putBeachChairReportFailure(
      state,
      action: PayloadAction<PutBeachChairReportFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    /**END BEACH CHAIR */
    routerRedirectReports(
      state,
      action: PayloadAction<RouterRedirectReportsPayload>
    ) {},
  },
});

export const {
  selectAll: getAllPageBeachChairReportsPages,
  selectById: getPageBeachChairReportsPages,
} = pageBeachChairReportsAdapter.getSelectors<AppState>(
  (state) => state.reports.pageBeachChairReports
);

export const {
  selectAll: getAllBeachChairReports,
  selectById: getBeachChairReports,
} = beachChairReportsAdapter.getSelectors<AppState>(
  (state) => state.reports.beachChairReports
);

export const {
  selectAll: getAllReportsKeyStatusPages,
  selectById: getReportsKeyStatusPages,
} = pageAdapter.getSelectors<AppState>((state) => state.reports.page);

export const {
  selectAll: getAllClosingReportsPages,
  selectById: getClosingReportPages,
} = pageClosingReportsAdapter.getSelectors<AppState>(
  (state) => state.reports.pageClosingReports
);

export const {
  selectAll: getAllClosingReport,
  selectTotal: getClosingReportTotalCount,
} = closingReportAdapter.getSelectors<AppState>(
  (state) => state.reports.closingRepots
);

export const {
  selectAll: getAllSepaXMLPages,
  selectById: getSepaXMLPages,
} = pageSepaXMLAdapter.getSelectors<AppState>(
  (state) => state.reports.pageSepaXMLReports
);

export const {
  selectAll: getAllSepaXMLs,
  selectTotal: getSepaXMLsTotalCount,
} = sepaXMLAdapter.getSelectors<AppState>((state) => state.reports.sepaXMLs);

export const {
  selectAll: getAllReportKeyStatus,
  selectById: getReportKeyStatusById,
  selectTotal: getReportKeyStatusTotalCount,
} = keyStatusAdapter.getSelectors<AppState>((state) => state.reports.keyStatus);

export const {
  resetReportRequest,
  getReportsKeyStatusRequest,
  getReportsKeyStatusSuccess,
  getReportsKeyStatusFailure,
  filterDataChangeRequest,
  changeReportStatusRequest,
  changeReportStatusSuccess,
  changeReportStatusFailure,
  changeDataRequest,
  setReportKeyStatusCurrentDate,
  changeClosingReportsDataRequest,
  setClosingReportPickupCurrentDate,
  getClosingReportsRequest,
  getClosingReportsSuccess,
  getClosingReportsFailure,
  putClosingReportRequest,
  putClosingReportSuccess,
  putClosingReportFailure,
  /**SEPA XML */
  changeSepaXMLsDataRequest,
  changeSepaXMLStartDateRequest,
  changeSepaXMLEndDateRequest,
  getSepaXMLsRequest,
  getSepaXMLsSuccess,
  getSepaXMLsFailure,
  putSepaXMLRequest,
  putSepaXMLSuccess,
  putSepaXMLFailure,
  /** END SEPA XML */
  /** BEACH CHAIRS */
  changeBeachChairReportCreatedAtPeriodRequest,
  changeBeachChairReportDataRequest,
  changeBeachChairReportStartDateRequest,
  changeBeachChairReportEndDateRequest,
  getBeachChairReportsRequest,
  getBeachChairReportsSuccess,
  getBeachChairReportsFailure,
  putBeachChairReportRequest,
  putBeachChairReportSuccess,
  putBeachChairReportFailure,
  /** END BEACH CHAIRS */
  routerRedirectReports,
} = reportSlice.actions;

export default reportSlice.reducer;
