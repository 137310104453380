import { IconButton, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import {
  setAvabilityLayout,
  setPositionView,
} from "../../../store/reducers/availabilityReducer";
import CalendarViewDayRoundedIcon from "@mui/icons-material/CalendarViewDayRounded";
import FormatAlignJustifyRoundedIcon from "@mui/icons-material/FormatAlignJustifyRounded";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PrintIcon from "@mui/icons-material/Print";
import * as htmlToImage from "html-to-image";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AvailabilitiesPrintPickers from "../availabilitiesBeachChairs/availabilitiesBeachChairsGrid/availabilitiesPrintPickers";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models/range";
import { Dayjs } from "dayjs";
import {
  SBColor,
  SBComponentHeightStyle,
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
  SBHeaderItemsColumnsStyle,
} from "../../SBComponents/styles/SBStyles";
import { useAvailabilitiesDatesStore } from "../hooks/useAvailabilitiesDatesStore";
import { useAvailabilitiesSections } from "../hooks/useAvailabilitiesSections";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import { useSelectedBeachChairDates } from "../../bookingsList/store/useSelectedBeachChairDates";
import { deDE } from "@mui/x-date-pickers/locales";
import { useEditBeachChairsMapStore } from "../availabilitiesBeachChairs/availabilitiesBeachChairsMap/hooks/useEditBeachChairsMapStore";

const AvailabilitiesHeader = () => {
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation(["common"]);
  const { sectionId } = useParams<{ sectionId: string; type: string }>();
  const { availabilitiesDateRange, setAvailabilitiesDateRange } =
    useAvailabilitiesDatesStore();
  const { data: availabilities } = useAvailabilitiesSections(
    availabilitiesDateRange[0]?.toISOString(),
    availabilitiesDateRange[1]?.toISOString()
  );
  const availability = useMemo(
    () =>
      availabilities?.flatMap((availability) =>
        availability.sections.filter((section) => section.id === +sectionId)
      )[0],
    [availabilities, sectionId]
  );
  const [availabilityLayout, setAvailabilityLayout] = useState<boolean>(false);
  const [positionState, setPositionState] = useState<boolean>(false);
  const { setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const { draggable, setDraggable } = useEditBeachChairsMapStore();
  const dispatch = useDispatch();
  const { type } = useParams<{ type: string }>();
  const gridState = type === "grid";
  const ifMap = type === undefined;

  const onDateRangeChange = (newDateRange: DateRange<Dayjs>) => {
    if (newDateRange && newDateRange[0]) {
      setAvailabilitiesDateRange([newDateRange[0], newDateRange[0]]);
      setBookingStartDateTime(newDateRange[0]);
      setBookingEndDateTime(newDateRange[0]);
    }
    if (newDateRange && newDateRange[0] && newDateRange[1]) {
      setAvailabilitiesDateRange(newDateRange);
      setBookingStartDateTime(newDateRange[0]);
      setBookingEndDateTime(newDateRange[1]);
    }
  };

  return (
    <SubPageHeader headerTitle={t("availabilities")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {availability && (
          <SBHeaderLink
            label={t("availabilities")}
            onClick={() => {
              history.push(`/availabilities/${type}`);
            }}
          />
        )}
        {availability && <SBHeaderTypography label={availability?.name} />}
        {!availability && <SBHeaderTypography label={t("availabilities")} />}
      </SBBreadcrumbsWrapper>

      <Box
        sx={{
          justifyContent: "flex-end",
          ...SBHeaderItemsColumnsStyle,
        }}
      >
        {sectionId && !gridState && !ifMap && (
          <>
            <IconButton
              aria-label="filter"
              data-testid="filter-trigger"
              sx={{
                pt: "10px",
                height: "40px",
                color: theme.palette.primary.main,
              }}
              onClick={() => {
                setAvailabilityLayout(false);
                dispatch(setAvabilityLayout({ avabilityLayout: false }));
                setTimeout(() => {
                  const node = document.getElementById("boxContent");
                  if (node) {
                    const nodeClassName = node.getAttribute("class");
                    node.setAttribute("class", "fixedWIdth");
                    const firstChildClassName =
                      node.children[0].getAttribute("class");
                    const secondChildClassName =
                      node.children[1].getAttribute("class");
                    node.children[0].setAttribute("class", "showPrint");
                    node.children[1].setAttribute("class", "showPrint");
                    htmlToImage
                      .toPng(node)
                      .then(function (dataUrl) {
                        var img = new Image();
                        img.src = dataUrl;
                        node.setAttribute("class", nodeClassName || "");
                        node.children[0].setAttribute(
                          "class",
                          firstChildClassName || ""
                        );
                        node.children[1].setAttribute(
                          "class",
                          secondChildClassName || ""
                        );
                        const newWindow = window.open("", "_blank");
                        if (newWindow) {
                          newWindow.document.body.appendChild(img);

                          setTimeout(() => {
                            newWindow.print();
                          }, 1000);
                        }
                      })
                      .catch(function (error) {
                        console.error("oops, something went wrong!", error);
                      });
                  }
                }, 500);
              }}
              disabled={positionState}
            >
              <PrintIcon />
            </IconButton>
            <IconButton
              aria-label="filter"
              data-testid="filter-trigger"
              sx={{
                pt: "10px",
                height: "40px",
                width: "40px",
                color: theme.palette.primary.main,
                border: positionState ? `3px solid ${SBColor.orange}` : "none",
              }}
              onClick={() => {
                setAvailabilityLayout(false);
                dispatch(setAvabilityLayout({ avabilityLayout: false }));
                setPositionState(!positionState);
                dispatch(setPositionView({ isPosition: !positionState }));
              }}
            >
              {positionState ? <EditIcon /> : <EditOutlinedIcon />}
            </IconButton>
            <IconButton
              aria-label="layout"
              data-testid="layout-trigger"
              sx={{
                pt: "10px",
                height: "40px",
                color: theme.palette.primary.main,
              }}
              onClick={() => {
                setAvailabilityLayout(!availabilityLayout);
                dispatch(
                  setAvabilityLayout({ avabilityLayout: !availabilityLayout })
                );
              }}
              disabled={positionState}
            >
              {availabilityLayout ? (
                <FormatAlignJustifyRoundedIcon />
              ) : (
                <CalendarViewDayRoundedIcon />
              )}
            </IconButton>
          </>
        )}
        {ifMap ? (
          <IconButton
            aria-label="filter"
            data-testid="filter-trigger"
            sx={{
              pt: "10px",
              height: "40px",
              width: "40px",
              color: theme.palette.primary.main,
              border: draggable ? `3px solid ${SBColor.orange}` : "none",
            }}
            onClick={() => {
              setDraggable(!draggable);
            }}
          >
            {draggable ? <EditIcon /> : <EditOutlinedIcon />}
          </IconButton>
        ) : null}
        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          /*localeText={
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        }*/
        >
          <MobileDateRangePicker
            disablePast
            onChange={onDateRangeChange}
            value={availabilitiesDateRange}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
                ...SBComponentHeightStyle,
              },
            }}
            localeText={{
              ...deDE,
              ...{ start: `${t("start")}`, end: `${t("end")}` },
            }}
          />
        </LocalizationProvider>
        {sectionId && gridState && <AvailabilitiesPrintPickers />}
      </Box>
    </SubPageHeader>
  );
};

export default AvailabilitiesHeader;
