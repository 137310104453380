import logo_sbl from "./../../assets/logo_sbl.png";
import { Box } from "@mui/material";

const Settings = () => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={logo_sbl} alt={"logo_sbl"} />
    </Box>
  );
};

export default Settings;
