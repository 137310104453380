import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { ResponseUserDto, UsersApiUsersControllerUpdateRequest } from "../../../api-client/generated";
import { usersApi } from "../../../api/apiTanStack";

export type UpdateUserVariables = UsersApiUsersControllerUpdateRequest;

interface UpdateVendorUser {
  updateVendorUser: UseMutateFunction<
    AxiosResponse<ResponseUserDto>,
    AxiosError,
    UpdateUserVariables
  >;
  isLoading: boolean;
}

export const useUpdateVendorUser = (): UpdateVendorUser => {
  const { mutate: updateVendorUser, isLoading } = useMutation<
    AxiosResponse<ResponseUserDto>,
    AxiosError,
    UpdateUserVariables
  >(["updateVendorUser"], (
    { updateUserBodyDto, id }: UpdateUserVariables = {} as UpdateUserVariables) =>
    usersApi.usersControllerUpdate({ id, updateUserBodyDto })
  );

  return {
    updateVendorUser,
    isLoading,
  };
};
