import { customStyle } from "../../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Box,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { postAdminContextVendorRequest } from "../../../../store/reducers/adminReducer";
import React from "react";
import { useAdminChangeVendor } from "../../../../utils/customHooks/useAdminChangeVendor";
import { IVendor } from "../../../../models/vendor/IVendor";
import {
  autocomplete,
  autocompleteInput,
} from "../../../../utils/customStyles/globalStyles";
import { SBColor } from "../../../SBComponents/styles/SBStyles";

export const VendorSelect: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { vendorId, vendors } = useAdminChangeVendor();
  const [value, setValue] = React.useState<IVendor | null>(null);

  React.useEffect(() => {
    if (vendorId) {
      setValue(vendors.find((vendor) => vendor.id === vendorId) || null);
    }
  }, [vendors, vendorId]);

  const onChange = React.useCallback((option: IVendor | null) => {
    setValue(option);
    option && dispatch(postAdminContextVendorRequest({ vendorId: option.id }));
    // eslint-disable-next-line
  }, []);

  return (
    <List key="superAdminVendorSelect" data-testid="superAdminVendorSelect">
      <ListItem
        sx={{
          "&.MuiListItem-root": {
            height: "70px",
            width: customStyle.menuWidth,
            backgroundColor: customStyle.mainColor,
            color: customStyle.textColor,
            opacity: "0.8",
          },
        }}
        key="superAdminVendorSelectListItem"
        data-testid="superAdminVendorSelectListItem"
      >
        <Box
          sx={{
            display: "grid",
            width: "300px",
            gap: "px",
            gridTemplateRows: "auto auto",
          }}
        >
          <Typography
            data-testid="superAdminVendorSelectLabel"
            sx={{ fontWeight: "bold" }}
          >
            {t("selectVendor")}
          </Typography>
          {vendorId && (
            <Autocomplete
              value={value}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    width: "300px!important",
                    "*::-webkit-scrollbar": {
                      width: "2px",
                    },
                    "*::-webkit-scrollbar-thumb": {
                      backgroundColor: SBColor.scroll,
                    },
                  }}
                />
              )}
              id="superAdminVendorSelectSelect"
              options={vendors}
              getOptionLabel={(option) => `${option.name} id:${option.id}`}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                ...autocomplete,
              }}
              renderOption={(props, option: IVendor) => (
                <Typography
                  {...props}
                  sx={{
                    color: option.activeAvailability
                      ? SBColor.blue
                      : SBColor.red,
                    fontSize: "12px!important",
                    fontWeight: "bold!important",
                  }}
                >{`${option.name} id:${option.id}`}</Typography>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{ input: autocompleteInput }}
                  variant="outlined"
                />
              )}
              onChange={(_, newValue) => {
                onChange(newValue);
              }}
            />
          )}
        </Box>
      </ListItem>
    </List>
  );
};
