import { useQuery } from "@tanstack/react-query";
import { featuresApi } from "../../../api/apiTanStack";
import { SectionDetailsDto } from "../../../api-client/generated";

export interface IBeachSections {
  id: number;
  name: string;
  sections: SectionDetailsDto[];
}

export const useAvailabilitiesSections = (
  startDate?: string,
  endDate?: string
) =>
  useQuery({
    queryKey: ["availabilitiesSections", startDate, endDate],
    queryFn: async () => {
      return featuresApi
        .availabilitiesControllerGetSectionsAvailability({
          startDate,
          endDate,
        })
        .then((response) => {
          const beachList = response.data.flatMap((section) => section.beach);
          const setBeachList = beachList.filter(
            (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
          );
          return setBeachList.flatMap((beach) => {
            return {
              id: beach.id,
              name: beach.name,
              sections: response.data.filter(
                (section) => section.beach.id === beach.id
              ),
            };
          });
        });
    },
    enabled: !!startDate && !!endDate,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
