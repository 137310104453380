import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Typography,
  Box,
  Stack,
  InputLabel,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useParams } from "react-router";
import {
  TimeZone,
  formatInUtc,
} from "../../../../utils/conversions/dataConversion";
import { useSelectedBeachChair } from "../../../../utils/customHooks/useSelectedBeachChair";
import { useTranslation } from "react-i18next";
import React, { useCallback, useMemo } from "react";
import { useGetBeachChairBooking, useUpdateBeachChairBooking } from "../hooks";
import {
  DateTimePicker,
  LocalizationProvider,
  deDE,
} from "@mui/x-date-pickers";
import {
  SBDatePickerStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
} from "../../../SBComponents/styles/SBStyles";
import { CalendarToday, Euro } from "@mui/icons-material";
import SBNumberField from "../../../SBComponents/SBForms/SBNumberField";
import { setBookingDateTime } from "../../../../utils/conversions/locationsTimes";
import dayjs from "dayjs";
import { useSelectedBeachChairDates } from "../../store/useSelectedBeachChairDates";
import { useGetBeachChair } from "../../../beachChairList/hooks/useGetBeachChair";
import { useSectionData } from "../../../../utils/customHooks/useSectionData";
import { useLocationData } from "../../../../utils/customHooks/useLocationData";

interface Props {
  beachChairBookingId: number;
  viewType?: "edit" | "show";
  onClose: () => void;
}

export const SelectedBookingBeachChair: React.FC<Props> = ({
  beachChairBookingId,
  viewType = "show",
  onClose,
}) => {
  const { id: bookingId } = useParams<{ id?: string }>();
  const { t } = useTranslation(["common"]);

  const queryClient = useQueryClient();
  const { beachChairBooking } = useGetBeachChairBooking({
    id: Number(bookingId),
    beachChairBookingId,
  });
  const { data: beachChair } = useGetBeachChair(
    beachChairBooking?.beachChairId ?? 0
  );

  const { control, handleSubmit, errors, isSubmitting } =
    useSelectedBeachChair(beachChairBooking);

  const { updateBeachChairBooking, isLoading: isUpdating } =
    useUpdateBeachChairBooking();

  const { dateTime, setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const { sectionData } = useSectionData(beachChair?.sectionId || 0);
  const { locationData } = useLocationData(sectionData?.locationId);
  const {
    bookingStartDateTime,
    bookingStartDateTimeEnd,
    bookingEndDateTime,
    bookingEndDateTimeStart,
  } = setBookingDateTime(
    [dayjs(beachChairBooking?.start), dayjs(beachChairBooking?.end)],
    locationData,
    beachChair?.afterHourBooking
  );

  const startTime = useMemo(() => {
    if (bookingStartDateTime) {
      return dayjs().set("hour", bookingStartDateTime.get("hour"));
    }
    return null;
  }, [bookingStartDateTime]);

  const endTime = useMemo(() => {
    if (bookingStartDateTimeEnd) {
      return dayjs().set("hour", bookingStartDateTimeEnd.get("hour"));
    }
    return null;
  }, [bookingStartDateTimeEnd]);

  const onSubmit = useCallback(
    (formValues: any) => {
      if (beachChairBooking) {
        updateBeachChairBooking(
          {
            id: beachChairBooking.id,
            data: {
              start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
              end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
              price: formValues.price,
            },
          },
          {
            onSuccess: async () => {
              await queryClient.refetchQueries([
                "beachChairBooking",
                String(bookingId),
                String(beachChairBooking.id),
              ]);
              await queryClient.refetchQueries(["booking", Number(bookingId)]);
              onClose();
            },
          }
        );
      }
    },
    [dateTime]
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <Box sx={{ p: 4 }}>
        <Typography variant="h6" component="p">
          {t(`models.${beachChairBooking?.model}`)}
        </Typography>

        <Typography>
          {t("beachChairsDataTable.publicNumber")}:{" "}
          {beachChairBooking?.publicNumber || `&mdash;`}
        </Typography>

        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Stack>
            <Box
              sx={{
                ...SBTextFieldGridStyle,
              }}
            >
              <Box sx={{ ...SBTextFieldIconStyle }}>
                <CalendarToday />
              </Box>
              <Box>
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {t("bookingStart")}
                </InputLabel>
                <DateTimePicker
                  disablePast
                  minDate={startTime}
                  minTime={startTime}
                  maxTime={endTime}
                  value={dateTime[0]}
                  onChange={(newDate) => {
                    if (newDate) {
                      const { bookingStartDateTime, bookingStartDateTimeEnd } =
                        setBookingDateTime(
                          [newDate, newDate],
                          locationData,
                          beachChair?.afterHourBooking
                        );
                      const isSame = newDate.isSame(dateTime[1], "day");
                      setBookingStartDateTime(
                        isSame ? newDate : bookingStartDateTime || newDate,
                        bookingStartDateTimeEnd || undefined
                      );
                    }
                  }}
                  sx={{ ...SBDatePickerStyle }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      sx: { ...SBDatePickerStyle, borderColor: "white" },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                ...SBTextFieldGridStyle,
              }}
            >
              <Box sx={{ ...SBTextFieldIconStyle }}>
                <CalendarToday />
              </Box>
              <Box>
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {t("bookingEnd")}
                </InputLabel>
                <DateTimePicker
                  minDate={dateTime[0]}
                  minTime={bookingEndDateTimeStart}
                  maxTime={bookingEndDateTime}
                  value={dateTime[1]}
                  onChange={(newDate) => {
                    if (newDate) {
                      const { bookingEndDateTime } = setBookingDateTime(
                        [newDate, newDate],
                        locationData,
                        beachChair?.afterHourBooking
                      );
                      const isSame = newDate.isSame(dateTime[0], "day");
                      setBookingEndDateTime(
                        isSame ? newDate : bookingEndDateTime || newDate
                      );
                    }
                  }}
                  sx={{ ...SBDatePickerStyle }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      sx: { ...SBDatePickerStyle, borderColor: "white" },
                    },
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </LocalizationProvider>
        <Stack>
          <Box>
            <SBNumberField
              name="price"
              label={t("price")}
              control={control}
              type={"edit"}
              icon={<Euro />}
              isLoading={false}
              defaultValue={0}
              endAdornmentSymbol="€"
              error={errors?.price && t((errors.price as any)?.message)}
            />
          </Box>

          {viewType === "edit" && (
            <Box>
              <LoadingButton
                variant="contained"
                type="submit"
                endIcon={<ChevronRightIcon />}
                loading={isSubmitting || isUpdating}
              >
                {t("update")}
              </LoadingButton>
            </Box>
          )}
        </Stack>
      </Box>
    </form>
  );
};
