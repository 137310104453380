import { useQuery } from "@tanstack/react-query";
import { featuresApi } from "../../../api/apiTanStack";

export const useGetBeachChairsFilters = () =>
  useQuery({
    queryKey: ["beachChairFilters"],
    queryFn: async () => {
      return featuresApi
        .filtersControllerGetChairsFilters()
        .then((response) => response.data.filter);
    },
    cacheTime: Infinity,
    keepPreviousData: true,
  });
