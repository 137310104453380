export enum FiskalyVatIds {
  STANDARD = 1,
  REDUCED = 2,
  NON_TAXABLE = 5,
}

export enum vatPercent {
  STANDARD = 19,
  REDUCED = 7,
  NON_TAXABLE = 0,
}

export enum FiskalyBusinessCaseTypeEnum {
  ANFANGSBESTAND = 'Anfangsbestand', // Initial balance
  UMSATZ = 'Umsatz', // Sales
  PFAND = 'Pfand', // Deposit for empty bottles or a key
  PFANDRUECKZAHLUNG = 'PfandRueckzahlung', // Deposit refund (for PFAND)
  MEHRZWECKGUTSCHEINKAUF = 'MehrzweckgutscheinKauf', // Multi-purpose voucher purchase
  MEHRZWECKGUTSCHEINEINLOESUNG = 'MehrzweckgutscheinEinloesung', // Multi-purpose voucher redemption
  EINZWECKGUTSCHEINKAUF = 'EinzweckgutscheinKauf', // Single-purpose voucher purchase
  EINZWECKGUTSCHEINEINLOESUNG = 'EinzweckgutscheinEinloesung', // Single-purpose voucher redemption
  FORDERUNGSENTSTEHUNG = 'Forderungsentstehung', // Receivable creation
  FORDERUNGSAUFLOESUNG = 'Forderungsaufloesung', // Receivable resolution
  ANZAHLUNGSEINSTELLUNG = 'Anzahlungseinstellung', // Down payment setting
  ANZAHLUNGSAUFLOESUNG = 'Anzahlungsaufloesung', // Down payment resolution
  PRIVATEINLAGE = 'Privateinlage', // Private deposit
  PRIVATENTNAHME = 'Privatentnahme', // Private withdrawal
  GELDTRANSIT = 'Geldtransit', // Cash transit
  DIFFERENZSOLLIST = 'DifferenzSollIst', // Difference debit/credit
  TRINKGELDAG = 'TrinkgeldAG', // Revenue from a tip for the company/employer
  TRINKGELDAN = 'TrinkgeldAN', // Revenue from a tip for the employee
  AUSZAHLUNG = 'Auszahlung', // Payout
  EINZAHLUNG = 'Einzahlung', // Deposit
  RABATT = 'Rabatt', // Discount
  AUFSCHLAG = 'Aufschlag', // Surcharge
  LOHNZAHLUNG = 'Lohnzahlung', // Salary payment
  ZUSCHUSSECHT = 'ZuschussEcht', // Subsidy
  ZUSCHUSSUNECHT = 'ZuschussUnecht', // Subsidy
}

export enum FiskalyTransactionHeadTypeEnum {
  BELEG = 'Beleg', // Receipt
  AVTRANSFER = 'AVTransfer', // AV transfer
  AVBESTELLUNG = 'AVBestellung', // AV order
  AVTRAINING = 'AVTraining', // AV training
  AVBELEGSTORNO = 'AVBelegstorno', // AV receipt cancellation
  AVBELEGABBRUCH = 'AVBelegabbruch', // AV receipt abort
  AVSACHBEZUG = 'AVSachbezug', // AV benefit in kind
  AVSONSTIGE = 'AVSonstige', // AV other
  AVRECHNUNG = 'AVRechnung', // AV invoice
}
