import { shortUrlToRow } from "../../utils/conversions/shortUrlToRow";
import { createSelector } from "reselect";
import {
  selectShortUrlById,
  selectShortUrls,
} from "../reducers/shortUrlsReducer";
import { AppState } from "../reducers/rootReducer";
import { getShortUrlPages } from "../reducers/shortUrlsReducer";

const getState = (state: AppState) => state;
const getTotalCount = (state: AppState) => state.shortUrls.count;
const getPending = (state: AppState) => state.shortUrls.pending;
const getError = (state: AppState) => state.shortUrls.error;

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getShortUrls = createSelector(getState, (state) =>
  selectShortUrls(state)
);

export const getShortUrlsSelector = createSelector(getShortUrls, (shortUrls) =>
  shortUrls.map(shortUrlToRow)
);

export const getShortUrlSelector = (uid: string) =>
  createSelector(getState, (state) => selectShortUrlById(state, uid));

export const getShortUrlsPage = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) => {
    const page = getShortUrlPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.dataIds
        .map((id) => selectShortUrlById(state, id)!)
        .map(shortUrlToRow)
    );
  });

const getShortURLFilterData = (state: AppState) =>
  state.shortUrls.filterShortURLData;

export const getShortURLFilterDataSelector = createSelector(
  getShortURLFilterData,
  (filterData) => filterData
);

export const getTotalCountSelector = createSelector(
  getTotalCount,
  (count) => count
);

