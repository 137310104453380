import { create } from "zustand";

interface EditBeachChairsMapStore {
  draggable: boolean;
  setDraggable: (draggable: boolean) => void;
}

export const useEditBeachChairsMapStore = create<EditBeachChairsMapStore>(
  (set) => {
    return {
      draggable: false,
      setDraggable: (draggable: boolean) =>
        set((state) => ({
          ...state,
          draggable,
        })),
    };
  }
);
