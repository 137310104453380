import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { deleteLock, getLock, postLock, putLock } from "../../api/lockApi";
import {
  deleteLockRequest,
  deleteLockSuccess,
  getLockRequest,
  getLockSuccess,
  locksFailure,
  postLockRequest,
  postLockSuccess,
  putLockRequest,
  putLockSuccess,
} from "../reducers/lockReducer";
import history from "../../utils/history/history";
import { toast } from "react-toastify";
import i18next from "i18next";

function* getLockSaga(action: Action) {
  try {
    if (getLockRequest.match(action)) {
      const { id } = action.payload;
      const { data } = yield* call(getLock, id);
      yield* put(getLockSuccess({ lock: data }));
    }
  } catch (e: any) {
    yield* put(
      locksFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.GetLockFailure`)}`);
  }
}

function* postLockSaga(action: Action) {
  try {
    if (postLockRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postLock, formData);
      yield* put(postLockSuccess({ lock: data }));
      toast.success(`${i18next.t(`toastMessages.PostLockSuccess`)}`);
      yield* call([history, history.push], `/locks`);
    }
  } catch (e: any) {
    yield* put(
      locksFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostLockError`)}`);
  }
}

function* putLockSaga(action: Action) {
  try {
    if (putLockRequest.match(action)) {
      const { id, formData } = action.payload;
      const { data } = yield* call(putLock, id, formData);
      yield* put(putLockSuccess({ lock: data }));
      toast.success(`${i18next.t(`toastMessages.PutLockSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      locksFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutLockError`)}`);
  }
}

function* deleteLockSaga(action: Action) {
  try {
    if (deleteLockRequest.match(action)) {
      const { id } = action.payload;
      yield* call(deleteLock, id);
      yield* put(deleteLockSuccess({}));
      yield* call([history, history.push], `/locks`);
      toast.success(`${i18next.t(`toastMessages.DeleteLockSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      locksFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteLockError`)}`);
  }
}

function* LocksSaga() {
  yield* all([
    takeLatest(getLockRequest.type, getLockSaga),
    takeLatest(postLockRequest.type, postLockSaga),
    takeLatest(putLockRequest.type, putLockSaga),
    takeLatest(deleteLockRequest.type, deleteLockSaga),
  ]);
}

export default LocksSaga;
