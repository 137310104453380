import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../SBTable/SBTable";
import { useHistory } from "react-router";
import ShortUrlsHeader from "./header/shortUrlsHeader";
import { useDeleteShortUrl } from "./hooks/useDeleteShortUrl";
import { useGetAdminShortUrls } from "./hooks/useGetAdminShortUrls";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const ShortUrlList = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteShortUrl = useDeleteShortUrl();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<string>("0");
  const openDeleteShortUrlModal = (uid: string) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(uid);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("shortUrls");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAdminShortUrls(columnFilters, globalFilter, pagination, sorting);

  const handleDeleteShortUrl = (uid: string) => {
    deleteShortUrl.mutateAsync({ uid: uid }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "uid",
        header: `UID`,
        maxSize: 20,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/shortUrls/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: `${t("updatedAt")}`,
        maxSize: 60,
      },
      {
        accessorKey: "tagId",
        header: `${t("tagId")}`,
        maxSize: 60,
      },
      {
        accessorKey: "url",
        header: `${t("url")}`,
        maxSize: 60,
      },
      {
        accessorKey: "counter",
        header: `${t("counter")}`,
        maxSize: 60,
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={`${t("destinations.allShorturls")}`}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const shortUrlUid = data?.items[cell.row.index].uid;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (shortUrlUid) {
                  history.push(`/shortUrls/${shortUrlUid}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (shortUrlUid) {
                  history.push(`/shortUrls/${shortUrlUid}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (shortUrlUid) {
                  openDeleteShortUrlModal(shortUrlUid);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <ShortUrlsHeader />
      <SBTable
        columnVisibilityStoreName="ShortUrlColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="shortUrl"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteShortUrl(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default ShortUrlList;
