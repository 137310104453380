import { useHistory, useParams } from "react-router-dom";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

const BeachChairBookingsListHeader = () => {
  const { t } = useTranslation(["common"]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <SubPageHeader
      headerTitle={
        t("beachChairs") + " " + t("bookings") + " - " + t("overview")
      }
    >
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("beachChairs")}
          onClick={() => {
            history.push(`/beach_chairs`);
          }}
        />
        <SBHeaderLink
          label={id}
          onClick={() => {
            history.push(`/beach_chairs/${id}/show`);
          }}
        />
        <SBHeaderTypography label={t("bookings")} />
      </SBBreadcrumbsWrapper>
    </SubPageHeader>
  );
};

export default BeachChairBookingsListHeader;
