import { Button as MuiButton, ButtonProps } from "@mui/material";

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = "contained",
  color = "primary",
  ...buttonProps
}) => {
  return (
    <MuiButton variant={variant} color={color} {...buttonProps}>
      {children}
    </MuiButton>
  );
};
