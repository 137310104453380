import { IInvoice, IShortInvoice } from "../models/invoices/IInvoice";
import API from "./api";

const BASE_URL = `/invoices`;

export const getInvoice = (
  bookingId: number,
  vendorId: number,
  invoiceNumber: string,
  receiptType: string
) =>
  API.get<IInvoice>(
    `${BASE_URL}?bookingId=${bookingId}&vendorId=${vendorId}&invoiceNumber=${invoiceNumber}&receiptType=${receiptType}`
  );

export const getShortInvoice = (bookingId: number) =>
  API.get<IShortInvoice>(`features/booking_details/${bookingId}/shortInvoice?buffer=true`);
