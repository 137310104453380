import { QRCodeSVG } from "qrcode.react";
import { useHistory, useParams } from "react-router-dom";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import SubPageHeader from "../../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import { BookingCancellationSplitButton } from "../components";
import { useGetBooking } from "../hooks";
import { BookingRecoverButton } from "../components/BookingRecoverButton";
import { BookingDeleteButton } from "../components/BookingDeleteButton";
import { useGetCurrentUser, UserRoles } from "../../../../hooks";
import { Box } from "@mui/material";
import SBBreadcrumbsWrapper from "../../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";

const BookingDetailHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const { booking } = useGetBooking(+id);
  const { userRoles } = useGetCurrentUser();
  const isSuperAdmin = userRoles.includes(UserRoles.SUPER_ADMIN);

  return (
    <SubPageHeader headerTitle={t("bookings") + " - " + t("detail") + `#${id}`}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("bookings")}
          onClick={() => {
            history.push(`/bookings`);
          }}
        />
        <SBHeaderTypography label={t("detail")} />
      </SBBreadcrumbsWrapper>
      {booking && (
        <SBHeaderActionButtonsWrapper>
          <Box
            sx={{
              width: "100px",
              height: "32px",
              display: "grid",
              justifyContent: "center",
              alignContent: "center",
              marginRight: "auto",
              verticalAlign: "middle",
              textAlign: "center",
              borderRadius: "20px",
              border: `2px solid ${customStyle.secondaryColor}`,
              color: customStyle.secondaryColor,
            }}
          >
            {t(`states.${booking.status}`)}
          </Box>
          <Box sx={{ mr: "70px" }}>
            {booking.status === "CONFIRMED" && (
              <BookingCancellationSplitButton />
            )}
            {booking.status === "EXPIRED" && isSuperAdmin && (
              <BookingRecoverButton />
            )}
            {(booking.status === "OPEN" || booking.status === "PENDING") && (
              <BookingDeleteButton />
            )}
          </Box>
          <QRCodeSVG
            value={`strandbutler://booking/${booking.customer?.email}/${booking.bookingRef}`}
            fgColor="#233F91"
            size={60}
            style={{
              position: "absolute",
            }}
          />
        </SBHeaderActionButtonsWrapper>
      )}
    </SubPageHeader>
  );
};

export default BookingDetailHeader;
