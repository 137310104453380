import { IFiltersBeachChair } from "./../models/beachChairs/IFiltersBeachChair";
import { IBeachChair } from "../models/beachChairs/IBeachChair";
import API from "./api";
import { IBeachChairCalendarAvability } from "../models/beachChairs/IBeachChairCalendarDay";

const FEATURES_BASE_URL = "/features/vendor";
const FEATURES_FILTER_BASE_URL = "/features/filters/beach_chairs";
const FEATURES_BEACH_CHAIRS = "/features/beach_chair";
const FEATURES_AVAILABILITIES_BEACH_CHAIRS_BASE_URL =
  "/features/availabilities/beach_chairs";
const BASE_URL = "/bookings/beach-chairs";

export interface IBeachChairsRequest {
  count: number;
  items: IBeachChair[];
}

export const getCalendarBeachChair = (
  beachChairId: number,
  from: string,
  to: string
) =>
  API.get<IBeachChairCalendarAvability[]>(
    `${FEATURES_AVAILABILITIES_BEACH_CHAIRS_BASE_URL}/${beachChairId}?from=${from}&to=${to}`
  );

export const getBeachChairs = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IBeachChairsRequest>(
    `${FEATURES_BASE_URL}/beach_chairs?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getFiltersBeachChairs = () =>
  API.get<IFiltersBeachChair>(`${FEATURES_FILTER_BASE_URL}`);

export const getBeachChair = (beachChairId: number) =>
  API.get<IBeachChair>(`${BASE_URL}/${beachChairId}`);

export const putBeachChair = (beachChairId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IBeachChair>(`${BASE_URL}/${beachChairId}`, data, config);
};

export const postNewBeachChair = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IBeachChair>(`${FEATURES_BEACH_CHAIRS}`, data, config);
};

export const postBeachChair = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IBeachChair>(`${BASE_URL}`, data, config);
};

export const deleteBeachChair = (beachChairId: number) => {
  return API.delete<IBeachChair>(`${BASE_URL}/${beachChairId}`);
};

export const searchBeachChairs = (searchPhrase: string) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.get<IBeachChairsRequest>(
    `${FEATURES_BASE_URL}/beach_chairs?search=${searchPhrase}`,
    config
  );
};
