import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  postAdminContextVendorFailurePayload,
  postAdminContextVendorRequestPayload,
  postAdminContextVendorSuccessPayload,
} from "../actionPayload/adminPayloads";

interface IAuthState {
  pending: boolean;
  error: string;
  vendorId?: number;
  vendorChangeContextComplete: boolean;
}

const INITIAL_STATE: IAuthState = {
  pending: false,
  vendorChangeContextComplete: true,
  error: "",
};

const adminSlice = createSlice({
  name: "admin",
  initialState: INITIAL_STATE,
  reducers: {
    postAdminContextVendorRequest(
      state,
      action: PayloadAction<postAdminContextVendorRequestPayload>
    ) {
      state.pending = true;
      state.vendorChangeContextComplete = false;
    },
    postAdminContextVendorSuccess(
      state,
      action: PayloadAction<postAdminContextVendorSuccessPayload>
    ) {
      state.pending = false;
      state.vendorId = action.payload.vendorId;
    },
    postAdminContextVendorFailure(
      state,
      action: PayloadAction<postAdminContextVendorFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
      state.vendorChangeContextComplete = true;
    },
    postAdminVendorChangeContextCompleteSuccess(state) {
      state.vendorChangeContextComplete = true;
    },
  },
});

export const {
  postAdminContextVendorRequest,
  postAdminContextVendorSuccess,
  postAdminContextVendorFailure,
  postAdminVendorChangeContextCompleteSuccess,
} = adminSlice.actions;

export default adminSlice.reducer;
