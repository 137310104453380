import { ILock, ILockRow } from "../../models/lock/ILock";
import { batteryLevelMap } from "../conversions/lockConversion";
import { dateToProperFormat } from "./dataConversion";

export const lockToRow = (lock: ILock): ILockRow => {
  return {
    id: lock.id,
    number: lock.number,
    updatedAt: dateToProperFormat(lock.updatedAt || (new Date()).toISOString()),
    mac: lock.mac,
    vendorId: lock.vendorId,
    status: lock.status,
    batteryLevel: `${batteryLevelMap(lock.batteryLevel)} %`,
  };
};
