import React, { useCallback } from "react";
import {
  BookingDetailsResponseDto,
  BookingPaymentMethod,
} from "../../../../api-client/generated";
import { toast } from "react-toastify";
import { useBookingPriceForm } from "../../../../utils/customHooks/useBookingPriceForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getCurrentUserSelector } from "../../../../store/selectors/authSelectors";
import { ListSubheader, MenuItem, SelectChangeEvent } from "@mui/material";
import { Box } from "@mui/system";
import { useGetBooking, useUpdateBooking } from "../hooks";
import BankData from "./bankData";
import SBSelectListSubHeader from "../../../SBComponents/SBForms/SBSelectListSubHeader";
import PaymentIcon from "@mui/icons-material/Payment";
import SBNumberField from "../../../SBComponents/SBForms/SBNumberField";
import EuroIcon from "@mui/icons-material/Euro";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import { useQueryClient } from "@tanstack/react-query";
export interface BookingPayProps {
  id: number;
  type: string;
  booking?: BookingDetailsResponseDto;
}

const onSitePayments = [
  "CASH",
  "INVOICE",
  "BANK_TRANSFER",
  "CARD_TERMINAL",
  "CUSTOM_SEPA",
];
const onlinePayments = [
  "CC",
  "PAYPAL",
  "OTHER_STRIPE",
  "STRIPE_BANK_TRANSFER",
  "STRIPE_DIRECT_DEBIT",
  "STRIPE_SOFORT",
  "STRIPE_CREDIT_CARD",
  "STRIPE_PAYPAL",
  "STRIPE_PAYMENT_LINK",
];

const BookingPay = ({ id, type, booking }: BookingPayProps) => {
  const currentUser = useSelector(getCurrentUserSelector);
  const isAdmin = currentUser && currentUser.roles[0] === "SUPER_ADMIN";
  const { t } = useTranslation(["common"]);
  const { control, onSubmit, errors } = useBookingPriceForm(id, type);
  const [value, setValue] = React.useState<BookingPaymentMethod | undefined>(
    booking?.paymentMethod
  );
  const updateBooking = useUpdateBooking();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    setValue(booking?.paymentMethod);
  }, [booking]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const newPaymentMethod = event.target.value as BookingPaymentMethod;
      setValue(newPaymentMethod);

      updateBooking.mutateAsync(
        { id, paymentMethod: newPaymentMethod },
        {
          onSuccess: async () => {
            toast.success(t(`toastMessages.ChangeBookingPaymentSuccess`));
            queryClient.invalidateQueries(["booking"]);
          },
          onError: () => {
            toast.error(t(`toastMessages.ChangeBookingPaymentFailure`));
          },
        }
      );
    },
    [id, t, updateBooking]
  );

  return (
    <Box
      sx={{
        pr: "10px",
        pl: "10px",
        marginTop: "40px",
        display: "grid",
        gap: "10%",
        maxWidth: "50%",
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "10px",
          }}
        >
          <SBNumberField
            name="totalPrice"
            label={t("totalPrice")}
            control={control}
            type={type}
            icon={<PriceChangeIcon />}
            defaultValue={0}
            endAdornmentSymbol="€"
            error={errors?.price && t((errors.price as any)?.message)}
          />
          <SBNumberField
            name="vat"
            label={t("vat")}
            control={control}
            type={"show"}
            icon={<EuroIcon />}
            defaultValue={0}
            endAdornmentSymbol="€"
            error={errors?.vat && t((errors.vat as any)?.message)}
          />
        </Box>

        <SBSelectListSubHeader
          name="paymentMethods"
          label={t("paymentMethods")}
          noOptionLabel={t("NO_SECTION")}
          type={type}
          icon={<PaymentIcon />}
          isLoading={false}
          handleChange={handleChange}
          defaultValue={"Bar"}
          option={value}
        >
          <ListSubheader>{t("onSitePayments")}</ListSubheader>
          {onSitePayments.map(
            (paymentMethod) =>
              ((booking?.vendor?.configuration?.paymentMethods?.includes(
                paymentMethod
              ) &&
                isAdmin) ||
                paymentMethod === "CASH" ||
                paymentMethod === "BANK_TRANSFER" ||
                paymentMethod === "CUSTOM_SEPA") && (
                <MenuItem
                  key={`paymentMethods-${paymentMethod}`}
                  data-testid={`${paymentMethod}-option`}
                  value={paymentMethod}
                >
                  {t(`paymentMethod.${paymentMethod}`)}
                </MenuItem>
              )
          )}

          <ListSubheader>{t("onlinePayments")}</ListSubheader>
          {type !== "show" ? (
            onlinePayments.map(
              (paymentMethod) =>
                ((booking?.vendor?.configuration?.paymentMethods?.includes(
                  paymentMethod
                ) &&
                  isAdmin) ||
                  paymentMethod === "STRIPE_PAYMENT_LINK" ||
                  paymentMethod === "STRIPE_BANK_TRANSFER") && (
                  <MenuItem
                    key={`paymentMethods-${paymentMethod}`}
                    data-testid={`${paymentMethod}-option`}
                    value={paymentMethod}
                  >
                    {t(`paymentMethod.${paymentMethod}`)}
                  </MenuItem>
                )
            )
          ) : (
            <MenuItem
              key={`paymentMethods-${booking?.paymentMethod}`}
              data-testid={`${booking?.paymentMethod}-option`}
              value={booking?.paymentMethod}
            >
              {t(`paymentMethod.${booking?.paymentMethod}`)}
            </MenuItem>
          )}
        </SBSelectListSubHeader>
      </form>
      {booking?.paymentMethod === BookingPaymentMethod.StripeBankTransfer && (
        <BankData id={booking?.id} />
      )}
    </Box>
  );
};

export default BookingPay;
