import { useTranslation } from "react-i18next";
import { Box, Stack, TextField, Typography } from "@mui/material";
import { inputRootPayment } from "../../../../utils/customStyles/globalStyles";
import { useGetBooking } from "../hooks";

export default function BankData({ id }: { id: number }) {
  const { t } = useTranslation(["common"]);
  const { booking } = useGetBooking(id);

  return (
    <Box
      sx={{
        gridRow: 1,
        gridColumn: "2/4",
        maxWidth: "350px",
        marginTop: "40px",
        ...inputRootPayment,
      }}
    >
      <Typography sx={{ mb: 2 }}>{t("showPaymentData")}</Typography>
      <Stack spacing={2}>
        <TextField
          label={t("paymentData.accountHolder")}
          value={"Strand & Mehr GmbH"}
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          label={t("paymentData.iban")}
          value={booking?.metaData?.bankData?.iban}
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          label={t("paymentData.bic")}
          value={booking?.metaData?.bankData?.bic}
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
        <TextField
          label={t("paymentData.reference")}
          value={booking?.metaData?.bankData?.reference}
          style={{ width: "100%" }}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
        />
      </Stack>
    </Box>
  );
}
