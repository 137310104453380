import { useQuery } from "@tanstack/react-query";
import { beachChairApi } from "../../../api/apiTanStack";

export const useGetBeachChair = (id: number) =>
  useQuery({
    queryKey: ["beachChair", id],
    queryFn: async () => {
      return beachChairApi
        .beachChairsControllerFindOne({
          id,
        })
        .then((response) => response.data);
    },
    enabled: !isNaN(id),
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
