import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useLocation } from "react-router-dom";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getBeachChairCalendarMonthSelector } from "../../../store/selectors/beachChairsSelectors";
import { useHistory, useParams } from "react-router";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import {
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";
import dayjs from "dayjs";
import { setBeachChairCalendarMonthRequest } from "../../../store/reducers/beachChairsReducer";
import { deDE } from "@mui/x-date-pickers/locales";

const BeachChairListHeader = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const month = useSelector(getBeachChairCalendarMonthSelector);
  const [datePicker, setDatePicker] = useState<string>(month);

  return (
    <SubPageHeader headerTitle={t("beachChairs")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {id && (
          <SBHeaderLink
            label={t("beachChairs")}
            onClick={() => {
              history.push(`/beach_chairs`);
            }}
          />
        )}
        {id && (
          <SBHeaderLink
            label={id}
            onClick={() => {
              history.push(`/beach_chairs/${id}/show`);
            }}
          />
        )}
        {location.pathname.includes(`calendar`) && (
          <SBHeaderLink
            label={id}
            onClick={() => {
              history.push(`/beach_chairs/${id}/show`);
            }}
          />
        )}
        {location.pathname.includes(`calendar`) && (
          <SBHeaderTypography label={t("calendar")} />
        )}
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {id && location.pathname.includes(`calendar`) && (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <MobileDatePicker
              onChange={(newValue: Date | null) => {
                if (newValue) {
                  setDatePicker(dayjs(newValue).toISOString());
                  dispatch(
                    setBeachChairCalendarMonthRequest({
                      date: dayjs(newValue).toISOString(),
                    })
                  );
                }
              }}
              sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
              slotProps={{
                textField: {
                  variant: "outlined",
                },
              }}
              views={["month"]}
              value={dayjs(datePicker) as any}
            />
          </LocalizationProvider>
        )}
        {id && !location.pathname.includes(`calendar`) && (
          <SBLoadingButton
            label={t("calendar")}
            type="blue"
            icon={<CalendarMonthIcon />}
            onClick={() => {
              history.push(`/beach_chairs/${id}/calendar`);
            }}
          />
        )}
        {id && !location.pathname.includes(`calendar`) && (
          <SBLoadingButton
            label={t("bookings")}
            type="blue"
            icon={<BookmarkBorderIcon />}
            onClick={() => {
              history.push(`/beach_chairs/${id}/bookings`);
            }}
          />
        )}
        {!location.pathname.includes(`calendar`) && (
          <SBLoadingButton
            label={t("newBeachChair")}
            type="blue"
            icon={<AddIcon />}
            onClick={() => {
              history.push(`/beach_chairs/new`);
            }}
          />
        )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default BeachChairListHeader;
