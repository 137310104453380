import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetGlobalBookingIdPayload } from "../actionPayload/globalBookingPayloads";

/**
 * The `globalBookingSlice` slice and global booking ID handle the business logic for managing the booking context in the application.
 *
 * The purpose of the global booking ID is to allow users to add more beach chairs, beach baskets, and extras to their booking.
 *
 * The user can interact with several screens to add items to the booking, including the
 * - Beach Chairs Overview,
 * - Beach Baskets Overview,
 * = Extras,
 * = Quick Booking (a combined view of all options).
 *
 * After selecting an option and submitting the form successfully, there are two possible outcomes:
 * A) If the user wants to view the newly created booking, they are redirected to the Booking Details page.
 * B) If the user wants to continue adding more items (beach chairs, beach baskets, or extras), they stay on the current page - the global booking is set after the first item was added.
 *
 * The global booking ID plays a crucial role in this process. Any application component that needs to add additional items to the booking must access and update the global booking context.
 *
 * The context can be unset in two ways:
 * - By clicking on the X icon next to the booking ID displayed on any Beach Chair, Beach Basket, or Extras view after opening the right-hand side drawer.
 * - By completing the booking process.
 */

interface IGlobalBookingState {
  bookingId: number | null;
}

const INITIAL_STATE: IGlobalBookingState = {
  bookingId: null,
};

const globalBookingSlice = createSlice({
  name: "globalBooking",
  initialState: INITIAL_STATE,
  reducers: {
    resetGlobalBookingRequest() {
      return INITIAL_STATE;
    },
    setGlobalBookingId(
      state,
      action: PayloadAction<SetGlobalBookingIdPayload>
    ) {
      state.bookingId = action.payload.id;
    },
    clearGlobalBookingId(state) {
      state.bookingId = null;
    },
  },
});

export const {
  resetGlobalBookingRequest,
  setGlobalBookingId,
  clearGlobalBookingId,
} = globalBookingSlice.actions;

export default globalBookingSlice.reducer;
