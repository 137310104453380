import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { extrasApi } from "../../../api/apiTanStack";
import { UpdateExternalServicesDto } from "../../../api-client/generated";
import { useTranslation } from "react-i18next";

export const useUpdateExtra = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["updateExtra"],
    ({
      id,
      updateExternalServicesDto,
    }: {
      id: number;
      updateExternalServicesDto: UpdateExternalServicesDto;
    }) =>
      extrasApi.externalServicesControllerUpdate({
        id,
        updateExternalServicesDto,
      }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.PutExtraSuccess`));
        queryClient.refetchQueries(["extras"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.PutExtraFailure`));
      },
    }
  );
};
