import { useQuery } from "@tanstack/react-query";
import { vendorApi } from "../../../api/apiTanStack";
export const useGetVendorsAutocompleteOptions = () =>
  useQuery({
    queryKey: ["GetVendorsAutocompleteOptions"],
    queryFn: async () => {
      return vendorApi
        .vendorsControllerFindMany({ page: 0, itemsPerPage: 0 })
        .then((response) => response.data.items);
    },
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
