import { useHistory } from "react-router-dom";
import { useAddBeachChairToBooking } from "../../components/availabilities/hooks";
import {
  useCreateBooking,
  useUpdateBooking,
} from "../../components/bookingsList/booking";
import { useQueryClient } from "@tanstack/react-query";
import { setGlobalBookingId } from "../../store/reducers/globalBookingReducer";
import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { ILocation } from "../../models/vendor/ILocation";
import { useQuery } from "@tanstack/react-query";
import { pricesApi } from "../../api/apiTanStack";
import { useBookingInProgressStore } from "../../components/bookingsList/store/useBookingZustand";
import { BeachChairResponseDto } from "../../api-client/generated";
import { toast } from "react-toastify";
import { useSelectedBeachChairDates } from "../../components/bookingsList/store/useSelectedBeachChairDates";
import { setBookingDateTime } from "../conversions/locationsTimes";
import { TimeZone } from "../conversions/dataConversion";

const DateTimeFormat = "YYYY-MM-DDTHH:mm:ss";

interface Props {
  id: number;
  isContinue?: boolean;
  beachChair?: BeachChairResponseDto;
  locationData?: ILocation;
  onSuccess?: () => void;
  booking?: any;
  isExtended?: boolean;
}

export function useSelectedAvailableBeachChair({
  id,
  beachChair,
  locationData,
  onSuccess,
  booking,
  isExtended,
}: Props) {
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { dateTime, setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const [isDiscountPresent, setIsDiscountPresent] = useState(false);
  const updateBooking = useUpdateBooking();
  const { bookingInProgress } = useBookingInProgressStore();
  const globalBookingId = useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );

  const createBooking = useCreateBooking();
  const { addBeachChairToBooking } = useAddBeachChairToBooking();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number(),
        // publicNumber: yup.string(),
        discount: yup.boolean().nullable(),
        price: yup
          .number()
          .moreThan(-1, t("priceError"))
          .typeError(t("priceTypeError")),
      }),
    [t]
  );

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
    formState,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const beachChairPublicNumber = beachChair?.publicNumber || "";

  useEffect(() => {
    setValue("id", id);
    setValue("publicNumber", beachChairPublicNumber);
  }, [beachChairPublicNumber, id, setValue]);

  useEffect(() => {
    if (dateTime[0] && dateTime[1]) {
      const { bookingStartDateTime, bookingEndDateTime } = setBookingDateTime(
        dateTime,
        locationData,
        beachChair?.afterHourBooking
      );

      bookingStartDateTime && setBookingStartDateTime(bookingStartDateTime);
      bookingEndDateTime && setBookingEndDateTime(bookingEndDateTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData, beachChair]);

  const dayDiff = useMemo(() => {
    return (dateTime[1]?.diff(dateTime[0], "day") ?? 0) + 1;
  }, [dateTime]);

  const sectionId = useMemo(() => beachChair?.sectionId, [beachChair]);
  const rowId = useMemo(() => beachChair?.rowId, [beachChair]);
  const model = useMemo(() => beachChair?.model, [beachChair]);

  const { data: calculate } = useQuery(
    ["prices", sectionId, rowId, model, dateTime],
    () =>
      pricesApi
        .pricesControllerCalculatePrice({
          appVersion: "backoffice",
          sectionId,
          rowId,
          model,
          start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
          end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
        })
        .then((res) => res.data),
    {
      enabled:
        !!sectionId &&
        !!rowId &&
        !!model &&
        !!dateTime[0] &&
        !!dateTime[1] &&
        dayDiff > 1,
      retry: false,
      cacheTime: 100000,
      staleTime: 100000,
    }
  );

  const { data: rates } = useQuery(
    ["prices", sectionId, rowId, model, dateTime],
    () =>
      pricesApi
        .pricesControllerGetRates({
          sectionId,
          rowId,
          model,
          start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
          end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
        })
        .then((res) => res.data),
    {
      enabled:
        !!sectionId &&
        !!rowId &&
        !!model &&
        !!dateTime[0] &&
        !!dateTime[1] &&
        dayDiff === 1,
      retry: false,
      cacheTime: 100000,
      staleTime: 100000,
    }
  );

  const prices = useMemo(() => {
    return dayDiff === 1 ? rates : calculate;
  }, [dayDiff, calculate, rates]);

  // when checking the checkbox, the price changes
  const watchDiscount = watch("discount");
  useEffect(() => {
    if (isDiscountPresent && watchDiscount) {
      setValue(
        "price",
        prices
          ?.filter((price) => price.discount)
          .sort((a, b) => b.price - a.price)[0].price || 0
      );
    } else {
      if (prices && prices.length > 0) {
        setIsDiscountPresent(prices.some((price) => price.discount) ?? false);
        setValue(
          "price",
          prices?.sort((a, b) => b.price - a.price)[0]?.price || 0
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDiscount, isDiscountPresent, prices]);

  const onSubmit = async ({ id: beachChairId, price }: any) => {
    const beachChairBookingPayload = {
      beachChairId,
      price,
      start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
      end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
      discount: watchDiscount,
    };

    if (globalBookingId) {
      addBeachChairToBooking(
        {
          bookingId: Number(globalBookingId),
          isExtended: false,
          ...beachChairBookingPayload,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(["booking", Number(globalBookingId)]);
            queryClient.removeQueries(["BeachChairBookings"]);
            queryClient.invalidateQueries([
              "beachChairsAvailabilitiesCalendar",
            ]);
            toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));
            history.push(`/bookings/${globalBookingId}/edit`);
          },
        }
      );
    } else {
      createBooking.mutateAsync(undefined, {
        onSuccess: (newBooking) => {
          if (booking) {
            const comment = `Verlängerung von ${booking.bookingRef}`;
            updateBooking.mutate({
              id: newBooking.data.id,
              comment,
              customerId: booking.customerId,
            });
          }
          addBeachChairToBooking(
            {
              bookingId: Number(newBooking.data.id),
              isExtended: isExtended ?? false,
              ...beachChairBookingPayload,
            },
            {
              onSuccess: () => {
                toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));
                queryClient.removeQueries(["BeachChairBookings"]);
                queryClient.invalidateQueries([
                  "beachChairsAvailabilitiesCalendar",
                ]);
                if (!isExtended) {
                  dispatch(setGlobalBookingId({ id: newBooking.data.id }));
                }
                history.push(`/bookings/${newBooking.data.id}/edit`);
              },
            }
          );
        },
      });
    }
  };

  const onSubmitAndStay = async ({ id: beachChairId, price }: any) => {
    const beachChairBookingPayload = {
      beachChairId,
      price,
      start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
      end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
      discount: watchDiscount,
    };

    if (globalBookingId) {
      addBeachChairToBooking(
        {
          bookingId: Number(globalBookingId),
          isExtended: false,
          ...beachChairBookingPayload,
        },
        {
          onSuccess: () => {
            queryClient.refetchQueries(["booking", Number(globalBookingId)]);
            queryClient.removeQueries(["BeachChairBookings"]);
            queryClient.invalidateQueries([
              "beachChairsAvailabilitiesCalendar",
            ]);
            toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));
            onSuccess?.();
          },
        }
      );
    } else {
      createBooking.mutateAsync(undefined, {
        onSuccess: (newBooking) => {
          if (booking) {
            const comment = `Verlängerung von ${booking.bookingRef}`;
            updateBooking.mutate({
              id: newBooking.data.id,
              comment,
              customerId: booking.customerId,
            });
          }
          addBeachChairToBooking(
            {
              bookingId: Number(newBooking.data.id),
              isExtended: isExtended ?? false,
              ...beachChairBookingPayload,
            },
            {
              onSuccess: () => {
                toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));
                queryClient.removeQueries(["BeachChairBookings"]);
                queryClient.invalidateQueries([
                  "beachChairsAvailabilitiesCalendar",
                ]);
                if (!isExtended) {
                  dispatch(setGlobalBookingId({ id: newBooking.data.id }));
                }
                onSuccess?.();
              },
            }
          );
        },
      });
    }
  };

  return {
    control,
    setValue,
    dateTime,
    errors,
    formState,
    isDiscountPresent,
    onSubmit: handleSubmit(onSubmit),
    onSubmitAndStay: handleSubmit(onSubmitAndStay),
  };
}
