import { Stack } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import React, { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelectedExtraForm } from "../../../utils/customHooks/useSelectedExtraForm";
import { SelectedGlobalBooking } from "../../common";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import NumbersIcon from "@mui/icons-material/Numbers";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import SBNumberField from "../../SBComponents/SBForms/SBNumberField";
import EuroIcon from "@mui/icons-material/Euro";
import Filter9PlusIcon from "@mui/icons-material/Filter9Plus";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import AddIcon from "@mui/icons-material/Add";
import SBSelectedButtonsWrapper from "../../SBComponents/SBLayout/SBSelectedButtonsWrapper";
import { useAddExtrasToBooking } from "../hooks";
import { useCreateBookingAddExtra } from "../../bookingsList/booking";

export interface ISelectedExtrasProps {
  id: number;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

const SelectedExtras: React.FC<ISelectedExtrasProps> = ({
  id,
  handleClose,
}) => {
  const { t } = useTranslation(["common"]);
  const addExtrasToBooking = useAddExtrasToBooking();
  const createBooking = useCreateBookingAddExtra();
  const { bookingInProgress } = useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );
  const { control, isLoading, getValues, errors } = useSelectedExtraForm(id);

  const handleOnSubmit = useCallback(
    (isContinue: boolean) => {
      if (globalBookingId) {
        addExtrasToBooking.mutateAsync({
          id: Number(globalBookingId),
          amount: getValues("amount"),
          extraId: id,
          isContinue,
        });
      } else {
        createBooking.mutateAsync({
          isContinue,
          amount: getValues("amount"),
          extraId: id,
        });
      }
    },
    [getValues, addExtrasToBooking, createBooking, globalBookingId, id]
  );

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        handleClose(false);
      }}
    >
      <Stack spacing={2} sx={{ p: 4 }}>
        {globalBookingId && <SelectedGlobalBooking />}
        <SBTextField
          name="name"
          label={t("name")}
          control={control}
          type={"show"}
          icon={<NumbersIcon />}
          isLoading={isLoading}
          error={errors?.name && t((errors.name as any)?.message)}
        />
        <SBTextField
          name="type"
          label={t("type")}
          control={control}
          type={"show"}
          icon={<BeachAccessIcon />}
          isLoading={isLoading}
          error={errors?.type && t((errors.type as any)?.message)}
        />
        <SBNumberField
          name="price"
          label={t("price")}
          control={control}
          type={"edit"}
          icon={<EuroIcon />}
          isLoading={isLoading}
          defaultValue={0}
          endAdornmentSymbol="€"
          error={errors?.price && t((errors.price as any)?.message)}
        />
        <SBNumberField
          name="amount"
          label={t("amount")}
          control={control}
          type={"edit"}
          icon={<Filter9PlusIcon />}
          isLoading={isLoading}
          defaultValue={0}
          error={errors?.amount && t((errors.amount as any)?.message)}
        />

        <SBSelectedButtonsWrapper>
          <SBHeaderTypography label={`${t("addLabel")} & ...`} />
          <Stack spacing={1}>
            <SBLoadingButton
              label={t("addButtonRedirectToBooking")}
              type="blue"
              icon={<ChevronRight />}
              onClick={() => {
                handleOnSubmit(false);
              }}
            />
            <SBLoadingButton
              label={t("addMoreButton")}
              type="blue"
              icon={<AddIcon />}
              onClick={() => {
                handleOnSubmit(true);
              }}
            />
          </Stack>
        </SBSelectedButtonsWrapper>
      </Stack>
    </form>
  );
};

export default SelectedExtras;
