import { IBeachChairBooking } from "./../../models/bookings/IBeachChairBooking";
import {
  AddBeachChairToBookingFailurePayload,
  AddBeachChairToBookingRequestPayload,
  AddBeachChairToBookingSuccessPayload,
  AddExtrasToBookingFailurePayload,
  AddExtrasToBookingRequestPayload,
  AddExtrasToBookingSuccessPayload,
  BookingExistingCustomerChosenPayload,
  CancelBookingFailurePayload,
  CancelBookingRequestPayload,
  CancelBookingSuccessPayload,
  ChangeBeachChairsFailurePayload,
  ChangeBeachChairsRequestPayload,
  ChangeBeachChairsSuccessPayload,
  ChangeBookingExtrasAmountFailurePayload,
  ChangeBookingExtrasAmountRequestPayload,
  ChangeBookingExtrasAmountSuccessPayload,
  ChangeInstantBookingTabRequestPayload,
  CreateInstantBookingFailurePayload,
  CreateInstantBookingRequestPayload,
  CreateInstantBookingSuccessPayload,
  CreateNewBookingFailurePayload,
  CreateNewBookingRequestPayload,
  CreateNewBookingSuccessPayload,
  DeleteBookingBeachChairSuccessPayload,
  DeleteBookingExtrasFailurePayload,
  DeleteBookingExtrasRequestPayload,
  DeleteBookingExtrasSuccessPayload,
  DeleteBookingFailurePayload,
  DeleteBookingRequestPayload,
  DeleteBookingSuccessPayload,
  GetCSVBookingsRequestPayload,
  GetCSVBookingsSuccessPayload,
  PatchBookingFailurePayload,
  PatchBookingRequestPayload,
  PatchBookingSuccessPayload,
  PostNewBookingFailurePayload,
  PostNewBookingRequestPayload,
  PostNewBookingSuccessPayload,
  PostNewExtrasBookingFailurePayload,
  PostNewExtrasBookingRequestPayload,
  PostNewExtrasBookingSuccessPayload,
  PushBookingCustomerFailurePayload,
  PushBookingCustomerRequestPayload,
  PushBookingCustomerSuccessPayload,
  PutBookingBeachChairFailurePayload,
  PutBookingBeachChairRequestPayload,
  PutBookingBeachChairSuccessPayload,
  PutBookingConfirmFailurePayload,
  PutBookingConfirmRequestPayload,
  PutBookingConfirmSuccessPayload,
  RemoveBeachChairBookingFailurePayload,
  RemoveBeachChairBookingRequestPayload,
  RemoveBeachChairBookingSuccessPayload,
  RemoveBookingBeachChairRightDrawerToggleRequestPayload,
  SendPaymentLinkRequestPayload,
  SendPaymentLinkSuccessPayload,
  SetBookingIdRequestPayload,
  SetCurrentDatePayload,
  UpdateCommentFailurePayload,
  UpdateCommentRequestPayload,
  UpdateCommentSuccessPayload,
} from "./../actionPayload/bookingPayloads";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IBooking } from "../../models/bookings/IBooking";
import { IFilterData } from "../../models/dataTable/IFilterData";
import {
  DeleteBookingBeachChairFailurePayload,
  DeleteBookingBeachChairRequestPayload,
  FetchBookingFailurePayload,
  FetchBookingRequestPayload,
  FetchBookingsFailurePayload,
  FetchBookingsRequestPayload,
  FetchBookingsSuccessPayload,
  FetchBookingSuccessPayload,
  FilterBookingsFailurePayload,
  FilterBookingsRequestPayload,
  FilterBookingsSuccessPayload,
  FilterDataBookingsChangeRequestPayload,
  PushBookingFailurePayload,
  PushBookingRequestPayload,
  PushBookingSuccessPayload,
  RouterRedirectBookingPayload,
} from "../actionPayload/bookingPayloads";
import { AppState } from "./rootReducer";
import { IBookingExtras } from "../../models/bookings/IBookingExtras";
import { EBookingRequestType } from "../../models/bookings/EBookingRequestType";
import { IInstantBookingPending } from "../../models/bookings/IInstantBooking";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IBookingState {
  pending: boolean;
  instantBookingPending: IInstantBookingPending;
  instantBookingTabSet: number;
  bookingTab: number;
  instantBookingCurrentDate: string;
  error: string;
  reqFromBookings: EBookingRequestType;
  isExistingCustomer: boolean;
  count: number;
  filterData: IFilterData[];
  page: EntityState<IPage>;
  beachChairBookingPage: EntityState<IPage>;
  beachChairBooking: EntityState<IBeachChairBooking>;
  customerBookingPage: EntityState<IPage>;
  customerBooking: EntityState<IBooking>;
  extrasBooking: EntityState<IBookingExtras>;
  csvData: any;
  removeBookingBeachChairRightDrawer: boolean;
}

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const pageBeachChairBookingAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const pageCustomerBookingAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const beachChairBookingAdapter = createEntityAdapter<IBeachChairBooking>({
  selectId: (beachChair) => beachChair.id,
});

const customerBookingAdapter = createEntityAdapter<IBooking>({
  selectId: (beachChair) => beachChair.id,
});

const extrasBookingAdapter = createEntityAdapter<IBookingExtras>({
  selectId: (extra) => extra.id,
});

const bookingAdapter = createEntityAdapter<IBooking>({
  selectId: (booking) => booking.id,
});

const INITIAL_STATE = bookingAdapter.getInitialState<IBookingState>({
  pending: false,
  instantBookingPending: { value: false },
  instantBookingTabSet: 0,
  bookingTab: 0,
  instantBookingCurrentDate: new Date().toISOString(),
  error: "",
  isExistingCustomer: false,
  reqFromBookings: EBookingRequestType.BEACH_CHAIR_BOOKING_REQUEST,
  count: 0,
  filterData: [],
  page: pageAdapter.getInitialState({}),
  beachChairBookingPage: pageBeachChairBookingAdapter.getInitialState({}),
  beachChairBooking: beachChairBookingAdapter.getInitialState({}),
  customerBookingPage: pageCustomerBookingAdapter.getInitialState({}),
  customerBooking: customerBookingAdapter.getInitialState({}),
  extrasBooking: extrasBookingAdapter.getInitialState({}),
  csvData: undefined,
  removeBookingBeachChairRightDrawer: false,
});

const bookingSlice = createSlice({
  name: "booking",
  initialState: INITIAL_STATE,
  reducers: {
    resetBookingRequest() {
      return INITIAL_STATE;
    },
    fetchBookingsRequest(
      state,
      action: PayloadAction<FetchBookingsRequestPayload>
    ) {
      state.pending = true;
      state.reqFromBookings = action.payload.reqFromBookings;
    },
    fetchBookingsSuccess(
      state,
      action: PayloadAction<FetchBookingsSuccessPayload>
    ) {
      state.pending = false;
      const { bookings, beachChairId, customerId, count, page, itemsPerPage } =
        action.payload;
      state.count = count;
      //bookingAdapter.removeAll(state);
      bookingAdapter.upsertMany(state, bookings);
      if (state.reqFromBookings === EBookingRequestType.MAIN_BOOKING_REQUEST) {
        pageAdapter.removeAll(state.page);
        pageAdapter.upsertOne(state.page, {
          key: `${page}:${itemsPerPage}`,
          dataIds: [...bookings.map((booking) => booking.id)],
        });
      } else if (
        state.reqFromBookings ===
        EBookingRequestType.BEACH_CHAIR_BOOKING_REQUEST
      ) {
        pageBeachChairBookingAdapter.removeAll(state.beachChairBookingPage);
        pageBeachChairBookingAdapter.upsertOne(state.beachChairBookingPage, {
          key: `${beachChairId}:${page}:${itemsPerPage}`,
          dataIds: [...bookings.map((booking) => booking.id)],
        });
        state.reqFromBookings = EBookingRequestType.MAIN_BOOKING_REQUEST;
      } else if (
        state.reqFromBookings === EBookingRequestType.CUSTOMER_BOOKING_REQUEST
      ) {
        pageCustomerBookingAdapter.removeAll(state.customerBookingPage);
        pageCustomerBookingAdapter.upsertOne(state.customerBookingPage, {
          key: `${customerId}:${page}:${itemsPerPage}`,
          dataIds: [...bookings.map((booking) => booking.id)],
        });
        state.reqFromBookings = EBookingRequestType.MAIN_BOOKING_REQUEST;
      }
      bookings.forEach((booking) => {
        beachChairBookingAdapter.upsertMany(
          state.beachChairBooking,
          booking.beachChairBookings
        );
      });
      customerBookingAdapter.upsertMany(state.customerBooking, bookings);
    },
    fetchBookingsFailure(
      state,
      action: PayloadAction<FetchBookingsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    fetchBookingRequest(
      state,
      action: PayloadAction<FetchBookingRequestPayload>
    ) {
      state.pending = true;
    },
    fetchBookingSuccess(
      state,
      action: PayloadAction<FetchBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
    },
    fetchBookingFailure(
      state,
      action: PayloadAction<FetchBookingFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterBookingsRequest(
      state,
      action: PayloadAction<FilterBookingsRequestPayload>
    ) {
      state.pending = true;
    },
    filterBookingsSuccess(
      state,
      action: PayloadAction<FilterBookingsSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertMany(state, action.payload.bookings);
      pageAdapter.upsertOne(state.page, {
        key: `filter:${action.payload.filterPhrase}:${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.bookings.map((booking) => booking.id)],
      });
    },
    filterBookingsFailure(
      state,
      action: PayloadAction<FilterBookingsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterCancelBookingSuccess(state) {
      state.pending = false;
      //back to select PAGE
    },
    filterDataBookingsChangeRequest(
      state,
      action: PayloadAction<FilterDataBookingsChangeRequestPayload>
    ) {
      state.filterData = state.filterData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    pushBookingRequest(
      state,
      action: PayloadAction<PushBookingRequestPayload>
    ) {
      state.pending = true;
    },
    pushBookingSuccess(
      state,
      action: PayloadAction<PushBookingSuccessPayload>
    ) {
      state.pending = false;
    },
    pushBookingFailure(
      state,
      action: PayloadAction<PushBookingFailurePayload>
    ) {
      state.pending = false;
    },
    addBeachChairToBookingRequest(
      state,
      action: PayloadAction<AddBeachChairToBookingRequestPayload>
    ) {
      state.pending = true;
    },
    addBeachChairToBookingSuccess(
      state,
      action: PayloadAction<AddBeachChairToBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.removeOne(state, action.payload.bookingId);
    },
    addBeachChairToBookingFailure(
      state,
      action: PayloadAction<AddBeachChairToBookingFailurePayload>
    ) {
      state.pending = false;
    },
    addExtrasToBookingRequest(
      state,
      action: PayloadAction<AddExtrasToBookingRequestPayload>
    ) {
      state.pending = true;
    },
    addExtrasToBookingSuccess(
      state,
      action: PayloadAction<AddExtrasToBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.removeOne(state, action.payload.bookingId);
    },
    addExtrasToBookingFailure(
      state,
      action: PayloadAction<AddExtrasToBookingFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    deleteBookingBeachChairRequest(
      state,
      action: PayloadAction<DeleteBookingBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    deleteBookingBeachChairSuccess(
      state,
      action: PayloadAction<DeleteBookingBeachChairSuccessPayload>
    ) {
      state.pending = false;
      beachChairBookingAdapter.removeOne(
        state.beachChairBooking,
        action.payload.beachChairId
      );
      if (state.beachChairBooking.ids.length === 0) {
        bookingAdapter.removeOne(state, action.payload.bookingId);
        customerBookingAdapter.removeOne(
          state.customerBooking,
          action.payload.bookingId
        );
      } else {
        bookingAdapter.updateOne(state, {
          id: action.payload.bookingId,
          changes: action.payload.data,
        });
      }
    },
    deleteBookingBeachChairFailure(
      state,
      action: PayloadAction<DeleteBookingBeachChairFailurePayload>
    ) {
      state.pending = false;
    },
    pushBookingCustomerRequest(
      state,
      action: PayloadAction<PushBookingCustomerRequestPayload>
    ) {
      state.pending = true;
    },
    pushBookingCustomerSuccess(
      state,
      action: PayloadAction<PushBookingCustomerSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.bookingId,
        changes: action.payload.bookingData,
      });
    },
    pushBookingCustomerFailure(
      state,
      action: PayloadAction<PushBookingCustomerFailurePayload>
    ) {
      state.pending = false;
    },
    patchBookingRequest(
      state,
      action: PayloadAction<PatchBookingRequestPayload>
    ) {
      state.pending = true;
    },
    patchBookingSuccess(
      state,
      action: PayloadAction<PatchBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    patchBookingFailure(
      state,
      action: PayloadAction<PatchBookingFailurePayload>
    ) {
      state.pending = false;
    },
    putBookingConfirmRequest(
      state,
      action: PayloadAction<PutBookingConfirmRequestPayload>
    ) {
      state.pending = true;
    },
    putBookingConfirmSuccess(
      state,
      action: PayloadAction<PutBookingConfirmSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
    },
    putBookingConfirmFailure(
      state,
      action: PayloadAction<PutBookingConfirmFailurePayload>
    ) {
      state.pending = false;
    },
    putBookingReserveRequest(
      state,
      action: PayloadAction<PutBookingConfirmRequestPayload>
    ) {
      state.pending = true;
    },
    putBookingReserveSuccess(
      state,
      action: PayloadAction<PutBookingConfirmSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
    },
    sendPaymentLinkRequest(
      state,
      action: PayloadAction<SendPaymentLinkRequestPayload>
    ) {},
    sendPaymentLinkSuccess(
      state,
      action: PayloadAction<SendPaymentLinkSuccessPayload>
    ) {},
    putBookingReserveFailure(
      state,
      action: PayloadAction<PutBookingConfirmFailurePayload>
    ) {
      state.pending = false;
    },
    putBookingPayRequest(
      state,
      action: PayloadAction<PutBookingConfirmRequestPayload>
    ) {
      state.pending = true;
    },
    putBookingPaySuccess(
      state,
      action: PayloadAction<PutBookingConfirmSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
    },
    putBookingPayFailure(
      state,
      action: PayloadAction<PutBookingConfirmFailurePayload>
    ) {
      state.pending = false;
    },
    putBookingBeachChairRequest(
      state,
      action: PayloadAction<PutBookingBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    putBookingBeachChairSuccess(
      state,
      action: PayloadAction<PutBookingBeachChairSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
    },
    putBookingBeachChairFailure(
      state,
      action: PayloadAction<PutBookingBeachChairFailurePayload>
    ) {
      state.pending = false;
    },
    createNewBookingRequest(
      state,
      action: PayloadAction<CreateNewBookingRequestPayload>
    ) {
      state.pending = true;
    },
    createNewBookingSuccess(
      state,
      action: PayloadAction<CreateNewBookingSuccessPayload>
    ) {
      state.pending = false;
    },
    createNewBookingFailure(
      state,
      action: PayloadAction<CreateNewBookingFailurePayload>
    ) {
      state.pending = false;
    },
    setInstantBookingCurrentDate(
      state,
      action: PayloadAction<SetCurrentDatePayload>
    ) {
      state.instantBookingCurrentDate = action.payload.date;
    },
    changeInstantBookingTabRequest(
      state,
      action: PayloadAction<ChangeInstantBookingTabRequestPayload>
    ) {
      state.instantBookingTabSet = action.payload.value;
      state.bookingTab = action.payload.value;
    },
    changeBookingTabRequest(
      state,
      action: PayloadAction<ChangeInstantBookingTabRequestPayload>
    ) {
      state.bookingTab = action.payload.value;
    },
    createInstantBookingRequest(
      state,
      action: PayloadAction<CreateInstantBookingRequestPayload>
    ) {
      state.instantBookingPending = { type: action.payload.type, value: false };
    },
    createInstantBookingSuccess(
      state,
      action: PayloadAction<CreateInstantBookingSuccessPayload>
    ) {
      state.instantBookingPending = { value: false };
      bookingAdapter.upsertOne(state, action.payload.booking);
      pageAdapter.removeAll(state.page);
    },
    createInstantBookingFailure(
      state,
      action: PayloadAction<CreateInstantBookingFailurePayload>
    ) {
      state.instantBookingPending = { value: false };
    },
    setBookingIdRequest(
      state,
      action: PayloadAction<SetBookingIdRequestPayload>
    ) {},
    postNewBookingRequest(
      state,
      action: PayloadAction<PostNewBookingRequestPayload>
    ) {
      state.pending = true;
    },
    postNewBookingSuccess(
      state,
      action: PayloadAction<PostNewBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      beachChairBookingAdapter.upsertMany(
        state.beachChairBooking,
        action.payload.booking.beachChairBookings
      );
      customerBookingAdapter.upsertOne(
        state.customerBooking,
        action.payload.booking
      );
      pageAdapter.removeAll(state.page);
    },
    postNewBookingFailure(
      state,
      action: PayloadAction<PostNewBookingFailurePayload>
    ) {
      state.pending = false;
    },
    postNewExtrasBookingRequest(
      state,
      action: PayloadAction<PostNewExtrasBookingRequestPayload>
    ) {
      state.pending = true;
    },
    postNewExtrasBookingSuccess(
      state,
      action: PayloadAction<PostNewExtrasBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.upsertOne(state, action.payload.booking);
      extrasBookingAdapter.upsertMany(
        state.extrasBooking,
        action.payload.booking.extras
      );
      pageAdapter.removeAll(state.page);
    },
    postNewExtrasBookingFailure(
      state,
      action: PayloadAction<PostNewExtrasBookingFailurePayload>
    ) {
      state.pending = false;
    },
    deleteBookingRequest(
      state,
      action: PayloadAction<DeleteBookingRequestPayload>
    ) {
      state.pending = true;
    },
    deleteBookingSuccess(
      state,
      action: PayloadAction<DeleteBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.removeOne(state, action.payload.bookingId);
      pageAdapter.removeAll(state.page);
    },
    deleteBookingFailure(
      state,
      action: PayloadAction<DeleteBookingFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    routerRedirectBookings(
      state,
      action: PayloadAction<RouterRedirectBookingPayload>
    ) {},
    bookingExistingCustomerChosen(
      state,
      action: PayloadAction<BookingExistingCustomerChosenPayload>
    ) {
      state.isExistingCustomer = action.payload.isExistingCustomer;
    },
    cancelBookingRequest(
      state,
      action: PayloadAction<CancelBookingRequestPayload>
    ) {
      state.pending = true;
    },
    cancelBookingSuccess(
      state,
      action: PayloadAction<CancelBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    cancelBookingFailure(
      state,
      action: PayloadAction<CancelBookingFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeBookingExtrasAmountRequest(
      state,
      action: PayloadAction<ChangeBookingExtrasAmountRequestPayload>
    ) {
      state.pending = true;
    },
    changeBookingExtrasAmountSuccess(
      state,
      action: PayloadAction<ChangeBookingExtrasAmountSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    changeBookingExtrasAmountFailure(
      state,
      action: PayloadAction<ChangeBookingExtrasAmountFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    },
    changeDataBeachChairBookingsRequest(state) {
      state.pending = true;
      pageBeachChairBookingAdapter.removeAll(state.beachChairBookingPage);
    },
    changeDataCustomerBookingsRequest(state) {
      state.pending = true;
      pageCustomerBookingAdapter.removeAll(state.customerBookingPage);
    },
    deleteBookingExtrasRequest(
      state,
      action: PayloadAction<DeleteBookingExtrasRequestPayload>
    ) {
      state.pending = true;
    },
    deleteBookingExtrasSuccess(
      state,
      action: PayloadAction<DeleteBookingExtrasSuccessPayload>
    ) {
      state.pending = false;
      extrasBookingAdapter.removeOne(
        state.extrasBooking,
        action.payload.bookingExtrasId
      );
      bookingAdapter.removeOne(state, action.payload.bookingId);
    },
    deleteBookingExtrasFailure(
      state,
      action: PayloadAction<DeleteBookingExtrasFailurePayload>
    ) {
      state.pending = false;
    },
    changeBeachChairsRequest(
      state,
      action: PayloadAction<ChangeBeachChairsRequestPayload>
    ) {
      state.pending = true;
    },
    changeBeachChairsSuccess(
      state,
      action: PayloadAction<ChangeBeachChairsSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    changeBeachChairsFailure(
      state,
      action: PayloadAction<ChangeBeachChairsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    updateCommentRequest(
      state,
      action: PayloadAction<UpdateCommentRequestPayload>
    ) {
      state.pending = true;
    },
    updateCommentSuccess(
      state,
      action: PayloadAction<UpdateCommentSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    updateCustomerCommentRequest(
      state,
      action: PayloadAction<UpdateCommentRequestPayload>
    ) {
      state.pending = true;
    },
    updateCustomerCommentSuccess(
      state,
      action: PayloadAction<UpdateCommentSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    getCSVBookingsRequest(
      state,
      action: PayloadAction<GetCSVBookingsRequestPayload>
    ) {
      state.csvData = undefined;
    },
    getCSVBookingsSuccess(
      state,
      action: PayloadAction<GetCSVBookingsSuccessPayload>
    ) {
      state.csvData = action.payload.data;
    },
    removeBookingBeachChairRightDrawerToggleRequest(
      state,
      action: PayloadAction<RemoveBookingBeachChairRightDrawerToggleRequestPayload>
    ) {
      state.removeBookingBeachChairRightDrawer = action.payload.toggle;
    },
    removeBeachChairBookingRequest(
      state,
      action: PayloadAction<RemoveBeachChairBookingRequestPayload>
    ) {
      state.pending = true;
    },
    removeBeachChairBookingSuccess(
      state,
      action: PayloadAction<RemoveBeachChairBookingSuccessPayload>
    ) {
      state.pending = false;
      bookingAdapter.updateOne(state, {
        id: action.payload.booking.id,
        changes: action.payload.booking,
      });
    },
    removeBeachChairBookingFailure(
      state,
      action: PayloadAction<RemoveBeachChairBookingFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    updateCommentFailure(
      state,
      action: PayloadAction<UpdateCommentFailurePayload>
    ) {
      state.pending = false;
    },
    removePage(state) {
      pageAdapter.removeAll(state.page);
    },
  },
});

export const { selectAll: getAllBookingsPages, selectById: getBookingPages } =
  pageAdapter.getSelectors<AppState>((state) => state.bookings.page);

export const {
  selectAll: getAllBeachChairBookingsPages,
  selectById: getBeachChairBookingPages,
} = pageBeachChairBookingAdapter.getSelectors<AppState>(
  (state) => state.bookings.beachChairBookingPage
);

export const {
  selectAll: getAllCustomerBookingsPages,
  selectById: getCustomerBookingPages,
} = pageCustomerBookingAdapter.getSelectors<AppState>(
  (state) => state.bookings.customerBookingPage
);

export const {
  selectAll: getAllBookings,
  selectById: getBookingById,
  selectTotal: getBookingTotalCount,
} = bookingAdapter.getSelectors<AppState>((state) => state.bookings);

export const {
  selectAll: getAllBookingsBeachChairs,
  selectById: getBookingBrachChairById,
} = beachChairBookingAdapter.getSelectors<AppState>(
  (state) => state.bookings.beachChairBooking
);

export const {
  selectAll: getAllBookingsCustomer,
  selectById: getBookingCustomerById,
} = customerBookingAdapter.getSelectors<AppState>(
  (state) => state.bookings.customerBooking
);

export const {
  resetBookingRequest,
  fetchBookingsRequest,
  fetchBookingsSuccess,
  fetchBookingsFailure,
  fetchBookingRequest,
  fetchBookingSuccess,
  fetchBookingFailure,
  filterBookingsRequest,
  filterBookingsSuccess,
  filterBookingsFailure,
  filterCancelBookingSuccess,
  filterDataBookingsChangeRequest,
  addBeachChairToBookingRequest,
  addBeachChairToBookingSuccess,
  addBeachChairToBookingFailure,
  addExtrasToBookingRequest,
  addExtrasToBookingSuccess,
  addExtrasToBookingFailure,
  pushBookingRequest,
  pushBookingSuccess,
  pushBookingFailure,
  deleteBookingBeachChairRequest,
  deleteBookingBeachChairSuccess,
  deleteBookingBeachChairFailure,
  pushBookingCustomerRequest,
  pushBookingCustomerSuccess,
  pushBookingCustomerFailure,
  patchBookingRequest,
  patchBookingSuccess,
  patchBookingFailure,
  putBookingConfirmRequest,
  putBookingConfirmSuccess,
  putBookingConfirmFailure,
  putBookingReserveRequest,
  putBookingReserveSuccess,
  putBookingReserveFailure,
  putBookingPayRequest,
  putBookingPaySuccess,
  putBookingPayFailure,
  putBookingBeachChairRequest,
  putBookingBeachChairSuccess,
  putBookingBeachChairFailure,
  createNewBookingRequest,
  createNewBookingFailure,
  createNewBookingSuccess,
  setInstantBookingCurrentDate,
  changeInstantBookingTabRequest,
  changeBookingTabRequest,
  createInstantBookingRequest,
  createInstantBookingSuccess,
  createInstantBookingFailure,
  setBookingIdRequest,
  postNewBookingRequest,
  postNewBookingSuccess,
  postNewBookingFailure,
  postNewExtrasBookingRequest,
  postNewExtrasBookingSuccess,
  postNewExtrasBookingFailure,
  deleteBookingRequest,
  deleteBookingSuccess,
  deleteBookingFailure,
  routerRedirectBookings,
  bookingExistingCustomerChosen,
  cancelBookingRequest,
  cancelBookingSuccess,
  cancelBookingFailure,
  changeDataRequest,
  changeDataBeachChairBookingsRequest,
  changeDataCustomerBookingsRequest,
  changeBookingExtrasAmountRequest,
  changeBookingExtrasAmountSuccess,
  changeBookingExtrasAmountFailure,
  deleteBookingExtrasRequest,
  deleteBookingExtrasSuccess,
  deleteBookingExtrasFailure,
  changeBeachChairsRequest,
  changeBeachChairsSuccess,
  changeBeachChairsFailure,
  updateCommentRequest,
  updateCommentSuccess,
  updateCustomerCommentRequest,
  updateCustomerCommentSuccess,
  updateCommentFailure,
  getCSVBookingsRequest,
  getCSVBookingsSuccess,
  removeBookingBeachChairRightDrawerToggleRequest,
  removeBeachChairBookingRequest,
  removeBeachChairBookingSuccess,
  removeBeachChairBookingFailure,
  sendPaymentLinkRequest,
  sendPaymentLinkSuccess,
  removePage,
} = bookingSlice.actions;

export default bookingSlice.reducer;
