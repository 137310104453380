import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useCloseCashPoint } from "../hooks/";

export const ConfirmCloseCashpointModal: React.FC<{
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const { locationId } = useParams<{ locationId?: string }>();

  const closeCashPoint = useCloseCashPoint();

  const handleCloseCashPoint = () => {
    if (closeCashPoint.isLoading) return;
    closeCashPoint
      .mutateAsync({ locationId: Number(locationId) })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="close-cashpoint-modal-title"
      aria-describedby="close-cashpoint-modal-description"
      fullWidth
      disableEscapeKeyDown={closeCashPoint.isLoading}
      maxWidth="sm"
    >
      <DialogTitle id="close-cashpoint-modal-title">
        {t("cashPointBalancePage.modals.closeCashPoint.title")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="close-cashpoint-modal-description">
          <Typography component="span">
            {t("cashPointBalancePage.modals.closeCashPoint.text")}
          </Typography>{" "}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} disabled={closeCashPoint.isLoading}>
          {t("common:cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          loading={closeCashPoint.isLoading}
          onClick={handleCloseCashPoint}
        >
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
