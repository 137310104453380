import { LoadingButton } from "@mui/lab";
import { Typography } from "@mui/material";
import {
  SBLoadingButtonBlueStyle,
  SBLoadingButtonIconDisabledStyle,
  SBLoadingButtonLabelDisabledStyle,
  SBLoadingButtonLabelStyle,
  SBLoadingButtonOrangeStyle,
  SBLoadingButtonStyle,
} from "../styles/SBStyles";

export type SBButtonType = "blue" | "orange";

export interface SBLoadingButtonPrimary {
  label: React.ReactNode;
  type: SBButtonType;
  icon?: React.ReactNode;
  isLoading?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  width?: string;
  disabled?: boolean;
  href?: string;
}

export type SBLoadingButtonType = SBLoadingButtonPrimary;

const SBLoadingButton: React.FC<SBLoadingButtonType> = ({
  icon,
  type,
  label,
  isLoading,
  onClick,
  width,
  disabled,
  href,
}) => {
  const isBlue = type === "blue";
  return (
    <LoadingButton
      sx={{
        ...SBLoadingButtonStyle,
        ...(isBlue ? SBLoadingButtonBlueStyle : SBLoadingButtonOrangeStyle),
        ...(disabled ? SBLoadingButtonIconDisabledStyle : ""),
        width,
      }}
      loading={isLoading}
      loadingPosition="center"
      startIcon={icon}
      variant="outlined"
      onClick={onClick}
      type={onClick ? "button" : href ? undefined : "submit"}
      disabled={disabled}
      href={href}
    >
      <Typography
        sx={{
          ...(disabled
            ? SBLoadingButtonLabelDisabledStyle
            : SBLoadingButtonLabelStyle),
        }}
      >
        {label}
      </Typography>
    </LoadingButton>
  );
};

export default SBLoadingButton;
