import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { featuresApi } from "../../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../../types";

interface UseGetCancellationFee {
  cancellationFee?: number;
  isLoading: boolean;
  refetch: ReactQueryRefetch<number, AxiosError>;
}

export const useGetCancellationFee = ({
  bookingId,
  enabled,
}: {
  bookingId?: number;
  enabled?: boolean;
}): UseGetCancellationFee => {
  const { data: cancellationFee, isLoading, refetch } = useQuery<
    number,
    AxiosError
  >(
    ["cancellationFee", Number(bookingId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return featuresApi
        .bookingDetailsControllerCalculateCancellationFee(
          { id: bookingId ?? 0 },
          { cancelToken: source.token }
        )
        .then(({ data: calculatedFee }) => calculatedFee);
    },
    { enabled: enabled && !!bookingId }
  );

  return {
    cancellationFee,
    isLoading,
    refetch,
  };
};
