import { IPriceValue } from "./../models/prices/IPriceValue";
import { IPrice } from "../models/prices/IPrice";
import { IPriceCalculate } from "../models/prices/IPriceCalculate";
import API from "./api";

const BASE_URL = "/vendor/prices";

export interface IPricesRequest {
  count: number;
  items: IPrice[];
}

export const getPrices = (
  vendorId: number,
  itemsPerPage: number,
  page: number
) =>
  API.get<IPricesRequest>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}`
  );

export const getFilteredPrices = (
  vendorId: number,
  itemsPerPage: number,
  page = 0,
  filter?: string
) =>
  API.get<IPricesRequest>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      filter && `&${filter}`
    }`
  );

export const getSortPrices = (
  vendorId: number,
  itemsPerPage: number,
  page = 0,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IPricesRequest>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ""
    }`
  );

export const getPrice = (priceId: number) => {
  API.get<IPrice>(`${BASE_URL}/${priceId}`);
};

export const putPrice = (priceId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IPrice>(`${BASE_URL}/${priceId}`, data, config);
};

export const getPriceCalculate = (data: IPriceCalculate) =>
  API.get<IPriceValue>(
    `/prices/calculate?sectionId=${data.sectionId}&rowId=${data.rowId}&model=${data.model}&start=${data.start}&end=${data.end}`
  );

export const getRates = (data: IPriceCalculate) =>
  API.get(
    `/prices/rates?sectionId=${data.sectionId}&rowId=${data.rowId}&model=${data.model}&start=${data.start}&end=${data.end}`
  );
