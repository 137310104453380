import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetExtra } from "../../components/extras/hooks/useGetExtra";
import { useCreateExtra } from "../../components/extras/hooks/useCreateExtra";
import { useHistory } from "react-router";
import {
  CreateExternalServicesDto,
  UpdateExternalServicesDto,
} from "../../api-client/generated";
import { useUpdateExtra } from "../../components/extras/hooks/useUpdateExtra";

export function useExtraForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const { data, isLoading } = useGetExtra(id);
  const createExtra = useCreateExtra();
  const updateExtra = useUpdateExtra();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        name: yup.string().required(t("name") + t("isMandatory")),
        type: yup.string().required(t("type") + t("isMandatory")),
        onlineBooking: yup.boolean(),
        locationId: yup.number().nullable(),
        /*.typeError(t("location") + t("isMandatory"))
          .moreThan(0, t("location") + t("isMandatory"))
          .required(t("location") + t("isMandatory")),*/
        price: yup
          .number()
          .typeError(t("price") + t("isMandatory"))
          .moreThan(-1, t("price") + t("isMandatory"))
          .required(t("price") + t("isMandatory")),
        vat: yup.number().nullable(true),
        description: yup.string().nullable(),
        active: yup.boolean().nullable(),
      }),
    [t]
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (data) {
      setValue("name", data.name);
      setValue("type", data.type);
      setValue("locationId", data.locationId);
      setValue("onlineBooking", (data as any).onlineBooking);
      setValue("price", data.price);
      setValue("vat", data.vat || 19);
      setValue("description", (data as any).description);
      setValue("active", (data as any).active);
    }
  }, [data, setValue]);

  const onSubmit = useCallback(
    (formValues) => {
      if (type === "new") {
        createExtra
          .mutateAsync({
            createExternalServicesDto: formValues as CreateExternalServicesDto,
          })
          .finally(() => {
            history.push("/extras");
          });
      } else {
        updateExtra.mutateAsync({
          id: id,
          updateExternalServicesDto: formValues as UpdateExternalServicesDto,
        });
      }
    },
    [id, type, history, updateExtra, createExtra]
  );

  const handleReset = useCallback(() => {
    setValue("name", "");
    setValue("type", "FOOD");
    setValue("locationId", 1);
    setValue("onlineBooking", false);
    setValue("price", 0);
    setValue("vat", 19);
    setValue("description", "");
    setValue("active", false);
  }, [setValue]);

  return {
    isLoading,
    control,
    errors,
    setValue,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
