import { useQuery } from "@tanstack/react-query";
import { ExtendedBeachChairResponseDto } from "../../../api-client/generated";
import axios, { AxiosError } from "axios";
import { featuresApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";
import { SearchVariables } from "./useSearch";

export interface UseSearchBeachChairs {
  beachChairs: ExtendedBeachChairResponseDto[];
  isFetching: boolean;
  refetch: ReactQueryRefetch<ExtendedBeachChairResponseDto[]>;
}

export const useSearchBeachChairs = (
  variables: SearchVariables,
  isById: boolean = false
): UseSearchBeachChairs => {
  const { data, isFetching, refetch } = useQuery<
    ExtendedBeachChairResponseDto[],
    AxiosError
  >(
    [
      `searchBeachChairs${isById ? "ById" : ""}`,
      variables.search,
      variables.itemsPerPage,
    ],
    ({ signal }) => {
      const filters = new URLSearchParams(`filters[id]=${variables.search}`);
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      signal?.addEventListener("abort", () => {
        source.cancel();
      });
      return featuresApi
        .beachChairsControllerGetChairsByVendorId(
          isById
            ? {}
            : {
                search: variables.search,
              },
          {
            params: isById && !isNaN(+variables.search) && filters,
            cancelToken: source.token,
          }
        )
        .then(({ data }) => data.items);
    },
    {
      enabled:
        variables.search.length > 0 &&
        (isById ? !isNaN(+variables.search) : true),
    }
  );

  return {
    beachChairs: data || [],
    isFetching,
    refetch,
  };
};
