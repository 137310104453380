import React, { useMemo } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationResponseDto } from "../../../api-client/generated";
import { useGetLocations, useGetLocation } from "../../location";
import { InfiniteScrollList } from "./InfiniteScrollList";
import {
  SBAutompleteTextFieldStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../SBComponents/styles/SBStyles";

interface LocationOption {
  value: string;
  label: string;
}

interface LocationAutocompleteProps
  extends Omit<
    AutocompleteProps<LocationOption, false, true, false>,
    "options" | "renderInput" | "onChange" | "value"
  > {
  value?: string | number;
  vendorId?: number;
  onChange: (locationId: number) => void;
  label: React.ReactNode;
  icon?: React.ReactNode;
  type?: string;
}

export const LocationsAutocomplete: React.FC<LocationAutocompleteProps> = ({
  value: locationId = "",
  label,
  icon,
  onChange,
  vendorId,
  type,
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  const {
    locations,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isFetching,
  } = useGetLocations({
    itemsPerPage: 25,
    filters: { vendorId },
    enabled: !props.disabled,
  });

  const allLocations: LocationResponseDto[] = useMemo(
    () => locations?.flatMap((page) => page.data) || [],
    [locations]
  );
  const isLocationAlreadyInList = !!allLocations.find(
    ({ id }: LocationResponseDto) => id === Number(locationId)
  );

  // Undefined will prevent a location fetch
  const { location, isFetching: isLocationFetching } = useGetLocation(
    isFetching || isLocationAlreadyInList ? undefined : locationId
  );

  const locationOptions: LocationOption[] = React.useMemo(() => {
    const locationList = [...allLocations];

    if (!isLocationAlreadyInList && location) {
      locationList.push(location);
      locationList.sort((a, b) => a.name.localeCompare(b.name));
    }

    return locationList.map((vendor: LocationResponseDto) => ({
      value: vendor.id.toString(),
      label: vendor.name,
      data: vendor,
    }));
  }, [allLocations, isLocationAlreadyInList, location]);

  const selectedLocation = locationOptions.find(
    ({ value: selectedLocationId }: LocationOption) =>
      selectedLocationId === locationId
  ) || {
    value: locationId.toString(),
    label: "",
  };

  const handleOnChange = (
    event: React.ChangeEvent<{}>,
    selectedOption: LocationOption | null
  ) => {
    if (selectedOption) {
      onChange(Number(selectedOption.value));
    }
  };

  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box sx={{ display: "grid" }}>
        <InputLabel
          sx={{
            ...SBLabelStyle,
            width: "100%",
            position: "static",
            transform: "none",
          }}
        >
          {label}
        </InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          <Autocomplete
            {...props}
            disabled={isDisabled}
            autoComplete={true}
            freeSolo={false}
            multiple={false}
            value={selectedLocation}
            onChange={handleOnChange}
            options={locationOptions}
            getOptionLabel={(option: LocationOption) => option.label}
            loading={isFetching || isLocationFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isDisabled}
                placeholder={t("searchLocation")}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {!props.disabled && isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  disableUnderline: true,
                }}
                sx={{
                  ...SBTextFieldStyle,
                  ...SBAutompleteTextFieldStyle,
                  position: "static",
                }}
              />
            )}
            ListboxComponent={(listboxProps) => (
              <InfiniteScrollList
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                fetchNextPage={fetchNextPage}
                {...listboxProps}
              />
            )}
            clearOnBlur={false}
            clearOnEscape={false}
            disableClearable={true}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};
