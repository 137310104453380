import { all, call, put, takeLatest } from "typed-redux-saga";
import { Action } from "@reduxjs/toolkit";
import {
  fetchVendorRequest,
  fetchVendorSuccess,
  fetchVendorError,
  pushVendorRequest,
  pushVendorSuccess,
  pushVendorError,
  getLocationsRequest,
  getLocationsSuccess,
  getLocationsFailure,
  getLocationRequest,
  getLocationSuccess,
  deleteLocationSuccess,
  deleteLocationRequest,
  putLocationRequest,
  putLocationSuccess,
  getLocationFailure,
  deleteLocationFailure,
  routerRedirectVendorRequest,
  pushLocationRequest,
  pushLocationSuccess,
} from "../../store/reducers/vendorsReducer";
import {
  deleteLocation,
  getLocation,
  getLocations,
  getVendor,
  postLocations,
  putLocation,
  putVendor,
} from "../../api/vendorApi";
import history from "../../utils/history/history";
import { toast } from "react-toastify";
import i18next from "i18next";

function* fetchVendorsSaga(action: Action) {
  try {
    if (fetchVendorRequest.match(action)) {
      const { vendorId } = action.payload;
      const { data } = yield* call(getVendor, vendorId);
      yield put(fetchVendorSuccess({ vendor: data }));
    }
  } catch (e: any) {
    yield* put(
      fetchVendorError({
        error: e.message,
      })
    );
  }
}

function* pushVendorSaga(action: Action) {
  try {
    if (pushVendorRequest.match(action)) {
      const { formData, vendorId } = action.payload;
      const { id, ...newData } = formData;
      const { data } = yield* call(putVendor, vendorId, newData);
      yield* put(pushVendorSuccess({ vendor: data }));
      toast.success(`${i18next.t(`toastMessages.UpdateVendorSuccessMessage`)}`);
    }
  } catch (e: any) {
    yield* put(
      pushVendorError({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.UpdateVendorFailureMessage`)}`);
  }
}

function* getLocationSaga(action: Action) {
  try {
    if (getLocationRequest.match(action)) {
      const { locationId } = action.payload;
      const { data } = yield* call(getLocation, locationId);
      yield* put(getLocationSuccess({ location: data }));
    }
  } catch (e: any) {
    yield* put(
      getLocationFailure({
        error: e.message,
      })
    );
  }
}

function* getLocationsSaga(action: Action) {
  try {
    if (getLocationsRequest.match(action)) {
      const { data } = yield* call(getLocations);
      yield* put(getLocationsSuccess({ locations: data.items }));
    }
  } catch (e: any) {
    yield* put(
      getLocationsFailure({
        error: e.message,
      })
    );
  }
}

function* putLocationSaga(action: Action) {
  try {
    if (putLocationRequest.match(action)) {
      const { locationId, formData } = action.payload;
      const { data } = yield* call(putLocation, locationId, formData);
      yield* put(putLocationSuccess({ locationId, location: data }));
      toast.success(`${i18next.t(`toastMessages.PutLocationSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      getLocationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutLocationFailure`)}`);
  }
}

function* pushLocationSaga(action: Action) {
  try {
    if (pushLocationRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postLocations, formData);
      yield* put(pushLocationSuccess({ location: data }));
      toast.success(`${i18next.t(`toastMessages.PushLocationSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      getLocationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PushLocationFailure`)}`);
  }
}

function* deleteLocationSaga(action: Action) {
  try {
    if (deleteLocationRequest.match(action)) {
      const { locationId } = action.payload;
      yield* call(deleteLocation, locationId);
      yield put(deleteLocationSuccess({}));
    }
  } catch (e: any) {
    yield* put(
      deleteLocationFailure({
        error: e.message,
      })
    );
  }
}

function* routerRedirectVendorSaga(action: Action) {
  if (routerRedirectVendorRequest.match(action)) {
    const { path, id, type } = action.payload;
    yield* call(
      [history, history.push],
      `${path}${id ? `/${id}` : ``}${type ? `/${type}` : ``}`
    );
  }
}

function* VendorSaga() {
  yield* all([
    takeLatest(fetchVendorRequest.type, fetchVendorsSaga),
    takeLatest(pushVendorRequest.type, pushVendorSaga),
    takeLatest(getLocationsRequest.type, getLocationsSaga),
    takeLatest(getLocationRequest.type, getLocationSaga),
    takeLatest(putLocationRequest.type, putLocationSaga),
    takeLatest(pushLocationRequest.type, pushLocationSaga),
    takeLatest(deleteLocationRequest.type, deleteLocationSaga),
    takeLatest(routerRedirectVendorRequest.type, routerRedirectVendorSaga),
  ]);
}

export default VendorSaga;
