import {
  useMutation,
  UseMutateFunction,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingResponseDto,
  UpdateBookingBodyDto,
} from "../../../../api-client/generated";
import { bookingsApi } from "../../../../api/apiTanStack";

interface Variables extends UpdateBookingBodyDto {
  id: number;
}

interface UpdateBooking {
  updateBooking: UseMutateFunction<
    AxiosResponse<BookingResponseDto>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useUpdateBooking = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ["updateBooking"],
    ({ id, ...rest }: Variables) =>
      bookingsApi.bookingsControllerUpdate({
        id,
        updateBookingBodyDto: { ...rest },
      }),
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(["booking", Number(variables.id)]);
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );
};
