import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IFiltersVendors } from "../../components/configuration/model/IDestinations";
import { IFilterData } from "../../models/dataTable/IFilterData";
import { ICurrentUser } from "../../models/user/ICurrentUser";
import { IVendor } from "../../models/vendor/IVendor";
import {
  AdminVendorsFailurePayload,
  DeleteAdminVendorRequestPayload,
  DeleteAdminVendorSuccessPayload,
  FilterDataAdminVendorsChangeRequestPayload,
  GetAdminVendorRequestPayload,
  GetAdminVendorsRequestPayload,
  GetAdminVendorsSuccessPayload,
  GetAdminVendorSuccessPayload,
  GetCurrentUserFailurePayload,
  GetCurrentUserRequestPayload,
  GetCurrentUserSuccessPayload,
  GetVendorsFailurePayload,
  GetVendorsRequestPayload,
  GetVendorsSuccessPayload,
  LoginUserFailurePayload,
  LoginUserSuccessPayload,
  LogoutUserFailurePayload,
  PostAdminVendorRequestPayload,
  PostAdminVendorSuccessPayload,
  PutAdminVendorRequestPayload,
  PutAdminVendorSuccessPayload,
} from "../actionPayload/authPayloads";
import { AppState } from "./rootReducer";

interface IPage {
  key: string;
  dataIds: number[];
}

const vendorsAdapter = createEntityAdapter<IVendor>({
  selectId: (vendor) => vendor.id,
});

const pageVendorsAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

interface IAuthState {
  pending: boolean;
  error: string;
  isAuth: boolean;
  idToken: string;
  currentUser?: ICurrentUser;
  filterVendorsData: IFilterData[];
  filterVendors?: IFiltersVendors;
  vendorsCount: number;
  vendors: EntityState<IVendor>;
  pageVendors: EntityState<IPage>;
}

const INITIAL_STATE: IAuthState = {
  pending: false,
  error: "",
  isAuth: false,
  idToken: "",
  currentUser: undefined,
  filterVendorsData: [],
  filterVendors: undefined,
  vendorsCount: 0,
  vendors: vendorsAdapter.getInitialState({}),
  pageVendors: pageVendorsAdapter.getInitialState({}),
};

const authSlice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    loginUserSuccess(state, action: PayloadAction<LoginUserSuccessPayload>) {
      state.pending = false;
      state.isAuth = true;
      state.idToken = action.payload.id_token;
    },
    loginUserFailure(state, action: PayloadAction<LoginUserFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    logoutUserRequest(state) {
      state.pending = true;
    },
    logoutUserSuccess(state) {
      state.pending = false;
      state.isAuth = false;
      state.idToken = "null";
    },
    logoutUserFailure(state, action: PayloadAction<LogoutUserFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getCurrentUserRequest(
      state,
      action: PayloadAction<GetCurrentUserRequestPayload>
    ) {
      state.pending = true;
    },
    getCurrentUserSuccess(
      state,
      action: PayloadAction<GetCurrentUserSuccessPayload>
    ) {
      state.pending = true;
      state.currentUser = action.payload.currentUser;
    },
    getCurrentUserFailure(
      state,
      action: PayloadAction<GetCurrentUserFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    //Vendors
    getVendorsRequest(state, action: PayloadAction<GetVendorsRequestPayload>) {
      state.pending = true;
    },
    getVendorsSuccess(state, action: PayloadAction<GetVendorsSuccessPayload>) {
      state.pending = false;
      vendorsAdapter.addMany(state.vendors, action.payload.vendors);
    },
    getVendorsFailure(state, action: PayloadAction<GetVendorsFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    //Admin Vendors
    getAdminVendorsRequest(
      state,
      action: PayloadAction<GetAdminVendorsRequestPayload>
    ) {
      state.pending = true;
    },
    getAdminVendorsSuccess(
      state,
      action: PayloadAction<GetAdminVendorsSuccessPayload>
    ) {
      state.pending = false;
      state.vendorsCount = action.payload.count;
      vendorsAdapter.removeAll(state.vendors);
      pageVendorsAdapter.removeAll(state.pageVendors);
      vendorsAdapter.upsertMany(state.vendors, action.payload.vendors);
      pageVendorsAdapter.upsertOne(state.pageVendors, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.vendors.map((vendor) => vendor.id)],
      });
    },
    changeAdminVendorsDataRequest(state) {
      state.pending = true;
      pageVendorsAdapter.removeAll(state.pageVendors);
    },
    getAdminVendorRequest(
      state,
      action: PayloadAction<GetAdminVendorRequestPayload>
    ) {
      state.pending = true;
    },
    getAdminVendorSuccess(
      state,
      action: PayloadAction<GetAdminVendorSuccessPayload>
    ) {
      state.pending = false;
      vendorsAdapter.upsertOne(state.vendors, action.payload.vendor);
    },
    putAdminVendorRequest(
      state,
      action: PayloadAction<PutAdminVendorRequestPayload>
    ) {
      state.pending = true;
    },
    putAdminVendorSuccess(
      state,
      action: PayloadAction<PutAdminVendorSuccessPayload>
    ) {
      state.pending = false;
      vendorsAdapter.updateOne(state.vendors, {
        id: action.payload.vendor.id,
        changes: action.payload.vendor,
      });
      pageVendorsAdapter.removeAll(state.pageVendors);
    },
    postAdminVendorRequest(
      state,
      action: PayloadAction<PostAdminVendorRequestPayload>
    ) {
      state.pending = true;
    },
    postAdminVendorSuccess(
      state,
      action: PayloadAction<PostAdminVendorSuccessPayload>
    ) {
      state.pending = false;
      state.vendorsCount = state.vendorsCount + 1;
      vendorsAdapter.upsertOne(state.vendors, action.payload.vendor);
      pageVendorsAdapter.removeAll(state.pageVendors);
    },
    deleteAdminVendorRequest(
      state,
      action: PayloadAction<DeleteAdminVendorRequestPayload>
    ) {
      state.pending = true;
    },
    deleteAdminVendorSuccess(
      state,
      action: PayloadAction<DeleteAdminVendorSuccessPayload>
    ) {
      state.pending = false;
      vendorsAdapter.removeOne(state.vendors, action.payload.vendorId);
      pageVendorsAdapter.removeAll(state.pageVendors);
    },
    filterDataAdminVendorsChangeRequest(
      state,
      action: PayloadAction<FilterDataAdminVendorsChangeRequestPayload>
    ) {
      state.filterVendorsData = state.filterVendorsData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    adminVendorsFailure(
      state,
      action: PayloadAction<AdminVendorsFailurePayload>
    ) {
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: getVendors,
  selectById: getVendorById,
} = vendorsAdapter.getSelectors<AppState>((state) => state.auth.vendors);

export const {
  selectAll: getAllVendorsPages,
  selectById: getVendorsPages,
} = pageVendorsAdapter.getSelectors<AppState>(
  (state) => state.auth.pageVendors
);

export const {
  loginUserSuccess,
  loginUserFailure,
  logoutUserRequest,
  logoutUserSuccess,
  logoutUserFailure,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  getCurrentUserFailure,
  getVendorsRequest,
  getVendorsSuccess,
  getVendorsFailure,
  //Admin Vendors
  getAdminVendorsRequest,
  getAdminVendorsSuccess,
  changeAdminVendorsDataRequest,
  getAdminVendorRequest,
  getAdminVendorSuccess,
  putAdminVendorRequest,
  putAdminVendorSuccess,
  postAdminVendorRequest,
  postAdminVendorSuccess,
  deleteAdminVendorRequest,
  deleteAdminVendorSuccess,
  filterDataAdminVendorsChangeRequest,
  adminVendorsFailure,
} = authSlice.actions;

export default authSlice.reducer;
