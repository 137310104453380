import { IRowOrder } from "./../../components/configuration/model/IDestinations";
import { useFieldArray, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getSectionRequest,
  postSectionRequest,
  putSectionRequest,
  getBeachesRequest,
} from "../../store/reducers/destinationReducer";
import {
  getDestinationsSectionSelector,
  getDestinationsBeachesSelector,
} from "../../store/selectors/destinationSelectors";
import { getLocationsSelector } from "../../store/selectors/vendorsSelectors";
import { getLocationsRequest } from "../../store/reducers/vendorsReducer";
import { getVendorsRequest } from "../../store/reducers/authReducer";
import { getVendorsSelector } from "../../store/selectors/authSelectors";

export function useSectionForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const data = useSelector(getDestinationsSectionSelector(id));
  const beachesSelector = useSelector(getDestinationsBeachesSelector);
  const locationSelector = useSelector(getLocationsSelector);
  const vendorsSelector = useSelector(getVendorsSelector);

  useEffect(() => {
    beachesSelector.length === 0 &&
      dispatch(getBeachesRequest({ itemsPerPage: 0, page: 0 }));
    // eslint-disable-next-line
  }, [beachesSelector]);

  useEffect(() => {
    locationSelector.length === 0 && dispatch(getLocationsRequest({}));
    // eslint-disable-next-line
  }, [locationSelector]);

  useEffect(() => {
    vendorsSelector.length === 0 && dispatch(getVendorsRequest({}));
    // eslint-disable-next-line
  }, [vendorsSelector]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        vendorId: yup.number(),
        beachId: yup
          .number()
          .required(t("destinations.beachId") + t("isMandatory")),
        locationId: yup
          .number()
          .min(1, t("destinations.locationId") + t("isMandatory"))
          .required(t("destinations.locationId") + t("isMandatory")),
        name: yup.string().required(t("destinations.name") + t("isMandatory")),
        description: yup
          .string()
          .required(t("destinations.description") + t("isMandatory")),
        seoDescription: yup.string().nullable(true),
        seoShortDescription: yup.string().nullable(true),
        beachPlan: yup.string().nullable(true),
        active: yup
          .boolean()
          .required(t("destinations.active") + t("isMandatory")),
        geoJson: yup
          .string()
          .test(
            "test-type-coordinates",
            `${t("destinations.type")} ${t("destinations.coordinates")} ${t(
              "isMandatory"
            )}`,
            (value) =>
              value
                ? value?.includes("type") && value?.includes("coordinates")
                : false
          )
          .required(t("destinations.geoJson") + t("isMandatory")),
        attributes: yup.object().shape({
          wc: yup.boolean().nullable(true),
          ice: yup.boolean().nullable(true),
          dogs: yup.boolean().nullable(true),
          food: yup.boolean().nullable(true),
          nude: yup.boolean().nullable(true),
          wlan: yup.boolean().nullable(true),
          drinks: yup.boolean().nullable(true),
          showers: yup.boolean().nullable(true),
          pedalBoat: yup.boolean().nullable(true),
          carparking: yup.boolean().nullable(true),
          playground: yup.boolean().nullable(true),
          rentingToy: yup.boolean().nullable(true),
          wheelChair: yup.boolean().nullable(true),
          freeMagazin: yup.boolean().nullable(true),
          rentalbikes: yup.boolean().nullable(true),
          dressingRoom: yup.boolean().nullable(true),
        }),
      }),
    [t]
  );

  const {
    control,
    setValue,
    getValues,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    append: imagesAppend,
    remove: imagesRemove,
    fields: imagesFields,
    replace: imagesReplace,
    update: imagesUpdate,
  } = useFieldArray({ name: "images", control, keyName: "key" as "id" });

  const {
    append: rowOrderAppend,
    move: rowOrderMove,
    remove: rowOrderRemove,
    fields: rowOrderFields,
    update: rowOrderUpdate,
  } = useFieldArray({ name: "rowOrder", control, keyName: "key" as "id" });

  useEffect(() => {
    if (!data && type !== "new") {
      dispatch(getSectionRequest({ sectionId: id }));
    }
    // eslint-disable-next-line
  }, [data, id, type]);

  useEffect(() => {
    setValue("name", data?.name || "");
    setValue("description", data?.description || "");
    setValue("seoDescription", data?.seoDescription || "");
    setValue("seoShortDescription", data?.seoShortDescription || "");
    setValue("geoJson", `${JSON.stringify(data?.geoJson || {})}`);
    setValue("beachPlan", data?.beachPlan || "");
    setValue("active", data?.active || false);
    setValue("attributes.wc", data?.attributes.wc || false);
    setValue("attributes.ice", data?.attributes.ice || false);
    setValue("attributes.dogs", data?.attributes.dogs || false);
    setValue("attributes.food", data?.attributes.food || false);
    setValue("attributes.nude", data?.attributes.nude || false);
    setValue("attributes.wlan", data?.attributes.wlan || false);
    setValue("attributes.drinks", data?.attributes.drinks || false);
    setValue("attributes.showers", data?.attributes.showers || false);
    setValue("attributes.pedalBoat", data?.attributes.pedalBoat || false);
    setValue("attributes.carparking", data?.attributes.carparking || false);
    setValue("attributes.playground", data?.attributes.playground || false);
    setValue("attributes.rentingToy", data?.attributes.rentingToy || false);
    setValue("attributes.wheelChair", data?.attributes.wheelChair || false);
    setValue("attributes.freeMagazin", data?.attributes.freeMagazin || false);
    setValue("attributes.rentalbikes", data?.attributes.rentalbikes || false);
    setValue("attributes.dressingRoom", data?.attributes.dressingRoom || false);
    setValue(
      "images",
      data?.images?.map((element: string, index: number) => {
        return { kay: index, value: element };
      }) || []
    );
    setValue("rowOrder", data?.rowOrder || []);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    vendorsSelector.length > 0 && setValue("vendorId", data?.vendorId || 0);
    // eslint-disable-next-line
  }, [data, vendorsSelector]);
  useEffect(() => {
    beachesSelector.length > 0 && setValue("beachId", data?.beachId || 0);
    // eslint-disable-next-line
  }, [data, beachesSelector]);
  useEffect(() => {
    locationSelector.length > 0 &&
      setValue("locationId", data?.locationId || 0);
    // eslint-disable-next-line
  }, [data, locationSelector]);

  const onSubmit = useCallback(
    (formValues) => {
      formValues.geoJson = JSON.parse(formValues.geoJson);
      formValues.images = formValues.images.map(
        (items: { key: number; value: string }) => items.value
      );
      formValues.rowOrder = formValues.rowOrder.map((row: IRowOrder) => row.id);
      //const { vendorId, ...formValuesWithoutVendorId } = formValues;
      if (type === "new") {
        dispatch(postSectionRequest({ formData: formValues }));
      } else {
        dispatch(
          putSectionRequest({
            sectionId: id,
            formData: formValues,
          })
        );
      }
    },
    // eslint-disable-next-line
    [id, type]
  );

  const handleReset = useCallback(() => {
    setValue("vendorId", 0);
    setValue("beachId", 0);
    setValue("locationId", 0);
    setValue("name", "");
    setValue("description", "");
    setValue("seoDescription", "");
    setValue("seoShortDescription", "");
    setValue("beachPlan", "");
    setValue("geoJson", `${JSON.stringify({})}`);
    setValue("attributes.wc", false);
    setValue("attributes.ice", false);
    setValue("attributes.dogs", false);
    setValue("attributes.food", false);
    setValue("attributes.nude", false);
    setValue("attributes.wlan", false);
    setValue("attributes.drinks", false);
    setValue("attributes.showers", false);
    setValue("attributes.pedalBoat", false);
    setValue("attributes.carparking", false);
    setValue("attributes.playground", false);
    setValue("attributes.rentingToy", false);
    setValue("attributes.wheelChair", false);
    setValue("attributes.freeMagazin", false);
    setValue("attributes.rentalbikes", false);
    setValue("attributes.dressingRoom", false);
    setValue("images", []);
    setValue("rowOrder", []);
    // eslint-disable-next-line
  }, [imagesFields]);

  return {
    control,
    errors,
    beachesSelector,
    locationSelector,
    vendorsSelector,
    imagesAppend,
    imagesRemove,
    imagesFields,
    imagesReplace,
    imagesUpdate,
    rowOrderAppend,
    rowOrderMove,
    rowOrderRemove,
    rowOrderFields,
    rowOrderUpdate,
    setValue,
    getValues,
    watch,
    register,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
