import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { beachChairApi } from "../../../../../api/apiTanStack";

export const useUpdateBeachChairLocationMapMutation = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["UpdateBeachChairLocationMapMutation"],
    (data: { id: number; update: any }) =>
      beachChairApi.beachChairsControllerBatchUpdate({
        batchUpdateBeachChairBodyDto: {
          ids: [`${data.id}`],
          update: data.update,
        },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["beachChairList"]);
        queryClient.invalidateQueries(["beachChairsAvailabilities"]);
        toast.success(t(`toastMessages.UpdateBeachChairGeoLocationSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.UpdateBeachChairGeoLocationFailure`));
      },
    }
  );
};
