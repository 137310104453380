import { useMutation, useQueryClient } from "@tanstack/react-query";
import { usersApi } from "../../../../api/apiTanStack";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const useVndorResendConfirmationMutation = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    return useMutation(
      ["useVndorResendConfirmationMutation"],
      (data: {
        id: string;
      }) =>
        usersApi
          .usersControllerResendConfirmation({
            id: data.id
          }),
      {
        onSuccess() {
            queryClient.invalidateQueries(["useVendorUserStatus"])
            toast.success(t("ResendConfirmationSuccess"));
        },
        onError() {
            toast.error(t("ResendConfirmationFailed"));
        },
      }
    );
  };