import React, { useCallback, useState } from "react";
import ConfigurationHeader from "../header/configurationHeader";
import { EDestination } from "../model/EDestination";
import DeleteDialog from "../../dialog/DeleteDialog";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useCityForm } from "../../../utils/customHooks/useCityForm";
import { useDeleteCity } from "./hooks/useDeleteCity";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import {
  Description,
  LocationCity,
  LocationOn,
  TextDecrease,
} from "@mui/icons-material";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBImageUploader from "../../SBComponents/SBForms/SBImageUploader";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";

const City = () => {
  const { t } = useTranslation(["common"]);
  const deleteCity = useDeleteCity();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const {
    control,
    fields,
    remove,
    append,
    onSubmit,
    regionsSelector,
    onReset,
    errors,
  } = useCityForm(+id, type);

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteCity.mutateAsync({ id: id }).finally(() => {
        history.push("/city");
      });
    },
    [history, deleteCity]
  );

  return (
    <SBMainComponentGridWrapper>
      <ConfigurationHeader type={EDestination.CITY} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="regionId"
              label={t("destinations.regionId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<LocationCity />}
              isLoading={false}
              additionalOptions={regionsSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.regionId && t((errors.regionId as any)?.message)}
            />
            <SBTextField
              name="name"
              label={t("destinations.name")}
              control={control}
              type={type}
              icon={<TextDecrease />}
              isLoading={false}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBTextArea
              name="shortDescription"
              label={t("destinations.shortDescription")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.shortDescription &&
                t((errors.shortDescription as any)?.message)
              }
            />
            <SBTextArea
              name="description"
              label={t("destinations.description")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBTextArea
              name="geoJson"
              label={t("destinations.geoJson")}
              control={control}
              type={type}
              icon={<LocationOn />}
              isLoading={false}
              error={errors?.geoJson && t((errors.geoJson as any)?.message)}
            />
            <SBCheckBox
              name="active"
              label={t("destinations.active")}
              control={control}
              type={type}
              isLoading={false}
              error={errors?.active && t((errors.active as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBImageUploader
              label={t("destinations.images")}
              imagesFields={fields}
              imagesAppend={append}
              imagesRemove={remove}
              type={type}
            />
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/city/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/city/${id}/show`)}
          type={type}
          isLoading={false}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="city"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default City;
