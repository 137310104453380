import { createSelector } from "reselect";
import { reportKeyStatusToRow } from "../../utils/conversions/reportKeyStatusToRow";
import {
  getAllReportKeyStatus,
  getReportsKeyStatusPages,
  getReportKeyStatusById,
  getClosingReportPages,
  getAllClosingReport,
  getSepaXMLPages,
  getAllSepaXMLs,
  getPageBeachChairReportsPages,
  getAllBeachChairReports,
} from "../reducers/reportsReducer";
import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.reports.pending;

const getError = (state: AppState) => state.reports.error;

const getCurrentDate = (state: AppState) => state.reports.currentDate;

const getClosingPickupCurrentDate = (state: AppState) =>
  state.reports.closingPickupCurrentDate;

const getClosingReportMaxCount = (state: AppState) =>
  state.reports.closingReportsMaxCount;

const getSepaXMLStartDate = (state: AppState) => state.reports.sepaXMLStartDate;
const getSepaXMLEndDate = (state: AppState) => state.reports.sepaXMLEndDate;
const getSepaXMLMaxCount = (state: AppState) => state.reports.sepaXMLMaxCount;

const getBeachChairReportStartDate = (state: AppState) =>
  state.reports.beachChairReportStartDate;
const getBeachChairReportEndDate = (state: AppState) =>
  state.reports.beachChairReportEndDate;
const getBeachChairReportsMaxCount = (state: AppState) =>
  state.reports.beachChairReportsMaxCount;
const getBeachChairReportCreatedAtPeriod = (state: AppState) =>
  state.reports.beachChairReportCreatedAtPeriod;
const getFilterData = (state: AppState) => state.reports.filterData;
const getKeyStatusCount= (state: AppState) =>
  state.reports.keyStatusCount;


export const getFilterDataSelector = createSelector(
  getFilterData,
  (filterData) => filterData
);

export const getReportKeyStatusSelector = createSelector(
  getAllReportKeyStatus,
  (reports) => reports
);

export const getReportsKayStatusCountSelector = createSelector(
  getKeyStatusCount,
  (reports) => reports
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getReportStatusPage = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) => {
    const page = getReportsKeyStatusPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds
        .map((id) => getReportKeyStatusById(state, id)!)
        .map(reportKeyStatusToRow)
    );
  });

export const getReportStatusByIdSelector = (id: number) =>
  createSelector(getState, (state) => {
    return getReportKeyStatusById(state, id);
  });

export const getReportKeyStatusCurrentDateSelector = createSelector(
  getCurrentDate,
  (date) => date
);

export const getClosingReportPickupCurrentDateSelector = createSelector(
  getClosingPickupCurrentDate,
  (date) => date
);

export const getClosingReportsSelector = (
  pageNumber: number,
  itemsPerPage: number
) =>
  createSelector(getState, (state) => {
    const page = getClosingReportPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.closingReportsNames.map(
        (name) =>
          getAllClosingReport(state).filter((report) => report.name === name)[0]
      )
    );
  });

export const getClosingReportsCountSelector = createSelector(
  getClosingReportMaxCount,
  (closingReportsMaxCount) => closingReportsMaxCount
);

export const getSepaXMLStartDateSelector = createSelector(
  getSepaXMLStartDate,
  (date) => date
);

export const getSepaXMLEndDateSelector = createSelector(
  getSepaXMLEndDate,
  (date) => date
);

export const getSepaXMLMaxCountSelector = createSelector(
  getSepaXMLMaxCount,
  (sepaXMLMaxCount) => sepaXMLMaxCount
);

export const getSepaXMLsSelector = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) => {
    const page = getSepaXMLPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.sepaXMLNames.map(
        (name) =>
          getAllSepaXMLs(state).filter((report) => report.name === name)[0]
      )
    );
  });

export const getBeachChairReportStartDateSelector = createSelector(
  getBeachChairReportStartDate,
  (date) => date
);

export const getBeachChairReportEndDateSelector = createSelector(
  getBeachChairReportEndDate,
  (date) => date
);

export const getBeachChairReportsMaxCountSelector = createSelector(
  getBeachChairReportsMaxCount,
  (maxCount) => maxCount
);

export const getBeachChairReportCreatedAtPeriodSelector = createSelector(
  getBeachChairReportCreatedAtPeriod,
  (maxCount) => maxCount
);

export const getBeachChairReportsSelector = (
  pageNumber: number,
  itemsPerPage: number
) =>
  createSelector(getState, (state) => {
    const page = getPageBeachChairReportsPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.beachChairReportsNames.map(
        (name) =>
          getAllBeachChairReports(state).filter(
            (report) => report.name === name
          )[0]
      )
    );
  });
