import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { reportsApi } from "../../../../api/apiTanStack";
import { useGetCurrentUser } from "../../../../hooks";

export const useUpdateClosingReports = () => {
  const queryClient = useQueryClient();
  const { currentUser } = useGetCurrentUser();

  return useMutation(
    ["updateClosingReports"],
    (data: { startDate: string; endDate: string; reportType: string }) => {
      const startDateString: string = format(
        new Date(data.startDate),
        "yyyy-MM-dd"
      );
      const endDateString: string = format(
        new Date(data.endDate),
        "yyyy-MM-dd"
      );
      return reportsApi.reportsControllerGenerateReportPDF(
        {
          startDate: startDateString,
          endDate: endDateString,
          reportType: data.reportType,
          userReport: currentUser?.id,
        },
        { headers: { "x-custom-lang": "de" } }
      );
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(["closingReports"]);
      },
    }
  );
};
