import { Action } from "redux";
import { getInvoice, getShortInvoice } from "../../api/invoiceApi";
import { all, put, call, takeLatest } from "typed-redux-saga";
import {
  fetchInvoiceRequest,
  fetchInvoiceSuccess,
  fetchInvoiceError,
  getShortInvoiceRequest,
  getShortInvoiceSuccess,
  fetchCancellationRequest,
  fetchCancellationSuccess,
} from "../reducers/invoicesReducer";

function* fetchInvoicesSaga(action: Action) {
  try {
    if (fetchInvoiceRequest.match(action)) {
      const {
        vendorId,
        bookingId,
        invoiceNumber,
        receiptType,
      } = action.payload;
      const { data } = yield* call(
        getInvoice,
        bookingId,
        vendorId,
        invoiceNumber,
        receiptType
      );
      yield* put(
        fetchInvoiceSuccess({
          url: data.url,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchInvoiceError({
        error: e.error,
      })
    );
  }
}

function* fetchCancellationSaga(action: Action) {
  try {
    if (fetchCancellationRequest.match(action)) {
      const {
        vendorId,
        bookingId,
        invoiceNumber,
        receiptType,
      } = action.payload;
      const { data } = yield* call(
        getInvoice,
        bookingId,
        vendorId,
        invoiceNumber,
        receiptType
      );
      yield* put(
        fetchCancellationSuccess({
          url: data.url,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchInvoiceError({
        error: e.error,
      })
    );
  }
}

function* getShortInvoicesSaga(action: Action) {
  try {
    if (getShortInvoiceRequest.match(action)) {
      const { bookingId } = action.payload;
      const { data } = yield* call(getShortInvoice, bookingId);
      const buff = Buffer.from(data.data);
      const file = new Blob([buff], { type: "application/pdf" });
      const blobURL = URL.createObjectURL(file);
      const iframe = document.createElement("iframe");
      document.body.appendChild(iframe);
      iframe.style.display = "none";
      iframe.src = blobURL;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow?.print();
        }, 1);
      };
      yield* put(getShortInvoiceSuccess({}));
    }
  } catch (e: any) {
    yield* put(
      fetchInvoiceError({
        error: e.error,
      })
    );
  }
}

function* invoicesSaga() {
  yield* all([
    takeLatest(fetchInvoiceRequest.type, fetchInvoicesSaga),
    takeLatest(fetchCancellationRequest.type, fetchCancellationSaga),
    takeLatest(getShortInvoiceRequest.type, getShortInvoicesSaga),
  ]);
}

export default invoicesSaga;
