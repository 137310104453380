import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { postAdminContextVendor } from "../../api/adminApi";
import {
  postAdminContextVendorFailure,
  postAdminContextVendorRequest,
  postAdminContextVendorSuccess,
  postAdminVendorChangeContextCompleteSuccess,
} from "../reducers/adminReducer";
import { resetAvailabilityRequest } from "../reducers/availabilityReducer";
import { resetBeachChairRequest } from "../reducers/beachChairsReducer";
import { resetBookingRequest } from "../reducers/bookingsReducer";
import { resetCustomerRequest } from "../reducers/customersReducer";
import { resetExtrasRequest } from "../reducers/extrasReducer";
import { resetGlobalBookingRequest } from "../reducers/globalBookingReducer";
import { resetInvoiceRequest } from "../reducers/invoicesReducer";
import { resetPriceRequest } from "../reducers/pricesReducer";
import { resetReportRequest } from "../reducers/reportsReducer";
import { resetSearchRequest } from "../reducers/searchReducer";
import {
  fetchVendorRequest,
  resetVendorRequest,
} from "../reducers/vendorsReducer";
import API from "./../../api/api";
import { axiosInstance } from "../../api/apiTanStack";
import { queryClient } from "../..";

function* postAdminContextVendorSaga(action: Action) {
  try {
    if (
      postAdminContextVendorRequest.match(action) &&
      API.defaults.headers.common["Authorization"] !== "" &&
      axiosInstance.defaults.headers.common["Authorization"] !== ""
    ) {
      const { vendorId } = action.payload;
      yield* call(postAdminContextVendor, vendorId);
      yield* put(postAdminContextVendorSuccess({ vendorId }));
      yield* put(resetBeachChairRequest());
      yield* put(resetVendorRequest());
      yield* put(fetchVendorRequest({ vendorId }));
      yield* put(resetAvailabilityRequest());
      yield* put(resetBookingRequest());
      yield* put(resetCustomerRequest());
      yield* put(resetExtrasRequest());
      yield* put(resetGlobalBookingRequest());
      yield* put(resetInvoiceRequest());
      yield* put(resetPriceRequest());
      yield* put(resetReportRequest());
      yield* put(resetSearchRequest());
      queryClient.invalidateQueries({
        queryKey: ["BeachChairBookings"],
      });
      queryClient.invalidateQueries({
        queryKey: ["availabilitiesSections"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChairsAvailabilities"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChairsAvailabilitiesCalendar"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChairBookingList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChair"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChairList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["beachChairFilters"],
      });
      queryClient.invalidateQueries({
        queryKey: ["useBookigHistory"],
      });
      queryClient.invalidateQueries({
        queryKey: ["bookingList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["closingReports"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminBeaches"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminCities"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminLocations"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminRegions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminSections"],
      });
      queryClient.invalidateQueries({
        queryKey: ["customerBookingList"],
      });
      queryClient.invalidateQueries({
        queryKey: ["customers"],
      });
      queryClient.invalidateQueries({
        queryKey: ["searchCustomers"],
      });
      queryClient.invalidateQueries({
        queryKey: ["extra"],
      });
      queryClient.invalidateQueries({
        queryKey: ["extras"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminLocks"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getPrice"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getPrices"],
      });
      queryClient.invalidateQueries({
        queryKey: ["getReportKeyStatuses"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminShortUrls"],
      });
      queryClient.invalidateQueries({
        queryKey: ["adminVendors"],
      });
      queryClient.invalidateQueries({
        queryKey: ["GetVendorsAutocompleteOptions"],
      });
      queryClient.invalidateQueries({
        queryKey: ["vendorUsers"],
      });
      queryClient.invalidateQueries({
        queryKey: ["availabilitiesBeachChairsChangeQuery"],
      });
      queryClient.invalidateQueries({
        queryKey: ["locations"],
      });
      queryClient.invalidateQueries({
        queryKey: ["location"],
      });
      queryClient.invalidateQueries({
        queryKey: ["GetBilling"],
      });
      queryClient.invalidateQueries({
        queryKey: ["useStatistics"],
      });
      yield* put(postAdminVendorChangeContextCompleteSuccess());
    }
  } catch (e: any) {
    yield* put(
      postAdminContextVendorFailure({
        error: e.message,
      })
    );
  }
}

function* AdminSaga() {
  yield* all([
    takeLatest(postAdminContextVendorRequest.type, postAdminContextVendorSaga),
  ]);
}

export default AdminSaga;
