import AppsIcon from "@mui/icons-material/Apps";
import AssessmentIcon from "@mui/icons-material/Assessment";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import BuildIcon from "@mui/icons-material/Build";
import DateRangeIcon from "@mui/icons-material/DateRange";
import BedroomChildIcon from "@mui/icons-material/BedroomChild";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";
import GridOnIcon from "@mui/icons-material/GridOn";
import HomeIcon from "@mui/icons-material/Home";
import HttpIcon from "@mui/icons-material/Http";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import PeopleIcon from "@mui/icons-material/People";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import SettingsIcon from "@mui/icons-material/Settings";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import StorefrontIcon from "@mui/icons-material/Storefront";
import SummarizeIcon from "@mui/icons-material/Summarize";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import WavesIcon from "@mui/icons-material/Waves";
import WorkIcon from "@mui/icons-material/Work";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ViewTimelineIcon from "@mui/icons-material/ViewTimeline";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useGetCurrentUser, UserRoles } from "../../../../hooks";
import { useGetVendor } from "../../../vendor";
import {
  IMenuItem,
  IMenuNestedItem,
  ISubmenu,
} from "../../../../models/menu/IMenuItem";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { useMenuZustand } from "../hooks/useMenuZustand";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Euro, Map, Payment } from "@mui/icons-material";
import { useSBTablePersistState } from "../../../extras/hooks/useSBTablePersist";

export const NavigationLinks: React.FC = () => {
  const { currentUser } = useGetCurrentUser();
  const { pathname } = useLocation();
  const { clear } = useSBTablePersistState();
  const { data: vendor } = useGetVendor(currentUser?.vendorId);
  const showCashPoint = !!(
    vendor?.configuration?.hasTSE && currentUser?.defaultLocationId
  );
  const showGrid =
    vendor?.configuration?.beachChairView === "grid" ||
    vendor?.configuration?.beachChairView === "quota";
  const [submenu, setSubmenu] = useState<ISubmenu>({
    settings: false,
    configuration: false,
    bookings: true,
    availabilities: false,
    cashPoint: false,
  });
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation(["common"]);
  const { open, selectedMenuItem, changeMenuItem } = useMenuZustand();

  const menuItems: IMenuItem[] = useMemo<IMenuItem[]>(
    () => [
      {
        text: t("home"),
        icon: <HomeIcon />,
        path: "/",
        keyId: "home",
      },
      {
        text: t("bookingsMenu.instantBooking"),
        icon: <ShoppingCartIcon />,
        path: "/instant-bookings",
        keyId: "instant-bookings",
      },
      showGrid === true
        ? {
            text: t("availabilitiesMenu.box"),
            icon: <GridOnIcon />,
            path: "/availabilities/box",
            keyId: "box",
          }
        : { text: "", keyId: "" },
      showGrid === false
        ? {
            text: t("availabilitiesMenu.map"),
            icon: <Map />,
            path: "/availabilities/map",
            keyId: "map",
          }
        : { text: "", keyId: "" },
      {
        text: t("availabilitiesMenu.grid"),
        icon: <ViewTimelineIcon />,
        path: "/availabilities/grid",
        keyId: "grid",
      },
      {
        text: t("bookingsMenu.beachChairsBookings"),
        icon: <DateRangeIcon />,
        path: "/bookings",
        keyId: "typical-bookings",
      },
      {
        text: t("beachChairs"),
        icon: <BeachAccessIcon />,
        path: "/beach_chairs",
        keyId: "strandkoerbe",
      },
      {
        text: t("extras"),
        icon: <StorefrontIcon />,
        path: "/extras",
        keyId: "extras",
      },
      {
        text: t("customers"),
        icon: <FamilyRestroomIcon />,
        path: "/customers",
        keyId: "kunden",
      },
      {
        text: t("vendorUsers"),
        icon: <PeopleAltIcon />,
        path: "/vendor_users",
        keyId: "vendorUsers",
        accessRoles: [UserRoles.SUPER_ADMIN, UserRoles.VENDOR_SUPER_ADMIN],
      },
      {
        text: t("reports"),
        icon: <AssessmentIcon />,
        path: "/reports",
        keyId: "berichte",
      },
      showCashPoint
        ? {
            text: t("cashPoint"),
            icon: <PointOfSaleIcon />,
            keyId: "cashPoint",
            isOpen: submenu.cashPoint,
            setIsOpen: setSubmenu,
            nestedItems: [
              {
                text: t("cashPointMenu.cashPointBalance"),
                icon: <EuroSymbolIcon />,
                path: `/cashpoint/${currentUser?.defaultLocationId}/cash-point-balance`,
                keyId: "cashPointBalance",
              },
              {
                text: t("cashPointMenu.cashPointReports"),
                icon: <SummarizeIcon />,
                path: `/cashpoint/${currentUser?.defaultLocationId}/cash-point-reports`,
                keyId: "cashPointReports",
              },
            ],
          }
        : { text: "", keyId: "" },
      {
        text: t("settings"),
        icon: <SettingsIcon />,
        keyId: "settings",
        isOpen: submenu.settings,
        setIsOpen: setSubmenu,
        nestedItems: [
          {
            text: t("settingsMenu.companyData"),
            icon: <WorkIcon />,
            path: "/vendor",
            keyId: "vendor",
          },
          {
            text: t("commission"),
            icon: <Euro />,
            path: "/billing",
            keyId: "vendorsBilling",
            accessRoles: [UserRoles.SUPER_ADMIN],
          },
          {
            text: t("extraTexts"),
            icon: <TextFieldsIcon />,
            path: "/extraTexts",
            keyId: "extraTexts",
          },
          {
            text: t("settingsMenu.rentalOffice"),
            icon: <EditLocationIcon />,
            path: "/location",
            keyId: "location",
          },
        ],
      },
      {
        text: t("adminConfiguration"),
        icon: <BuildIcon />,
        keyId: "configurations",
        isOpen: submenu.configuration,
        accessRoles: [UserRoles.SUPER_ADMIN],
        setIsOpen: setSubmenu,
        nestedItems: [
          {
            text: t("configurationMenu.vendors"),
            icon: <PeopleIcon />,
            path: "/vendors",
            keyId: "configuration-vendors",
          },
          {
            text: t("prices"),
            icon: <Payment />,
            path: "/prices",
            keyId: "prices",
            accessRoles: [UserRoles.SUPER_ADMIN, UserRoles.VENDOR_SUPER_ADMIN],
          },
          {
            text: t("configurationMenu.region"),
            icon: <EmojiTransportationIcon />,
            path: "/region",
            keyId: "configuration-region",
          },
          {
            text: t("configurationMenu.city"),
            icon: <LocationCityIcon />,
            path: "/city",
            keyId: "configuration-city",
          },
          {
            text: t("configurationMenu.beach"),
            icon: <WavesIcon />,
            path: "/beach",
            keyId: "configuration-beach",
          },
          {
            text: t("configurationMenu.section"),
            icon: <AppsIcon />,
            path: "/section",
            keyId: "configuration-section",
          },
          {
            text: t("configurationMenu.location"),
            icon: <LocationOnIcon />,
            path: "/locations",
            keyId: "configuration-locations",
          },
          {
            text: t("configurationMenu.shortUrls"),
            icon: <HttpIcon />,
            path: "/shortUrls",
            keyId: "configuration-shortUrls",
          },
          {
            text: t("configurationMenu.locks"),
            icon: <LockOpenIcon />,
            path: "/locks",
            keyId: "configuration-locks",
          },
        ],
      },
    ],
    [submenu, t, currentUser, showCashPoint, showGrid]
  );

  return (
    <Box
      sx={{
        maxHeight: open
          ? "calc(100vh - 180px - 70px - 20px)"
          : "calc(100vh - 130px)",
        overflowY: "scroll",
        boxSizing: "content-box",
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: customStyle.mainColor,
        },
      }}
    >
      {menuItems
        .filter(({ keyId, accessRoles }) => {
          return (
            keyId &&
            (!accessRoles ||
              accessRoles.some((role) => {
                return (currentUser?.roles ?? []).includes(role);
              }))
          );
        })
        .map((menuItem: IMenuItem) => {
          const { text, icon, nestedItems, path, keyId, isOpen, setIsOpen } =
            menuItem;

          const isSelcted = path === selectedMenuItem ?? false;

          return (
            <Fragment key={`fragment-${keyId}`}>
              <ListItemButton
                selected={isSelcted}
                sx={{
                  height: "40px",
                  ".MuiListItemButton-root": {
                    width: open
                      ? customStyle.menuWidth
                      : customStyle.minMenuWidth,
                  },
                  "&.Mui-selected": {
                    backgroundColor: customStyle.secondaryColor,
                    opacity: 1,
                    "&:hover": {
                      backgroundColor: customStyle.secondaryColor,
                    },
                  },
                }}
                key={`listItem-${keyId}`}
                data-testid={`listItem-${keyId}`}
                onClick={() => {
                  if (path) {
                    changeMenuItem(path);
                    history.push(path);
                    if (!pathname.includes(path)) {
                      clear();
                    }
                  }
                  if (setIsOpen) {
                    if (keyId.includes("settings")) {
                      setIsOpen({
                        settings: !isOpen,
                        configuration: false,
                        bookings: false,
                        availabilities: false,
                        cashPoint: false,
                      });
                    }
                    if (keyId.includes("configurations")) {
                      setIsOpen({
                        settings: false,
                        configuration: !isOpen,
                        bookings: false,
                        availabilities: false,
                        cashPoint: false,
                      });
                    }
                    if (keyId.includes("bookings")) {
                      setIsOpen({
                        settings: false,
                        configuration: false,
                        bookings: !isOpen,
                        availabilities: false,
                        cashPoint: false,
                      });
                    }
                    if (keyId.includes("verfuegbarkeiten")) {
                      setIsOpen({
                        settings: false,
                        configuration: false,
                        bookings: false,
                        availabilities: !isOpen,
                        cashPoint: false,
                      });
                    }
                    if (keyId.includes("cashPoint")) {
                      setIsOpen({
                        settings: false,
                        configuration: false,
                        bookings: false,
                        availabilities: false,
                        cashPoint: !isOpen,
                      });
                    }
                  }
                }}
              >
                {icon && (
                  <ListItemIcon
                    sx={{
                      "&.MuiListItemIcon-root": {
                        minWidth: open ? "40px" : "20px",
                      },
                      color: !isSelcted
                        ? customStyle.textColor
                        : customStyle.mainColor,
                      opacity: !isSelcted ? 0.6 : 0.5,
                    }}
                    key={`listItemIcon-${keyId}`}
                    data-testid={`listItemIcon-${keyId}`}
                  >
                    {icon}
                  </ListItemIcon>
                )}
                {open && (
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                    sx={{
                      color: customStyle.textColor,
                      opacity: 0.8,
                    }}
                    primary={text}
                    key={`listItemText-${keyId}`}
                    data-testid={`listItemText-${keyId}`}
                  />
                )}
                {nestedItems &&
                  (isOpen ? (
                    <ArrowDropDownIcon
                      sx={{
                        color: customStyle.textColor,
                        fontSize: "20px",
                        fontWeight: "bolder",
                        opacity: 0.8,
                      }}
                      key={`arrowDropDown-${keyId}`}
                      data-testid={`arrowDropDown-${keyId}`}
                    />
                  ) : (
                    <ArrowRightIcon
                      sx={{
                        color: customStyle.textColor,
                        fontSize: "20px",
                        fontWeight: "bolder",
                        opacity: 0.8,
                      }}
                      key={`arrowRight-${keyId}`}
                      data-testid={`arrowRight-${keyId}`}
                    />
                  ))}
              </ListItemButton>
              {nestedItems && (
                <Collapse
                  in={isOpen}
                  timeout="auto"
                  unmountOnExit
                  key={`collapse-${keyId}`}
                  data-testid={`collapse-${keyId}`}
                >
                  <List
                    component="div"
                    disablePadding
                    key={`listNested-${keyId}`}
                  >
                    {nestedItems
                      .filter(({ keyId, accessRoles }) => {
                        return (
                          keyId &&
                          (!accessRoles ||
                            accessRoles.some((role) => {
                              return (currentUser?.roles ?? []).includes(role);
                            }))
                        );
                      })
                      .map((nestedItem: IMenuNestedItem) => {
                        const { text, path, keyId, icon } = nestedItem;
                        const isNestedSelcted = location.pathname.includes(
                          path || ""
                        );
                        return (
                          <ListItemButton
                            selected={isNestedSelcted}
                            sx={{
                              height: "40px",
                              backgroundColor: "#38529c",
                              "&.Mui-selected": {
                                backgroundColor: customStyle.secondaryColor,
                                opacity: 1,
                                "&:hover": {
                                  backgroundColor: customStyle.secondaryColor,
                                },
                              },
                            }}
                            key={`listItemNested-${keyId}`}
                            onClick={() => {
                              if (path) {
                                changeMenuItem(path);
                                history.push(path || "");
                                if (!pathname.includes(path)) {
                                  clear();
                                }
                              }
                            }}
                          >
                            {open ? (
                              <ListItemText
                                primaryTypographyProps={{
                                  fontSize: "15px",
                                  fontWeight: "bold",
                                }}
                                primary={text}
                                key={`listItemTextNested-${keyId}`}
                                sx={{
                                  color: customStyle.textColor,
                                  opacity: 0.6,
                                  paddingLeft: "40px",
                                }}
                              />
                            ) : (
                              icon && (
                                <ListItemIcon
                                  sx={{
                                    "&.MuiListItemIcon-root": {
                                      minWidth: open ? "40px" : "20px",
                                    },
                                    color: !isNestedSelcted
                                      ? customStyle.textColor
                                      : customStyle.mainColor,
                                    opacity: !isNestedSelcted ? 0.6 : 0.5,
                                  }}
                                  key={`listItemIcon-${keyId}`}
                                  data-testid={`listItemIcon-${keyId}`}
                                >
                                  {icon}
                                </ListItemIcon>
                              )
                            )}
                          </ListItemButton>
                        );
                      })}
                  </List>
                </Collapse>
              )}
            </Fragment>
          );
        })}
    </Box>
  );
};
