import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import React from "react";
import { useTranslation } from "react-i18next";
import DialogAvailabilitiesBeachChairsGridPrint from "./availabilitiesBeachChairsGridPrint";
import { Box, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import { Print } from "@mui/icons-material";
import SBSelectListSubHeader from "../../../SBComponents/SBForms/SBSelectListSubHeader";
import {
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
  SBHeaderItemsColumnsStyle,
} from "../../../SBComponents/styles/SBStyles";
import { deDE } from "@mui/x-date-pickers/locales";

export const cheeckMonth = (checker: "month" | "week") => checker === "month";

const AvailabilitiesPrintPickers = () => {
  const { t } = useTranslation(["common"]);
  const [datePicker, setDatePicker] = React.useState<Date>(new Date());
  const [gridDialog, setGridDialog] = React.useState<boolean>(false);
  const [option, setOption] = React.useState<"month" | "week">("month");
  return (
    <Box sx={{ ...SBDatePickerHeightStyle, ...SBHeaderItemsColumnsStyle }}>
      <LocalizationProvider
        adapterLocale="de"
        dateAdapter={AdapterDayjs}
        localeText={
          deDE.components.MuiLocalizationProvider.defaultProps.localeText
        }
      >
        <SBSelectListSubHeader
          name="month/week"
          type={"edit"}
          isLoading={false}
          handleChange={(e) => {
            setOption(e.target.value === "month" ? "month" : "week");
          }}
          defaultValue={"month"}
          option={option}
        >
          <MenuItem value={"month"}>{t("month")}</MenuItem>
          <MenuItem value={"week"}>{t("weeks")}</MenuItem>
        </SBSelectListSubHeader>
        <MobileDatePicker
          onChange={(newValue: Date | null) => {
            if (newValue) {
              setDatePicker(newValue);
            }
          }}
          sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
          slotProps={{
            textField: {
              variant: "outlined",
            },
          }}
          views={[...((option === "week" ? ["day"] : []) as any), "month"]}
          value={dayjs(datePicker) as any}
        />
      </LocalizationProvider>
      <SBLoadingButton
        label={t("print")}
        type="blue"
        icon={<Print />}
        onClick={() => setGridDialog(true)}
      />
      <DialogAvailabilitiesBeachChairsGridPrint
        open={gridDialog}
        setGridLayout={setGridDialog}
        selectedDate={datePicker}
        option={cheeckMonth(option)}
      />
    </Box>
  );
};

export default AvailabilitiesPrintPickers;
