import { customStyle } from "./customStyles";

export const componentRoot = {
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  bottom: 0,
};
export const componentHeader = {
  height: "48px",
  paddingTop: "15px",
  paddingLeft: "16px",
  fontWeight: "bold",
};
export const iconItem = {
  color: "black",
};
export const iconAddItem = {
  color: customStyle.mainColor,
};
export const iconResetItem = {
  color: customStyle.secondaryColor,
};
export const typographyLocation = {
  fontFamily: "NunitoMedium",
  fontWeight: "bold",
};
export const link = {
  fontFamily: "NunitoMedium",
  color: "gray",
  textDecoration: "none",
};
export const buttonFlex = {
  display: "flex",
  gap: 1,
  alignContent: "flex-end",
  justifyContent: "flex-end",
} as const;
export const button = {
  fontWeight: "bold",
  gridColumn: 2,
  gridRow: 1,
  height: "35px",
  fontSize: "100%",
  color: "white",
  backgroundColor: customStyle.mainColor,
};
export const iconButton = {
  height: "24px",
  width: "24px",
  padding: 0,
};
export const breadcrumb = {
  fontFamily: "NunitoMedium",
  gridColumn: 1,
  gridRow: 2,
  "& > *": {
    color: "black",
  },
};
export const buttonCalendar = {
  height: 35,
  width: 230,
  fontSize: 12,
  color: "white",
  backgroundColor: customStyle.secondaryColor,
};

export const root = {
  paddingTop: 20,
  paddingLeft: 20,
  paddingRight: 20,
  display: "grid",
  gridTemplateRows: "72px auto calc(100vh - 310px)",
};

export const instantBookingsContentBox = {
  marginTop: "20px",
  width: "100%",
  display: "grid",
  paddingBottom: "10px",
  gridTemplateColumns: "repeat(auto-fit, minmax(300px, 330px));",
  gap: "30px",
  minHeight: `calc(100vh - 280px)`,
  maxHeight: `calc(100vh - 280px)`,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
    height: "0px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;

export const vendorBookingsCard = {
  display: "grid",
  gap: "2px",
  width: "300px",
  maxWidth: "300px",
  maxHeight: "850px",
  minHeight: "850px",
  padding: "10px",
  backgroundColor: "white",
} as const;

export const instantBookingsCard = {
  display: "grid",
  gap: "5px",
  width: "300px",
  maxWidth: "300px",
  maxHeight: "500px",
  minHeight: "550px",
  padding: "10px",
  backgroundColor: "white",
} as const;

export const instantBookingsCardControls = {
  width: "280px",
} as const;

export const instantBookingsCardBox = {
  display: "grid",
  alignContent: "center",
  minWidth: "300px",
  gap: "1px",
} as const;

export const instantBookingsCardTypography = {
  fontWeight: "bolder",
  fontSize: "18px",
  textAlign: "center",
  color: customStyle.mainColor,
} as const;

export const contentLockBox = {
  marginTop: "20px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "50% 50%",
  backgroundColor: "white",
  maxHeight: `calc(100vh - 310px)`,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;
export const insideLockCard = {
  paddingTop: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  paddingBottom: "20px",
  display: "grid",
  gridTemplateRows: "repeat(auto-fit, minmax(50px, 50px))",
  gap: "20px",
  maxHeight: "100%",
};
export const contentBox = {
  marginTop: "20px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: "auto 450px",
  backgroundColor: "white",
  maxHeight: `calc(100vh - 310px)`,
  minHeight: `calc(100vh - 290px)`,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;

export const contentBoxShortUrl = {
  marginTop: "20px",
  width: "100%",
  display: "grid",
  gridTemplateColumns: {
    sm: "auto",
    md: "auto auto",
  },
  backgroundColor: "white",
  maxHeight: `calc(100vh - 310px)`,
  minHeight: `calc(100vh - 290px)`,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;

export const insideCard = {
  paddingTop: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  paddingBottom: "20px",
  display: "grid",
  gridTemplateRows: "auto",
  gap: "20px",
  maxHeight: "100%",
};

export const insideCardShortUrl = {
  paddingTop: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  paddingBottom: {
    sm: "0",
    md: "20px",
  },
  display: "grid",
  gridAutoRows: "50px",
  gap: "20px",
  maxHeight: "100%",
};

export const insideCardPaddingTop = {
  paddingTop: {
    sm: "0",
    md: "20px",
  },
};
export const insideBoxImageCard = {
  position: "fixed",
  paddingTop: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  paddingBottom: "20px",
  display: "grid",
  gap: "10px",
  gridTemplateRows: "270px 270px 60px",
  right: "30px",
} as const;
export const cardRoot = {
  padding: "5px",
  display: "grid",
  maxWidth: "200px",
  justifyContent: "center",
  justifyItems: "center",
  alignItems: "center",
  alignContent: "center",
  "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
} as const;
export const cardRowRoot = {
  padding: "5px",
  borderRadius: "4px",
  display: "grid",
  maxWidth: "200px",
  "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
} as const;
export const draggableCardRow = {
  cursor: "grab",
} as const;
export const noneDraggableCardRow = {
  cursor: "default",
} as const;
export const imageLabel = {
  fontSize: "12px",
  color: "rgba(0, 0, 0, 0.38)",
} as const;
export const imagesGrid = {
  display: "grid",
  paddingBottom: "10px",
  gap: "10px",
  gridTemplateColumns: "repeat(auto-fit, minmax(200px, 200px));",
} as const;
export const rowsGrid = {
  display: "grid",
  paddingBottom: "10px",
  gap: "10px",
} as const;
export const iconButtonDelete = {
  height: "30px",
  width: "30px",
} as const;
export const iconButtonDeleteColor = {
  color: customStyle.mainColor,
  "&:buttonDisabled": {
    color: "gray",
  },
} as const;
export const iconBox = {
  position: "relative",
} as const;
export const iconButtonClockColorAbsolute = {
  position: "absolute",
  color: customStyle.mainColor,
  "&:buttonDisabled": {
    color: "gray",
  },
  cursor: "text",
} as const;
export const iconButtonClockColorAbsoluteStart = {
  top: "30%",
  left: "70%",
} as const;
export const iconButtonClockColorAbsoluteEnd = {
  top: "30%",
  left: "70%",
} as const;
export const imageCard = {
  minHeight: "100%",
  width: "400px",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
  "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
};
export const insideImageCard = {
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
};
export const insideImageCardRow = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  justifyItems: "center",
} as const;
export const textArea = {
  height: "100%",
  gridRowStart: "4",
  gridRowEnd: "6",
};
export const buttonSavePosition = {
  gridRowStart: "8",
  gridRowEnd: "9",
};
export const buttonSave = {
  height: "35px",
  fontSize: "12px",
  color: "white",
  backgroundColor: customStyle.mainColor,
  bottom: 0,
};
export const buttonCancel = {
  height: "35px",
  fontSize: "12px",
  color: "red",
  border: "1px solid",
  borderColor: "red",
};
export const error = {
  color: "#f44336",
};
export const errorText = {
  color: "#f44336",
  margin: 0,
  fontSize: "0.75rem",
  marginTop: "3px",
  textAlign: "left",
  fontWeight: "400px",
  lineHeight: 1.66,
} as const;
export const mainColor = {
  color: customStyle.mainColor,
};
export const propGrid = {
  display: "grid",
  gridTemplateColumns: "10% 90%",
  alignItems: "center",
};
export const innerGrid = {
  display: "grid",
  justifyItems: "right",
  marginTop: "auto",
  marginBottom: 0,
  paddingBottom: "20px",
};
export const innerGridLeft = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "200px auto",
  alignItems: "left",
  justifyItems: "left",
};

export const innerGridRight = {
  width: "100%",
  display: "grid",
  gridTemplateColumns: "auto 200px",
  alignItems: "right",
  justifyItems: "right",
};
export const inputRoot = {
  fontWeight: "bold",
  fontFamily: "NunitoMedium",
  "&.MuiInputBase-root-MuiInput-root.Mui-disabled:before": {
    //borderBottom: "none",
    //borderBottomStyle: "none",
  },
  ".MuiInputBase-input": {
    fontFamily: "NunitoMedium",
    fontWeight: "bold",
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  ".MuiInputBase-input.Mui-disabled": {
    fontFamily: "NunitoMedium",
    fontWeight: "bold",
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  "&.MuiInput-root.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
  "&.MuiInput-underline .Mui-disabled:before": {
    borderBottom: "none",
  },
};

export const inputRootRow = {
  textAlign: "center",
  fontWeight: "bold",
  fontFamily: "NunitoMedium",
  maxWidth: "350px",
  "&.MuiInputBase-root-MuiInput-root.Mui-disabled:before": {
    borderBottom: "none",
    borderBottomStyle: "none",
  },
  ".MuiInputBase-input": {
    fontFamily: "NunitoMedium",
    fontWeight: "bold",
    textAlign: "center",
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  ".MuiInputBase-input.Mui-disabled": {
    fontFamily: "NunitoMedium",
    fontWeight: "bold",
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  "&.MuiInput-root:before": {
    borderBottom: "none",
  },
  "&.MuiInput-root.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
  "&.MuiInputBase-root-MuiInput-root:before": {
    borderBottom: "none",
  },
  "&.MuiInput-underline .Mui-disabled:before": {
    borderBottom: "none",
  },
} as const;

export const inputRootPayment = {
  fontFamily: "NunitoMedium",
  "&.MuiInputBase-root-MuiInput-root.Mui-disabled:before": {
    borderBottom: "none",
    borderBottomStyle: "none",
  },
  ".MuiInputBase-input": {
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  ".MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: `${customStyle.mainColor}!important`,
  },
  "&.MuiInput-root:before": {
    borderBottom: "none",
  },
  "&.MuiInput-root.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
} as const;

export const inputBoxRoot = {
  display: "grid",
  gridTemplateColumns: "auto 50px 50px",
  gap: "10px",
};
export const selectLabel = {
  marginLeft: "-15px",
};

export const selectRoot = {
  "& .Mui-disabled": {
    color: `${customStyle.mainColor}!important`,
  },
  "& .MuiSelect-select.Mui-disabled": {
    color: `${customStyle.mainColor}!important`,
  },
};
export const checkBoxRootLabel = {
  fontFamily: "NunitoMedium",
  display: "grid",
  justifySelf: "center",
  alignSelf: "center",
  color: "rgba(0, 0, 0, 0.87)",
  "&.MuiFormLabel-root.Mui-disabled": {
    color: `${customStyle.mainColor}!important`,
  },
};
export const disabledCheckbox = {
  "&.MuiCheckbox-colorPrimary.Mui-disabled": {
    color: `${customStyle.mainColor}!important`,
  },
};
export const rootInsideRender = {
  zIndex: 100,
  display: "grid",
  gap: "12px",
  gridTemplateRows: "auto auto",
};
export const afterBookingPosition = {
  gridRowStart: "5",
  gridRowEnd: "6",
};
export const seasonBeachChairPosition = {
  gridRowStart: "7",
  gridRowEnd: "8",
};
export const datePickersPosition = {
  gridRowStart: "6",
  gridRowEnd: "7",
};
export const datePickers = {
  display: "grid",
  gap: 10,
  gridTemplateColumns: "auto auto",
  gridTemplateRows: "auto",
};
export const iconItemMain = {
  padding: "1px",
  color: customStyle.mainColor,
};
export const iconItemMainLabel = {
  display: "flex",
  flexDirection: "column",
  gap: "1px",
  alignItems: "center",
  justifyItems: "center",
  color: customStyle.mainColor,
  fontSize: "13px",
  fontWeight: "bold",
} as const;
export const iconItemMainLabelBox = {
  display: "grid",
  gap: "1px",
  gridTemplateColumns: "auto auto",
  gridTemplateRows: "auto",
};
export const iconItemMainChecked = {
  padding: "1px",
  color: customStyle.mainColor,
  border: "2px solid",
  borderColor: customStyle.mainColor,
  borderRadius: "50%",
};
export const iconItemMainDisabled = {
  padding: "1px",
  color: customStyle.secondaryColor,
  border: "2px solid",
  borderColor: customStyle.secondaryColor,
  borderRadius: "50%",
};
export const rowContentAttributes = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(170px, 170px));",
  gap: "10px",
};
export const buttonDatePicker = {
  gridColumn: 2,
  gridRow: 1,
  height: 35,
  fontSize: 12,
  color: "white",
  backgroundColor: customStyle.secondaryColor,
} as const;

export const autocomplete = {
  color: customStyle.whiteColor,
  "& .MuiAutocomplete-popper": {
    color: "#233F91!important",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    color: customStyle.whiteColor,
    borderColor: customStyle.whiteColor,
  },
  "&:hover $MuiOutlinedInput-notchedOutline": {
    borderColor: customStyle.whiteColor,
  },
  "& label.Mui-focused": {
    color: customStyle.whiteColor,
  },
  "& .MuiInput-underline:after": {
    borderColor: customStyle.whiteColor,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: customStyle.whiteColor,
    },
    "&:hover fieldset": {
      borderColor: customStyle.whiteColor,
    },
    "&.Mui-focused fieldset": {
      borderColor: customStyle.whiteColor,
    },
  },
  "& .MuiAutocomplete-clearIndicator": {
    color: customStyle.whiteColor,
  },
  "& .MuiAutocomplete-popupIndicator": {
    color: customStyle.whiteColor,
  },
} as const;

export const autocompleteInput = {
  color: customStyle.whiteColor,
  fontSize: "14px",
  fontWeight: "bold",
} as const;

export const buttonGroup = {
  alignSelf: "end",
  justifySelf: "end",
  height: 35,
  fontSize: 12,
  color: "white",
  backgroundColor: `${customStyle.mainColor} !important`,
  gridColumn: 2,
  gridRow: 2,
};

export const buttonGroupBooking = {
  height: 35,
  fontSize: 12,
  color: "white",
  backgroundColor: `${customStyle.mainColor} !important`,
};

export const buttonColor = {
  backgroundColor: `${customStyle.mainColor} !important`,
  fontSize: "12px",
};

export const buttonSplitReport = {
  height: 35,
  fontSize: 12,
  color: "white",
  backgroundColor: `${customStyle.mainColor} !important`,
};

export const tableHeader = {
  fontWeight: "bold",
};

export const privacyRoot = {
  padding: "20px",
  maxHeight: "calc(100vh - 90px - 90px);",
  overflow: "auto",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;

export const weekDay = {
  color: customStyle.mainColor,
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "center",
} as const;

export const boxBeachChairCalendar = {
  justifyContent: "center",
  display: "grid",
  gap: "10px",
  gridTemplateColumns: "repeat(7, 80px);",
  gridTemplateRows: "30px repeat(auto-fit, minmax(90px, 90px))",
  minHeight: `calc(100vh - 260px)`,
  maxHeight: `calc(100vh - 260px)`,
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: customStyle.scrollColor,
  },
} as const;
