import React, { useCallback, useState } from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  componentRoot,
  contentBoxShortUrl,
  insideCardShortUrl,
  errorText,
  inputRoot,
  selectLabel,
  insideCardPaddingTop,
} from "../../utils/customStyles/globalStyles";
import DeleteDialog from "../dialog/DeleteDialog";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Controller } from "react-hook-form";
import ShortUrlsHeader from "./header/shortUrlsHeader";
import { useShortUrlForm } from "../../utils/customHooks/useShortUrlForm";
import { deleteShortUrlRequest } from "../../store/reducers/shortUrlsReducer";
import SBButtonActionGrid from "../SBComponents/SBForms/SBButtonActionGrid";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import SBSelect from "../SBComponents/SBForms/SBSelect";
import {
  CalendarToday,
  Countertops,
  Http,
  People,
  PermIdentity,
  Tag,
} from "@mui/icons-material";
import SBTextField from "../SBComponents/SBForms/SBTextField";
import SBTextAreaDatePicker from "../SBComponents/SBForms/SBTextAreaDatePicker";

const ShortUrl = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const { vendorsSelector, control, errors, onSubmit, onReset } =
    useShortUrlForm(id, type);
  const handleDeleteAfterModalConfirm = useCallback(
    (uid: string) => {
      dispatch(deleteShortUrlRequest({ uid }));
    },
    [dispatch]
  );

  const handleOnDeleteClick = React.useCallback(() => {
    setDialog(true);
  }, [setDialog]);

  return (
    <SBMainComponentGridWrapper>
      <ShortUrlsHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="vendorId"
              label={t("destinations.vendorId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={false}
              additionalOptions={vendorsSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.vendorId && t((errors.vendorId as any)?.message)}
            />
            <SBTextField
              name="uid"
              label={t("shortUrls.uid")}
              control={control}
              type={type}
              icon={<PermIdentity />}
              isLoading={false}
              error={errors?.uid && t((errors.uid as any)?.message)}
            />
            <SBTextAreaDatePicker
              name="updatedAt"
              label={t("shortUrls.updated_at")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={false}
              error={errors?.updatedAt && t((errors.updatedAt as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="tagId"
              label={t("shortUrls.tag_id")}
              control={control}
              type={type}
              icon={<Tag />}
              isLoading={false}
              error={errors?.tagId && t((errors.tagId as any)?.message)}
            />
            <SBTextField
              name="url"
              label={t("shortUrls.url")}
              control={control}
              type={type}
              icon={<Http />}
              isLoading={false}
              error={errors?.url && t((errors.url as any)?.message)}
            />
            <SBTextField
              name="counter"
              label={t("shortUrls.counter")}
              control={control}
              type={type}
              icon={<Countertops />}
              isLoading={false}
              error={errors?.counter && t((errors.counter as any)?.message)}
            />
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/shortUrls/${+id}/edit`)}
          deleteOnClick={() => {
            handleOnDeleteClick();
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/shortUrls/${+id}/show`)}
          type={type}
          isLoading={false}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="shortUrl"
          open={dialog}
          selectedValue={id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(`${id}`);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default ShortUrl;
