import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  PaymentsResponseDto as Response,
  PaymentsApiPaymentsControllerCreateStripeCheckoutSessionRequest as Payload,
} from "../../../../api-client/generated";
import { paymentApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";

interface UseCreateStripeCheckoutSession {
  createStripeCheckoutSession: UseMutateFunction<
    AxiosResponse<Response>,
    AxiosError,
    Payload
  >;
  isLoading: boolean;
}

export const useCreateStripeCheckoutSession = (): UseCreateStripeCheckoutSession => {
  const queryClient = useQueryClient();
  const { clearBookingInProgress } = useBookingInProgressStore();
  const { mutate: createStripeCheckoutSession, isLoading } = useMutation<
    AxiosResponse<Response>,
    AxiosError,
    Payload
  >({
    mutationFn: (variables: Payload) =>
      paymentApi.paymentsControllerCreateStripeCheckoutSession(variables),
    onSuccess: () => {
      clearBookingInProgress();

      queryClient.refetchQueries(["bookingList"]);
    },
  });

  return {
    createStripeCheckoutSession,
    isLoading,
  };
};
