import { Close } from "@mui/icons-material";
import { Divider, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";

export const SelectedGlobalBooking: React.FC = () => {
  const {
    bookingInProgress,
    clearBookingInProgress,
  } = useBookingInProgressStore();
  const globalBookingId = React.useMemo(() => bookingInProgress?.id, [
    bookingInProgress,
  ]);
  const { t } = useTranslation(["common"]);

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="h5" color="primary">
          {t("booking")}: {globalBookingId}
        </Typography>

        <IconButton
          aria-label="Unset global booking"
          onClick={() => {
            clearBookingInProgress();
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <Divider light />
    </Stack>
  );
};
