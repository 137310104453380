import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { UsersApiUsersControllerDeleteRequest as Variables } from "../../../api-client/generated";
import { usersApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useDeleteVendorUser = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation<AxiosResponse<void>, AxiosError, Variables>(
    ["deleteVendorUser"],
    ({ id }: Variables) => usersApi.usersControllerDelete({ id: `${id}` }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.deleteUserSuccess`));
        queryClient.refetchQueries(["vendorUsers"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.deleteUserFailure`));
      },
    }
  );
};
