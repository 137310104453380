import { getCustomerSagaSelector } from "../selectors/customersSelectors";
import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest, select, debounce } from "typed-redux-saga";
import {
  addBeachChairToBooking,
  deleteBookingBeachChair,
  getBooking,
  getBookings,
  addExtrasToBooking,
  cancelBooking,
  changeBeachChairBooking,
  checkoutSessionEmail,
  createNewBooking,
  deleteBooking,
  deleteBookingExtras,
  getCSVBookings,
  patchBooking,
  postBookingCustomer,
  putBookingBeachChair,
  putBookingConfirm,
  putBookingExtrasAmount,
  putBookingPay,
  putBookingReserve,
  removeBeachChairBooking,
} from "../../api/bookingApi";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import {
  addBeachChairToBookingFailure,
  addBeachChairToBookingRequest,
  addBeachChairToBookingSuccess,
  addExtrasToBookingFailure,
  addExtrasToBookingRequest,
  addExtrasToBookingSuccess,
  cancelBookingFailure,
  cancelBookingRequest,
  cancelBookingSuccess,
  changeBeachChairsFailure,
  changeBeachChairsRequest,
  changeBeachChairsSuccess,
  changeBookingExtrasAmountFailure,
  changeBookingExtrasAmountRequest,
  changeBookingExtrasAmountSuccess,
  changeInstantBookingTabRequest,
  createInstantBookingFailure,
  createInstantBookingRequest,
  createInstantBookingSuccess,
  createNewBookingFailure,
  createNewBookingRequest,
  createNewBookingSuccess,
  deleteBookingBeachChairFailure,
  deleteBookingBeachChairRequest,
  deleteBookingBeachChairSuccess,
  deleteBookingExtrasFailure,
  deleteBookingExtrasRequest,
  deleteBookingExtrasSuccess,
  deleteBookingFailure,
  deleteBookingRequest,
  deleteBookingSuccess,
  fetchBookingFailure,
  fetchBookingRequest,
  fetchBookingsFailure,
  fetchBookingsRequest,
  fetchBookingsSuccess,
  fetchBookingSuccess,
  filterBookingsFailure,
  filterBookingsRequest,
  filterBookingsSuccess,
  getCSVBookingsRequest,
  getCSVBookingsSuccess,
  patchBookingRequest,
  patchBookingSuccess,
  postNewBookingFailure,
  postNewBookingRequest,
  postNewBookingSuccess,
  postNewExtrasBookingRequest,
  pushBookingCustomerFailure,
  pushBookingCustomerRequest,
  pushBookingCustomerSuccess,
  pushBookingFailure,
  pushBookingRequest,
  putBookingBeachChairFailure,
  putBookingBeachChairRequest,
  putBookingBeachChairSuccess,
  putBookingConfirmFailure,
  putBookingConfirmRequest,
  putBookingConfirmSuccess,
  putBookingPayRequest,
  putBookingPaySuccess,
  putBookingReserveFailure,
  putBookingReserveRequest,
  putBookingReserveSuccess,
  removeBeachChairBookingRequest,
  removeBeachChairBookingSuccess,
  removeBookingBeachChairRightDrawerToggleRequest,
  routerRedirectBookings,
  sendPaymentLinkRequest,
  sendPaymentLinkSuccess,
  setBookingIdRequest,
  updateCommentFailure,
  updateCommentRequest,
  updateCommentSuccess,
  updateCustomerCommentRequest,
  updateCustomerCommentSuccess,
} from "../reducers/bookingsReducer";
import history from "../../utils/history/history";
import { postCustomer, putCustomer } from "../../api/customerApi";
import {
  clearGrid,
  routeAvailabilitiesBeachChair,
} from "../reducers/availabilityReducer";
import {
  clearGlobalBookingId,
  setGlobalBookingId,
} from "../reducers/globalBookingReducer";
import { toast } from "react-toastify";
import { ICustomer } from "../../models/customers/ICustomer";
import { TimeZone, formatInUtc } from "../../utils/conversions/dataConversion";
import {
  getBookingByIdSelector,
  getExtrasByIdSelector,
  getFilterDataSelector,
} from "../selectors/bookingsSelectors";
import { IBooking } from "../../models/bookings/IBooking";
import { getBeachChair } from "../../api/beachChairApi";
import { getRates } from "../../api/priceApi";
import { IPriceCalculate } from "../../models/prices/IPriceCalculate";
import { EInstantBooking } from "../../components/bookingsList/instantBooking/instantBookingCard/instantBookingCard";
import { format, getDay } from "date-fns";
import {
  getAvailabilitiesEndDate,
  getAvailabilitiesEndTime,
  getAvailabilitiesStartDate,
  getAvailabilitiesStartTime,
} from "../selectors/availabilitySelector";
import { getSection } from "../../api/destinationApi";
import { getLocation } from "../../api/vendorApi";
import { pushCustomerSuccess } from "../reducers/customersReducer";
import { useBookingInProgressStore } from "../../components/bookingsList/store/useBookingZustand";
import i18next from "i18next";
import { useInstantBookingCurrentDatesStore } from "../../components/bookingsList/instantBooking/hooks/useAvailabilitiesDatesStore";
import {
  createDateFromHour,
  selectHour,
} from "../../utils/conversions/locationsTimes";
import { queryClient } from "../..";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

function* fetchBookingsSaga(action: Action) {
  try {
    if (fetchBookingsRequest.match(action)) {
      const {
        page,
        itemsPerPage,
        filterData,
        order,
        field,
        beachChairId,
        customerId,
      } = action.payload;
      let filterString: string = filterDataToString(filterData);
      if (beachChairId) {
        let newFilter = [...filterData];
        newFilter.push({
          name: "beachChairId",
          value: beachChairId,
        });
        filterString = filterDataToString(newFilter);
      }
      if (customerId) {
        let newFilter = [...filterData];
        newFilter.push({
          name: "customerId",
          value: customerId,
        });
        filterString = filterDataToString(newFilter);
      }
      const { data } = yield* call(
        getBookings,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield put(
        fetchBookingsSuccess({
          bookings: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
          beachChairId,
          customerId,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchBookingsFailure({
        error: e.message,
      })
    );
  }
}

function* fetchBookingSaga(action: Action) {
  try {
    if (fetchBookingRequest.match(action)) {
      const { bookingId } = action.payload;
      const { data } = yield* call(getBooking, bookingId);
      yield put(
        fetchBookingSuccess({
          booking: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchBookingFailure({
        error: e.message,
      })
    );
  }
}

function* filterCancelSaga() {
  try {
    yield put(filterCancelBookingsSuccess());
  } catch (e: any) {
    yield* put(
      filterBookingsFailure({
        error: e.message,
      })
    );
  }
}

function* filterBookingsSaga(action: Action) {
  try {
    if (filterBookingsRequest.match(action)) {
      const { filterData, itemsPerPage, page } = action.payload;
      const filterString: string = filterDataToString(filterData);
      if (filterString.length > 0) {
        const { data } = yield* call(getBookings, itemsPerPage, page);
        yield put(
          filterBookingsSuccess({
            bookings: data.items,
            count: data.count,
            itemsPerPage: itemsPerPage,
            page: page,
            filterPhrase: filterString,
          })
        );
      } else {
        yield* call(filterCancelSaga);
      }
    }
  } catch (e: any) {
    yield* put(
      filterBookingsFailure({
        error: e.message,
      })
    );
  }
}

function* pushBookingSaga(action: Action) {
  try {
    if (pushBookingRequest.match(action)) {
    }
    //const { formData } = action.payload;
    /**TODO CALL API */
    /**PRE API CONVERSION */
    //yield put(pushBookingSuccess({ booking: newBooking }));
  } catch (e: any) {
    yield* put(
      pushBookingFailure({
        error: e.message,
      })
    );
  }
}

function* pushBookingCustomerSaga(action: Action) {
  try {
    if (pushBookingCustomerRequest.match(action)) {
      let customer: ICustomer;

      const { bookingId, formData, isExistingCustomer, existingCustomerId } =
        action.payload;

      if (!isExistingCustomer) {
        const newCustomer: Omit<ICustomer, "id" | "vendorId" | "userId"> = {
          firstName: formData?.firstName || "",
          lastName: formData?.lastName || "",
          salutation: formData?.salutation || "",
          address: {
            street: formData.address?.street || "",
            zip: formData.address?.zip || "",
            city: formData.address?.city || "",
            country: formData.address?.country || "",
            additionalInfo: formData.address?.additionalInfo || "",
          },
          telephone: formData?.telephone || "",
          email: formData.email,
          bankDetails: {
            iban: formData?.bankDetails?.iban || "",
            bic: formData?.bankDetails?.bic || "",
            bankName: formData?.bankDetails?.bankName || "",
            accountHolder: formData?.bankDetails?.accountHolder || "",
          },
          default: false,
        };

        const { data } = yield* call(postCustomer, newCustomer);
        customer = data;
        yield* call(postBookingCustomer, bookingId, { id: data.id });
        const bookingData = yield* call(getBooking, bookingId);
        yield* put(
          pushBookingCustomerSuccess({
            bookingId,
            bookingData: bookingData.data,
            customer: customer,
          })
        );
        toast.success(
          `${i18next.t(`toastMessages.BookingsCustomersChangedSuccess`)}`
        );
      } else {
        const existingCustomer = yield* select(
          getCustomerSagaSelector(existingCustomerId)
        );
        customer = existingCustomer!;
        const { data } = yield* call(
          putCustomer,
          +existingCustomerId,
          formData
        );
        yield put(pushCustomerSuccess({ customer: data }));
        toast.success(`${i18next.t(`toastMessages.PutCustomerSuccess`)}`);
        yield* call(postBookingCustomer, bookingId, { id: existingCustomerId });
        const bookingData = yield* call(getBooking, bookingId);
        yield* put(
          pushBookingCustomerSuccess({
            bookingId,
            bookingData: bookingData.data,
            customer: customer,
          })
        );
        toast.success(
          `${i18next.t(
            `toastMessages.BookingsExistingCustomersChangedSuccess`
          )}`
        );
      }
    }
  } catch (e: any) {
    yield* put(
      pushBookingCustomerFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.BookingsCustomerChangedFailure`)}`);
  }
}

function* deleteBookingBeachChairSaga(action: Action) {
  try {
    if (deleteBookingBeachChairRequest.match(action)) {
      const { bookingId, beachChairId } = action.payload;
      yield* call(deleteBookingBeachChair, bookingId, beachChairId);
      const { data } = yield* call(getBooking, bookingId);
      yield* put(
        deleteBookingBeachChairSuccess({ bookingId, beachChairId, data })
      );
    }
  } catch (e: any) {
    yield* put(
      deleteBookingBeachChairFailure({
        error: e.message,
      })
    );
  }
}

function* deleteBookingExtrasSaga(action: Action) {
  try {
    if (deleteBookingExtrasRequest.match(action)) {
      const { bookingId, bookingExtrasId } = action.payload;
      yield* call(deleteBookingExtras, bookingId, bookingExtrasId);
      yield* put(deleteBookingExtrasSuccess({ bookingId, bookingExtrasId }));
    }
  } catch (e: any) {
    yield* put(
      deleteBookingExtrasFailure({
        error: e.message,
      })
    );
  }
}

function* addBeachChairToBookingSaga(action: Action) {
  try {
    if (addBeachChairToBookingRequest.match(action)) {
      const { bookingId, formData, isContinue } = action.payload;
      const { id, price } = formData;
      const startDate = yield* select(getAvailabilitiesStartDate);
      const endDate = yield* select(getAvailabilitiesEndDate);
      const startTime = yield* select(getAvailabilitiesStartTime);
      const endTime = yield* select(getAvailabilitiesEndTime);
      const start = formatInUtc(
        new Date(
          `${format(new Date(startDate), `yyyy-MM-dd`)}T${format(
            new Date(startTime),
            `HH:mm`
          )}:00`
        ),
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      );
      const end = formatInUtc(
        new Date(
          `${format(new Date(endDate), `yyyy-MM-dd`)}T${format(
            new Date(endTime),
            `HH:mm`
          )}:00`
        ),
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      );
      const booking = yield* call(addBeachChairToBooking, bookingId, {
        beachChairId: id,
        start,
        end,
        price,
        discount: formData.discount,
      });
      yield* put(clearGrid({}));
      yield* put(
        addBeachChairToBookingSuccess({
          bookingId: bookingId,
          bookingData: booking.data,
          beachChairId: id,
        })
      );
      toast.success(
        `${i18next.t(`toastMessages.AddBeachChairToBookingSuccess`)}`
      );
      if (!isContinue) {
        yield* put(routeAvailabilitiesBeachChair({ bookingId: bookingId }));
      }
      yield* put(setGlobalBookingId({ id: bookingId }));
    }
  } catch (e: any) {
    yield* put(
      addBeachChairToBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.${e.message}`)}`);
  }
}

function* addExtrasToBookingSaga(action: Action) {
  try {
    if (addExtrasToBookingRequest.match(action)) {
      const { bookingId, formData, isContinue } = action.payload;
      const { id, amount } = formData;
      yield* call(addExtrasToBooking, bookingId, {
        extraId: id,
        amount,
      });
      yield* put(
        addExtrasToBookingSuccess({
          bookingId: bookingId,
          extrasId: id,
        })
      );
      toast.success(`${i18next.t(`toastMessages.AddExtrasToBookingSuccess`)}`);
      if (!isContinue) {
        yield* put(routeAvailabilitiesBeachChair({ bookingId: bookingId }));
      }
      yield* put(setGlobalBookingId({ id: bookingId }));
    }
  } catch (e: any) {
    yield* put(
      addExtrasToBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.${e.message}`)}`);
  }
}

function* putBookingBeachChairSaga(action: Action) {
  try {
    if (putBookingBeachChairRequest.match(action)) {
      const { beachChairId, formData } = action.payload;
      const { price, fromDate, fromTime, toDate, toTime } = formData;
      const start = formatInUtc(
        new Date(`${fromDate}T${fromTime}:00`),
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      );
      const end = formatInUtc(
        new Date(`${toDate}T${toTime}:00`),
        "yyyy-MM-dd'T'HH:mm:ss'Z'"
      );
      const { data } = yield* call(putBookingBeachChair, beachChairId, {
        price,
        start,
        end,
      });
      yield* put(putBookingBeachChairSuccess({ booking: data }));
    }
  } catch (e: any) {
    yield* put(
      putBookingBeachChairFailure({
        error: e.message,
      })
    );
  }
}

function* createNewBookingSaga(action: Action) {
  try {
    if (createNewBookingRequest.match(action)) {
      const { data } = yield* call(createNewBooking);
      yield* put(createNewBookingSuccess({ booking: data }));
    }
  } catch (e: any) {
    yield* put(
      createNewBookingFailure({
        error: e.message,
      })
    );
  }
}

function* patchBookingSaga(action: Action) {
  try {
    if (patchBookingRequest.match(action)) {
      const { bookingId, paymentMethod } = action.payload;
      const { data } = yield* call(patchBooking, bookingId, {
        paymentMethod: paymentMethod,
      });
      /* to be changed if BACKEND returns whole BOOKING object */
      var booking = yield* select(getBookingByIdSelector(bookingId));
      const bookingTab = { ...booking } as IBooking;
      bookingTab.paymentMethod = paymentMethod;
      bookingTab.status = (data as IBooking).status;
      /*end of to be changed */
      yield* put(patchBookingSuccess({ booking: bookingTab }));
      toast.success(
        `${i18next.t(`toastMessages.ChangeBookingPaymentSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* put(
      addBeachChairToBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.ChangeBookingPaymentFailure`)}`);
  }
}

function* putBookingConfirmSaga(action: Action) {
  try {
    if (putBookingConfirmRequest.match(action)) {
      const { bookingId } = action.payload;
      const { data } = yield* call(putBookingConfirm, bookingId);
      yield* put(putBookingConfirmSuccess({ booking: data }));
      yield* put(clearGrid({}));
      toast.success(`${i18next.t(`toastMessages.ChangeBookingStatusSuccess`)}`);
      yield* put(setGlobalBookingId({ id: null }));
      yield* put(
        routerRedirectBookings({
          path: "/bookings",
          id: bookingId,
          type: "show",
        })
      );
    }
  } catch (e: any) {
    yield* put(
      putBookingConfirmFailure({
        error: e.message,
      })
    );
    yield* put(clearGrid({}));
    toast.error(`${i18next.t(`toastMessages.ChangeBookingStatusFailure`)}`);
  }
}

function* putBookingReserveSaga(action: Action) {
  try {
    if (putBookingReserveRequest.match(action)) {
      const { bookingId } = action.payload;
      const { data } = yield* call(putBookingReserve, bookingId);
      yield* put(putBookingReserveSuccess({ booking: data }));
      yield* put(clearGrid({}));
      toast.success(`${i18next.t(`toastMessages.ChangeBookingStatusSuccess`)}`);
      yield* put(setGlobalBookingId({ id: null }));
      yield* put(
        routerRedirectBookings({
          path: "/bookings",
          id: bookingId,
          type: "show",
        })
      );
    }
  } catch (e: any) {
    yield* put(
      putBookingReserveFailure({
        error: e.message,
      })
    );
    yield* put(clearGrid({}));
    toast.error(`${i18next.t(`toastMessages.ChangeBookingStatusFailure`)}`);
  }
}

function* sendPaymentLinkSaga(action: Action) {
  try {
    if (sendPaymentLinkRequest.match(action)) {
      const { bookingId, bookingRef, email } = action.payload;
      yield* call(checkoutSessionEmail, bookingRef, email);
      yield* put(sendPaymentLinkSuccess({}));
      yield* put(fetchBookingRequest({ bookingId: bookingId }));
      yield* put(clearGlobalBookingId());
      toast.success(`${i18next.t(`toastMessages.SendPaymentLinkSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      putBookingReserveFailure({
        error: e.message,
      })
    );
    yield* put(clearGrid({}));
    toast.error(`${i18next.t(`toastMessages.SendPaymentLinkFailure`)}`);
  }
}

function* putBookingPaySaga(action: Action) {
  try {
    if (putBookingPayRequest.match(action)) {
      const { bookingId } = action.payload;
      const { data } = yield* call(putBookingPay, bookingId);
      yield* put(putBookingPaySuccess({ booking: data }));
      yield* put(clearGrid({}));
      toast.success(`${i18next.t(`toastMessages.ChangeBookingStatusSuccess`)}`);
      yield* put(setGlobalBookingId({ id: null }));
      yield* put(
        routerRedirectBookings({
          path: "/bookings",
          id: bookingId,
          type: "show",
        })
      );
    }
  } catch (e: any) {
    yield* put(
      putBookingConfirmFailure({
        error: e.message,
      })
    );
    yield* put(clearGrid({}));
    toast.error(`${i18next.t(`toastMessages.ChangeBookingStatusFailure`)}`);
  }
}

function* setBookingIdSaga(action: Action) {
  if (setBookingIdRequest.match(action)) {
    const { bookingId, isExtras } = action.payload;
    yield* put(setGlobalBookingId({ id: bookingId }));
    yield* put(
      routerRedirectBookings({
        path: isExtras ? "/extras" : "/availabilities/box",
      })
    );
  }
}

export interface INewBooking {
  vendorId: number;
}

function* postNewBookingSaga(action: Action) {
  try {
    if (postNewBookingRequest.match(action)) {
      const { formData, isContinue } = action.payload;
      const { data } = yield* call(createNewBooking);
      yield* put(
        postNewBookingSuccess({
          booking: data,
        })
      );
      yield* put(
        addBeachChairToBookingRequest({
          bookingId: data.id,
          formData: formData,
          isContinue: isContinue,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      postNewBookingFailure({
        error: e.message,
      })
    );
  }
}

function* postNewExtrasBookingSaga(action: Action) {
  try {
    if (postNewExtrasBookingRequest.match(action)) {
      const { formData, isContinue } = action.payload;
      const { data } = yield* call(createNewBooking);
      yield* put(
        postNewBookingSuccess({
          booking: data,
        })
      );
      yield* put(
        addExtrasToBookingRequest({
          bookingId: data.id,
          formData: formData,
          isContinue: isContinue,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      postNewBookingFailure({
        error: e.message,
      })
    );
  }
}

function* deleteBookingSaga(action: Action) {
  try {
    if (deleteBookingRequest.match(action)) {
      const { bookingId } = action.payload;
      yield* call(deleteBooking, bookingId);
      yield* put(
        deleteBookingSuccess({
          bookingId: bookingId,
        })
      );
      const { bookingInProgress } = useBookingInProgressStore.getState();
      const globalBookingId = bookingInProgress?.id;
      if (globalBookingId && globalBookingId === bookingId) {
        yield* put(clearGlobalBookingId());
      }
      toast.success(`${i18next.t(`toastMessages.DeleteBookingSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      deleteBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteBookingFailure`)}`);
  }
}

function* routerRedirectBookingsSaga(action: Action) {
  if (routerRedirectBookings.match(action)) {
    const { path, id, type } = action.payload;
    yield* call(
      [history, history.push],
      `${path ? path : `/bookings`}${id ? `/${id}` : ``}${
        type ? `/${type}` : ``
      }`
    );
  }
}

function* changeBookingExtrasAmountSaga(action: Action) {
  try {
    if (changeBookingExtrasAmountRequest.match(action)) {
      const { isAddAction, bookingId, extrasBookingId } = action.payload;
      const extra = yield* select(
        getExtrasByIdSelector(bookingId, extrasBookingId)
      );
      const newAmount = extra!.amount + (isAddAction ? 1 : -1);
      const { data } = yield* call(
        putBookingExtrasAmount,
        bookingId,
        extrasBookingId,
        newAmount
      );
      yield* put(
        changeBookingExtrasAmountSuccess({
          booking: data,
        })
      );
      toast.success(
        `${i18next.t(`toastMessages.ChangeBookingExtrasAmountSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* put(
      changeBookingExtrasAmountFailure({
        error: e.message,
      })
    );
    toast.error(
      `${i18next.t(`toastMessages.ChangeBookingExtrasAmountFailure`)}`
    );
  }
}

function* changeBeachChairsSaga(action: Action) {
  try {
    if (changeBeachChairsRequest.match(action)) {
      const { previousBeachChairId, beachChairId, price } = action.payload;
      const { data } = yield* call(
        changeBeachChairBooking,
        previousBeachChairId,
        beachChairId,
        price
      );
      yield* put(
        changeBeachChairsSuccess({
          booking: data,
        })
      );
      toast.success(
        `${i18next.t(`toastMessages.ChangeBookingBeachChairsSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* put(
      changeBeachChairsFailure({
        error: e.message,
      })
    );
    toast.error(
      `${i18next.t(`toastMessages.ChangeBookingBeachChairsFailure`)}`
    );
  }
}

function* cancelBookingSaga(action: Action) {
  try {
    if (cancelBookingRequest.match(action)) {
      const { bookingId, skipRefund, cancellationFee } = action.payload;
      const { data } = yield* call(cancelBooking, {
        bookingId,
        skipRefund,
        cancellationFee,
      });

      yield* put(
        cancelBookingSuccess({
          booking: data,
        })
      );
      toast.success(`${i18next.t(`toastMessages.CancelBookingSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      cancelBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.CancelBookingFailure`)}`);
  }
}

function* updateCommentSaga(action: Action) {
  try {
    if (updateCommentRequest.match(action)) {
      const { bookingId, comment } = action.payload;
      yield* call(patchBooking, bookingId, {
        comment: comment,
      });
      /* to be changed if BACKEND returns whole BOOKING object */
      var booking = yield* select(getBookingByIdSelector(bookingId));
      const bookingTab = { ...booking } as IBooking;
      bookingTab.comment = comment;
      /*end of to be changed */
      yield* put(updateCommentSuccess({ booking: bookingTab }));
    }
  } catch (e: any) {
    yield* put(
      updateCommentFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.ChangeBookingCommentFailure`)}`);
  }
}

function* updateCustomerCommentSaga(action: Action) {
  try {
    if (updateCustomerCommentRequest.match(action)) {
      const { bookingId, comment } = action.payload;
      yield* call(patchBooking, bookingId, {
        customerComment: comment,
      });
      /* to be changed if BACKEND returns whole BOOKING object */
      var booking = yield* select(getBookingByIdSelector(bookingId));
      const bookingTab = { ...booking } as IBooking;
      bookingTab.customerComment = comment;
      /*end of to be changed */
      yield* put(updateCustomerCommentSuccess({ booking: bookingTab }));
    }
  } catch (e: any) {
    yield* put(
      updateCommentFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.ChangeBookingCommentFailure`)}`);
  }
}

function* createInstantBookingSaga(action: Action) {
  let bookingId: number = 0;
  try {
    if (createInstantBookingRequest.match(action)) {
      const { type, formValues } = action.payload;
      const { instantBookingCurrentDate } =
        useInstantBookingCurrentDatesStore.getState();
      const instantBookingCurrentDateUtc = instantBookingCurrentDate
        .tz(TimeZone)
        .utc()
        .format();
      const date = instantBookingCurrentDate.utc().tz(TimeZone).format();
      const dateObj = instantBookingCurrentDate.utc().tz(TimeZone).toDate();
      const startDate = instantBookingCurrentDate
        .tz(TimeZone)
        .startOf("day")
        .utc()
        .format();
      const endDate = instantBookingCurrentDate
        .tz(TimeZone)
        .endOf("day")
        .utc()
        .format();

      const { data } = yield* call(createNewBooking);
      bookingId = data.id;
      const paymentMethod = formValues.cashPayment ? "CASH" : "BANK_TRANSFER";

      yield* call(patchBooking, data.id, {
        paymentMethod,
      });

      switch (type) {
        case EInstantBooking.beachChairBooking: {
          const beachChair = yield* call(getBeachChair, formValues.id);
          const beachChairData: any = beachChair.data;
          const sectionData = yield* call(getSection, beachChairData.sectionId);
          const locationData = yield* call(
            getLocation,
            sectionData.data.locationId
          );

          const startTimeWeekDay = getDay(new Date(date));
          const endTimeWeekDay = getDay(new Date(date));
          const locationStartTime =
            locationData?.data?.details?.openingHours &&
            selectHour(
              locationData.data.details.openingHours,
              startTimeWeekDay,
              true
            );
          const locationEndTime =
            locationData?.data?.details?.openingHours &&
            selectHour(
              locationData.data.details.openingHours,
              endTimeWeekDay,
              false
            );
          const priceCalculate: IPriceCalculate = {
            sectionId: beachChairData.sectionId,
            model: beachChairData.model,
            rowId: beachChairData.rowId,
            start: instantBookingCurrentDateUtc,
            end: instantBookingCurrentDateUtc,
          };
          const fetchedPrice = yield* call(getRates, priceCalculate);
          const price = fetchedPrice.data.find(
            ({ type }: { id: number; type: string }) => type === "FULLDAY"
          );
          const locationStartTimeArray = locationStartTime?.split(":");
          const locationEndTimeArray = locationEndTime?.split(":");
          const startBeachChairToBooking = locationStartTimeArray
            ? instantBookingCurrentDate
                .set("hours", +locationStartTimeArray[0])
                .set("minutes", +locationStartTimeArray[1])
                .tz(TimeZone)
                .utc()
                .format()
            : startDate;
          const endBeachChairToBooking = locationEndTimeArray
            ? instantBookingCurrentDate
                .set("hours", +locationEndTimeArray[0])
                .set("minutes", +locationEndTimeArray[1])
                .tz(TimeZone)
                .utc()
                .format()
            : endDate;
          yield* call(addBeachChairToBooking, data.id, {
            beachChairId: formValues.id,
            start: startBeachChairToBooking,
            end: endBeachChairToBooking,
            price: price.price,
          });

          break;
        }
        case EInstantBooking.extraBooking: {
          yield* call(addExtrasToBooking, bookingId, {
            extraId: formValues.id,
            amount: formValues.amount,
          });
          break;
        }
        case EInstantBooking.beachChairAndExtraBooking: {
          const beachChair = yield* call(getBeachChair, formValues.id);
          const beachChairData: any = beachChair.data;
          const sectionData = yield* call(getSection, beachChairData.sectionId);
          const locationData = yield* call(
            getLocation,
            sectionData.data.locationId
          );

          const startTimeWeekDay = getDay(new Date(date));
          const endTimeWeekDay = getDay(new Date(date));
          const locationStartTime =
            locationData?.data?.details?.openingHours &&
            selectHour(
              locationData.data.details.openingHours,
              startTimeWeekDay,
              true
            );
          const locationEndTime =
            locationData?.data?.details?.openingHours &&
            selectHour(
              locationData.data.details.openingHours,
              endTimeWeekDay,
              false
            );

          const priceCalculate: IPriceCalculate = {
            sectionId: beachChairData.sectionId,
            model: beachChairData.model,
            rowId: beachChairData.rowId,
            start: instantBookingCurrentDateUtc,
            end: instantBookingCurrentDateUtc,
          };
          const fetchedPrice = yield* call(getRates, priceCalculate);
          const price = fetchedPrice.data.find(
            ({ type }: { id: number; type: string }) => type === "FULLDAY"
          );
          const locationStartTimeArray = locationStartTime?.split(":");
          const locationEndTimeArray = locationEndTime?.split(":");
          const startBeachChairToBooking = locationStartTimeArray
            ? instantBookingCurrentDate
                .set("hours", +locationStartTimeArray[0])
                .set("minutes", +locationStartTimeArray[1])
                .tz(TimeZone)
                .utc()
                .format()
            : startDate;
          const endBeachChairToBooking = locationEndTimeArray
            ? instantBookingCurrentDate
                .set("hours", +locationEndTimeArray[0])
                .set("minutes", +locationEndTimeArray[1])
                .tz(TimeZone)
                .utc()
                .format()
            : endDate;
          yield* call(addBeachChairToBooking, data.id, {
            beachChairId: formValues.id,
            start: startBeachChairToBooking,
            end: endBeachChairToBooking,
            price: price.price,
          });
          yield* call(addExtrasToBooking, bookingId, {
            extraId: formValues.secondId,
            amount: formValues.amount,
          });
          break;
        }
      }
      if (paymentMethod === "CASH") {
        const bookingPay = yield* call(putBookingPay, data.id);
        yield* put(createInstantBookingSuccess({ booking: bookingPay.data }));
        yield* put(changeInstantBookingTabRequest({ value: 4 }));
      }
      yield* put(routerRedirectBookings({ id: bookingId, type: "show" }));
      queryClient.refetchQueries(["useAvailabilitiesBeachChairsSections"]);
      queryClient.refetchQueries(["useAvailabilitiesBeachChairsAllSections"]);
      toast.success(
        `${i18next.t(`toastMessages.CreateInstantBookingSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* call(deleteBooking, bookingId);
    yield* put(createInstantBookingFailure({ error: e.message }));
    toast.error(`${i18next.t(`toastMessages.CreateInstantBookingFailure`)}`);
  }
}

function* getCSVBookingsSaga(action: Action) {
  try {
    if (getCSVBookingsRequest.match(action)) {
      const { page, csv } = action.payload;
      const filterData = yield* select(getFilterDataSelector);
      let filterString: string = filterDataToString(filterData);
      const { data } = yield* call(getCSVBookings, page, filterString, csv);
      yield put(getCSVBookingsSuccess({ data }));
    }
  } catch (e: any) {
    yield* put(
      fetchBookingsFailure({
        error: e.message,
      })
    );
  }
}

function* removeBeachChairBookingSaga(action: Action) {
  try {
    if (removeBeachChairBookingRequest.match(action)) {
      const { id, bookingId } = action.payload;
      yield* call(removeBeachChairBooking, id, bookingId);
      const { data } = yield* call(getBooking, bookingId);
      yield* put(
        removeBookingBeachChairRightDrawerToggleRequest({
          toggle: false,
        })
      );
      yield* put(
        removeBeachChairBookingSuccess({
          booking: data,
        })
      );
      toast.success(
        `${i18next.t(`toastMessages.RemoveBeachChairBookingSuccess`)}`
      );
    }
  } catch (e: any) {
    yield* put(
      cancelBookingFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.RemoveBeachChairBookingFailure`)}`);
  }
}

function* bookingsSaga() {
  yield* all([
    takeLatest(fetchBookingsRequest.type, fetchBookingsSaga),
    takeLatest(fetchBookingRequest.type, fetchBookingSaga),
    takeLatest(filterBookingsRequest.type, filterBookingsSaga),
    takeLatest(pushBookingRequest.type, pushBookingSaga),
    takeLatest(addBeachChairToBookingRequest.type, addBeachChairToBookingSaga),
    takeLatest(
      deleteBookingBeachChairRequest.type,
      deleteBookingBeachChairSaga
    ),
    takeLatest(addExtrasToBookingRequest.type, addExtrasToBookingSaga),
    takeLatest(patchBookingRequest.type, patchBookingSaga),
    takeLatest(putBookingConfirmRequest.type, putBookingConfirmSaga),
    takeLatest(putBookingReserveRequest.type, putBookingReserveSaga),
    takeLatest(putBookingPayRequest.type, putBookingPaySaga),
    takeLatest(putBookingBeachChairRequest.type, putBookingBeachChairSaga),
    takeLatest(setBookingIdRequest.type, setBookingIdSaga),
    takeLatest(deleteBookingRequest.type, deleteBookingSaga),
    takeLatest(postNewBookingRequest.type, postNewBookingSaga),
    takeLatest(postNewExtrasBookingRequest.type, postNewExtrasBookingSaga),
    takeLatest(routerRedirectBookings.type, routerRedirectBookingsSaga),
    takeLatest(createNewBookingRequest.type, createNewBookingSaga),
    takeLatest(
      changeBookingExtrasAmountRequest.type,
      changeBookingExtrasAmountSaga
    ),
    takeLatest(cancelBookingRequest.type, cancelBookingSaga),
    takeLatest(changeBeachChairsRequest.type, changeBeachChairsSaga),
    takeLatest(deleteBookingExtrasRequest.type, deleteBookingExtrasSaga),
    takeLatest(createInstantBookingRequest.type, createInstantBookingSaga),
    takeLatest(getCSVBookingsRequest.type, getCSVBookingsSaga),
    takeLatest(sendPaymentLinkRequest.type, sendPaymentLinkSaga),
    debounce(
      500,
      removeBeachChairBookingRequest.type,
      removeBeachChairBookingSaga
    ),
    debounce(1000, updateCommentRequest.type, updateCommentSaga),
    debounce(
      1000,
      updateCustomerCommentRequest.type,
      updateCustomerCommentSaga
    ),
    debounce(100, pushBookingCustomerRequest.type, pushBookingCustomerSaga),
  ]);
}

export default bookingsSaga;
function filterCancelBookingsSuccess(): any {
  throw new Error("Function not implemented.");
}
