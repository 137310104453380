import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { axiosInstance } from "../../api/apiTanStack";
import { getCurrentUser } from "../../api/userApi";
import {
  deleteAdminVendor,
  getAdminVendor,
  getAdminVendors,
  getVendors,
  postAdminVendor,
  putAdminVendor,
} from "../../api/vendorApi";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import {
  getCurrentUserFailure,
  getCurrentUserRequest,
  getCurrentUserSuccess,
  loginUserFailure,
  loginUserSuccess,
  logoutUserRequest,
  logoutUserSuccess,
  getVendorsRequest,
  getVendorsSuccess,
  getVendorsFailure,
  getAdminVendorsRequest,
  getAdminVendorsSuccess,
  deleteAdminVendorSuccess,
  deleteAdminVendorRequest,
  adminVendorsFailure,
  getAdminVendorSuccess,
  getAdminVendorRequest,
  putAdminVendorRequest,
  putAdminVendorSuccess,
  postAdminVendorRequest,
  postAdminVendorSuccess,
} from "../reducers/authReducer";
import { toast } from "react-toastify";
import { routerRequest } from "../reducers/routerReducer";
import API from "./../../api/api";
import { postAdminContextVendorSuccess } from "../reducers/adminReducer";
import i18next from "i18next";
import { queryClient } from "../..";

function* idTokenSaga(action: Action) {
  try {
    if (loginUserSuccess.match(action)) {
      const { id_token } = action.payload;
      API.defaults.headers.common["Authorization"] = `Bearer ${id_token}`;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${id_token}`;
      yield* put(getCurrentUserRequest({}));
    }
  } catch (e: any) {
    yield* put(
      loginUserFailure({
        error: e.message,
      })
    );
  }
}

function* logoutSaga() {
  try {
    API.defaults.headers.common["Authorization"] = ``;
    axiosInstance.defaults.headers.common["Authorization"] = ``;
    yield* put(logoutUserSuccess());
  } catch (e: any) {
    yield* put(
      loginUserFailure({
        error: e.message,
      })
    );
  }
}

function* currentUserSaga(action: Action) {
  try {
    if (
      getCurrentUserRequest.match(action) &&
      API.defaults.headers.common["Authorization"] !== "" &&
      axiosInstance.defaults.headers.common["Authorization"] !== ""
    ) {
      const { data } = yield* call(getCurrentUser);
      yield* put(postAdminContextVendorSuccess({ vendorId: data.vendorId }));
      yield* put(getCurrentUserSuccess({ currentUser: data }));
    }
  } catch (e: any) {
    yield* put(
      getCurrentUserFailure({
        error: e.message,
      })
    );
  }
}

function* getVendorsSaga(action: Action) {
  try {
    if (getVendorsRequest.match(action)) {
      const { data } = yield* call(getVendors);
      yield put(getVendorsSuccess({ vendors: data.items }));
    }
  } catch (e: any) {
    yield* put(
      getVendorsFailure({
        error: e.message,
      })
    );
  }
}

function* getAdminVendorsRequestSaga(action: Action) {
  try {
    if (getAdminVendorsRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getAdminVendors,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getAdminVendorsSuccess({
          vendors: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      adminVendorsFailure({
        error: e.message,
      })
    );
  }
}

function* getAdminVendorRequestSaga(action: Action) {
  try {
    if (getAdminVendorRequest.match(action)) {
      const { vendorId } = action.payload;
      const { data } = yield* call(getAdminVendor, vendorId);
      yield* put(
        getAdminVendorSuccess({
          vendor: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      adminVendorsFailure({
        error: e.message,
      })
    );
  }
}

function* putAdminVendorSaga(action: Action) {
  try {
    if (putAdminVendorRequest.match(action)) {
      const { vendorId, formData } = action.payload;
      const { data } = yield* call(putAdminVendor, vendorId, formData);
      yield* put(putAdminVendorSuccess({ vendor: data }));
      queryClient.refetchQueries(["vendor", Number(vendorId)]);
      toast.success(`${i18next.t(`toastMessages.PutAdminVendorSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      adminVendorsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutAdminVendorFailure`)}`);
  }
}

function* postAdminVendorSaga(action: Action) {
  try {
    if (postAdminVendorRequest.match(action)) {
      const { formData } = action.payload;
      const { id, ...formValuesWithoutVendorId } = formData;
      const { data } = yield* call(postAdminVendor, formValuesWithoutVendorId);
      yield* put(postAdminVendorSuccess({ vendor: data }));
      toast.success(`${i18next.t(`toastMessages.PostAdminVendorSuccess`)}`);
      yield* put(routerRequest({ path: "/vendors" }));
    }
  } catch (e: any) {
    yield* put(
      adminVendorsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostAdminVendorFailure`)}`);
  }
}

function* deleteAdminVendorSaga(action: Action) {
  try {
    if (deleteAdminVendorRequest.match(action)) {
      const { vendorId, isList } = action.payload;
      yield* call(deleteAdminVendor, vendorId);
      if (!isList) {
        yield* put(routerRequest({ path: "/vendors" }));
      }
      yield* put(deleteAdminVendorSuccess({ vendorId }));
      toast.success(`${i18next.t(`toastMessages.DeleteAdminVendorSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      adminVendorsFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteAdminVendorFailure`)}`);
  }
}

function* AuthSaga() {
  yield* all([
    takeLatest(loginUserSuccess.type, idTokenSaga),
    takeLatest(logoutUserRequest.type, logoutSaga),
    takeLatest(getCurrentUserRequest.type, currentUserSaga),
    takeLatest(getVendorsRequest.type, getVendorsSaga),
    takeLatest(getAdminVendorsRequest.type, getAdminVendorsRequestSaga),
    takeLatest(getAdminVendorRequest.type, getAdminVendorRequestSaga),
    takeLatest(putAdminVendorRequest.type, putAdminVendorSaga),
    takeLatest(postAdminVendorRequest.type, postAdminVendorSaga),
    takeLatest(deleteAdminVendorRequest.type, deleteAdminVendorSaga),
  ]);
}

export default AuthSaga;
