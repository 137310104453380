import { create } from "zustand";

interface BulkIdsStore {
  ids: string[];
  changeIds: (ids: string[]) => void;
}

export const useBulkIdsStore = create<BulkIdsStore>((set) => {
  return {
    ids: [],
    changeIds: (ids: string[]) =>
      set((state) => ({
        ...state,
        ids,
      })),
  };
});
