import { create } from "zustand";
import {
  CustomerFiltersDto,
  OrderByDirection,
} from "../../../api-client/generated";

export type CustomerSortField =
  | "id"
  | "firstName"
  | "lastName"
  | "email"
  | "zip"
  | "city";

interface CustomerFilterStore {
  filters: CustomerFiltersDto;
  setFilters: (filters: CustomerFiltersDto) => void;

  orderBy: CustomerSortField;
  setSortField: (field: CustomerSortField) => void;

  orderDir: OrderByDirection;
  setSortOrder: (order: OrderByDirection) => void;
}

export const useCustomerFilters = create<CustomerFilterStore>((set) => {
  return {
    filters: {},
    orderBy: "id",
    orderDir: OrderByDirection.Asc,
    setSortField: (field: CustomerSortField) =>
      set((state) => ({ ...state, orderBy: field })),
    setSortOrder: (order: OrderByDirection) =>
      set((state) => ({ ...state, orderDir: order })),
    setFilters: (filters: CustomerFiltersDto) =>
      set((state) => ({ ...state, filters })),
  };
});
