import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { featuresApi, sectionApi } from "../../api/apiTanStack";
import { SectionTimeFrameRowDataResponseDto } from "../../api-client/generated";
import { getVendorIdSelector } from "../../store/selectors/adminSelectors";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export function useResendPaymentDataMutation() {
  const { t } = useTranslation(["common"]);
  const resendPaymentDataMutation = useMutation({
    mutationFn: (data: { id: number }) =>
      featuresApi.bookingDetailsControllerResendBankTransfer({ id: data.id }),
    onSuccess: () => {
      toast.success(
        t(`toastMessages.Payment instructions sent out successfully`)
      );
    },
    onError: () => {
      toast.error(t(`toastMessages.Error while sending payment data`));
    },
  });
  return {
    resendPaymentDataMutation,
  };
}

export function useAvailabilitiesBeachChairsChangeQuery(
  start?: string,
  end?: string,
  beachChairId?: number,
  selectedSectionId?: number
) {
  const { t } = useTranslation(["common"]);
  const vendorId = useSelector(getVendorIdSelector);
  const enabled =
    start !== undefined &&
    end !== undefined &&
    beachChairId !== undefined &&
    vendorId !== undefined;
  const sectionFilters = new URLSearchParams(`filters[vendorId]=${vendorId}`);
  const { data: sections } = useQuery(
    ["sectionsVendor", vendorId, start],
    () =>
      sectionApi
        .sectionsControllerFindMany(
          { page: 0, itemsPerPage: 0 },
          { params: sectionFilters }
        )
        .then((res) => res.data.items),
    {
      enabled,
      networkMode: "online",
      cacheTime: Infinity,
    }
  );

  const ids = selectedSectionId
    ? [selectedSectionId]
    : sections?.map((a) => a.id) ?? [];
  const filters = new URLSearchParams(
    `filters[start]=${start}&filters[end]=${end}&filters[free]=true&filters[timeZone]=Europe/Berlin`
  );
  const allSections = useQueries({
    queries: ids.map((id) => {
      return {
        queryKey: ["availabilitiesBeachChairsChangeQuery", id, beachChairId],
        queryFn: () =>
          featuresApi
            .sectionsControllerGetSectionsBeachChairs(
              { id: id },
              { params: filters }
            )
            .then((response) => response.data)
            .catch((e) => {
              if (e && e?.response?.status === 500) {
                toast.error(t("toastMessages.BeachCharAlreadyChanged"));
              }
              return null;
            }),
        enabled,
      };
    }),
  });
  const isLoading = allSections.some((query) => query.isLoading);
  const allSectionsData = allSections.map((fetchedData) => fetchedData.data);
  const allAvailableBeachChairs: SectionTimeFrameRowDataResponseDto[] = [];
  allSectionsData.forEach((section) => {
    section && allAvailableBeachChairs.push(...section);
  });
  return { allAvailableBeachChairs, isLoading };
}

export function useAvailabilitiesBeachChairs(start: string) {
  const { t } = useTranslation(["common"]);
  const vendorId = useSelector(getVendorIdSelector);
  const enabled = vendorId !== undefined;
  const sectionFilters = new URLSearchParams(`filters[vendorId]=${vendorId}`);
  const { data: sections } = useQuery(
    ["useAvailabilitiesBeachChairsSections", vendorId, start],
    () =>
      sectionApi
        .sectionsControllerFindMany(
          { page: 0, itemsPerPage: 0 },
          { params: sectionFilters }
        )
        .then((res) => res.data.items),
    {
      enabled,
      networkMode: "online",
      cacheTime: Infinity,
    }
  );

  const ids = sections?.map((a) => a.id) ?? [];
  const filters = new URLSearchParams(
    `filters[start]=${start}&filters[end]=${start}&filters[free]=true&filters[timeZone]=Europe/Berlin`
  );
  const allSections = useQueries({
    queries: ids.map((id) => {
      return {
        queryKey: ["useAvailabilitiesBeachChairsAllSections", Number(id)],
        queryFn: () =>
          featuresApi
            .sectionsControllerGetSectionsBeachChairs(
              { id: id },
              { params: filters }
            )
            .then((response) => response.data)
            .catch((e) => {
              if (e && e?.response?.status === 500) {
                toast.error(t("toastMessages.BeachCharAlreadyChanged"));
              }
              return null;
            }),
        enabled,
      };
    }),
  });
  const isLoading = allSections.some((query) => query.isLoading);
  const allSectionsData = allSections.map((fetchedData) => fetchedData.data);
  const allAvailableBeachChairs: SectionTimeFrameRowDataResponseDto[] = [];
  allSectionsData.forEach((section) => {
    section && allAvailableBeachChairs.push(...section);
  });
  return { allAvailableBeachChairs, isLoading };
}
