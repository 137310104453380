import { useQuery } from "@tanstack/react-query";
import { sectionApi } from "../../../../api/apiTanStack";

export const useSection = (id?: number) =>
  useQuery({
    queryKey: ["sectionById", Number(id)],
    queryFn: async () => {
      return sectionApi
        .sectionsControllerFindOne({
          id: id ?? 0,
        })
        .then((response) => response.data);
    },
    enabled: !!id,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
