import moment from "moment";
import "moment/locale/de";
import React, { useMemo } from "react";
import Timeline, {
  CustomMarker,
  DateHeader,
  ReactCalendarGroupRendererProps,
  SidebarHeader,
  TimelineHeaders,
  TimelineMarkers,
} from "react-calendar-timeline";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { setGridTime } from "../../../../store/reducers/availabilityReducer";
import { routerRedirectBookings } from "../../../../store/reducers/bookingsReducer";
import { getBeachChairFilters } from "../../../../store/selectors/availabilitySelector";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { EColor } from "../../../../models/beachChairs/EColors";
import { ItemType } from "../../../../models/availability/IGrid";
import { routerRedirectBeachChair } from "../../../../store/reducers/beachChairsReducer";
import { Box, CardMedia } from "@mui/material";
import dayjs from "dayjs";
import { useAvailabilitiesDatesStore } from "../../hooks/useAvailabilitiesDatesStore";
import { useBeachChairsAvailabilitiesCalendar } from "../../hooks/useBeachChairsAvailabilitiesCalendar";
import { BeachChairModel } from "../../../../api-client/generated";
import { useMenuZustand } from "../../../layout/Navigation/hooks/useMenuZustand";
import { useSelectedBeachChairDates } from "../../../bookingsList/store/useSelectedBeachChairDates";
import { useBookingInProgressStore } from "../../../bookingsList/store/useBookingZustand";
import { SBColor } from "../../../SBComponents/styles/SBStyles";
import lockImg from "../../../../assets/lock.png";

export interface AvailabilitiesBeachChairsGridProps {
  setSelectedBeachChairId: React.Dispatch<React.SetStateAction<number>>;
  setRightDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
}

const detect = (id: number): boolean => `${id}`.includes("00");

const AvailabilitiesBeachChairsGrid = ({
  setSelectedBeachChairId,
  setRightDrawerState,
}: AvailabilitiesBeachChairsGridProps) => {
  moment.locale("de");
  const { open } = useMenuZustand();
  const { sectionId } = useParams<{ sectionId: string }>();
  const dispatch = useDispatch();
  const date = useMemo(() => dayjs(new Date()).toISOString(), []);
  const filters = useSelector(getBeachChairFilters);
  const defaultTimeStart = useMemo(
    () => moment(date).add(-1, "day").toDate(),
    [date]
  );
  const defaultTimeEnd = useMemo(
    () => moment(date).add(31, "day").toDate(),
    [date]
  );
  const { availabilitiesDateRange } = useAvailabilitiesDatesStore();
  const { data } = useBeachChairsAvailabilitiesCalendar(
    +sectionId,
    availabilitiesDateRange[0]?.format("YYYY-MM-DD"),
    availabilitiesDateRange[1]?.format("YYYY-MM-DD"),
    filters.row !== "" ? +filters.row : undefined,
    filters.model !== "" ? (filters.model as BeachChairModel) : undefined,
    filters.free,
    filters.hasLock
  );
  const { setBookingStartDateTime, setBookingEndDateTime } =
    useSelectedBeachChairDates();
  const { bookingInProgressBeachChairsIds, bookingInProgress } =
    useBookingInProgressStore();

  const groupRenderer = ({
    group,
  }: ReactCalendarGroupRendererProps<{
    id: number;
    title: string;
  }>) => {
    const [id, shortModel, lock, color] = group.title.split("/");
    const redirectID = `${group.id}`.split(/00(.*)/)[1];
    const row = !detect(group.id)
      ? {
          backgroundColor: "gray",
          color: customStyle.whiteColor,
          marginLeft: "-5px",
          marginRight: "-5px",
          paddingLeft: "10px",
          display: "grid",
          gridTemplateColumns: "auto auto",
        }
      : {
          paddingLeft: "1px",
          display: "grid",
          marginRight:`${color ? "-4px" : null}`,
          gridTemplateColumns: "80% 20%",
              borderRight: `solid 5px ${color
                ? EColor[color as keyof typeof EColor]
                : null}`
        };
    const isLock = (lock?.toLowerCase?.() === 'true')
    return (
      <Box sx={row}>
        <Box
          sx={{
            color: customStyle.mainColor,
              whiteSpace: "nowrap",
              overflow: "hidden",
            
          }}
          onClick={()=>{
            if(redirectID) {
              dispatch(
                routerRedirectBeachChair({
                  id: +redirectID,
                  type: "show",
                })
              );
            }
            
          }}
        >
          {detect(group.id) ? (
            id
          ) : (
            <Box
              sx={{
                color: SBColor.white,
              }}
            >
              {group.title}
            </Box>
          )}
          
        </Box>
        {isLock && <CardMedia
              sx={{
                mt: "10px",
                height: "30px",
                width: "20px",
              }}
              data-testid="hasLock"
              component="img"
              src={lockImg}
            />}
      </Box>
    );
  };

  const itemRenderer = ({ item, getItemProps }: any) => {
    const exist = bookingInProgressBeachChairsIds.find((id) =>
      `${item.group}`.includes(`${id}`)
    );
    const bookingCheck = `${item.id}`.includes(`${bookingInProgress?.id}`);
    return (
      <Box
        {...getItemProps()}
        sx={{
          ...(item.type === ItemType.STOP_SALE && {
            border: "none !important",
            height: "23px",
            background: "none !important",
            lineHeight: "16px !important",
            "z-index": "30 !important",
          }),
        }}
      >
        {item.type === ItemType.RESERVATION ? (
          <Box
            sx={{
              backgroundColor: "white",
              color: "green",
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: "green",
              height: "31px",
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "",
            }}
          >
            {item.title}
          </Box>
        ) : item.type === ItemType.STOP_SALE ? (
          <Box
            sx={{
              backgroundColor: "white",
              color: customStyle.mainColor,
              borderStyle: "dashed",
              borderWidth: "2px",
              borderColor: customStyle.mainColor,
              height: "21px",
              zIndex: 30,
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {item.title}
          </Box>
        ) : (
          <Box
            sx={{
              backgroundColor:
                bookingCheck && exist
                  ? customStyle.secondaryColor
                  : customStyle.gray,
              height: "31px",
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "",
            }}
          >
            {item.title}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: 0,
        maxWidth: open ? "calc(100vw - 260px)" : "calc(100vw - 100px)",
        maxHeight: "calc(65vh)",
        height: "calc(65vh)",
      }}
    >
      {data && data?.groups.length !== 0 && (
        <Timeline
          lineHeight={50}
          groups={data.groups}
          items={data.items}
          groupRenderer={groupRenderer}
          itemRenderer={itemRenderer}
          horizontalLineClassNamesForGroup={(group) =>
            !detect(group.id) ? ["highlight"] : [""]
          }
          onItemSelect={(itemId) => {
            if (`${itemId}`.startsWith("999999")) {
              dispatch(
                routerRedirectBeachChair({
                  id: +`${itemId}`.substring(6),
                  type: "show",
                })
              );
            } else {
              const id = `${itemId}`.split(/00(.*)/)[1];
              dispatch(
                routerRedirectBookings({
                  id: +id,
                  type: "show",
                })
              );
            }
          }}
          onCanvasClick={(groupId, time) => {
            if (detect(+groupId)) {
              const id = +`${groupId}`.split(/00(.*)/)[1];
              setRightDrawerState(true);
              setSelectedBeachChairId(id);
              dispatch(setGridTime({ time }));
              const date = new Date(time);
              setBookingStartDateTime(dayjs(date));
              setBookingEndDateTime(dayjs(date));
            }
          }}
          sidebarWidth={200}
          canMove={false}
          timeSteps={{
            day: 1,
            second: 0,
            year: 0,
            month: 1,
            minute: 0,
            hour: 1,
          }}
          canResize={false}
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
        >
          <TimelineHeaders className="headerTimeline">
            <SidebarHeader>
              {({ getRootProps }) => {
                return (
                  <div {...getRootProps()} className="headerTimeline"></div>
                );
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" className="headerTimeline" />
            <DateHeader />
          </TimelineHeaders>
          <TimelineMarkers>
            <CustomMarker date={new Date().getTime()}>
              {({ styles }) => {
                const customStyles = {
                  ...styles,
                  backgroundColor: customStyle.mainColor,
                  width: "4px",
                };
                return <div style={customStyles} />;
              }}
            </CustomMarker>
          </TimelineMarkers>
        </Timeline>
      )}
    </Box>
  );
};

export default AvailabilitiesBeachChairsGrid;
