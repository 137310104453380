import { useQuery } from "@tanstack/react-query";
import { sectionApi } from "../../../../api/apiTanStack";
import { useSelector } from "react-redux";
import { getVendorIdSelector } from "../../../../store/selectors/adminSelectors";

export const useGetSectionByVendorId = () => {
  const selectedVendorId = useSelector(getVendorIdSelector);
  const filters = new URLSearchParams(`filters[vendorId]=${selectedVendorId}`);

  return useQuery(
    [
      "useGetSectionByVendorId",
      Number(selectedVendorId),
      Number(selectedVendorId),
    ],
    () =>
      sectionApi
        .sectionsControllerFindMany(
          { page: 0, itemsPerPage: 0 },
          {
            params: filters,
          }
        )
        .then((res) => res.data),
    {
      cacheTime: Infinity,
      enabled: !!selectedVendorId,
    }
  );
};
