import {
  useInfiniteQuery,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import {
  LocationsFiltersDto,
  LocationResponseDto,
  OrderByDirection,
} from "../../../api-client/generated";
import axios, { AxiosError } from "axios";
import { locationApi } from "../../../api/apiTanStack";
import { PaginationMeta, RefetchInfinityQuery } from "../../../types";

interface Payload {
  page?: number;
  itemsPerPage?: number;
  orderBy?: keyof LocationsFiltersDto;
  orderDir?: OrderByDirection;
  filters?: LocationsFiltersDto;
  search?: string;
  enabled?: boolean;
}

interface LocationPage {
  data: LocationResponseDto[];
  totalItems: number;
  nextPage: number | undefined;
}

export interface UseGetLocations {
  locations: LocationPage[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<
      {
        data: LocationResponseDto[];
        nextPage: number | undefined;
      }
    >
  >;
  hasNextPage?: boolean;
  paginationMeta?: PaginationMeta;
  refetch: RefetchInfinityQuery<LocationResponseDto>;
}

export const useGetLocations = (
  variables: Payload = { enabled: true }
): UseGetLocations => {
  const queryVariables = {
    page: variables?.page || 0,
    itemsPerPage: variables?.itemsPerPage || 15,
    orderBy: variables?.orderBy,
    orderDir: variables?.orderDir,
    search: variables?.search
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<LocationPage, AxiosError>(
    [
      "vendors",
      queryVariables.page,
      queryVariables.itemsPerPage,
      queryVariables.orderBy,
      queryVariables.orderDir,
      variables?.filters,
      queryVariables.search,
    ],
    ({ signal, pageParam = queryVariables.page }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      const filterParams = new URLSearchParams(
        Object.entries(variables?.filters || {})
          .filter(([, value]) => value !== undefined)
          .map(([key, value]) => `filters[${key}]=${value}`)
          .join("&")
      );

      return locationApi
        .locationsControllerFindMany({
          ...queryVariables,
          page: pageParam,
        }, {
          params: filterParams
        })
        .then(({ data }) => {
          return {
            data: data.items,
            totalItems: data.count,
            nextPage: pageParam + 1,
          };
        });
    },
    {
      enabled: variables.enabled,
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < queryVariables.itemsPerPage) {
          return undefined;
        }
        return lastPage.nextPage;
      },
    }
  );

  const totalItems = data?.pages?.[0]?.totalItems || 0;
  const totalPages = totalItems
    ? Math.ceil(totalItems / queryVariables.itemsPerPage)
    : 0;

  return {
    locations: data?.pages || [],
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    paginationMeta: {
      itemsPerPage: queryVariables.itemsPerPage,
      currentPageIndex: (data?.pages?.length || 1) - 1,
      totalItems,
      totalPages,
    },
    refetch,
  };
};
