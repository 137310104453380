import { useEffect, useMemo, useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { customStyle } from "../../utils/customStyles/customStyles";
import RepeatIcon from "@mui/icons-material/Repeat";
import i18n from "../../utils/i18n/i18n";
import CircularProgress from "@mui/material/CircularProgress";
import { useAvailabilitiesBeachChairsChangeQuery } from "../../utils/tanstackHooks/useBookingsTanStack";
import { useGetBooking } from "../bookingsList/booking/hooks";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { deDE } from "@mui/x-date-pickers/locales";
import { useSectionRow } from "./ChangeDialogAdvanced/useSectionRow";
import SBSelect from "../SBComponents/SBForms/SBSelect";
import { MenuOpen, ViewModule } from "@mui/icons-material";
import React from "react";

export interface IDialogProps {
  open: boolean;
  namespace: string;
  bookingId: number;
  selectedId: number | undefined;
  onClose: (
    previousBeachChairId?: number,
    beachChairId?: number,
    start?: string,
    end?: string,
    price?: number,
    hasStarted?: boolean
  ) => void;
}

const ChangeDialog = ({
  open,
  onClose,
  bookingId,
  selectedId,
}: IDialogProps) => {
  const { t } = useTranslation(["common"]);
  const [choosenId, setChoosenId] = useState<number>();
  const [choosenPublicNumber, setChoosenPublicNumber] = useState<string>();
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const { booking } = useGetBooking(bookingId);
  const selectedBeachChair = booking?.beachChairBookings.find(
    (beachChair) => beachChair.id === selectedId
  );
  const {
    control,
    filterIsLoading,
    setValue,
    filters,
    selectedSectionId,
    selectedRowId,
    errors,
  } = useSectionRow(selectedBeachChair);
  const hasStarted = useMemo(() => {
    return selectedBeachChair
      ? dayjs().isAfter(dayjs(selectedBeachChair.start)) //isAfter(new Date(), new Date(selectedBeachChair.start))
      : false;
  }, [selectedBeachChair]);

  const { allAvailableBeachChairs: tableData, isLoading } =
    useAvailabilitiesBeachChairsChangeQuery(
      hasStarted ? startDate?.toISOString() : selectedBeachChair?.start,
      selectedBeachChair?.end,
      selectedId,
      selectedSectionId
    );

  const handleCancel = () => {
    onClose();
    setChoosenPublicNumber(undefined);
    setChoosenId(undefined);
  };

  const handleOk = () => {
    if (selectedBeachChair) {
      onClose(
        selectedBeachChair?.id,
        choosenId,
        startDate?.toISOString(),
        selectedBeachChair.end,
        selectedBeachChair.price,
        hasStarted
      );
      setChoosenPublicNumber(undefined);
      setChoosenId(undefined);
    }
  };

  return (
    <I18nextProvider i18n={i18n}>
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth={true}
      >
        <DialogTitle id="simple-dialog-title">
          {t(`changeDialogTitle`)}
          {hasStarted && (
            <Box sx={{ float: "right" }}>
              <LocalizationProvider
                adapterLocale="de"
                dateAdapter={AdapterDayjs}
                localeText={
                  deDE.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <DatePicker
                  minDate={dayjs()}
                  maxDate={
                    selectedBeachChair?.end
                      ? dayjs(selectedBeachChair.end)
                      : undefined
                  }
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  value={dayjs(startDate)}
                  /*renderInput={(params) => (
                    <TextField
                      sx={{ minWidth: "95px" }}
                      {...params}
                      variant="standard"
                    />
                  )}*/
                />
              </LocalizationProvider>
            </Box>
          )}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            justifyContent: "center",
            alignItems: "center",
            overflowY: "scroll",
            boxSizing: "content-box",
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: customStyle.scrollColor,
            },
          }}
        >
          <Box
            sx={{
              minHeight: 70,
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {t(`changeDialogContent`)}
            <Box
              sx={{
                display: "grid",
                justifyContent: "center",
                alignContent: "center",
                gridTemplateColumns: "30% 40% 30%",
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                }}
              >
                {selectedBeachChair?.beachChair.publicNumber}
              </Box>
              <Box
                sx={{
                  margin: "auto",
                }}
              >
                <RepeatIcon />
              </Box>
              <Box
                sx={{
                  margin: "auto",
                  color: customStyle.mainColor,
                  fontWeight: "bold",
                }}
              >
                {choosenPublicNumber}
              </Box>
            </Box>
          </Box>
          <Box>
            <SBSelect
              name="sectionId"
              label={t("section")}
              noOptionLabel={t("NO_SECTION")}
              control={control}
              type={"edit"}
              icon={<ViewModule />}
              isLoading={filterIsLoading}
              setValue={setValue}
              setValueId="rowId"
              additionalOptions={filters?.sections.slice().sort((r, o) => {
                return r.value.localeCompare(o.value);
              })}
              defaultValue={0}
              error={errors?.sectionId && t((errors.sectionId as any)?.message)}
            />
            <SBSelect
              name="rowId"
              label={t("row")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={"edit"}
              icon={<MenuOpen />}
              isLoading={filterIsLoading}
              additionalOptions={filters?.sections
                .filter((section) => section.id === selectedSectionId)
                .flatMap((section) => {
                  return section.rows
                    .slice()
                    .sort((r, o) => {
                      return r.value.localeCompare(o.value);
                    })
                    .flatMap((row) => {
                      return { id: row.id, value: row.value };
                    });
                })}
              defaultValue={0}
              error={errors?.rowId && t((errors.rowId as any)?.message)}
            />
          </Box>

          {tableData.length > 0 ? (
            <TableContainer
              sx={{
                maxHeight: "35vh",
                overflowY: "scroll",
                boxSizing: "content-box",
                "&::-webkit-scrollbar": {
                  width: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: customStyle.scrollColor,
                },
                position: "relative",
              }}
            >
              <Table aria-label="dataTableChange" data-testid="tableChange">
                <TableHead data-testid="table-headChange">
                  <TableRow data-testid="table-head-row">
                    <TableCell sx={{ padding: 0, paddingLeft: 2, height: 49 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t(`publicNumber`)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 0, paddingLeft: 2, height: 49 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t(`section`)}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 0, paddingLeft: 2, height: 49 }}>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {t(`model`)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {tableData

                    .filter((row) => row.id === selectedRowId)
                    .map((row) => {
                      const rowData = row.rowData;
                      return (rowData as any)
                        .sort((r, o) => {
                          const rPublicNumber = `${r.publicNumber}`;
                          const oPublicNumber = `${o.publicNumber}`;
                          return rPublicNumber.localeCompare(
                            oPublicNumber,
                            "de",
                            { numeric: true }
                          );
                        })
                        .map((beachChair: any) => {
                          const selected = choosenId === beachChair.id;
                          return (
                            <TableRow
                              key={`id-${beachChair.id}-${beachChair.publicNumber}`}
                              sx={{
                                backgroundColor: selected
                                  ? customStyle.mainColor
                                  : "white",
                                color: selected ? "white" : "black",
                                fontWeight: "bold",
                                "&:hover": selected
                                  ? { boxShadow: "0 0 11px rgba(33,33,33,.2)" }
                                  : {},
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setChoosenId(beachChair.id);
                                setChoosenPublicNumber(beachChair.publicNumber);
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: 0,
                                  paddingLeft: 2,
                                  height: 49,
                                  color: selected ? "white" : "black",
                                }}
                                component="th"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: selected ? "bold" : "none",
                                  }}
                                >
                                  {beachChair.publicNumber}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: 0,
                                  paddingLeft: 2,
                                  height: 49,
                                  color: selected ? "white" : "black",
                                }}
                                component="th"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: selected ? "bold" : "none",
                                  }}
                                >
                                  {beachChair.sectionName}
                                  <br />
                                  {beachChair.rowName}
                                </Typography>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: 0,
                                  paddingLeft: 2,
                                  height: 49,
                                  color: selected ? "white" : "black",
                                }}
                                component="th"
                              >
                                <Typography
                                  sx={{
                                    fontWeight: selected ? "bold" : "none",
                                  }}
                                >
                                  {t(`models.${beachChair.model}`)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        });
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Box
              sx={{
                minHeight: 70,
                display: "grid",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isLoading ? <CircularProgress /> : t(`search.noBeachChairs`)}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel} color="primary">
            {t("cancel")}
          </Button>
          <Button onClick={handleOk} color="primary">
            {t("change")}
          </Button>
        </DialogActions>
      </Dialog>
    </I18nextProvider>
  );
};

export default ChangeDialog;
