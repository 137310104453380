import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import SBTable from "../SBTable/SBTable";
import { useHistory } from "react-router";
import ExtrasHeader from "./extrasHeader.tsx/extrasHeader";
import RightDrawer from "../rightDrawer/rightDrawer";
import SelectedExtras from "./selectedExtras/selectedExtras";
import { useDeleteExtra } from "./hooks/useDeleteExtra";
import { useGetExtras } from "./hooks/useGetExtras";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import { useDuplicateExtraMutation } from "./hooks/useDuplicateExtraMutation";
import { ContentCopy } from "@mui/icons-material";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const OnlineBookingStatus: React.FC<{ status: boolean }> = ({ status }) => {
  const { t } = useTranslation(["common"]);
  return (
    <Box
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        color: "white",
        width: 80,
        borderRadius: 20,
        fontWeight: "bold",
        backgroundColor: status ? "green" : "red",
      }}
    >
      {status ? t("yes") : t("no")}
    </Box>
  );
};

const ExtraList = () => {
  const { t } = useTranslation(["common"]);
  const [rightDrawerState, setRightDrawerState] = useState<boolean>(false);
  const [selectedExtrasId, setSelectedExtrasId] = useState<number>(0);
  const history = useHistory();
  const deleteExtra = useDeleteExtra();
  const duplicate = useDuplicateExtraMutation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteExtraModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("extras");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetExtras(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleOnAddClick = React.useCallback((id: number) => {
    setSelectedExtrasId(id);
    setRightDrawerState(true);
  }, []);

  const handleDeleteExtra = (id: number) => {
    deleteExtra.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "name",
        header: `${t("extrasName")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/extras/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "type",
        header: `${t("type")}`,
        accessorFn: (originalRow: any) => `${t(`types.${originalRow.type}`)}`,
        filterVariant: "select",
        filterSelectOptions: [
          { text: `${t(`types.FOOD`)}`, value: "FOOD" },
          { text: `${t(`types.ACCESSOIRES`)}`, value: "ACCESSOIRES" },
          { text: `${t(`types.TOYS`)}`, value: "TOYS" },
          { text: `${t(`types.BEVERAGES`)}`, value: "BEVERAGES" },
          {
            text: `${t(`types.ALCOHOLIC_BEVERAGES`)}`,
            value: "ALCOHOLIC_BEVERAGES",
          },
          { text: `${t(`types.OTHER`)}`, value: "OTHER" },
        ].sort((a, b) => a.text.localeCompare(b.text)),
        maxSize: 60,
      },
      {
        accessorKey: "price",
        header: `${t("price")}`,
        accessorFn: (originalRow: any) =>
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(originalRow.price),
        maxSize: 60,
        filterVariant: "range",
      },
      {
        accessorKey: "onlineBooking",
        header: `${t("onlineBooking")}`,
        filterVariant: "select",
        filterSelectOptions: [
          { text: `${t(`yes`)}`, value: "true" },
          { text: `${t(`no`)}`, value: "false" },
        ],
        accessorFn: (originalRow: any) => originalRow?.onlineBooking || false,
        maxSize: 100,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <OnlineBookingStatus
                status={row.renderedCellValue.props["aria-label"]}
              />
            ) : (
              <OnlineBookingStatus status={row.renderedCellValue} />
            )}
          </Box>
        ),
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("extrasList")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const extraId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (extraId) {
                  history.push(`/extras/${extraId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (extraId) {
                  history.push(`/extras/${extraId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("duplicate")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (extraId) {
                  const { id, vendorId, ...createExternalServicesDto } =
                    data?.items[cell.row.index];
                  duplicate.mutate({
                    createExternalServicesDto: createExternalServicesDto,
                  });
                }
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (extraId) {
                  openDeleteExtraModal(extraId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("add")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (extraId) {
                  handleOnAddClick(extraId);
                }
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history, handleOnAddClick]
  );

  return (
    <SBMainListWrapper>
      <RightDrawer state={rightDrawerState} setState={setRightDrawerState}>
        <SelectedExtras
          id={selectedExtrasId}
          handleClose={setRightDrawerState}
        />
      </RightDrawer>
      <ExtrasHeader />
      <SBTable
        columnVisibilityStoreName="ExtrasColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="extras"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteExtra(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default ExtraList;
