import { useQuery } from "@tanstack/react-query";
import { usersApi } from "../../../../api/apiTanStack";

export const useVendorUserStatus = (id: string) =>
  useQuery({
    queryKey: ["useVendorUserStatus", String(id)],
    queryFn: async () => {
      return usersApi
        .usersControllerGetUserStatus({
          id
        })
        .then((response) => {
          
          return response.data
        });
    },
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });


  
