export interface IGridGroup {
  id: number;
  publicNumber?: string;
  model?: string;
  title?: string;
  color?: string;
  lockId?: number;
}

export interface IGridGroupView {
  id: number;
  title: string;
}

export enum ItemType {
  BOOKING = 'BOOKING',
  RESERVATION = 'RESERVATION',
  STOP_SALE = 'STOP_SALE'
}

export interface IGridItem {
  type: ItemType;
  id: string;
  group: number;
  title: string;
  start_time: string;
  end_time: string;
}

export interface IGrid {
  items: IGridItem[];
  groups: IGridGroup[];
}

export interface IGridView {
    items: IGridItem[];
    groups: IGridGroupView[];
  }
