import { create } from "zustand";
import { BookingResponseDto } from "../../../api-client/generated";

interface BookingInProgressStore {
  bookingInProgress?: BookingResponseDto;
  bookingInProgressBeachChairsIds: number[];
  setBookingInProgress: (bookingInProgress: BookingResponseDto) => void;
  clearBookingInProgress: () => void;
}

export const useBookingInProgressStore = create<BookingInProgressStore>(
  (set) => {
    return {
      bookingInProgress: undefined,
      bookingInProgressBeachChairsIds: [],
      setBookingInProgress: (bookingInProgress: BookingResponseDto) =>
        set((state) => ({
          ...state,
          bookingInProgress,
          bookingInProgressBeachChairsIds: (
            bookingInProgress?.beachChairBookings ?? []
          ).map((beachChair) => beachChair.beachChairId),
        })),
      clearBookingInProgress: () =>
        set((state) => ({
          ...state,
          bookingInProgress: undefined,
          bookingInProgressBeachChairsIds: [],
        })),
    };
  }
);
