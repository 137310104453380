import {
  Box,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  Email,
  Phone,
  Room,
  Http,
  Settings,
  Delete,
  Description,
  Payment,
  Message,
  Tune,
} from "@mui/icons-material";
import React from "react";
import { Controller } from "react-hook-form";
import { BookingPaymentMethod } from "../../api-client/generated";
import { customStyle } from "../../utils/customStyles/customStyles";
import { useVendorForm } from "../../utils/customHooks/useVendorForm";
import { useTranslation } from "react-i18next";
import VendorHeader from "./vendorHeader/vendorHeader";
import { useHistory, useParams } from "react-router";
import DeleteDialog from "../dialog/DeleteDialog";
import { Autocomplete, Chip } from "@mui/material";
import ImageUploader from "../SBComponents/SBForms/SBImageUpload";
import logo_sbl_min from "./../../assets/SBL.png";
import { useDeleteVendor } from "./hooks";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import SBTextField from "../SBComponents/SBForms/SBTextField";
import BadgeIcon from "@mui/icons-material/Badge";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import EuroIcon from "@mui/icons-material/Euro";
import SBTextArea from "../SBComponents/SBForms/SBTextArea";
import SBCheckboxGrid from "../SBComponents/SBForms/SBCheckboxGrid";
import SBCheckBox from "../SBComponents/SBForms/SBCheckBox";
import GavelIcon from "@mui/icons-material/Gavel";
import SBToggleButtonGroup from "../SBComponents/SBForms/SBToggleButtonGroup";
import SBButtonActionGrid from "../SBComponents/SBForms/SBButtonActionGrid";
import {
  SBAutompleteTextFieldStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../SBComponents/styles/SBStyles";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LanguageIcon from "@mui/icons-material/Language";
import HttpIcon from "@mui/icons-material/Http";
import MoneyIcon from "@mui/icons-material/Money";
import SchemaIcon from "@mui/icons-material/Schema";
import KeyIcon from "@mui/icons-material/Key";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SBSelect from "../SBComponents/SBForms/SBSelect";

const chipRoot = {
  backgroundColor: customStyle.mainColor,
  color: customStyle.whiteColor,
  opacity: "1 !important",
  ".MuiChip-deleteIcon": {
    color: customStyle.whiteColor,
  },
} as const;

const invalidPaymentMethods: string[] = [
  BookingPaymentMethod.Ec,
  BookingPaymentMethod.DirectDebit,
];
const PaymentMethods = Object.values(BookingPaymentMethod).filter(
  (method) => !invalidPaymentMethods.includes(method)
);

const Vendor = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const [dialog, setDialog] = React.useState<boolean>(false);

  const { control, setValue, watchLogo, onSubmit, onReset, errors } =
    useVendorForm(isNaN(+id) ? undefined : +id, type);
  const deleteVendor = useDeleteVendor();

  const handleDeleteAfterModalConfirm = React.useCallback(
    (id: number) => {
      deleteVendor.mutateAsync({ id: id }).finally(() => {
        history.push("/vendors");
      });
    },
    [deleteVendor, history]
  );

  const handleOnDeleteClick = React.useCallback(() => {
    setDialog(true);
  }, [setDialog]);

  const checkType = type === undefined ? "edit" : type;
  const disableOnShow = checkType === "show";
  const isLoading = false;

  return (
    <SBMainComponentGridWrapper>
      <VendorHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="name"
              label={t("name")}
              control={control}
              type={checkType}
              icon={<BadgeIcon />}
              isLoading={isLoading}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBTextField
              name="companyName"
              label={t("companyName")}
              control={control}
              type={checkType}
              isLoading={isLoading}
              error={
                errors?.companyName && t((errors.companyName as any)?.message)
              }
            />
            <SBTextField
              name="details.telephone"
              label={t("telephone")}
              control={control}
              type={checkType}
              icon={<Phone />}
              isLoading={isLoading}
              placeholder={`+`}
              error={
                errors?.details &&
                (errors.details as any).telephone &&
                t((errors.details as any).telephone?.message)
              }
            />
            <SBTextField
              name="details.website"
              label={t("website")}
              control={control}
              type={checkType}
              icon={<Http />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any).website &&
                t((errors.details as any).website?.message)
              }
            />
            <SBTextField
              name="details.email"
              label={t("email")}
              control={control}
              type={checkType}
              icon={<Email />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any).email &&
                t((errors.details as any).email?.message)
              }
            />

            <SBTextField
              name="details.taxNumber"
              label={t("taxNumber")}
              control={control}
              type={checkType}
              icon={<LooksOneIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any).taxNumber &&
                t((errors.details as any).taxNumber?.message)
              }
            />
            <SBTextField
              name="details.vatId"
              label={t("vatId")}
              control={control}
              type={checkType}
              icon={<EuroIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any).vatId &&
                t((errors.details as any).vatId?.message)
              }
            />
            <SBTextArea
              name="description"
              label={t("description")}
              control={control}
              type={checkType}
              icon={<Description />}
              isLoading={isLoading}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBCheckboxGrid>
              <SBCheckBox
                name="details.smallBusiness"
                label={t("smallBusiness")}
                control={control}
                type={checkType}
                isLoading={isLoading}
                error={
                  errors?.details &&
                  (errors.details as any).smallBusiness &&
                  t((errors.details as any).smallBusiness?.message)
                }
              />
              <SBCheckBox
                name="activeAvailability"
                label={t("activeAvailability")}
                control={control}
                type={checkType}
                isLoading={isLoading}
                error={
                  errors?.activeAvailability &&
                  t((errors.activeAvailability as any)?.message)
                }
              />
              {type && (
                <SBCheckBox
                  name="configuration.hideCustomerComment"
                  label={t("configuration.hideCustomerComment")}
                  control={control}
                  type={checkType}
                  isLoading={isLoading}
                  error={
                    errors?.configuration &&
                    (errors.configuration as any).hideCustomerComment &&
                    t(
                      (errors.configuration as any).hideCustomerComment?.message
                    )
                  }
                />
              )}
              {type && (
                <SBCheckBox
                  name="configuration.hideOpeningHours"
                  label={t("configuration.hideOpeningHours")}
                  control={control}
                  type={checkType}
                  isLoading={isLoading}
                  error={
                    errors?.configuration &&
                    (errors.configuration as any).hideOpeningHours &&
                    t((errors.configuration as any).hideOpeningHours?.message)
                  }
                />
              )}
            </SBCheckboxGrid>
            <SBToggleButtonGroup
              optionFirstName="terms.de"
              optionSecondName="terms.en"
              label={t("termsTitle")}
              control={control}
              type={checkType}
              icon={<GavelIcon />}
            />
            <Box>
              <InputLabel
                sx={{
                  ...SBLabelStyle,
                  paddingLeft: "30px",
                  width: "100%",
                  position: "static",
                  transform: "none",
                }}
              >
                {t("logo")}
              </InputLabel>
              <Box
                sx={{
                  display: "grid",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    display: "grid",
                    maxWidth: "400px",
                    justifyContent: "center",
                    justifyItems: "center",
                    alignItems: "center",
                    alignContent: "center",
                    "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
                  }}
                >
                  <img
                    className="logoSquare"
                    alt={`logo`}
                    src={watchLogo || logo_sbl_min}
                  />
                  {!disableOnShow && (
                    <IconButton
                      sx={{
                        color: customStyle.mainColor,
                        "&:buttonDisabled": {
                          color: "gray",
                        },
                      }}
                      aria-label="delete"
                      onClick={() => {
                        setValue("details.logo", "");
                      }}
                      disabled={disableOnShow}
                    >
                      <Delete
                        sx={{
                          height: "30px",
                          width: "30px",
                        }}
                      />
                    </IconButton>
                  )}
                </Card>
              </Box>
            </Box>
            <Box
              sx={{
                height: "400px",
                display: "grid",
                gap: "10px",
                justifyContent: "center",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <Box style={{ height: "400px" }}>
                <ImageUploader
                  disabled={disableOnShow}
                  setValue={setValue}
                  width="400px"
                />
              </Box>
            </Box>
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="address.street"
              label={t("street")}
              control={control}
              type={checkType}
              icon={<Room />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.street &&
                t((errors.address as any)?.street?.message)
              }
            />
            <SBTextField
              name="address.zip"
              label={t("zip")}
              control={control}
              type={checkType}
              icon={<Room />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.zip &&
                t((errors.address as any)?.zip?.message)
              }
            />
            <SBTextField
              name="address.city"
              label={t("city")}
              control={control}
              type={checkType}
              icon={<Room />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.city &&
                t((errors.address as any)?.city?.message)
              }
            />
            <SBTextField
              name="address.country"
              label={t("country")}
              control={control}
              type={checkType}
              icon={<Room />}
              isLoading={isLoading}
              error={
                errors?.address &&
                (errors.address as any)?.country &&
                t((errors.address as any)?.country?.message)
              }
            />
            {type && (
              <SBTextField
                name="details.whatsAppNumber"
                label={t("whatsAppNumber")}
                control={control}
                type={checkType}
                icon={<Message />}
                isLoading={isLoading}
                placeholder={`whatsapp:`}
                error={
                  errors?.details &&
                  (errors.details as any)?.whatsAppNumber &&
                  t((errors.details as any).whatsAppNumber?.message)
                }
              />
            )}
            <SBTextField
              name="details.bankDetails.iban"
              label={t("iban")}
              control={control}
              type={checkType}
              icon={<AccountBalanceIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any)?.bankDetails &&
                ((errors.details as any)?.bankDetails as any)?.iban &&
                t(((errors.details as any)?.bankDetails as any)?.iban?.message)
              }
            />
            <SBTextField
              name="details.bankDetails.bankName"
              label={t("bankName")}
              control={control}
              type={checkType}
              icon={<AccountBalanceIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any)?.bankDetails &&
                ((errors.details as any)?.bankDetails as any)?.bankName &&
                t(
                  ((errors.details as any)?.bankDetails as any)?.bankName
                    ?.message
                )
              }
            />
            <SBTextField
              name="details.bankDetails.bic"
              label={t("bic")}
              control={control}
              type={checkType}
              icon={<AccountBalanceIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any)?.bankDetails &&
                ((errors.details as any)?.bankDetails as any)?.bic &&
                t(((errors.details as any)?.bankDetails as any)?.bic?.message)
              }
            />
            <SBTextField
              name="details.bankDetails.accountHolder"
              label={t("accountHolder")}
              control={control}
              type={checkType}
              icon={<AccountBalanceIcon />}
              isLoading={isLoading}
              error={
                errors?.details &&
                (errors.details as any)?.bankDetails &&
                ((errors.details as any)?.bankDetails as any)?.accountHolder &&
                t(
                  ((errors.details as any)?.bankDetails as any)?.accountHolder
                    ?.message
                )
              }
            />
            {type && (
              <SBTextField
                name="publicReference"
                label={t("publicReference")}
                control={control}
                type={checkType}
                icon={<Settings />}
                isLoading={isLoading}
                error={
                  errors?.publicReference &&
                  t((errors.publicReference as any)?.message)
                }
              />
            )}
            <SBTextField
              name="allowedHosts"
              label={t("allowedHosts")}
              control={control}
              type={checkType}
              icon={<LanguageIcon />}
              isLoading={isLoading}
              error={
                errors?.allowedHosts && t((errors.allowedHosts as any)?.message)
              }
            />
            <SBSelect
              name="configuration.beachChairView"
              label={t("configuration.beachChairView")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<Tune />}
              isLoading={isLoading}
              additionalOptions={[
                { id: "grid", value: `${t(`grid`)}` },
                { id: "map", value: `${t(`map`)}` },
                { id: "quota", value: `${t(`quota`)}` },
              ]}
              defaultValue={"grid"}
              error={errors?.status && t((errors.status as any)?.message)}
            />
            <SBTextField
              name="configuration.widgetUrl"
              label={t("configuration.widgetUrl")}
              control={control}
              type={checkType}
              icon={<HttpIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.widgetUrl &&
                t((errors.configuration as any)?.widgetUrl?.message)
              }
            />
            {type && (
              <SBTextField
                name="configuration.metabaseUrl"
                label={t("configuration.metabaseUrl")}
                control={control}
                type={checkType}
                icon={<QueryStatsIcon />}
                isLoading={isLoading}
                error={
                  errors?.configuration &&
                  (errors.configuration as any)?.metabaseUrl &&
                  t((errors.configuration as any)?.metabaseUrl?.message)
                }
              />
            )}
            <SBTextField
              name="configuration.stripeAccountId"
              label={t("configuration.stripeAccountId")}
              control={control}
              type={checkType}
              icon={<MoneyIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.stripeAccountId &&
                t((errors.configuration as any)?.stripeAccountId?.message)
              }
            />
            <Controller
              control={control}
              name="configuration.stripeFlow"
              render={({ field: { onChange, value } }) => (
                <Box sx={{ ...SBTextFieldGridStyle }}>
                  <Box sx={{ ...SBTextFieldIconStyle }}>
                    <SchemaIcon />
                  </Box>
                  <Box sx={{ display: "grid" }}>
                    <InputLabel
                      sx={{
                        ...SBLabelStyle,
                        width: "100%",
                        position: "static",
                        transform: "none",
                      }}
                    >
                      {t("configuration.stripeFlow")}
                    </InputLabel>
                    <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
                      <Autocomplete
                        disabled={disableOnShow}
                        options={["DIRECT", "DESTINATION"]}
                        value={value || "DIRECT"}
                        onChange={(_, data) => {
                          onChange(data);
                          return data;
                        }}
                        renderInput={(params) => (
                          <TextField
                            data-testid="configuration.stripeFlow"
                            {...params}
                            disabled={disableOnShow}
                            variant="outlined"
                            InputProps={{
                              ...params.InputProps,
                              disableUnderline: true,
                            }}
                            sx={{
                              ...SBTextFieldStyle,
                              ...SBAutompleteTextFieldStyle,
                              position: "static",
                            }}
                          />
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
              )}
            />
            <SBTextField
              name="configuration.payPalClientId"
              label={t("configuration.payPalClientId")}
              control={control}
              type={checkType}
              icon={<MoneyIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.payPalClientId &&
                t((errors.configuration as any)?.payPalClientId?.message)
              }
            />
            <SBTextField
              name="configuration.payPalClientSecret"
              label={t("configuration.payPalClientSecret")}
              control={control}
              type={checkType}
              icon={<KeyIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.payPalClientSecret &&
                t((errors.configuration as any)?.payPalClientSecret?.message)
              }
            />
            <SBTextField
              name="configuration.SevDeskCustomerId"
              label={t("configuration.SevDeskCustomerId")}
              control={control}
              type={checkType}
              icon={<FeaturedVideoIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.SevDeskCustomerId &&
                t((errors.configuration as any)?.SevDeskCustomerId?.message)
              }
            />
            <SBTextField
              name="configuration.masterKey"
              label={t("configuration.masterKey")}
              control={control}
              type={checkType}
              icon={<VpnKeyIcon />}
              isLoading={isLoading}
              error={
                errors?.configuration &&
                (errors.configuration as any)?.masterKey &&
                t((errors.configuration as any)?.masterKey?.message)
              }
            />
            {type && (
              <Controller
                control={control}
                name="configuration.paymentMethods"
                render={({ field: { onChange, value } }) => (
                  <Box sx={{ ...SBTextFieldGridStyle }}>
                    <Box sx={{ ...SBTextFieldIconStyle }}>
                      <Payment />
                    </Box>
                    <Box sx={{ display: "grid" }}>
                      <InputLabel
                        sx={{
                          ...SBLabelStyle,
                          width: "100%",
                          position: "static",
                          transform: "none",
                        }}
                      >
                        {t("configuration.paymentMethods")}
                      </InputLabel>
                      <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
                        <Autocomplete
                          disabled={disableOnShow}
                          multiple
                          options={PaymentMethods}
                          getOptionLabel={(option) =>
                            t(`paymentMethod.${option}`)
                          }
                          value={
                            value || [
                              "CC",
                              "STRIPE_BANK_TRANSFER",
                              "STRIPE_DIRECT_DEBIT",
                              "STRIPE_SOFORT",
                              "STRIPE_CREDIT_CARD",
                            ]
                          }
                          onChange={(_, data) => {
                            onChange(data);
                            return data;
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                sx={{ ...chipRoot }}
                                variant="outlined"
                                label={t(`paymentMethod.${option}`)}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              disabled={disableOnShow}
                              data-testid="configuration.paymentMethods"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              sx={{
                                ...SBTextFieldStyle,
                                ...SBAutompleteTextFieldStyle,
                                position: "static",
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                )}
              />
            )}
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/vendors/${+id}/edit`)}
          deleteOnClick={() => {
            handleOnDeleteClick();
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/vendors/${+id}/show`)}
          type={type}
          isLoading={isLoading}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="vendor"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Vendor;
