import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  FetchVendorFailurePayload,
  FetchVendorRequestPayload,
  FetchVendorSuccessPayload,
  GetLocationRequestPayload,
  GetLocationSuccessPayload,
  GetLocationFailurePayload,
  PushVendorFailurePayload,
  PushVendorRequestPayload,
  PushVendorSuccessPayload,
  PutLocationRequestPayload,
  PutLocationSuccessPayload,
  PutLocationFailurePayload,
  DeleteLocationRequestPayload,
  DeleteLocationSuccessPayload,
  DeleteLocationFailurePayload,
  GetLocationsRequestPayload,
  GetLocationsSuccessPayload,
  GetLocationsFailurePayload,
  RouterVendorRequestPayload,
  PushLocationRequestPayload,
  PushLocationSuccessPayload,
  PushLocationFailurePayload,
} from "../actionPayload/vendorPayloads";
import { AppState } from "./rootReducer";
import { IVendor } from "../../models/vendor/IVendor";
import { ILocation } from "../../models/vendor/ILocation";

const vendorAdapter = createEntityAdapter<IVendor>({
  selectId: (vendor) => vendor.id,
});

const locationsAdapter = createEntityAdapter<ILocation>({
  selectId: (location) => location.id,
});

interface IVendorState {
  pending: boolean;
  vendor?: IVendor;
  locations: EntityState<ILocation>;
  error: string;
}

const INITIAL_STATE = vendorAdapter.getInitialState<IVendorState>({
  pending: false,
  locations: locationsAdapter.getInitialState({}),
  error: "",
});

const vendorSlice = createSlice({
  name: "vendor",
  initialState: INITIAL_STATE,
  reducers: {
    resetVendorRequest() {
      return INITIAL_STATE;
    },
    fetchVendorRequest(
      state,
      action: PayloadAction<FetchVendorRequestPayload>
    ) {
      state.pending = true;
    },
    fetchVendorSuccess(
      state,
      action: PayloadAction<FetchVendorSuccessPayload>
    ) {
      state.pending = false;
      state.vendor = action.payload.vendor;
      //  vendorAdapter.upsertOne(state, action.payload.vendor);
    },
    fetchVendorError(state, action: PayloadAction<FetchVendorFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    pushVendorRequest(state, action: PayloadAction<PushVendorRequestPayload>) {
      state.pending = true;
    },
    pushVendorSuccess(state, action: PayloadAction<PushVendorSuccessPayload>) {
      state.pending = false;
      state.vendor = action.payload.vendor;
    },
    pushVendorError(state, action: PayloadAction<PushVendorFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getLocationsRequest(
      state,
      action: PayloadAction<GetLocationsRequestPayload>
    ) {
      state.pending = true;
    },
    getLocationsSuccess(
      state,
      action: PayloadAction<GetLocationsSuccessPayload>
    ) {
      state.pending = false;
      locationsAdapter.upsertMany(state.locations, action.payload.locations);
    },
    getLocationsFailure(
      state,
      action: PayloadAction<GetLocationsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getLocationRequest(
      state,
      action: PayloadAction<GetLocationRequestPayload>
    ) {
      state.pending = true;
    },
    getLocationSuccess(
      state,
      action: PayloadAction<GetLocationSuccessPayload>
    ) {
      state.pending = false;
      locationsAdapter.upsertOne(state.locations, action.payload.location);
    },
    getLocationFailure(
      state,
      action: PayloadAction<GetLocationFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    putLocationRequest(
      state,
      action: PayloadAction<PutLocationRequestPayload>
    ) {
      state.pending = true;
    },
    putLocationSuccess(
      state,
      action: PayloadAction<PutLocationSuccessPayload>
    ) {
      state.pending = false;
      locationsAdapter.updateOne(state.locations, {
        id: action.payload.locationId,
        changes: action.payload.location,
      });
    },
    putLocationFailure(
      state,
      action: PayloadAction<PutLocationFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    pushLocationRequest(
      state,
      action: PayloadAction<PushLocationRequestPayload>
    ) {
      state.pending = true;
    },
    pushLocationSuccess(
      state,
      action: PayloadAction<PushLocationSuccessPayload>
    ) {
      state.pending = false;
      locationsAdapter.upsertOne(state.locations, action.payload.location)
    },
    pushLocationFailure(
      state,
      action: PayloadAction<PushLocationFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    deleteLocationRequest(
      state,
      action: PayloadAction<DeleteLocationRequestPayload>
    ) {
      state.pending = true;
    },
    deleteLocationSuccess(
      state,
      action: PayloadAction<DeleteLocationSuccessPayload>
    ) {
      state.pending = false;
      locationsAdapter.removeAll(state.locations);
    },
    deleteLocationFailure(
      state,
      action: PayloadAction<DeleteLocationFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeLocationsDataRequest(state) {
      state.pending = true;
      locationsAdapter.removeAll(state.locations);
    },
    routerRedirectVendorRequest(
      state,
      action: PayloadAction<RouterVendorRequestPayload>
    ) {},
  },
});

export const {
  selectById: getVendorById,
} = vendorAdapter.getSelectors<AppState>((state) => state.vendors);

export const {
  selectAll: getLocations,
  selectById: getLocationById,
} = locationsAdapter.getSelectors<AppState>((state) => state.vendors.locations);

export const {
  resetVendorRequest,
  fetchVendorRequest,
  fetchVendorSuccess,
  fetchVendorError,
  pushVendorRequest,
  pushVendorSuccess,
  pushVendorError,
  getLocationsRequest,
  getLocationsSuccess,
  getLocationsFailure,
  getLocationRequest,
  getLocationSuccess,
  getLocationFailure,
  putLocationRequest,
  putLocationSuccess,
  putLocationFailure,
  pushLocationRequest,
  pushLocationSuccess,
  pushLocationFailure,
  deleteLocationRequest,
  deleteLocationSuccess,
  deleteLocationFailure,
  changeLocationsDataRequest,
  routerRedirectVendorRequest,
} = vendorSlice.actions;

export default vendorSlice.reducer;
