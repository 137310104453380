import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { customerApi } from "../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteCustomer = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteCustomer"],
    ({ id }: { id: number }) =>
      customerApi.customersControllerDelete({ id: id }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteCustomerSuccess`));
        queryClient.refetchQueries(["customers"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteCustomerFailure`));
      },
    }
  );
};
