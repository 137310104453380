import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IExtras } from "../../models/extras/IExtras";
import {
  DeleteExtrasFailurePayload,
  DeleteExtrasRequestPayload,
  DeleteExtrasSuccessPayload,
  GetExtraRequestPayload,
  GetExtraSuccessPayload,
  GetExtraFailurePayload,
  GetExtrasRequestPayload,
  GetExtrasSuccessPayload,
  GetExtrasFailurePayload,
  RouterRedirectExtrasPayload,
  PutExtraRequestPayload,
  PutExtraSuccessPayload,
  PutExtraFailurePayload,
  PostExtraRequestPayload,
  PostExtraSuccessPayload,
  PostExtraFailurePayload,
} from "../actionPayload/extrasPayloads";
import { AppState } from "./rootReducer";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IExtrasState {
  pending: boolean;
  error: string;
  count: number;
  page: EntityState<IPage>;
}

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const extrasAdapter = createEntityAdapter<IExtras>({
  selectId: (extras) => extras.id!,
});

const INITIAL_STATE = extrasAdapter.getInitialState<IExtrasState>({
  pending: false,
  error: "",
  count: 0,
  page: pageAdapter.getInitialState({}),
})

const extrasSlice = createSlice({
  name: "extras",
  initialState: INITIAL_STATE,
  reducers: {
    resetExtrasRequest(){
      return INITIAL_STATE;
    },
    getExtrasRequest(state, action: PayloadAction<GetExtrasRequestPayload>) {
      state.pending = true;
    },
    getExtrasSuccess(state, action: PayloadAction<GetExtrasSuccessPayload>) {
      state.pending = false;
      state.count = action.payload.count;
      extrasAdapter.removeAll(state);
      pageAdapter.removeAll(state.page);
      extrasAdapter.upsertMany(state, action.payload.extras);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.extras.map((extra) => extra.id!)],
      });
    },
    getExtrasFailure(state, action: PayloadAction<GetExtrasFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getExtraRequest(state, action: PayloadAction<GetExtraRequestPayload>) {
      state.pending = true;
    },
    getExtraSuccess(state, action: PayloadAction<GetExtraSuccessPayload>) {
      state.pending = false;
      extrasAdapter.upsertOne(state, action.payload.extra);
    },
    getExtraFailure(state, action: PayloadAction<GetExtraFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    deleteExtrasRequest(state, action: PayloadAction<DeleteExtrasRequestPayload>) {
      state.pending = true;
    },
    deleteExtrasSuccess(state, action: PayloadAction<DeleteExtrasSuccessPayload>) {
      state.pending = false;
      extrasAdapter.removeOne(state, action.payload.extrasId);
      pageAdapter.removeAll(state.page);
    },
    deleteExtrasFailure(state, action: PayloadAction<DeleteExtrasFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    },
    routerRedirectExtras(
      state,
      action: PayloadAction<RouterRedirectExtrasPayload>
    ) {},
    putExtraRequest(
      state,
      action: PayloadAction<PutExtraRequestPayload>
    ) {
      state.pending = true;
    },
    putExtraSuccess(
      state,
      action: PayloadAction<PutExtraSuccessPayload>
    ) {
      state.pending = false;
      extrasAdapter.removeAll(state);
      pageAdapter.removeAll(state.page);
    },
    putExtraFailure(
      state,
      action: PayloadAction<PutExtraFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    postExtraRequest(
      state,
      action: PayloadAction<PostExtraRequestPayload>
    ) {
      state.pending = true;
    },
    postExtraSuccess(
      state,
      action: PayloadAction<PostExtraSuccessPayload>
    ) {
      state.pending = false;
      extrasAdapter.removeAll(state);
      pageAdapter.removeAll(state.page);
    },
    postExtraFailure(
      state,
      action: PayloadAction<PostExtraFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },

  },
});

export const {
  selectAll: getAllExtrasPages,
  selectById: getExtrasPages,
} = pageAdapter.getSelectors<AppState>((state) => state.extras.page);

export const {
  selectAll: getAllExtras,
  selectById: getExtrasById,
  selectTotal: getExtrasTotal,
} = extrasAdapter.getSelectors<AppState>((state) => state.extras);

export const {
  resetExtrasRequest,
  getExtrasRequest,
  getExtrasSuccess,
  getExtrasFailure,
  getExtraRequest,
  getExtraSuccess,
  getExtraFailure,
  deleteExtrasRequest,
  deleteExtrasSuccess,
  deleteExtrasFailure,
  changeDataRequest,
  routerRedirectExtras,
  putExtraRequest,
  putExtraSuccess,
  putExtraFailure,
  postExtraRequest,
  postExtraSuccess,
  postExtraFailure
} = extrasSlice.actions;

export default extrasSlice.reducer;
