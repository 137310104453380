import { MRT_ColumnFiltersState } from "material-react-table";
import { create } from "zustand";

interface FiltersForCSVStore {
  columnFilters: MRT_ColumnFiltersState;
  setColumnFiltersStore: (columnFilters: MRT_ColumnFiltersState) => void;
}

export const useFiltersForCSVStore = create<FiltersForCSVStore>((set) => {
  return {
    columnFilters: [],
    setColumnFiltersStore: (columnFilters: MRT_ColumnFiltersState) =>
      set((state) => ({
        ...state,
        columnFilters,
      })),
  };
});
