import { Box } from "@mui/material";
import logo_sbl from "./../../assets/logo_sbl.png";

const Loading = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "grid",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <a href="/">
        <img src={logo_sbl} alt={"logo_sbl"} style={{ border: "0" }} />
      </a>
    </Box>
  );
};

export default Loading;
