import { IBeachChairFormData } from "../../models/beachChairs/IBeachChairFormData";

export const beachChairToFormData = (beachChair: any): IBeachChairFormData => {
  return {
    publicNumber: beachChair.publicNumber,
    buyingDate: beachChair.buyingDate,
    sectionId: beachChair.section
      ? beachChair.section.id
      : beachChair.sectionId,
    rowId: beachChair.row ? beachChair.row.id : beachChair.rowId,
    geoJson: beachChair.geoJson.coordinates
      ? `${beachChair.geoJson.coordinates[0]}, ${beachChair.geoJson.coordinates[1]}`
      : "0, 0",
    status: beachChair.status,
    lockId: beachChair.lock ? beachChair.lock.id : beachChair.lockId,
    model: beachChair.model,
    description: beachChair.description,
    internalNote: beachChair.internalNote,
    afterHourBooking: beachChair.afterHourBooking,
    onlineBooking: beachChair.onlineBooking,
    seasonBeachChair: beachChair.seasonBeachChair,
    images: beachChair.images,
    stopSaleDates: beachChair.stopSaleDates,
    attributes: {
      color: beachChair.attributes.color,
    },
    details: {
      extraInformation: beachChair.details?.extraInformation,
    },
  };
};
