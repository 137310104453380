import { bookingToRow } from "../../utils/conversions/bookingToRow";
import { createSelector } from "reselect";
import { IFilterData } from "../../models/dataTable/IFilterData";
import {
  getAllBookings,
  getBookingById,
  getBookingPages,
  getBookingBrachChairById,
  getBeachChairBookingPages,
  getCustomerBookingPages,
} from "../reducers/bookingsReducer";

import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.bookings.pending;

const getInstantBookingTabSet = (state: AppState) =>
  state.bookings.instantBookingTabSet;

const getBookingTab = (state: AppState) => state.bookings.bookingTab;

const getInstantBookingPending = (state: AppState) =>
  state.bookings.instantBookingPending;

const getCSVData = (state: AppState) => state.bookings.csvData;

const getBookingsCount = (state: AppState) => state.bookings.count;

const getIsExistingCustomer = (state: AppState) =>
  state.bookings.isExistingCustomer;

const getFilterData = (state: AppState) => state.bookings.filterData;

const getError = (state: AppState) => state.bookings.error;

const getRemoveBookingBeachChairRightDrawer = (state: AppState) =>
  state.bookings.removeBookingBeachChairRightDrawer;

const getInstantBookingCurrentDate = (state: AppState) =>
  state.bookings.instantBookingCurrentDate;

export const getInstantBookingCurrentDateSelector = createSelector(
  getInstantBookingCurrentDate,
  (date) => date
);

export const getInstantBookingTabSetSelector = createSelector(
  getInstantBookingTabSet,
  (tab) => tab
);

export const getBookingTabSelector = createSelector(
  getBookingTab,
  (tab) => tab
);

export const getInstantBookingPendingSelector = createSelector(
  getInstantBookingPending,
  (pending) => pending
);

export const getBookingsSelector = createSelector(
  getAllBookings,
  (bookings) => bookings
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getCSVDataSelector = createSelector(
  getCSVData,
  (csvData) => csvData
);

export const getFilterDataSelector = createSelector(
  getFilterData,
  (filterData) => filterData
);

export const getIsExistingCustomerSelector = createSelector(
  getIsExistingCustomer,
  (isExistingCustomer) => isExistingCustomer
);

export const getBookingsCountSelector = createSelector(
  getBookingsCount,
  (count) => count
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getBookingSelector = (id: number) =>
  createSelector(getAllBookings, (bookings) =>
    bookings.filter((booking) => booking.id === id)
  );

export const getBookingByIdSelector = (id: number) =>
  createSelector(getState, (state) => getBookingById(state, id));

export const getExtrasByIdSelector = (bookingId: number, id: number) =>
  createSelector(getState, (state) => {
    const booking = getBookingById(state, bookingId);
    return (
      booking &&
      booking.extras &&
      booking.extras.find((extra) => extra.id === id)
    );
  });

export const getBookingBrachChairByIdSelector = (id: number) =>
  createSelector(getState, (state) => getBookingBrachChairById(state, id));

export const getAllBookingList = createSelector(getState, (state) =>
  getAllBookings(state)
);

export const getBookingPageItems = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) =>
    getBookingPages(state, `${pageNumber}:${itemsPerPage}`)
  );

export const getBookingPage = (pageNumber: number, itemsPerPage: number) =>
  createSelector(
    getBookingPageItems(pageNumber, itemsPerPage),
    getAllBookings,
    (page, bookings) =>
      page?.dataIds.flatMap((id) =>
        bookings.filter((booking) => booking.id === id).flatMap(bookingToRow)
      )
  );

export const getBookingPageSelector = (
  pageNumber: number,
  itemsPerPage: number
) =>
  createSelector(
    getBookingPage(pageNumber, itemsPerPage),
    (collections) => collections && Object.values(collections)
  );

export const getBeachChairBookingPage = (
  pageNumber: number,
  itemsPerPage: number,
  id: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getBeachChairBookingPages(
      state,
      `${id}:${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds.map((id) => getBookingById(state, id)!).map(bookingToRow)
    );
  });

export const getCustomerBookingPage = (
  pageNumber: number,
  itemsPerPage: number,
  id: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getCustomerBookingPages(
      state,
      `${id}:${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds.map((id) => getBookingById(state, id)!).map(bookingToRow)
    );
  });

export const getChoosedBookingBeachChairSelector = (
  bookingId: number,
  selectedBookingBeachChair: number
) =>
  createSelector(getState, (state) => {
    const booking = getBookingById(state, bookingId);
    return booking?.beachChairBookings.find(
      (beachChair) => beachChair.id === selectedBookingBeachChair
    );
  });

export const getRemoveBookingBeachChairRightDrawerSelector = createSelector(
  getRemoveBookingBeachChairRightDrawer,
  (removeBookingBeachChairRightDrawer) => removeBookingBeachChairRightDrawer
);
