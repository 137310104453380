import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import SubPageHeader from "../subPageHeader/subPageHeader";
import SBBreadcrumbsWrapper from "../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../SBComponents/SBLayout/SBHeaderTypography";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import { Add } from "@mui/icons-material";
import SBHeaderActionButtonsWrapper from "../SBComponents/SBForms/SBHeaderActionButtonsWrapper";

const PricesHeader = () => {
  const { t } = useTranslation(["common"]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <SubPageHeader headerTitle={t("prices")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {id && (
          <SBHeaderLink
            label={t("prices")}
            onClick={() => {
              history.push(`/prices`);
            }}
          />
        )}
        {id && (
          <SBHeaderLink
            label={id}
            onClick={() => {
              history.push(`/prices/${id}/show`);
            }}
          />
        )}
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {!location.pathname.includes(`/prices/`) && (
          <SBLoadingButton
            label={t("newPrice")}
            type="blue"
            icon={<Add />}
            onClick={() => {
              history.push(`/prices/new`);
            }}
          />
        )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default PricesHeader;
