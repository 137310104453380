import API from "./api";

const BASE_URL = "/features/users";

export const postAdminContextVendor = (vendorId: number) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post(`${BASE_URL}/context/vendor`, { vendorId }, config);
};

export const getAdminToken = () => {
  return API.get(`${BASE_URL}/admin/token`);
};
