import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingDetailsResponseDto,
  FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest,
  UpdateBeachChairBookingBodyDto,
} from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";

interface Payload
  extends Omit<
    FeaturesApiBookingDetailsControllerUpdateBeachChairBookingRequest,
    "updateBeachChairBookingBodyDto"
  > {
  data: UpdateBeachChairBookingBodyDto;
}

interface UseUpdateBeachChairBooking {
  updateBeachChairBooking: UseMutateFunction<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >;
  isLoading: boolean;
}

export const useUpdateBeachChairBooking = (): UseUpdateBeachChairBooking => {
  const queryClient = useQueryClient();
  const { mutate: updateBeachChairBooking, isLoading } = useMutation<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >(
    ["updateBeachChairBooking"],
    ({ id, data, timeZone }: Payload) =>
      featuresApi.bookingDetailsControllerUpdateBeachChairBooking({
        id,
        timeZone,
        updateBeachChairBookingBodyDto: data,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );

  return {
    updateBeachChairBooking,
    isLoading,
  };
};
