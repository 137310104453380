import { Box, InputLabel } from "@mui/material";
import {
  SBLabelGridStyle,
  SBLabelStyle,
  SBTextFieldIconStyle,
} from "../styles/SBStyles";

export interface SBLabelPrimary {
  label: React.ReactNode;
  icon?: React.ReactNode;
}

export type SBLabelType = SBLabelPrimary;

const SBLabel: React.FC<SBLabelType> = ({ icon, label }) => {
  return (
    <Box sx={{ ...SBLabelGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
      </Box>
    </Box>
  );
};

export default SBLabel;
