import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingDetailsResponseDto,
  FeaturesApiBookingDetailsControllerReserveBookingRequest as Payload,
} from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";

interface UsePayForBooking {
  reserveBooking: UseMutateFunction<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >;
  isLoading: boolean;
}

export const useReserveBooking = (): UsePayForBooking => {
  const queryClient = useQueryClient();
  const { clearBookingInProgress } = useBookingInProgressStore();
  const { mutate: reserveBooking, isLoading } = useMutation<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >({
    mutationFn: (variables: Payload) =>
      featuresApi.bookingDetailsControllerReserveBooking(variables),
    onSuccess: () => {
      queryClient.refetchQueries(["bookingList"]);
      clearBookingInProgress();
    },
  });

  return {
    reserveBooking,
    isLoading,
  };
};
