import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import AddIcon from "@mui/icons-material/Add";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

const ShortUrlsHeader = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <SubPageHeader headerTitle={t("adminConfiguration")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {!id ? (
          <SBHeaderTypography label={t("configurationMenu.shortUrls")} />
        ) : (
          <SBHeaderLink
            label={t("configurationMenu.shortUrls")}
            onClick={() => {
              history.push(`/shortUrls`);
            }}
          />
        )}
        {id && <SBHeaderTypography label={t("overview")} />}
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {location.pathname.includes("shortUrls") && (
          <SBLoadingButton
            label={t("destinations.new.shortUrl")}
            type="blue"
            icon={<AddIcon />}
            onClick={() => history.push("/shortUrls/new")}
            disabled={location.pathname.includes(`shortUrls/new`)}
          />
        )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default ShortUrlsHeader;
