import React from "react";
import { Redirect } from "react-router-dom";
import { useAdminChangeVendor } from "../../../utils/customHooks/useAdminChangeVendor";
import { useGetVendor } from "../../vendor";
import CashPointReportsTable from "./CashPointReportsTable";
import CashPointReportsHeader from "./CashPointReportsHeader";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";

export const CashPointReports: React.FC = () => {
  const { vendorId } = useAdminChangeVendor();
  const { data: vendor, isFetched } = useGetVendor(vendorId);

  const hasAccess = vendor?.configuration?.hasTSE;

  if (isFetched && !hasAccess) {
    return <Redirect to="/no-access" />;
  }

  return (
    <SBMainListWrapper>
      <CashPointReportsHeader />
      <CashPointReportsTable />
    </SBMainListWrapper>
  );
};
