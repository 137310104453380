import { useForm } from "react-hook-form";
import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CustomerResponseDto,
  UpdateCustomerBodyDto,
  CreateCustomerBodyDto,
} from "../../../api-client/generated";
import { useTranslation } from "react-i18next";
import * as ibantools from "ibantools";
import { useEffect } from "react";

export type CustomerFormValues = UpdateCustomerBodyDto | CreateCustomerBodyDto;

export const useCustomerForm = (customerData?: CustomerResponseDto) => {
  const { t } = useTranslation(["common"]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        firstName: yup.string(),
        lastName: yup.string(),
        salutation: yup.string(),
        address: yup.object({
          zip: yup.string(),
          city: yup.string(),
          street: yup.string(),
          country: yup.string(),
          additionalInfo: yup.string(),
        }),
        telephone: yup.string(),
        email: yup.string().required(t("email") + t("isMandatory")),
        default: yup.boolean().nullable(true),
        bankDetails: yup
          .object({
            iban: yup
              .string()
              .test("isIban", t("shouldBeIBAN"), (iban) => {
                return iban ? ibantools.isValidIBAN(iban || "") : true;
              })
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            bic: yup
              .string()
              .test("isBic", t("shouldBeBic"), (bic) => {
                return bic ? ibantools.isValidBIC(bic || "") : true;
              })
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            accountHolder: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            bankName: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
          })
          .nullable(true),
      }),
    [t]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount, isValid, isDirty },
    reset,
    watch,
    setValue,
  } = useForm<CustomerFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: customerData?.firstName || "",
      lastName: customerData?.lastName || "",
      salutation: customerData?.salutation || "MR",
      telephone: customerData?.telephone || "",
      email: customerData?.email || "",
      address: {
        city: customerData?.address?.city || "",
        country: customerData?.address?.country || "",
        street: customerData?.address?.street || "",
        zip: customerData?.address?.zip || "",
        additionalInfo: customerData?.address?.additionalInfo || "",
      },
      default: customerData?.default || false,
      bankDetails: {
        iban: customerData?.bankDetails?.iban || "",
        bic: customerData?.bankDetails?.bic || "",
        accountHolder: customerData?.bankDetails?.accountHolder || "",
        bankName: customerData?.bankDetails?.bankName || "",
      },
    },
  });

  useEffect(() => {
    if (customerData) {
      setValue("salutation", customerData?.salutation || "MR");
      setValue("firstName", customerData?.firstName || "");
      setValue("lastName", customerData?.lastName || "");
      setValue("telephone", customerData?.telephone || "");
      setValue("email", customerData?.email || "");
      setValue("address.city", customerData?.address?.city || "");
      setValue("address.country", customerData?.address?.country || "");
      setValue("address.street", customerData?.address?.street || "");
      setValue("address.zip", customerData?.address?.zip || "");
      setValue(
        "address.additionalInfo",
        customerData?.address?.additionalInfo || ""
      );
      setValue("default", customerData?.default || false);
      setValue("bankDetails.iban", customerData.bankDetails?.iban || "");
      setValue("bankDetails.bic", customerData.bankDetails?.bic || "");
      setValue(
        "bankDetails.accountHolder",
        customerData.bankDetails?.accountHolder || ""
      );
      setValue(
        "bankDetails.bankName",
        customerData.bankDetails?.bankName || ""
      );
    }
  }, [customerData, reset]);

  return {
    control,
    reset,
    errors,
    handleSubmit,
    watch,
    submitCount,
    isValid,
    isDirty,
    setValue,
  };
};
