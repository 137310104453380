import { Action } from "redux";
import { all, call, takeLatest } from "typed-redux-saga";
import { setLocale } from "../reducers/localesReducer";
import i18n from "../../utils/i18n/i18n";

function* setLocaleSaga(action: Action) {
  try {
    if (setLocale.match(action)) {
      yield* call(i18n.changeLanguage.bind(i18n), action.payload.shortCode);
      yield* call(localStorage.setItem.bind(localStorage), 'lc', action.payload.shortCode);
    }
  } catch (e) {
    yield* call(
      console.log.bind(console), e
    )
  }
}

function* localesSaga() {
  yield* all([
    takeLatest(setLocale.type, setLocaleSaga)
  ]);
}

export default localesSaga;