import { Breadcrumbs } from "@mui/material";
import { SBBreadcrumbStyle } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBBreadcrumbsWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Breadcrumbs
      aria-label="Breadcrumb"
      data-testid="location"
      sx={{ ...SBBreadcrumbStyle }}
    >
      {children}
    </Breadcrumbs>
  );
};

export default SBBreadcrumbsWrapper;
