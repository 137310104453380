import { create } from "zustand";
import dayjs, { Dayjs } from "dayjs";

interface InstantBookingCurrentDatesStore {
  instantBookingCurrentDate: Dayjs;
  setInstantBookingCurrentDate: (instantBookingCurrentDate: Dayjs) => void;
}

export const useInstantBookingCurrentDatesStore =
  create<InstantBookingCurrentDatesStore>((set) => {
    return {
      instantBookingCurrentDate: dayjs(new Date()),
      setInstantBookingCurrentDate: (instantBookingCurrentDate: Dayjs) =>
        set((state) => ({
          ...state,
          instantBookingCurrentDate,
        })),
    };
  });
