import { IBooking } from "../models/bookings/IBooking";
import { CancelBookingRequestPayload } from "../store/actionPayload/bookingPayloads";
import API from "./api";

const FEATURE_BASE_URL = `/features/booking_details`;
const BASE_URL = `/bookings/bookings`;

const language = "de";
const timeZone = "Europe/Berlin";

export interface IBookingsRequest {
  count: number;
  items: IBooking[];
}

export const getBookings = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string,
  vendorId?: number,
  csv?: boolean
) =>
  API.get<IBookingsRequest>(
    `${FEATURE_BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      csv ? `&csv=${csv}` : ``
    }${vendorId ? `&filters[vendorId]=${vendorId}` : ``}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getCSVBookings = (page = 0, filter: string, csv: boolean) =>
  API.get(
    `${FEATURE_BASE_URL}?page=${page}${csv ? `&csv=${csv}` : ``}${
      filter ? `&${filter}` : ``
    }`
  );

export const getBooking = (bookingId: number) =>
  API.get<IBooking>(`${FEATURE_BASE_URL}/${bookingId}`);

export const patchBooking = (bookingId: number, data: any) =>
  API.patch(`${BASE_URL}/${bookingId}`, data);

export const addBeachChairToBooking = (bookingId: number, data: any) =>
  API.post(`${FEATURE_BASE_URL}/${bookingId}/beach_chair_bookings`, data);

export const addExtrasToBooking = (bookingId: number, data: any) =>
  API.post(`${BASE_URL}/${bookingId}/extras`, data);

export const postBookingCustomer = (bookingId: number, data: { id: number }) =>
  API.post(`${BASE_URL}/${bookingId}/customer`, data);

export const deleteBookingBeachChair = (
  bookingId: number,
  beachChairId: number
) => API.delete(`${BASE_URL}/${bookingId}/beach_chair_booking/${beachChairId}`);

export const deleteBooking = (bookingId: number) =>
  API.delete(`${BASE_URL}/${bookingId}`);

export const putBookingConfirm = (bookingId: number) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.put<IBooking>(
    `${FEATURE_BASE_URL}/${bookingId}/confirm/?timeZone=${timeZone}`,
    {},
    config
  );
};

export const putBookingReserve = (bookingId: number) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.put<IBooking>(
    `${FEATURE_BASE_URL}/${bookingId}/reserve/?timeZone=${timeZone}`,
    {},
    config
  );
};

export const checkoutSessionEmail = (bookingRef: string, email: string) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.post(
    `/payments/stripe/checkout-session?email=${encodeURIComponent(
      email
    )}&bookingRef=${bookingRef}&timeZone=${timeZone}&flow=EMAIL`,
    {},
    config
  );
};

export const putBookingPay = (bookingId: number) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.put<IBooking>(
    `${FEATURE_BASE_URL}/${bookingId}/pay/?timeZone=${timeZone}`,
    {},
    config
  );
};

export const putBookingBeachChair = (beachChair: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IBooking>(
    `${FEATURE_BASE_URL}/beach_chair_bookings/${beachChair}`,
    data,
    config
  );
};

export const createNewBooking = () => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IBooking>(`${BASE_URL}`, {}, config);
};

export const searchBookings = (searchPhrase: string) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.get<IBooking[]>(`${BASE_URL}?search=${searchPhrase}`, config);
};

export const cancelBooking = ({
  bookingId,
  cancellationFee,
  skipRefund,
}: CancelBookingRequestPayload) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };

  const params = new URLSearchParams();
  params.append("timeZone", timeZone);

  if (typeof cancellationFee === "number" && cancellationFee >= 0) {
    params.append("cancellationFee", cancellationFee.toString());
  }

  if (skipRefund) {
    params.append("skipRefund", skipRefund.toString());
  }

  return API.put<IBooking>(
    `${FEATURE_BASE_URL}/${bookingId}/cancel?${params.toString()}`,
    {},
    config
  );
};

export const putBookingExtrasAmount = (
  bookingId: number,
  extrasBookingId: number,
  amount: number
) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.put<IBooking>(
    `${BASE_URL}/${bookingId}/extras/${extrasBookingId}`,
    { amount: amount },
    config
  );
};

export const deleteBookingExtras = (
  bookingId: number,
  extrasBookingId: number
) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.delete<IBooking>(
    `${BASE_URL}/${bookingId}/extras/${extrasBookingId}`,
    config
  );
};

export const changeBeachChairBooking = (
  previousBeachChairId: number,
  beachChairId: number,
  price: number
) => {
  const data = {
    beachChairId: beachChairId,
    price: price,
  };
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.put<IBooking>(
    `features/booking_details/beach_chair_bookings/${previousBeachChairId}`,
    data,
    config
  );
};

export const removeBeachChairBooking = (id: number, bookingkId: number) => {
  const config = {
    headers: { "Content-Type": "application/json", "x-custom-lang": language },
  };
  return API.delete(
    `bookings/bookings/${bookingkId}/beach_chair_confirmed/${id}`,
    config
  );
};
