import { useQuery } from "@tanstack/react-query";
import { extrasApi } from "../../../api/apiTanStack";

export const useGetExtra = (id: number) =>
  useQuery({
    queryKey: ["extra", Number(id)],
    queryFn: async () => {
      return extrasApi
        .externalServicesControllerFindOne({
          id,
        })
        .then((response) => response.data);
    },
    enabled: !isNaN(id),
    cacheTime: Infinity,
    keepPreviousData: true,
  });
