import { useMutation } from "@tanstack/react-query";
import { bookingsApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";
import { useAddExtrasToBooking } from "../../../extras/hooks";

export enum MetaDataSource {
  WEBSITE = "WEBSITE",
  PARTNER = "PARTNER",
  /** @deprecated */
  APP = "APP",
  WIDGET = "WIDGET",
  BACK_OFFICE = "BACK_OFFICE",
  VENDOR_APP = "VENDOR_APP",
  CUSTOMER_APP = "CUSTOMER_APP",
  NOT_DEFINED = "NOT_DEFINED",
}

export const useCreateBooking = () => {
  return useMutation({
    mutationFn: () =>
      bookingsApi.bookingsControllerCreate({
        source: MetaDataSource.BACK_OFFICE,
      }),
  });
};

export const useCreateBookingAddBeachChair = () => {
  return useMutation({
    mutationFn: () =>
      bookingsApi.bookingsControllerCreate({
        source: MetaDataSource.BACK_OFFICE,
      }),
  });
};

export const useCreateBookingAddExtra = () => {
  const addExtrasToBooking = useAddExtrasToBooking();
  const { setBookingInProgress } = useBookingInProgressStore();
  return useMutation(
    ["createBookingAddExtra"],
    (data: { isContinue: boolean; amount: number; extraId: number }) =>
      bookingsApi
        .bookingsControllerCreate({ source: MetaDataSource.BACK_OFFICE })
        .then((response) => response.data),
    {
      onSuccess: (data, variables) => {
        setBookingInProgress(data);
        addExtrasToBooking.mutateAsync({
          amount: variables.amount,
          extraId: variables.extraId,
          id: data.id,
          isContinue: variables.isContinue,
        });
      },
    }
  );
};
