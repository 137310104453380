import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChangeMenuStateRequestPayload,
  RWDFailurePayload,
} from "../actionPayload/rwdPayloads";

interface IRWDState {
  toggle: boolean;
  error: string;
}

const INITIAL_STATE: IRWDState = {
  toggle: localStorage.getItem("menuExpanded") === null ? true : localStorage.getItem("menuExpanded") === "true",
  error: "",
};

const rwdSlice = createSlice({
  name: "rwd",
  initialState: INITIAL_STATE,
  reducers: {
    changeMenuStateRequest(
      state,
      action: PayloadAction<ChangeMenuStateRequestPayload>
    ) {
      state.toggle = action.payload.toggle;
      localStorage.setItem("menuExpanded", JSON.stringify(action.payload.toggle));
    },
    rwdFailure(state, action: PayloadAction<RWDFailurePayload>) {
      state.error = action.payload.error;
    },
  },
});

export const { changeMenuStateRequest, rwdFailure } = rwdSlice.actions;

export default rwdSlice.reducer;
