import React, { useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel, Save } from "@mui/icons-material";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import { SBTextFieldStyle } from "../SBComponents/styles/SBStyles";

export interface IBulkProps {
  open: boolean;
  onClose: (id?: number | string) => void;
  onClick: () => void;
  count: number;
}

const BulkSaveDialog = ({ open, onClose, count, onClick }: IBulkProps) => {
  const { t } = useTranslation(["common"]);
  const [check, setCheck] = useState("");
  return (
    <I18nextProvider i18n={i18n}>
      <Dialog disableEscapeKeyDown open={open}>
        <DialogTitle>{t(`bulkSave`)}</DialogTitle>
        <DialogContent
          dividers
          sx={{
            height: "140px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t(`bulkSaveText`, { count })}
          <Box sx={{ padding: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {t(`saveBulkCheck`)}
            </Typography>
            <TextField
              variant="outlined"
              label=""
              sx={{
                ...SBTextFieldStyle,
              }}
              fullWidth
              value={check}
              onChange={(a) => {
                setCheck(a.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <SBLoadingButton
            onClick={() => {
              onClose();
            }}
            label={t("cancel")}
            type="orange"
            icon={<Cancel />}
          />
          <SBLoadingButton
            disabled={check !== "SPEICHERN"}
            onClick={() => {
              onClick();
            }}
            label={t("save")}
            type="blue"
            icon={<Save />}
          />
        </DialogActions>
      </Dialog>
    </I18nextProvider>
  );
};

export default BulkSaveDialog;
