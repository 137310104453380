import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeaturesApiBookingDetailsControllerCancelBookingRequest as Variables } from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";

export function useCancelBooking() {
  const queryClient = useQueryClient();
  const { mutate: cancelBooking, isLoading } = useMutation(
    (variables: Variables) =>
      featuresApi.bookingDetailsControllerCancelBooking(variables),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["bookingList"]);
      },
    }
  );

  return {
    cancelBooking,
    isLoading,
  };
}
