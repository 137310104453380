import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { postRowRequest } from "../../store/reducers/destinationReducer";

export function useRowForm(sectionId: number, type: string) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        sectionId: yup
          .number()
          .required(t("destinations.sectionId") + t("isMandatory")),
        name: yup.string().required(t("destinations.name") + t("isMandatory")),
        description: yup.string().nullable(true),
        geoJson: yup.string(),
      }),
    [t]
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("sectionId", sectionId);
    setValue("description", "");
    setValue("geoJson", `${JSON.stringify({})}`);
    // eslint-disable-next-line
  }, [sectionId, type]);

  const handleReset = useCallback(() => {
    setValue("sectionId", sectionId);
    setValue("name", "");
    setValue("description", "");
    setValue("geoJson", `${JSON.stringify({})}`);
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(
    (formValues) => {
      formValues.geoJson = JSON.parse(formValues.geoJson);
      dispatch(postRowRequest({ formData: formValues }));
      handleReset();
    },
    // eslint-disable-next-line
    []
  );

  return {
    control,
    errors,
    setValue,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
