import AddIcon from "@mui/icons-material/Add";
import {
  Add,
  BeachAccess,
  CalendarToday,
  Euro,
  MenuOpen,
  Remove,
  Schedule,
  ViewModule,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useRef } from "react";
import {
  Autocomplete,
  Box,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  instantBookingsCardTypography,
  vendorBookingsCard,
} from "../../../utils/customStyles/globalStyles";
import {
  SBAutompleteTextFieldStyle,
  SBColor,
  SBDatePickerStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../SBComponents/styles/SBStyles";
import { Controller } from "react-hook-form";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import { useBeachChairsAvailabilities } from "../../availabilities/hooks/useBeachChairsAvailabilities";
import { vendorBookingForm } from "./hooks/vendorBookingForm";
import { useSelectedBeachChairDates } from "../store/useSelectedBeachChairDates";
import {
  DateTimePicker,
  LocalizationProvider,
  deDE,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBNumberField from "../../SBComponents/SBForms/SBNumberField";
import { useBookingInProgressStore } from "../store/useBookingZustand";
import { collator } from "../../../utils/comparator/comparator";
import { useInstantBookingCurrentDatesStore } from "../instantBooking/hooks/useAvailabilitiesDatesStore";

const VendorBookings = () => {
  const { t } = useTranslation(["common"]);
  const { bookingInProgress } = useBookingInProgressStore();
  const { setInstantBookingCurrentDate } = useInstantBookingCurrentDatesStore();
  const {
    control,
    setValue,
    watch,
    onSubmit,
    isDiscountPresent,
    filters,
    filterIsLoading,
    errors,
  } = vendorBookingForm();
  const { dateTime, setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const { data: availableBeachChairs, refetch } = useBeachChairsAvailabilities(
    watch("sectionId"),
    dateTime[0]?.toISOString(),
    dateTime[1]?.toISOString(),
    watch("rowId"),
    watch("model"),
    true,
    false
  );

  const dateTime0Ref = useRef(dateTime[0]);
  const dateTime1Ref = useRef(dateTime[1]);

  const beachChairs = useMemo(() => {
    return availableBeachChairs
      ? availableBeachChairs.length === 0
        ? [
          {
            label: `${t(`noBeachChairAvailable`)}`,
            model: "noModel",
            value: 0,
          },
        ]
        : availableBeachChairs.flatMap((availability) =>
          (availability as any).rowData
            .sort((a, b) => collator.compare(a.publicNumber, b.publicNumber))
            .map((beachChair) => {
              return {
                label: `${beachChair.publicNumber} (${t(
                  `models.${beachChair.model}`
                )})`,
                model: beachChair.model,
                value: beachChair.id,
              };
            })
        )
      : [
        {
          label: `${t(`noBeachChairAvailable`)}`,
          model: "noModel",
          value: 0,
        },
      ];
  }, [availableBeachChairs, t]);
  const selectedSectionId = watch("sectionId");
  const isDisabled = useMemo(() => {
    return watch("id") === 0 || watch("id") === undefined;
  }, [watch("id")]);

  useEffect(() => {
    if (availableBeachChairs) {
      refetch();
    }
  }, [watch("model"), watch("sectionId"), watch("rowId")]);

  const dayDiff = useMemo(() => {
    return (dateTime[1]?.diff(dateTime[0], "day") ?? 0) + 1;
  }, [dateTime]);

  const models = useMemo(() => {
    if (beachChairs.length > 0 && beachChairs[0].value === 0) {
      return [];
    } else {
      const allRowData = beachChairs?.flatMap(item => item.model) ?? [];
      const modelSet = [...new Set(allRowData)];
      if (modelSet.length === 1) {
        setValue("model", modelSet[0]);
      }
      return modelSet;
    }
  }, [beachChairs]);

  return (
    <Card sx={{ ...vendorBookingsCard }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "2px",
          justifyContent: "center",
        }}
      >
        <Typography sx={{ ...instantBookingsCardTypography }}>
          {t(`bookings`)}
        </Typography>
        {bookingInProgress && (
          <Typography
            sx={{ ...instantBookingsCardTypography, color: SBColor.orange }}
          >
            {bookingInProgress?.bookingRef}
          </Typography>
        )}
      </Box>
      {bookingInProgress && (
        <Typography
          sx={{
            ...instantBookingsCardTypography,
            color: SBColor.orange,
            fontSize: "12px",
          }}
        >
          {t(`inProgress`)}
        </Typography>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <Box>
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <Stack>
              <Box
                sx={{
                  ...SBTextFieldGridStyle,
                }}
              >
                <Box sx={{ ...SBTextFieldIconStyle }}>
                  <CalendarToday />
                </Box>
                <Box>
                  <InputLabel sx={{ ...SBLabelStyle }}>
                    {t("bookingStart")}
                  </InputLabel>
                  <DateTimePicker
                    minDate={dayjs(new Date())}
                    value={dateTime[0]}
                    onChange={(newDate) => {
                      if (newDate) {
                        let sameHour = false;
                        if (
                          newDate.isSame(dateTime0Ref.current, "day") &&
                          newDate.isSame(dateTime0Ref.current, "hours") &&
                          newDate.isSame(dateTime0Ref.current, "hours")
                        ) {
                          sameHour = true;
                          dateTime0Ref.current = newDate;
                        }

                        setBookingStartDateTime(
                          sameHour
                            ? newDate
                            : newDate.set("hours", 8).set("minutes", 0),
                          newDate.set("hours", 22).set("minutes", 0)
                        );
                        setInstantBookingCurrentDate(newDate);
                      }
                    }}
                    sx={{
                      ...SBDatePickerStyle,
                      width: "-webkit-fill-available",
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        sx: {
                          ...SBDatePickerStyle,
                          borderColor: "white",
                          width: "-webkit-fill-available",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  ...SBTextFieldGridStyle,
                }}
              >
                <Box sx={{ ...SBTextFieldIconStyle }}>
                  <CalendarToday />
                </Box>
                <Box>
                  <InputLabel sx={{ ...SBLabelStyle }}>
                    {t("bookingEnd")}
                  </InputLabel>
                  <DateTimePicker
                    minDate={dateTime[0]}
                    minTime={dateTime[0]}
                    value={dateTime[1]}
                    onChange={(newDate) => {
                      if (newDate) {
                        let sameHour = false;
                        if (
                          newDate.isSame(dateTime1Ref.current, "day") &&
                          newDate.isSame(dateTime1Ref.current, "hours") &&
                          newDate.isSame(dateTime1Ref.current, "hours")
                        ) {
                          sameHour = true;
                          dateTime1Ref.current = newDate;
                        }
                        setBookingEndDateTime(
                          sameHour
                            ? newDate
                            : newDate.set("hours", 22).set("minutes", 0)
                        );
                      }
                    }}
                    sx={{
                      ...SBDatePickerStyle,
                      width: "-webkit-fill-available",
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        sx: {
                          ...SBDatePickerStyle,
                          borderColor: "white",
                          width: "-webkit-fill-available",
                        },
                      },
                    }}
                  />
                </Box>
              </Box>
            </Stack>
          </LocalizationProvider>
          <Box
            sx={{
              ...SBTextFieldGridStyle,
            }}
          >
            <Box sx={{ ...SBTextFieldIconStyle }}>
              <Schedule />
            </Box>
            <Box>
              <InputLabel sx={{ ...SBLabelStyle }}>{t("daysLabel")}</InputLabel>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "40px 40px 20px",
                }}
              >
                <IconButton
                  aria-label="deduct"
                  sx={{
                    ...SBTextFieldIconStyle,
                    width: "40px",
                    background: SBColor.blue,
                    color: SBColor.white,
                    ":hover": {
                      background: SBColor.blue,
                      color: SBColor.white,
                    },
                    ":disabled": {
                      background: SBColor.lightGray,
                      color: SBColor.blue,
                    },
                  }}
                  onClick={() => {
                    dateTime[1] &&
                      setBookingEndDateTime(dateTime[1].add(-1, "day"));
                  }}
                  disabled={dayDiff <= 1}
                >
                  <Remove />
                </IconButton>
                <Typography
                  sx={{
                    display: "flex",
                    width: "40px",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    fontWeight: "bold",
                    color: SBColor.blue,
                  }}
                >
                  {dayDiff}
                </Typography>
                <IconButton
                  aria-label="add"
                  sx={{
                    ...SBTextFieldIconStyle,
                    width: "40px",
                    background: SBColor.blue,
                    color: SBColor.white,
                    ":hover": {
                      background: SBColor.blue,
                      color: SBColor.white,
                    },
                    ":disabled": {
                      background: SBColor.lightGray,
                      color: SBColor.blue,
                    },
                  }}
                  onClick={() => {
                    dateTime[1] &&
                      setBookingEndDateTime(dateTime[1].add(1, "day"));
                  }}
                >
                  <Add />
                </IconButton>
              </Box>
            </Box>
          </Box>
          <SBSelect
            name="sectionId"
            label={t("section")}
            noOptionLabel={t("NO_SECTION")}
            control={control}
            type={"edit"}
            icon={<ViewModule />}
            isLoading={filterIsLoading}
            setValue={setValue}
            setValueId="rowId"
            additionalOptions={filters?.sections}
            defaultValue={0}
            error={errors?.sectionId && t((errors.sectionId as any)?.message)}
          />
          <SBSelect
            name="rowId"
            label={t("row")}
            noOptionLabel={t("NO_ROW")}
            control={control}
            type={"edit"}
            icon={<MenuOpen />}
            isLoading={filterIsLoading}
            additionalOptions={filters?.sections
              .filter((section) => section.id === selectedSectionId)
              .flatMap((section) => {
                return section.rows
                  .slice()
                  .sort((r, o) => {
                    return r.value.localeCompare(o.value);
                  })
                  .flatMap((row) => {
                    return { id: row.id, value: row.value };
                  });
              })}
            defaultValue={0}
            error={errors?.rowId && t((errors.rowId as any)?.message)}
          />
          <SBSelect
            name="model"
            label={t("model")}
            noOptionLabel={t("noModel")}
            control={control}
            type={"edit"}
            icon={<BeachAccess />}
            isLoading={filterIsLoading}
            additionalOptions={models.length > 0 ? models.flatMap((option) => {
              return { id: option, value: `${t(`models.${option}`)}` };
            }) : filters?.models.flatMap((option) => {
              return { id: option, value: `${t(`models.${option}`)}` };
            })}
            defaultValue={0}
            error={errors?.model && t((errors.model as any)?.message)}
          />
          <Box sx={{ ...SBTextFieldGridStyle }}>
            <Box sx={{ ...SBTextFieldIconStyle }}>
              <BeachAccess />
            </Box>
            <Box>
              <InputLabel sx={{ ...SBLabelStyle }}>
                {t("beachChair")}
              </InputLabel>
              <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
                <Controller
                  control={control}
                  name="id"
                  defaultValue={0}
                  render={({ field }) => (
                    <Autocomplete
                      options={beachChairs}
                      getOptionLabel={(option) => `${option.label}`}
                      onChange={(_, data) => field.onChange(data?.value || 0)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label=""
                          placeholder={t("instantBooking.pleaseChoose")}
                          variant="outlined"
                          sx={{
                            ...SBTextFieldStyle,
                            ...SBAutompleteTextFieldStyle,
                          }}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
        {isDiscountPresent && (
          <SBCheckBox
            name="discount"
            label={t("discount")}
            control={control}
            type={isDisabled ? "show" : "edit"}
            isLoading={false}
            error={errors?.discount && t((errors.discount as any)?.message)}
          />
        )}
        <SBNumberField
          name="price"
          label={t("price")}
          control={control}
          type={isDisabled ? "show" : "edit"}
          icon={<Euro />}
          isLoading={false}
          defaultValue={0}
          endAdornmentSymbol="€"
          error={errors?.price && t((errors.price as any)?.message)}
        />
        <Box sx={{ display: "grid", gap: "10px", alignContent: "center" }}>
          <SBLoadingButton
            label={t("bookings")}
            type="blue"
            icon={<AddIcon />}
            disabled={isDisabled}
          />
        </Box>
      </form>
    </Card>
  );
};

export default VendorBookings;
