import { Box } from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import { SBButtonGridStyle } from "../styles/SBStyles";
import SBLoadingButton from "./SBLoadingButton";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveIcon from "@mui/icons-material/Save";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface SBButtonActionGridPrimary {
  editOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  deleteOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  resetOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  watchOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
}

export type SBButtonActionGridType = SBButtonActionGridPrimary;

const SBButtonActionGrid: React.FC<SBButtonActionGridType> = ({
  editOnClick,
  deleteOnClick,
  resetOnClick,
  watchOnClick,
  type,
  isLoading,
}) => {
  const { t } = useTranslation(["common"]);
  return (
    <Box
      sx={{
        ...SBButtonGridStyle,
      }}
    >
      {type === "show" && (
        <SBLoadingButton
          label={t("edit")}
          type="blue"
          icon={<EditIcon />}
          onClick={editOnClick}
          isLoading={isLoading}
        />
      )}
      {type === "edit" && deleteOnClick && (
        <SBLoadingButton
          label={t("delete")}
          type="orange"
          icon={<DeleteIcon />}
          onClick={deleteOnClick}
          isLoading={isLoading}
        />
      )}
      {(type === "new" || type === "edit") && (
        <SBLoadingButton
          label={t("reset")}
          type="orange"
          icon={<RestartAltIcon />}
          onClick={resetOnClick}
          isLoading={isLoading}
        />
      )}
      {type !== "show" && (
        <SBLoadingButton
          label={t("save")}
          type="blue"
          icon={<SaveIcon />}
          isLoading={isLoading}
        />
      )}
      {type === "edit" && (
        <SBLoadingButton
          label={t("watch")}
          type="blue"
          icon={<VisibilityIcon />}
          onClick={watchOnClick}
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default SBButtonActionGrid;
