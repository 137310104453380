import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  InvoicesResponseDto,
  GeneratorApiInvoicesControllerGetInvoicePDFRequest as Payload,
} from "../../../../api-client/generated";
import { generatorApi } from "../../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../../types";

interface UseGetBooking {
  invoice?: InvoicesResponseDto;
  isLoading: boolean;
  getInvoice: ReactQueryRefetch<InvoicesResponseDto, AxiosError>;
}

interface Variables {
  enabled?: boolean;
  payload: Payload;
}

export const useGetInvoice = ({
  enabled = false,
  payload,
}: Variables): UseGetBooking => {
  const { data: invoice, isLoading, refetch: getInvoice } = useQuery<
    InvoicesResponseDto,
    AxiosError
  >(
    ["getInvoice", payload?.invoiceNumber],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return generatorApi
        .invoicesControllerGetInvoicePDF(payload)
        .then(({ data }) => data);
    },
    { enabled }
  );

  return {
    invoice,
    isLoading,
    getInvoice,
  };
};
