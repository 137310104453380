import { useMutation, useQueryClient } from "@tanstack/react-query";
import { beachChairApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export const useDeleteBeachChair = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    ["deleteBeachChair"],
    (data: { id: number; isList: boolean }) =>
      beachChairApi.beachChairsControllerDelete({ id: data.id }),
    {
      onSuccess: (_, varaiables) => {
        queryClient.refetchQueries(["beachChairList"]);
        queryClient.refetchQueries(["beachChairFilters"]);
        queryClient.refetchQueries(["beachChairsAvailabilities"]);
        queryClient.refetchQueries(["useOpenBookingsInPeriodBeachChairIds"]);

        toast.success(t(`toastMessages.DeleteBeachChairSuccess`));
        if (!varaiables.isList) {
          history.push("/beach_chairs");
        }
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.errorType === "BEACH_CHAIR_CAN_NOT_BE_REMOVED"
        ) {
          toast.error(t(`toastMessages.DeleteBeachChairInBookingFailure`));
        } else {
          toast.error(t(`toastMessages.DeleteBeachChairFailure`));
        }
      },
    }
  );
};
