import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import { setGridTime } from "../../../store/reducers/availabilityReducer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setReportKeyStatusCurrentDate } from "../../../store/reducers/reportsReducer";
import { getReportKeyStatusCurrentDateSelector } from "../../../store/selectors/reportSelectors";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import dayjs, { Dayjs } from "dayjs";
import {
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";
import { deDE } from "@mui/x-date-pickers/locales";

const ReportKeyStatusHeader = () => {
  const { t } = useTranslation(["common"]);
  const date = useSelector(getReportKeyStatusCurrentDateSelector);
  const [dt, setDt] = useState<Dayjs>(dayjs(new Date(date)));
  const [datePickerState, setDatePickerState] = useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <SubPageHeader headerTitle={t("reports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("reports")}
          onClick={() => {
            history.push(`/reports`);
          }}
        />
        <SBHeaderTypography label={t("keyStatus")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DatePicker
            format="DD.MM.YYYY"
            onChange={(newValue: Date | null) => {
              setDt(dayjs(newValue));
              dispatch(setGridTime({ time: 0 }));
              dispatch(
                setReportKeyStatusCurrentDate({
                  date: dayjs(newValue)?.toISOString() || dt.toISOString(),
                })
              );
            }}
            value={dt as any}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
              },
            }}
          />
        </LocalizationProvider>
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default ReportKeyStatusHeader;
