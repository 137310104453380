const {
  REACT_APP_PUBLIC_URL,
  REACT_APP_API_PREFIX,
  REACT_APP_MUI_LICENSE_KEY,
  REACT_APP_ACCESS_TOKEN_KEY,
  REACT_APP_REFRESH_TOKEN_KEY,
  REACT_APP_STAY_LOGGED_IN,
  REACT_APP_EMAIL_CURRENT_USER,
} = process.env;

const CONFIG_DATA = JSON.parse(
  // @ts-ignore
  decodeURIComponent(window["SERVER_DATA"] || "%7B%7D")
) as {
  PUBLIC_URL: string;
  API_PREFIX: string;
  REACT_APP_MUI_LICENSE_KEY: string;
  REACT_APP_ACCESS_TOKEN_KEY: string;
  REACT_APP_REFRESH_TOKEN_KEY: string;
  REACT_APP_STAY_LOGGED_IN: string;
  REACT_APP_EMAIL_CURRENT_USER: string;
};

export const apiConfig = {
  publicUrl: CONFIG_DATA.PUBLIC_URL || REACT_APP_PUBLIC_URL,
  apiPrefix: CONFIG_DATA.API_PREFIX || REACT_APP_API_PREFIX,
  reactAppAccessTokenKey:
    CONFIG_DATA.REACT_APP_ACCESS_TOKEN_KEY || REACT_APP_ACCESS_TOKEN_KEY,
  reactAppRefreshTokenKey:
    CONFIG_DATA.REACT_APP_REFRESH_TOKEN_KEY || REACT_APP_REFRESH_TOKEN_KEY,
  reactAppStayLoggedIn:
    CONFIG_DATA.REACT_APP_STAY_LOGGED_IN || REACT_APP_STAY_LOGGED_IN,
  reactAppEmailCurrentUser:
    CONFIG_DATA.REACT_APP_EMAIL_CURRENT_USER || REACT_APP_EMAIL_CURRENT_USER,
};

export const muiLicense = {
  license: CONFIG_DATA.REACT_APP_MUI_LICENSE_KEY || REACT_APP_MUI_LICENSE_KEY,
};
