import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pricesApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { UpdatePriceDto } from "../../../api-client/generated";

export const useEditPricesMutation = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["EditPricesMutation"],
    (data: { id: number; updatePriceDto: UpdatePriceDto }) =>
      pricesApi.pricesControllerUpdate({
        id: data.id,
        updatePriceDto: data.updatePriceDto,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["getPrices"]);
        toast.success(t(`toastMessages.UpdatePriceSucess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.UpdatePriceFailure`));
      },
    }
  );
};
