import { Box, Stack } from "@mui/material";
import React, { useCallback } from "react";
import {
  FieldValues,
  UseFieldArrayUpdate,
  UseFormSetValue,
} from "react-hook-form";
import { ReactSortable } from "react-sortablejs";
import {
  cardRowRoot,
  draggableCardRow,
  noneDraggableCardRow,
} from "../../../../utils/customStyles/globalStyles";
import RowDivider from "../../../availabilities/availabilitiesBeachChairs/rowDivider/rowDivider";

export interface IRowOrderProps {
  rowOrderFields: Record<"id", string>[];
  type: string;
  setValue: UseFormSetValue<FieldValues>;
  rowOrderUpdate: UseFieldArrayUpdate<FieldValues, "rowOrder">;
  sectionId: number;
}

const RowOrder = ({
  rowOrderFields,
  type,
  setValue,
  rowOrderUpdate,
  sectionId,
}: IRowOrderProps) => {
  const render = useCallback(
    () =>
      rowOrderFields.map((item) => (
        <Box
          key={(item as any).id}
          sx={{
            ...cardRowRoot,
            ...(type !== "show" ? draggableCardRow : noneDraggableCardRow),
          }}
          draggable={type !== "show"}
        >
          <RowDivider
            name={`${(item as any).name}`}
            id={Number(item.id)}
            type={type === "edit" ? type : undefined}
            rowOrderUpdate={type === "edit" ? rowOrderUpdate : undefined}
            sectionId={type === "edit" ? sectionId : undefined}
          />
        </Box>
      )),
    // eslint-disable-next-line
    [rowOrderFields, type]
  );

  return type !== "show" ? (
    <ReactSortable
      list={rowOrderFields}
      setList={(list) => {
        setValue("rowOrder", list);
      }}
    >
      {render()}
    </ReactSortable>
  ) : (
    <Stack spacing={2}>{render()}</Stack>
  );
};

export default React.memo(RowOrder);
