import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { BeachChairBookingResponseDto, BookingsApiBookingsControllerFindOneBeachChairBookingRequest as Variables } from "../../../../api-client/generated";
import { bookingsApi } from "../../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../../types";

interface UseGetBeachChairBooking {
  beachChairBooking?: BeachChairBookingResponseDto;
  isLoading: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<BeachChairBookingResponseDto, AxiosError>;
}

export const useGetBeachChairBooking = ({
  id: bookingId,
  beachChairBookingId
}: Variables): UseGetBeachChairBooking => {
  const { data: beachChairBooking, isLoading, isFetched, refetch } = useQuery<
    BeachChairBookingResponseDto,
    AxiosError
  >(
    ["beachChairBooking", String(bookingId), String(beachChairBookingId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return bookingsApi
        .bookingsControllerFindOneBeachChairBooking({
          id: bookingId,
          beachChairBookingId,
        })
        .then(({ data }) => data);
    },
    {
      enabled: !!(bookingId && beachChairBookingId),
    }
  );

  return {
    beachChairBooking,
    isLoading,
    isFetched,
    refetch,
  };
};
