import React from "react";
import { Box } from "@mui/material";
import { SBHeaderActionButtonsStyle } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBHeaderActionButtonsWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      sx={{ ...SBHeaderActionButtonsStyle }}
      data-testid="SBHeaderActionButtonsWrapper"
    >
      {children}
    </Box>
  );
};

export default SBHeaderActionButtonsWrapper;
