import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import { removeBookingBeachChairRightDrawerToggleRequest } from "../../../../store/reducers/bookingsReducer";
import {
  buttonColor,
  buttonGroupBooking,
} from "../../../../utils/customStyles/globalStyles";
import CancellationModal from "../bookingDetailHeader/cancellationModal";
import { useParams } from "react-router-dom";
import { useGetBooking } from "../hooks";

export const BookingCancellationSplitButton: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { booking, isFetching } = useGetBooking(+id);
  const { t } = useTranslation(["common"]);
  const options = React.useMemo(
    () =>
      booking?.beachChairBookings.length === 1
        ? ["cancel"]
        : ["cancel", "removeBeachChair"],
    [booking]
  );
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  // Cancellation Modal Handling
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleClick = React.useCallback(() => {
    if (options[selectedIndex] === "cancel") {
      setModalOpen(true);
    } else {
      dispatch(
        removeBookingBeachChairRightDrawerToggleRequest({ toggle: true })
      );
    }
  }, [options, selectedIndex, dispatch]);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        disabled={isFetching}
        sx={{
          ...buttonGroupBooking,
        }}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={handleClick}
          sx={{ ...buttonColor }}
          startIcon={<CancelIcon />}
        >
          {t(`${options[selectedIndex]}Booking`)}
        </Button>
        {options.length > 1 ? (
          <Button
            sx={{ ...buttonColor }}
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        ) : null}
      </ButtonGroup>
      <Popper
        style={{ zIndex: 10 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
              zIndex: 9999999,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === 2}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {t(`${option}Booking`)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <CancellationModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </React.Fragment>
  );
};
