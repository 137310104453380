import { useMutation, UseMutateFunction } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  CustomerResponseDto,
  UpdateCustomerBodyDto,
} from "../../../api-client/generated";
import { customerApi } from "../../../api/apiTanStack";

interface Variables extends UpdateCustomerBodyDto {
  id: number;
}

interface UseUpdateCustomer {
  updateCustomer: UseMutateFunction<
    AxiosResponse<CustomerResponseDto>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useUpdateCustomer = (): UseUpdateCustomer => {
  const { mutate: updateCustomer, isLoading } = useMutation<
    AxiosResponse<CustomerResponseDto>,
    AxiosError,
    Variables
  >(["updateCustomer"], ({ id, ...rest }: Variables) =>
    customerApi.customersControllerUpdate({
      id,
      updateCustomerBodyDto: { ...rest },
    })
  );

  return {
    updateCustomer,
    isLoading,
  };
};
