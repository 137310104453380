import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { featuresApi } from "../../../../api/apiTanStack";
import { OrderByDirection } from "../../../../api-client/generated";
import { format } from "date-fns";

export const useGetReportKeyStatuses = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState,
  date: string
) =>
  useQuery({
    queryKey: [
      "getReportKeyStatuses",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      date,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        `filters[date]=${format(new Date(date), "yyyy-MM-dd")}&` +
          columnFilters
            .flatMap((filter) =>
              filter.id.includes("start")
                ? `filters[start]=${new Date(
                    (filter.value as any)[0] || undefined
                  ).toISOString()}&filters[end]=${new Date(
                    (filter.value as any)[1] || undefined
                  ).toISOString()}`
                : `filters[${filter.id}]=${filter.value}`
            )
            .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return featuresApi
        .bookingDetailsControllerFindManyReport(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
