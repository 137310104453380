import React from "react";
import { useTranslation } from "react-i18next";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import { useHistory } from "react-router";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

const CashPointReportsHeader = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  return (
    <SubPageHeader headerTitle={t("cashPointMenu.cashPointReports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderTypography label={t("cashPointMenu.cashPointReports")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
    </SubPageHeader>
  );
};

export default CashPointReportsHeader;
