import { useForm } from "react-hook-form";
import { useCallback, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetBooking } from "../../components/bookingsList/booking/hooks";
import React from "react";
import { removeBeachChairBookingRequest } from "../../store/reducers/bookingsReducer";

export function useRemoveBeachChairBooking(id: number) {
  const { t } = useTranslation(["common"]);
  const { booking } = useGetBooking(id);
  const dispatch = useDispatch();

  const beachChairBookings = React.useMemo(() => {
    return booking
      ? booking.beachChairBookings.map((beachChair) => {
          return {
            label: `${beachChair.beachChair.publicNumber}`,
            value: beachChair.id,
          };
        })
      : [];
  }, [booking]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number().required(`${t("beachChair")} ${t("isMandatory")}`),
      }),
    [t]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = useCallback(
    (formValues) => {
      formValues.id !== 0 &&
        dispatch(
          removeBeachChairBookingRequest({ id: formValues.id, bookingId: id })
        );
    },
    // eslint-disable-next-line
    [id]
  );

  return {
    control,
    errors,
    beachChairBookings,
    onSubmit: handleSubmit(onSubmit),
  };
}
