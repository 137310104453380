import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { OrderByDirection } from "../api-client/generated";

interface FilterState<Filters extends Record<string, unknown>, OrderBy extends keyof Filters> {
  filters: Filters;
  setFilters: (newFilters: Partial<Filters>) => void;
  resetFilters: () => void;

  orderBy: OrderBy;
  setSortField: (orderBy: OrderBy) => void;

  orderDir: OrderByDirection;
  setSortOrderDir: (orderDir: OrderByDirection) => void;
}

export const useFilters = <
  Filters extends Record<string, unknown>,
  OrderBy extends keyof Filters = keyof Filters
>(
  syncFiltersWithUrl = false
): FilterState<Filters, OrderBy> => {
  const [filterState, setFilterState] = useState<Filters>({} as Filters);
  const [orderBy, setOrderBy] = useState<OrderBy>();
  const [orderDir, setOrderDir] = useState<OrderByDirection>(OrderByDirection.Desc);

  const history = useHistory();
  const location = useLocation();

  // Parse filter values from URL params and update state
  useEffect(() => {
    if (syncFiltersWithUrl) {
      const searchParams = new URLSearchParams(location.search);
      const filterValues: Partial<Filters> = {};

      for (const key of searchParams.keys()) {
        if (key in filterState) {
          filterValues[key as keyof Filters] = searchParams.get(key) as Filters[keyof Filters];
        } else if (key === "orderBy") {
          setOrderBy(searchParams.get(key) as unknown as OrderBy);
        } else if (key === "orderDir") {
          setOrderDir(searchParams.get(key) as unknown as OrderByDirection);
        }
      }

      setFilterState((prevFilters) => ({
        ...prevFilters,
        ...filterValues
      }));
    }
    // The Eslint error: React Hook useEffect has a missing dependency: 'filterState'. Either include it or remove the dependency array
    // seems to be a false positive, as we don't want to update the state when filterState changes (it would cause an infinite loop)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncFiltersWithUrl, location.search]);

  // Set a filter value
  const setFilters = (newFilters: Partial<Filters>) => {
    setFilterState((prevFilters) => ({
      ...prevFilters,
      ...newFilters
    }));

    if (syncFiltersWithUrl) {
      const searchParams = new URLSearchParams(location.search);
      for (const [key, value] of Object.entries(newFilters)) {
        if (value === null) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, String(value));
        }
      }
      history.replace({ search: searchParams.toString() });
    }
  };

  // Set the orderBy value
  const setSortField = (orderBy: OrderBy) => {
    setOrderBy(orderBy);

    if (syncFiltersWithUrl) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("orderBy", String(orderBy));
      history.replace({ search: searchParams.toString() });
    }
  };

  // Set the orderDir value
  const setSortOrderDir = (orderDir: OrderByDirection) => {
    setOrderDir(orderDir);

    if (syncFiltersWithUrl) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("orderDir", orderDir);
      history.replace({ search: searchParams.toString() });
    }
  };

  // Reset all filter values
  const resetFilters = () => {
    setFilterState({} as Filters);
    setOrderBy(undefined);
    setOrderDir(OrderByDirection.Desc);

    if (syncFiltersWithUrl) {
      history.replace({ search: "" });
    }
  };

  return {
    filters: filterState,
    setFilters,
    resetFilters,

    orderBy: orderBy!,
    setSortField,

    orderDir,
    setSortOrderDir
  };
};
