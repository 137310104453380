import { create } from "zustand";
import Cookies from "js-cookie";
import API from "../../../api/api";
import { axiosInstance } from "../../../api/apiTanStack";
import { apiConfig } from "../../../utils/cfg/config";

export enum AuthState {
  LOGIN = "LOGIN",
  FORGET = "RESET",
  FIRST_LOG_IN = "FIRST_LOG_IN",
}

const {
  reactAppAccessTokenKey,
  reactAppRefreshTokenKey,
  reactAppStayLoggedIn,
  reactAppEmailCurrentUser,
} = apiConfig;

type AuthStore = {
  authState: AuthState;

  isLoggedIn: boolean;
  email: string | undefined;
  oldPassword: string | undefined;
  accessToken: string | undefined;
  refreshToken: string | undefined;

  actions: {
    setAuthState: (authState: AuthState) => void;
    setEmail: (email: string | undefined) => void;
    setOldPassword: (oldPassword: string | undefined) => void;
    setAccessToken: (
      accessToken: string | undefined,
      stayLogIn: boolean,
      email: string | undefined
    ) => void;
    setRefreshToken: (
      refreshToken: string | undefined,
      stayLogIn: boolean
    ) => void;
    init: () => void;
    clearTokens: () => void;
  };
};

export const useAuthStore = create<AuthStore>()((set, get) => ({
  authState: AuthState.LOGIN,
  isLoggedIn: false,
  email: undefined,
  oldPassword: undefined,
  accessToken: undefined,
  accessTokenData: undefined,
  refreshToken: undefined,
  actions: {
    setAuthState: (authState: AuthState) => {
      set({
        authState,
      });
    },
    setEmail: (email: string | undefined) => {
      set({
        email,
      });
    },
    setOldPassword: (oldPassword: string | undefined) => {
      set({
        oldPassword,
      });
    },
    setAccessToken: (
      accessToken: string | undefined,
      stayLogIn: boolean,
      email: string | undefined
    ) => {
      if (stayLogIn) {
        Cookies.set(`${reactAppAccessTokenKey}`, `${accessToken}`, {
          expires: 7,
        });
        Cookies.set(`${reactAppStayLoggedIn}`, `${stayLogIn}`, {
          expires: 7,
        });
      }
      Cookies.set(`${reactAppEmailCurrentUser}`, `${email}`, {
        expires: 7,
      });
      API.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;
      set({
        authState: AuthState.LOGIN,
        accessToken,
        isLoggedIn: true,
        email: email,
      });
    },
    setRefreshToken: (refreshToken: string | undefined, stayLogIn: boolean) => {
      stayLogIn &&
        Cookies.set(`${reactAppRefreshTokenKey}`, `${refreshToken}`, {
          expires: 7,
        });
      set({
        authState: AuthState.LOGIN,
        refreshToken,
      });
    },
    init: () => {
      const { setAccessToken, setRefreshToken } = get().actions;
      const stayLoggedIn = Boolean(Cookies.get(`${reactAppStayLoggedIn}`));
      const cookiesExists =
        stayLoggedIn &&
        !!Cookies.get(`${reactAppAccessTokenKey}`) &&
        !!Cookies.get(`${reactAppRefreshTokenKey}`);
      if (cookiesExists) {
        setAccessToken(
          Cookies.get(`${reactAppAccessTokenKey}`),
          stayLoggedIn,
          Cookies.get(`${reactAppEmailCurrentUser}`)
        );
        setRefreshToken(
          Cookies.get(`${reactAppRefreshTokenKey}`),
          stayLoggedIn
        );
      }
    },
    clearTokens: () => {
      set({
        authState: AuthState.LOGIN,
        accessToken: undefined,
        refreshToken: undefined,
        isLoggedIn: false,
      });
      API.defaults.headers.common["Authorization"] = ``;
      axiosInstance.defaults.headers.common["Authorization"] = ``;
      Cookies.remove(`${reactAppAccessTokenKey}`);
      Cookies.remove(`${reactAppRefreshTokenKey}`);
      Cookies.remove(`${reactAppEmailCurrentUser}`);
      Cookies.remove(`${reactAppStayLoggedIn}`);
    },
  },
}));
