import React, { Fragment, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { EAvailabilityTime } from "../../../models/availability/EAvailabilityTime";
import { routerRequest } from "../../../store/reducers/routerReducer";
import {
  getAvabilityLayoutSelector,
  getBeachChairFilters,
  getIsPositionSelector,
} from "../../../store/selectors/availabilitySelector";
import { useLocationData } from "../../../utils/customHooks/useLocationData";
import { useSectionData } from "../../../utils/customHooks/useSectionData";
import { customStyle } from "../../../utils/customStyles/customStyles";
import Position from "../../position/position";
import RightDrawer from "../../rightDrawer/rightDrawer";
import AvailabilitiesBeachChair from "../availabilitiesBeachChair/availabilitiesBeachChair";
import AvailabilitiesHeader from "../availabilitiesHeader/availabilitiesHeader";
import SelectedAvailableBeachChair from "../selectedAvailableBeachChair/selectedAvailableBeachChair";
import AvailabilitiesBeachChairsFilter from "./availabilitiesBeachChairsFilter/availabilitiesBeachChairsFilter";
import AvailabilitiesBeachChairsGrid from "./availabilitiesBeachChairsGrid/availabilitiesBeachChairsGrid";
import RowDivider from "./rowDivider/rowDivider";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";
import { Box, CircularProgress, Dialog, DialogContent } from "@mui/material";
import { useBeachChairsAvailabilities } from "../hooks/useBeachChairsAvailabilities";
import { useAvailabilitiesDatesStore } from "../hooks/useAvailabilitiesDatesStore";
import { BeachChairModel } from "../../../api-client/generated";
import { useMenuZustand } from "../../layout/Navigation/hooks/useMenuZustand";
import { useOpenBookingsInPeriodBeachChairIds } from "../hooks/useOpenBookingsInPeriodBeachChairIds";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import { useDeleteBookingBeachChair } from "../../bookingsList/booking/hooks";
import { clearGlobalBookingId } from "../../../store/reducers/globalBookingReducer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { useBookingsFiltersPersists } from "../../../utils/customHooks/useBookingsFiltersPersists";
import { useGetVendor } from "../../vendor";

const AvailabilitiesBeachChairs: React.FC = () => {
  const { setColumnFiltersPersits } = useBookingsFiltersPersists();
  const deleteBeachChairBooking = useDeleteBookingBeachChair();
  const { bookingInProgress, clearBookingInProgress } =
    useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );
  const { type, sectionId } = useParams<{ type: string; sectionId: string }>();
  const { open } = useMenuZustand();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const filters = useSelector(getBeachChairFilters);
  const { availabilitiesDateRange } = useAvailabilitiesDatesStore();
  const { data: availableBeachChairs, isLoading } =
    useBeachChairsAvailabilities(
      +sectionId,
      availabilitiesDateRange[0]?.format("YYYY-MM-DD"),
      availabilitiesDateRange[1]?.format("YYYY-MM-DD"),
      filters.row !== "" ? +filters.row : undefined,
      filters.model !== "" ? (filters.model as BeachChairModel) : undefined,
      filters.free,
      filters.hasLock
    );
  const { data: bookings, isLoading: isOpenIdsLoading } =
    useOpenBookingsInPeriodBeachChairIds(
      availabilitiesDateRange[0]?.add(-1, "day").toISOString(),
      availabilitiesDateRange[1]?.add(2, "day").toISOString()
    );
  const openIds = useMemo(
    () => bookings?.map((item) => item.beachChairId) ?? [],
    [bookings]
  );
  const [selectedBeachChairId, setSelectedBeachChairId] = useState(0);
  const beachChairIdRef = useRef<number>(0);
  const beachChairPublicNumberRef = useRef<string>("");
  const [selectedBookingId, setSelectedBookingId] = useState(0);
  const [rightDrawerState, setRightDrawerState] = useState(false);
  const { sectionData } = useSectionData(+sectionId);
  const { data: vendor } = useGetVendor(sectionData?.vendorId);
  const { locationData } = useLocationData(sectionData?.locationId);
  const isPosition = useSelector(getIsPositionSelector);
  const availabilityLayout = useSelector(getAvabilityLayoutSelector);
  const { bookingInProgressBeachChairsIds } = useBookingInProgressStore();
  const isGrid = type === "grid";

  return (
    <Box
      sx={{
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        bottom: 0,
      }}
    >
      {rightDrawerState && (
        <RightDrawer state={rightDrawerState} setState={setRightDrawerState}>
          <SelectedAvailableBeachChair
            id={selectedBeachChairId}
            handleClose={() => setRightDrawerState(false)}
            locationData={locationData}
            publicReference={vendor?.publicReference}
          />
        </RightDrawer>
      )}
      <AvailabilitiesHeader />
      <AvailabilitiesBeachChairsFilter />
      {isLoading && isOpenIdsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="50vh"
        >
          <CircularProgress />
        </Box>
      ) : isGrid ? (
        <AvailabilitiesBeachChairsGrid
          setSelectedBeachChairId={setSelectedBeachChairId}
          setRightDrawerState={setRightDrawerState}
        />
      ) : (
        <Box
          sx={{
            ...{
              minHeight: "calc(60vh)",
              maxHeight: "calc(60vh)",
              minWidth: "calc(100vw - 300px)",
              maxWidth: open ? "calc(100vw - 300px)" : "calc(100vw - 100px)",
              height: "calc(60vh)",
              paddingTop: "20px",
              paddingRight: "20px",
              paddingLeft: "20px",
              bottom: 0,
              display: "grid",
              gridTemplateRows: "repeat(8, 40px auto)",
              gap: "10px",
              alignItems: "self-start;",
              overflowY: "scroll",
              overflowX: "scroll",
              boxSizing: "content-box",
              "&::-webkit-scrollbar": {
                width: "2px",
                height: "2px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: customStyle.scrollColor,
              },
            },
            ...(availabilityLayout && {
              gridTemplateRows: "repeat(auto-fit, 40px 86px );",
            }),
          }}
          id="boxContent"
        >
          <Box
            sx={{
              display: "none",
            }}
          >
            <Box>
              {t("section")}: {sectionData?.name}
            </Box>
            <Box>
              {t("date")} :{" "}
              {`${availabilitiesDateRange[0]?.format(
                "dd.MM.yyyy"
              )} - ${availabilitiesDateRange[1]?.format("dd.MM.yyyy")}`}
            </Box>
          </Box>
          <Box
            sx={{
              display: "none",
            }}
          ></Box>
          {availableBeachChairs?.map(
            (data, index) =>
              data && (
                <Fragment key={`fragment-${index}`}>
                  {availabilityLayout ? (
                    <Box
                      sx={
                        {
                          position: "sticky",
                          minWidth: "calc(100vw - 381px)",
                          maxWidth: "calc(100vw - 381px)",
                          left: 0,
                          flexGrow: 0,
                          flexShrink: 0,
                        } as const
                      }
                    >
                      <RowDivider name={data.name} />
                    </Box>
                  ) : (
                    <Box>
                      <RowDivider name={data.name} />
                    </Box>
                  )}
                  {isPosition ? (
                    <Position items={(data as any).rowData} rowId={data.id} />
                  ) : (
                    <Box
                      sx={{
                        ...(availabilityLayout
                          ? {
                              display: "flex",
                              gap: "10px",
                            }
                          : {
                              display: "grid",
                              gridTemplateColumns:
                                "repeat(auto-fit, minmax(70px, 70px));",
                              gap: "10px",
                            }),
                      }}
                      key={`box-row-content-${index}`}
                    >
                      {(data as any).rowData
                        .filter((x) => x !== null)
                        .map((beachChair) => (
                          <span
                            key={`box-row-content-fragment-${index}-${beachChair.id}`}
                            onClick={() => {
                              if (
                                bookingInProgressBeachChairsIds.includes(
                                  beachChair.id
                                ) ||
                                openIds?.includes(beachChair.id)
                              ) {
                                setSelectedBeachChairId(beachChair.id);
                                beachChairIdRef.current = beachChair.id;
                                beachChairPublicNumberRef.current =
                                  beachChair.publicNumber;
                                setSelectedBookingId(beachChair.bookingId);
                                setIsDialogOpen(true);
                              } else if (
                                beachChair.timeEnum !== EAvailabilityTime.Allday
                              ) {
                                setSelectedBeachChairId(beachChair.id);
                                beachChairIdRef.current = beachChair.id;
                                beachChairPublicNumberRef.current =
                                  beachChair.publicNumber;
                                setSelectedBookingId(beachChair.bookingId);
                                setRightDrawerState(!rightDrawerState);
                              } else if (
                                beachChair.timeEnum === EAvailabilityTime.Allday
                              ) {
                                setColumnFiltersPersits([
                                  {
                                    id: "publicNumber",
                                    value: beachChair.publicNumber,
                                  },
                                  {
                                    id: "start",
                                    value: [
                                      availabilitiesDateRange[0]
                                        ?.utc()
                                        ?.startOf("years"),
                                      availabilitiesDateRange[1]
                                        ?.utc()
                                        ?.endOf("years"),
                                    ],
                                  },
                                ]);
                                dispatch(
                                  routerRequest({
                                    path: "bookings",
                                  })
                                );
                              }
                            }}
                          >
                            <AvailabilitiesBeachChair
                              key={beachChair.id}
                              isSeasonBeachChair={beachChair.seasonBeachChair}
                              isInBooking={
                                bookingInProgressBeachChairsIds.includes(
                                  beachChair.id
                                ) || openIds?.includes(beachChair.id)
                              }
                              hasLock={beachChair.lockId !== null}
                              id={beachChair.publicNumber}
                              model={beachChair.model}
                              timeEnum={beachChair.timeEnum}
                              attributes={beachChair.attributes}
                              reason={beachChair.reason}
                              isReserved={(beachChair as any).reserved}
                            />
                          </span>
                        ))}
                    </Box>
                  )}
                </Fragment>
              )
          )}
        </Box>
      )}
      <Dialog
        open={isDialogOpen}
        onClose={() => {
          setSelectedBeachChairId(0);
          setSelectedBookingId(0);
          beachChairIdRef.current = 0;
          beachChairPublicNumberRef.current = "";
          setIsDialogOpen(false);
        }}
      >
        <DialogContent
          dividers
          sx={{
            height: "140px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SBLoadingButton
            label={t("addButtonRedirect")}
            type="blue"
            icon={<ArrowOutwardIcon />}
            onClick={() => {
              setColumnFiltersPersits([
                {
                  id: "publicNumber",
                  value: beachChairPublicNumberRef.current,
                },
                {
                  id: "start",
                  value: [
                    availabilitiesDateRange[0]?.add(-1, "day")?.startOf("day"),
                    availabilitiesDateRange[1]?.add(1, "day")?.endOf("day"),
                  ],
                },
                {
                  id: "end",
                  value: [
                    availabilitiesDateRange[0]?.add(-1, "day")?.startOf("day"),
                    availabilitiesDateRange[1]?.add(1, "day")?.endOf("day"),
                  ],
                },
              ]);
              setIsDialogOpen(false);
              dispatch(
                routerRequest({
                  path: "bookings",
                })
              );
            }}
          />
          <SBLoadingButton
            label={t("removeBeachChairFromBooking")}
            type="orange"
            icon={<DeleteForeverIcon />}
            onClick={() => {
              const beachChairBookingId =
                bookings?.filter(
                  (item) => item.beachChairId === beachChairIdRef.current
                )[0].id ?? 0;
              deleteBeachChairBooking
                .mutateAsync({
                  id: selectedBookingId,
                  beachChairBookingId: beachChairBookingId,
                })
                .finally(() => {
                  if (selectedBookingId === globalBookingId) {
                    dispatch(clearGlobalBookingId());
                  }
                  // Remove Booking in Progress if its the same id
                  if (
                    bookingInProgress &&
                    bookingInProgress.id === selectedBookingId
                  ) {
                    clearBookingInProgress();
                  }
                  setIsDialogOpen(false);
                })
                .catch(() => {
                  setIsDialogOpen(false);
                });
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AvailabilitiesBeachChairs;
