import { useMutation, useQueryClient } from "@tanstack/react-query";
import { beachChairApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { IBeachChairFormData } from "../../../models/beachChairs/IBeachChairFormData";
import { IGeoPoint } from "../../../models/common/IGeoPoint";
import { UpdateBeachChairBodyDto } from "../../../api-client/generated";
import { useTranslation } from "react-i18next";

export const useEditBeachChair = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  return useMutation(
    ["editBeachChair"],
    ({ id, formData }: { id: number; formData: IBeachChairFormData }) => {
      const coordinates = formData.geoJson
        ? formData.geoJson.split(",").map((value) => +value)
        : [0, 0];
      const geoPoint: IGeoPoint = {
        type: "Point",
        coordinates: coordinates,
      };
      const newBeachChair = {
        model: formData.model,
        sectionId: formData.sectionId,
        rowId: formData.rowId === 0 ? null : formData.rowId,
        lockId: formData.lockId === 0 ? null : formData.lockId,
        publicNumber: formData.publicNumber,
        geoJson: geoPoint,
        description: formData.description,
        internalNote: formData.internalNote,
        buyingDate: formData.buyingDate,
        status: formData.status,
        afterHourBooking: formData.afterHourBooking,
        onlineBooking: formData.onlineBooking,
        seasonBeachChair: formData.seasonBeachChair,
        images: formData.images,
        stopSaleDates: formData.stopSaleDates,
        attributes: formData.attributes,
        details: formData.details,
      };
      return beachChairApi.beachChairsControllerUpdate({
        id,
        updateBeachChairBodyDto: newBeachChair as UpdateBeachChairBodyDto,
      });
    },
    {
      onSuccess: (_, variables) => {
        queryClient.refetchQueries(["beachChair", Number(variables.id)]);
        queryClient.refetchQueries(["beachChairList"]);
        queryClient.refetchQueries(["beachChairFilters"]);
        toast.success(t(`toastMessages.PutBeachChairSuccess`));
        history.push(`/beach_chairs`);
      },
      onError: () => {
        toast.error(t(`toastMessages.PutBeachChairFailure`));
      },
    }
  );
};
