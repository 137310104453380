import { useQuery } from "@tanstack/react-query";
import { vendorApi } from "../../../api/apiTanStack";

export const useGetVendor = (vendorId?: string | number) =>
  useQuery({
    queryKey: ["vendor", Number(vendorId)],
    queryFn: async () => {
      return vendorApi
        .vendorsControllerFindOne({ id: Number(vendorId) ?? 0 })
        .then(({ data }) => data);
    },
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
    enabled: !!vendorId
  });
