import { lockToRow } from "../../utils/conversions/lockToRow";
import { createSelector } from "reselect";
import { selectLockById, selectLocks } from "../reducers/lockReducer";
import { AppState } from "../reducers/rootReducer";
import { getLockPages } from "../reducers/lockReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.locks.pending;

const getError = (state: AppState) => state.locks.error;


const getLocksFilterData = (state: AppState) =>
  state.locks.filterLocksData;

const getLocksCount = (state: AppState) =>
  state.locks.locksCount;

export const getLocksFilterDataSelector = createSelector(
  getLocksFilterData,
  (filterData) => filterData
);

export const getLocksCountSelector = createSelector(
  getLocksCount,
  (count) => count
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getLocks = createSelector(getState, (state) => selectLocks(state));

export const getLocksSelector = createSelector(getLocks, (locks) =>
  locks.map(lockToRow)
);

export const getLockSelector = (id: number) =>
  createSelector(getState, (state) => selectLockById(state, id));

export const getLockPage = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) => {
    const page = getLockPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.dataIds.map((id) => selectLockById(state, id)!).map(lockToRow)
    );
  });
