import { dateToProperFormat } from "./../conversions/dataConversion";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getShortUrlSelector } from "../../store/selectors/shortUrlsSelectors";
import {
  getShortUrlRequest,
  postShortUrlRequest,
  putShortUrlRequest,
} from "../../store/reducers/shortUrlsReducer";
import { getVendorsRequest } from "../../store/reducers/authReducer";
import { getVendorsSelector } from "../../store/selectors/authSelectors";

export function useShortUrlForm(uid: string, type: string) {
  const dispatch = useDispatch();
  const data = useSelector(getShortUrlSelector(uid));
  const vendorsSelector = useSelector(getVendorsSelector);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        vendorId: yup.number().nullable(true),
        uid: yup.string().nullable(true),
        updatedAt: yup.string().nullable(true),
        tagId: yup.number().nullable(true),
        url: yup.string().nullable(true),
        counter: yup.number().nullable(true),
      }),
    []
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    vendorsSelector.length === 0 && dispatch(getVendorsRequest({}));
    // eslint-disable-next-line
  }, [vendorsSelector]);

  useEffect(() => {
    if (!data && type !== "new") {
      dispatch(getShortUrlRequest({ uid }));
    }
    // eslint-disable-next-line
  }, [data, uid, type]);

  useEffect(() => {
    setValue("uid", data?.uid || "");
    setValue(
      "updatedAt",
      data?.updatedAt ? dateToProperFormat(data.updatedAt) : ""
    );
    setValue("tagId", data?.tagId || 0);
    setValue("url", data?.url || "");
    setValue("counter", data?.counter || 0);
    setValue("vendorId", data?.vendorId || 0);
    // eslint-disable-next-line
  }, [data]);

  const onSubmit = useCallback(
    (formValues) => {
      const { updatedAt, counter, ...newFormValues } = formValues;
      if (type === "new") {
        dispatch(postShortUrlRequest({ formData: newFormValues }));
      } else {
        dispatch(putShortUrlRequest({ uid: uid, formData: newFormValues }));
      }
    },
    // eslint-disable-next-line
    [uid, type]
  );

  const handleReset = useCallback(() => {
    setValue("uid", "");
    setValue("updatedAt", "");
    setValue("tagId", 0);
    setValue("url", "");
    setValue("counter", 0);
    setValue("vendorId", 0);
    // eslint-disable-next-line
  }, []);

  return {
    vendorsSelector,
    control,
    errors,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
