import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { locksApi } from "../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteLock = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteLock"],
    ({ id }: { id: number }) => locksApi.locksControllerDelete({ id: id }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteLockSuccess`));
        queryClient.refetchQueries(["adminLocks"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteLockFailure`));
      },
    }
  );
};
