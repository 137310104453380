import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import RoomIcon from "@mui/icons-material/Room";
import FeedIcon from "@mui/icons-material/Feed";
import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import {
  CreateCustomerBodyDto,
  CustomerResponseDto,
  UpdateCustomerBodyDto,
} from "../../../api-client/generated";
import { toast } from "react-toastify";
import DeleteDialog from "../../dialog/DeleteDialog";
import {
  useCreateCustomer,
  useCustomerForm,
  useDeleteCustomer,
  useUpdateCustomer,
} from "../hooks";
import { useGetCurrentUser, UserRoles } from "../../../hooks";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import { Email, People } from "@mui/icons-material";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";

export type CustomerFormValues = CreateCustomerBodyDto | UpdateCustomerBodyDto;

export const CustomerForm: React.FC<{
  initialValues?: CustomerResponseDto;
  type?: string;
}> = ({ initialValues, type = "edit" }) => {
  const { control, reset, handleSubmit, errors } =
    useCustomerForm(initialValues);
  const { userRoles } = useGetCurrentUser();
  const isAdmin = userRoles.includes(UserRoles.SUPER_ADMIN);
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const queryClient = useQueryClient();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const deleteCustomer = useDeleteCustomer();

  const handleReset = () => {
    reset(initialValues);
  };

  const handleOnConfirmedDeleteClick = (id: number) => {
    deleteCustomer.mutateAsync({ id }).finally(() => {
      history.push("/customers");
    });
  };

  const { updateCustomer, isLoading: isUpdating } = useUpdateCustomer();
  const { createCustomer, isLoading: isCreating } = useCreateCustomer();

  const onSubmit = (formValues: CustomerFormValues) => {
    let customerData = { ...formValues };

    if (!isAdmin) {
      delete customerData.default;
    }

    if (initialValues?.id) {
      updateCustomer(
        {
          id: initialValues.id,
          ...customerData,
        },
        {
          onSuccess: ({ data: updatedCustomer }) => {
            toast.success(t(`toastMessages.PutCustomerSuccess`));
            queryClient.setQueryData(
              ["customer", initialValues.id],
              updatedCustomer
            );

            history.push(`/customers/${updatedCustomer.id}/show`);
          },
        }
      );
    } else {
      createCustomer(customerData as CreateCustomerBodyDto, {
        onSuccess: async ({ data: newCustomer }) => {
          toast.success(t(`toastMessages.PushCustomerSuccess`));
          await queryClient.invalidateQueries(["customers"]);
          queryClient.setQueryData(["customer", newCustomer.id], newCustomer);
          history.push(`/customers/${newCustomer.id}/show`);
        },
      });
    }
  };

  const isLoading = isUpdating || isCreating;

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <SBFormGridWrapper>
        <SBColumnGridWrapper>
          <SBSelect
            name="salutation"
            label={t("salutation")}
            control={control}
            type={type}
            icon={<AccountCircleIcon />}
            isLoading={isLoading}
            additionalOptions={[
              { id: "MR", value: `${t(`salutations.MR`)}` },
              { id: "MS", value: `${t(`salutations.MS`)}` },
              { id: "FM", value: `${t(`salutations.FM`)}` },
              { id: "MX", value: `${t(`salutations.MX`)}` },
            ]}
            defaultValue={"MR"}
            error={errors?.salutation && t((errors.salutation as any)?.message)}
          />
          <SBTextField
            name="firstName"
            label={t("firstname")}
            control={control}
            type={type}
            icon={<People />}
            isLoading={isLoading}
            error={errors?.firstName && t((errors.firstName as any)?.message)}
          />
          <SBTextField
            name="lastName"
            label={t("lastname")}
            control={control}
            type={type}
            icon={<People />}
            isLoading={isLoading}
            error={errors?.lastName && t((errors.lastName as any)?.message)}
          />
          <SBTextField
            name="telephone"
            label={t("telephone")}
            control={control}
            type={type}
            icon={<PhoneIcon />}
            isLoading={isLoading}
            error={errors?.telephone && t((errors.telephone as any)?.message)}
          />
          <SBTextField
            name="email"
            label={t("email")}
            control={control}
            type={type}
            icon={<Email />}
            isLoading={isLoading}
            error={errors?.email && t((errors.email as any)?.message)}
          />
        </SBColumnGridWrapper>
        <SBColumnGridWrapper>
          <SBTextField
            name="address.additionalInfo"
            label={t("additionalInfo")}
            control={control}
            type={type}
            icon={<FeedIcon />}
            isLoading={isLoading}
            error={
              errors?.address &&
              (errors.address as any)?.additionalInfo &&
              t((errors.address as any)?.additionalInfo?.message)
            }
          />
          <SBTextField
            name="address.street"
            label={t("street")}
            control={control}
            type={type}
            icon={<RoomIcon />}
            isLoading={isLoading}
            error={
              errors?.address &&
              (errors.address as any)?.street &&
              t((errors.address as any)?.street?.message)
            }
          />
          <SBTextField
            name="address.zip"
            label={t("zip")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.address &&
              (errors.address as any)?.zip &&
              t((errors.address as any)?.zip?.message)
            }
          />
          <SBTextField
            name="address.city"
            label={t("city")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.address &&
              (errors.address as any)?.city &&
              t((errors.address as any)?.city?.message)
            }
          />
          <SBTextField
            name="address.country"
            label={t("country")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.address &&
              (errors.address as any)?.country &&
              t((errors.address as any)?.country?.message)
            }
          />
          <SBTextField
            name="bankDetails.iban"
            label={t("iban")}
            control={control}
            type={type}
            icon={<AccountBalanceIcon />}
            isLoading={isLoading}
            error={
              errors?.bankDetails &&
              (errors.bankDetails as any)?.iban &&
              t((errors.bankDetails as any)?.iban?.message)
            }
          />
          <SBTextField
            name="bankDetails.bic"
            label={t("bic")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.bankDetails &&
              (errors.bankDetails as any)?.bic &&
              t((errors.bankDetails as any)?.bic?.message)
            }
          />
          <SBTextField
            name="bankDetails.accountHolder"
            label={t("accountHolder")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.bankDetails &&
              (errors.bankDetails as any)?.accountHolder &&
              t((errors.bankDetails as any)?.accountHolder?.message)
            }
          />
          <SBTextField
            name="bankDetails.bankName"
            label={t("bankName")}
            control={control}
            type={type}
            isLoading={isLoading}
            error={
              errors?.bankDetails &&
              (errors.bankDetails as any)?.bankName &&
              t((errors.bankDetails as any)?.bankName?.message)
            }
          />
          {isAdmin && (
            <SBCheckBox
              name="default"
              label={t("setAsDefault")}
              control={control}
              type={type}
              isLoading={isLoading}
              error={errors?.default && t((errors.default as any)?.message)}
            />
          )}
        </SBColumnGridWrapper>
      </SBFormGridWrapper>
      <SBButtonActionGrid
        editOnClick={() => history.push(`/customers/${initialValues?.id}/edit`)}
        deleteOnClick={() => setDeleteDialogOpen(true)}
        resetOnClick={handleReset}
        watchOnClick={() =>
          history.push(`/customers/${initialValues?.id}/show`)
        }
        type={type}
        isLoading={isLoading}
      />
      {initialValues?.id && (
        <DeleteDialog
          namespace="customers"
          open={deleteDialogOpen}
          selectedValue={initialValues.id}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (initialValues.id) {
              handleOnConfirmedDeleteClick(+initialValues.id);
            }
          }}
        />
      )}
    </form>
  );
};
