import { useQuery } from "@tanstack/react-query";
import { locationApi } from "../../../../api/apiTanStack";

export const useLocation = (id?: number) =>
  useQuery({
    queryKey: ["locationById", Number(id)],
    queryFn: async () => {
      return locationApi
        .locationsControllerFindOne({
          id: id ?? 0,
        })
        .then((response) => response.data);
    },
    enabled: !!id,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
