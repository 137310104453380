import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FetchInvoiceRequestPayload,
  FetchInvoiceFailurePayload,
  FetchInvoiceSuccessPayload,
  GetShortInvoiceRequestPayload,
  GetShortInvoiceSuccessPayload,
} from "../actionPayload/invoicesPayloads";

const INITIAL_STATE = {
  pending: false,
  url: "",
  cancelUrl: "",
  error: "",
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: INITIAL_STATE,
  reducers: {
    resetInvoiceRequest() {
      return INITIAL_STATE;
    },
    fetchInvoiceRequest(
      state,
      action: PayloadAction<FetchInvoiceRequestPayload>
    ) {
      state.pending = true;
    },
    fetchInvoiceSuccess(
      state,
      action: PayloadAction<FetchInvoiceSuccessPayload>
    ) {
      state.pending = false;
      state.url = action.payload.url;
    },
    fetchCancellationRequest(
      state,
      action: PayloadAction<FetchInvoiceRequestPayload>
    ) {
      state.pending = true;
    },
    fetchCancellationSuccess(
      state,
      action: PayloadAction<FetchInvoiceSuccessPayload>
    ) {
      state.pending = false;
      state.cancelUrl = action.payload.url;
    },
    getShortInvoiceRequest(
      state,
      action: PayloadAction<GetShortInvoiceRequestPayload>
    ) {
      state.pending = true;
    },
    getShortInvoiceSuccess(
      state,
      action: PayloadAction<GetShortInvoiceSuccessPayload>
    ) {
      state.pending = false;
    },
    fetchInvoiceError(
      state,
      action: PayloadAction<FetchInvoiceFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  resetInvoiceRequest,
  fetchInvoiceRequest,
  fetchInvoiceSuccess,
  fetchCancellationRequest,
  fetchCancellationSuccess,
  getShortInvoiceRequest,
  getShortInvoiceSuccess,
  fetchInvoiceError,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;
