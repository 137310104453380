import React from "react";
import BeachChairBookingsListHeader from "./beachChairBookingsListHeader";
import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import {
  Box,
  IconButton,
  //TextField,
  Typography,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { LocalOffer, Visibility } from "@mui/icons-material";
import SBTable from "../../SBTable/SBTable";
import { useDispatch } from "react-redux";
import { clearGlobalBookingId } from "../../../store/reducers/globalBookingReducer";
import { useQueryClient } from "@tanstack/react-query";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";
import { useGetBeachChairBookings } from "./hooks/useGetBeachChairBookings";
import { toast } from "react-toastify";
import { bookingsDateToProperFormat } from "../../../utils/conversions/dataConversion";
import Status from "../../status/status";
import { useDeleteBooking } from "./hooks/useDeleteBooking";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

const BeachChairBookingsList: React.FC = () => {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteBooking = useDeleteBooking();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteBookingModal = (bookingId: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(bookingId);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("BeachChairBookingsList");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetBeachChairBookings(
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      +id
    );

  const { bookingInProgress, clearBookingInProgress } =
    useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteBooking
        .mutateAsync(
          { id: id },
          {
            onSuccess: (_, variables) => {
              toast.success(t(`toastMessages.deleteSuccess`));
              queryClient.invalidateQueries(["booking", Number(variables.id)]);
              queryClient.refetchQueries(["bookings"]);
              queryClient.refetchQueries(["bookingList"]);
              queryClient.refetchQueries(["beachChairBookingList"]);
              // Remove Booking in Progress if its the same id
              if (
                bookingInProgress &&
                bookingInProgress.id === Number(variables.id)
              ) {
                clearBookingInProgress();
              }
            },
            onError: (e) => {
              toast.success(t(`toastMessages.deleteFailure`));
            },
          }
        )
        .finally(() => {
          if (id === globalBookingId) {
            dispatch(clearGlobalBookingId());
          }
          // Remove Booking in Progress if its the same id
          if (bookingInProgress && bookingInProgress.id === id) {
            clearBookingInProgress();
          }
        });
    },
    // eslint-disable-next-line
    [globalBookingId, clearBookingInProgress, bookingInProgress]
  );

  const columns = React.useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "bookingRef",
        header: `${t("bookingRef")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold">{row.renderedCellValue}</Typography>
          </Box>
        ),
        maxSize: 50,
        minSize: 10,
      },
      {
        accessorKey: "lastName",
        accessorFn: (originalRow: any) =>
          `${originalRow.customer?.firstName ?? ""} ${
            originalRow.customer?.lastName ?? ""
          }`,
        header: `${t("fullname")}`,
        maxSize: 50,
        minSize: 10,
      },
      {
        accessorKey: "createdAt",
        accessorFn: (originalRow: any) => originalRow.createdAt,
        header: `${t("createdAt")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>
              {bookingsDateToProperFormat(row.renderedCellValue)}
            </Typography>
          </Box>
        ),
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
        maxSize: 50,
        minSize: 10,
      },
      {
        accessorKey: "totalPrice",
        header: `${t("totalPrice")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Typography>
                {new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(row.renderedCellValue.props["aria-label"])}
              </Typography>
            ) : (
              <Typography>
                {new Intl.NumberFormat("de-DE", {
                  style: "currency",
                  currency: "EUR",
                }).format(row.renderedCellValue)}
              </Typography>
            )}
          </Box>
        ),
        maxSize: 50,
        minSize: 10,
      },
      {
        accessorKey: "discount",
        accessorFn: (originalRow: any) =>
          originalRow?.beachChairBookings?.some(
            (beachChairBooking: any) => beachChairBooking.discount
          ),
        header: `${t("discountLabel")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue && <LocalOffer />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "status",
        header: `${t("beachChairsDataTable.status")}`,
        maxSize: 50,
        minSize: 10,
        filterVariant: "select",
        filterSelectOptions: [
          { value: "CONFIRMED", text: `${t("filters.CONFIRMED")}` },
          { value: "OPEN", text: `${t("filters.OPEN")}` },
          { value: "CANCELED", text: `${t("filters.CANCELED")}` },
          { value: "PENDING", text: `${t("filters.PENDING")}` },
          { value: "RESERVED", text: `${t("filters.RESERVED")}` },
          { value: "EXPIRED", text: `${t("filters.EXPIRED")}` },
        ],
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]}`
                )}`}
              />
            ) : (
              <Status value={`${t(`states.${row.renderedCellValue}`)}`} />
            )}
          </Box>
        ),
      },
    ];
  }, [t]);
  /**
   *
  
  
   */

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("bookingList")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const bookingId = data?.items[cell.row.index].id;
      const status = data?.items[cell.row.index].status;
      return (
        <Box sx={{ float: "right" }}>
          {(status === "PENDING" ||
            status === "OPEN" ||
            status === "RESERVED") && (
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (bookingId) {
                  openDeleteBookingModal(bookingId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          )}
          {(status === "PENDING" ||
            status === "OPEN" ||
            status === "RESERVED") && (
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (bookingId) {
                  history.push(`/bookings/${bookingId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          )}
          {(status === "CONFIRMED" ||
            status === "EXPIRED" ||
            status === "PENDING" ||
            status === "CANCELED" ||
            status === "RESERVED") && (
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (bookingId) {
                  history.push(`/bookings/${bookingId}/show`);
                }
              }}
            >
              <Visibility />
            </IconButton>
          )}
        </Box>
      );
    },
    [data, history]
  );

  const renderDetailPanel = React.useCallback(
    (cell: any) => {
      const beachChairBookings = data?.items[cell.row.index].beachChairBookings;

      const columns: any[] = [
        {
          accessorKey: "publicNumber",
          header: `${t("publicNumber")}`,
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "model",
          header: `${t("model")}`,
          accessorFn: (originalRow: any) =>
            `${t(`models.${originalRow.model}`)}`,
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "sectionId",
          header: `${t("section")}`,
          accessorFn: (originalRow: any) =>
            originalRow.beachChair?.section?.value,
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "rowId",
          header: `${t("row")}`,
          accessorFn: (originalRow: any) => originalRow.beachChair?.row?.value,
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "lockId",
          header: `${t("lock")}`,
          accessorFn: (originalRow: any) => originalRow.beachChair?.lock?.value,
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "start",
          header: `${t("pickup")}`,
          accessorFn: (originalRow: any) =>
            bookingsDateToProperFormat(originalRow.start),
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "end",
          header: `${t("dropoff")}`,
          accessorFn: (originalRow: any) =>
            bookingsDateToProperFormat(originalRow.end),
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "price",
          header: `${t("price")}`,
          accessorFn: (originalRow: any) =>
            new Intl.NumberFormat("de-DE", {
              style: "currency",
              currency: "EUR",
            }).format(originalRow.price),
          maxSize: 20,
          minSize: 10,
        },
        {
          accessorKey: "discount",
          accessorFn: (originalRow: any) => originalRow.discount,
          header: `${t("discountLabel")}`,
          filterVariant: "checkbox",
          Cell: (row: any) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {row.renderedCellValue && <LocalOffer />}
            </Box>
          ),
          maxSize: 20,
          minSize: 10,
        },
      ];

      return (
        <Box>
          {beachChairBookings && beachChairBookings.length > 0 ? (
            <MaterialReactTable
              muiTableHeadCellProps={{
                sx: { backgroundColor: "#FAFAFA" },
              }}
              muiTableBodyCellProps={{
                sx: { backgroundColor: "#FAFAFA" },
              }}
              data={beachChairBookings}
              columns={columns}
              enableSorting={false}
              enableGlobalFilter={false}
              enableFilters={false}
              enableTopToolbar={false}
              enablePagination={false}
              enableHiding={false}
              enableBottomToolbar={false}
              enableTableFooter={false}
              enableColumnActions={false}
              enableEditing={false}
              muiTableContainerProps={{
                sx: { margin: 0 },
              }}
            />
          ) : (
            <Box>{t("filterNoOption")}</Box>
          )}
        </Box>
      );
    },

    [t, data]
  );

  return (
    <SBMainListWrapper>
      <BeachChairBookingsListHeader />
      <SBTable
        columnVisibilityStoreName="BeachChairBookingsListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        renderDetailPanel={renderDetailPanel}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="bookings"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteAfterModalConfirm(+deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default React.memo(BeachChairBookingsList);
