import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBCheckBoxGridStyle,
  SBCheckboxBoxLabelStyle,
  SBCheckboxStyle,
  SBFormControlStyle,
  SBLabelStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBCheckBoxPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  defaultValue?: boolean;
  color?: string;
}

export type SBCheckBoxType = SBCheckBoxPrimary & SBFormHelperTextPrimary;

const SBCheckBox: React.FC<SBCheckBoxType> = ({
  type,
  label,
  isLoading,
  name,
  control,
  defaultValue,
  color,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBCheckBoxGridStyle }}>
      <Box sx={{ ...SBCheckboxBoxLabelStyle }}>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue ?? false}
              render={({ field }) => (
                <FormControlLabel
                  disabled={isDisabled}
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                      sx={{
                        ...SBCheckboxStyle,
                        ...(color && {
                          color: color,
                          "&.Mui-checked": {
                            color: color,
                          },
                        }),
                      }}
                    />
                  }
                  label={
                    <Typography sx={{ ...SBLabelStyle }}>{label}</Typography>
                  }
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBCheckBox;
