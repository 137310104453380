import { Box } from "@mui/material";
import { SBBreadcrumbsWrapperStyle } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBSelectedButtonsWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box
      aria-label="SBSelectedButtonsWrapper"
      data-testid="SBSelectedButtonsWrapper"
      sx={{ ...SBBreadcrumbsWrapperStyle }}
    >
      {children}
    </Box>
  );
};

export default SBSelectedButtonsWrapper;
