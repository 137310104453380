import { Link } from "@mui/material";
import { SBLinkStyle } from "../styles/SBStyles";

export interface SBHeaderLinkPrimary {
  label: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
}

export type SBHeaderLinkType = SBHeaderLinkPrimary;

const SBHeaderLink: React.FC<SBHeaderLinkType> = ({ label, onClick }) => {
  return (
    <Link
      sx={{
        ...SBLinkStyle,
      }}
      onClick={onClick}
    >
      {label}
    </Link>
  );
};

export default SBHeaderLink;
