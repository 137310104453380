import { IAvailabilitiesBeachChairProps } from "../../../models/availability/IAvailabilitiesBeachChairProps";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import lock from "../../../assets/lock.png";
import { EColor } from "../../../models/beachChairs/EColors";
import { Reason } from "../../../models/availability/EAvailabilityTime";
import { Box, CardMedia, Typography } from "@mui/material";
import { SBColor } from "../../SBComponents/styles/SBStyles";

const getTimeStyle = (timeEnum: string) => {
  switch (timeEnum) {
    case "boxBackgroundFree":
      return {
        backgroundColor: customStyle.whiteColor,
      };
    case "boxBackgroundNoon":
      return {
        background: `linear-gradient(180deg, ${customStyle.whiteColor} 50%, ${customStyle.lightGray} 50%)`,
      };
    case "boxBackgroundAfterNoon":
      return {
        background: `linear-gradient(180deg, ${customStyle.lightGray} 50%, ${customStyle.whiteColor} 50%)`,
      };
    case "boxBackgroundAllDay":
      return {
        backgroundColor: customStyle.gray,
        color: "white",
      };
  }
};

const AvailabilitiesBeachChair = ({
  hasLock,
  isSeasonBeachChair,
  isInBooking,
  model,
  id,
  timeEnum,
  draggable,
  attributes,
  reason,
  isReserved,
}: IAvailabilitiesBeachChairProps) => {
  const { t } = useTranslation(["common"]);

  return (
    <Box
      sx={{
        height: "70px",
        width: "70px",
      }}
      draggable={draggable}
    >
      <Box
        data-testid="box"
        sx={{
          ...{
            height: "100%",
            width: "100%",
            display: "grid",
            gridTemplateRows: "1px 45px 1px",
            borderRadius: "10%",
            border: "1px solid",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              boxShadow: "0 0 11px rgba(33,33,33,.2)",
              cursor: "pointer",
            },
            "&.dropArea": {
              borderRadius: "10%",
              cursor: "pointer",
              color: customStyle.mainColor,
              border: `1px dashed ${customStyle.mainColor}`,
            },
          },
          ...(isReserved
            ? {
                backgroundColor: customStyle.whiteColor,
                color: "green",
                border: `1px dashed green`,
              }
            : getTimeStyle(timeEnum)),
          ...(isInBooking
            ? {
                backgroundColor: `${customStyle.secondaryColor} !important`,
                color: customStyle.whiteColor,
              }
            : getTimeStyle(timeEnum)),
          ...(draggable && {
            "&:hover": {
              background: `${customStyle.secondaryColor}`,
              color: customStyle.whiteColor,
              cursor: "pointer",
            },
          }),
          ...(reason === Reason.STOP_SALE && {
            border: `2px dashed ${customStyle.mainColor}`,
          }),
          ...(attributes?.color && {
            borderTop: `2px dashed ${
              EColor[attributes.color as keyof typeof EColor]
            } !important`,
          }),
          ...(draggable && {
            border: `1px solid ${SBColor.orange}`,
          }),
        }}
      >
        <Box
          sx={{
            display: "grid",
            width: "70px",
            height: "30px",
            gridTemplateColumns: "50% 50%",
          }}
        >
          {hasLock ? (
            <CardMedia
              sx={{
                transform: "translate(-30%, 5%)",
                height: "30px",
                width: "20px",
              }}
              data-testid="hasLock"
              component="img"
              src={lock}
            />
          ) : (
            <Box />
          )}
          {isSeasonBeachChair ? (
            <EventBusyIcon
              data-testid="isSeasonBeachChair"
              sx={{
                gridColumnStart: "2px",
                gridColumnEnd: "2px",
                color: customStyle.mainColor,
                transform: "translate(13%, 20%)",
                height: "30px !important",
                width: "40px !important",
              }}
            />
          ) : (
            <Box />
          )}
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            gridRow: "2px",
          }}
          data-testid="id"
        >
          {id}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            gridRow: "3px",
          }}
          data-testid="model"
        >
          {t(`shortModels.${model}` as any)}
        </Typography>
      </Box>
    </Box>
  );
};

export default AvailabilitiesBeachChair;
