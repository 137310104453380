import React from "react";
import { Box } from "@mui/material";
import { SBInsideGrid, SBInsideGridWidth } from "../styles/SBStyles";
import { Props } from "../../../utils/types/Props";

const SBFormGridWrapper: React.FC<Props> = ({ children }) => {
  return (
    <Box sx={{ ...SBInsideGrid }} data-testid="inside-card">
      <Box sx={{ ...SBInsideGridWidth }} data-testid="inside-card">
        {children}
      </Box>
    </Box>
  );
};

export default SBFormGridWrapper;
