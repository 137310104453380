import { AxiosResponse } from "axios";
import { Action } from "redux";
import { all, call, put, debounce } from "typed-redux-saga";
import { searchBeachChairs } from "../../api/beachChairApi";
import { searchBookings } from "../../api/bookingApi";
import {
  getFilteredCustomers,
  ICustomerResponse,
  searchCustomers,
} from "../../api/customerApi";
import {
  searchRequest,
  searchSuccess,
  searchFailure,
} from "../reducers/searchReducer";

function* searchSaga(action: Action) {
  try {
    if (searchRequest.match(action)) {
      const { searchPhrase } = action.payload;
      let customerSearchresponse: AxiosResponse<ICustomerResponse>;
      if (isNaN(+searchPhrase)) {
        customerSearchresponse = yield* call(searchCustomers, searchPhrase);
      } else {
        customerSearchresponse = yield* call(
          getFilteredCustomers,
          0,
          0,
          `filters[id]=${+searchPhrase}`
        );
      }

      const bookingsSearchresponse = yield* call(searchBookings, searchPhrase);
      const beachChairsSearchresponse = yield* call(
        searchBeachChairs,
        searchPhrase
      );
      yield* put(
        searchSuccess({
          searchedCustomers: customerSearchresponse.data.items,
          searchedBookings: bookingsSearchresponse.data,
          searchedBeachChairs: beachChairsSearchresponse.data.items,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      searchFailure({
        error: e.message,
      })
    );
  }
}

function* SearchSaga() {
  yield* all([debounce(700, searchRequest.type, searchSaga)]);
}

export default SearchSaga;
