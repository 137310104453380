import { useQuery } from "@tanstack/react-query";
import { featuresApi } from "../../../api/apiTanStack";
import { BeachChairModel } from "../../../api-client/generated";

export const useBeachChairsAvailabilities = (
  id: number,
  start?: string,
  end?: string,
  row?: number,
  model?: BeachChairModel,
  free?: boolean,
  lock?: boolean
) =>
  useQuery({
    queryKey: [
      "beachChairsAvailabilities",
      id,
      start,
      end,
      row,
      model,
      free,
      lock,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        `filters[start]=${start}&filters[end]=${end}${
          free ? `&filters[free]=${free}` : ""
        }${lock ? `&filters[lock]=${lock}` : ""}${
          model ? `&filters[model]=${model}` : ""
        }${row ? `&filters[row]=${row}` : ""}`
      );
      return featuresApi
        .sectionsControllerGetSectionsBeachChairs(
          {
            id,
          },
          { params: filters }
        )
        .then((response) => response.data);
    },
    enabled: !!start && !!end && id > 0,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
