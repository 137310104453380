import React from "react";
import { useRowForm } from "../../../../utils/customHooks/useRowField";
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  TextField,
} from "@mui/material";
import {
  errorText,
  iconAddItem,
  iconResetItem,
  inputBoxRoot,
  inputRoot,
} from "../../../../utils/customStyles/globalStyles";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

export interface IRowProps {
  sectionId: number;
  type: string;
}

const Row = ({ sectionId, type }: IRowProps) => {
  const { control, errors, onSubmit, onReset } = useRowForm(sectionId, type);
  const { t } = useTranslation(["common"]);
  return (
    <Box sx={{ ...inputBoxRoot }}>
      <FormControl error={!!errors.name} sx={{ ...inputRoot }}>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field }) => (
            <TextField
              disabled={type === "show"}
              sx={{ ...inputRoot }}
              data-testid="name"
              label={t("destinations.name")}
              error={!!errors.name}
              {...field}
              InputProps={{ disableUnderline: type === "show" }}
              variant="standard"
            />
          )}
        />
        <FormHelperText sx={{ ...errorText }}>
          {errors.name && (errors.name?.message as any)}
        </FormHelperText>
      </FormControl>
      <IconButton
        disabled={type === "show"}
        aria-label="reset"
        data-testid="reset-trigger"
        sx={{ ...iconResetItem }}
        onClick={() => onReset()}
      >
        <RestartAltIcon />
      </IconButton>
      <IconButton
        disabled={type === "show"}
        aria-label="add"
        data-testid="add-trigger"
        sx={{ ...iconAddItem }}
        onClick={() => onSubmit()}
      >
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default React.memo(Row);
