import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";
import {
  getAllCustomers,
  getCustomerById,
  getCustomerPages,
  getCustomerSearchById,
} from "../reducers/customersReducer";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import { customerToRow } from "../../utils/conversions/customerToRow";
import { IFilterData } from "../../models/dataTable/IFilterData";
const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.customers.pending;

const getAppliedFilterData = (state: AppState) =>
  state.customers.appliedFilterData;

const getDraftFilterData = (state: AppState) => state.customers.draftFilterData;

const getError = (state: AppState) => state.customers.error;

export const getCustomersSelector = createSelector(
  getAllCustomers,
  (customers) => customers
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getAppliedFilterDataSelector = createSelector(
  getAppliedFilterData,
  (filterData) => filterData
);

export const getDraftFilterDataSelector = createSelector(
  getDraftFilterData,
  (newFilterData) => newFilterData
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getCustomerPage = (
  pageNumber: number,
  rowsPerPage: number,
  orderBy: string,
  orderDir: string,
  appliedFilter: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const filter = appliedFilter && filterDataToString(appliedFilter);

    const page = getCustomerPages(
      state,
      `${filter ? `filter:${filter}:` : ""}${pageNumber}:${rowsPerPage}${
        orderBy ? ":order:" + orderBy + ":" + orderDir : ""
      }`
    );
    return (
      page &&
      page!.dataIds.map((id) => getCustomerById(state, id)!).map(customerToRow)
    );
  });

export const getCustomerPageCounts = (
  pageNumber: number,
  rowsPerPage: number,
  orderBy: string,
  orderDir: string,
  appliedFilter: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const filter = appliedFilter && filterDataToString(appliedFilter);

    const page = getCustomerPages(
      state,
      `${filter ? `filter:${filter}:` : ""}${pageNumber}:${rowsPerPage}${
        orderBy ? ":order:" + orderBy + ":" + orderDir : ""
      }`
    );
    return page && page!.count;
  });

export const getCustomerSearch = (searchPhrase?: string) =>
  createSelector(getState, (state) => {
    const searchString = searchPhrase && searchPhrase;
    const search = getCustomerSearchById(state, `sp:${searchString}`);
    return search && search!.dataIds.map((id) => getCustomerById(state, id)!);
  });

export const getCustomerByIdSelector = (id: number) =>
  createSelector(getState, (state) => getCustomerById(state, id));

export const getCustomerSagaSelector = (id: number) =>
  createSelector(getState, (state) => {
    const customer = getCustomerById(state, id);
    return customer;
  });
