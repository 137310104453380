import { Box, Divider, Stack, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Print as PrintIcon } from "@mui/icons-material";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { formatPrice } from "../../../utils";
import { useGetClosingReport } from "../hooks/";
import {
  FiskalyBusinessCaseTypeEnum,
  FiskalyVatIds,
  vatPercent,
} from "./types";
import {
  getCashBalanceExcl,
  getCashBalanceIncl,
  getInitialBalance,
  getPaymentTypes,
  getStartDate,
} from "./utils";
import { useReactToPrint } from "react-to-print";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import {
  breadcrumb,
  button,
  buttonFlex,
  componentRoot,
  link,
  typographyLocation,
} from "../../../utils/customStyles/globalStyles";
import { Breadcrumbs } from "@mui/material";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import { useHistory } from "react-router";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

export const CashPointReport: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const printRef = React.useRef<HTMLDivElement>(null);
  const { locationId, id: closingId } = useParams<{
    locationId?: string;
    id?: string;
  }>();
  const { closingReport } = useGetClosingReport({ closingId, locationId });
  const history = useHistory();
  const handlePrint = () => {
    if (!closingReport) return;
    reactToPrint();
  };

  const reactToPrint = useReactToPrint({
    content: () => printRef.current,
    bodyClass: "cash-point-report",
  });

  const cashPointClosingData =
    closingReport?.cashPointClosing.cash_point_closing;
  const organization = closingReport?.organization;
  const location = closingReport?.location;

  const initialBalance =
    getInitialBalance(cashPointClosingData?.cash_statement?.business_cases) ||
    0;
  const cashBalance =
    initialBalance +
    (cashPointClosingData?.cash_statement?.payment?.cash_amount || 0);
  const cashBalanceExcl = getCashBalanceExcl(
    cashPointClosingData?.cash_statement.business_cases
  );
  const cashBalanceIncl = getCashBalanceIncl(
    cashPointClosingData?.cash_statement.business_cases
  );

  const paymentTypes = getPaymentTypes(cashPointClosingData?.transactions);

  const startDate = closingReport?.cashPointClosing
    ? getStartDate(closingReport?.cashPointClosing)
    : format(new Date(), "dd.MM.yyyy HH:mm");

  const closingUserName = `${closingReport?.user?.firstName} ${closingReport?.user?.lastName}`;

  return (
    <SBMainComponentGridWrapper>
      <SubPageHeader headerTitle={t("cashPointMenu.cashPointReports")}>
        <SBBreadcrumbsWrapper>
          <SBHeaderLink
            label={t("home")}
            onClick={() => {
              history.push(`/`);
            }}
          />
          <SBHeaderLink
            label={t(`cashPointMenu.cashPointReports`)}
            onClick={() => {
              history.push(`/cashpoint/${locationId}/cash-point-reports`);
            }}
          />
          <SBHeaderTypography
            label={`${t("cashPointMenu.cashPointReport")} - ${
              cashPointClosingData?.cash_point_closing_export_id
            }`}
          />
          <SBHeaderTypography label={t("overview")} />
        </SBBreadcrumbsWrapper>
        <Box sx={{ ...buttonFlex }}>
          <Button
            variant="contained"
            sx={{ ...button }}
            startIcon={<PrintIcon />}
            data-testid="add-button"
            onClick={() => {
              handlePrint();
            }}
          >
            {t(`print`)}
          </Button>
        </Box>
      </SubPageHeader>
      <Box
        sx={{
          height: `calc(65vh - 10px)`,
          overflowY: "scroll",
          boxSizing: "content-box",
          "&::-webkit-scrollbar": {
            width: 1,
            height: 1,
          },
        }}
      >
        <Box ref={printRef}>
          <Box id="cash-point-report">
            <Stack spacing={4}>
              <Box
                sx={{
                  width: "320px",
                  backgroundColor: "white",
                  paddingBottom: "100px",
                }}
                p={3}
              >
                <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Z-Bericht
                </Typography>
                <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                  {organization?.display_name}
                </Typography>
                <Typography>{organization?.address_line1}</Typography>
                <Typography>
                  {organization?.zip} {organization?.town}
                </Typography>
                <Typography>
                  Steuernummer: {organization?.tax_number}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography sx={{ fontWeight: "bold", textAlign: "center" }}>
                  Berichtnr.{" "}
                  {cashPointClosingData?.cash_point_closing_export_id}
                </Typography>

                <Typography sx={{ textAlign: "center" }}>
                  {format(
                    new Date(
                      (cashPointClosingData?.head?.export_creation_date || 0) *
                        1000
                    ),
                    "dd.MM.yyyy HH:mm"
                  )}
                </Typography>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Mitarbeiter:</Typography>
                  <Typography>{closingUserName}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Kasse:</Typography>
                  <Typography>{location?.name}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Eröffnung:</Typography>
                  <Typography>{startDate}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Geschlossen:</Typography>
                  <Typography>
                    {format(
                      new Date(
                        (cashPointClosingData?.head?.export_creation_date ||
                          0) * 1000
                      ),
                      "dd.MM.yyyy HH:mm"
                    )}
                  </Typography>
                </Stack>

                <Divider sx={{ my: 2 }} />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Vorgänge:</Typography>
                  <Typography>
                    {cashPointClosingData?.transactions.length}
                  </Typography>
                </Stack>

                <Divider sx={{ my: 2 }} />

                <Typography fontWeight="bold">Geschäftsvorfälle:</Typography>

                {cashPointClosingData?.cash_statement.business_cases
                  .filter(
                    (busiCa) =>
                      busiCa.type !== FiskalyBusinessCaseTypeEnum.ANFANGSBESTAND
                  )
                  .map((bc) => (
                    <Stack
                      key={`${bc.amounts_per_vat_id}-${bc.name}-${bc.type}`}
                      direction="row"
                      justifyContent="space-between"
                      spacing={5}
                    >
                      <Typography>{bc.name}:</Typography>
                      <Typography>
                        {formatPrice(bc.amounts_per_vat_id[0]?.incl_vat || 0)}
                      </Typography>
                    </Stack>
                  ))}

                <Divider sx={{ my: 2 }} />

                <Typography fontWeight="bold">Steuersätze:</Typography>

                {cashPointClosingData?.cash_statement.business_cases.flatMap(
                  (bc) =>
                    bc.amounts_per_vat_id
                      .filter(
                        (vat) =>
                          vat.vat_definition_export_id !==
                          FiskalyVatIds.NON_TAXABLE
                      )
                      .map((vat) => (
                        <Stack
                          key={`${vat.excl_vat}-${vat.incl_vat}-${vat.vat}`}
                          direction="row"
                          justifyContent="space-between"
                          spacing={5}
                        >
                          <Typography>
                            MwSt.{" "}
                            {
                              vatPercent[
                                FiskalyVatIds[
                                  vat.vat_definition_export_id as unknown as keyof typeof FiskalyVatIds
                                ]
                              ]
                            }{" "}
                            %:
                          </Typography>
                          <Typography>{formatPrice(vat.vat || 0)}</Typography>
                        </Stack>
                      ))
                )}

                <Divider sx={{ my: 2 }} />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Umsatz (Netto):</Typography>
                  <Typography>{formatPrice(cashBalanceExcl)}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Umsatz (Brutto):</Typography>
                  <Typography>{formatPrice(cashBalanceIncl)}</Typography>
                </Stack>

                {paymentTypes.map((pt) => (
                  <Stack
                    key={`${pt.amount}-${pt.type}`}
                    direction="row"
                    justifyContent="space-between"
                    spacing={5}
                  >
                    <Typography>{pt.type} (Brutto):</Typography>
                    <Typography>{formatPrice(pt.amount || 0)}</Typography>
                  </Stack>
                ))}

                <Divider sx={{ my: 2 }} />

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Anfangsbestand:</Typography>
                  <Typography>{formatPrice(initialBalance)}</Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={5}
                >
                  <Typography>Endbestand:</Typography>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {formatPrice(cashBalance)}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Box>
    </SBMainComponentGridWrapper>
  );
};
