import React, { useMemo, useState } from "react";
import {
  Box,
  IconButton,
  //TextField
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SBTable from "../../SBTable/SBTable";
import SepaXMLHeader from "./sepaXMLHeader";
import { dateToProperPrintDateFormat } from "../../../utils/conversions/dataConversion";
import { CloudDownload } from "@mui/icons-material";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { useGetClosingReports } from "../closingReports/hooks/useGetClosingReports";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";

const SepaXMLs = () => {
  const { t } = useTranslation(["common"]);
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("reports/sepa_xmls");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetClosingReports(
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      "SEPA_XML_REPORT"
    );

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "name",
        header: `${t("name")}`,
        maxSize: 100,
      },
      {
        accessorKey: "details",
        header: `${t("detailsLabel")}`,
        accessorFn: (originalRow: any) => {
          return originalRow?.details?.startISODate &&
            originalRow?.details?.endISODate
            ? originalRow?.details?.startISODate ===
              originalRow?.details?.endISODate
              ? dateToProperPrintDateFormat(originalRow?.details?.startISODate)
              : `${dateToProperPrintDateFormat(
                  originalRow?.details?.startISODate
                )} - ${dateToProperPrintDateFormat(
                  originalRow?.details?.endISODate
                )}`
            : t(`noClosingReportsDate`);
        },
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
        maxSize: 80,
      },
      {
        accessorKey: "createdAt",
        header: `${t("createdAtReport")}`,
        accessorFn: (originalRow: any) =>
          dateToProperPrintDateFormat(originalRow.createdAt),
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
        maxSize: 50,
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("sepaXMLs")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const url = data?.items[cell.row.index].url ?? "";
      return (
        <Box sx={{ float: "right" }}>
          <IconButton
            aria-label="delete"
            data-testid="table-body-delete"
            sx={{ color: customStyle.mainColor }}
            type="submit"
            target="_blank"
            href={url}
          >
            <CloudDownload />
          </IconButton>
        </Box>
      );
    },
    [data]
  );

  return (
    <SBMainListWrapper>
      <SepaXMLHeader />
      <SBTable
        columnVisibilityStoreName="SepaXMLColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
    </SBMainListWrapper>
  );
};

export default SepaXMLs;
