// eslint-disable-next-lineimport {axiosInstance, axiosRefreshInstance} from './api';
import { QueryClient } from "@tanstack/react-query";
import { axiosInstance, axiosRefreshInstance } from "./apiTanStack";
import jwt_decode from "jwt-decode";
import { useAuthStore } from "../components/auth/hooks/authStore";
const setupInterceptors = (queryClient: QueryClient) => {
  axiosInstance.interceptors.request.use(
    async (config) => {
      const authZustand = useAuthStore.getState();
      const token = authZustand.accessToken;
      const currentDate = new Date();
      if (token) {
        const decodedToken: { exp: number } = jwt_decode(token);
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          try {
            const email = authZustand.email;
            const refreshToken = authZustand.refreshToken;
            await axiosRefreshInstance
              .post("api/v1/features/users/refresh", {
                refreshToken: refreshToken,
                email: email,
              })
              .then((newToken) => {
                const jwt = newToken.data;
                authZustand.actions.setAccessToken(jwt, true, email);
                authZustand.actions.setRefreshToken(refreshToken, true);
                queryClient.refetchQueries();
              })
              .catch((_) => {
                authZustand.actions.clearTokens();
              });
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

export default setupInterceptors;
