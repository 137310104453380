import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useQuery,
} from "@tanstack/react-query";
import {
  OrderByDirection,
  ResponseUserDto,
} from "../../../api-client/generated";
import { usersApi } from "../../../api/apiTanStack";
import { PaginationMeta, RefetchInfinityQuery } from "../../../types";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

interface VendorUsersPage {
  data: ResponseUserDto[];
  totalItems: number;
  nextPage: number | undefined;
}

export interface UseGetVendorUsers {
  users: VendorUsersPage[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<
      {
        data: ResponseUserDto[];
        nextPage: number | undefined;
      },
      unknown
    >
  >;
  hasNextPage?: boolean;
  paginationMeta?: PaginationMeta;
  refetch: RefetchInfinityQuery<ResponseUserDto>;
}

export const useGetVendorUsers = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "vendorUsers",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => `filters[${filter.id}]=${filter.value}`)
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return usersApi
        .usersControllerFindMany(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
