import { useQuery } from "@tanstack/react-query";
import { featuresApi } from "../../../api/apiTanStack";

export const useOpenBookingsInPeriodBeachChairIds = (
  startFrom?: string | undefined,
  startTo?: string | undefined
) =>
  useQuery({
    queryKey: ["useOpenBookingsInPeriodBeachChairIds", startFrom, startTo],
    queryFn: async () => {
      const filters = new URLSearchParams(
        `filters[startFrom]=${startFrom}&filters[startTo]=${startTo}&filters[status]=OPEN`
      );
      return featuresApi
        .bookingDetailsControllerFindManyBeachChairBookings(
          {
            page: 0,
          },
          { params: filters }
        )
        .then((response) => response.data.items);
    },
    enabled: !!startFrom && !!startTo,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
