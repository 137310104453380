import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BookingsApiBookingsControllerRemoveFromBookingRequest as Variables } from "../../../../api-client/generated";
import { bookingsApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";
import { useDeleteBooking } from "./useDeleteBooking";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

export const useDeleteBookingBeachChair = () => {
  const { t } = useTranslation(["common"]);
  const { pathname } = useLocation();
  const history = useHistory();
  const { setBookingInProgress, bookingInProgress, clearBookingInProgress } =
    useBookingInProgressStore();
  const queryClient = useQueryClient();
  const deleteBooking = useDeleteBooking();
  return useMutation(
    ({ id, beachChairBookingId }: Variables) =>
      bookingsApi.bookingsControllerRemoveFromBooking({
        id,
        beachChairBookingId,
      }),
    {
      onSuccess: (data, variables) => {
        if (
          data.data.beachChairBookings.length === 0 &&
          data.data.extras.length === 0
        ) {
          deleteBooking.mutate({ id: variables.id });
          if (
            bookingInProgress &&
            bookingInProgress.id === Number(variables.id)
          ) {
            clearBookingInProgress();
          }
          if (!pathname.includes(`/availabilities/box/`)) {
            history.push(`/bookings`);
          }
        } else {
          if (bookingInProgress) {
            queryClient.invalidateQueries(["booking", Number(variables.id)]);
            setBookingInProgress(data.data);
          }
        }
        queryClient.refetchQueries(["BeachChairBookings"]);
        queryClient.refetchQueries(["useOpenBookingsInPeriodBeachChairIds"]);
        queryClient.refetchQueries(["beachChairsAvailabilities"]);
        toast.success(t(`toastMessages.deleteSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.deleteFailure`));
      },
    }
  );
};
