import { useQuery } from "@tanstack/react-query";
import { billingsApi } from "../../../../api/apiTanStack";

export const useGetBilling = (id?: number) =>
  useQuery({
    queryKey: ["GetBilling", Number(id)],
    queryFn: async () => {
      return billingsApi
        .billingVendorsControllerGet({
          id: id ?? 0,
        })
        .then((response) => {
          return response.data;
        });
    },
    enabled: !!id,
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
