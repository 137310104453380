import { BeachChairBookingResponseDto } from "../../api-client/generated";
import {
  dateToProperDateFormat,
  dateToProperTimeFormat,
} from "../conversions/dataConversion";
import { useForm } from "react-hook-form";
import { useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

export function useSelectedBeachChair(
  bookingBeachChair?: BeachChairBookingResponseDto
) {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number(),
        publicNumber: yup.string(),
        fromDate: yup.string(),
        fromTime: yup.string(),
        toDate: yup.string(),
        toTime: yup.string(),
        price: yup.number(),
      }),
    []
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      publicNumber: "",
      fromDate: "",
      fromTime: "",
      toDate: "",
      toTime: "",
      price: 0,
    },
  });

  useEffect(() => {
    reset({
      publicNumber: bookingBeachChair?.publicNumber ?? "",
      fromDate: bookingBeachChair?.start
        ? dateToProperDateFormat(bookingBeachChair.start)
        : "",
      fromTime: bookingBeachChair?.start
        ? dateToProperTimeFormat(bookingBeachChair.start)
        : "",
      toDate: bookingBeachChair?.end
        ? dateToProperDateFormat(bookingBeachChair.end)
        : "",
      toTime: bookingBeachChair?.end
        ? dateToProperTimeFormat(bookingBeachChair.end)
        : "",
      price: bookingBeachChair?.price ?? 0,
    });
  }, [bookingBeachChair, reset]);

  return {
    control,
    errors,
    handleSubmit,
    isSubmitting,
  };
}
