import React from "react";
import { Box } from "@mui/material";
import {
  instantBookingsContentBox,
} from "../../../utils/customStyles/globalStyles";
import InstantBookingsHeader from "./instantBookingsHeader";
import InstantBookingCard, {
  EInstantBooking,
} from "./instantBookingCard/instantBookingCard";
import { useDispatch, useSelector } from "react-redux";
import { getExtrasSelector } from "../../../store/selectors/extrasSelectors";
import { getAllAvailableBeachChairsRequest } from "../../../store/reducers/availabilityReducer";
import { getExtrasRequest } from "../../../store/reducers/extrasReducer";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import VendorBookings from "../vendorBooking/vendorBooking";
import { useInstantBookingCurrentDatesStore } from "./hooks/useAvailabilitiesDatesStore";
import { useAvailabilitiesBeachChairs } from "../../../utils/tanstackHooks/useBookingsTanStack";

const InstantBookings = () => {
  const dispatch = useDispatch();
  const { instantBookingCurrentDate } = useInstantBookingCurrentDatesStore();
  const storedExtras = useSelector(getExtrasSelector);

  const { allAvailableBeachChairs: storedBeachChairs } =
    useAvailabilitiesBeachChairs(
      instantBookingCurrentDate?.toISOString(),
    );
  React.useEffect(() => {
    dispatch(
      getAllAvailableBeachChairsRequest({
        date: instantBookingCurrentDate.toISOString(),
      })
    );
    // eslint-disable-next-line
  }, [instantBookingCurrentDate]);

  React.useEffect(() => {
    dispatch(
      getExtrasRequest({
        itemsPerPage: 0,
        page: 0,
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <SBMainComponentGridWrapper>
      <InstantBookingsHeader />
      <Box sx={{ ...instantBookingsContentBox }} border={0}>
        <VendorBookings />
        <InstantBookingCard
          type={EInstantBooking.beachChairBooking}
          storedBeachChairs={storedBeachChairs as any}
        />
        {storedExtras.length > 0 && (
          <>
            <InstantBookingCard
              type={EInstantBooking.extraBooking}
              storedExtras={storedExtras}
            />
            <InstantBookingCard
              type={EInstantBooking.beachChairAndExtraBooking}
              storedBeachChairs={storedBeachChairs as any}
              storedExtras={storedExtras}
            />
          </>
        )}
      </Box>
    </SBMainComponentGridWrapper>
  );
};

export default InstantBookings;
