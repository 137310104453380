import { Box, CircularProgress } from "@mui/material";
import { useMenuZustand } from "../layout/Navigation/hooks/useMenuZustand";
import { useState } from "react";
import logo_sbl from "./../../assets/logo_sbl.png";
import { useGetVendor } from "../vendor";
import { getVendorIdSelector } from "../../store/selectors/adminSelectors";
import { useSelector } from "react-redux";
import IframeResizer from 'iframe-resizer-react'
import { SBColor } from "../SBComponents/styles/SBStyles";

const Home = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const adminvendorId = useSelector(getVendorIdSelector);
  const { data: vendor } = useGetVendor(adminvendorId);
  const { open } = useMenuZustand();
  return (
    <Box
      sx={{
        height: "100%",
        maxHeight: "calc(100% - 140px)",
        width: open ? "calc(100vw - 270px)" : "calc(100vw - 70px)",
        display: "grid",
      }}
    >
      {isLoading && (
        <Box
          sx={{
            height: "calc(100% - 140px)",
            width: open ? "calc(100vw - 270px)" : "calc(100vw - 70px)",
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      {vendor && vendor.configuration && vendor.configuration.metabaseUrl ? (
        <Box
          sx={{
            height: "calc(100vh - 140px)",
            maxHeight: "calc(100vh - 140px)",
            width: open ? "calc(100vw - 270px)" : "calc(100vw - 70px)",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: SBColor.scroll,
              borderRadius: "5px",
            },
          }}
        >
          <IframeResizer
            heightCalculationMethod="lowestElement"
            src={vendor?.configuration?.metabaseUrl}
            height={isLoading ? "0px" : "100vh"}
            onLoad={() => {
              setIsLoading(false);
            }}
            style={{ width: '1px', minWidth: '100%', border: "none" }}
            scrolling="omit"
          />
        </Box>
      ) : (
        <Box
          sx={{
            height: "calc(100% - 140px)",
            width: open ? "calc(100vw - 300px)" : "calc(100vw - 70px)",
            display: "grid",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={logo_sbl} alt={"logo_sbl"} width="100%" />
        </Box>
      )}
    </Box>
  );
};

export default Home;
