import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { OrderByDirection } from "../../../api-client/generated";
import { featuresApi } from "../../../api/apiTanStack";

export const useGetBeachChairBookingsList = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "BeachChairBookings",
      columnFilters,
      globalFilter,
      pagination,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .flatMap((filter) => {
            if (
              filter.id === "start" &&
              (filter.value as any)[0] !== undefined &&
              (filter.value as any)[1] !== undefined
            ) {
              return `filters[startFrom]=${new Date(
                (filter.value as any)[0] || undefined
              ).toISOString()}&filters[startTo]=${new Date(
                (filter.value as any)[1] || undefined
              ).toISOString()}`;
            }
            if (
              filter.id === "end" &&
              (filter.value as any)[0] !== undefined &&
              (filter.value as any)[1] !== undefined
            ) {
              return `filters[endFrom]=${new Date(
                (filter.value as any)[0] || undefined
              ).toISOString()}&filters[endTo]=${new Date(
                (filter.value as any)[1] || undefined
              ).toISOString()}`;
            }
            if (
              filter.id === "bookingDate" &&
              (filter.value as any)[0] !== undefined &&
              (filter.value as any)[1] !== undefined
            ) {
              return `filters[bookingDateFrom]=${new Date(
                (filter.value as any)[0] || undefined
              ).toISOString()}&filters[bookingDateTo]=${new Date(
                (filter.value as any)[1] || undefined
              ).toISOString()}`;
            }
            if (String(filter.value) === ",") {
              return;
            }
            return `filters[${filter.id}]=${filter.value}`;
          })
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return featuresApi
        .bookingDetailsControllerFindManyBeachChairBookings(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((response) => response.data);
    },
    keepPreviousData: true,
    cacheTime: 600000,
    staleTime: 600000,
  });
