import { Box, InputLabel, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useBookingDetailForm } from "../../../../utils/customHooks/useBookingDetailForm";
import NotesIcon from "@mui/icons-material/Notes";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeBookingTabRequest } from "../../../../store/reducers/bookingsReducer";
import { useParams } from "react-router";
import { PaymentActionButton } from "../components";
import { useUpdateBooking } from "../hooks";
import SBTextField from "../../../SBComponents/SBForms/SBTextField";
import SBTextAreaDatePicker from "../../../SBComponents/SBForms/SBTextAreaDatePicker";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import NumbersIcon from "@mui/icons-material/Numbers";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import {
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../../SBComponents/styles/SBStyles";
import { Save, Schedule } from "@mui/icons-material";
import dayjs from "dayjs";
import { BookingDetailsResponseDto } from "../../../../api-client/generated";
import SBTextAreaComment from "../../../SBComponents/SBForms/SBTextAreaComment";

export interface IBookingDetailProps {
  tab: number;
  booking?: BookingDetailsResponseDto;
}

const BookingDetail = ({ tab, booking }: IBookingDetailProps) => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { control, setValue, errors, onSubmit } = useBookingDetailForm(booking);
  const storedExtras = useMemo(() => booking?.extras ?? [], [booking]);
  const updateBooking = useUpdateBooking();

  const isDisabled = type === "show";

  useEffect(() => {
    if (booking) {
      setValue("status", booking.status);
    }
    // eslint-disable-next-line
  }, [booking]);

  const days = useMemo(() => {
    if (booking && booking?.beachChairBookings?.length > 0) {
      let sum = 0;
      booking?.beachChairBookings.forEach((beachChair) => {
        const start = dayjs(beachChair.start);
        const end = dayjs(beachChair.end);
        sum += (end.diff(start, "day") ?? 0) + 1;
      });
      return `${sum} ${t("days")}`;
    } else {
      return "0";
    }
  }, [booking]);

  return (
    <Box
      sx={{
        paddingTop: "10px",
        paddingRight: "10px",
        paddingLeft: "10px",
        display: "grid",
        bottom: 0,
      }}
    >
      <form className="firstSection" onSubmit={onSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              lg: "70% 30%",
              md: "90% 10%",
              sm: "90% 10%",
              xs: "90% 10%",
            },
          }}
        >
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "30% 30% 30%",
                gap: "5%",
              }}
            >
              <SBTextField
                name="bookingRef"
                label={t("bookingNumber")}
                control={control}
                type={"show"}
                icon={<NumbersIcon />}
                isLoading={false}
                error={
                  errors?.bookingRef && t((errors.bookingRef as any)?.message)
                }
              />
              <SBTextAreaDatePicker
                name="createdAt"
                label={t("bookingDate")}
                control={control}
                type={"show"}
                icon={<CalendarTodayIcon />}
                isLoading={false}
                error={
                  errors?.createdAt && t((errors.createdAt as any)?.message)
                }
              />
              <Box sx={{ ...SBTextFieldGridStyle }}>
                <Box sx={{ ...SBTextFieldIconStyle }}>
                  <Schedule />
                </Box>
                <Box>
                  <InputLabel sx={{ ...SBLabelStyle }}>
                    {t("daysLabel")}
                  </InputLabel>
                  <TextField
                    value={days}
                    fullWidth
                    variant="outlined"
                    disabled={isDisabled}
                    label=""
                    sx={{ ...SBTextFieldStyle }}
                  />
                </Box>
              </Box>
            </Box>
            <SBTextAreaComment
              name="comment"
              label={t("comment")}
              control={control}
              type={"edit"}
              icon={<NotesIcon />}
              isLoading={false}
              error={errors?.comment && t((errors.comment as any)?.message)}
            />
            <SBTextAreaComment
              name="customerComment"
              label={t("customerComment")}
              control={control}
              type={"edit"}
              icon={<NotesIcon />}
              isLoading={false}
              error={
                errors?.customerComment &&
                t((errors.customerComment as any)?.message)
              }
            />
          </Box>
          <Box sx={{ paddingLeft: "20px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                {!isDisabled && tab === 0 && (
                  <SBLoadingButton
                    label={t("nextBooking")}
                    type="blue"
                    icon={<ArrowForwardIosIcon />}
                    onClick={() => {
                      dispatch(
                        changeBookingTabRequest({
                          value: storedExtras.length === 0 ? 2 : 1,
                        })
                      );
                    }}
                    isLoading={updateBooking.isLoading}
                  />
                )}

                {storedExtras.length !== 0 && !isDisabled && tab === 1 && (
                  <SBLoadingButton
                    label={t("nextBooking")}
                    type="blue"
                    icon={<ArrowForwardIosIcon />}
                    onClick={() => {
                      dispatch(changeBookingTabRequest({ value: 2 }));
                    }}
                    isLoading={updateBooking.isLoading}
                  />
                )}

                {!isDisabled && tab === 2 && (
                  <SBLoadingButton
                    label={t("nextBooking")}
                    type="blue"
                    icon={<ArrowForwardIosIcon />}
                    onClick={() => {
                      dispatch(changeBookingTabRequest({ value: 3 }));
                    }}
                    isLoading={updateBooking.isLoading}
                  />
                )}

                {!isDisabled &&
                  tab === 3 &&
                  booking &&
                  !booking.status.includes("CONFIRMED") &&
                  !booking.status.includes("PENDING") &&
                  !booking.status.includes("RESERVED") && (
                    <PaymentActionButton id={+id} />
                  )}
                <Box
                  sx={{
                    display: "flex",
                    position: "relative",
                    top: "140px",
                  }}
                >
                  <SBLoadingButton
                    label={t("save")}
                    type="blue"
                    icon={<Save />}
                    isLoading={false}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default React.memo(BookingDetail);
