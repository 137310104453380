import React, { useMemo } from "react";
import { Stack, Box, Typography, InputLabel, IconButton } from "@mui/material";
import {
  Add,
  CalendarToday,
  ChevronRight,
  Euro,
  Remove,
  Schedule,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelectedAvailableBeachChair } from "../../../utils/customHooks/useSelectedAvailableBeachChair";
import { useState } from "react";
import {
  DateTimePicker,
  LocalizationProvider,
  deDE,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "rc-time-picker/assets/index.css";
import { ILocation } from "../../../models/vendor/ILocation";
import { Button, SelectedGlobalBooking } from "../../common";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";
import { useGetBeachChair } from "../../beachChairList/hooks/useGetBeachChair";
import { useSelectedBeachChairDates } from "../../bookingsList/store/useSelectedBeachChairDates";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBNumberField from "../../SBComponents/SBForms/SBNumberField";
import {
  SBCheckBoxGridStyle,
  SBColor,
  SBDatePickerStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
} from "../../SBComponents/styles/SBStyles";
import { setBookingDateTime } from "../../../utils/conversions/locationsTimes";
import { useCheckBeachChairAvailable } from "../hooks/useCheckBeachChairAvailable";
import { TimeZone } from "../../../utils/conversions/dataConversion";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import tz from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(tz);

export interface ISelectedAvailableBeachChairExtendProps {
  id: number;
  handleClose: () => void;
  locationData?: ILocation;
  booking?: any;
}

const SelectedAvailableBeachChairExtend: React.FC<
  ISelectedAvailableBeachChairExtendProps
> = ({ id, handleClose, locationData, booking }) => {
  const { t } = useTranslation(["common"]);
  const { data: beachChair } = useGetBeachChair(id);
  const [isContinue, setIsContinue] = useState<boolean>(false);
  const { bookingInProgress } = useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );

  const { dateTime, setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();

  const { control, onSubmit, isDiscountPresent, errors } =
    useSelectedAvailableBeachChair({
      id,
      isContinue,
      beachChair,
      locationData,
      onSuccess: handleClose,
      booking,
      isExtended: true,
    });

  const {
    bookingStartDateTime,
    bookingStartDateTimeEnd,
    bookingEndDateTime,
    bookingEndDateTimeStart,
  } = setBookingDateTime(dateTime, locationData, beachChair?.afterHourBooking);

  const { data: available } = useCheckBeachChairAvailable(
    id,
    dateTime[0]?.tz(TimeZone).utc().format(),
    dateTime[1]?.tz(TimeZone).utc().format(),
    booking?.vendor?.publicReference
  );
  const dayDiff = useMemo(() => {
    return (dateTime[1]?.diff(dateTime[0], "day") ?? 0) + 1;
  }, [dateTime]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Stack gap="5px" padding={3}>
        {globalBookingId && <SelectedGlobalBooking />}
        <Typography
          variant="h5"
          sx={{
            color: SBColor.blue,
            gridColumn: "1/3",
            gridRow: 2,
          }}
        >
          {`Verlängern`}
        </Typography>
        <Typography
          variant="h4"
          sx={{
            gridColumn: "1/3",
            gridRow: 2,
          }}
        >
          {`Korb: ${beachChair?.publicNumber || ``}`}
        </Typography>

        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <Stack>
            <Box
              sx={{
                ...SBTextFieldGridStyle,
              }}
            >
              <Box sx={{ ...SBTextFieldIconStyle }}>
                <CalendarToday />
              </Box>
              <Box>
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {t("bookingStart")}
                </InputLabel>
                <DateTimePicker
                  disablePast
                  minDate={bookingStartDateTime}
                  minTime={bookingStartDateTime}
                  maxTime={bookingStartDateTimeEnd}
                  value={dateTime[0]}
                  onChange={(newDate) => {
                    if (newDate) {
                      const { bookingStartDateTime, bookingStartDateTimeEnd } =
                        setBookingDateTime(
                          [newDate, newDate],
                          locationData,
                          beachChair?.afterHourBooking
                        );
                      const isSame = newDate.isSame(dateTime[1], "day");
                      setBookingStartDateTime(
                        isSame ? newDate : bookingStartDateTime || newDate,
                        bookingStartDateTimeEnd || undefined
                      );
                    }
                  }}
                  sx={{ ...SBDatePickerStyle }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      sx: { ...SBDatePickerStyle, borderColor: "white" },
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                ...SBTextFieldGridStyle,
              }}
            >
              <Box sx={{ ...SBTextFieldIconStyle }}>
                <CalendarToday />
              </Box>
              <Box>
                <InputLabel sx={{ ...SBLabelStyle }}>
                  {t("bookingEnd")}
                </InputLabel>
                <DateTimePicker
                  minDate={dateTime[0]}
                  minTime={bookingEndDateTimeStart}
                  maxTime={bookingEndDateTime}
                  value={dateTime[1]}
                  onChange={(newDate) => {
                    if (newDate) {
                      const { bookingEndDateTime } = setBookingDateTime(
                        [newDate, newDate],
                        locationData,
                        beachChair?.afterHourBooking
                      );
                      const isSame = newDate.isSame(dateTime[0], "day");
                      setBookingEndDateTime(
                        isSame ? newDate : bookingEndDateTime || newDate
                      );
                    }
                  }}
                  sx={{ ...SBDatePickerStyle }}
                  slotProps={{
                    textField: {
                      variant: "outlined",
                      sx: { ...SBDatePickerStyle, borderColor: "white" },
                    },
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </LocalizationProvider>
        <Box
          sx={{
            ...SBTextFieldGridStyle,
          }}
        >
          <Box sx={{ ...SBTextFieldIconStyle }}>
            <Schedule />
          </Box>
          <Box>
            <InputLabel sx={{ ...SBLabelStyle }}>{t("daysLabel")}</InputLabel>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "40px 40px 20px",
              }}
            >
              <IconButton
                aria-label="deduct"
                sx={{
                  ...SBTextFieldIconStyle,
                  width: "40px",
                  background: SBColor.blue,
                  color: SBColor.white,
                  ":hover": {
                    background: SBColor.blue,
                    color: SBColor.white,
                  },
                  ":disabled": {
                    background: SBColor.lightGray,
                    color: SBColor.blue,
                  },
                }}
                onClick={() => {
                  dateTime[1] &&
                    setBookingEndDateTime(dateTime[1].add(-1, "day"));
                }}
                disabled={dayDiff <= 1}
              >
                <Remove />
              </IconButton>
              <Typography
                sx={{
                  display: "flex",
                  width: "40px",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  fontWeight: "bold",
                  color: SBColor.blue,
                }}
              >
                {dayDiff}
              </Typography>
              <IconButton
                aria-label="add"
                sx={{
                  ...SBTextFieldIconStyle,
                  width: "40px",
                  background: SBColor.blue,
                  color: SBColor.white,
                  ":hover": {
                    background: SBColor.blue,
                    color: SBColor.white,
                  },
                  ":disabled": {
                    background: SBColor.lightGray,
                    color: SBColor.blue,
                  },
                }}
                onClick={() => {
                  dateTime[1] &&
                    setBookingEndDateTime(dateTime[1].add(1, "day"));
                }}
              >
                <Add />
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box>
          <SBNumberField
            name="price"
            label={t("price")}
            control={control}
            type={"edit"}
            icon={<Euro />}
            isLoading={false}
            defaultValue={0}
            endAdornmentSymbol="€"
            error={errors?.price && t((errors.price as any)?.message)}
          />
        </Box>
        <Box sx={{ mt: "-20px" }}>
          {isDiscountPresent ? (
            <SBCheckBox
              name="discount"
              label={t("discount")}
              control={control}
              type={"edit"}
              isLoading={false}
              error={errors?.discount && t((errors.discount as any)?.message)}
            />
          ) : (
            <Box sx={{ ...SBCheckBoxGridStyle }}></Box>
          )}
        </Box>
        <Stack spacing={2}>
          <Button
            disabled={!available}
            type="submit"
            endIcon={<ChevronRight />}
            data-testid="update-button"
            onClick={() => setIsContinue(false)}
          >
            {t("addButtonRedirect")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default SelectedAvailableBeachChairExtend;
