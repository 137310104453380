import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RouterPayload } from "../actionPayload/routerPayloads";

const routerSlice = createSlice({
  name: "router",
  initialState: {},
  reducers: {
    routerRequest(state, action: PayloadAction<RouterPayload>) {},
  },
});

export const { routerRequest } = routerSlice.actions;

export default routerSlice.reducer;
