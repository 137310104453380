import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { featuresApi } from "../../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { changeDataRequest } from "../../../../store/reducers/bookingsReducer";

export function useReturnBeachChairMutation() {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation(
    (data: { id: number; end: string }) =>
      featuresApi
        .bookingDetailsControllerUpdateBeachChairBooking({
          id: data.id,
          updateBeachChairBookingBodyDto: {
            end: data.end,
          },
          timeZone: "Europe/Berlin",
        })
        .then((response) => response.data),
    {
      onSuccess() {
        queryClient.refetchQueries(["booking"]);
        //this will change after shift list to tanstack -> queryClient.invalidateQueries(["bookings"]);
        dispatch(changeDataRequest());
        toast.success(t(`toastMessages.RETURN_BEACH_CHAIR_SUCCESS`));
      },
      onError(e: Error) {
        toast.error(t(`toastMessages.RETURN_BEACH_CHAIR_ERROR`));
      },
    }
  );
}
