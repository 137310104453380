import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import React from "react";
import { useHistory, useParams } from "react-router";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import { Add } from "@mui/icons-material";

const VendorUserListHeader = () => {
  const { t } = useTranslation(["common"]);
  const { id } = useParams<{ id: string; type: string }>();
  const history = useHistory();
  return (
    <SubPageHeader headerTitle={t("title") + " - " + t("overview")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {id ? (
          <SBHeaderLink
            label={t("title")}
            onClick={() => {
              history.push(`/vendor_users`);
            }}
          />
        ) : (
          <SBHeaderTypography label={t("title")} />
        )}
        {id && <SBHeaderTypography label={id} />}
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <SBLoadingButton
          label={t("buttons.createUser")}
          type="blue"
          icon={<Add />}
          onClick={() => {
            history.push("/vendor_users/new");
          }}
        />
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default React.memo(VendorUserListHeader);
