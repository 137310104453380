import { Dialog } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  Marker,
  Polygon,
  useJsApiLoader,
} from "@react-google-maps/api";
import * as turf from "@turf/turf";
import { SBColor } from "../../../SBComponents/styles/SBStyles";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

export const getCentroid = (geoJson: object) => {
  const polygon = turf.polygon((geoJson as any).coordinates);
  return turf.centroid(polygon);
};

const containerStyle = {
  width: "550px",
  height: "500px",
};

const SBGeoLocationMapModal: React.FC<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  field: ControllerRenderProps<FieldValues, string>;
  geoJson: object;
  geoLocation: object;
}> = ({ showModal, setShowModal, geoJson, geoLocation, field }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: encodeURIComponent(
      "AIzaSyBWGae4Fpv0p0n-llM6Qysj4r_CvvhzIkw"
    ),
    language: "DE",
  });
  const [map, setMap] = React.useState(null);
  const [marker, setMarker] = useState<object>();

  useEffect(() => {
    setMarker(geoLocation);
  }, [geoLocation]);

  const onMapClick = (e) => {
    setMarker({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
    field.onChange(`${e.latLng.lat()}, ${e.latLng.lng()}`);
  };

  const center = useMemo(() => {
    const centroid = getCentroid(geoJson);
    return {
      lat: centroid.geometry.coordinates[1],
      lng: centroid.geometry.coordinates[0],
    };
  }, [geoJson]);

  const path = useMemo(() => {
    return (geoJson as any).coordinates[0].map((coord) => {
      return { lat: coord[1], lng: coord[0] };
    });
  }, [geoJson]);

  const onLoad = React.useCallback(
    function callback(map) {
      let mapBounds = new window.google.maps.LatLngBounds();
      (path as any).forEach((point) => {
        mapBounds.extend({
          lat: point.lat,
          lng: point.lng,
        });
      });
      map.fitBounds(mapBounds);
      setMap(map);
    },
    [path]
  );

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Dialog
      open={showModal}
      onClose={() => {
        setShowModal(false);
      }}
      sx={{ margin: 0 }}
    >
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {marker ? (
            <Marker
              position={{
                lat: parseFloat((marker as any).lat),
                lng: parseFloat((marker as any).lng),
              }}
            />
          ) : null}
          <Polygon
            onClick={onMapClick}
            path={path}
            options={{
              strokeColor: SBColor.blue,
              fillColor: SBColor.orange,
              strokeOpacity: 0.78,
              strokeWeight: 1,
              fillOpacity: 0.5,
            }}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
    </Dialog>
  );
};

export default SBGeoLocationMapModal;
