import { Email } from "@mui/icons-material";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper"
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton"
import SBLabel from "../../SBComponents/SBForms/SBLabel";
import { useVendorUserStatus } from "../components/vendorUserForm/useVendorUseStatus";
import { useParams } from "react-router";
import { useVndorResendConfirmationMutation } from "../components/vendorUserForm/useVndorResendConfirmationMutation";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import SBCircularProgress from "../../SBComponents/SBForms/SBCircularProgress";

export const VendorUserStatus: React.FC = () => {
    const { t } = useTranslation(["common"]);
    const { id } = useParams<{ id: string, type: string }>();
    const resendConfirmationMutation = useVndorResendConfirmationMutation();
    const {data, isLoading} = useVendorUserStatus(id);
    
    if(isLoading) return <SBCircularProgress />

    return <SBColumnGridWrapper>
        <Box sx={{display: "grid", pt: "20px", gridTemplateColumns: "auto auto", alignItems: "center"
        }}>
        {data?.status ? <SBLabel label={t(`${data?.status}`)} /> : null }
        {data?.status === "FORCE_CHANGE_PASSWORD" ? <Box sx={{display:"flex", flexDirection: "row-reverse"}}><SBLoadingButton
            label={t("ResendConfirmation")}
            type="blue"
            icon={<Email />}
            onClick={() => {
                resendConfirmationMutation.mutate({id});
            }}
            isLoading={resendConfirmationMutation.isLoading}
          /></Box>: null}
        </Box>
    </SBColumnGridWrapper>
}