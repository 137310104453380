import { combineReducers } from "redux";
import authReducer from "./authReducer";
import beachChairsReducer from "./beachChairsReducer";
import bookingsReducer from "./bookingsReducer";
import dataTableColumnsReducer from "./dataTableColumnsReducer";
import searchReducer from "./searchReducer";
import availabilitiesReducer from "./availabilityReducer";
import customersReducer from "./customersReducer";
import pricesReducer from "./pricesReducer";
import invoicesReducer from "./invoicesReducer";
import localesReducer from "./localesReducer";
import globalBookingReducer from "./globalBookingReducer";
import vendorsReducer from "./vendorsReducer";
import routerReducer from "./routerReducer";
import extrasReducer from "./extrasReducer";
import reportsReducer from "./reportsReducer";
import adminReducer from "./adminReducer";
import destinationReducer from "./destinationReducer";
import shortUrlReducer from "./shortUrlsReducer";
import rwdReducer from "./rwdReducer";
import lockReducer from "./lockReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  beachChairs: beachChairsReducer,
  bookings: bookingsReducer,
  search: searchReducer,
  dataTableColumns: dataTableColumnsReducer,
  availabilities: availabilitiesReducer,
  customers: customersReducer,
  prices: pricesReducer,
  locales: localesReducer,
  invoice: invoicesReducer,
  vendors: vendorsReducer,
  globalBooking: globalBookingReducer,
  extras: extrasReducer,
  reports: reportsReducer,
  destination: destinationReducer,
  locks: lockReducer,
  shortUrls: shortUrlReducer,
  router: routerReducer,
  rwd: rwdReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
