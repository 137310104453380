import * as Sentry from "@sentry/react";
import "./fonts/Nunito/Nunito-VariableFont_wght.ttf";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import { appStore } from "./store";
import "./utils/i18n/i18n";
import i18n from "./utils/i18n/i18n";
import { muiLicense } from "./utils/cfg/config";
import Loading from "./components/loading/loading";
import { LicenseInfo } from "@mui/x-date-pickers-pro";

Sentry.init({
  dsn: "https://f20d63fb82ea4252910cbbedd041d640@o909629.ingest.sentry.io/5844802",
  integrations: [
    new Integrations.BrowserTracing({ startTransactionOnPageLoad: false }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

LicenseInfo.setLicenseKey(`${muiLicense.license}`);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: Infinity,
      useErrorBoundary: false,
      refetchOnWindowFocus: false,
      retry: 0,
      refetchOnReconnect: true,
      refetchInterval: false,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <React.Suspense fallback={<Loading />}>
        <Provider store={appStore}>
          <App />
        </Provider>
      </React.Suspense>
    </I18nextProvider>
    {process.env.NODE_ENV === "development" && (
      <ReactQueryDevtools initialIsOpen={false} />
    )}
  </QueryClientProvider>
);
