import { IExtras } from "../models/extras/IExtras";
import API from "./api";

const BASE_URL = "/bookings/extras";
export interface IExtrasRequest {
  count: number;
  items: IExtras[];
}

export const getExtras = (
  itemsPerPage: number,
  page = 0,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IExtrasRequest>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const putExtra = (extraId: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<IExtras>(`${BASE_URL}/${extraId}`, data, config);
};

export const postExtra = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<IExtras>(`${BASE_URL}`, data, config);
};

export const getExtra = (extraId: number) =>
  API.get<IExtras>(`${BASE_URL}/${extraId}`);

export const deleteExtra = (extraId: number) => {
  return API.delete<IExtras>(`${BASE_URL}/${extraId}`);
};
