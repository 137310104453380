import React, { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Delete, LocalOffer, Visibility } from "@mui/icons-material";
import SBTable from "../SBTable/SBTable";
import { bookingsDateToProperFormatDayjs } from "../../utils/conversions/dataConversion";
import Status from "../status/status";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import BeachChairBookingsListHeader from "./beachChairBookingsListHeader/beachChairBookingsListHeader";
import dayjs from "dayjs";
import { useGetBeachChairBookingsList } from "./hooks/useGetBeachChairBookingsList";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useGetBeachChairsFilters } from "../beachChairList/hooks/useGetBeachChairsFilters";
import { useFiltersForCSVStore } from "./hooks/useFiltersForCSVStore";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import { useDeleteBookingBeachChair } from "../bookingsList/booking/hooks";
import { useBookingInProgressStore } from "../bookingsList/store/useBookingZustand";
import { useDispatch } from "react-redux";
import { clearGlobalBookingId } from "../../store/reducers/globalBookingReducer";
import DeleteDialog from "../dialog/DeleteDialog";
import { deDE } from "@mui/x-date-pickers/locales";
import { useQueryClient } from "@tanstack/react-query";
import { useBookingsFiltersPersists } from "../../utils/customHooks/useBookingsFiltersPersists";
import { customerText } from "./utils/customerText";
import { SBColor } from "../SBComponents/styles/SBStyles";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const BeachChairBookingsAdvancedList: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("bookings");

  const { columnFiltersPersits, clear } = useBookingsFiltersPersists();
  useEffect(() => {
    columnFiltersPersits.length > 0 && setColumnFilters(columnFiltersPersits);
  }, [columnFiltersPersits]);
  useEffect(() => {
    clear();
  }, []);
  const { setColumnFiltersStore } = useFiltersForCSVStore();
  useEffect(() => {
    setColumnFiltersStore(columnFilters);
  }, [columnFilters]);
  const [globalFilter, setGlobalFilter] = useState("");
  const deleteBeachChairBooking = useDeleteBookingBeachChair();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteBeachChairBookingId, setDeleteBeachChairBookingId] =
    useState<number>(0);
  const [deleteId, setDeleteId] = useState<number>(0);
  const openDeleteBookingModal = (id: number, beachChairBookingId: number) => {
    setDeleteDialogOpen(true);
    setDeleteId(id);
    setDeleteBeachChairBookingId(beachChairBookingId);
  };

  const { bookingInProgress, clearBookingInProgress } =
    useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );

  const removeCache = useCallback(() => {
    queryClient.removeQueries(["BeachChairBookings"]);
  }, []);

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number, beachChairBookingId: number) => {
      deleteBeachChairBooking
        .mutateAsync({ id: id, beachChairBookingId: beachChairBookingId })
        .finally(() => {
          if (id === globalBookingId) {
            dispatch(clearGlobalBookingId());
          }
          // Remove Booking in Progress if its the same id
          if (bookingInProgress && bookingInProgress.id === id) {
            clearBookingInProgress();
          }
        })
        .catch(() => {});
    },
    // eslint-disable-next-line
    [globalBookingId, clearBookingInProgress, bookingInProgress]
  );

  const { data: filters } = useGetBeachChairsFilters();

  const { data, isError, isFetching, isLoading, refetch } =
    useGetBeachChairBookingsList(
      columnFilters,
      globalFilter,
      pagination,
      sorting
    );

  const columns = React.useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        accessorFn: (originalRow: any) => `${originalRow.id}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold">{row.renderedCellValue}</Typography>
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "publicNumber",
        header: `${t("publicNumber")}`,
        accessorFn: (originalRow: any) => `${originalRow.publicNumber}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (row?.row?.original?.bookingId)
                history.push(`/bookings/${row?.row?.original?.bookingId}/show`);
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "model",
        header: `${t("model")}`,
        accessorFn: (originalRow: any) => `${t(`models.${originalRow.model}`)}`,
        maxSize: 30,
        minSize: 10,
        filterVariant: "select",
        filterSelectOptions: filters?.models
          .map((model) => {
            return { text: `${t(`models.${model}`)}`, value: model };
          })
          .sort((a, b) => a.text?.localeCompare(b.text)),
      },
      {
        accessorKey: "bookingRef",
        accessorFn: (originalRow: any) => originalRow.bookingRef,
        header: `${t("bookingRef")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold">{row.renderedCellValue}</Typography>
          </Box>
        ),
        maxSize: 40,
        minSize: 10,
      },
      {
        accessorKey: "customer",
        accessorFn: (originalRow: any) => customerText(originalRow.customer),
        header: `${t("fullname")}`,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
        maxSize: 40,
        minSize: 10,
      },
      {
        accessorKey: "start",
        header: `${t("pickup")}`,
        accessorFn: (originalRow: any) =>
          bookingsDateToProperFormatDayjs(originalRow.start),
        maxSize: 40,
        minSize: 10,
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "end",
        header: `${t("dropoff")}`,
        accessorFn: (originalRow: any) =>
          bookingsDateToProperFormatDayjs(originalRow.end),
        maxSize: 40,
        minSize: 10,
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "days",
        header: `${t("days")}`,
        accessorFn: (originalRow: any) => {
          const date = dayjs(originalRow.end);
          const dayCount = date.diff(dayjs(originalRow.start), "day");
          return `${dayCount + 1} ${dayCount >= 1 ? t("days") : t("day")}`;
        },
        filterVariant: "number",
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "bookingDate",
        header: `${t("bookingDate")}`,
        accessorFn: (originalRow: any) =>
          bookingsDateToProperFormatDayjs(originalRow.bookingDate),
        maxSize: 40,
        minSize: 10,
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "price",
        header: `${t("price")}`,
        accessorFn: (originalRow: any) =>
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(originalRow.price),
        filterVariant: "number",
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "discount",
        accessorFn: (originalRow: any) => originalRow.discount,
        header: `${t("discountLabel")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue && <LocalOffer />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "sectionId",
        header: `${t("section")}`,
        accessorFn: (originalRow: any) => originalRow.section?.name,
        maxSize: 40,
        minSize: 10,
        filterVariant: "select",
        filterSelectOptions: filters?.sections
          .map((section) => {
            return { text: section.value, value: section.id };
          })
          .sort((a, b) => a.text?.localeCompare(b.text)),
      },
      {
        accessorKey: "rowId",
        header: `${t("row")}`,
        accessorFn: (originalRow: any) => originalRow.row?.name,
        maxSize: 40,
        minSize: 10,
        filterVariant: "select",
        filterSelectOptions: filters?.sections
          .flatMap((section) =>
            section.rows.map((row) => {
              return {
                text: `${row.value} (${section.value}) `,
                value: row.id,
              };
            })
          )
          .sort((a, b) => a.text?.localeCompare(b.text)),
      },

      {
        accessorKey: "status",
        header: `${t("beachChairsDataTable.status")}`,
        accessorFn: (originalRow: any) => originalRow.status,
        maxSize: 50,
        minSize: 10,
        filterVariant: "select",
        filterSelectOptions: [
          { value: "CONFIRMED", text: `${t("filters.CONFIRMED")}` },
          { value: "OPEN", text: `${t("filters.OPEN")}` },
          { value: "CANCELED", text: `${t("filters.CANCELED")}` },
          { value: "PENDING", text: `${t("filters.PENDING")}` },
          { value: "RESERVED", text: `${t("filters.RESERVED")}` },
          { value: "EXPIRED", text: `${t("filters.EXPIRED")}` },
        ],
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]}`
                )}`}
              />
            ) : (
              <Status value={`${t(`states.${row.renderedCellValue}`)}`} />
            )}
          </Box>
        ),
      },
    ];
  }, [t, filters]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("bookingList")}
        onClick={() => {
          removeCache();
          refetch();
        }}
      />
    ),
    [refetch, t, removeCache, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const bookingId = data?.items[cell.row.index].bookingId;
      const beachChairId = data?.items[cell.row.index].id;
      const status = data?.items[cell.row.index].status;
      return (
        <Box sx={{ float: "right" }}>
          {(status === "CONFIRMED" ||
            status === "EXPIRED" ||
            status === "PENDING" ||
            status === "CANCELED" ||
            status === "RESERVED") && (
            <Tooltip title={t("eye")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (bookingId) {
                    history.push(`/bookings/${bookingId}/show`);
                  }
                }}
              >
                <Visibility />
              </IconButton>
            </Tooltip>
          )}
          {(status === "OPEN" || status === "RESERVED") && (
            <Tooltip title={t("pen")}>
              <IconButton
                sx={{ color: "#000000" }}
                disabled={false}
                onClick={() => {
                  if (bookingId) {
                    history.push(`/bookings/${bookingId}/edit`);
                  }
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {(status === "OPEN" || status === "RESERVED") && (
            <Tooltip title={t("bin")}>
              <IconButton
                sx={{ color: "#000000" }}
                onClick={() => {
                  if (bookingId && beachChairId) {
                    openDeleteBookingModal(bookingId, beachChairId);
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      );
    },
    [data, history, pagination]
  );

  return (
    <SBMainListWrapper>
      <BeachChairBookingsListHeader />
      <SBTable
        columnVisibilityStoreName="beachChairBookingsAdvancedListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="bookings"
          open={deleteDialogOpen}
          selectedValue={deleteId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteId) {
              handleDeleteAfterModalConfirm(
                +deleteId,
                +deleteBeachChairBookingId
              );
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default React.memo(BeachChairBookingsAdvancedList);
