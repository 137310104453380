import {
  Box,
  CircularProgress,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useBookigHistory } from "../hooks/useBookingHistory";
import { SBColor } from "../../../SBComponents/styles/SBStyles";

const ActionIndicator: React.FC<{ action: string }> = ({ action }) => {
  const { t } = useTranslation(["common"]);
  let selectedColor = SBColor.gray;
  switch (action) {
    case "Booking": {
      selectedColor = SBColor.green;
      break;
    }
    case "booking.customer.assigned": {
      selectedColor = SBColor.blue;
      break;
    }
    case "booking.chair.added": {
      selectedColor = SBColor.blue;
      break;
    }
    case "booking.paid": {
      selectedColor = SBColor.green;
      break;
    }
    case "booking.updated": {
      selectedColor = SBColor.orange;
      break;
    }
    case "booking.cancelled": {
      selectedColor = SBColor.red;
      break;
    }
    case "booking.payment.aborted": {
      selectedColor = SBColor.red;
      break;
    }
    case "booking.recovered": {
      selectedColor = SBColor.blue;
      break;
    }
    case "booking.expired": {
      selectedColor = SBColor.red;
      break;
    }
    case "booking.chair.updated": {
      selectedColor = SBColor.blue;
      break;
    }
    case "booking.reserved": {
      selectedColor = SBColor.blue;
      break;
    }
    case "booking.confirmed": {
      selectedColor = SBColor.green;
      break;
    }

    default: {
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pl: "5px",
        pr: "5px",
        width: "140px",
        height: "20px",
        bgcolor: SBColor.white,
        border: `2px solid ${selectedColor}`,
        borderRadius: "5px",
      }}
    >
      <Typography
        sx={{ color: selectedColor, fontSize: "12px", fontWeight: "bold" }}
      >
        {t(`auditLogAction.${action}`)}
      </Typography>
    </Box>
  );
};

const BookingHistory: React.FC<{ id: number }> = ({ id }) => {
  const { t } = useTranslation(["common"]);
  const { data, isLoading } = useBookigHistory(id);

  if (isLoading) {
    return <CircularProgress sx={{ color: SBColor.blue }} />;
  }

  return (
    <Box sx={{ pt: "10px", pl: "10px", pr: "10px" }}>
      <List
        sx={{
          width: "100%",
          bgcolor: SBColor.white,
          border: `1px solid ${SBColor.blue}`,
          borderRadius: "5px",
        }}
      >
        {data?.map((value) => (
          <ListItem key={value.id} sx={{ fontSize: "12px", gap: "5px" }}>
            <Typography
              sx={{
                color: SBColor.gray,
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              {value.updatedAt}
            </Typography>
            <Typography
              sx={{ color: SBColor.blue, fontSize: "12px", fontWeight: "bold" }}
            >
              {`${value.user} ${t(
                `auditLogActionAdditionalText.${value.action}`
              )}
              `}
            </Typography>
            <ActionIndicator action={value.action} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default BookingHistory;
