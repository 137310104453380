import { Box, IconButton, InputLabel } from "@mui/material";
import {
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
} from "react-hook-form";
import {
  SBLabelStyle,
  SBStopSaleIconButtonStyle,
  SBTextAreaStopSaleDatesAreaEditStyle,
  SBTextAreaStopSaleDatesAreaShowStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBIconButtonStyle,
  SBDatePickerStyle,
} from "../styles/SBStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { formatInUtc } from "../../../utils/conversions/dataConversion";
import SBCircularProgress from "./SBCircularProgress";
import dayjs from "dayjs";
import { deDE } from "@mui/x-date-pickers/locales";

export interface SBTextAreaStopSaleDatesPrimary {
  label: React.ReactNode;
  index: number;
  item: Record<"id", string>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<FieldValues, "stopSaleDates">;
  isLoading?: boolean;
  type?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
}

export type SBTextAreaStopSaleDatesType = SBTextAreaStopSaleDatesPrimary;

const SBTextAreaStopSaleDates: React.FC<SBTextAreaStopSaleDatesType> = ({
  label,
  index,
  item,
  remove,
  update,
  isLoading,
  type,
  icon,
  hidden,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box
      sx={{
        ...SBTextFieldGridStyle,
        display: hidden ? "none" : "grid",
      }}
    >
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <Box
          sx={{
            ...(type === "show"
              ? SBTextAreaStopSaleDatesAreaShowStyle
              : SBTextAreaStopSaleDatesAreaEditStyle),
          }}
          key={`stopSaleDates-${index}`}
        >
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <LocalizationProvider
              adapterLocale="de"
              dateAdapter={AdapterDayjs}
              localeText={
                deDE.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <DatePicker
                format="DD.MM.YYYY"
                value={dayjs((item as any)?.start) as any}
                onChange={(newValue: Date | null) => {
                  if (newValue) {
                    const date = new Date(newValue);
                    date.setHours(0, 0, 0);
                    update(index, {
                      start: formatInUtc(date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
                      end: (item as any).end,
                    });
                  }
                }}
                disabled={isDisabled}
                sx={{ ...SBDatePickerStyle, width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                  },
                }}
              />
              <DatePicker
                format="DD.MM.YYYY"
                value={dayjs((item as any)?.end) as any}
                onChange={(newValue: Date | null) => {
                  if (newValue) {
                    const date = new Date(newValue);
                    date.setHours(23, 59, 59);
                    update(index, {
                      start: (item as any).start,
                      end: formatInUtc(date, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
                    });
                  }
                }}
                disabled={isDisabled}
                sx={{ ...SBDatePickerStyle, width: "100%" }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                  },
                }}
              />
              {type === "edit" && (
                <IconButton
                  aria-label="filter"
                  data-testid="filter-trigger"
                  sx={{
                    ...SBIconButtonStyle,
                    ...SBStopSaleIconButtonStyle,
                  }}
                  onClick={() => {
                    remove(index);
                  }}
                >
                  <DeleteForeverIcon />
                </IconButton>
              )}
            </LocalizationProvider>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SBTextAreaStopSaleDates;
