import { IFiltersBeachChair } from "./../../models/beachChairs/IFiltersBeachChair";
import {
  DeleteBeachChairFailurePayload,
  DeleteBeachChairRequestPayload,
  DeleteBeachChairSuccessPayload,
  GetFiltersBeachChairFailurePayload,
  GetFiltersBeachChairRequestPayload,
  GetFiltersBeachChairSuccessPayload,
  PostNewBeachChairFailurePayload,
  PostNewBeachChairRequestPayload,
  PostNewBeachChairSuccessPayload,
  FetchBeachChairFailurePayload,
  FetchBeachChairRequestPayload,
  FetchBeachChairsFailurePayload,
  FetchBeachChairsRequestPayload,
  FetchBeachChairsSuccessPayload,
  FetchBeachChairSuccessPayload,
  FilterBeachChairsFailurePayload,
  FilterBeachChairsRequestPayload,
  FilterBeachChairsSuccessPayload,
  FilterDataBeachChairsChangeRequestPayload,
  PostBeachChairFailurePayload,
  PostBeachChairRequestPayload,
  PostBeachChairSuccessPayload,
  PushBeachChairFailurePayload,
  PushBeachChairRequestPayload,
  PushBeachChairSuccessPayload,
  RouterRedirectBeachChairPayload,
  SetCurrentDateRequestPayload,
  getBeachChairCalendarFailurePayload,
  getBeachChairCalendarSuccessPayload,
  getBeachChairCalendarRequestPayload,
} from "../actionPayload/beachChairPayloads";
import { AppState } from "./rootReducer";
import { IFilterData } from "./../../models/dataTable/IFilterData";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { IBeachChair } from "../../models/beachChairs/IBeachChair";
import { IBeachChairAvability } from "../../models/beachChairs/IBeachChairCalendarDay";

interface IPage {
  key: string;
  dataIds: number[];
}

interface IBeachChairState {
  pending: boolean;
  error: string;
  count: number;
  filterData: IFilterData[];
  filter?: IFiltersBeachChair;
  beachChairCalendarMonth: string;
  calendarBeachChair: EntityState<IBeachChairAvability>;
  page: EntityState<IPage>;
}

const calendarBeachChairAdapter = createEntityAdapter<IBeachChairAvability>({
  selectId: (calendarBeachChair: IBeachChairAvability) =>
    calendarBeachChair.key,
});

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

const beachChairAdapter = createEntityAdapter<IBeachChair>({
  selectId: (beachChair) => beachChair.id,
});

const today = (): string => {
  var date = new Date();
  return date.toISOString();
};

const INITIAL_STATE = beachChairAdapter.getInitialState<IBeachChairState>({
  pending: false,
  error: "",
  count: 0,
  filterData: [],
  filter: undefined,
  beachChairCalendarMonth: today(),
  calendarBeachChair: calendarBeachChairAdapter.getInitialState({}),
  page: pageAdapter.getInitialState({}),
});

const beachChairSlice = createSlice({
  name: "beachChair",
  initialState: INITIAL_STATE,
  reducers: {
    resetBeachChairRequest() {
      return INITIAL_STATE;
    },
    fetchBeachChairsRequest(
      state,
      action: PayloadAction<FetchBeachChairsRequestPayload>
    ) {
      state.pending = true;
    },
    fetchBeachChairsSuccess(
      state,
      action: PayloadAction<FetchBeachChairsSuccessPayload>
    ) {
      state.pending = false;
      state.count = action.payload.count;
      beachChairAdapter.removeAll(state);
      pageAdapter.removeAll(state.page);
      beachChairAdapter.upsertMany(state, action.payload.beachChairs);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.beachChairs.map((beachChair) => beachChair.id),
        ],
      });
    },
    fetchBeachChairsFailure(
      state,
      action: PayloadAction<FetchBeachChairsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    fetchBeachChairRequest(
      state,
      action: PayloadAction<FetchBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    fetchBeachChairSuccess(
      state,
      action: PayloadAction<FetchBeachChairSuccessPayload>
    ) {
      state.pending = false;
      beachChairAdapter.upsertOne(state, action.payload.beachChair);
    },
    fetchBeachChairFailure(
      state,
      action: PayloadAction<FetchBeachChairFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterBeachChairsRequest(
      state,
      action: PayloadAction<FilterBeachChairsRequestPayload>
    ) {
      state.pending = true;
    },
    filterBeachChairsSuccess(
      state,
      action: PayloadAction<FilterBeachChairsSuccessPayload>
    ) {
      state.pending = false;
      beachChairAdapter.upsertMany(state, action.payload.beachChairs);
      pageAdapter.upsertOne(state.page, {
        key: `filter:${action.payload.filterPhrase}:${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [
          ...action.payload.beachChairs.map((beachChair) => beachChair.id),
        ],
      });
    },
    filterBeachChairsFailure(
      state,
      action: PayloadAction<FilterBeachChairsFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    filterCancelBeachChairsSuccess(state, action) {},
    filterDataBeachChairsChangeRequest(
      state,
      action: PayloadAction<FilterDataBeachChairsChangeRequestPayload>
    ) {
      state.filterData = state.filterData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    putBeachChairRequest(
      state,
      action: PayloadAction<PushBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    putBeachChairSuccess(
      state,
      action: PayloadAction<PushBeachChairSuccessPayload>
    ) {
      state.pending = false;
      beachChairAdapter.upsertOne(state, action.payload.beachChair);
      beachChairAdapter.removeAll(state);
      pageAdapter.removeAll(state.page);
    },
    putBeachChairFailure(
      state,
      action: PayloadAction<PushBeachChairFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    postBeachChairRequest(
      state,
      action: PayloadAction<PostBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    postBeachChairSuccess(
      state,
      action: PayloadAction<PostBeachChairSuccessPayload>
    ) {
      state.pending = false;
      state.count = state.count + 1;
      beachChairAdapter.upsertOne(state, action.payload.beachChair);
    },
    postBeachChairFailure(
      state,
      action: PayloadAction<PostBeachChairFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    deleteBeachChairRequest(
      state,
      action: PayloadAction<DeleteBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    deleteBeachChairSuccess(
      state,
      action: PayloadAction<DeleteBeachChairSuccessPayload>
    ) {
      state.pending = false;
      beachChairAdapter.removeOne(state, action.payload.beachChairId);
      pageAdapter.removeAll(state.page);
    },
    deleteBeachChairFailure(
      state,
      action: PayloadAction<DeleteBeachChairFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    getFiltersBeachChairRequest(
      state,
      action: PayloadAction<GetFiltersBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    getFiltersBeachChairSuccess(
      state,
      action: PayloadAction<GetFiltersBeachChairSuccessPayload>
    ) {
      state.pending = false;
      state.filter = action.payload.filters;
    },
    getFiltersBeachChairFailure(
      state,
      action: PayloadAction<GetFiltersBeachChairFailurePayload>
    ) {
      state.pending = false;
    },
    postNewBeachChairRequest(
      state,
      action: PayloadAction<PostNewBeachChairRequestPayload>
    ) {
      state.pending = true;
    },
    postNewBeachChairSuccess(
      state,
      action: PayloadAction<PostNewBeachChairSuccessPayload>
    ) {
      state.pending = false;
      state.count = state.count + 1;
      beachChairAdapter.upsertOne(state, action.payload.beachChair);
      pageAdapter.removeAll(state.page);
    },
    postNewBeachChairFailure(
      state,
      action: PayloadAction<PostNewBeachChairFailurePayload>
    ) {
      state.pending = false;
      state.error = action.payload.error;
    },
    changeDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    },
    routerRedirectBeachChair(
      state,
      action: PayloadAction<RouterRedirectBeachChairPayload>
    ) {},
    setBeachChairCalendarMonthRequest(
      state,
      action: PayloadAction<SetCurrentDateRequestPayload>
    ) {
      state.beachChairCalendarMonth = action.payload.date;
    },
    getBeachChairCalendarRequest(
      state,
      action: PayloadAction<getBeachChairCalendarRequestPayload>
    ) {},
    getBeachChairCalendarSuccess(
      state,
      action: PayloadAction<getBeachChairCalendarSuccessPayload>
    ) {
      calendarBeachChairAdapter.upsertOne(
        state.calendarBeachChair,
        action.payload.calendar
      );
    },
    getBeachChairCalendarFailure(
      state,
      action: PayloadAction<getBeachChairCalendarFailurePayload>
    ) {
      state.error = action.payload.error;
    },
    routerRedirectBeachChairPage(
      state,
      action: PayloadAction<RouterRedirectBeachChairPayload>
    ) {},
  },
});

export const {
  selectById: getCalendarBeachChairById,
} = calendarBeachChairAdapter.getSelectors<AppState>(
  (state) => state.beachChairs.calendarBeachChair
);

export const {
  selectAll: getAllBeachChairsPages,
  selectById: getBeachChairPages,
} = pageAdapter.getSelectors<AppState>((state) => state.beachChairs.page);

export const {
  selectAll: getAllBeachChairs,
  selectById: getBeachChairById,
  selectTotal: getBeachChairTotal,
} = beachChairAdapter.getSelectors<AppState>((state) => state.beachChairs);

export const {
  resetBeachChairRequest,
  fetchBeachChairsRequest,
  fetchBeachChairsSuccess,
  fetchBeachChairsFailure,
  fetchBeachChairRequest,
  fetchBeachChairSuccess,
  fetchBeachChairFailure,
  filterBeachChairsRequest,
  filterBeachChairsSuccess,
  filterBeachChairsFailure,
  filterCancelBeachChairsSuccess,
  filterDataBeachChairsChangeRequest,
  putBeachChairRequest,
  putBeachChairSuccess,
  putBeachChairFailure,
  postBeachChairRequest,
  postBeachChairSuccess,
  postBeachChairFailure,
  deleteBeachChairRequest,
  deleteBeachChairSuccess,
  deleteBeachChairFailure,
  getFiltersBeachChairRequest,
  getFiltersBeachChairSuccess,
  getFiltersBeachChairFailure,
  postNewBeachChairRequest,
  postNewBeachChairSuccess,
  postNewBeachChairFailure,
  changeDataRequest,
  setBeachChairCalendarMonthRequest,
  getBeachChairCalendarRequest,
  getBeachChairCalendarSuccess,
  getBeachChairCalendarFailure,
  routerRedirectBeachChair,
  routerRedirectBeachChairPage,
} = beachChairSlice.actions;

export default beachChairSlice.reducer;
