import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export const NoAccessPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const handleGoBack = () => {
    history.length > 1 ? history.goBack() : history.push("/");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Typography variant="h4" gutterBottom>
        {t("noAccessErrorPage.title")}
      </Typography>
      <Typography variant="body1" marginBottom={2}>
        {t("noAccessErrorPage.message")}
      </Typography>

      <Button variant="contained" color="primary" onClick={handleGoBack}>
        {t("noAccessErrorPage.backButton")}
      </Button>
    </Box>
  );
};
