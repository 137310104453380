import { FormHelperText } from "@mui/material";
import { SBColor } from "../styles/SBStyles";

export interface SBFormHelperTextPrimary {
  error?: string | undefined;
}

export type SBFormHelperTextType = SBFormHelperTextPrimary;

const SBFormHelperText: React.FC<SBFormHelperTextType> = ({ error }) => {
  return error ? (
    <FormHelperText sx={{ color: SBColor.red, width: "full" }}>
      {error}
    </FormHelperText>
  ) : null;
};

export default SBFormHelperText;
