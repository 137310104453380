import { createSelector } from "reselect";
import { extrasToRow } from "../../utils/conversions/extrasToRow";
import { getExtrasPages, getExtrasById, getAllExtras } from "../reducers/extrasReducer";
import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.extras.pending;

const getError = (state: AppState) => state.extras.error;

const getExtrasCount = (state: AppState) => state.extras.count;

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getExtrasCountSelector = createSelector(
  getExtrasCount,
  (count) => count
);

export const getExtrasSelector = createSelector(
  getAllExtras,
  (extras) => extras
);

export const getExtraSelector = (id: number) =>
  createSelector(getState, (state) => getExtrasById(state, id));

export const getExtrasPage = (pageNumber: number, itemsPerPage: number) =>
  createSelector(getState, (state) => {
    const page = getExtrasPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.dataIds.map((id) => getExtrasById(state, id)!).map(extrasToRow)
    );
  });
