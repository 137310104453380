import { IGridGroupView, IGridView } from "../../models/availability/IGrid";
import { Action } from "@reduxjs/toolkit";
import {
  getAvailabilities,
  getAvailabilitiesGrid,
  getAvailableBeachChairs,
} from "../../api/availabilitiesApi";
import { all, call, put, takeLatest, throttle } from "typed-redux-saga";
import {
  fetchAvailabilitiesBeachChairsFailure,
  fetchAvailabilitiesBeachChairsRequest,
  fetchAvailabilitiesBeachChairsSuccess,
  fetchAvailabilitiesFailure,
  fetchAvailabilitiesRequest,
  fetchAvailabilitiesSuccess,
  getAllAvailableBeachChairsRequest,
  getAllAvailableBeachChairsSuccess,
  getAvailabilitiesBeachChairsChangeFailure,
  getAvailabilitiesBeachChairsChangeRequest,
  getAvailabilitiesBeachChairsChangeSuccess,
  getGridViewFailure,
  getGridViewRequest,
  getGridViewSuccess,
  putPositionFailure,
  putPositionRequest,
  putPositionSuccess,
  routeAvailabilitiesBeachChair,
  routeAvailabilitiesSection,
} from "../reducers/availabilityReducer";
import history from "../../utils/history/history";
import { putBeachChairsOrder } from "../../api/destinationApi";
import { IAvailableBeachChairs } from "../../models/availability/IAvailableBeachChairs";
import { format } from "date-fns";
import { queryClient } from "../..";

function* fetchAvailabilitiesSaga(action: Action) {
  try {
    if (fetchAvailabilitiesRequest.match(action)) {
      const { startDate, endDate } = action.payload;
      const { data } = yield* call(getAvailabilities, { startDate, endDate });
      yield put(
        fetchAvailabilitiesSuccess({
          availabilities: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchAvailabilitiesFailure({
        error: e.message,
      })
    );
  }
}

function* fetchAvailabilitiesBeachChairsSaga(action: Action) {
  try {
    if (fetchAvailabilitiesBeachChairsRequest.match(action)) {
      const { sectionId, row, model, free, hasLock, start, end } =
        action.payload;
      const { data } = yield* call(
        getAvailableBeachChairs,
        sectionId,
        format(new Date(start), "yyyy-MM-dd"),
        format(new Date(end), "yyyy-MM-dd"),
        row,
        model,
        free,
        hasLock
      );
      yield put(
        fetchAvailabilitiesBeachChairsSuccess({
          availableBeachChairs: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchAvailabilitiesBeachChairsFailure({
        error: e.message,
      })
    );
  }
}

function* getAvailabilitiesBeachChairsChangeSaga(action: Action) {
  try {
    if (getAvailabilitiesBeachChairsChangeRequest.match(action)) {
      const { row, model, start, end } = action.payload;
      const sections = yield* call(getAvailabilities, { startDate: start });
      let ids = sections.data.map((a) => a.id);
      const allSections = yield* all(
        ids.map((id) =>
          call(getAvailableBeachChairs, id, start, end, row, model, true, false)
        )
      );
      const allSectionsData = allSections.map(
        (fetchedData) => fetchedData.data
      );
      let allAvailableBeachChairs: IAvailableBeachChairs[] = [];
      allSectionsData.forEach((section) => {
        allAvailableBeachChairs.push(...section);
      });
      yield put(
        getAvailabilitiesBeachChairsChangeSuccess({
          availableBeachChairs: allAvailableBeachChairs,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getAvailabilitiesBeachChairsChangeFailure({
        error: e.message,
      })
    );
  }
}

function* getGridViewSaga(action: Action) {
  try {
    if (getGridViewRequest.match(action)) {
      const { sectionId, date, row, model, free, hasLock } = action.payload;
      const { data } = yield* call(
        getAvailabilitiesGrid,
        sectionId,
        new Date(date),
        row,
        model,
        free,
        hasLock
      );
      const groups: IGridGroupView[] = data.groups.map((group) => {
        return {
          id: +group.id,
          title: group.title
            ? group.title
            : `${group.publicNumber}/${group.model}${
                group.color ? "/" + group.color : ""
              }`,
        };
      });
      const gridData: IGridView = {
        items: data.items,
        groups: groups,
      };
      yield put(
        getGridViewSuccess({
          grid: gridData,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getGridViewFailure({
        error: e.message,
      })
    );
  }
}

function* routerRedirectAvailabilitiesSection(action: Action) {
  if (routeAvailabilitiesSection.match(action)) {
    const { secitonId, type } = action.payload;
    yield* call(
      [history, history.push],
      `/availabilities/${type}/${secitonId}/beachChairs/`
    );
  }
}

function* routerRedirectAvailabilitiesBeachChairSaga(action: Action) {
  if (routeAvailabilitiesBeachChair.match(action)) {
    const { bookingId } = action.payload;
    yield* call([history, history.replace], `/bookings/${bookingId}/edit`);
  }
}

function* putPositionSaga(action: Action) {
  try {
    if (putPositionRequest.match(action)) {
      const { rowId, availableBeachChairs } = action.payload;
      const beachChairOrder = availableBeachChairs.map(
        (beachChair) => beachChair.id
      );
      yield* call(putBeachChairsOrder, rowId, {
        beachChairOrder: beachChairOrder,
      });
      yield put(putPositionSuccess({}));
      queryClient.invalidateQueries({
        queryKey: ["beachChairsAvailabilities"],
      });
    }
  } catch (e: any) {
    yield* put(
      putPositionFailure({
        error: e.message,
      })
    );
  }
}

function* getAllAvailableBeachChairsSaga(action: Action) {
  try {
    if (getAllAvailableBeachChairsRequest.match(action)) {
      const { date } = action.payload;
      const sections = yield* call(getAvailabilities, {
        startDate: date,
        endDate: date,
      });
      yield put(
        fetchAvailabilitiesSuccess({
          availabilities: sections.data,
        })
      );
      const allSections = yield* all(
        sections.data.map((section) =>
          call(
            getAvailableBeachChairs,
            section.id,
            date,
            date,
            "",
            "",
            true,
            false
          )
        )
      );

      const allSectionsData = allSections.map(
        (fetchedData) => fetchedData.data
      );

      let allAvailableBeachChairs: IAvailableBeachChairs[] = [];
      allSectionsData.forEach((section) =>
        allAvailableBeachChairs.push(...section)
      );

      yield put(
        getAllAvailableBeachChairsSuccess({
          availableBeachChairs: allAvailableBeachChairs,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchAvailabilitiesBeachChairsFailure({
        error: e.message,
      })
    );
  }
}

function* availabilitySaga() {
  yield* all([
    throttle(500, fetchAvailabilitiesRequest.type, fetchAvailabilitiesSaga),
    takeLatest(
      fetchAvailabilitiesBeachChairsRequest.type,
      fetchAvailabilitiesBeachChairsSaga
    ),
    takeLatest(getGridViewRequest.type, getGridViewSaga),
    takeLatest(
      routeAvailabilitiesSection.type,
      routerRedirectAvailabilitiesSection
    ),
    takeLatest(
      routeAvailabilitiesBeachChair.type,
      routerRedirectAvailabilitiesBeachChairSaga
    ),
    takeLatest(
      getAvailabilitiesBeachChairsChangeRequest.type,
      getAvailabilitiesBeachChairsChangeSaga
    ),
    takeLatest(putPositionRequest.type, putPositionSaga),
    takeLatest(
      getAllAvailableBeachChairsRequest.type,
      getAllAvailableBeachChairsSaga
    ),
  ]);
}

export default availabilitySaga;
