import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch} from "react-redux";
import {
  setAvailabilitiesBeachChairFilters,
} from "../../store/reducers/availabilityReducer";

export interface IAvailabilitiesBeachChairsFilter {
  row: string;
  model: string;
  free: boolean;
  hasLock: boolean;
}

export function useAvailabilitiesBeachChairsFilter() {
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm<IAvailabilitiesBeachChairsFilter>();

  const onSubmit = useCallback(
    (formValues: IAvailabilitiesBeachChairsFilter) => {
      dispatch(
        setAvailabilitiesBeachChairFilters({
          row: formValues.row,
          model: formValues.model,
          free: formValues.free,
          hasLock: formValues.hasLock,
        })
      );
    },
    [dispatch]
  );

  return {
    control,
    onSubmit: handleSubmit(onSubmit),
  };
}
