import { create } from "zustand";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

interface BeachChairFilterSortPaginationStore {
  m_columnFilters: MRT_ColumnFiltersState;
  m_setColumnFilters: (value: MRT_ColumnFiltersState) => void;
  m_globalFilter: string;
  m_setGlobalFilter: (value: string) => void;
  m_sorting: MRT_SortingState;
  m_setSorting: (value: MRT_SortingState) => void;
  m_pagination: MRT_PaginationState;
  m_setPagination: (value: MRT_PaginationState) => void;
  clear: () => void;
}

export const useBeachChairFilterSortPaginationStore = create<BeachChairFilterSortPaginationStore>(
  (set) => {
    return {
      m_columnFilters: [],
      m_setColumnFilters: (value: MRT_ColumnFiltersState) =>
        set((state) => ({
          ...state,
          m_columnFilters: value,
        })),
      m_globalFilter: "",
      m_setGlobalFilter: (value: string) =>
        set((state) => ({
          ...state,
          m_globalFilter: value,
        })),
      m_sorting: [],
      m_setSorting: (value: MRT_SortingState) =>
        set((state) => ({
          ...state,
          m_sorting: value,
        })),
      m_pagination: { pageIndex: 0, pageSize: 15 },
      m_setPagination: (value: MRT_PaginationState) =>
        set((state) => ({
          ...state,
          m_pagination: value,
        })),
      clear: () =>
        set((state) => ({
          ...state,
          m_columnFilters: [],
          m_globalFilter: "",
          m_sorting: [],
          m_pagination: { pageIndex: 0, pageSize: 15 },
        })),
    };
  }
);
