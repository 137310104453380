import {
  ClickAwayListener,
  CircularProgress,
  Divider,
  IconButton,
  Box,
  Typography,
  List,
  Stack,
  Grid,
} from "@mui/material";
import { PowerSettingsNew } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { customStyle } from "../../utils/customStyles/customStyles";
import history from "../../utils/history/history";
import { SearchInput } from "../common";
import { useSearch } from "./hooks";
import { SearchResultsList, SearchResultItem } from "./components";
import { mainColor } from "../../utils/customStyles/globalStyles";
import { useLocation } from "react-router";
import { useBeachChairFilterSortPaginationStore } from "../beachChairList/hooks/useFilterSortStore";
import { useAuthStore } from "../auth/hooks/authStore";
import LanguageSelector from "../languageSelect/languageSelect";
import { SBColor } from "../SBComponents/styles/SBStyles";

export const Search: React.FC = () => {
  const searchInputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(["common"]);
  //PART FOR CLEARING eachChairFilterSortPagination
  const { actions } = useAuthStore();
  const { clear } = useBeachChairFilterSortPaginationStore();
  const location = useLocation();
  useEffect(() => {
    if (!location.pathname.includes("beach_chairs")) {
      clear();
    }
    // eslint-disable-next-line
  }, [location]);

  const [searchPhrase, setSearchPhrase] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);

  const {
    customers,
    isFetchingCustomers,
    customersById,
    isFetchingCustomersById,
    beachChairs,
    isFetchingBeachChairs,
    beachChairsById,
    isFetchingBeachChairsById,
    bookings,
    isFetchingBookings,
  } = useSearch(searchPhrase);

  const handleSpecialKeys = (e: React.KeyboardEvent) => {
    // When Escape, hide search
    if (e.key === "Escape") {
      setShowSearchResults(false);
      searchInputRef.current?.blur();
    }
  };

  const handleChange = (searchValue: string) => {
    setSearchPhrase(searchValue);
    if (!showSearchResults) {
      setShowSearchResults(true);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          py: 1,
          px: 2,
        }}
      >
        <Box flex={1}>
          <ClickAwayListener onClickAway={() => setShowSearchResults(false)}>
            <Box sx={{ position: "relative" }}>
              <SearchInput
                inputRef={searchInputRef}
                onChange={handleChange}
                onFocus={() => setShowSearchResults(true)}
                loading={
                  isFetchingCustomers ||
                  isFetchingBeachChairs ||
                  isFetchingBookings
                }
                onKeyDown={handleSpecialKeys}
                fullWidth
                placeholder={t("searchPrefill")}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
              />

              {showSearchResults && (
                <Box
                  sx={{
                    top: "70px",
                    position: "absolute",
                    backgroundColor: customStyle.whiteColor,
                    zIndex: 99999,
                    height: "500px",
                    boxShadow: "6px 8px 6px -6px black",
                    p: 1,
                    boxSizing: "border-box",
                    overflow: "hidden",
                  }}
                >
                  <Grid container spacing={3} sx={{ height: "100%" }}>
                    {/* Beach Chairs column */}
                    <SearchResultsList title={t("beachChairs")}>
                      {isFetchingBeachChairs && <CircularProgress />}

                      {!isFetchingBeachChairs &&
                        !isFetchingBeachChairsById &&
                        beachChairs.length === 0 &&
                        beachChairsById.length === 0 && (
                          <Typography sx={{ mt: 2 }} textAlign="center">
                            {t("search.noBeachChairs")}
                          </Typography>
                        )}

                      {!isFetchingBeachChairs &&
                        !isFetchingBeachChairsById &&
                        (beachChairs.length > 0 ||
                          beachChairsById.length > 0) && (
                          <List>
                            {beachChairs.map((beachChair) => (
                              <SearchResultItem
                                key={`beachChair-${beachChair.id}`}
                                onClick={() => {
                                  setShowSearchResults(false);
                                  history.push(
                                    `/beach_chairs/${beachChair.id}/show`
                                  );
                                }}
                              >
                                {[
                                  beachChair.publicNumber,
                                  t(`models.${beachChair.model}`),
                                  beachChair.section.value,
                                  beachChair.row.value,
                                ]
                                  .filter(Boolean)
                                  .join(" - ")}
                              </SearchResultItem>
                            ))}
                            {beachChairsById.map((beachChair) => (
                              <SearchResultItem
                                key={`beachChair-${beachChair.id}`}
                                onClick={() => {
                                  setShowSearchResults(false);
                                  history.push(
                                    `/beach_chairs/${beachChair.id}/show`
                                  );
                                }}
                              >
                                {[
                                  beachChair.publicNumber,
                                  t(`models.${beachChair.model}`),
                                  beachChair.section.value,
                                  beachChair.row.value,
                                ]
                                  .filter(Boolean)
                                  .join(" - ")}
                              </SearchResultItem>
                            ))}
                          </List>
                        )}
                    </SearchResultsList>

                    {/* Booking column */}
                    <SearchResultsList title={t("bookings")}>
                      {isFetchingBookings && <CircularProgress />}
                      {!isFetchingBookings && bookings.length === 0 && (
                        <Typography sx={{ mt: 2 }} textAlign="center">
                          {t("search.noBookings")}
                        </Typography>
                      )}

                      {!isFetchingBookings && bookings.length > 0 && (
                        <List>
                          {bookings.map((booking) => (
                            <SearchResultItem
                              key={`booking-${booking.id}`}
                              onClick={() => {
                                setShowSearchResults(false);
                                history.push(`/bookings/${booking.id}/show`);
                              }}
                            >
                              {booking.bookingRef} = {booking.firstName}{" "}
                              {booking.lastName}
                            </SearchResultItem>
                          ))}
                        </List>
                      )}
                    </SearchResultsList>

                    {/* Customers Column */}
                    <SearchResultsList title={t("customers")}>
                      {isFetchingCustomers && <CircularProgress />}

                      {!isFetchingCustomers &&
                        !isFetchingCustomersById &&
                        customers.length === 0 &&
                        customersById.length === 0 && (
                          <Typography sx={{ mt: 2 }} textAlign="center">
                            {t("search.noCustomers")}
                          </Typography>
                        )}

                      {!isFetchingCustomers &&
                        !isFetchingCustomersById &&
                        (customers.length > 0 || customers.length > 0) && (
                          <List>
                            {customers.map((customer) => (
                              <SearchResultItem
                                key={`customer-${customer.id}`}
                                onClick={() => {
                                  setShowSearchResults(false);
                                  history.push(
                                    `/customers/${customer.id}/show`
                                  );
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    boxSizing: "border-box",
                                  }}
                                >
                                  <Box>
                                    {`${customer.firstName} ${customer.lastName} - ${customer.email}`}{" "}
                                  </Box>
                                  <Box
                                    sx={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      borderRadius: "50%",
                                      height: "24px",
                                      marginLeft: "auto",
                                      marginRight: 0,
                                      backgroundColor: `${mainColor.color}!important`,
                                      fontWeight: "bold",
                                      color: "#FFFFFF",
                                    }}
                                  >
                                    {customer.bookingCount}
                                  </Box>
                                </Box>
                              </SearchResultItem>
                            ))}
                            {customersById.map((customer) => (
                              <SearchResultItem
                                key={`customer-${customer.id}`}
                                onClick={() => {
                                  setShowSearchResults(false);
                                  history.push(
                                    `/customers/${customer.id}/show`
                                  );
                                }}
                              >
                                {customer.firstName} {customer.lastName} -{" "}
                                {customer.email}
                              </SearchResultItem>
                            ))}
                          </List>
                        )}
                    </SearchResultsList>
                  </Grid>
                </Box>
              )}
            </Box>
          </ClickAwayListener>
        </Box>

        <Divider light orientation="vertical" />
        <LanguageSelector />
        <IconButton
          onClick={(e) => {
            e.preventDefault();
            actions.clearTokens();
          }}
        >
          <PowerSettingsNew sx={{ color: SBColor.blue }} />
        </IconButton>
      </Stack>
    </Box>
  );
};
