import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { customerApi } from "../../../api/apiTanStack";
import { OrderByDirection } from "../../../api-client/generated";

export const useGetCustomers = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState,
  vendorId?: number
) =>
  useQuery({
    queryKey: [
      "customers",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      Number(vendorId),
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        vendorId
          ? `filters[vendorId]=${vendorId}&` +
            columnFilters
              .map((filter) => `filters[${filter.id}]=${filter.value}`)
              .join("&")
          : columnFilters
              .map((filter) => `filters[${filter.id}]=${filter.value}`)
              .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return customerApi
        .customersControllerFindMany(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
            search: globalFilter,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
    enabled: !!vendorId,
  });

export const useGetSearchCustomers = (
  globalFilter: string,
  enabled: boolean = true
) =>
  useQuery({
    queryKey: ["searchCustomers", globalFilter],
    queryFn: async () => {
      return customerApi
        .customersControllerFindMany({
          page: 0,
          itemsPerPage: 0,
          search: globalFilter,
        })
        .then((data) => data.data);
    },
    enabled: enabled,
    keepPreviousData: true,
  });
