import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSectionRequest } from "../../store/reducers/destinationReducer";
import { getDestinationsSectionSelector } from "../../store/selectors/destinationSelectors";

export function useSectionData(id: number) {
  const dispatch = useDispatch();
  const sectionData = useSelector(getDestinationsSectionSelector(id));
  React.useEffect(() => {
    if (!sectionData) {
      dispatch(getSectionRequest({ sectionId: id }));
    }
    // eslint-disable-next-line
  }, [sectionData]);
  return {
    sectionData
  };
}
