import React, { useState } from "react";
import {
  button,
  imageCard,
  insideImageCard,
  insideImageCardRow,
} from "../../utils/customStyles/globalStyles";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import API from "../../api/api";
import { FieldArrayMethodProps } from "react-hook-form/dist/types/fieldArray";
import { UseFormSetValue } from "react-hook-form";

interface IFileUploaderProps {
  disabled: boolean;
  append?: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  setValue?: UseFormSetValue<any>;
  setValueName?: string;
  width?: string;
  acceptedFileTypes?: string;
  buttonTitle?: string;
}

const FileUploader = ({
  disabled,
  append,
  setValue,
  setValueName,
  acceptedFileTypes = ".pdf, .doc, .docx, .jpg, .jpeg, .png, .gif, .bmp, .tiff, .svg",
  buttonTitle,
  width,
}: IFileUploaderProps) => {
  const { t } = useTranslation(["common"]);
  const [progress, setProgress] = useState<boolean>(false);
  const [files, setFiles] = useState<[{ preview: string; raw?: File }]>([
    {
      preview: "",
      raw: undefined,
    },
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      let files: [{ preview: string; raw?: File }] = [
        {
          preview: "",
          raw: undefined,
        },
      ];
      Array.from(e.target.files).forEach((file) => {
        files.push({
          preview: URL.createObjectURL(file),
          raw: file,
        });
      });
      setFiles(files);
      Array.from(e.target.files).forEach((file) => {
        handleUpload(file);
      });
    }
    e.target.files = null;
  };

  const handleUpload = async (raw?: File) => {
    if (raw) {
      setProgress(true);
      const formData = new FormData();
      formData.append("file", raw, raw.name);
      await API.post("/features/files", formData, {
        onUploadProgress: (progressEvent: any) => {
          setProgress(true);
        },
      })
        .then((res: any) => {
          const { data } = res;
          append && append({ value: data.url });
          setValue && setValue(setValueName || "details.logo", data.url);
          handleCancel();
        })
        .catch((e: any) => {
          handleCancel();
        });
    }
  };

  const handleCancel = async () => {
    setFiles([
      {
        preview: "",
        raw: undefined,
      },
    ]);
    setProgress(false);
  };

  return (
    <Card sx={{ ...imageCard, width }} data-testid="image">
      <Box sx={{ ...insideImageCard }}>
        <Box sx={{ ...insideImageCardRow }}>
          {/* showing the selected file name during upload */}
          {files &&
            files.map(
              (file) =>
                file.preview &&
                file.raw && <Typography>{file.raw.name}</Typography>
            )}
          <Button
            disabled={disabled}
            sx={{ ...button }}
            variant="contained"
            component="label"
          >
            {buttonTitle || t("clickToSelectFile")}
            <input
              accept={acceptedFileTypes}
              multiple
              type="file"
              hidden
              id="upload-button"
              onChange={handleChange}
            />
          </Button>
          {progress && <CircularProgress />}
        </Box>
      </Box>
    </Card>
  );
};

export default FileUploader;
