import { Box, IconButton, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  SBTopToolbarCustomActionsBoxStyle,
  SBTopToolbarCustomActionsIconButtonStyle,
  SBTopToolbarCustomActionsTypographyStyle,
} from "../styles/SBStyles";
import { LoadingButton } from "@mui/lab";
import SBLoadingButton from "../SBForms/SBLoadingButton";
import SBCircularProgress from "../SBForms/SBCircularProgress";

export interface SBTopToolbarCustomActionsPrimary {
  label: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  isLoading?: boolean;
}

export type SBTopToolbarCustomActionsType = SBTopToolbarCustomActionsPrimary;

const SBTopToolbarCustomActions: React.FC<SBTopToolbarCustomActionsType> = ({
  label,
  onClick,
  isLoading,
}) => {
  return (
    <Box sx={{ ...SBTopToolbarCustomActionsBoxStyle }}>
      <Typography
        sx={{
          ...SBTopToolbarCustomActionsTypographyStyle,
        }}
      >
        {label}
      </Typography>
      {isLoading ? (
        <SBCircularProgress />
      ) : (
        <IconButton
          sx={{
            ...SBTopToolbarCustomActionsIconButtonStyle,
          }}
          onClick={onClick}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default SBTopToolbarCustomActions;
