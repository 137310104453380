import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGetBilling } from "./useGetBilling";
import { usePutBillingMutation } from "./usePutBillingMutation";
import { useGetCurrentUser } from "../../../../hooks";
import { useSelector } from "react-redux";
import { getVendorIdSelector } from "../../../../store/selectors/adminSelectors";

export function useBillingForm(id?: number, type?: string) {
  const { t } = useTranslation(["common"]);
  const vendorId = useSelector(getVendorIdSelector);
  const { currentUser } = useGetCurrentUser();
  const { data, isLoading } = useGetBilling(
    id ?? vendorId ?? currentUser?.vendorId
  );
  const putBillingMutation = usePutBillingMutation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        billingEnabled: yup.boolean().nullable(true),
        serviceFee: yup.object().shape({
          feePerChair: yup.number(),
          contractedChairs: yup.number(),
        }),
        bookingDays: yup.object().shape({
          feePerDay: yup.number(),
        }),
        commission: yup.object().shape({
          onlineRate: yup.number(),
          offlineRate: yup.number(),
        }),
      }),
    [t]
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  useEffect(() => {
    setValue("billingEnabled", data?.billingEnabled ?? true);
    setValue(
      "serviceFee.feePerChair",
      (data?.serviceFee as any)?.feePerChair ?? 0
    );
    setValue(
      "serviceFee.contractedChairs",
      (data?.serviceFee as any)?.contractedChairs ?? 0
    );
    setValue(
      "bookingDays.feePerDay",
      (data?.bookingDays as any)?.feePerDay ?? 0
    );
    setValue(
      "commission.onlineRate",
      (data?.commission as any)?.onlineRate ?? 0
    );
    setValue(
      "commission.offlineRate",
      (data?.commission as any)?.offlineRate ?? 0
    );
  }, [data]);

  const onSubmit = useCallback(
    (formValues: any) => {
      if (id) {
        putBillingMutation.mutate({ id, update: formValues });
      } else {
        if (vendorId)
          putBillingMutation.mutate({
            id: vendorId,
            update: formValues,
          });
      }
    },
    // eslint-disable-next-line
    [id, vendorId]
  );

  const handleReset = useCallback(() => {
    setValue("billingEnabled", true);
    setValue("serviceFee.feePerChair", 0);
    setValue("serviceFee.contractedChairs", 0);
    setValue("bookingDays.feePerDay", 0);
    setValue("commission.onlineRate", 0);
    setValue("commission.offlineRate", 0);
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    control,
    errors,
    setValue,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
