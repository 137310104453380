import React, { useState, useRef, useEffect } from "react";
import {
  debounce,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  TextFieldProps,
} from "@mui/material";
import { Search as SearchIcon, Close as CloseIcon } from "@mui/icons-material";

type SearchInputProps = {
  onChange: (value: string) => void;
  loading: boolean;
} & Omit<TextFieldProps, "onChange">;

export const SearchInput: React.FC<SearchInputProps> = ({
  onChange,
  loading,
  value = "",
  inputRef,
  variant = "outlined",
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);
  const lastValueRef = useRef(value);

  const debouncedOnChange = debounce((value: string) => {
    if (value !== lastValueRef.current) {
      onChange(value);
      lastValueRef.current = value;
    }
  }, 500);

  // Sync local state with external value
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  useEffect(() => {
    debouncedOnChange(localValue as string);
    return () => {
      debouncedOnChange.clear();
    };
  }, [localValue, debouncedOnChange]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const clearSearch = () => {
    setLocalValue("");
    onChange("");
  };

  const onSearchIconClick = () => {
    if (inputRef && typeof inputRef === "object" && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <TextField
      {...props}
      inputRef={inputRef}
      value={localValue}
      onChange={handleInputChange}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={onSearchIconClick}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {loading && <CircularProgress size={20} />}
            {!loading &&
              localValue &&
              ((
                <IconButton
                  size="large"
                  aria-label="clear"
                  edge="end"
                  onClick={clearSearch}
                >
                  <CloseIcon />
                </IconButton>
              ) as any)}
          </InputAdornment>
        ),
      }}
      variant="standard"
      sx={{ pt: "15px" }}
    />
  );
};
