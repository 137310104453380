import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router";
import { useGetPrice } from "./useGetPrice";
import { useEditPricesMutation } from "./useEditPricesMutation";
import { CreatePriceDto, UpdatePriceDto } from "../../../api-client/generated";
import dayjs from "dayjs";
import { useCreatePricesMutation } from "./useCreatePricesMutation";

export function usePriceForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const { data: price, isLoading } = useGetPrice(Number(id));
  const editPricesMutation = useEditPricesMutation();
  const createPrice = useCreatePricesMutation();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        vendorId: yup.number(),
        bookingStart: yup
          .string()
          .nullable(true)
          .transform((v) => {
            return v ? dayjs(v).toISOString() : null;
          }),
        bookingEnd: yup
          .string()
          .nullable(true)
          .transform((v) => {
            return v ? dayjs(v).toISOString() : null;
          }),
        offerStart: yup
          .string()
          .nullable(true)
          .transform((v) => {
            return v ? dayjs(v).toISOString() : null;
          }),
        offerEnd: yup
          .string()
          .nullable(true)
          .transform((v) => {
            return v ? dayjs(v).toISOString() : null;
          }),
        rules: yup.object().nullable(),
      }),
    [t]
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (price) {
      setValue("vendorId", price.vendorId);
      setValue("bookingStart", price.bookingStart);
      setValue("bookingEnd", price.bookingEnd);
      setValue("offerStart", price.offerStart);
      setValue("offerEnd", price.offerEnd);
      setValue("rules", price.rules);
    } else {
      setValue("vendorId", 0);
      setValue("bookingStart", dayjs(new Date()).toISOString());
      setValue("bookingEnd", dayjs(new Date()).toISOString());
      setValue("offerStart", null);
      setValue("offerEnd", null);
      setValue("rules", {
        dayRules: [
          {
            maxDays: 1,
            minDays: 0,
            conditions: [
              {
                perExtra: 0,
                grossPrice: 0,
              },
            ],
          },
        ],
        durationRules: [
          {
            type: "FULLDAY",
            minHours: 1,
            conditions: [
              {
                grossPrice: 0,
              },
            ],
          },
        ],
      });
    }
  }, [price, setValue]);

  const onSubmit = useCallback(
    (formValues) => {
      if (type === "new") {
        createPrice
          .mutateAsync({
            createPriceDto: formValues as CreatePriceDto,
          })
          .finally(() => {
            history.push("/prices");
          })
          .catch((_) => {});
      } else {
        editPricesMutation
          .mutateAsync({
            id: id,
            updatePriceDto: formValues as UpdatePriceDto,
          })
          .catch((_) => {});
      }
    },
    [id, type, history, editPricesMutation]
  );

  const handleReset = useCallback(() => {
    setValue("vendorId", 0);
    setValue("bookingStart", dayjs(new Date()).toISOString());
    setValue("bookingEnd", dayjs(new Date()).toISOString());
    setValue("offerStart", null);
    setValue("offerEnd", null);
    setValue("rules", {
      dayRules: [
        {
          maxDays: 1,
          minDays: 0,
          conditions: [
            {
              perExtra: 0,
              grossPrice: 0,
            },
          ],
        },
      ],
      durationRules: [
        {
          type: "FULLDAY",
          minHours: 1,
          conditions: [
            {
              grossPrice: 0,
            },
          ],
        },
      ],
    });
  }, [setValue]);

  return {
    watch,
    isLoading,
    control,
    errors,
    setValue,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
