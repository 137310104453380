import { create } from "zustand";
import { VisibilityState } from "@tanstack/react-table";
import { persist } from "zustand/middleware";

interface ColumnVisibilityState {
  columnVisibility: VisibilityState;
  setVisibility: (value: VisibilityState) => void;
}

export const useColumnVisibilityStore = create<ColumnVisibilityState>()(
  persist(
    (set) => {
      return {
        columnVisibility: {},
        setVisibility: (columnVisibility: VisibilityState) =>
          set((state) => ({
            ...state,
            columnVisibility,
          })),
      };
    },
    {
      name: "useColumnVisibilityStore",
    }
  )
);
