import { getPriceCalculate, putPrice } from "./../../api/priceApi";
import { all, call, debounce, put, takeLatest } from "typed-redux-saga";
import { getPrices, getFilteredPrices } from "../../api/priceApi";
import history from "../../utils/history/history";
import { Action } from "@reduxjs/toolkit";
import {
  fetchPricesFailure,
  fetchPricesRequest,
  fetchPricesSuccess,
  filterPricesFailure,
  filterPricesRequest,
  filterPricesSuccess,
  filterCancelPricesSuccess,
  pushPriceFailure,
  pushPriceRequest,
  routerRedirectPrice,
  pushPriceSuccess,
  getPriceCalculateRequest,
  getPriceCalculateSuccess,
  getPriceCalculateFailure,
} from "../reducers/pricesReducer";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import { dateToProperTimeFormat } from "../../utils/conversions/dataConversion";

function* fetchPricesSaga(action: Action) {
  try {
    if (fetchPricesRequest.match(action)) {
      const { vendorId, page, itemsPerPage } = action.payload;
      const { data } = yield* call(getPrices, vendorId, itemsPerPage, page);
      yield put(
        fetchPricesSuccess({
          prices: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchPricesFailure({
        error: e.message,
      })
    );
  }
}

function* filterCancelSaga() {
  try {
    yield put(filterCancelPricesSuccess({}));
  } catch (e: any) {
    yield* put(
      filterPricesFailure({
        error: e.message,
      })
    );
  }
}

function* filterPricesSaga(action: Action) {
  try {
    if (filterPricesRequest.match(action)) {
      const { filterData, vendorId, itemsPerPage, page } = action.payload;
      const filterString: string = filterDataToString(filterData);
      if (filterString.length > 0) {
        const { data } = yield* call(
          getFilteredPrices,
          vendorId,
          itemsPerPage,
          page,
          filterString
        );
        yield put(
          filterPricesSuccess({
            prices: data.items,
            count: data.count,
            itemsPerPage: itemsPerPage,
            page: page,
            filterPhrase: filterString,
          })
        );
      }
    } else {
      yield* call(filterCancelSaga);
    }
  } catch (e: any) {
    yield* put(
      filterPricesFailure({
        error: e.message,
      })
    );
  }
}

function* pushPriceSaga(action: Action) {
  try {
    if (pushPriceRequest.match(action)) {
      const { formData, priceId } = action.payload;
      const { id, ...newData } = formData;
      newData.pickupTimeStart = dateToProperTimeFormat(
        formData.pickupTimeStart
      );
      newData.pickupTimeEnd = dateToProperTimeFormat(formData.pickupTimeEnd);
      yield* call(putPrice, priceId, newData);
      formData.pickupTimeStart = dateToProperTimeFormat(
        formData.pickupTimeStart
      );
      formData.pickupTimeEnd = dateToProperTimeFormat(formData.pickupTimeEnd);
      yield* put(pushPriceSuccess({ price: formData }));
      yield* call([history, history.push], `/prices`);
    }
  } catch (e: any) {
    yield* put(
      pushPriceFailure({
        error: e.message,
      })
    );
  }
}

function* getPriceCalculateSaga(action: Action) {
  try {
    if (getPriceCalculateRequest.match(action)) {
      const { priceCalculate } = action.payload;
      const { data } = yield* call(getPriceCalculate, priceCalculate);
      yield put(
        getPriceCalculateSuccess({
          calculatedPrice: data.price ? data.price : 0,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getPriceCalculateFailure({
        error: e.message,
      })
    );
  }
}

function* routerRedirectPriceSaga(action: Action) {
  if (routerRedirectPrice.match(action)) {
    const { id, type } = action.payload;
    yield* call(
      [history, history.push],
      `/prices${id ? `/${id}` : ``}/${type}`
    );
  }
}

function* PricesSaga() {
  yield* all([
    takeLatest(fetchPricesRequest.type, fetchPricesSaga),
    takeLatest(filterPricesRequest.type, filterPricesSaga),
    takeLatest(pushPriceRequest.type, pushPriceSaga),
    debounce(1000, getPriceCalculateRequest.type, getPriceCalculateSaga),
    takeLatest(routerRedirectPrice.type, routerRedirectPriceSaga),
  ]);
}

export default PricesSaga;
