import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { beachApi } from "../../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteBeach = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteBeach"],
    ({ id }: { id: number }) => beachApi.beachesControllerDelete({ id: id }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteBeachSuccess`));
        queryClient.refetchQueries(["adminBeaches"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteBeachFailure`));
      },
    }
  );
};
