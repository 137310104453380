import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useGetBooking } from "../../components/bookingsList/booking/hooks";

export interface IBookingPriceFormData {
  totalPrice: string;
  vat: string;
}

export function useBookingPriceForm(id: number, type: string) {
  const { booking } = useGetBooking(id);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        totalPrice: yup.string(),
        vat: yup.string(),
      }),
    []
  );

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (booking) {
      setValue("totalPrice", booking.totalPrice);
      setValue(
        "vat",
        (booking.totalPrice - booking.totalPrice / 1.19).toFixed(2)
      );
    }
  }, [booking, setValue]);

  const onSubmit = useCallback(
    (formValues: any) => {
      if (type.includes("new")) {
        //dispatch(postBeachChairRequest({ formData: formValues }));
      } else {
        //dispatch(postBeachChairRequest({ formData: formValues }));
      }
    },
    [type]
  );

  return {
    control,
    errors,
    onSubmit: handleSubmit(onSubmit),
  };
}
