import React, { Dispatch, SetStateAction } from "react";
import { IAuxProps } from "../../models/common/IAuxProps";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { RemoveBookingBeachChairRightDrawerToggleRequestPayload } from "../../store/actionPayload/bookingPayloads";
import { useDispatch } from "react-redux";
import { Drawer } from "@mui/material";
import { Props } from "../../utils/types/Props";

export interface IRightWrapperProps extends IAuxProps {
  state: boolean;
  setState?: Dispatch<SetStateAction<boolean>>;
  action?: ActionCreatorWithPayload<
    RemoveBookingBeachChairRightDrawerToggleRequestPayload,
    string
  >;
}

const RightDrawer = ({
  state,
  setState,
  action,
  children,
}: IRightWrapperProps & Props) => {
  const dispatch = useDispatch();

  return (
    <Drawer
      sx={{
        ".MuiDrawer-paper": {
          height: "calc(100vh - 160px)",
          top: "80px",
          bottom: "80px",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        },
      }}
      anchor="right"
      data-testid="right-drawer"
      open={state}
      onClose={() => {
        setState && setState(false);
        action && dispatch(action({ toggle: false }));
      }}
      disableEnforceFocus
    >
      {children}
    </Drawer>
  );
};

export default RightDrawer;
