import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { CashPointStatus as Response } from "../../../api-client/generated";
import { ReactQueryRefetch } from "../../../types";
import { cashPointApi } from "../../../api/apiTanStack";

interface UseGetCashPointBalance {
  cashPointBalance?: Response;
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<Response, AxiosError>;
}

export const useGetCashPointBalance = (locationId?: string): UseGetCashPointBalance => {
  const {data: cashPointBalance, refetch, isLoading, isFetched, isFetching} = useQuery<
    Response,
    AxiosError
  >(
    ['cashPointBalance', Number(locationId)],
    () =>
      cashPointApi
        .cashPointControllerGetCashPointStatus({ locationId: Number(locationId)})
        .then(({ data }) => data),
    { enabled: locationId !== undefined },
  );

  return {
    cashPointBalance,
    isLoading,
    isFetching,
    isFetched,
    refetch
  }
}
