import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useSBTablePersistState } from "../../components/extras/hooks/useSBTablePersist";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

export function useSBTablePersists(name: string) {
  const {
    columnFilters,
    setColumnFiltersPersits,
    sorting,
    setSortingPersits,
    pagination,
    setPaginationPersists,
  } = useSBTablePersistState();
  const item = useMemo(() => localStorage.getItem(name) ?? "", [name]);
  const [filters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    item.length && Object.keys(JSON.parse(item).state).length
      ? JSON.parse(item).state.columnFilters
      : []
  );
  const [sort, setSorting] = useState<MRT_SortingState>(
    item.length && Object.keys(JSON.parse(item).state).length
      ? JSON.parse(item).state.sorting
      : []
  );
  const [paginate, setPagination] = useState<MRT_PaginationState>(
    item.length && Object.keys(JSON.parse(item).state).length
      ? JSON.parse(item).state.pagination
      : {
          pageIndex: 0,
          pageSize: 15,
        }
  );

  useLayoutEffect(() => {
    useSBTablePersistState.persist.setOptions({
      name,
    });
    useSBTablePersistState.persist.rehydrate();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setColumnFiltersPersits(filters);
  }, [setColumnFiltersPersits, filters]);

  useEffect(() => {
    setSortingPersits(sort);
  }, [setSortingPersits, sort]);

  useEffect(() => {
    setPaginationPersists(paginate);
  }, [setPaginationPersists, paginate]);

  return {
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    pagination,
    setPagination,
  };
}
