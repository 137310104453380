import { useTranslation } from "react-i18next";
import ReportsHeader from "./reportsHeader/reportsHeader";
import { Box, Card, Typography } from "@mui/material";
import {
  Lock,
  Assessment,
  AccountBalance,
  BeachAccess,
} from "@mui/icons-material";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import {
  SBCardBoxStyle,
  SBCardIconStyle,
  SBCardStyle,
  SBCardTypographyStyle,
  SBReportsCardsStyle,
} from "../SBComponents/styles/SBStyles";
import { useHistory } from "react-router";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
const Reports = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  return (
    <SBMainComponentGridWrapper>
      <ReportsHeader />
      <Box
        sx={{
          ...SBReportsCardsStyle,
        }}
        data-testid="reports-content"
      >
        <Card
          sx={{
            ...SBCardStyle,
          }}
          data-testid="reports-box"
        >
          <Typography
            sx={{
              ...SBCardTypographyStyle,
            }}
            data-testid="reports-title"
          >
            {t("keyStatus")}
          </Typography>
          <Box
            sx={{
              ...SBCardBoxStyle,
            }}
          >
            <Lock
              sx={{
                ...SBCardIconStyle,
              }}
            />
            <SBLoadingButton
              label={t("reports")}
              type="blue"
              icon={<Lock />}
              onClick={() => {
                history.push("/reports/key_status");
              }}
            />
          </Box>
        </Card>

        <Card
          sx={{
            ...SBCardStyle,
          }}
          data-testid="reports-box"
        >
          <Typography
            sx={{
              ...SBCardTypographyStyle,
            }}
            data-testid="reports-title"
          >
            {t("closingReports")}
          </Typography>
          <Box
            sx={{
              ...SBCardBoxStyle,
            }}
          >
            <Assessment
              sx={{
                ...SBCardIconStyle,
              }}
            />
            <SBLoadingButton
              label={t("reports")}
              type="blue"
              icon={<Assessment />}
              onClick={() => {
                history.push("/reports/closing_reports");
              }}
            />
          </Box>
        </Card>
        <Card
          sx={{
            ...SBCardStyle,
          }}
          data-testid="reports-box"
        >
          <Typography
            sx={{
              ...SBCardTypographyStyle,
            }}
            data-testid="reports-title"
          >
            {t("sepaXMLs")}
          </Typography>
          <Box
            sx={{
              ...SBCardBoxStyle,
            }}
          >
            <AccountBalance
              sx={{
                ...SBCardIconStyle,
              }}
            />
            <SBLoadingButton
              label={t("reports")}
              type="blue"
              icon={<AccountBalance />}
              onClick={() => {
                history.push("/reports/sepa_xmls");
              }}
            />
          </Box>
        </Card>
        <Card
          sx={{
            ...SBCardStyle,
          }}
          data-testid="reports-box"
        >
          <Typography
            sx={{
              ...SBCardTypographyStyle,
            }}
            data-testid="reports-title"
          >
            {t("beachChairReports")}
          </Typography>
          <Box
            sx={{
              ...SBCardBoxStyle,
            }}
          >
            <BeachAccess
              sx={{
                ...SBCardIconStyle,
              }}
            />
            <SBLoadingButton
              label={t("reports")}
              type="blue"
              icon={<BeachAccess />}
              onClick={() => {
                history.push("/reports/beach_chair");
              }}
            />
          </Box>
        </Card>
        <Card
          sx={{
            ...SBCardStyle,
          }}
          data-testid="reports-box"
        >
          <Typography
            sx={{
              ...SBCardTypographyStyle,
            }}
            data-testid="reports-title"
          >
            {t("statistics")}
          </Typography>
          <Box
            sx={{
              ...SBCardBoxStyle,
            }}
          >
            <StackedLineChartIcon
              sx={{
                ...SBCardIconStyle,
              }}
            />
            <SBLoadingButton
              label={t("statistics")}
              type="blue"
              icon={<StackedLineChartIcon />}
              onClick={() => {
                history.push("/reports/statistics");
              }}
            />
          </Box>
        </Card>
      </Box>
    </SBMainComponentGridWrapper>
  );
};

export default Reports;
