import { useEffect, useState } from "react";
import { useColumnVisibilityStore } from "../../components/extras/hooks/useColumnVisibility";
import { VisibilityState } from "@tanstack/react-table";

export function useColumnVisibility(name: string) {
  const { columnVisibility, setVisibility } = useColumnVisibilityStore();
  const [visibility, setColumnVisibility] =
    useState<VisibilityState>(columnVisibility);
  useEffect(() => {
    useColumnVisibilityStore.persist.setOptions({
      name,
    });
    useColumnVisibilityStore.persist.rehydrate();
    setColumnVisibility(useColumnVisibilityStore.getState().columnVisibility);
    if (name === "beachChairBookingsAdvancedListColumnVisibilityStore") {
      const parse = JSON.parse(
        localStorage.getItem(
          "beachChairBookingsAdvancedListColumnVisibilityStore"
        ) ?? ``
      );
      let cv = useColumnVisibilityStore.getState().columnVisibility;
      if (!Object.keys(parse?.state?.columnVisibility).length) {
        cv["id"] = false;
        cv["bookingDate"] = false;
        cv["discount"] = false;
        cv["end"] = false;
        cv["sectionId"] = false;
        cv["rowId"] = false;
      }
      setVisibility(cv);
    }
    if (name === "BeachChairListColumnVisibilityStore") {
      const parse = JSON.parse(
        localStorage.getItem("BeachChairListColumnVisibilityStore") ?? ``
      );
      let cv = useColumnVisibilityStore.getState().columnVisibility;
      if (!Object.keys(parse?.state?.columnVisibility).length) {
        cv["buyingDate"] = false;
        cv["attributes.color"] = false;
        cv["internalNote"] = false;
        cv["images"] = false;
        cv["stopSaleDates"] = false;
        cv["afterHourBooking"] = false;
        cv["seasonBeachChair"] = false;
      }
      setVisibility(cv);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setVisibility(visibility);
  }, [visibility, setVisibility]);

  return {
    columnVisibility,
    setColumnVisibility,
  };
}
