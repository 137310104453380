import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { cityApi } from "../../../../api/apiTanStack";
import { OrderByDirection } from "../../../../api-client/generated";

export const useGetAdminCities = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "adminCities",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => `filters[${filter.id}]=${filter.value}`)
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return cityApi
        .citiesControllerFindMany(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
