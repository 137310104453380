import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IColumnDefinition } from "../../models/dataTable/IColumnDefinition";
import { IFilterSelect } from "../../models/dataTable/IFilterSelect";
import { FiltersBeachChairPayload } from "../actionPayload/dataTablePayloads";

export interface DataTableColumnState {
  bookings: IColumnDefinition[];
  beachChairs: IColumnDefinition[];
  customers: IColumnDefinition[];
  bookingsBeachChairs: IColumnDefinition[];
  bookingsExtras: IColumnDefinition[];
  prices: IColumnDefinition[];
  extras: IColumnDefinition[];
  reportKeyStatus: IColumnDefinition[];
  closingReports: IColumnDefinition[];
  destinationRegions: IColumnDefinition[];
  destinationSections: IColumnDefinition[];
  shortUrls: IColumnDefinition[];
  locks: IColumnDefinition[];
  vendors: IColumnDefinition[];
}

const initialState: DataTableColumnState = {
  bookings: [
    {
      field: "id",
      displayName: "ID",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "bookingRef",
      displayName: "Buchungsnummer",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "lastName",
      displayName: "Nachname",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "createdAt",
      displayName: "Buchungsdatum",
      width: "20%",
      filter: {
        filterType: "date",
        filterDateValues: {
          start: "Von Buchungsdatum",
          end: "Bis Buchungsdatum",
        },
      },
    },
    {
      field: "totalPrice",
      displayName: "Gesamtpreis",
      width: "10%",
      filter: {
        filterType: "text",
      },
    },
    {
      field: "discount",
      displayName: "Rabattiert",
      width: "10%",
      filter: { filterType: "boolean" },
    },
    {
      field: "status",
      displayName: "Status",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [
          { value: "", displayName: "filters.NO_STATUS" },
          { value: "CONFIRMED", displayName: "filters.CONFIRMED" },
          { value: "OPEN", displayName: "filters.OPEN" },
          { value: "CANCELED", displayName: "filters.CANCELED" },
          { value: "PENDING", displayName: "filters.PENDING" },
          { value: "RESERVED", displayName: "filters.RESERVED" },
          { value: "EXPIRED", displayName: "filters.EXPIRED" },
        ],
      },
    },
    { field: "actions", displayName: "", width: "10%" },
  ],
  beachChairs: [
    {
      field: "id",
      displayName: "beachChairsDataTable.id",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "publicNumber",
      displayName: "beachChairsDataTable.publicNumber",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "model",
      displayName: "beachChairsDataTable.model",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "models.NO_MODEL" }],
      },
    },
    {
      field: "sectionId",
      displayName: "beachChairsDataTable.sectionId",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "No section" }],
      },
    },
    {
      field: "rowId",
      displayName: "beachChairsDataTable.rowId",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "No row" }],
      },
    },
    {
      field: "lockId",
      displayName: "beachChairsDataTable.lock",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "No lock" }],
      },
    },
    {
      field: "description",
      displayName: "beachChairsDataTable.description",
      width: "25%",
      filter: { filterType: "text" },
    },
    {
      field: "status",
      displayName: "beachChairsDataTable.status",
      width: "10%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "No status" }],
      },
    },
    {
      field: "actions",
      displayName: "beachChairsDataTable.actions",
      width: "10%",
    },
  ],
  customers: [
    {
      field: "id",
      displayName: "id",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "firstName",
      displayName: "firstname",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "lastName",
      displayName: "lastname",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "email",
      displayName: "email",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "zip",
      displayName: "zip",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "city",
      displayName: "city",
      width: "10%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "25%" },
  ],
  bookingsBeachChairs: [
    {
      field: "id",
      displayName: "ID",
      isDisplayed: true,
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "bid",
      displayName: "BID",
      isDisplayed: true,
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "number",
      displayName: "Nummer",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "model",
      displayName: "Modell",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "section",
      displayName: "Abschnitt",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "row",
      displayName: "Reihe",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "lockNumber",
      displayName: "Schlossnummer",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "begin",
      displayName: "Mietbeginn",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "end",
      displayName: "Mietende",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "price",
      displayName: "Preis",
      width: "8%",
      filter: { filterType: "text" },
    },
    {
      field: "discount",
      displayName: "Rabattiert",
      width: "2%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "10%" },
  ],
  bookingsExtras: [
    {
      field: "extraId",
      displayName: "Extra ID",
      isDisplayed: true,
      width: "auto",
      filter: { filterType: "text" },
    },
    {
      field: "name",
      displayName: "Name",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "type",
      displayName: "Typ",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "amount",
      displayName: "Menge",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "totalPrice",
      displayName: "Gesamtpreis",
      width: "8%",
      filter: { filterType: "text" },
    },
    {
      field: "vat",
      displayName: "Mehrwertsteuer",
      width: "5%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  prices: [
    {
      field: "id",
      displayName: "ID",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "duration",
      displayName: "Dauer",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "booking",
      displayName: "Reservierungszeitraum",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "pickupDate",
      displayName: "Saison",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "pickupTime",
      displayName: "Tageszeit",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "grossPrice",
      displayName: "Preis",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "section",
      displayName: "Abschnitt",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "row",
      displayName: "Reihe",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "model",
      displayName: "Typ",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "vendor",
      displayName: "Betreiber",
      width: "10%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "10%" },
  ],
  extras: [
    {
      field: "id",
      displayName: "ID",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "name",
      displayName: "Name",
      width: "25%",
      filter: { filterType: "text" },
    },
    {
      field: "onlineBooking",
      displayName: "Online Buchbar",
      width: "10%",
      filter: { filterType: "boolean" },
    },
    {
      field: "type",
      displayName: "Type",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "price",
      displayName: "Preis",
      width: "10%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  reportKeyStatus: [
    {
      field: "id",
      isDisplayed: true,
      displayName: "ID",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "publicNumber",
      displayName: "beachChairsDataTable.publicNumber",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "start",
      displayName: "Von Buchungsdatum",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "end",
      displayName: "Bis Buchungsdatum",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "description",
      displayName: "Notiz",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "locationId",
      displayName: "Vermietungsbüro",
      width: "20%",
      filter: {
        filterType: "select",
        filterSelectValues: [{ value: "", displayName: "NO_LOCATION" }],
      },
    },
    {
      field: "bookingRef",
      displayName: "Buchungsnummer",
      width: "10%",
      filter: { filterType: "text" },
    },
    { field: "checked", displayName: "", width: "10%" },
  ],
  closingReports: [
    {
      field: "name",
      displayName: "Name",
      width: "30%",
      filter: { filterType: "text" },
    },
    {
      field: "type",
      displayName: "Typ",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "details",
      displayName: "Berichtsdatum",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "createdAt",
      displayName: "Erstellungsdatum",
      width: "20%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  destinationRegions: [
    {
      field: "id",
      displayName: "ID",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "name",
      displayName: "Name",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "shortDescription",
      displayName: "Kurzbeschreibung",
      width: "50%",
      filter: { filterType: "text" },
    },
    {
      field: "active",
      displayName: "Aktiv",
      width: "10%",
      filter: { filterType: "boolean" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  destinationSections: [
    {
      field: "id",
      displayName: "ID",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "name",
      displayName: "Name",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "description",
      displayName: "Beschreibung",
      width: "40%",
      filter: { filterType: "text" },
    },
    {
      field: "vendorId",
      displayName: "Betreiber",
      width: "20%",
      filter: {
        filterType: "select",
        filterSelectValues: [
          { value: "", displayName: "destinations.noVendor" },
        ],
      },
    },
    {
      field: "active",
      displayName: "Aktiv",
      width: "10%",
      filter: { filterType: "boolean" },
    },
    { field: "actions", displayName: "", width: "10%" },
  ],
  shortUrls: [
    {
      field: "uid",
      displayName: "UID",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "updatedAt",
      displayName: "Aktualisiert am",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "tagId",
      displayName: "TagId",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "url",
      displayName: "URL",
      width: "30%",
      filter: { filterType: "text" },
    },
    {
      field: "counter",
      displayName: "Zähler",
      width: "5%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  locks: [
    {
      field: "id",
      displayName: "ID",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "number",
      displayName: "Schlossnummer",
      width: "15%",
      filter: { filterType: "text" },
    },
    {
      field: "updatedAt",
      displayName: "Aktualisiert am",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "mac",
      displayName: "MAC",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "vendorId",
      displayName: "Betreiber",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "status",
      displayName: "Status",
      width: "5%",
      filter: { filterType: "text" },
    },
    {
      field: "batteryLevel",
      displayName: "Batterie Level",
      width: "10%",
      filter: { filterType: "text" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
  vendors: [
    {
      field: "id",
      displayName: "ID",
      width: "10%",
      filter: { filterType: "text" },
    },
    {
      field: "name",
      displayName: "Name",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "companyName",
      displayName: "Firmen Name",
      width: "20%",
      filter: { filterType: "text" },
    },
    {
      field: "city",
      displayName: "city",
      width: "30%",
      filter: { filterType: "text" },
    },
    {
      field: "activeAvailability",
      displayName: "Aktiv Verfügbarkeit",
      width: "10%",
      filter: { filterType: "boolean" },
    },
    { field: "actions", displayName: "", width: "20%" },
  ],
};

const dataTableColumnSlice = createSlice({
  name: "dataTableColumn",
  initialState: initialState,
  reducers: {
    dataTableUpdateBeachChairsFilters(
      state,
      action: PayloadAction<FiltersBeachChairPayload>
    ) {
      const models = action.payload.filters.filter.models.map(
        (nameOfFilter) => {
          return {
            value: `${nameOfFilter}`,
            displayName: `models.${nameOfFilter}`,
          };
        }
      );
      models.push({ value: "", displayName: "filters.NO_MODEL" });

      const sections = action.payload.filters.filter.sections.map(
        (nameOfFilter) => {
          return {
            value: `${nameOfFilter.id}`,
            displayName: `${nameOfFilter.value}`,
          };
        }
      );
      sections.push({ value: "", displayName: "filters.NO_SECTIONS" });

      let rows: IFilterSelect[] = [];
      action.payload.filters.filter.sections.forEach((section) =>
        section.rows.forEach((nameOfFilter) => {
          rows.push({
            value: `${nameOfFilter.id}`,
            displayName: `${nameOfFilter.value}`,
          });
        })
      );
      rows.push({ value: "", displayName: "filters.NO_ROWS" });

      const locks = action.payload.filters.filter.locks.map((nameOfFilter) => {
        return {
          value: `${nameOfFilter.id}`,
          displayName: `${nameOfFilter.value}`,
        };
      });
      locks.push({ value: "", displayName: "filters.NO_LOCKS" });

      const statuses = action.payload.filters.filter.statuses.map(
        (nameOfFilter) => {
          return {
            value: `${nameOfFilter}`,
            displayName: `filters.${nameOfFilter}`,
          };
        }
      );
      statuses.push({ value: "", displayName: "NO_STATUS" });

      const locations = action.payload.filters.filter.locations.map(
        (nameOfFilter) => {
          return {
            value: `${nameOfFilter.id}`,
            displayName: `${nameOfFilter.value}`,
          };
        }
      );
      locations.push({ value: "", displayName: "filters.NO_LOCATION" });

      const idxModels = state.beachChairs.findIndex(
        (data) => data.field === "model"
      );
      state.beachChairs[idxModels].filter!.filterSelectValues = models;

      const idxSections = state.beachChairs.findIndex(
        (data) => data.field === "sectionId"
      );
      state.beachChairs[idxSections].filter!.filterSelectValues = sections;

      const idxRows = state.beachChairs.findIndex(
        (data) => data.field === "rowId"
      );
      state.beachChairs[idxRows].filter!.filterSelectValues = rows;

      const idxLocks = state.beachChairs.findIndex(
        (data) => data.field === "lockId"
      );
      state.beachChairs[idxLocks].filter!.filterSelectValues = locks;

      const idxStatuses = state.beachChairs.findIndex(
        (data) => data.field === "status"
      );
      state.beachChairs[idxStatuses].filter!.filterSelectValues = statuses;

      const idxLocation = state.reportKeyStatus.findIndex(
        (data) => data.field === "locationId"
      );
      state.reportKeyStatus[idxLocation].filter!.filterSelectValues = locations;
    },
  },
});

export const {
  dataTableUpdateBeachChairsFilters,
} = dataTableColumnSlice.actions;

export default dataTableColumnSlice.reducer;
