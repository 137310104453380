import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { FeaturesApiBookingDetailsControllerCancelBookingRequest } from "../../../../api-client/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useCancelBooking, useGetCancellationFee } from "../hooks";
import SBNumberField from "../../../SBComponents/SBForms/SBNumberField";
import SBCheckBox from "../../../SBComponents/SBForms/SBCheckBox";

type FormValues = Pick<
  FeaturesApiBookingDetailsControllerCancelBookingRequest,
  "cancellationFee" | "skipRefund"
>;

const validationSchema = yup.object().shape({
  cancellationFee: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : Number(value)))
    .min(0)
    .notRequired(),
  skipRefund: yup.boolean().notRequired(),
});

const CancellationModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [showCancellationOptions, setShowCancellationOptions] = useState(false);
  const { id: bookingId } = useParams<{ id: string }>();
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const { cancelBooking, isLoading: isCancelling } = useCancelBooking();
  const { cancellationFee: calculatedFee } = useGetCancellationFee({
    bookingId: Number(bookingId),
    enabled: open,
  });

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      cancellationFee: calculatedFee,
      skipRefund: false,
    },
  });

  useEffect(() => {
    // If the cancellation fee changes, update the form value
    setValue("cancellationFee", calculatedFee ?? 0);
  }, [calculatedFee, setValue, open, showCancellationOptions]);

  const toggleCancellationOptions = () => {
    resetField("cancellationFee");
    setValue("cancellationFee", 0);
    resetField("skipRefund");
    setShowCancellationOptions(!showCancellationOptions);
  };

  const handleCloseModal = () => {
    setShowCancellationOptions(false);
    resetField("cancellationFee");
    resetField("skipRefund");
    onClose();
  };

  const onSubmit = ({ cancellationFee, skipRefund }: FormValues) => {
    cancelBooking(
      {
        id: Number(bookingId),
        cancellationFee: showCancellationOptions
          ? Number(cancellationFee ?? 0)
          : undefined,
        skipRefund:
          showCancellationOptions && skipRefund ? skipRefund : undefined,
      },
      {
        onSuccess: () => {
          toast.success(t(`toastMessages.CancelBookingSuccess`));
          queryClient.refetchQueries(["booking", Number(bookingId)]);
          queryClient.refetchQueries(["bookings"]);
          onClose();
        },
        onError: (e: any) => {
          const errorCode = e?.response?.data?.errorCode;
          if (errorCode && errorCode === "CASH_POINT_STATE_ERROR") {
            toast.error(t(`toastMessages.${errorCode}`));
          } else {
            toast.error(t(`toastMessages.CancelBookingFailure`));
          }
        },
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cancel-booking-modal-title"
      aria-describedby="cancel-booking-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="cancel-booking-modal-title">
        {t("cancelBookingModal.title")}
      </DialogTitle>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText id="cancel-booking-modal-description">
            <Typography component="span">
              {t("cancelBookingModal.text")}
            </Typography>{" "}
            <Button type="button" onClick={toggleCancellationOptions}>
              {t("options")}
            </Button>
          </DialogContentText>

          {showCancellationOptions && (
            <FormGroup>
              <SBNumberField
                name="cancellationFee"
                label={t("cancelBookingModal.cancellationFee")}
                control={control}
                type={"edit"}
                isLoading={false}
                defaultValue={0}
                endAdornmentSymbol="€"
                error={
                  errors?.cancellationFee &&
                  t((errors.cancellationFee as any)?.message)
                }
              />
              <Box sx={{ pl: "30px" }}>
                <SBCheckBox
                  name="skipRefund"
                  label={t("cancelBookingModal.skipRefund")}
                  control={control}
                  type={"edit"}
                  isLoading={false}
                  error={
                    errors?.skipRefund && t((errors.skipRefund as any)?.message)
                  }
                />
              </Box>
            </FormGroup>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            type="button"
            onClick={handleCloseModal}
            disabled={isCancelling}
          >
            {t("cancelBookingModal.dismissModal")}
          </Button>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isCancelling}
          >
            {t("cancelBookingModal.confirmCancellation")}
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CancellationModal;
