import { Action } from "redux";
import { all, call, put, takeLatest } from "typed-redux-saga";
import { routerRequest } from "../reducers/routerReducer";
import { logoutUserRequest } from "../reducers/authReducer";
import history from "../../utils/history/history";

function* routerSaga(action: Action) {
  if (routerRequest.match(action)) {
    const { path, id, type } = action.payload;
    yield* call([history, history.push], `/${path}${id ? `/${id}` : ``}${type? `/${type}`: ``}`);
    if(path.includes("logout")) {
      yield* put(logoutUserRequest());
    }
  }
}

function* RouterSaga() {
  yield* all([takeLatest(routerRequest.type, routerSaga)]);
}

export default RouterSaga;
