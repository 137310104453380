import { useQuery } from "@tanstack/react-query";
import { pricesApi } from "../../../api/apiTanStack";

export const useGetPrice = (id: number) =>
  useQuery({
    queryKey: ["getPrice", Number(id)],
    queryFn: async () => {
      return pricesApi
        .pricesControllerFindOne({ id: id })
        .then((response) => response.data);
    },
    retry: 0,
    cacheTime: Infinity,
    keepPreviousData: true,
  });
