import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { useAvailabilitiesBeachChairsFilter } from "../../../../utils/customHooks/useAvailabilitiesBeachChairsFilter";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getFiltersBeachChairsSelector } from "../../../../store/selectors/beachChairsSelectors";
import { getFiltersBeachChairRequest } from "../../../../store/reducers/beachChairsReducer";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";

const AvailabilitiesBeachChairsFilter = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { sectionId } = useParams<{ sectionId: string }>();
  const { control, onSubmit } = useAvailabilitiesBeachChairsFilter();
  const filters = useSelector(getFiltersBeachChairsSelector());

  useEffect(() => {
    if (filters === undefined) {
      dispatch(
        getFiltersBeachChairRequest({
          vendorId: 1,
        })
      );
    }
  }, [dispatch, filters]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Box
        sx={{
          height: "48px",
          display: "grid",
          gridTemplateColumns: "200px 200px 100px 250px 100px",
          gap: "15px",
          alignItems: "center",
          paddingLeft: "20px",
          paddingTop: "20px",
        }}
      >
        <FormControl>
          <InputLabel id="row-label">{t("row")}</InputLabel>
          <Controller
            control={control}
            name="row"
            defaultValue={""}
            render={({ field }) => (
              <Select
                variant="standard"
                id="row"
                data-testid="row"
                placeholder={t("row")}
                {...field}
              >
                <MenuItem value={""} key={`rid-row-empty-0`}>
                  {t("NO_ROW")}
                </MenuItem>
                {filters?.sections
                  .filter((section) => section.id === +sectionId)
                  .map((section) => {
                    return section.rows.map((row) => (
                      <MenuItem value={row.id} key={`rid-${row.id}`}>
                        {row.value}
                      </MenuItem>
                    ));
                  })}
              </Select>
            )}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="model-label">{t("model")}</InputLabel>
          <Controller
            control={control}
            name="model"
            defaultValue={""}
            render={({ field }) => (
              <Select
                variant="standard"
                id="type"
                data-testid="type"
                placeholder={t("model")}
                {...field}
              >
                <MenuItem value={""} key={`rid-model-empty-0`}>
                  {t("NO_MODEL")}
                </MenuItem>
                {filters?.models.map((model) => (
                  <MenuItem value={model} key={model}>
                    {t(`models.${model}`)}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <Controller
          defaultValue={false}
          control={control}
          name="free"
          render={({ field }) => (
            <FormControlLabel
              value="top"
              sx={{
                fontSize: "10px",
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              }
              label={<Typography>{t("free")}</Typography>}
              labelPlacement="start"
            />
          )}
        />
        <Controller
          defaultValue={false}
          control={control}
          name="hasLock"
          render={({ field }) => (
            <FormControlLabel
              value="top"
              sx={{
                fontSize: "10px",
              }}
              control={
                <Checkbox
                  color="primary"
                  checked={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              }
              label={<Typography>{t("lockFilter")}</Typography>}
              labelPlacement="start"
            />
          )}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            height: "35px",
          }}
          color="primary"
        >
          {t("filter")}
        </Button>
      </Box>
    </form>
  );
};

export default AvailabilitiesBeachChairsFilter;
