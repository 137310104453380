import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import { Payment } from "@mui/icons-material";

export const CustomerHeader: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <SubPageHeader headerTitle={t("customers")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {id ? (
          <SBHeaderLink
            label={t("customers")}
            onClick={() => {
              history.push(`/customers`);
            }}
          />
        ) : (
          <SBHeaderTypography label={t("customers")} />
        )}
        {id && (
          <SBHeaderLink
            label={id}
            onClick={() => {
              history.push(`/customers/${id}/show`);
            }}
          />
        )}
        {id && <SBHeaderTypography label={t("overview")} />}
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {!id && (
          <SBLoadingButton
            label={t("newCustomer")}
            type="blue"
            icon={<AddIcon />}
            onClick={() => {
              history.push(`/customers/new`);
            }}
            disabled={location.pathname.includes(`/customers/${id}/bookings`)}
          />
        )}
        {id && (
          <SBLoadingButton
            label={t("bookings")}
            type="blue"
            icon={<Payment />}
            onClick={() => {
              history.push(`/customers/${id}/bookings`);
            }}
            disabled={location.pathname.includes(`/customers/${id}/bookings`)}
          />
        )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};
