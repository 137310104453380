import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getBeachChairCalendarRequest } from "../../../store/reducers/beachChairsReducer";
import {
  getBeachChairCalendarMonthSelector,
  getBeachChairCalendarSelector,
} from "../../../store/selectors/beachChairsSelectors";
import BeachChairListHeader from "../beachChairListHeader/beachChairListHeader";
import BeachChairCalendarDay from "./beachChairCalendarDay";
import {
  boxBeachChairCalendar,
  weekDay,
} from "../../../utils/customStyles/globalStyles";
import { Box } from "@mui/material";

const BeachChairCalendar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const { id } = useParams<{ id: string }>();
  const month = useSelector(getBeachChairCalendarMonthSelector);
  const calendar = useSelector(getBeachChairCalendarSelector(`${id}-${month}`));

  useEffect(() => {
    if (calendar === undefined) {
      dispatch(getBeachChairCalendarRequest({ id: +id }));
    }
    // eslint-disable-next-line
  }, [calendar]);

  return (
    <Box
      sx={{
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        display: "grid",
        gridTemplateRows: "80px auto",
      }}
    >
      <BeachChairListHeader />
      <Box sx={{ ...boxBeachChairCalendar }} border={0}>
        <Box sx={{ ...weekDay }}>{t("week.monday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.tuesday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.wednesday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.thursday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.friday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.saturday")}</Box>
        <Box sx={{ ...weekDay }}>{t("week.sunday")}</Box>
        {calendar &&
          calendar.availability.map((day, index) => (
            <BeachChairCalendarDay
              day={day.date}
              booked={day.beachChairAvailabilityName}
              key={`${day.date}-${index}`}
            />
          ))}
      </Box>
    </Box>
  );
};

export default BeachChairCalendar;
