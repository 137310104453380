import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../../SBTable/SBTable";
import { useHistory } from "react-router";
import { EDestination } from "../model/EDestination";
import ConfigurationHeader from "../header/configurationHeader";
import { useDeleteBeach } from "./hooks/useDeleteBeach";
import { useGetAdminBeaches } from "./hooks/useGetBeaches";
import Status from "../../status/status";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";

const BeachList = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteBeach = useDeleteBeach();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteBeachModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("beach");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetAdminBeaches(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleDeleteBeach = (id: number) => {
    deleteBeach.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "name",
        header: `${t("destinations.name")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/beach/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "shortDescription",
        header: `${t("shortDescription")}`,
        maxSize: 100,
      },
      {
        accessorKey: "active",
        header: `${t("destinations.active")}`,
        maxSize: 60,
        filterVariant: "select",
        filterSelectOptions: [
          { text: `${t(`filters.ACTIVE`)}`, value: "true" },
          { text: `${t(`filters.INACTIVE`)}`, value: "false" },
        ],
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]
                    ? "ACTIVE"
                    : "INACTIVE"
                  }`
                )}`}
              />
            ) : (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue ? "ACTIVE" : "INACTIVE"}`
                )}`}
              />
            )}
          </Box>
        ),
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("destinations.allBeaches")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const beachId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (beachId) {
                  history.push(`/beach/${beachId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (beachId) {
                  history.push(`/beach/${beachId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (beachId) {
                  openDeleteBeachModal(beachId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <ConfigurationHeader type={EDestination.BEACH} />
      <SBTable
        columnVisibilityStoreName="BeachListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="beach"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteBeach(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default BeachList;
