import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { Login } from "@mui/icons-material";
import { useAuthStore } from "../auth/hooks/authStore";

const PrivateRoute: React.FC<RouteProps> = ({ path, exact, component }) => {
  const { isLoggedIn } = useAuthStore();
  return isLoggedIn ? (
    <Route path={path} exact={exact} component={component} />
  ) : (
    <Login />
  );
};
export default PrivateRoute;
