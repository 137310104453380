import { useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useBookingInProgressStore } from "../../store/useBookingZustand";
import { useCreateBooking, useGetBooking } from "../../booking";
import { useAddBeachChairToBooking } from "../../../availabilities/hooks";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelectedBeachChairDates } from "../../store/useSelectedBeachChairDates";
import { useSectionData } from "../../../../utils/customHooks/useSectionData";
import { useLocationData } from "../../../../utils/customHooks/useLocationData";
import dayjs from "dayjs";
import { pricesApi } from "../../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useGetBeachChairsFilters } from "../../../beachChairList/hooks/useGetBeachChairsFilters";
import {
  createDateFromHour,
  selectHour,
} from "../../../../utils/conversions/locationsTimes";
import { useSection } from "./useSection";
import { useLocation } from "./useLocation";
import { TimeZone } from "../../../../utils/conversions/dataConversion";

const DateTimeFormat = "YYYY-MM-DDTHH:mm:ss";

export function vendorBookingForm() {
  const { t } = useTranslation(["common"]);
  const { data: filters, isLoading: filterIsLoading } =
    useGetBeachChairsFilters();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { dateTime, setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  const [isDiscountPresent, setIsDiscountPresent] = useState(false);
  const { bookingInProgress } = useBookingInProgressStore();
  const globalBookingId = useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );
  const { booking } = useGetBooking(Number(globalBookingId));

  const createBooking = useCreateBooking();
  const { addBeachChairToBooking } = useAddBeachChairToBooking();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number().required(),
        secondId: yup.number().nullable(),
        rowId: yup.number().nullable(),
        model: yup.string().nullable(),
        discount: yup.boolean().nullable(),
        price: yup
          .number()
          .moreThan(-1, t("priceError"))
          .typeError(t("priceTypeError")),
      }),
    [t]
  );

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (booking && booking.beachChairBookings) {
      const beachChair = booking.beachChairBookings[0]?.beachChair;
      if (beachChair) {
        setValue("sectionId", beachChair.section.id);
        setValue("rowId", beachChair.row.id);
      }
    }
  }, [setValue, booking]);

  const sectionId = watch("sectionId");
  const rowId = watch("rowId");
  const model = watch("model");

  useEffect(() => {
    if (filters && filters.sections.length === 1) {
      setValue("sectionId", filters.sections[0].id);
    }
    if (filters && filters.sections) {
      const rows = filters?.sections?.filter(
        (section) => section.id === sectionId
      )[0]?.rows;
      if (rows && rows.length === 1) {
        setValue("rowId", rows[0].id);
      }
    }
  }, [filters, sectionId]);

  const { data: sectionData } = useSection(+sectionId);
  const { data: locationData } = useLocation(sectionData?.locationId);

  useEffect(() => {
    if (dateTime[0] && dateTime[1]) {
      const startTimeWeekDay = dateTime[0]?.day() ?? 0;
      const endTimeWeekDay = dateTime[1]?.day() ?? 0;
      const locationStartTime =
        locationData?.details?.openingHours &&
        selectHour(
          locationData.details.openingHours as any,
          startTimeWeekDay,
          true
        );
      const locationEndTime =
        locationData?.checkoutTime ||
        (locationData?.details?.openingHours &&
          selectHour(
            locationData.details.openingHours as any,
            endTimeWeekDay,
            false
          ));
      locationStartTime &&
        setBookingStartDateTime(
          dayjs(
            createDateFromHour(
              locationStartTime,
              new Date(dateTime[0].toISOString())
            )
          )
        );
      locationEndTime &&
        setBookingEndDateTime(
          dayjs(
            createDateFromHour(
              locationEndTime,
              new Date(dateTime[1].toISOString())
            )
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationData]);
  const dayDiff = useMemo(() => {
    return (dateTime[1]?.diff(dateTime[0], "day") ?? 0) + 1;
  }, [dateTime]);
  const { data: calculate } = useQuery(
    ["prices", sectionId, rowId, model, dateTime],
    () =>
      pricesApi
        .pricesControllerCalculatePrice({
          appVersion: "backoffice",
          sectionId,
          rowId,
          model,
          start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
          end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
        })
        .then((res) => res.data),
    {
      enabled:
        !!sectionId &&
        !!rowId &&
        !!model &&
        !!dateTime[0] &&
        !!dateTime[1] &&
        dayDiff > 1,
      retry: false,
      cacheTime: 100000,
      staleTime: 100000,
    }
  );

  const { data: rates } = useQuery(
    ["prices", sectionId, rowId, model, dateTime],
    () =>
      pricesApi
        .pricesControllerGetRates({
          sectionId,
          rowId,
          model,
          start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
          end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
        })
        .then((res) => res.data),
    {
      enabled:
        !!sectionId &&
        !!rowId &&
        !!model &&
        !!dateTime[0] &&
        !!dateTime[1] &&
        dayDiff === 1,
      retry: false,
      cacheTime: 100000,
      staleTime: 100000,
    }
  );

  const prices = useMemo(() => {
    return dayDiff === 1 ? rates : calculate;
  }, [dayDiff, calculate, rates]);

  const watchDiscount = watch("discount");
  useEffect(() => {
    if (isDiscountPresent && watchDiscount) {
      setValue(
        "price",
        prices
          ?.filter((price) => price.discount)
          .sort((a, b) => b.price - a.price)[0].price || 0
      );
    } else {
      if (prices && prices.length > 0) {
        setIsDiscountPresent(prices.some((price) => price.discount) ?? false);
        setValue(
          "price",
          prices?.sort((a, b) => b.price - a.price)[0]?.price || 0
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDiscount, isDiscountPresent, prices]);

  const onSubmit = async ({ id: beachChairId, price }: any) => {
    const beachChairBookingPayload = {
      beachChairId,
      price,
      start: dateTime[0]?.tz(TimeZone)?.utc()?.format() ?? "",
      end: dateTime[1]?.tz(TimeZone)?.utc()?.format() ?? "",
      discount: watchDiscount,
    };

    if (globalBookingId) {
      addBeachChairToBooking(
        {
          bookingId: Number(globalBookingId),
          isExtended: false,
          ...beachChairBookingPayload,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["booking", Number(globalBookingId)]);
            queryClient.removeQueries(["BeachChairBookings"]);
            queryClient.invalidateQueries([
              "beachChairsAvailabilitiesCalendar",
            ]);
            toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));

            history.push(`/bookings/${globalBookingId}/edit`);
          },
        }
      );
    } else {
      createBooking.mutateAsync(undefined, {
        onSuccess: (newBooking) => {
          addBeachChairToBooking(
            {
              bookingId: Number(newBooking.data.id),
              isExtended: false,
              ...beachChairBookingPayload,
            },
            {
              onSuccess: () => {
                toast.success(t(`toastMessages.AddBeachChairToBookingSuccess`));
                queryClient.removeQueries(["BeachChairBookings"]);
                queryClient.invalidateQueries([
                  "beachChairsAvailabilitiesCalendar",
                ]);
                history.push(`/bookings/${newBooking.data.id}/edit`);
              },
            }
          );
        },
      });
    }
  };

  return {
    watch,
    control,
    errors,
    setValue,
    isDiscountPresent,
    filters,
    filterIsLoading,
    onSubmit: handleSubmit(onSubmit),
  };
}
