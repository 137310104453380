import { useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ResponseUserDto } from "../../../api-client/generated";
import { usersApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";

interface UseGetVendorUser {
  vendorUser?: ResponseUserDto;
  isLoading: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<ResponseUserDto, AxiosError>;
}

export const useGetVendorUser = (userId?: string): UseGetVendorUser => {
  const {
    data: vendorUser,
    isLoading,
    isFetched,
    refetch,
  } = useQuery<ResponseUserDto, AxiosError>(
    ["vendorUser", Number(userId)],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return usersApi
        .usersControllerGetUser({ id: userId || "" })
        .then(({ data }) => data);
    },
    {
      enabled: !!userId,
      cacheTime: 0,
    }
  );

  return {
    vendorUser,
    isLoading,
    isFetched,
    refetch,
  };
};
