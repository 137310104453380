import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingDetailsResponseDto,
  FeaturesApiBookingDetailsControllerPayForBookingRequest as Payload,
} from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";

interface UsePayForBooking {
  payForBooking: UseMutateFunction<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >;
  isLoading: boolean;
}

export const usePayForBooking = (): UsePayForBooking => {
  const queryClient = useQueryClient();
  const { clearBookingInProgress } = useBookingInProgressStore();
  const { mutate: payForBooking, isLoading } = useMutation<
    AxiosResponse<BookingDetailsResponseDto>,
    AxiosError,
    Payload
  >({
    mutationFn: (variables: Payload) =>
      featuresApi.bookingDetailsControllerPayForBooking(variables),
    onSuccess: () => {
      queryClient.refetchQueries(["bookingList"]);
      clearBookingInProgress();
    },
  });

  return {
    payForBooking,
    isLoading,
  };
};
