import { format, parseISO } from "date-fns";
import {
  FiskalyBusinessCaseDto,
  FiskalyCashPointTransactionDto,
  FiskalyCreateCashPointClosingDetailResponseDto
} from "../../../api-client/generated";
import { FiskalyBusinessCaseTypeEnum } from "./types";

export type PaymentType = {
  amount: number;
  type: string;
};

export function getStartDate(
  cashPointClosing: FiskalyCreateCashPointClosingDetailResponseDto,
): string {
  return format(
    (cashPointClosing.metadata as any)?.openDate !== undefined
      ? parseISO((cashPointClosing.metadata as any).openDate)
      : cashPointClosing?.cash_point_closing.transactions.length > 0
        ? (cashPointClosing?.cash_point_closing.transactions.sort(
        (a: any, b: any) => a.head.timestamp_start - b.head.timestamp_start,
      )[0].head.timestamp_start || 0) * 1000
        : new Date(),
    'dd.MM.yyyy HH:mm',
  );
}

export function getCashBalanceIncl(
  business_cases: FiskalyBusinessCaseDto[] | undefined,
): number {
  let cashBalanceIncl = 0;
  cashBalanceIncl =
    business_cases &&
    business_cases
      .map((item: any) => item.amounts_per_vat_id)
      .flat()
      .filter((amount: any) => amount.vat_definition_export_id === 1)
      .reduce((sum: number, amount: any) => sum + amount.incl_vat, 0);

  return cashBalanceIncl;
}

export function getCashBalanceExcl(
  business_cases: FiskalyBusinessCaseDto[] | undefined,
): number {
  let cashBalanceExcl = 0;
  cashBalanceExcl =
    business_cases &&
    business_cases
      .map((item: any) => item.amounts_per_vat_id)
      .flat()
      .filter((amount: any) => amount.vat_definition_export_id === 1)
      .reduce((sum: number, amount: any) => sum + amount.excl_vat, 0);

  return cashBalanceExcl;
}

export function getInitialBalance(
  business_cases: FiskalyBusinessCaseDto[] | undefined,
): number {
  let initialBalance = 0;
  initialBalance =
    (business_cases &&
      business_cases.find(
        bc => bc.type === FiskalyBusinessCaseTypeEnum.ANFANGSBESTAND,
      )?.amounts_per_vat_id[0].incl_vat) ||
    0;
  return initialBalance;
}

export function getPaymentTypes(
  transactions: FiskalyCashPointTransactionDto[] | undefined,
): PaymentType[] {
  const paymentTypes: PaymentType[] = [];

  transactions
    ?.filter(
      transaction =>
        !transaction.data.lines ||
        transaction.data.lines[0]?.business_case?.type !== 'Geldtransit',
    )
    .filter(transaction => transaction.head.type === 'Beleg')
    .flatMap(transaction =>  transaction.data.payment_types)
    .forEach(({ type, amount }) => {
      if (type === 'Bar' || type === 'Unbar') {
        if (paymentTypes.find(pt => pt.type === type)) {
          paymentTypes.find(pt => pt.type === type)!.amount += amount;
        } else {
          paymentTypes.push({ type, amount });
        }
      }
    });

  return paymentTypes;
}
