import { Divider } from "@mui/material";
import React from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import { useHistory } from "react-router";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

export const CustomerBookingsHeader: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  return (
    <SubPageHeader
      headerTitle={`${t("customer")} ${t("bookings")} ${t("overview")}`}
    >
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("customers")}
          onClick={() => {
            history.push(`/customers`);
          }}
        />
        <SBHeaderLink
          label={id}
          onClick={() => {
            history.push(`/customers/${id}/show`);
          }}
        />
        <SBHeaderTypography label={t("bookings")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>

      <Divider light sx={{ my: 1 }} />
    </SubPageHeader>
  );
};
