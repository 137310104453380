import { useMutation, UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { CashPointApiCashPointControllerWithdrawCashRequest as Variables } from "../../../api-client/generated";
import { cashPointApi } from "../../../api/apiTanStack";

interface UseCashPointWithdraw {
  withdraw: UseMutateFunction<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useCashPointWithdraw = (): UseCashPointWithdraw => {
  const queryClient = useQueryClient();

  const { mutate: withdraw, isLoading } = useMutation<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >(
    ["cashPointWithdraw"],
    (variables: Variables) => cashPointApi.cashPointControllerWithdrawCash(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['cashPointBalance', Number(variables.cashPointWithdraw.locationId)]);
      }
    }
  );

  return {
    withdraw,
    isLoading
  };
};
