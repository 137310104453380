import React, { useCallback, useState } from "react";
import DeleteDialog from "../dialog/DeleteDialog";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import LockHeader from "./header/lockHeader";
import { useLockForm } from "../../utils/customHooks/useLockForm";
import { getVendorsSelector } from "../../store/selectors/authSelectors";
import { useDeleteLock } from "./hooks/useDeleteLock";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import SBSelect from "../SBComponents/SBForms/SBSelect";
import {
  Battery1Bar,
  CalendarToday,
  FormatColorText,
  ModelTraining,
  Numbers,
  NumbersRounded,
  NumbersTwoTone,
  OpenInFull,
  Password,
  People,
  PrecisionManufacturing,
  StarOutlineSharp,
  Update,
} from "@mui/icons-material";
import SBTextField from "../SBComponents/SBForms/SBTextField";
import SBCheckBox from "../SBComponents/SBForms/SBCheckBox";
import SBButtonActionGrid from "../SBComponents/SBForms/SBButtonActionGrid";
import SBTextAreaDatePicker from "../SBComponents/SBForms/SBTextAreaDatePicker";

const Lock = () => {
  const { t } = useTranslation(["common"]);
  const deleteLock = useDeleteLock();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const { control, onSubmit, onReset, errors, watch } = useLockForm(+id, type);
  const vendorsSelector = useSelector(getVendorsSelector);

  const watchModel = watch("details.model");

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteLock.mutateAsync({ id: id }).finally(() => {
        history.push("/locks");
      });
    },
    [history, deleteLock]
  );

  return (
    <SBMainComponentGridWrapper>
      <LockHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="vendorId"
              label={t("destinations.vendorId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={false}
              additionalOptions={vendorsSelector.flatMap((option) => {
                return { id: option.id, value: `${option.id}: ${option.name}` };
              })}
              defaultValue={0}
              error={errors?.vendorId && t((errors.vendorId as any)?.message)}
            />
            {type === "show" && (
              <SBTextField
                name="id"
                label={t("locks.id")}
                control={control}
                type={type}
                icon={<Numbers />}
                isLoading={false}
                error={errors?.id && t((errors.id as any)?.message)}
              />
            )}
            <SBTextField
              name="number"
              label={t("locks.number")}
              control={control}
              type={type}
              icon={<FormatColorText />}
              isLoading={false}
              error={errors?.number && t((errors.number as any)?.message)}
            />
            <SBTextField
              name="bcd"
              label={t("locks.bcd")}
              control={control}
              type={type}
              icon={<NumbersRounded />}
              isLoading={false}
              error={errors?.bcd && t((errors.bcd as any)?.message)}
            />
            <SBTextField
              name="mac"
              label={t("locks.mac")}
              control={control}
              type={type}
              icon={<NumbersTwoTone />}
              isLoading={false}
              error={errors?.mac && t((errors.mac as any)?.message)}
            />
            <SBSelect
              name="details.model"
              label={t("locks.details.model")}
              control={control}
              type={type}
              icon={<ModelTraining />}
              isLoading={false}
              additionalOptions={[
                { id: "Solar lock V1", value: "Solar lock V1" },
                { id: "Strandbutler lock", value: "Strandbutler lock" },
                { id: "BeachBuddy lock", value: "BeachBuddy lock" },
                { id: "SKJ lock", value: "SKJ lock" },
              ]}
              defaultValue={"Solar lock V1"}
              error={
                errors?.details &&
                (errors.details as any)?.model &&
                t((errors.details as any)?.model?.message)
              }
            />

            <SBSelect
              name="status"
              label={t("status")}
              control={control}
              type={type}
              icon={<StarOutlineSharp />}
              isLoading={false}
              additionalOptions={[
                { id: "ONLINE", value: t("locks.online") },
                { id: "OFFLINE", value: t("locks.offline") },
              ]}
              defaultValue={"ONLINE"}
              error={errors?.status && t((errors.status as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="password"
              label={t("locks.password")}
              control={control}
              type={type}
              icon={<Password />}
              isLoading={false}
              error={errors?.password && t((errors.password as any)?.message)}
            />
            {watchModel === "Solar lock V1" ? (
              <SBTextField
                name="secondaryPassword"
                label={t("locks.secondaryPassword")}
                control={control}
                type={type}
                icon={<Password />}
                isLoading={false}
                error={
                  errors?.secondaryPassword &&
                  t((errors.secondaryPassword as any)?.message)
                }
              />
            ) : null}
            {type === "show" && (
              <SBTextField
                name="details.openCount"
                label={t("locks.details.openCount")}
                control={control}
                type={type}
                icon={<OpenInFull />}
                isLoading={false}
                error={
                  errors?.details &&
                  (errors.details as any)?.openCount &&
                  t((errors.details as any)?.openCount?.message)
                }
              />
            )}
            <SBTextAreaDatePicker
              name="details.buyDate"
              label={t("locks.details.buyDate")}
              control={control}
              type={type}
              icon={<CalendarToday />}
              isLoading={false}
              error={
                errors?.details &&
                (errors.details as any)?.buyDate &&
                t((errors.details as any)?.buyDate?.message)
              }
            />
            <SBTextField
              name="details.manufactor"
              label={t("locks.details.manufactor")}
              control={control}
              type={type}
              icon={<PrecisionManufacturing />}
              isLoading={false}
              error={
                errors?.details &&
                (errors.details as any)?.manufactor &&
                t((errors.details as any)?.manufactor?.message)
              }
            />

            {type === "show" && (
              <SBTextField
                name="batteryLevel"
                label={t("locks.batteryLevel")}
                control={control}
                type={type}
                icon={<Battery1Bar />}
                isLoading={false}
                error={
                  errors?.batteryLevel &&
                  t((errors.batteryLevel as any)?.message)
                }
              />
            )}
            {type === "show" && (
              <SBTextField
                name="updatedAt"
                label={t("locks.updatedAt")}
                control={control}
                type={type}
                icon={<Update />}
                isLoading={false}
                error={
                  errors?.updatedAt && t((errors.updatedAt as any)?.message)
                }
              />
            )}
            {type === "show" && (
              <SBCheckBox
                name="opened"
                label={t("locks.opened")}
                control={control}
                type={type}
                isLoading={false}
                error={errors?.opened && t((errors.opened as any)?.message)}
              />
            )}
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/locks/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/locks/${id}/show`)}
          type={type}
          isLoading={false}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="lock"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Lock;
