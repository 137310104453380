import { Box, FormControl, InputLabel } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBDatePickerStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
} from "../styles/SBStyles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SBCircularProgress from "./SBCircularProgress";
import dayjs from "dayjs";
import { deDE } from "@mui/x-date-pickers/locales";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBTextAreaDatePickerPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  hidden?: boolean;
}

export type SBTextAreaDatePickerType = SBTextAreaDatePickerPrimary &
  SBFormHelperTextPrimary;

const SBTextAreaDatePicker: React.FC<SBTextAreaDatePickerType> = ({
  type,
  icon,
  label,
  isLoading,
  name,
  control,
  hidden,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box
      sx={{
        ...SBTextFieldGridStyle,
        display: hidden ? "none" : "grid",
      }}
    >
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue=""
              render={({ field }) => (
                <LocalizationProvider
                  adapterLocale="de"
                  dateAdapter={AdapterDayjs}
                  localeText={
                    deDE.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <DatePicker
                    format="DD.MM.YYYY"
                    value={dayjs(field.value)}
                    inputRef={field.ref}
                    onChange={(date) => {
                      field.onChange(date);
                    }}
                    disabled={isDisabled}
                    sx={{ ...SBDatePickerStyle }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        sx: { ...SBDatePickerStyle, borderColor: "white" },
                      },
                      inputAdornment: {
                        sx: { display: isDisabled ? "none" : "auto" },
                      },
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBTextAreaDatePicker;
