import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { FieldValues, UseFieldArrayUpdate } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  changeRowRequest,
  deleteRowRequest,
} from "../../../../store/reducers/destinationReducer";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import { inputRootRow } from "../../../../utils/customStyles/globalStyles";

export interface IRowDividerProps {
  name: string;
  id?: number;
  type?: string;
  rowOrderUpdate?: UseFieldArrayUpdate<FieldValues, "rowOrder">;
  sectionId?: number;
}

const RowDivider = ({
  name,
  id,
  type,
  rowOrderUpdate,
  sectionId,
}: IRowDividerProps) => {
  const [text, setText] = React.useState<string>(name);
  const dispatch = useDispatch();

  const hasValidData = !(id && type && rowOrderUpdate);
  const deleteRow = () => {
    id && sectionId && dispatch(deleteRowRequest({ id, sectionId }));
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: "10px" }}>
      <Typography>{id}</Typography>
      <Divider
        sx={{
          background: "black",
          height: 2,
          width: "auto",
        }}
        data-testid="first-divider"
      />
      <Box flexGrow={1}>
        {hasValidData ? (
          <Typography
            sx={{
              fontWeight: "bolder",
            }}
            data-testid="row-name"
          >
            {name}
          </Typography>
        ) : (
          <TextField
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              id &&
                sectionId &&
                dispatch(
                  changeRowRequest({
                    name: e.target.value,
                    id: id,
                    sectionId: sectionId,
                  })
                );
            }}
            variant="standard"
            sx={{ ...inputRootRow }}
            data-testid="geoJson"
            InputProps={{ disableUnderline: type === "show" }}
          />
        )}
      </Box>
      <Divider
        sx={{
          background: "black",
          height: "2px",
          width: "auto",
        }}
        data-testid="second-divider"
      />
      {type === "edit" && (
        <IconButton
          aria-label="filter"
          data-testid="filter-trigger"
          sx={{
            paddingTop: "10px",
            height: "40px",
            color: customStyle.mainColor,
          }}
          onClick={deleteRow}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </Stack>
  );
};

export default RowDivider;
