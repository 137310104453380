import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import EmailIcon from "@mui/icons-material/Email";
import PrintIcon from "@mui/icons-material/Print";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  BookingDetailsResponseDto,
  BookingPaymentMethod,
  BookingStatus,
  ReceiptType,
} from "../../../../api-client/generated";
import { toast } from "react-toastify";
import { useResendPaymentDataMutation } from "../../../../utils/tanstackHooks/useBookingsTanStack";
import { Button } from "../../../common";
import {
  useGetBooking,
  useGetShortInvoice,
  usePayForBooking,
  useResendPaymentLink,
  useGetInvoice,
} from "../hooks";
import { useGetBankTransferDetails } from "../hooks/useGetBankTransferDetails";
import { useGetCurrentUser } from "../../../../hooks";
import { useCallback } from "react";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import { useQueryClient } from "@tanstack/react-query";

const BookingInvoice: React.FC<{
  id: number;
  booking?: BookingDetailsResponseDto;
}> = ({ id, booking }) => {
  const { t } = useTranslation(["common"]);
  const { currentUser } = useGetCurrentUser();
  const { resendPaymentDataMutation } = useResendPaymentDataMutation();
  const { payForBooking } = usePayForBooking();
  const { getBankTransferDetailsPDF } = useGetBankTransferDetails(id);
  const { resendPaymentLink, isLoading: isPaymentLinkSending } =
    useResendPaymentLink();
  const { getShortInvoice } = useGetShortInvoice({ id });
  const queryClient = useQueryClient();
  const { invoice } = useGetInvoice({
    enabled: !!booking,
    payload: {
      bookingId: booking?.id ?? 0,
      invoiceNumber: booking?.invoiceNumber ?? "",
      vendorId: booking?.vendorId ?? 0,
      receiptType: ReceiptType.Invoice,
    },
  });

  const { invoice: cancellationInvoice } = useGetInvoice({
    enabled: !!booking?.cancellationNumber,
    payload: {
      bookingId: booking?.id ?? 0,
      invoiceNumber: booking?.cancellationNumber ?? "",
      vendorId: booking?.vendorId ?? 0,
      receiptType: ReceiptType.Cancellation,
    },
  });

  if (!booking) return null;

  const bookingStatus = booking.status;
  const paymentMethod = booking.paymentMethod;

  const handlePay = () => {
    payForBooking(
      { id: booking.id },
      {
        onSuccess: () => {
          toast.success(t(`toastMessages.ReconfimBookingSuccess`));
          queryClient.invalidateQueries(["booking"]);
        },
        onError: () => {
          toast.error(t(`toastMessages.ReconfimBookingFailure`));
        },
      }
    );
  };

  const onDownloadPrint = useCallback(async () => {
    const { data: pdfFile } = await getShortInvoice();
    if (!pdfFile) return;

    const blobURL = URL.createObjectURL(pdfFile);
    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    iframe.style.display = "none";
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(function () {
        iframe.focus();
        iframe.contentWindow?.print();
      }, 1);
    };
  }, []);

  const downloadBankTransferDetails = useCallback(async () => {
    const { data: pdfFile } = await getBankTransferDetailsPDF();

    if (!pdfFile) return;

    // Force download of the file
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(pdfFile);
    link.download = `strandbutler-${booking.bookingRef}-bank-transfer-details.pdf`;
    link.click();

    // Remove the link from the DOM
    link.remove();
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto auto",
        gridRow: "auto",
        mt: "20px",
        gap: "10px",
      }}
    >
      {bookingStatus === BookingStatus.Pending &&
        currentUser &&
        currentUser.roles[0] === "SUPER_ADMIN" && (
          <SBLoadingButton
            label={t("CONFIRM_BOOKING_MANUALLY")}
            onClick={handlePay}
            icon={<CheckCircleIcon />}
            type="blue"
          />
        )}
      {bookingStatus === BookingStatus.Pending &&
        paymentMethod === BookingPaymentMethod.StripeBankTransfer && (
          <>
            <SBLoadingButton
              label={t("downloadInvoice")}
              icon={<CloudDownloadIcon />}
              type="blue"
              disabled={true}
            />
            <SBLoadingButton
              label={t("printPaymentData")}
              onClick={() => {
                downloadBankTransferDetails();
              }}
              disabled={resendPaymentDataMutation.isLoading}
              icon={<PrintIcon />}
              type="blue"
              isLoading={resendPaymentDataMutation.isLoading}
            />
            <SBLoadingButton
              label={t("shortInvoice")}
              icon={<PrintIcon />}
              type="blue"
              disabled={true}
            />
            <SBLoadingButton
              label={t("resendPaymentData")}
              onClick={() => resendPaymentDataMutation.mutate({ id })}
              disabled={resendPaymentDataMutation.isLoading}
              icon={<EmailIcon />}
              type="blue"
              isLoading={resendPaymentDataMutation.isLoading}
            />
          </>
        )}

      {bookingStatus === BookingStatus.Pending &&
        paymentMethod === BookingPaymentMethod.StripePaymentLink && (
          <SBLoadingButton
            label={t("resendPaymentLink")}
            onClick={() => resendPaymentLink({ id })}
            disabled={isPaymentLinkSending}
            icon={<EmailIcon />}
            type="blue"
            isLoading={isPaymentLinkSending}
          />
        )}

      {bookingStatus === BookingStatus.Pending &&
        (
          [
            BookingPaymentMethod.Cash,
            BookingPaymentMethod.CustomSepa,
            BookingPaymentMethod.CardTerminal,
          ] as Array<Partial<BookingPaymentMethod>>
        ).includes(paymentMethod) && (
          <>
            <SBLoadingButton
              label={t("downloadInvoice")}
              icon={<CloudDownloadIcon />}
              type="blue"
              disabled={true}
            />
            <SBLoadingButton
              label={t("shortInvoice")}
              icon={<PrintIcon />}
              type="blue"
              disabled={true}
            />
            <SBLoadingButton
              label={t("pay")}
              onClick={handlePay}
              icon={<CheckCircleIcon />}
              type="blue"
            />
          </>
        )}

      {bookingStatus === BookingStatus.Confirmed &&
        paymentMethod !== BookingPaymentMethod.StripePaymentLink && (
          <>
            <SBLoadingButton
              label={t("downloadInvoice")}
              icon={<CloudDownloadIcon />}
              type="blue"
              disabled={!invoice}
              href={invoice?.url}
            />
            <SBLoadingButton
              label={t("shortInvoice")}
              onClick={() => {
                onDownloadPrint();
              }}
              icon={<PrintIcon />}
              type="blue"
            />
            <SBLoadingButton
              label={t("reConfirm")}
              onClick={handlePay}
              icon={<CheckCircleIcon />}
              type="blue"
            />
          </>
        )}

      {bookingStatus === BookingStatus.Canceled &&
        paymentMethod !== BookingPaymentMethod.StripePaymentLink && (
          <>
            <SBLoadingButton
              label={t("downloadCancellation")}
              icon={<CloudDownloadIcon />}
              type="blue"
              disabled={!cancellationInvoice}
              href={cancellationInvoice?.url}
            />
            <SBLoadingButton
              label={t("downloadInvoice")}
              icon={<CloudDownloadIcon />}
              type="blue"
              disabled={!invoice}
              href={invoice?.url}
            />
            <SBLoadingButton
              label={t("shortInvoice")}
              icon={<PrintIcon />}
              type="blue"
              disabled={true}
            />
            <SBLoadingButton
              label={t("reConfirm")}
              onClick={handlePay}
              icon={<CheckCircleIcon />}
              type="blue"
            />
          </>
        )}

      {bookingStatus === BookingStatus.Reserved && (
        <Button startIcon={<CheckCircleIcon />} onClick={handlePay}>
          {t("pay")}
        </Button>
      )}
    </Box>
  );
};

export default BookingInvoice;
