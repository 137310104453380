import { useMutation, useQueryClient } from "@tanstack/react-query";
import { beachChairApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CreateBeachChairBodyDto } from "../../../api-client/generated";

export const useDuplicateBeachChairMutation = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["DuplicateBeachChairMutation"],
    (data: { createBeachChairBodyDto: CreateBeachChairBodyDto }) =>
      beachChairApi.beachChairsControllerCreate({
        createBeachChairBodyDto: data.createBeachChairBodyDto,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["beachChairList"]);
        toast.success(t(`toastMessages.PutBeachChairSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.PutBeachChairFailure`));
      },
    }
  );
};
