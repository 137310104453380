import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { setClosingReportPickupCurrentDate } from "../../../store/reducers/reportsReducer";
import { getClosingReportPickupCurrentDateSelector } from "../../../store/selectors/reportSelectors";
import { useUpdateClosingReports } from "./hooks/useCreateClosingReport";
import { Button } from "@mui/material";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import {
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";
import dayjs, { Dayjs } from "dayjs";
import { deDE } from "@mui/x-date-pickers/locales";

const ClosingReportHeader = () => {
  const { t } = useTranslation(["common"]);
  const pickup = useSelector(getClosingReportPickupCurrentDateSelector);
  const [pickupDate, setPickupDate] = useState<Dayjs>(dayjs(new Date(pickup)));
  const updateClosingReports = useUpdateClosingReports();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <SubPageHeader headerTitle={t("reports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderLink
          label={t("reports")}
          onClick={() => {
            history.push(`/reports`);
          }}
        />
        <SBHeaderTypography label={t("closingReports")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DatePicker
            format="DD.MM.YYYY"
            onChange={(newValue: Date | null) => {
              if (newValue) {
                setPickupDate(dayjs(new Date(newValue)));
                dispatch(
                  setClosingReportPickupCurrentDate({
                    date: dayjs(new Date(newValue))?.toISOString(),
                  })
                );
              }
            }}
            value={pickupDate as any}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
              },
            }}
          />
        </LocalizationProvider>
        <Button
          variant="contained"
          sx={{
            gridColumn: 2,
            gridRow: 1,
            height: "35px",
            fontSize: "12px",
            color: "white",
            backgroundColor: customStyle.mainColor,
          }}
          data-testid="date-button"
          onClick={() => {
            updateClosingReports.mutateAsync({
              startDate: pickup,
              endDate: pickup,
              reportType: "CASH_BALANCE",
            });
          }}
        >
          {t("genereateReport")}
        </Button>
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default ClosingReportHeader;
