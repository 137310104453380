import {
  destinationToRow,
  destinationToSectionRow,
} from "../../utils/conversions/destinationsToRow";
import { createSelector } from "reselect";
import { IFilterData } from "../../models/dataTable/IFilterData";
import {
  getAllDestinationBeaches,
  getAllDestinationCities,
  getAllDestinationRegions,
  getDestinationBeachById,
  getDestinationBeachPages,
  getDestinationCityById,
  getDestinationCityPages,
  getDestinationRegionById,
  getDestinationRegionPages,
  getDestinationSectionById,
  getDestinationSectionPages,
} from "../reducers/destinationReducer";
import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;
const getPending = (state: AppState) => state.destination.pending;
const getError = (state: AppState) => state.destination.error;

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

/**
 * Regions Selectors
 */

const getDestinationRegionsFilterData = (state: AppState) =>
  state.destination.filterDestinationRegionsData;

const getDestinationRegionsCount = (state: AppState) =>
  state.destination.destinationRegionsCount;

export const getDestinationsRegionsFilterDataSelector = createSelector(
  getDestinationRegionsFilterData,
  (filterData) => filterData
);

export const getDestinationRegionsCountSelector = createSelector(
  getDestinationRegionsCount,
  (count) => count
);

export const getDestinationsRegionsPageSelector = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getDestinationRegionPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds
        .map((id) => getDestinationRegionById(state, id)!)
        .map(destinationToRow)
    );
  });

export const getDestinationsRegionSelector = (id: number) =>
  createSelector(getState, (state) => getDestinationRegionById(state, id));

export const getDestinationsRegionsSelector = createSelector(
  getState,
  (state) => getAllDestinationRegions(state)
);

/**
 * Cities
 */
const getDestinationCitiesFilterData = (state: AppState) =>
  state.destination.filterDestinationCitiesData;

const getDestinationCitiesCount = (state: AppState) =>
  state.destination.destinationCitiesCount;

export const getDestinationsCitiesFilterDataSelector = createSelector(
  getDestinationCitiesFilterData,
  (filterData) => filterData
);

export const getDestinationCitiesCountSelector = createSelector(
  getDestinationCitiesCount,
  (count) => count
);

export const getDestinationsCitiesPageSelector = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getDestinationCityPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds
        .map((id) => getDestinationCityById(state, id)!)
        .map(destinationToRow)
    );
  });

export const getDestinationsCitySelector = (id: number) =>
  createSelector(getState, (state) => getDestinationCityById(state, id));

export const getDestinationsCitiesSelector = createSelector(getState, (state) =>
  getAllDestinationCities(state)
);
/**
 * Beaches
 */
const getDestinationBeachesFilterData = (state: AppState) =>
  state.destination.filterDestinationBeachesData;

const getDestinationBeachesCount = (state: AppState) =>
  state.destination.destinationBeachesCount;

export const getDestinationsBeachesFilterDataSelector = createSelector(
  getDestinationBeachesFilterData,
  (filterData) => filterData
);

export const getDestinationBeachesCountSelector = createSelector(
  getDestinationBeachesCount,
  (count) => count
);
export const getAllDestinationBeachesSelector = createSelector(
  getAllDestinationBeaches,
  (beaches) => beaches
);

export const getDestinationsBeachesPageSelector = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getDestinationBeachPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds
        .map((id) => getDestinationBeachById(state, id)!)
        .map(destinationToRow)
    );
  });

export const getDestinationsBeachSelector = (id: number) =>
  createSelector(getState, (state) => getDestinationBeachById(state, id));

export const getDestinationsBeachesSelector = createSelector(
  getState,
  (state) => getAllDestinationBeaches(state)
);
/**
 * Sections
 */
const getDestinationSectionsFilterData = (state: AppState) =>
  state.destination.filterDestinationSectionsData;

const getDestinationSectionsCount = (state: AppState) =>
  state.destination.destinationSectionsCount;

export const getDestinationsSectionsFilterDataSelector = createSelector(
  getDestinationSectionsFilterData,
  (filterData) => filterData
);

export const getDestinationSectionsCountSelector = createSelector(
  getDestinationSectionsCount,
  (count) => count
);

export const getDestinationsSectionsPageSelector = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getDestinationSectionPages(
      state,
      `${pageNumber}:${itemsPerPage}`
    );
    return (
      page &&
      page!.dataIds
        .map((id) => getDestinationSectionById(state, id)!)
        .map(destinationToSectionRow)
    );
  });

export const getDestinationsSectionSelector = (id: number) =>
  createSelector(getState, (state) => getDestinationSectionById(state, id));

const getImagesOrder = (state: AppState) => state.destination.imagesOrder;

export const getImagesOrderSelector = createSelector(
  getImagesOrder,
  (imagesOrder) => imagesOrder
);
