import React, { useCallback, useState } from "react";
import ConfigurationHeader from "../header/configurationHeader";
import { EDestination } from "../model/EDestination";
import DeleteDialog from "../../dialog/DeleteDialog";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useBeachForm } from "../../../utils/customHooks/useBeachForm";
import { useDeleteBeach } from "./hooks/useDeleteBeach";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import { Description, LocationCity, TextDecrease } from "@mui/icons-material";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SBImageUploader from "../../SBComponents/SBForms/SBImageUploader";

const Beach = () => {
  const { t } = useTranslation(["common"]);
  const deleteBeach = useDeleteBeach();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const {
    control,
    regionsSelector,
    citiesSelector,
    fields,
    remove,
    append,
    onSubmit,
    onReset,
    errors,
  } = useBeachForm(+id, type);

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteBeach.mutateAsync({ id: id }).finally(() => {
        history.push("/beach");
      });
    },
    [history, deleteBeach]
  );

  return (
    <SBMainComponentGridWrapper>
      <ConfigurationHeader type={EDestination.BEACH} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="regionId"
              label={t("destinations.regionId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<LocationCity />}
              isLoading={false}
              additionalOptions={regionsSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.regionId && t((errors.regionId as any)?.message)}
            />
            <SBSelect
              name="cityId"
              label={t("destinations.cityId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<LocationCity />}
              isLoading={false}
              additionalOptions={citiesSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.cityId && t((errors.cityId as any)?.message)}
            />
            <SBTextField
              name="name"
              label={t("destinations.name")}
              control={control}
              type={type}
              icon={<TextDecrease />}
              isLoading={false}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBTextArea
              name="shortDescription"
              label={t("destinations.shortDescription")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.shortDescription &&
                t((errors.shortDescription as any)?.message)
              }
            />
            <SBTextArea
              name="description"
              label={t("destinations.description")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBTextArea
              name="geoJson"
              label={t("destinations.geoJson")}
              control={control}
              type={type}
              icon={<LocationOnIcon />}
              isLoading={false}
              error={errors?.geoJson && t((errors.geoJson as any)?.message)}
            />

            <SBCheckBox
              name="active"
              label={t("destinations.active")}
              control={control}
              type={type}
              isLoading={false}
              error={errors?.active && t((errors.active as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBImageUploader
              label={t("destinations.images")}
              imagesFields={fields}
              imagesAppend={append}
              imagesRemove={remove}
              type={type}
            />
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/beach/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/beach/${id}/show`)}
          type={type}
          isLoading={false}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="beach"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Beach;
