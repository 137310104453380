import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import AddIcon from "@mui/icons-material/Add";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";

const LockHeader = () => {
  const { t } = useTranslation(["common"]);
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  return (
    <SubPageHeader headerTitle={t("adminConfiguration")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {!id && location.pathname.includes("/locks") ? (
          <SBHeaderTypography label={t("configurationMenu.locks")} />
        ) : (
          <SBHeaderLink
            label={t(`configurationMenu.locks`)}
            onClick={() => {
              history.push(`/locks`);
            }}
          />
        )}
        {id && location.pathname.includes("/locks") && (
          <SBHeaderTypography label={t("overview")} />
        )}
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        {location.pathname.includes(`/locks`) &&
          !location.pathname.includes("destination") && (
            <SBLoadingButton
              label={t("locks.new")}
              type="blue"
              icon={<AddIcon />}
              onClick={() => {
                history.push(`/locks/new`);
              }}
              disabled={location.pathname.includes(`/locks/new`)}
            />
          )}
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default LockHeader;
