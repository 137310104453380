import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Control, FieldValues } from "react-hook-form";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBSelectGridStyle,
  SBSelectStyle,
  SBTextFieldIconStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import { useState } from "react";
import { BookingPaymentMethod } from "../../../api-client/generated";
import { Props } from "../../../utils/types/Props";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBSelectPrimary {
  name: string;
  label?: React.ReactNode;
  noOptionLabel?: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  handleChange: (event: SelectChangeEvent) => void;
  filterAdditionalOptionsBy?: number;
  defaultValue?: string | number;
  option?: string;
}

export type SBSelectType = SBSelectPrimary & SBFormHelperTextPrimary;

const SBSelectListSubHeader: React.FC<SBSelectType & Props> = ({
  label,
  noOptionLabel,
  isLoading,
  type,
  icon,
  handleChange,
  defaultValue,
  option,
  children,
  error,
}) => {
  const [value, setValue] = useState<string | undefined>(option);
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBSelectGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Select
              value={value}
              fullWidth
              variant="filled"
              disabled={isDisabled}
              label=""
              sx={{
                ...SBSelectStyle,
                "& .MuiSelect-icon": {
                  display: isDisabled ? "none" : "auto",
                },
              }}
              onChange={(e) => {
                setValue(e.target.value as BookingPaymentMethod);
                handleChange && handleChange(e);
              }}
              input={<OutlinedInput sx={{ ...SBSelectStyle }} />}
            >
              {noOptionLabel && (
                <MenuItem value={defaultValue} key={`optionId-empty-0`}>
                  {noOptionLabel}
                </MenuItem>
              )}
              {children}
            </Select>
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBSelectListSubHeader;
