import {
  Accessibility,
  Accessible,
  BeachAccess,
  Checkroom,
  Delete,
  Description,
  FoodBank,
  Icecream,
  ImportContacts,
  Kayaking,
  LocalDrink,
  LocalParking,
  LocationCity,
  LocationOn,
  PedalBike,
  People,
  PetsSharp,
  Shower,
  TagFaces,
  TextureRounded,
  Toys,
  Wc,
  Wifi,
} from "@mui/icons-material";
import {
  Box,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { ReactSortable } from "react-sortablejs";
import { useSectionForm } from "../../../utils/customHooks/useSectionForm";
import {
  cardRoot,
  cardRowRoot,
  checkBoxRootLabel,
  draggableCardRow,
  iconButtonDelete,
  iconButtonDeleteColor,
  iconItemMainChecked,
  iconItemMainDisabled,
  imageLabel,
  imagesGrid,
  noneDraggableCardRow,
  rowContentAttributes,
} from "../../../utils/customStyles/globalStyles";
import DeleteDialog from "../../dialog/DeleteDialog";
import FileUploader from "../../fileUploader/fileUploader";
import ImageUploader from "../../SBComponents/SBForms/SBImageUpload";
import ConfigurationHeader from "../header/configurationHeader";
import { EDestination } from "../model/EDestination";
import Row from "./row/row";
import RowOrder from "./rowOrder/rowOrder";
import { useDeleteSection } from "./hooks/useDeleteSection";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import { SBLabelStyle } from "../../SBComponents/styles/SBStyles";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";

const Section = () => {
  const { t } = useTranslation(["common"]);
  const deleteSection = useDeleteSection();
  const [dialog, setDialog] = useState<boolean>(false);
  const [sortable, setSortable] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const {
    control,
    beachesSelector,
    locationSelector,
    vendorsSelector,
    imagesFields,
    imagesAppend,
    imagesRemove,
    rowOrderFields,
    rowOrderUpdate,
    setValue,
    onSubmit,
    onReset,
    errors,
  } = useSectionForm(+id, type);

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteSection.mutateAsync({ id: id }).finally(() => {
        history.push("/section");
      });
    },
    [history, deleteSection]
  );

  const render = useCallback(
    () =>
      imagesFields.map((item) => (
        <Card
          key={(item as any).id}
          sx={{
            ...cardRowRoot,
            ...(type !== "show" ? draggableCardRow : noneDraggableCardRow),
          }}
          draggable={type !== "show"}
        >
          <img
            className="imageSquare"
            alt={`${(item as any).value}`}
            src={`${(item as any).value}`}
          />
        </Card>
      )),
    // eslint-disable-next-line
    [imagesFields, type]
  );

  return (
    <SBMainComponentGridWrapper>
      <ConfigurationHeader type={EDestination.SECTION} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="vendorId"
              label={t("destinations.vendorId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<People />}
              isLoading={false}
              additionalOptions={vendorsSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.vendorId && t((errors.vendorId as any)?.message)}
            />
            <SBSelect
              name="beachId"
              label={t("destinations.beachId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<BeachAccess />}
              isLoading={false}
              additionalOptions={beachesSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={errors?.beachId && t((errors.beachId as any)?.message)}
            />
            <SBSelect
              name="locationId"
              label={t("destinations.locationId")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<LocationCity />}
              isLoading={false}
              additionalOptions={locationSelector.flatMap((option) => {
                return { id: option.id, value: option.name };
              })}
              defaultValue={0}
              error={
                errors?.locationId && t((errors.locationId as any)?.message)
              }
            />
            <SBTextField
              name="name"
              label={t("destinations.name")}
              control={control}
              type={type}
              icon={<TextureRounded />}
              isLoading={false}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBTextArea
              name="description"
              label={t("destinations.description")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBTextArea
              name="seoDescription"
              label={t("destinations.seoDescription")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.shortDescription &&
                t((errors.shortDescription as any)?.message)
              }
            />
            <SBTextArea
              name="seoShortDescription"
              label={t("destinations.seoShortDescription")}
              control={control}
              type={type}
              icon={<Description />}
              isLoading={false}
              error={
                errors?.seoShortDescription &&
                t((errors.seoShortDescription as any)?.message)
              }
            />
            <SBTextArea
              name="geoJson"
              label={t("destinations.geoJson")}
              control={control}
              type={type}
              icon={<LocationOn />}
              isLoading={false}
              error={errors?.geoJson && t((errors.geoJson as any)?.message)}
            />
            <SBCheckBox
              name="active"
              label={t("destinations.active")}
              control={control}
              type={type}
              isLoading={false}
              error={errors?.active && t((errors.active as any)?.message)}
            />

            {type === "edit" && (
              <FormControlLabel
                control={
                  <Switch
                    value={sortable}
                    onChange={() => {
                      setSortable((prev) => !prev);
                    }}
                  />
                }
                label={
                  <Typography sx={{ ...SBLabelStyle }}>
                    {t("destinations.sort")}
                  </Typography>
                }
              />
            )}
            {!sortable && imagesFields.length > 0 && (
              <InputLabel id="images" sx={{ ...imageLabel }}>
                {t("destinations.images")}
              </InputLabel>
            )}
            {!sortable && (
              <Box sx={{ ...imagesGrid }}>
                {imagesFields.map((item, index) => (
                  <Card key={item.id} sx={{ ...cardRoot }}>
                    <img
                      className="imageSquare"
                      alt={`${(item as any).value}`}
                      src={`${(item as any).value}`}
                    />
                    <IconButton
                      sx={{ ...iconButtonDeleteColor }}
                      aria-label="delete"
                      onClick={() => imagesRemove(index)}
                      disabled={type === "show"}
                    >
                      <Delete sx={{ ...iconButtonDelete }} />
                    </IconButton>
                  </Card>
                ))}
              </Box>
            )}
            {type === "edit" && sortable && imagesFields.length > 0 && (
              <>
                <InputLabel id="images" sx={{ ...imageLabel }}>
                  {t("destinations.imagesSort")}
                </InputLabel>
                <ReactSortable
                  list={imagesFields}
                  setList={(list) => {
                    setValue("images", list);
                  }}
                  style={{
                    display: "grid",
                    paddingBottom: "10px",
                    gap: "10px",
                    gridTemplateColumns:
                      "repeat(auto-fit, minmax(200px, 200px));",
                  }}
                >
                  {render()}
                </ReactSortable>
              </>
            )}
            {type === "edit" && (
              <>
                <InputLabel id="row" sx={{ ...imageLabel }}>
                  {t("destinations.row")}
                </InputLabel>
                <Row sectionId={+id} type={type} />
              </>
            )}
            {rowOrderFields.length > 0 && (
              <Box>
                <InputLabel id="rowOrder" sx={{ ...imageLabel }}>
                  {t("destinations.rowOrder")}
                </InputLabel>
                <RowOrder
                  rowOrderFields={rowOrderFields}
                  type={type}
                  setValue={setValue}
                  rowOrderUpdate={rowOrderUpdate}
                  sectionId={+id}
                />
              </Box>
            )}
            <Box sx={{ ...rowContentAttributes }}>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.wc"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      color="primary"
                      icon={<Wc sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Wc sx={{ ...iconItemMainChecked }} />}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.wc")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.ice"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Icecream sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Icecream sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.ice")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.dogs"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<PetsSharp sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <PetsSharp sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.dogs")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.food"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<FoodBank sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<FoodBank sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.food")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.nude"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Accessibility sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <Accessibility sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.nude")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.wlan"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Wifi sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Wifi sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.wlan")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.drinks"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<LocalDrink sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <LocalDrink sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.drinks")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.showers"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Shower sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Shower sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.showers")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.pedalBoat"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Kayaking sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Kayaking sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.pedalBoat")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.carparking"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<LocalParking sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <LocalParking sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.carparking")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.playground"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<TagFaces sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<TagFaces sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.playground")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.rentingToy"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Toys sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={<Toys sx={{ ...iconItemMainChecked }} />}
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.rentingToy")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.wheelChair"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Accessible sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <Accessible sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.wheelChair")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.freeMagazin"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<ImportContacts sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <ImportContacts sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.freeMagazin")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.rentalbikes"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<PedalBike sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <PedalBike sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.rentalbikes")}
                </InputLabel>
              </Grid>
              <Grid container>
                <Controller
                  defaultValue={false}
                  control={control}
                  name="attributes.dressingRoom"
                  render={({ field }) => (
                    <Checkbox
                      disabled={type === "show"}
                      icon={<Checkroom sx={{ ...iconItemMainDisabled }} />}
                      checkedIcon={
                        <Checkroom sx={{ ...iconItemMainChecked }} />
                      }
                      color="primary"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  )}
                />
                <InputLabel
                  disabled={type === "show"}
                  id="active"
                  sx={{ ...checkBoxRootLabel }}
                >
                  {t("attributes.dressingRoom")}
                </InputLabel>
              </Grid>
            </Box>
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <Box height="400px">
              <InputLabel sx={{ ...SBLabelStyle }}>
                {t("destinations.images")}
              </InputLabel>
              <ImageUploader
                disabled={type === "show"}
                append={imagesAppend}
                width="400px"
              />
            </Box>
            <Box height="400px" marginTop="40px">
              <InputLabel sx={{ ...SBLabelStyle }}>
                {t("destinations.beachPlan")}
              </InputLabel>
              <FileUploader
                buttonTitle={t("destinations.uploadBeachPlan")}
                acceptedFileTypes=".pdf,.png,.jpg,.jpeg,.gif,.bmp,.svg"
                disabled={type === "show"}
                setValue={setValue}
                setValueName="beachPlan"
                width="400px"
              />
            </Box>
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/section/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/section/${id}/show`)}
          type={type}
          isLoading={false}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="section"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Section;
