import { useTranslation } from "react-i18next";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  SBDatePickerHeightStyle,
  SBDatePickerStyle,
} from "../../SBComponents/styles/SBStyles";
import { useInstantBookingCurrentDatesStore } from "./hooks/useAvailabilitiesDatesStore";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import { useHistory } from "react-router";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import { deDE } from "@mui/x-date-pickers/locales";
import { useSelectedBeachChairDates } from "../store/useSelectedBeachChairDates";

const InstantBookingsHeader = () => {
  const { t } = useTranslation(["common"]);
  const { instantBookingCurrentDate, setInstantBookingCurrentDate } =
    useInstantBookingCurrentDatesStore();
  const history = useHistory();
  const { setBookingEndDateTime, setBookingStartDateTime } =
    useSelectedBeachChairDates();
  return (
    <SubPageHeader
      headerTitle={t("bookingsMenu.instantBooking") + " - " + t("overview")}
    >
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderTypography label={t("bookingsMenu.instantBooking")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <LocalizationProvider
          adapterLocale="de"
          dateAdapter={AdapterDayjs}
          localeText={
            deDE.components.MuiLocalizationProvider.defaultProps.localeText
          }
        >
          <DatePicker
            format="DD.MM.YYYY"
            onChange={(newValue: Date | null) => {
              setInstantBookingCurrentDate(dayjs(newValue));
              let start = dayjs(dayjs(newValue))
                .set("hours", 8)
                .set("minutes", 0);
              let end = dayjs(dayjs(newValue))
                .set("hours", 22)
                .set("minutes", 0);
              if (dayjs().isAfter(start)) {
                start = dayjs(dayjs(newValue));
              }
              if (dayjs().isAfter(end)) {
                start = dayjs(dayjs(newValue))
                  .add(1, "day")
                  .set("hours", 8)
                  .set("minutes", 0);
                end = dayjs(dayjs(newValue))
                  .add(1, "day")
                  .set("hours", 22)
                  .set("minutes", 0);
              }
              setBookingStartDateTime(start, end);
            }}
            value={instantBookingCurrentDate as any}
            sx={{ ...SBDatePickerStyle, ...SBDatePickerHeightStyle }}
            slotProps={{
              textField: {
                variant: "outlined",
              },
            }}
          />
        </LocalizationProvider>
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default InstantBookingsHeader;
