import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { CustomerForm, CustomerHeader } from "../components";
import { useGetCustomer } from "../hooks";
import { useParams } from "react-router";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";

type ViewType = "edit" | "show";

export const CustomerEdit: React.FC = () => {
  const { id, type } = useParams<{
    id: string;
    type: ViewType;
  }>();

  const { customer, isLoading } = useGetCustomer(id);

  return (
    <SBMainComponentGridWrapper>
      <CustomerHeader />
      {isLoading ? (
        <CircularProgress />
      ) : (
        <CustomerForm initialValues={customer} type={type} />
      )}
    </SBMainComponentGridWrapper>
  );
};
