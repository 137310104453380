import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { FiskalyCashPointDetailResponseDto as Response } from "../../../api-client/generated";
import { cashPointApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";

interface UseGetClosingReport {
  closingReport?: Response;
  isLoading: boolean;
  isFetching: boolean;
  isFetched: boolean;
  refetch: ReactQueryRefetch<Response, AxiosError>;
}

export const useGetClosingReport = ({
  locationId,
  closingId,
}: {
  locationId?: string;
  closingId?: string;
}): UseGetClosingReport => {
  const { data: closingReport, isLoading, isFetching, isFetched, refetch } = useQuery<
    Response,
    AxiosError
  >(
    ['current-cashclosing', Number(locationId), closingId],
    () =>
      cashPointApi
        .cashPointControllerGetCashPointClosing({
          locationId: Number(locationId),
          closingId: closingId ?? '',
        })
        .then(({ data }) => data),
    {
      enabled: locationId !== undefined && closingId !== undefined,
      cacheTime: Infinity,
      staleTime: Infinity,
      retry: 2,
    },
  );

  return {
    closingReport,
    isLoading,
    isFetching,
    isFetched,
    refetch
  };
};
