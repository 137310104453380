import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBTimeFieldPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
}

export type SBTimeFieldType = SBTimeFieldPrimary & SBFormHelperTextPrimary;

const SBTimeField: React.FC<SBTimeFieldType> = ({
  type,
  icon,
  label,
  isLoading,
  name,
  control,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type="time"
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  label=""
                  sx={{ ...SBTextFieldStyle }}
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBTimeField;
