import { IShortUrl } from "../../models/shortUrl/IShortUrl";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "./rootReducer";
import {
  DeleteShortUrlRequestPayload,
  DeleteShortUrlSuccessPayload,
  GetShortUrlRequestPayload,
  GetShortUrlsRequestPayload,
  GetShortUrlsSuccessPayload,
  GetShortUrlSuccessPayload,
  ShortUrlsFailurePayload,
  PostShortUrlRequestPayload,
  PostShortUrlSuccessPayload,
  PutShortUrlRequestPayload,
  PutShortUrlSuccessPayload,
  FilterDataShortURLChangeRequestPayload,
} from "../actionPayload/shortUrlsPayloade";
import { IFilterData } from "../../models/dataTable/IFilterData";
import { IFilter } from "../../models/dataTable/IFilter";

interface IPage {
  key: string;
  dataIds: string[];
}

export interface IFiltersShortURL {
  filter: IFilter;
}

const shortUrlsAdapter = createEntityAdapter<IShortUrl>({
  selectId: (shortUrl) => shortUrl.uid,
});

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

interface IShortUrlState {
  pending: boolean;
  error: string;
  shortUrls: EntityState<IShortUrl>;
  count: number;
  page: EntityState<IPage>;
  filterShortURLData: IFilterData[];
}

const INITIAL_STATE: IShortUrlState = {
  pending: false,
  error: "",
  count: 0,
  shortUrls: shortUrlsAdapter.getInitialState({}),
  page: pageAdapter.getInitialState({}),
  filterShortURLData: [],
};

const shortUrlSlice = createSlice({
  name: "shortUrls",
  initialState: INITIAL_STATE,
  reducers: {
    getShortUrlsRequest(
      state,
      action: PayloadAction<GetShortUrlsRequestPayload>
    ) {
      state.pending = true;
    },
    getShortUrlsSuccess(
      state,
      action: PayloadAction<GetShortUrlsSuccessPayload>
    ) {
      state.pending = false;
      shortUrlsAdapter.removeAll(state.shortUrls);
      pageAdapter.removeAll(state.page);
      shortUrlsAdapter.upsertMany(state.shortUrls, action.payload.shortUrls);
      pageAdapter.upsertOne(state.page, {
        key: `${action.payload.page}:${action.payload.itemsPerPage}`,
        dataIds: [...action.payload.shortUrls.map((shortUrl) => shortUrl.uid)],
      });
      state.count = action.payload.count;
    },
    changeShortUrlsDataRequest(state) {
      state.pending = true;
      pageAdapter.removeAll(state.page);
    },
    getShortUrlRequest(
      state,
      action: PayloadAction<GetShortUrlRequestPayload>
    ) {
      state.pending = true;
    },
    getShortUrlSuccess(
      state,
      action: PayloadAction<GetShortUrlSuccessPayload>
    ) {
      state.pending = false;
      shortUrlsAdapter.upsertOne(state.shortUrls, action.payload.shortUrl);
    },
    postShortUrlRequest(
      state,
      action: PayloadAction<PostShortUrlRequestPayload>
    ) {
      state.pending = true;
    },
    postShortUrlSuccess(
      state,
      action: PayloadAction<PostShortUrlSuccessPayload>
    ) {
      state.pending = false;
      pageAdapter.removeAll(state.page);
      shortUrlsAdapter.upsertOne(state.shortUrls, action.payload.shortUrl);
    },
    putShortUrlRequest(
      state,
      action: PayloadAction<PutShortUrlRequestPayload>
    ) {
      state.pending = true;
    },
    putShortUrlSuccess(
      state,
      action: PayloadAction<PutShortUrlSuccessPayload>
    ) {
      state.pending = false;
      pageAdapter.removeAll(state.page);
      shortUrlsAdapter.updateOne(state.shortUrls, {
        id: action.payload.shortUrl.uid,
        changes: action.payload.shortUrl,
      });
    },
    deleteShortUrlRequest(
      state,
      action: PayloadAction<DeleteShortUrlRequestPayload>
    ) {
      state.pending = true;
    },
    deleteShortUrlSuccess(
      state,
      action: PayloadAction<DeleteShortUrlSuccessPayload>
    ) {
      state.pending = false;
      pageAdapter.removeAll(state.page);
    },
    filterDataShortURLChangeRequest(
      state,
      action: PayloadAction<FilterDataShortURLChangeRequestPayload>
    ) {
      state.filterShortURLData = state.filterShortURLData.map(
        (obj) => action.payload.change.find((o) => o.name === obj.name) || obj
      );
    },
    shortUrlsFailure(state, action: PayloadAction<ShortUrlsFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: selectShortUrls,
  selectById: selectShortUrlById,
} = shortUrlsAdapter.getSelectors<AppState>(
  (state) => state.shortUrls.shortUrls
);

export const {
  selectAll: getShortUrlsPages,
  selectById: getShortUrlPages,
} = pageAdapter.getSelectors<AppState>((state) => state.shortUrls.page);

export const {
  getShortUrlsRequest,
  getShortUrlsSuccess,
  changeShortUrlsDataRequest,
  getShortUrlRequest,
  getShortUrlSuccess,
  postShortUrlRequest,
  postShortUrlSuccess,
  putShortUrlRequest,
  putShortUrlSuccess,
  deleteShortUrlRequest,
  deleteShortUrlSuccess,
  filterDataShortURLChangeRequest,
  shortUrlsFailure,
} = shortUrlSlice.actions;

export default shortUrlSlice.reducer;
