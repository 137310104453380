import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { ResponseUserDto, CreateUserBodyDto } from "../../../api-client/generated";
import { usersApi } from "../../../api/apiTanStack";
import { ApiError } from "../../../types";

export type CreateUserPayload = CreateUserBodyDto;

interface CreateVendorUser {
  createVendorUser: UseMutateFunction<
    AxiosResponse<ResponseUserDto>,
    ApiError,
    CreateUserPayload
  >;
  isLoading: boolean;
}

export const useCreateVendorUser = (): CreateVendorUser => {
  const { mutate: createVendorUser, isLoading } = useMutation<
    AxiosResponse<ResponseUserDto>,
    ApiError,
    CreateUserPayload
  >(
    ["createVendorUser"],
    (variables: CreateUserPayload = {} as CreateUserPayload) =>
    usersApi.usersControllerCreate({ createUserBodyDto: variables })
  );

  return {
    createVendorUser,
    isLoading,
  };
};
