
export const batteryLevelMap = (volt:number):string => {
    let percent:string = '0';
  
    const voltToPercentage = {
      "4200": 100,
      "4080": "> 90",
      "4000": "> 80",
      "3930": "> 70",
      "3870": "> 60",
      "3820": "> 50",
      "3790": "> 40",
      "3770": "> 30",
      "3730": "> 20",
      "3700": "> 15",
      "3680": "> 10",
      "3500": "> 5",
      "2500": "< 5"
    }
    
    type ObjectKey = keyof typeof voltToPercentage;
  
    Object.keys(voltToPercentage).sort().forEach((voltage) => {
      const key = voltage as ObjectKey;
      if (parseInt(key) <= volt) {
        percent = voltToPercentage[key].toString();
      } else {
        return percent
      }
    })
    return percent;
  }