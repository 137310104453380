import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import { useHistory } from "react-router";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";

const ReportsHeader = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();

  return (
    <SubPageHeader headerTitle={t("reports")}>
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderTypography label={t("reports")} />
        <SBHeaderTypography label={t("overview")} />
      </SBBreadcrumbsWrapper>
    </SubPageHeader>
  );
};

export default ReportsHeader;
