import {
  useInfiniteQuery,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";
import {
  VendorsFiltersDto,
  VendorResponseDto,
  OrderByDirection,
} from "../../../api-client/generated";
import axios, { AxiosError } from "axios";
import { PaginationMeta, RefetchInfinityQuery } from "../../../types";
import { useQuery } from "@tanstack/react-query";
import { vendorApi } from "../../../api/apiTanStack";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

interface Payload {
  page?: number;
  itemsPerPage?: number;
  orderBy?: keyof VendorsFiltersDto;
  orderDir?: OrderByDirection;
  filters?: VendorsFiltersDto;
  search?: string;
  enabled?: boolean;
}

interface VendorPage {
  data: VendorResponseDto[];
  totalItems: number;
  nextPage: number | undefined;
}

export interface UseGetVendors {
  vendors: VendorPage[];
  isFetching: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined
  ) => Promise<
    InfiniteQueryObserverResult<{
      data: VendorResponseDto[];
      nextPage: number | undefined;
    }>
  >;
  hasNextPage?: boolean;
  paginationMeta?: PaginationMeta;
  refetch: RefetchInfinityQuery<VendorResponseDto>;
}

export const useGetVendors = (
  variables: Payload = { enabled: true }
): UseGetVendors => {
  const queryVariables = {
    page: variables?.page || 0,
    itemsPerPage: variables?.itemsPerPage || 15,
    orderBy: variables?.orderBy,
    orderDir: variables?.orderDir,
    filters: variables?.filters,
    search: variables?.search,
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<VendorPage, AxiosError>(
    [
      "vendors",
      queryVariables.page,
      queryVariables.itemsPerPage,
      queryVariables.orderBy,
      queryVariables.orderDir,
      queryVariables.filters,
      queryVariables.search,
    ],
    ({ signal, pageParam = queryVariables.page }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return vendorApi
        .vendorsControllerFindMany({
          ...queryVariables,
          page: pageParam,
        })
        .then(({ data }) => {
          return {
            data: data.items,
            totalItems: data.count,
            nextPage: pageParam + 1,
          };
        });
    },
    {
      enabled: variables.enabled,
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length < queryVariables.itemsPerPage) {
          return undefined;
        }
        return lastPage.nextPage;
      },
    }
  );

  const totalItems = data?.pages?.[0]?.totalItems || 0;
  const totalPages = totalItems
    ? Math.ceil(totalItems / queryVariables.itemsPerPage)
    : 0;

  return {
    vendors: data?.pages || [],
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    paginationMeta: {
      itemsPerPage: queryVariables.itemsPerPage,
      currentPageIndex: (data?.pages?.length || 1) - 1,
      totalItems,
      totalPages,
    },
    refetch,
  };
};

export const useGetAdminVendors = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  pagination: MRT_PaginationState,
  sorting: MRT_SortingState
) =>
  useQuery({
    queryKey: [
      "adminVendors",
      columnFilters,
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
    ],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => `filters[${filter.id}]=${filter.value}`)
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return vendorApi
        .vendorsControllerFindMany(
          {
            page: pagination.pageIndex,
            itemsPerPage: pagination.pageSize,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    keepPreviousData: true,
  });
