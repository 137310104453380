import { featuresApi } from "../../../../api/apiTanStack";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Buffer } from "buffer";

export const useGetCsvBookings = () => {
  const { t } = useTranslation(["common"]);
  return useMutation(
    ["useGetCsvBookings"],
    (data: { filters: string }) => {
      return featuresApi
        .bookingDetailsControllerFindMany(
          { page: 0, csv: "true" },
          { params: data.filters }
        )
        .then((response) => {
          const buff = Buffer.from(response.data as never as string);
          return new Blob([buff], { type: "application/pdf" });
        });
    },
    {
      onSuccess: (data) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = `strandbutler${new Date().toISOString()}.csv`;
        a.click();
        window.URL.revokeObjectURL(url);
      },
      onError: () => {
        toast.error(t(`toastMessages.csvFailure`));
      },
    }
  );
};
