import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../../SBTable/SBTable";
import { useHistory } from "react-router";
import { EDestination } from "../model/EDestination";
import ConfigurationHeader from "../header/configurationHeader";
import { useDeleteLocation } from "./hooks/useDeleteLocation";
import { useGetAdminLocations } from "./hooks/useGetLocations";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";

const LocationList = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteLocation = useDeleteLocation();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteLocationModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("locations");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetAdminLocations(columnFilters, globalFilter, pagination, sorting);

  const handleDeleteLocation = (id: number) => {
    deleteLocation.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "name",
        header: `${t("destinations.name")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/location/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "entranceName",
        header: `${t("entranceName")}`,
        maxSize: 100,
      },
      {
        accessorKey: "seasonStart",
        header: `${t("seasonStart")}`,
        maxSize: 100,
      },
      {
        accessorKey: "seasonEnd",
        header: `${t("seasonEnd")}`,
        maxSize: 100,
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("destinations.allLocations")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const locationId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (locationId) {
                  history.push(`/location/${locationId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (locationId) {
                  history.push(`/location/${locationId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (locationId) {
                  openDeleteLocationModal(locationId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <ConfigurationHeader type={EDestination.LOCATION} />
      <SBTable
        columnVisibilityStoreName="LocationListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="location"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteLocation(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default LocationList;
