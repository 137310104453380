import { useQuery } from "@tanstack/react-query";
import { MRT_ColumnFiltersState, MRT_SortingState } from "material-react-table";
import { useGetCurrentUser } from "../../../hooks";
import { cashPointApi } from "../../../api/apiTanStack";
import { OrderByDirection } from "../../../api-client/generated";

export const useGetClosingReports = (
  columnFilters: MRT_ColumnFiltersState,
  globalFilter: string,
  sorting: MRT_SortingState
) => {
  const { currentUser } = useGetCurrentUser();
  return useQuery({
    queryKey: ["closingReports", columnFilters, globalFilter, sorting],
    queryFn: async () => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => `filters[${filter.id}]=${filter.value}`)
          .join("&")
      );
      const orderBy = sorting.length > 0 ? sorting[0].id : undefined;
      const orderDir =
        sorting.length > 0
          ? sorting[0].desc
            ? OrderByDirection.Desc
            : OrderByDirection.Asc
          : undefined;

      return cashPointApi
        .cashPointControllerGetCashPointClosings(
          {
            locationId: currentUser?.defaultLocationId || 0,
            orderBy: orderBy,
            orderDir: orderDir,
          },
          {
            params: filters,
          }
        )
        .then((data) => data.data);
    },
    enabled: !!currentUser?.defaultLocationId,
    keepPreviousData: true,
  });
};
