import AddIcon from "@mui/icons-material/Add";
import { useHistory, useParams } from "react-router";
import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";

const ExtrasHeader: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  return (
    <SubPageHeader headerTitle="Extras">
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        {id && (
          <SBHeaderLink
            label={id}
            onClick={() => {
              history.push(`/extras/${id}/edit`);
            }}
          />
        )}
        <SBHeaderTypography label={t("extras")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <SBLoadingButton
          label={t("newExtras")}
          type="blue"
          icon={<AddIcon />}
          onClick={() => {
            history.push(`/extras/new`);
          }}
        />
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default ExtrasHeader;
