import { Typography } from "@mui/material";
import { SBTypographyLocationStyle } from "../styles/SBStyles";

export interface SBHeaderTypographyPrimary {
  label: React.ReactNode;
}

export type SBHeaderTypographyType = SBHeaderTypographyPrimary;

const SBHeaderTypography: React.FC<SBHeaderTypographyType> = ({ label }) => {
  return (
    <Typography
      sx={{
        ...SBTypographyLocationStyle,
      }}
    >
      {label}
    </Typography>
  );
};

export default SBHeaderTypography;
