import { format, utcToZonedTime } from "date-fns-tz";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export function formatInUtc(
  date: Date,
  fmt = "yyyy-MM-dd'T'HH:mm:ss'Z'"
): string {
  return format(utcToZonedTime(date, "UTC"), fmt, { timeZone: "UTC" });
}

export const TimeZone = "Europe/Berlin";

export const dateToProperFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), TimeZone), `dd.MM.yyyy - HH:mm`);
};

export const bookingsDateToProperFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), TimeZone), `dd.MM.yyyy - HH:mm`);
};

export const dateToProperTimeFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), TimeZone), `HH:mm`);
};

export const dateToProperDateFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), TimeZone), `yyyy-MM-dd`);
};

export const dateToProperPrintDateFormat = (date: string): string => {
  return format(utcToZonedTime(new Date(date), TimeZone), `dd.MM.yyyy`);
};

export const bookingsDateToProperFormatDayjs = (date: string): string => {
  return dayjs(date).tz(TimeZone).format(`DD.MM.YYYY - HH:mm`);
};
