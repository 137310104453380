import { useMutation } from "@tanstack/react-query";
import { FeaturesApiBookingDetailsControllerCancelBookingRequest as Variables } from "../../../../api-client/generated";
import { bookingsApi } from "../../../../api/apiTanStack";
import { AxiosError, AxiosResponse } from "axios";

export const useDeleteBooking = () => {
  return useMutation<AxiosResponse<void>, AxiosError, Variables>(
    ["deleteBooking"],
    ({ id }: Variables) => bookingsApi.bookingsControllerDelete({ id })
  );
};
