import { createSelector } from "reselect";
import { IFilterData } from "../../models/dataTable/IFilterData";
import { vendorToRow } from "../../utils/conversions/vendorToRow";
import {
  getVendorById,
  getVendors,
  getVendorsPages,
} from "../reducers/authReducer";

import { AppState } from "../reducers/rootReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.auth.pending;

const getIsAuth = (state: AppState) => state.auth.isAuth;

const getIdToken = (state: AppState) => state.auth.idToken;

const getError = (state: AppState) => state.auth.error;

const getCurrentUser = (state: AppState) => state.auth.currentUser;

export const getIsAuthSelector = createSelector(getIsAuth, (isAuth) => isAuth);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getIdTokenSelector = createSelector(
  getIdToken,
  (idToken) => idToken
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getCurrentUserSelector = createSelector(
  getCurrentUser,
  (currentUser) => currentUser
);

export const getVendorsSelector = createSelector(getState, (state) =>
  getVendors(state)
);

export const getVendorByIdSelector = (id: number) =>
  createSelector(getState, (state) => getVendorById(state, id));

const getVendorsFilterData = (state: AppState) => state.auth.filterVendorsData;

const getVendorsCount = (state: AppState) => state.auth.vendorsCount;

export const getVendorsFilterDataSelector = createSelector(
  getVendorsFilterData,
  (filterData) => filterData
);

export const getVendorsCountSelector = createSelector(
  getVendorsCount,
  (count) => count
);

export const getVendorsPageSelector = (
  pageNumber: number,
  itemsPerPage: number,
  filterData?: IFilterData[]
) =>
  createSelector(getState, (state) => {
    const page = getVendorsPages(state, `${pageNumber}:${itemsPerPage}`);
    return (
      page &&
      page!.dataIds.map((id) => getVendorById(state, id)!).map(vendorToRow)
    );
  });

export const getVendorSelector = (id: number) =>
  createSelector(getState, (state) => getVendorById(state, id));
