import { createSelector } from "reselect";

import { AppState } from "../reducers/rootReducer";

const getPending = (state: AppState) => state.admin.pending;

const getVendorId = (state: AppState) => state.admin.vendorId;

const getVendorChangeContextComplete = (state: AppState) =>
  state.admin.vendorChangeContextComplete;

const getError = (state: AppState) => state.auth.error;

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getVendorIdSelector = createSelector(
  getVendorId,
  (vendorId) => vendorId
);

export const getVendorChangeContextCompleteSelector = createSelector(
  getVendorChangeContextComplete,
  (vendorChangeContextComplete) => vendorChangeContextComplete
);
