import { Action } from "@reduxjs/toolkit";
import { all, call, put, takeLatest } from "typed-redux-saga";
import {
  deleteCustomer,
  getCustomer,
  getFilteredCustomers,
  postCustomer,
  putCustomer,
  searchCustomers,
} from "../../api/customerApi";
import history from "../../utils/history/history";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import {
  deleteCustomerFailure,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  fetchCustomerFailure,
  fetchCustomerRequest,
  fetchCustomerSuccess,
  fetchCustomersPageFailure,
  fetchCustomersPageRequest,
  fetchCustomersPageSuccess,
  pushCustomerFailure,
  pushCustomerRequest,
  pushCustomerSuccess,
  routerRedirectCustomer,
  searchBookingCustomerFailure,
  searchBookingCustomerRequest,
  searchBookingCustomerSuccess,
} from "../reducers/customersReducer";
import { toast } from "react-toastify";
import i18next from "i18next";

function* fetchCustomerSaga(action: Action) {
  try {
    if (fetchCustomerRequest.match(action)) {
      const { customerId } = action.payload;
      const { data } = yield* call(getCustomer, customerId);
      yield put(
        fetchCustomerSuccess({
          customer: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchCustomerFailure({
        error: e.message,
      })
    );
  }
}

function* fetchCustomersPageSaga(action: Action) {
  try {
    if (fetchCustomersPageRequest.match(action)) {
      const { filterData, itemsPerPage, page, orderBy, order } = action.payload;
      const filterString: string = filterDataToString(filterData);
      const { data } = yield* call(
        getFilteredCustomers,
        itemsPerPage,
        page,
        filterString,
        orderBy,
        order
      );
      yield put(
        fetchCustomersPageSuccess({
          customers: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
          filterPhrase: filterString,
          orderBy,
          order,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchCustomersPageFailure({
        error: e.message,
      })
    );
  }
}

function* pushCustomerSaga(action: Action) {
  try {
    if (pushCustomerRequest.match(action)) {
      if (action.payload.id) {
        const { data } = yield* call(
          putCustomer,
          +action.payload.id,
          action.payload.formData
        );
        yield put(pushCustomerSuccess({ customer: data }));
        toast.success(`${i18next.t(`toastMessages.PutCustomerSuccess`)}`);
      } else {
        const { data } = yield* call(postCustomer, action.payload.formData);
        yield put(pushCustomerSuccess({ customer: data }));
        toast.error(`${i18next.t(`toastMessages.PushCustomerSuccess`)}`);
      }
      yield* put(routerRedirectCustomer({}));
    }
  } catch (e: any) {
    yield* put(
      pushCustomerFailure({
        error: e.message,
      })
    );
  }
}

function* searchBookingCustomerSaga(action: Action) {
  try {
    if (searchBookingCustomerRequest.match(action)) {
      const { searchPhrase } = action.payload;
      const { data } = yield* call(searchCustomers, searchPhrase);
      yield* put(
        searchBookingCustomerSuccess({
          customers: data.items,
          searchPhrase: searchPhrase,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      searchBookingCustomerFailure({
        error: e.message,
      })
    );
  }
}

function* deleteCustomerSaga(action: Action) {
  try {
    if (deleteCustomerRequest.match(action)) {
      const { customerId } = action.payload;
      yield* call(deleteCustomer, customerId);
      yield* put(
        deleteCustomerSuccess({
          customerId,
        })
      );
      yield* put(routerRedirectCustomer({}));
      toast.success(`${i18next.t(`toastMessages.DeleteCustomerSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      deleteCustomerFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteCustomerFailure`)}`);
  }
}

function* routerRedirectCustomerSaga(action: Action) {
  if (routerRedirectCustomer.match(action)) {
    const { id, type, path } = action.payload;
    yield* call(
      [history, history.push],
      `/customers${id ? `/${id}` : ``}${type ? `/${type}` : ""}${
        path ? `/${path}` : ""
      }`
    );
  }
}

function* customersSaga() {
  yield* all([
    takeLatest(fetchCustomerRequest.type, fetchCustomerSaga),
    takeLatest(fetchCustomersPageRequest.type, fetchCustomersPageSaga),
    takeLatest(pushCustomerRequest.type, pushCustomerSaga),
    takeLatest(deleteCustomerRequest.type, deleteCustomerSaga),
    takeLatest(searchBookingCustomerRequest.type, searchBookingCustomerSaga),
    takeLatest(routerRedirectCustomer.type, routerRedirectCustomerSaga),
  ]);
}

export default customersSaga;
