import { useMutation, UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { CashPointApiCashPointControllerOpenCashPointRequest as Variables } from "../../../api-client/generated";
import { cashPointApi } from "../../../api/apiTanStack";

interface UseOpenCashPoint {
  openCashPoint: UseMutateFunction<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useOpenCashPoint = (): UseOpenCashPoint => {
  const queryClient = useQueryClient();

  const { mutate: openCashPoint, isLoading } = useMutation<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >(
    ["openCashPoint"],
    ({ locationId }: Variables) => cashPointApi.cashPointControllerOpenCashPoint({
      locationId: Number(locationId)
    }),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['cashPointBalance', Number(variables.locationId)]);
      }
    }
  );

  return {
    openCashPoint,
    isLoading
  };
};
