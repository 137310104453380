import { Box } from "@mui/material";
import { customStyle } from "../../../utils/customStyles/customStyles";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { getLocaleShortCode } from "../../../store/selectors/localesSelector";
import * as LOCALES from "date-fns/locale";
import { EAvailabilityTime } from "../../../models/availability/EAvailabilityTime";

const calendarDay = {
  backgroundColor: customStyle.mainColor,
  maxWidth: "80px",
  maxHeight: "80px",
  display: "grid",
  gridTemplateRows: "30px 50px",
  borderRadius: "10% 10% 0 0",
};

const outOfCalendarDay = {
  maxWidth: "80px",
  maxHeight: "80px",
  display: "grid",
  gridTemplateRows: "30px 50px",
  borderRadius: "10% 10% 0 0",
  border: `1px dashed ${customStyle.mainColor}`,
};

const dateSection = {
  margin: "auto",
  maxWidth: "80px",
  maxHeight: "30px",
  color: customStyle.whiteColor,
  textAlign: "center",
} as const;

const avabilityAllday = {
  backgroundColor: customStyle.secondaryColor,
};

const avabilityNoon = {
  background: `${customStyle.secondaryColor}`,
  clipPath: `polygon(0 0, 0 100%, 100% 0)`,
};

const avabilityAfternoon = {
  background: `${customStyle.secondaryColor}`,
  clipPath: `polygon(100% 0, 0 100%, 110% 110%)`,
};

const avabilityFree = {
  maxWidth: "80px",
  maxHeight: "50px",
};

interface IBeachChairCalendarDayProps {
  day: string;
  booked: EAvailabilityTime;
}

const bookedToEnumTime = (booked: EAvailabilityTime) => {
  switch (booked) {
    case EAvailabilityTime.Afternoon:
      return avabilityAfternoon;
    case EAvailabilityTime.Allday:
      return avabilityAllday;
    case EAvailabilityTime.Free:
      return avabilityFree;
    case EAvailabilityTime.Noon:
      return avabilityNoon;
  }
};

const BeachChairCalendarDay = ({
  day,
  booked,
}: IBeachChairCalendarDayProps) => {
  const lc = useSelector(getLocaleShortCode);
  const locale = (LOCALES as Record<string, Locale>)[lc];

  return (
    <Box sx={day !== "" ? calendarDay : outOfCalendarDay}>
      <Box sx={{ ...dateSection }}>
        {day !== "" &&
          `${format(new Date(day), "EEEEEE", {
            locale: locale,
          })} ${format(new Date(day), "dd.MM")}`}
      </Box>
      <Box sx={{ ...bookedToEnumTime(booked) }}></Box>
    </Box>
  );
};

export default BeachChairCalendarDay;
