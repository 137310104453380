import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBTextFieldPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  placeholder?: string | undefined;
}

export type SBTextFieldType = SBTextFieldPrimary & SBFormHelperTextPrimary;

const SBTextField: React.FC<SBTextFieldType> = ({
  type,
  icon,
  label,
  isLoading,
  name,
  control,
  placeholder,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  label=""
                  sx={{ ...SBTextFieldStyle }}
                  placeholder={placeholder}
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBTextField;
