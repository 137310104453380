import React, { useMemo, useState } from "react";
import {
  Box,
  IconButton,
  //TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../SBTable/SBTable";
import { useHistory } from "react-router";
import { useDeleteLock } from "./hooks/useDeleteLock";
import { useGetAdminLocks } from "./hooks/useGetLocks";
import Status from "../status/status";
import LockHeader from "./header/lockHeader";
import { useGetVendors } from "../vendor";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import { deDE } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const LockList = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteLock = useDeleteLock();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteLockModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const { vendors } = useGetVendors({
    itemsPerPage: 250,
  });
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("locks");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } = useGetAdminLocks(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  const handleDeleteLock = (id: number) => {
    deleteLock.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "number",
        header: `${t("lockNumber")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/locks/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "updatedAt",
        header: `${t("updatedAt")}`,
        maxSize: 100,
        Cell: (row: any) => (
          <Typography>
            {new Date(row.renderedCellValue).toLocaleString()}
          </Typography>
        ),
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "mac",
        header: `${t("mac")}`,
        maxSize: 100,
      },
      {
        accessorKey: "vendorId",
        header: `${t("vendor")}`,
        filterVariant: "select",
        filterSelectOptions: vendors
          ?.flatMap((vendorPage) => {
            return vendorPage.data.flatMap((vendor) => {
              return {
                text: `${vendor.id} - ${vendor.name}`,
                value: vendor.id,
              };
            });
          })
          .sort((a, b) => a.value - b.value),
        maxSize: 100,
      },
      {
        accessorKey: "batteryLevel",
        header: `${t("batteryLevel")}`,
        maxSize: 100,
      },
      {
        accessorKey: "status",
        header: `${t("status")}`,
        maxSize: 60,
        filterVariant: "select",
        filterSelectOptions: [
          { text: `${t(`filters.ACTIVE`)}`, value: "ACTIVE" },
          { text: `${t(`filters.INACTIVE`)}`, value: "INACTIVE" },
        ],
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]
                    ? "ACTIVE"
                    : "INACTIVE"
                  }`
                )}`}
              />
            ) : (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue ? "ACTIVE" : "INACTIVE"}`
                )}`}
              />
            )}
          </Box>
        ),
      },
    ];
  }, [t, vendors]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("locks.allLocks")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const lockId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <IconButton
            sx={{ color: "#000000" }}
            disabled={false}
            onClick={() => {
              if (lockId) {
                history.push(`/locks/${lockId}/show`);
              }
            }}
          >
            <VisibilityIcon />
          </IconButton>
          <IconButton
            sx={{ color: "#000000" }}
            disabled={false}
            onClick={() => {
              if (lockId) {
                history.push(`/locks/${lockId}/edit`);
              }
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ color: "#000000" }}
            onClick={() => {
              if (lockId) {
                openDeleteLockModal(lockId);
              }
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <LockHeader />
      <SBTable
        columnVisibilityStoreName="LockListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="lock"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteLock(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default LockList;
