import { getExtra } from "./../../api/extrasApi";
import { Action } from "redux";
import { all, call, put, takeLatest } from "typed-redux-saga";
import {
  deleteExtra,
  getExtras,
  postExtra,
  putExtra,
} from "../../api/extrasApi";
import history from "../../utils/history/history";
import {
  deleteExtrasFailure,
  deleteExtrasRequest,
  deleteExtrasSuccess,
  getExtraFailure,
  getExtraRequest,
  getExtrasFailure,
  getExtrasRequest,
  getExtrasSuccess,
  getExtraSuccess,
  postExtraFailure,
  postExtraRequest,
  postExtraSuccess,
  putExtraFailure,
  putExtraRequest,
  putExtraSuccess,
  routerRedirectExtras,
} from "../reducers/extrasReducer";
import { toast } from "react-toastify";
import i18next from "i18next";

function* getExtrasSaga(action: Action) {
  try {
    if (getExtrasRequest.match(action)) {
      const { page, itemsPerPage, order, field } = action.payload;
      const { data } = yield* call(getExtras, itemsPerPage, page, field, order);
      yield* put(
        getExtrasSuccess({
          extras: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getExtrasFailure({
        error: e.message,
      })
    );
  }
}

function* putExtraSaga(action: Action) {
  try {
    if (putExtraRequest.match(action)) {
      const { formData, extraId } = action.payload;
      const { data } = yield* call(putExtra, extraId, formData);
      yield* put(putExtraSuccess({ extra: data }));
      toast.success(`${i18next.t(`toastMessages.PutExtraSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      putExtraFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutExtraFailure`)}`);
  }
}

function* postExtraSaga(action: Action) {
  try {
    if (postExtraRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postExtra, formData);
      yield* put(postExtraSuccess({ extra: data }));
      yield* put(routerRedirectExtras({}));
      toast.success(`${i18next.t(`toastMessages.PostExtraSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      postExtraFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostExtraFailure`)}`);
  }
}

function* getExtraSaga(action: Action) {
  try {
    if (getExtraRequest.match(action)) {
      const { extraId } = action.payload;
      const { data } = yield* call(getExtra, extraId);
      yield* put(
        getExtraSuccess({
          extra: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      getExtraFailure({
        error: e.message,
      })
    );
  }
}

function* deleteExtrasSaga(action: Action) {
  try {
    if (deleteExtrasRequest.match(action)) {
      const { extrasId, isList } = action.payload;
      yield* call(deleteExtra, extrasId);
      if (!isList) {
        yield* put(routerRedirectExtras({}));
      }
      yield* put(deleteExtrasSuccess({ extrasId: extrasId }));
      toast.success(`${i18next.t(`toastMessages.DeleteExtrasSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      deleteExtrasFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteExtrasFailure`)}`);
  }
}

function* routerRedirectExtrasSaga(action: Action) {
  if (routerRedirectExtras.match(action)) {
    const { id, type } = action.payload;
    yield* call(
      [history, history.push],
      `/extras${id ? `/${id}` : ``}${type ? `/${type}` : ``}`
    );
  }
}

function* ExtrasSaga() {
  yield* all([
    takeLatest(getExtrasRequest.type, getExtrasSaga),
    takeLatest(getExtraRequest.type, getExtraSaga),
    takeLatest(postExtraRequest.type, postExtraSaga),
    takeLatest(putExtraRequest.type, putExtraSaga),
    takeLatest(deleteExtrasRequest.type, deleteExtrasSaga),
    takeLatest(routerRedirectExtras.type, routerRedirectExtrasSaga),
  ]);
}

export default ExtrasSaga;
