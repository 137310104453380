import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { sectionApi } from "../../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteSection = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteSection"],
    ({ id }: { id: number }) => sectionApi.sectionsControllerDelete({ id: id }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteSectionSuccess`));
        queryClient.refetchQueries(["adminSections"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteSectionFailure`));
      },
    }
  );
};
