import { Typography } from "@mui/material";
import { SBColor } from "../styles/SBStyles";

export interface SBSBRedirectLabelPrimary {
  label: React.ReactNode;
}

export type SBHeaderLinkType = SBSBRedirectLabelPrimary;

const SBRedirectLabel: React.FC<SBHeaderLinkType> = ({ label }) => {
  return (
    <Typography sx={{color: SBColor.blue, textDecoration: "underline"}} fontWeight="bold">
      {label}
    </Typography>

  );
};

export default SBRedirectLabel;