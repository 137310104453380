import "./App.css";
import React from "react";
import { NoAccessPage } from "./components/errorPages";
import { Navigation, MainWrapper } from "./components/layout";
import { Router, Switch } from "react-router-dom";
import Home from "./components/home/home";
import BeachChairsList from "./components/beachChairList/beachChairsList";
import Availabilities from "./components/availabilities/availabilities";
import {
  CashPointBalance,
  CashPointReports,
  CashPointReport,
} from "./components/cashpoint/";
import Faq from "./components/static/faq";
import Support from "./components/static/support";
import Privacy from "./components/static/privacy";
import Terms from "./components/static/terms";
import Settings from "./components/settings/settings";
import { IRouteElement } from "./models/IRouteElement";
import { useMemo } from "react";
import BeachChair from "./components/beachChairList/beachChair/beachChair";
import PrivateRoute from "./components/privateRoute/privateRoute";
import availabilitiesBeachChairs from "./components/availabilities/availabilitiesBeachChairs/availabilitiesBeachChairs";
import Booking from "./components/bookingsList/booking/booking";
import history from "./utils/history/history";
import Vendor from "./components/vendor/vendor";
import Location from "./components/location/location";
import ExtrasList from "./components/extras/extrasList";
import Extra from "./components/extras/extra/extra";
import Locations from "./components/location/locations";
import Reports from "./components/reports/reports";
import ReportKeyStatus from "./components/reports/reportKeyStatus/reportKeyStatus";
import ClosingReports from "./components/reports/closingReports/closingReports";
import BeachChairBookingsList from "./components/beachChairList/beachChairBookings/beachChairBookingsList";
import SepaXMLs from "./components/reports/sepaXMLs/sepaXMLs";
import BeachChairCalendar from "./components/beachChairList/beachChairCalendar.tsx/beachChairCalendar";
import RegionList from "./components/configuration/region/regionList";
import Region from "./components/configuration/region/region";
import CityList from "./components/configuration/city/cityList";
import City from "./components/configuration/city/city";
import BeachList from "./components/configuration/beach/beachList";
import Beach from "./components/configuration/beach/beach";
import SectionList from "./components/configuration/section/sectionList";
import Section from "./components/configuration/section/section";
import InstantBookings from "./components/bookingsList/instantBooking/instantBookings";
import ShortUrls from "./components/shortUrls/shortUrls";
import ShortUrl from "./components/shortUrls/shortUrl";
import { ThemeProvider } from "@mui/material/styles";
import BeachChairReports from "./components/reports/beachChairs/beachChairReports";
import LockList from "./components/lock/lockList";
import Lock from "./components/lock/lock";
import VendorList from "./components/vendor/vendorList";
import ExtraText from "./components/extraText/extraText";
import LocationList from "./components/configuration/location/locationList";
import BeachChairBookingsAdvancedList from "./components/beachChairBookingsList/beachChairBookingsAdvancedList";
import { theme } from "./theme";
import { Box } from "@mui/material";
import { CustomerEdit } from "./components/customers/customerEdit";
import { CustomerCreate } from "./components/customers/customerCreate";
import { CustomerBookingsList } from "./components/customers/customerBookings";
import CustomerList from "./components/customers/customerList/customerList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Prices from "./components/prices/prices";
import PricesList from "./components/prices/pricesList";
import BeachChairBulk from "./components/beachChairList/beachChair/beachChairBulk";
import availabilitiesBeachChairsMap from "./components/availabilities/availabilitiesBeachChairs/availabilitiesBeachChairsMap/availabilitiesBeachChairsMap";
import Billing from "./components/vendor/commission/billing";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { getCurrentUserRequest } from "./store/reducers/authReducer";
import { useAuthStore } from "./components/auth/hooks/authStore";
import setupInterceptors from "./api/setupInterceptors";
import AuthWrapper from "./components/auth/AuthWrapper";
import { VendorUsersList } from "./components/vendorUsers/vendorUsersList";
import { VendorUser } from "./components/vendorUsers/vendorUser/vendorUser";
import StatisticsReports from "./components/reports/statistics/StatisticsReports";

const App = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { isLoggedIn, actions } = useAuthStore();
  React.useEffect(() => {
    actions.init();
    setupInterceptors(queryClient);
    ///dispatch(setLocale({ shortCode: "de" }));
    dispatch(getCurrentUserRequest({}));
    // eslint-disable-next-line
  }, []);
  const routeElements: IRouteElement[] = useMemo<IRouteElement[]>(
    () => [
      {
        path: "/beach_chairs",
        component: BeachChairsList,
        keyId: "route-beach_chairs",
      },
      {
        path: "/beach_chairs/bulk",
        component: BeachChairBulk,
        keyId: "route-beach_chairs-bulk",
      },
      {
        path: "/beach_chairs/:type",
        component: BeachChair,
        keyId: "route-beach_chairs-id",
      },
      {
        path: "/beach_chairs/:id/bookings",
        component: BeachChairBookingsList,
        keyId: "route-beach_chairs-id-bookings",
      },
      {
        path: "/beach_chairs/:id/calendar",
        component: BeachChairCalendar,
        keyId: "route-beach_chairs-id-calendar",
      },
      {
        path: "/beach_chairs/:id/:type",
        component: BeachChair,
        keyId: "route-beach_chairs-id-type",
      },
      {
        path: "/availabilities/map/:sectionId/beachChairs",
        component: availabilitiesBeachChairsMap,
        keyId: "route-availabilities-map",
      },
      {
        path: "/availabilities/:type",
        component: Availabilities,
        keyId: "route-availabilities",
      },
      {
        path: "/availabilities/:type/:sectionId/beachChairs",
        component: availabilitiesBeachChairs,
        keyId: "route-availabilities",
      },
      {
        path: "/bookings/:id/:type",
        component: Booking,
        keyId: "route-bookings",
      },
      {
        path: "/bookings/:id/edit/availabilities/box",
        component: Availabilities,
        keyId: "route-availabilities",
      },
      {
        path: "/bookings/:id/edit/availabilities/box/:sectionId/beachChairs",
        component: availabilitiesBeachChairs,
        keyId: "route-availabilities",
      },

      {
        path: "/instant-bookings",
        component: InstantBookings,
        keyId: "route-instant-bookings",
      },

      {
        path: "/extras",
        component: ExtrasList,
        keyId: "route-extras",
      },
      {
        path: "/extras/:id/:type",
        component: Extra,
        keyId: "route-extras",
      },
      {
        path: "/extras/:type",
        component: Extra,
        keyId: "route-extras",
      },
      {
        path: "/extraTexts",
        component: ExtraText,
        keyId: "route-extraTexts",
      },
      {
        path: "/vendor",
        component: Vendor,
        keyId: "route-vendors",
      },
      {
        path: "/vendors",
        component: VendorList,
        keyId: "route-vendor-list",
      },
      {
        path: "/vendors/:type",
        component: Vendor,
        keyId: "route-location-type",
      },
      {
        path: "/vendors/:id/:type",
        component: Vendor,
        keyId: "route-location-id-type",
      },
      {
        path: "/vendor_users",
        component: VendorUsersList,
        keyId: "vendor-users",
      },
      {
        path: "/vendor_users/:id/:type",
        component: VendorUser,
        keyId: "vendor-users-id-type",
      },
      {
        path: "/vendor_users/:type",
        component: VendorUser,
        keyId: "vendor-users-new",
      },
      {
        path: "/locations",
        component: LocationList,
        keyId: "route-location-list",
      },
      {
        path: "/location",
        component: Locations,
        keyId: "route-location",
      },
      {
        path: "/location/:type",
        component: Location,
        keyId: "route-location-type",
      },
      {
        path: "/location/:id/:type",
        component: Location,
        keyId: "route-location-id-type",
      },
      {
        path: "/reports",
        component: Reports,
        keyId: "route-reports-id-type",
      },
      {
        path: "/reports/key_status",
        component: ReportKeyStatus,
        keyId: "route-reports-id-type",
      },
      {
        path: "/reports/closing_reports",
        component: ClosingReports,
        keyId: "route-closing-reports-id-type",
      },
      {
        path: "/reports/sepa_xmls",
        component: SepaXMLs,
        keyId: "route-sepa-xml-id-type",
      },
      {
        path: "/reports/beach_chair",
        component: BeachChairReports,
        keyId: "route-beach-chairs-report-id-type",
      },
      {
        path: "/reports/statistics",
        component: StatisticsReports,
        keyId: "route-statistics-id-type",
      },
      {
        path: "/cashpoint/:locationId/cash-point-balance",
        component: CashPointBalance,
        keyId: "cash-point-balance",
      },
      {
        path: "/cashpoint/:locationId/cash-point-reports",
        component: CashPointReports,
        keyId: "cash-point-reports",
      },
      {
        path: "/cashpoint/:locationId/cash-point-reports/:id",
        component: CashPointReport,
        keyId: "cash-point-report",
      },
      {
        path: "/evaluations",
        component: Home,
        keyId: "route-evaluations",
      },
      {
        path: "/settings",
        component: Settings,
        keyId: "route-settings",
      },
      {
        path: "/pages/faq",
        component: Faq,
        keyId: "route-faq",
      },
      {
        path: "/pages/support",
        component: Support,
        keyId: "route-support",
      },
      {
        path: "/pages/privacy",
        component: Privacy,
        keyId: "route-privacy",
      },
      {
        path: "/pages/terms",
        component: Terms,
        keyId: "route-terms",
      },
      {
        path: "/region/:type",
        component: Region,
        keyId: "route-region-new",
      },
      {
        path: "/region/:id/:type",
        component: Region,
        keyId: "route-region",
      },
      {
        path: "/region",
        component: RegionList,
        keyId: "route-region-list",
      },
      {
        path: "/city/:type",
        component: City,
        keyId: "route-city",
      },
      {
        path: "/city/:id/:type",
        component: City,
        keyId: "route-city",
      },
      {
        path: "/city",
        component: CityList,
        keyId: "route-city-list",
      },
      {
        path: "/customers/:id/bookings",
        component: CustomerBookingsList,
        keyId: "route-city",
      },
      {
        path: "/customers/:type",
        component: CustomerCreate,
        keyId: "route-city",
      },
      {
        path: "/customers/:id/:type",
        component: CustomerEdit,
        keyId: "route-city",
      },
      {
        path: "/customers",
        component: CustomerList,
        keyId: "route-city-list",
      },
      {
        path: "/beach/:type",
        component: Beach,
        keyId: "route-beach",
      },
      {
        path: "/beach/:id/:type",
        component: Beach,
        keyId: "route-beach",
      },
      {
        path: "/beach",
        component: BeachList,
        keyId: "route-beach-list",
      },
      {
        path: "/section/:type",
        component: Section,
        keyId: "route-section",
      },
      {
        path: "/section/:id/:type",
        component: Section,
        keyId: "route-section",
      },
      {
        path: "/section",
        component: SectionList,
        keyId: "route-section-list",
      },
      {
        path: "/shortUrls/:type",
        component: ShortUrl,
        keyId: "route-shortUrl-list",
      },
      {
        path: "/shortUrls/:id/:type",
        component: ShortUrl,
        keyId: "route-shortUrl-list",
      },
      {
        path: "/shortUrls",
        component: ShortUrls,
        keyId: "route-shortUrls-list",
      },
      {
        path: "/locks/:type",
        component: Lock,
        keyId: "route-locks-new",
      },
      {
        path: "/locks/:id/:type",
        component: Lock,
        keyId: "route-lock-type",
      },
      {
        path: "/locks",
        component: LockList,
        keyId: "route-locks-list",
      },
      {
        path: "/no-access",
        component: NoAccessPage,
        keyId: "route-no-access",
      },
      {
        path: "/prices",
        component: PricesList,
        keyId: "route-prices",
      },
      {
        path: "/prices/:type",
        component: Prices,
        keyId: "route-prices",
      },
      {
        path: "/prices/:id/:type",
        component: Prices,
        keyId: "route-prices",
      },
      {
        path: "/vendors/billing/:id/:type",
        component: Billing,
        keyId: "route-vendors-billing",
      },
      {
        path: "/billing",
        component: Billing,
        keyId: "route-billing",
      },
      {
        path: "/bookings",
        component: BeachChairBookingsAdvancedList,
        keyId: "route-beach-chair-bookings",
      },
      { path: "/", component: Home, keyId: "route-home-redirect", exact: true },
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      {isLoggedIn ? (
        <Box
          sx={{
            display: "flex",
            minHeight: "100%",
            overflow: "hidden",
          }}
        >
          <Router history={history}>
            <Navigation />
            <MainWrapper>
              <Switch>
                {routeElements.map(
                  ({ path, component, keyId, exact = true }) => (
                    <PrivateRoute
                      path={path}
                      component={component}
                      key={keyId}
                      exact={exact}
                    />
                  )
                )}
              </Switch>
            </MainWrapper>
          </Router>
        </Box>
      ) : (
        <AuthWrapper />
      )}
      <ToastContainer position="top-center" autoClose={5000} theme="colored" />
    </ThemeProvider>
  );
};

export default App;
