import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CreateExtrasBookingDto } from "../../../api-client/generated";
import { bookingsApi } from "../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

interface Variables extends CreateExtrasBookingDto {
  id: number;
}

export function useAddExtrasToBooking() {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const { setBookingInProgress } = useBookingInProgressStore();
  return useMutation({
    mutationFn: ({
      id,
      extraId,
      amount,
    }: Variables & { isContinue: boolean }) =>
      bookingsApi.bookingsControllerAddExtras({
        id,
        createExtrasBookingDto: {
          extraId: Number(extraId),
          amount: Number(amount),
        },
      }),
    onSuccess: (data, variables) => {
      setBookingInProgress(data.data);
      queryClient.refetchQueries(["booking", Number(variables.id)]);
      toast.success(t(`toastMessages.AddExtrasToBookingSuccess`));
      if (!variables.isContinue) {
        history.push(`/bookings/${variables.id}/edit`);
      }
    },
  });
}
