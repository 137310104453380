import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { beachChairApi } from "../../../../api/apiTanStack";

export const usePatchBulkUpdateBeachChair = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    ["PatchBulkUpdateBeachChair"],
    (data: { ids: string[]; update: any }) =>
      beachChairApi.beachChairsControllerBatchUpdate({
        batchUpdateBeachChairBodyDto: { ids: data.ids, update: data.update },
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["beachChairList"]);
        queryClient.refetchQueries(["beachChairFilters"]);
        toast.success(t(`toastMessages.PatchBulkUpdateBeachChairSuccess`));
        history.push("/beach_chairs");
      },
      onError: () => {
        toast.error(t(`toastMessages.PatchBulkUpdateBeachChairFailure`));
      },
    }
  );
};
