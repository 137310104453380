import { create } from "zustand";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";

interface SelectedBeachChairDatesStore {
  dateTime: DateRange<Dayjs>;
  setBookingStartDateTime: (start: Dayjs, end?: Dayjs) => void;
  setBookingEndDateTime: (end: Dayjs) => void;
}

export const useSelectedBeachChairDates = create<SelectedBeachChairDatesStore>(
  (set, get) => {
    let start = dayjs(new Date()).set("hours", 8).set("minutes", 0);
    let end = dayjs(new Date()).set("hours", 22).set("minutes", 0);
    if (dayjs().isAfter(start)) {
      start = dayjs(new Date());
    }
    if (dayjs().isAfter(end)) {
      start = dayjs(new Date()).add(1, "day").set("hours", 8).set("minutes", 0);
      end = dayjs(new Date()).add(1, "day").set("hours", 22).set("minutes", 0);
    }
    return {
      dateTime: [start, end],
      setBookingStartDateTime: (start: Dayjs, end?: Dayjs) => {
        const endAfter = start.isAfter(get().dateTime?.[1])
          ? end ?? start
          : get().dateTime?.[1];
        return set((state) => ({
          ...state,
          dateTime: [start, endAfter],
        }));
      },
      setBookingEndDateTime: (end: Dayjs) =>
        set((state) => ({
          ...state,
          dateTime: [get().dateTime?.[0] ?? end, end],
        })),
    };
  }
);
