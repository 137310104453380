import React, { useMemo } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  TextField,
  CircularProgress,
  Box,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { VendorResponseDto } from "../../../api-client/generated";
import { useGetVendor } from "../../vendor";
import {
  SBAutompleteTextFieldStyle,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../SBComponents/styles/SBStyles";
import { useGetVendorsAutocompleteOptions } from "../../vendorUsers/hooks/useGetVendorsAutocomplete";

interface VendorOption {
  value: string;
  label: string;
  data?: VendorResponseDto;
}

interface VendorAutocompleteProps
  extends Omit<
    AutocompleteProps<VendorOption, false, true, false>,
    "options" | "renderInput" | "onChange" | "value"
  > {
  value?: string | number;
  onChange: (vendorId: number, vendor?: VendorResponseDto) => void;
  label: React.ReactNode;
  icon?: React.ReactNode;
  type?: string;
}

export const VendorAutocomplete: React.FC<VendorAutocompleteProps> = ({
  value: vendorId = "",
  label,
  icon,
  type,
  onChange,
  ...props
}) => {
  const { t } = useTranslation(["common"]);
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  // TODO: When free search text is available (search param)
  // we should switch from FE filtering to BE filtering
  const {
    data: vendors,
    isLoading,
    isFetching,
  } = useGetVendorsAutocompleteOptions();

  const allVendors = useMemo(() => vendors || [], [vendors]);
  const isVendorAlreadyInList = !!allVendors.find(
    ({ id }: VendorResponseDto) => id === Number(vendorId)
  );

  // Undefined will prevent a vendor fetch
  const { data: vendor, isFetching: isVendorFetching } = useGetVendor(
    isFetching || isVendorAlreadyInList ? undefined : vendorId
  );

  const vendorOptions: VendorOption[] = React.useMemo(() => {
    const vendorList = [...allVendors];

    if (!isVendorAlreadyInList && vendor) {
      vendorList.push(vendor);
      vendorList.sort((a, b) => a.name.localeCompare(b.name));
    }

    return vendorList.map((vendor: VendorResponseDto) => ({
      value: vendor.id.toString(),
      label: `${vendor.id} - ${vendor.name}`,
    }));
  }, [allVendors, isVendorAlreadyInList, vendor]);

  const selectedVendor = vendorOptions.find(
    ({ value: selectedVendorId }: VendorOption) => selectedVendorId === vendorId
  ) || {
    value: vendorId.toString(),
    label: "",
  };

  const handleOnChange = (
    event: React.ChangeEvent<{}>,
    selectedOption: VendorOption | null
  ) => {
    if (selectedOption) {
      onChange(Number(selectedOption.value), selectedOption?.data);
    }
  };

  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box sx={{ display: "grid" }}>
        <InputLabel
          sx={{
            ...SBLabelStyle,
            width: "100%",
            position: "static",
            transform: "none",
          }}
        >
          {label}
        </InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          <Autocomplete
            {...props}
            disabled={isDisabled}
            autoComplete={true}
            freeSolo={false}
            multiple={false}
            value={selectedVendor}
            onChange={handleOnChange}
            options={vendorOptions}
            getOptionLabel={(option: VendorOption) => option.label}
            loading={isFetching || isVendorFetching}
            renderInput={(params) => (
              <TextField
                {...params}
                disabled={isDisabled}
                placeholder={t("searchVendor")}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isFetching ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                  disableUnderline: true,
                }}
                sx={{
                  ...SBTextFieldStyle,
                  ...SBAutompleteTextFieldStyle,
                  position: "static",
                }}
              />
            )}
            clearOnBlur={false}
            clearOnEscape={false}
            disableClearable={true}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </FormControl>
      </Box>
    </Box>
  );
};
