import { Box } from "@mui/material";
import { Fragment } from "react";
import AvailabilitiesHeader from "./availabilitiesHeader/availabilitiesHeader";
import { customStyle } from "../../utils/customStyles/customStyles";
import { useTranslation } from "react-i18next";
import { AvailabilityCard } from "./components";
import { useAvailabilitiesSections } from "./hooks/useAvailabilitiesSections";
import { useAvailabilitiesDatesStore } from "./hooks/useAvailabilitiesDatesStore";
import RowDivider from "./availabilitiesBeachChairs/rowDivider/rowDivider";

const Availabilities = () => {
  const { availabilitiesDateRange } = useAvailabilitiesDatesStore();
  const { data: beachList } = useAvailabilitiesSections(
    availabilitiesDateRange[0]?.toISOString(),
    availabilitiesDateRange[1]?.toISOString()
  );
  const { t } = useTranslation(["common"]);

  return (
    <Box sx={{ px: "20px", pt: "20px" }}>
      <AvailabilitiesHeader />
      <Box
        sx={{
          minHeight: "calc(70vh)",
          maxHeight: "calc(70vh)",
          height: "calc(70vh)",
          pt: "20px",
          px: "20px",
          bottom: 0,
          overflowY: "scroll",
          boxSizing: "content-box",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: customStyle.scrollColor,
            borderRadius: "5px",
          },
        }}
        data-testid="availabilities-content"
      >
        {(beachList || []).map((beach) => (
          <Fragment key={beach.id}>
            <RowDivider name={beach.name} />
            <br />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(320px, 320px));",
                gap: "10px",
              }}
              key={`box-row-content-${beach.id}`}
            >
              {beach.sections?.map((availability) => (
                <AvailabilityCard
                  availability={availability}
                  key={availability.id}
                />
              ))}
            </Box>
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default Availabilities;
