import React, { useMemo, useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@mui/icons-material/Refresh";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../../SBTable/SBTable";
import { useHistory } from "react-router";
import { useGetCurrentUser } from "../../../hooks";
import { useGetClosingReports } from "../hooks";
import { format } from "date-fns";
import { formatPrice } from "../../../utils";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";

const CashPointReportsTable = () => {
  const { t } = useTranslation(["common"]);
  const { currentUser } = useGetCurrentUser();
  const locationId = React.useMemo(
    () => currentUser?.defaultLocationId,
    [currentUser]
  );
  const history = useHistory();

  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("CashPointReportsTable");
  const [globalFilter, setGlobalFilter] = useState("");

  const { data, isError, isFetching, isLoading, refetch } =
    useGetClosingReports(columnFilters, globalFilter, sorting);

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "closing_id",
        enableSorting: false,
        header: `ID`,
        maxSize: 80,
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.closing_id) {
                history.push(
                  `/cashpoint/${locationId}/cash-point-reports/${row?.row?.original?.closing_id}`
                );
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "cash_point_closing_export_id",
        header: `${t("exportId")}`,
        maxSize: 60,
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
      },
      {
        accessorKey: "export_creation_date",
        header: `${t("exportDate")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {format(new Date(row.renderedCellValue * 1000), "dd.MM.yyyy")}
          </Box>
        ),
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
      },
      {
        accessorKey: "full_amount",
        header: `${t("transactions")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {formatPrice(row.renderedCellValue)}
          </Box>
        ),
        disableGlobalFilter: true,
        Filter: () => <Box></Box>,
        enableSorting: false,
      },
    ];
  }, [t, locationId]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>
          {t("cashPointMenu.cashPointReports")}
        </Typography>
        <IconButton
          onClick={() => {
            refetch();
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Box>
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const closingId = data
        ? (data as any)?.items[cell.row.index]?.closing_id
        : 0;
      return (
        <Box sx={{ float: "right" }}>
          <IconButton
            sx={{ color: "#000000" }}
            disabled={false}
            onClick={() => {
              if (closingId) {
                history.push(
                  `/cashpoint/${locationId}/cash-point-reports/${closingId}`
                );
              }
            }}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      );
    },
    [data, history, locationId]
  );

  return (
    <Box>
      <SBTable
        data={{
          items:
            (data as any)?.items.slice(
              pagination.pageIndex * pagination.pageSize,
              (pagination.pageIndex + 1) * pagination.pageSize
            ) ?? [],
          count: (data as any)?.items.length ?? 0,
        }}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        columnVisibilityStoreName="cashPointReportsColumnVisibilityStore"
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
    </Box>
  );
};

export default CashPointReportsTable;
