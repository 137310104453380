import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { featuresApi, locationApi } from "../../api/apiTanStack";
import {
  CreateLocationDto,
  OrderByDirection,
  UpdateLocationDto,
} from "../../api-client/generated";
import { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

export function useInfiniteLocationListQuery(
  sorting: SortingState,
  columnFilters: ColumnFiltersState
) {
  return useInfiniteQuery(
    ["locations", sorting, columnFilters],
    async ({ pageParam = 0 }) => {
      const filters = new URLSearchParams(
        columnFilters
          .map((filter) => `filters[${filter.id}]=${filter.value}`)
          .join("&")
      );
      return locationApi.locationsControllerFindMany(
        {
          page: pageParam,
          itemsPerPage: 20,
          orderBy: sorting.length > 0 ? sorting[0].id : undefined,
          orderDir:
            sorting.length > 0
              ? sorting[0].desc
                ? OrderByDirection.Asc
                : OrderByDirection.Desc
              : undefined,
        },
        { params: filters }
      );
    },
    {
      getNextPageParam: (_lastGroup, groups) => groups.length,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchInterval: 60000,
    }
  );
}

export function useLocationsQuery() {
  const locationsQuery = useQuery({
    queryKey: ["locations"],
    queryFn: () => locationApi.locationsControllerFindMany(),
  });

  return {
    data: locationsQuery.data?.data,
    isLoading: locationsQuery.isLoading,
    isFetching: locationsQuery.isFetching,
    isError: locationsQuery.isError,
  };
}

export function useLocationQuery(id: number) {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["location", id],
    queryFn: () => locationApi.locationsControllerFindOne({ id }),
  });

  return {
    data: data?.data,
    isLoading,
    isError,
  };
}

export function useLocationUpdateMutation() {
  const queryClient = useQueryClient();

  const locationUpdateMutation = useMutation({
    mutationFn: (data: { id: number; updateLocationDto: UpdateLocationDto }) =>
      featuresApi.locationsControllerUpdate({
        id: data.id,
        updateLocationDto: data.updateLocationDto,
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["locations"] });
      queryClient.invalidateQueries({ queryKey: ["location", variables.id] });
    },
  });

  return {
    locationUpdateMutation,
  };
}

export function useLocationCreateMutation() {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const locationCreateMutation = useMutation({
    mutationFn: (data: { createLocationDto: CreateLocationDto }) =>
      featuresApi.locationsControllerCreate({
        createLocationDto: data.createLocationDto,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["locations"] });
      toast.success(t(`toastMessages.PutLocationSuccess`));
      history.goBack();
    },
    onError: () => {
      toast.success(t(`toastMessages.PutLocationFailure`));
    },
  });

  return {
    locationCreateMutation,
  };
}

export function useLocationDeleteMutation() {
  const queryClient = useQueryClient();

  const locationDeleteMutation = useMutation({
    mutationFn: (data: { id: number }) =>
      locationApi.locationsControllerDelete({
        id: data.id,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["locations"] });
    },
  });

  return {
    locationDeleteMutation,
  };
}
