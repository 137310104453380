import { QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { featuresApi } from "../../../../api/apiTanStack";

interface UseGetBankTransferDetails {
  data?: any;
  isLoading: boolean;
  getBankTransferDetailsPDF: () => Promise<
    QueryObserverResult<Blob, AxiosError>
  >;
}

export const useGetBankTransferDetails = (
  bookingId?: string | number
): UseGetBankTransferDetails => {
  const { isLoading, refetch: getBankTransferDetailsPDF } = useQuery<
    Blob,
    AxiosError
  >(
    ["bookingBankTransferDetails", bookingId],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      signal?.addEventListener("abort", () => {
        source.cancel();
      });
      return featuresApi
        .bookingDetailsControllerGetFile(
          { id: Number(bookingId) ?? "" },
          { responseType: "blob" }
        )
        .then(({ data }) => {
          // Response type is invalid.
          // The encoded PDF file is returned instead of void
          // @ts-ignore
          return data as Blob;
        });
    },
    { enabled: false }
  );

  return {
    isLoading,
    getBankTransferDetailsPDF,
  };
};
