export enum EAvailabilityTime {
  Free = "boxBackgroundFree",
  Noon = "boxBackgroundNoon",
  Afternoon = "boxBackgroundAfterNoon",
  Allday = "boxBackgroundAllDay",
}

export enum Reason {
  OUTSIDE_RENTAL_SEASON = 'OUTSIDE_RENTAL_SEASON',
  STOP_SALE = 'STOP_SALE',
  BOOKED = 'BOOKED'
}