import { useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { CreateUserBodyDto, ResponseUserDto } from "../../../../api-client/generated";
import { useTranslation } from "react-i18next";
import { UserRoles, useGetCurrentUser } from "../../../../hooks";
import { useCreateVendorUser, useGetVendorUser, useUpdateVendorUser } from "../../hooks";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { ApiError } from "../../../../types";

export const useVendorUserForm = (id: string, type: string) => {
  const { t } = useTranslation(["common"]);
  const { hasRole, currentUser, isLoading: isCurrentUserLoading } = useGetCurrentUser();
  const isSuperAdmin = hasRole(UserRoles.SUPER_ADMIN);
  const queryClient = useQueryClient();
  const { createVendorUser } = useCreateVendorUser();
  const { updateVendorUser } = useUpdateVendorUser();
  const {vendorUser, isLoading: isVendorUserLoading} = useGetVendorUser(id);

  const isLoading = (isCurrentUserLoading || isVendorUserLoading) && type !== "new";
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        salutation: yup
          .string()
          .oneOf(["MR", "MS", "FM", "MX"])
          .required(t("isMandatory")),
        firstName: yup.string().required(t("isMandatory")),
        lastName: yup.string().required(t("isMandatory")),
        email: yup.string().email(t("shouldBeEmail")).required(t("email") + t("isMandatory")),
        id: yup.string().optional(),
        vendorId: yup.number().required(t("isMandatory")),
        defaultLocationId: yup.number().required(t("isMandatory")),
      }),
    [t]
  );

  const {
    control,
    handleSubmit,
    formState: { errors, submitCount, isValid, isDirty, isSubmitting },
    watch,
    setValue,
  } = useForm<Partial<ResponseUserDto>>({
    resolver: yupResolver(validationSchema),
  });

  

  const handleCreate = ({
    email,
    firstName,
    lastName,
    salutation,
    vendorId,
    defaultLocationId,
  }: Partial<ResponseUserDto>) => {
    createVendorUser(
      {
        email,
        firstName,
        lastName,
        salutation,
        vendorId,
        defaultLocationId,
      } as CreateUserBodyDto,
      {
        onSuccess: () => {
          toast.success(t(`toastMessages.createUserSuccess`));
          queryClient.refetchQueries(["vendorUsers"]);
          
        },
        onError: (error: ApiError) => {
          const errortoastMessages =
            error.response?.data?.errorType === "USER_ALREADY_EXISTS"
              ? "createUserFailureAlreadyExists"
              : "createUserFailure";
          toast.error(t(`toastMessages.${errortoastMessages}`));
        },
      }
    );
  };

  const handleEdit= ({
    id,
    firstName,
    lastName,
    salutation,
    defaultLocationId,
  }: Partial<ResponseUserDto>) => {
    updateVendorUser(
      {
        id: id!,
        updateUserBodyDto: {
          id,
          firstName,
          lastName,
          salutation,
          defaultLocationId,
        },
      },
      {
        onSuccess: async () => {
          toast.success(t(`toastMessages.editUserSuccess`));
          await queryClient.refetchQueries(["vendorUsers"]);
          await queryClient.invalidateQueries(["vendorUser", Number(id)]);
        },
        onError: () => {
          toast.error(t(`toastMessages.editUserFailure`));
        },
      }
    );
  };

  const handleReset = useCallback(() => {
    setValue("id", "");
    setValue("salutation" ,"MR");
    setValue("firstName", "");
    setValue("lastName", "");
    setValue("email", "");
    setValue("vendorId", !isSuperAdmin && currentUser ? currentUser.vendorId : undefined,);
    setValue("defaultLocationId", !isSuperAdmin && currentUser
    ? currentUser?.defaultLocationId
    : undefined,);
  }, [setValue, isSuperAdmin, currentUser]);

  useEffect(() => {
    if(vendorUser) {
      setValue("id", vendorUser.id);
      setValue("salutation" ,vendorUser.salutation);
      setValue("firstName", vendorUser.firstName);
      setValue("lastName",vendorUser.lastName);
      setValue("email", vendorUser.email);
      setValue("vendorId", vendorUser.vendorId);
      setValue("defaultLocationId", vendorUser.defaultLocationId);
    }
    if(type === "new") {
      handleReset();
    }
  }, [setValue, vendorUser, type, handleReset]);

  const onSubmit = useCallback(
    (formValues) => {
      if (type === "new") {
        handleCreate(formValues);
      } else {
        handleEdit(formValues);
      }
    },
    [id, type, handleCreate, handleEdit]
  );

  return {
    isSuperAdmin,
    control,
    handleReset,
    errors,
    onSubmit: handleSubmit(onSubmit),
    watch,
    submitCount,
    isValid,
    isDirty,
    setValue,
    isSubmitting,
    isLoading };
};
