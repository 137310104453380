import React from "react";
import { CustomerForm, CustomerHeader } from "../components";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";

export const CustomerCreate: React.FC = () => {
  return (
    <SBMainComponentGridWrapper>
      <CustomerHeader />
      <CustomerForm />
    </SBMainComponentGridWrapper>
  );
};
