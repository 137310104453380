import { useMutation, useQueryClient } from "@tanstack/react-query";
import { pricesApi } from "../../../api/apiTanStack";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { CreatePriceDto } from "../../../api-client/generated";

export const useDuplicatePriceMutation = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["DuplicatePriceeMutation"],
    (data: { createPriceDto: CreatePriceDto }) =>
      pricesApi.pricesControllerCreate({
        createPriceDto: data.createPriceDto,
      }),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["getPrices"]);
        toast.success(t(`toastMessages.CreatePriceSuccess`));
      },
      onError: () => {
        toast.error(t(`toastMessages.CreatePriceFailure`));
      },
    }
  );
};
