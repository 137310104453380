import { Box, CircularProgress, Tab, Tabs } from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { BookingStatus } from "../../../api-client/generated";
import {
  changeBookingTabRequest,
  removeBookingBeachChairRightDrawerToggleRequest,
} from "../../../store/reducers/bookingsReducer";
import { getExtrasRequest } from "../../../store/reducers/extrasReducer";
import {
  getBookingTabSelector,
  getRemoveBookingBeachChairRightDrawerSelector,
} from "../../../store/selectors/bookingsSelectors";
import { getExtrasSelector } from "../../../store/selectors/extrasSelectors";
import { customStyle } from "../../../utils/customStyles/customStyles";
import RightDrawer from "../../rightDrawer/rightDrawer";
import TabPanel from "../../tabPanel/tabPanel";
import { BookingBeachChairs } from "./bookingBeachChairs";
import BookingCustomer from "./bookingCustomer/bookingCustomer";
import BookingDetail from "./bookingDetail/bookingDetail";
import BookingDetailHeader from "./bookingDetailHeader/bookingDetailHeader";
import BookingExtras from "./bookingExtras/bookingExtras";
import BookingInvoice from "./bookingInvoice/bookingInvoice";
import BookingPay from "./bookingPay/bookingPay";
import { useGetBooking } from "./hooks";
import RemoveBeachChairBooking from "./removeBeachChairBooking/removeBeachChairBooking";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useGetCurrentUser } from "../../../hooks";
import BookingHistory from "./bookingHistory/bookingHistory";

const a11yProps = (index: any) => {
  return {
    id: `skj-tab-${index}`,
    "aria-controls": `skj-tabpanel-${index}`,
  };
};

const Booking = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const { id, type } = useParams<{ id: string; type: string }>();
  const tab = useSelector(getBookingTabSelector);
  const { booking, isLoading, isFetched } = useGetBooking(Number(id));
  const history = useHistory();
  const { currentUser } = useGetCurrentUser();
  const isAdmin = useMemo(
    () => (currentUser?.roles ?? []).includes("SUPER_ADMIN"),
    [currentUser]
  );

  useEffect(() => {
    if (booking && booking.status === BookingStatus.Open) {
      history.push(`/bookings/${id}/edit`);
    }
  }, [booking, history, id]);

  const storedExtras = useMemo(() => booking?.extras ?? [], [booking]);
  const removeBeachChairRightDrawer = useSelector(
    getRemoveBookingBeachChairRightDrawerSelector
  );

  useEffect(() => {
    dispatch(getExtrasRequest({ itemsPerPage: 0, page: 0 }));
    dispatch(changeBookingTabRequest({ value: 0 }));
  }, [dispatch]);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    dispatch(changeBookingTabRequest({ value: newValue }));
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          position: "relative",
          paddingTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          bottom: 0,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress sx={{ color: customStyle.mainColor }} />
        </Box>
      </Box>
    );
  }

  // TODO: Should redirect to 404 page?
  if (isFetched && !booking) return null;

  return (
    <SBMainListWrapper>
      <RightDrawer
        state={removeBeachChairRightDrawer}
        action={removeBookingBeachChairRightDrawerToggleRequest}
      >
        <RemoveBeachChairBooking id={+id} />
      </RightDrawer>
      <BookingDetailHeader />
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="skj tabs"
        sx={{
          ".MuiTab-root": {
            width: "20%",
            color: customStyle.mainColor,
            fontWeight: "bold",
            borderRight: `2px solid ${customStyle.mainColor}`,
            boxShadow: "none",
            borderTop: `2px solid ${customStyle.mainColor}`,
            borderBottom: `2px solid ${customStyle.mainColor}`,
          },
          ".MuiTabs-indicator": {
            height: "0px",
            backgroundColor: customStyle.secondaryColor,
          },
          ".Mui-selected": {
            color: `${customStyle.whiteColor}!important`,
            backgroundColor: customStyle.mainColor,
          },
          ".MuiButtonBase-root:first-of-type": {
            borderLeft: `2px solid ${customStyle.mainColor}`,
          },
        }}
        data-testid="tabs"
      >
        <Tab
          label={t("submenuBeachChairs")}
          {...a11yProps(0)}
          data-testid="tab-0"
        />
        <Tab
          label={t("submenuExtras")}
          {...a11yProps(1)}
          data-testid="tab-1"
          disabled={storedExtras.length === 0}
          sx={{ display: storedExtras.length === 0 ? "none" : "auto" }}
        />
        <Tab
          label={t("submenuCustomer")}
          {...a11yProps(2)}
          data-testid="tab-2"
        />
        <Tab
          label={t("submenuPayment")}
          {...a11yProps(3)}
          data-testid="tab-3"
        />
        <Tab
          label={t("history")}
          {...a11yProps(4)}
          data-testid="tab-4"
          disabled={!isAdmin}
        />
      </Tabs>
      <Box
        sx={{
          height: "calc(100vh - 5*65px)",
          padding: 0,
          overflowY: "scroll",
          boxSizing: "content-box",
          "&::-webkit-scrollbar": {
            width: "2px",
            height: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: customStyle.scrollColor,
          },
        }}
        data-testid="box"
      >
        <BookingDetail tab={tab} booking={booking} />
        <TabPanel value={tab} index={0}>
          <BookingBeachChairs booking={booking} />
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <BookingExtras bookingId={+id} type={type} booking={booking} />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <BookingCustomer bookingId={+id} type={type} booking={booking} />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <BookingInvoice id={+id} booking={booking} />
          <BookingPay id={+id} type={type} booking={booking} />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <BookingHistory id={+id} />
        </TabPanel>
        <TabPanel value={tab} index={5}></TabPanel>
      </Box>
    </SBMainListWrapper>
  );
};

export default React.memo(Booking);
