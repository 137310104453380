export const SBColor = {
  /**HEX*/
  blue: "#233F91",
  orange: "#ED6B17",
  darkWhite: "#FAFAFA",
  white: "#FFFFFF",
  lightGray: "#F0F0F0",
  gray: "#808080",
  scroll: "#233F91",
  red: "#ff0033",

  green: "#228C22",
  /**RGBA*/
  grayTransparent: "rgba(0, 0, 0, 0.54)",
};

export const SBMainGrid = {
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  display: "grid",
  gridTemplateRows: "70px auto calc(100vh - 310px)",
} as const;

export const SBMainListGrid = {
  display: "grid",
  paddingTop: "20px",
  paddingLeft: "20px",
  paddingRight: "20px",
  bottom: 0,
  gap: "10px",
} as const;

export const SBInsideGrid = {
  display: "grid",
  paddingTop: "20px",
  paddingRight: {
    lg: "20px",
    md: 0,
  },
  maxHeight: "100%",
  overflow: "auto",
  boxSizing: "content-box",
  minHeight: "calc(100vh - 310px)",
  "&::-webkit-scrollbar": {
    width: "2px",
    height: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: SBColor.scroll,
  },
} as const;

export const SBInsideGridWidth = {
  display: "grid",
  gap: "10px",
  gridTemplateColumns: {
    lg: "50% 50%",
    md: "auto",
  },
} as const;

export const SBColumnGridStyle = {
  display: "grid",
  gap: "3px",
  gridAutoRows: "max-content",
} as const;

export const SBLabelStyle = {
  color: SBColor.blue,
  fontWeight: "bold",
} as const;

export const SBTextFieldStyle = {
  "& .MuiInputBase-root": {
    color: SBColor.blue,
    backgroundColor: SBColor.white,
  },
  "& .Mui-disabled": {
    color: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .MuiOutlinedInput-root.Mui-disabled": {
    backgroundColor: SBColor.lightGray,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${SBColor.blue}!important`,
  },
  "& .MuiOutlinedInput-input": {
    padding: "7px 10px",
  },
} as const;

export const SBAutompleteTextFieldStyle = {
  "& .MuiInput-root": {
    padding: "3px 10px",
    backgroundColor: SBColor.white,
  },
  "& .Mui-disabled": {
    backgroundColor: SBColor.lightGray,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },
  "& .MuiOutlinedInput-root": {
    padding: 0,
    paddingLeft: "5px",
    opacity: 1,
  },
  "& input::placeholder": {
    opacity: 1,
  },
} as const;

export const SBDatePickerHeightStyle = {
  height: "30px",
};

export const SBHeaderItemsColumnsStyle = {
  display: "grid",
  gap: "5px",
  gridTemplateColumns: "auto auto auto auto",
  gridTemplateRows: "auto",
};

export const SBDatePickerStyle = {
  borderColor: "white",
  "& .MuiInputBase-root": {
    color: SBColor.blue,
    backgroundColor: SBColor.white,
  },
  "& .MuiFilledInput-input.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
  },

  "& .MuiFilledInput-root.Mui-disabled": {
    backgroundColor: SBColor.lightGray,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .Mui-disabled": {
    backgroundColor: SBColor.lightGray,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: `${SBColor.blue}!important`,
  },

  "& .MuiInputLabel-root": {
    color: `${SBColor.blue}!important`,
  },

  "& .MuiOutlinedInput-input": {
    padding: "7px 10px",
  },
} as const;

export const SBSecondStopSaleDateStyle = {
  gridRowEnd: {
    lg: "auto",
    md: "auto",
    sm: 3,
    xs: 3,
  },
  gridColumnEnd: {
    lg: "auto",
    md: "auto",
    sm: 1,
    xs: 1,
  },
} as const;

export const SBIconButtonStyle = {
  height: "40px",
  width: "40px",
  color: SBColor.white,
  background: SBColor.blue,
  ":hover": {
    background: SBColor.orange,
  },
  "&:buttonDisabled": {
    color: "gray",
  },
} as const;

export const SBStopSaleIconButtonStyle = {
  gridColumnStart: {
    lg: "auto",
    md: "auto",
    sm: 2,
    xs: 2,
  },
  gridColumnEnd: {
    lg: "auto",
    md: "auto",
    sm: 2,
    xs: 2,
  },
  gridRowStart: {
    lg: "auto",
    md: "auto",
    sm: 1,
    xs: 1,
  },
  gridRowEnd: {
    lg: "auto",
    md: "auto",
    sm: 3,
    xs: 3,
  },
} as const;

export const SBSelectStyle = {
  ".MuiSelect-select": {
    color: SBColor.blue,
    backgroundColor: SBColor.white,
  },
  "& .Mui-disabled": {
    color: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
  },
  "& .MuiFilledInput-input.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
    backgroundColor: `${SBColor.lightGray}!important`,
  },
  "& .MuiOutlinedInput-input.Mui-disabled": {
    backgroundColor: `${SBColor.lightGray}!important`,
    color: `${SBColor.blue}!important`,
    WebkitTextFillColor: `${SBColor.blue}!important`,
  },
  "& fieldset": { borderColor: `${SBColor.blue}!important` },

  "& .MuiOutlinedInput-input": {
    padding: "7px 10px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: `${SBColor.blue}!important`,
  },
} as const;

export const SBTextFieldIconStyle = {
  display: "grid",
  alignContent: "center",
  color: SBColor.blue,
} as const;

export const SBTextFieldGridStyle = {
  display: "grid",
  gridTemplateColumns: "30px auto",
  minHeight: "80px",
  maxHeight: "200px",
} as const;

export const SBTextFieldGridLoginStyle = {
  display: "grid",
  minHeight: "80px",
  maxHeight: "200px",
} as const;

export const SBTimeFromToFieldsStyle = {
  display: "grid",
  gridTemplateColumns: "50% 50%",
  alignItems: "center",
} as const;

export const SBCheckBoxGridStyle = {
  display: "grid",
  gridTemplateColumns: "auto",
  paddingLeft: "2px",
  minHeight: "80px",
  maxHeight: "200px",
} as const;

export const SBSelectGridStyle = {
  display: "grid",
  gridTemplateColumns: "30px auto",
  minHeight: "80px",
  maxHeight: "200px",
} as const;

export const SBLabelGridStyle = {
  display: "grid",
  gridTemplateColumns: "30px auto",
  height: "30px",
} as const;

export const SBCheckboxStyle = {
  "&.MuiCheckbox-colorPrimary.Mui-disabled": {
    color: SBColor.blue,
  },
} as const;

export const SBCheckboxBoxLabelStyle = {
  display: "grid",
  gridTemplateColumns: "auto auto",
  gap: "10px",
  justifyContent: "flex-start",
  alignItems: "center",
} as const;

export const SBCheckboxGridStyle = {
  display: "grid",
  gap: "1px",
} as const;

export const SBCircularProgressStyle = {
  height: "15px",
  width: "15px",
  color: SBColor.blue,
} as const;

export const SBFormControlStyle = { height: "50px" } as const;

export const SBFormControlTextAreaStyle = {
  minHeight: "50px",
  maxHeight: "200px",
} as const;

export const SBTextAreaStopSaleDatesAreaEditStyle = {
  justifyItems: "center",
  alignItems: "center",
  display: "grid",
  gap: "10px",
  gridTemplateColumns: {
    lg: "auto auto 60px",
    md: "auto auto 60px",
    sm: "auto 60px",
    xs: "auto 60px",
  },
  gridTemplateRows: {
    lg: "auto",
    md: "auto",
    sm: "auto auto",
    xs: "auto auto",
  },
} as const;

export const SBTextAreaStopSaleDatesAreaShowStyle = {
  justifyItems: "center",
  alignItems: "center",
  display: "grid",
  gap: "10px",
  gridTemplateColumns: {
    lg: "auto auto",
    md: "auto auto",
    sm: "auto",
    xs: "auto",
  },
  gridTemplateRows: {
    lg: "auto",
    md: "auto",
    sm: "auto auto",
    xs: "auto auto",
  },
} as const;

export const SBImageGridStyle = {
  display: "grid",
  paddingBottom: "10px",
  gap: "10px",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 250px));",
} as const;

export const SBImageGridEditStyle = {
  justifyContent: "flex-end",
} as const;

export const SBImageGridShowStyle = {
  justifyContent: "flex-start",
} as const;

export const SBImageCardStyle = {
  position: "relative",
  display: "grid",
  maxWidth: "250px",
  maxHeight: "250px",
  justifyContent: "center",
  justifyItems: "center",
  alignItems: "center",
  alignContent: "center",
  "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
} as const;

export const SBImageCardIconButtonStyle = {
  position: "absolute",
  top: 0,
  right: 0,
  color: SBColor.white,
  background: SBColor.blue,
  ":hover": {
    background: SBColor.orange,
  },
  "&:buttonDisabled": {
    color: "gray",
  },
} as const;

export const SBComponentHeightStyle = {
  height: "40px!important",
  padding: "7px 10px",
};

export const SBLoadingButtonStyle = {
  border: "none",
  height: "40px",
  minWidth: "40px",
  padding: {
    lg: "5px 15px",
    md: "5px 10px",
    sm: "5px 10px",
    xs: "5px 10px",
  },
  ".MuiButton-startIcon": {
    color: SBColor.white,
    marginRight: {
      lg: "8px",
      md: "-3px",
      sm: "-3px",
      xs: "-3px",
    },
  },
  ".MuiSvgIcon-root": {
    alignSelf: "center",
  },
} as const;

export const SBLoadingButtonIconDisabledStyle = {
  ".MuiButton-startIcon": {
    color: SBColor.blue,
    marginRight: {
      lg: "8px",
      md: "-3px",
      sm: "-3px",
      xs: "-3px",
    },
  },
} as const;

export const SBLoadingButtonBlueStyle = {
  background: SBColor.blue,
  ":hover": {
    border: "none",
    background: SBColor.blue,
  },
  "&.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
  },
} as const;

export const SBLoadingButtonOrangeStyle = {
  background: SBColor.orange,
  ":hover": {
    border: "none",
    background: SBColor.orange,
  },
  "&.Mui-disabled": {
    color: `${SBColor.blue}!important`,
    backgroundColor: SBColor.lightGray,
  },
} as const;

export const SBLoadingButtonLabelStyle = {
  fontWeight: "bolder",
  color: SBColor.white,
  display: {
    lg: "grid",
    md: "none",
    sm: "none",
    xs: "none",
  },
} as const;

export const SBLoadingButtonLabelDisabledStyle = {
  fontWeight: "bolder",
  color: SBColor.blue,
  display: {
    lg: "grid",
    md: "none",
    sm: "none",
    xs: "none",
  },
} as const;

export const SBButtonGridStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  justifyContent: "end",
  alignSelf: "end",
  maxWidth: {
    lg: "70%",
    md: "200px",
    sm: "200px",
    xs: "200px",
  },
  marginLeft: "auto",
  marginRight: 0,
} as const;

export const SBImageCard = {
  minHeight: "100%",
  width: "400px",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
  "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
} as const;

export const SBInsideImageCard = {
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
} as const;

export const SBInsideImageCardRow = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "center",
  justifyItems: "center",
} as const;

export const SBHeaderActionButtonsStyle = {
  display: "flex",
  gap: "10px",
  alignContent: "flex-end",
  justifyContent: "flex-end",
  width: "100%",
} as const;

export const SBBreadcrumbStyle = {
  fontFamily: `"NunitoMedium", monospace`,
  gridColumnStart: 1,
  gridColumnEnd: 3,
  gridRow: 2,
  "& > *": {
    color: "black",
  },
  fontSize: {
    lg: "16px",
    md: "16ppx",
    sm: "12px",
    xs: "12px",
  },
};

export const SBLinkStyle = {
  fontFamily: `"NunitoMedium", monospace`,
  color: "gray",
  textDecoration: "none",
  cursor: "pointer",
  fontSize: {
    lg: "16px",
    md: "16ppx",
    sm: "12px",
    xs: "12px",
  },
} as const;

export const SBTypographyLocationStyle = {
  fontFamily: `"NunitoMedium", monospace`,
  fontWeight: "bold",
  fontSize: {
    lg: "16px",
    md: "16ppx",
    sm: "12px",
    xs: "12px",
  },
};

export const SBEndAdornment = {
  fontFamily: `"NunitoMedium", monospace`,
  fontWeight: "bold",
  color: SBColor.blue,
};

export const SBTopToolbarCustomActionsBoxStyle = {
  display: "grid",
  gridTemplateColumns: "auto 25px",
} as const;

export const SBTopToolbarCustomActionsTypographyStyle = {
  fontWeight: "bold",
  minWidth: "50px",
  overflow: "hidden",
  height: "30px",
} as const;

export const SBTopToolbarCustomActionsIconButtonStyle = {
  height: "30px",
} as const;

export const SBBreadcrumbsWrapperStyle = {
  p: "10px",
  border: `1px solid ${SBColor.gray}`,
} as const;

export const SBCardStyle = {
  padding: "10px",
  backgroundColor: "white",
  height: "200px",
  "&:hover": { boxShadow: "0 0 20px rgba(33,33,33,.2)" },
} as const;

export const SBCardTypographyStyle = {
  color: SBColor.gray,
  fontWeight: "bolder",
  fontSize: "16px",
  margin: 0,
} as const;

export const SBCardBoxStyle = {
  display: "grid",
  height: "180px",
  justifyItems: "center",
  alignItems: "center",
} as const;

export const SBCardIconStyle = {
  marginTop: "20px",
  color: SBColor.blue,
} as const;

export const SBReportsCardsStyle = {
  marginTop: "20px",
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 250px));",
  gap: "10px",
  overflowY: "scroll",
  boxSizing: "content-box",
  "&::-webkit-scrollbar": {
    width: "2px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: SBColor.scroll,
  },
} as const;
