import { useCallback, useReducer } from "react";

export type ModalsState = Record<string, boolean>;

interface Action<ModalNames> {
  type: "SHOW_MODAL" | "HIDE_MODAL";
  payload: ModalNames;
}

const modalsReducer = <ModalNames extends string>(
  state: ModalsState,
  action: Action<ModalNames>
): ModalsState => {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        ...state,
        [action.payload]: true,
      };
    case "HIDE_MODAL":
      return {
        ...state,
        [action.payload]: false,
      };
    default:
      console.warn(`Unknown action type: ${action.type}`);
      return state;
  }
};

export const useModals = <ModalNames extends string>(
  initialState: ModalsState = {}
) => {
  const [state, dispatch] = useReducer(modalsReducer, initialState);

  const showModal = useCallback((modalKey: ModalNames) => {
    dispatch({ type: "SHOW_MODAL", payload: modalKey });
    // eslint-disable-next-line
  }, []);

  const hideModal = useCallback((modalKey: ModalNames) => {
    dispatch({ type: "HIDE_MODAL", payload: modalKey });
    // eslint-disable-next-line
  }, []);

  return { state, showModal, hideModal };
};
