import React, { useEffect } from "react";
import { customStyle } from "../../utils/customStyles/customStyles";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LocationHeader from "./locationHeader/locationHeader";
import { getLocationsSelector } from "../../store/selectors/vendorsSelectors";
import {
  getLocationsRequest,
  routerRedirectVendorRequest,
} from "../../store/reducers/vendorsReducer";
import { getVendorChangeContextCompleteSelector } from "../../store/selectors/adminSelectors";
import { Box, Button, Typography } from "@mui/material";

const Locations = () => {
  const dispatch = useDispatch();
  const locations = useSelector(getLocationsSelector);
  const { t } = useTranslation(["common"]);
  const vendorChangeContextComplete = useSelector(
    getVendorChangeContextCompleteSelector
  );
  useEffect(() => {
    locations.length === 0 && dispatch(getLocationsRequest({}));
    // eslint-disable-next-line
  }, [locations, vendorChangeContextComplete]);

  return (
    <Box
      sx={{
        paddingTop: "20px",
        paddingLeft: "20px",
        paddingRight: "20px",
        bottom: 0,
      }}
    >
      <LocationHeader />
      <Box
        sx={{
          height: "48px",
          paddingTop: "15px",
          paddingLeft: "16px",
          fontWeight: "bold",
          backgroundColor: "white",
        }}
        data-testid="header"
      >
        {t("allLocations")}
      </Box>
      <Box
        sx={{
          minHeight: "calc(60vh)",
          maxHeight: "calc(60vh)",
          height: "calc(60vh)",
          paddingTop: "20px",
          paddingLeft: "20px",
          paddingRight: "20px",
          bottom: 0,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 250px));",
          gap: "10px",
          overflowY: "scroll",
          boxSizing: "content-box",
          "&::-webkit-scrollbar": {
            width: "2px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: customStyle.scrollColor,
          },
        }}
        data-testid="locations-content"
      >
        {locations.map((location) => (
          <Box
            sx={{
              padding: "20px",
              backgroundColor: "white",
              width: "80%",
              height: "200px",
              "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
            }}
            data-testid="locations-box"
            key={`locations-${location.id}`}
          >
            <Typography
              sx={{
                color: customStyle.grayColor,
                fontWeight: "bolder",
                fontSize: "15px",
                margin: 0,
              }}
              data-testid="locations-title"
            >
              {location.name}
            </Typography>
            <Box
              sx={{
                display: "grid",
                height: "180px",
                justifyItems: "center",
                alignItems: "center",
              }}
            >
              <Typography
                data-testid="locations-count"
                sx={{
                  fontWeight: "bolder",
                  fontSize: "25px",
                }}
              >
                {location.entranceName}
              </Typography>
              <Button
                sx={{
                  height: "25px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  fontSize: "12px",
                  color: "white",
                  textTransform: "none",
                  backgroundColor: customStyle.mainColor,
                }}
                data-testid="locations-button"
                onClick={() => {
                  dispatch(
                    routerRedirectVendorRequest({
                      path: "/location",
                      id: location.id,
                      type: "edit",
                    })
                  );
                }}
              >
                {t("chooseLocation")}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Locations;
