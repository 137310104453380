import { getImagesOrderSelector } from "./../selectors/destinationSelectors";
import { Action } from "@reduxjs/toolkit";
import {
  all,
  call,
  debounce,
  put,
  select,
  take,
  takeLatest,
} from "typed-redux-saga";
import {
  deleteBeach,
  deleteCity,
  deleteRegion,
  deleteRow,
  deleteSection,
  getBeach,
  getBeaches,
  getCities,
  getCity,
  getDestinationSections,
  getRegion,
  getRegions,
  getSection,
  postBeach,
  postCity,
  postRegion,
  postRow,
  postSection,
  putBeach,
  putCity,
  putRegion,
  putRow,
  putSection,
} from "../../api/destinationApi";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import { getVendors } from "../reducers/authReducer";
import {
  changeRowRequest,
  changeRowSuccess,
  deleteBeachRequest,
  deleteBeachSuccess,
  deleteCityRequest,
  deleteCitySuccess,
  deleteRegionRequest,
  deleteRegionSuccess,
  deleteRowRequest,
  deleteRowSuccess,
  deleteSectionRequest,
  deleteSectionSuccess,
  destinationFailure,
  getBeachesRequest,
  getBeachesSuccess,
  getBeachRequest,
  getBeachSuccess,
  getCitiesRequest,
  getCitiesSuccess,
  getCityRequest,
  getCitySuccess,
  getRegionRequest,
  getRegionsRequest,
  getRegionsSuccess,
  getRegionSuccess,
  getSectionRequest,
  getSectionsRequest,
  getSectionsSuccess,
  getSectionSuccess,
  postBeachRequest,
  postBeachSuccess,
  postCityRequest,
  postCitySuccess,
  postRegionRequest,
  postRegionSuccess,
  postRowRequest,
  postRowSuccess,
  postSectionRequest,
  postSectionSuccess,
  putBeachRequest,
  putBeachSuccess,
  putCityRequest,
  putCitySuccess,
  putRegionRequest,
  putRegionSuccess,
  putSectionRequest,
  putSectionSuccess,
} from "../reducers/destinationReducer";
import { toast } from "react-toastify";
import { routerRequest } from "../reducers/routerReducer";
import i18next from "i18next";

function* getRegionsRequestSaga(action: Action) {
  try {
    if (getRegionsRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getRegions,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getRegionsSuccess({
          destinationRegions: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* getRegionRequestSaga(action: Action) {
  try {
    if (getRegionRequest.match(action)) {
      const { regionId } = action.payload;
      const { data } = yield* call(getRegion, regionId);
      yield* put(
        getRegionSuccess({
          destinationRegion: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* putRegionSaga(action: Action) {
  try {
    if (putRegionRequest.match(action)) {
      const { regionId, formData } = action.payload;
      const { data } = yield* call(putRegion, regionId, formData);
      yield* put(putRegionSuccess({ destinationRegion: data }));
      toast.success(`${i18next.t(`toastMessages.PutRegionSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutRegionFailure`)}`);
  }
}

function* postRegionSaga(action: Action) {
  try {
    if (postRegionRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postRegion, formData);
      yield* put(postRegionSuccess({ destinationRegion: data }));
      toast.success(`${i18next.t(`toastMessages.PostRegionSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostRegionFailure`)}`);
  }
}

function* deleteRegionSaga(action: Action) {
  try {
    if (deleteRegionRequest.match(action)) {
      const { regionId, isList } = action.payload;
      yield* call(deleteRegion, regionId);
      if (!isList) {
        yield* put(routerRequest({ path: "/region" }));
      }
      yield* put(deleteRegionSuccess({ regionId }));
      toast.success(`${i18next.t(`toastMessages.DeleteRegionSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteRegionFailure`)}`);
  }
}

function* getCitiesRequestSaga(action: Action) {
  try {
    if (getCitiesRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getCities,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getCitiesSuccess({
          destinationCities: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* getCityRequestSaga(action: Action) {
  try {
    if (getCityRequest.match(action)) {
      const { cityId } = action.payload;
      const { data } = yield* call(getCity, cityId);
      yield* put(
        getCitySuccess({
          destinationCity: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* putCitySaga(action: Action) {
  try {
    if (putCityRequest.match(action)) {
      const { cityId, formData } = action.payload;
      const { data } = yield* call(putCity, cityId, formData);
      yield* put(putCitySuccess({ destinationCity: data }));
      toast.success(`${i18next.t(`toastMessages.PutCitySuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutCityFailure`)}`);
  }
}

function* postCitySaga(action: Action) {
  try {
    if (postCityRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postCity, formData);
      yield* put(postCitySuccess({ destinationCity: data }));
      toast.success(`${i18next.t(`toastMessages.PostCitySuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostCityFailure`)}`);
  }
}

function* deleteCitySaga(action: Action) {
  try {
    if (deleteCityRequest.match(action)) {
      const { cityId, isList } = action.payload;
      yield* call(deleteCity, cityId);
      if (!isList) {
        yield* put(routerRequest({ path: "/city" }));
      }
      yield* put(deleteCitySuccess({ cityId }));
      toast.success(`${i18next.t(`toastMessages.DeleteCitySuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteCityFailure`)}`);
  }
}

function* getBeachesRequestSaga(action: Action) {
  try {
    if (getBeachesRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getBeaches,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        getBeachesSuccess({
          destinationBeaches: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* getBeachRequestSaga(action: Action) {
  try {
    if (getBeachRequest.match(action)) {
      const { beachId } = action.payload;
      const { data } = yield* call(getBeach, beachId);
      yield* put(
        getBeachSuccess({
          destinationBeach: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* putBeachSaga(action: Action) {
  try {
    if (putBeachRequest.match(action)) {
      const { beachId, formData } = action.payload;
      const { data } = yield* call(putBeach, beachId, formData);
      yield* put(putBeachSuccess({ destinationBeach: data }));
      toast.success(`${i18next.t(`toastMessages.PutBeachSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutBeachFailure`)}`);
  }
}

function* postBeachSaga(action: Action) {
  try {
    if (postBeachRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postBeach, formData);
      yield* put(postBeachSuccess({ destinationBeach: data }));
      toast.success(`${i18next.t(`toastMessages.PostBeachSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostBeachFailure`)}`);
  }
}

function* deleteBeachSaga(action: Action) {
  try {
    if (deleteBeachRequest.match(action)) {
      const { beachId, isList } = action.payload;
      yield* call(deleteBeach, beachId);
      if (!isList) {
        yield* put(routerRequest({ path: "/beach" }));
      }
      yield* put(deleteBeachSuccess({ beachId }));
      toast.success(`${i18next.t(`toastMessages.DeleteBeachSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteBeachFailure`)}`);
  }
}

function* getSectionsRequestSaga(action: Action) {
  try {
    if (getSectionsRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      let vendors = yield* select(getVendors);
      if (vendors.length === 0) {
        yield* take("auth/getVendorsSuccess");
        vendors = yield* select(getVendors);
      }
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getDestinationSections,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      data.items.forEach((section) => {
        section.vendorName =
          vendors.find((vendor) => vendor.id === section.vendorId)?.name || "";
      });
      yield* put(
        getSectionsSuccess({
          destinationSections: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* getSectionRequestSaga(action: Action) {
  try {
    if (getSectionRequest.match(action)) {
      const { sectionId } = action.payload;
      const { data } = yield* call(getSection, sectionId);
      yield* put(
        getSectionSuccess({
          destinationSection: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
  }
}

function* putSectionSaga(action: Action) {
  try {
    if (putSectionRequest.match(action)) {
      const { sectionId, formData } = action.payload;
      const images = yield* select(getImagesOrderSelector);
      if (images.length > 0) {
        formData.images = images;
      }
      const { data } = yield* call(putSection, sectionId, formData);
      yield* put(putSectionSuccess({ destinationSection: data }));
      toast.success(`${i18next.t(`toastMessages.PutSectionSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutSectionFailure`)}`);
  }
}

function* postSectionSaga(action: Action) {
  try {
    if (postSectionRequest.match(action)) {
      const { formData } = action.payload;
      const { data } = yield* call(postSection, formData);
      yield* put(postSectionSuccess({ destinationSection: data }));
      toast.success(`${i18next.t(`toastMessages.PostSectionSuccess`)}`);
      yield* put(routerRequest({ path: "/section" }));
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostSectionFailure`)}`);
  }
}

function* postRowSaga(action: Action) {
  try {
    if (postRowRequest.match(action)) {
      const { formData } = action.payload;
      yield* call(postRow, formData);
      const { data } = yield* call(getSection, formData.sectionId);
      yield* put(postRowSuccess({ destinationSection: data }));
      toast.success(`${i18next.t(`toastMessages.PostRowSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PostRowFailure`)}`);
  }
}

function* deleteSectionSaga(action: Action) {
  try {
    if (deleteSectionRequest.match(action)) {
      const { sectionId, isList } = action.payload;
      yield* call(deleteSection, sectionId);
      if (!isList) {
        yield* put(routerRequest({ path: "/section" }));
      }
      yield* put(deleteSectionSuccess({ sectionId }));
      toast.success(`${i18next.t(`toastMessages.DeleteSectionSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteSectionFailure`)}`);
  }
}

function* changeRowSaga(action: Action) {
  try {
    if (changeRowRequest.match(action)) {
      const { name, id, sectionId } = action.payload;
      yield* call(putRow, id, name);
      yield* put(changeRowSuccess({}));
      yield* put(getSectionRequest({ sectionId }));
      yield* take("destination/getSectionSuccess");
      toast.success(`${i18next.t(`toastMessages.ChangeRowSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.ChangeRowFailure`)}`);
  }
}

function* deleteRowSaga(action: Action) {
  try {
    if (deleteRowRequest.match(action)) {
      const { id, sectionId } = action.payload;
      yield* call(deleteRow, id);
      yield* put(deleteRowSuccess({}));
      yield* put(getSectionRequest({ sectionId }));
      yield* take("destination/getSectionSuccess");
      toast.success(`${i18next.t(`toastMessages.DeleteRowSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      destinationFailure({
        error: e.message,
      })
    );
    toast.error(
      `${i18next.t(`toastMessages.PushLocatDeleteRowFailureionFailure`)}`
    );
  }
}

function* DestinationSaga() {
  yield* all([
    //Regions
    takeLatest(getRegionsRequest.type, getRegionsRequestSaga),
    takeLatest(getRegionRequest.type, getRegionRequestSaga),
    takeLatest(putRegionRequest.type, putRegionSaga),
    takeLatest(postRegionRequest.type, postRegionSaga),
    takeLatest(deleteRegionRequest.type, deleteRegionSaga),
    //Cities
    takeLatest(getCitiesRequest.type, getCitiesRequestSaga),
    takeLatest(getCityRequest.type, getCityRequestSaga),
    takeLatest(putCityRequest.type, putCitySaga),
    takeLatest(postCityRequest.type, postCitySaga),
    takeLatest(deleteCityRequest.type, deleteCitySaga),
    //Beaches
    takeLatest(getBeachesRequest.type, getBeachesRequestSaga),
    takeLatest(getBeachRequest.type, getBeachRequestSaga),
    takeLatest(putBeachRequest.type, putBeachSaga),
    takeLatest(postBeachRequest.type, postBeachSaga),
    takeLatest(deleteBeachRequest.type, deleteBeachSaga),
    //Sections
    takeLatest(getSectionsRequest.type, getSectionsRequestSaga),
    takeLatest(getSectionRequest.type, getSectionRequestSaga),
    takeLatest(putSectionRequest.type, putSectionSaga),
    takeLatest(postSectionRequest.type, postSectionSaga),
    takeLatest(deleteSectionRequest.type, deleteSectionSaga),
    //Row
    takeLatest(postRowRequest.type, postRowSaga),
    takeLatest(deleteRowRequest.type, deleteRowSaga),
    debounce(1000, changeRowRequest.type, changeRowSaga),
  ]);
}

export default DestinationSaga;
