import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getVendorSelector } from "../../store/selectors/vendorsSelectors";
import {
  fetchVendorRequest,
  pushVendorRequest,
} from "../../store/reducers/vendorsReducer";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as ibantools from "ibantools";
import {
  getCurrentUserSelector,
  getVendorByIdSelector,
} from "../../store/selectors/authSelectors";
import { getVendorIdSelector } from "../../store/selectors/adminSelectors";
import {
  getAdminVendorRequest,
  postAdminVendorRequest,
  putAdminVendorRequest,
} from "../../store/reducers/authReducer";
const phoneRegExp =
  /^([\+]?[(]?[0-9]{3}[)]?[-\s\.]?)?[0-9]{3}[-\s\.]?[0-9]{4,6}([0-9]{1,2})?$/;
const whatsappPhoneRegExp =
  /^(whatsapp\:)?[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}([0-9]{1,2})?$/;
export function useVendorForm(id?: number, type?: string) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector);
  const adminvendorId = useSelector(getVendorIdSelector);

  const vendorData = useSelector(
    id ? getVendorByIdSelector(id) : getVendorSelector
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        activeAvailability: yup.boolean(),
        name: yup.string(),
        details: yup.object().shape({
          email: yup.string().email(t("shouldBeEmail")).nullable(true),
          logo: yup.string().url(t("shouldBeUrl")).nullable(true),
          website: yup
            .string()
            .nullable(true)
            .transform((v) => (v === "" ? null : v)),
          telephone: yup
            .string()
            .matches(phoneRegExp, {
              message: t("shouldTelephone"),
              excludeEmptyString: true,
            })
            .nullable(true)
            .transform((v) => (v === "" ? null : v)),
          taxNumber: yup.string().nullable(true),
          vatId: yup.string().nullable(true),
          whatsAppNumber: yup
            .string()
            .matches(whatsappPhoneRegExp, {
              message: t("shouldBeWhatsappTelephone"),
              excludeEmptyString: true,
            })
            .nullable(true)
            .transform((v) => (v === "" ? null : v)),
          bankDetails: yup.object({
            accountHolder: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            bankName: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            bic: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            iban: yup
              .string()
              .test("isIban", t("shouldBeIBAN"), (iban) => {
                return iban ? ibantools.isValidIBAN(iban || "") : true;
              })
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
          }),
        }),
        companyName: yup.string(),
        description: yup.string(),
        address: yup.object().shape({
          zip: yup.string(),
          city: yup.string(),
          street: yup.string(),
          country: yup.string(),
        }),
        publicReference: yup.string().nullable(true),
        allowedHosts: yup
          .array()
          .of(yup.string())
          .nullable(true)
          .transform((v) => (v === "" ? null : v)),
        configuration: yup
          .object()
          .shape({
            beachChairView: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            widgetUrl: yup
              .string()
              .url()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            metabaseUrl: yup
              .string()
              .url()
              .nullable(true)
              .transform((v) => (v === "" ? null : v)),
            stripeAccountId: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            stripeFlow: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            payPalClientId: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            payPalClientSecret: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            SevDeskCustomerId: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            masterKey: yup
              .string()
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            paymentMethods: yup
              .array()
              .of(yup.string())
              .nullable(true)
              .transform((v) => (v === "" ? undefined : v)),
            hideCustomerComment: yup
              .boolean()
              .nullable(true)
              .transform((v) => (v === "" ? false : v)),
            hideOpeningHours: yup
              .boolean()
              .nullable(true)
              .transform((v) => (v === "" ? false : v)),
          })
          .nullable(true),
        terms: yup.object().shape({
          de: yup.string().nullable(),
          en: yup.string().nullable(),
        }),
      }),
    [t]
  );

  const {
    register,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  useEffect(() => {
    if (id === undefined && type === undefined && !vendorData && currentUser) {
      dispatch(
        fetchVendorRequest({
          vendorId: adminvendorId ? adminvendorId : currentUser.vendorId,
        })
      );
    }
    // eslint-disable-next-line
  }, [vendorData, currentUser, id, type]);

  useEffect(() => {
    if (id === undefined && type === undefined && currentUser) {
      dispatch(
        fetchVendorRequest({
          vendorId: adminvendorId ? adminvendorId : currentUser.vendorId,
        })
      );
    }
    // eslint-disable-next-line
  }, [adminvendorId, id, type]);

  useEffect(() => {
    if (!vendorData && type !== "new" && id !== undefined) {
      dispatch(getAdminVendorRequest({ vendorId: id }));
    }
    // eslint-disable-next-line
  }, [vendorData, id, type]);

  const onSubmit = useCallback(
    (formValues: any) => {
      const { publicReference, ...newFormValues } = formValues;
      if (id) {
        dispatch(
          putAdminVendorRequest({
            vendorId: id,
            formData: newFormValues,
          })
        );
      } else {
        if (type === "new") {
          (newFormValues as any).sectionOrder = [];
          (newFormValues as any).foodProvider = false;
          dispatch(postAdminVendorRequest({ formData: newFormValues }));
        }
        if (type === undefined && currentUser) {
          dispatch(
            putAdminVendorRequest({
              vendorId: adminvendorId ? adminvendorId : currentUser.vendorId,
              formData: newFormValues,
            })
          );
        }
      }
    },
    // eslint-disable-next-line
    [currentUser, adminvendorId, id, type]
  );

  useEffect(() => {
    if (vendorData) {
      setValue("name", vendorData.name || "");
      setValue("description", vendorData.description || "");
      setValue("companyName", vendorData.companyName || "");
      setValue("details.email", vendorData.details?.email || "");
      setValue("details.telephone", vendorData.details?.telephone || "");
      setValue("details.logo", vendorData.details?.logo || "");
      setValue("details.taxNumber", vendorData.details?.taxNumber || "");
      setValue("details.vatId", vendorData.details?.vatId || "");
      setValue("details.website", vendorData.details?.website || "");
      setValue(
        "details.smallBusiness",
        vendorData.details?.smallBusiness || false
      );
      setValue("address.zip", vendorData.address?.zip || "");
      setValue("address.city", vendorData.address?.city || "");
      setValue("address.street", vendorData.address?.street || "");
      setValue("address.country", vendorData.address?.country || "");
      setValue("activeAvailability", vendorData.activeAvailability || false);
      setValue(
        "details.bankDetails.bic",
        vendorData.details.bankDetails?.bic || ""
      );
      setValue(
        "details.bankDetails.iban",
        vendorData.details.bankDetails?.iban || ""
      );
      setValue(
        "details.bankDetails.bankName",
        vendorData.details.bankDetails?.bankName || ""
      );
      setValue(
        "details.bankDetails.accountHolder",
        vendorData.details.bankDetails?.accountHolder || ""
      );
      setValue("terms.de", vendorData.terms?.de || "");
      setValue("terms.en", vendorData.terms?.en || "");
      setValue("allowedHosts", vendorData.allowedHosts);
      setValue("configuration", {
        beachChairView: vendorData.configuration?.beachChairView || "grid",
        paymentMethods: vendorData.configuration?.paymentMethods || [],
        payPalClientId: vendorData.configuration?.payPalClientId || "",
        payPalClientSecret: vendorData.configuration?.payPalClientSecret || "",
        SevDeskCustomerId: vendorData.configuration?.SevDeskCustomerId || "",
        stripeAccountId: vendorData.configuration?.stripeAccountId || "",
        stripeFlow: vendorData.configuration?.stripeFlow || "",
        widgetUrl: vendorData.configuration?.widgetUrl || "",
        metabaseUrl: vendorData.configuration?.metabaseUrl || "",
        masterKey: vendorData.configuration?.masterKey || "",
        hideCustomerComment:
          vendorData.configuration?.hideCustomerComment || false,
        hideOpeningHours: vendorData.configuration?.hideOpeningHours || false,
      });
    }
    // eslint-disable-next-line
  }, [vendorData]);

  useEffect(() => {
    if (vendorData && id && type) {
      setValue("publicReference", vendorData.publicReference);
      setValue("allowedHosts", vendorData.allowedHosts);
      setValue("details.whatsAppNumber", vendorData.details?.whatsAppNumber);
      setValue("configuration", {
        beachChairView: vendorData.configuration?.beachChairView || "grid",
        paymentMethods: vendorData.configuration?.paymentMethods || [],
        payPalClientId: vendorData.configuration?.payPalClientId || "",
        payPalClientSecret: vendorData.configuration?.payPalClientSecret || "",
        SevDeskCustomerId: vendorData.configuration?.SevDeskCustomerId || "",
        stripeAccountId: vendorData.configuration?.stripeAccountId || "",
        stripeFlow: vendorData.configuration?.stripeFlow || "",
        widgetUrl: vendorData.configuration?.widgetUrl || "",
        metabaseUrl: vendorData.configuration?.metabaseUrl || "",
        masterKey: vendorData.configuration?.masterKey || "",
        hideCustomerComment:
          vendorData.configuration?.hideCustomerComment || false,
        hideOpeningHours: vendorData.configuration?.hideOpeningHours || false,
      });
      setValue(
        "configuration.stripeFlow",
        vendorData.configuration?.stripeFlow || "DESTINATION"
      );
      setValue(
        "configuration.paymentMethods",
        vendorData.configuration?.paymentMethods || [
          "CC",
          "STRIPE_BANK_TRANSFER",
          "STRIPE_DIRECT_DEBIT",
          "STRIPE_SOFORT",
          "STRIPE_CREDIT_CARD",
        ]
      );
      setValue("details.logo", vendorData?.details?.logo || "");
      setValue("details.vatId", vendorData.details?.vatId || "");
    }
    // eslint-disable-next-line
  }, [vendorData, id, type]);

  const handleReset = useCallback(() => {
    setValue("name", "");
    setValue("description", "");
    setValue("companyName", "");
    setValue("details.email", "");
    setValue("details.telephone", "");
    setValue("details.logo", "");
    setValue("details.taxNumber", "");
    setValue("details.vatId", "");
    setValue("details.website", "");
    setValue("details.smallBusiness", false);
    setValue("address.zip", "");
    setValue("address.city", "");
    setValue("address.street", "");
    setValue("address.country", "");
    setValue("activeAvailability", false);
    setValue("details.bankDetails.bic", "");
    setValue("details.bankDetails.iban", "");
    setValue("details.bankDetails.bankName", "");
    setValue("details.bankDetails.accountHolder", "");
    if (id && type) {
      setValue("details.whatsAppNumber", "");
      setValue("details.logo", "");
      setValue("allowedHosts", []);
      setValue("configuration", {
        beachChairView: "grid",
        paymentMethods: [
          "CC",
          "STRIPE_BANK_TRANSFER",
          "STRIPE_DIRECT_DEBIT",
          "STRIPE_SOFORT",
          "STRIPE_CREDIT_CARD",
        ],
        payPalClientId: "",
        payPalClientSecret: "",
        SevDeskCustomerId: "",
        stripeAccountId: "",
        stripeFlow: "DESTINATION",
        widgetUrl: "",
        metabaseUrl: "",
        masterKey: "",
        hideCustomerComment: false,
        hideOpeningHours: false,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (type === "new") {
      setValue("name", "");
      setValue("description", "");
      setValue("companyName", "");
      setValue("details.email", "");
      setValue("details.telephone", "");
      setValue("details.logo", "");
      setValue("details.taxNumber", "");
      setValue("details.vatId", "");
      setValue("details.website", "");
      setValue("details.smallBusiness", false);
      setValue("address.zip", "");
      setValue("address.city", "");
      setValue("address.street", "");
      setValue("address.country", "");
      setValue("activeAvailability", false);
      setValue("details.bankDetails.bic", "");
      setValue("details.bankDetails.iban", "");
      setValue("details.bankDetails.bankName", "");
      setValue("details.bankDetails.accountHolder", "");
      setValue("publicReference", "");
      setValue("allowedHosts", []);
      setValue("details.logo", "");
      setValue("configuration", {
        beachChairView: "grid",
        paymentMethods: [
          "CC",
          "STRIPE_BANK_TRANSFER",
          "STRIPE_DIRECT_DEBIT",
          "STRIPE_SOFORT",
          "STRIPE_CREDIT_CARD",
        ],
        payPalClientId: "",
        payPalClientSecret: "",
        SevDeskCustomerId: "",
        stripeAccountId: "",
        stripeFlow: "DESTINATION",
        widgetUrl: "",
        metabaseUrl: "",
        masterKey: "",
        hideCustomerComment: false,
        hideOpeningHours: false,
      });
      setValue("terms.de", "");
      setValue("terms.en", "");
    }
    // eslint-disable-next-line
  }, [type]);

  const watchLogo = watch("details.logo");

  return {
    register,
    control,
    errors,
    watchLogo,
    setValue,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit),
  };
}
