import React, { useMemo, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SBTable from "../../SBTable/SBTable";
import { useHistory } from "react-router";
import { useDeleteCustomer, useGetCustomers } from "../hooks";
import { CustomerHeader } from "../components";
import SBTopToolbarCustomActions from "../../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../../SBComponents/SBLayout/SBMainListWrapper";
import { useSBTablePersists } from "../../../utils/customHooks/useSBTablePersists";
import { useSelector } from "react-redux";
import { getVendorIdSelector } from "../../../store/selectors/adminSelectors";
import SBRedirectLabel from "../../SBComponents/SBLayout/SBRedirectLabel";

const CustomerList = () => {
  const { t } = useTranslation(["common"]);
  const history = useHistory();
  const deleteCustomer = useDeleteCustomer();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogId, setDeleteDialogId] = useState<number>(0);
  const openDeleteCustomerModal = (id: number) => {
    setDeleteDialogOpen(true);
    setDeleteDialogId(id);
  };
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("CustomerList");
  const [globalFilter, setGlobalFilter] = useState("");
  const selectedVendorId = useSelector(getVendorIdSelector);
  const { data, isError, isFetching, isLoading, refetch } = useGetCustomers(
    columnFilters,
    globalFilter,
    pagination,
    sorting,
    selectedVendorId
  );

  const handleDeleteCustomer = (id: number) => {
    deleteCustomer.mutateAsync({ id: id }).finally(() => {
      setDeleteDialogOpen(false);
    });
  };

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `ID`,
        maxSize: 20,
      },
      {
        accessorKey: "firstName",
        header: `${t("firstname")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/customers/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue} />
          </Box>
        ),
      },
      {
        accessorKey: "lastName",
        header: `${t("lastname")}`,
        maxSize: 60,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/customers/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue} />
          </Box>
        ),
      },
      {
        accessorKey: "email",
        header: `${t("email")}`,
        maxSize: 100,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                history.push(`/customers/${row?.row?.original?.id}/show`);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue} />
          </Box>
        ),
      },
      {
        accessorKey: "zip",
        header: `${t("zip")}`,
        accessorFn: (originalRow: any) => originalRow?.address?.zip,
        maxSize: 60,
      },
      {
        accessorKey: "city",
        header: `${t("city")}`,
        accessorFn: (originalRow: any) => originalRow?.address?.city,
        maxSize: 60,
      },
    ];
  }, [t]);

  const renderTopToolbarCustomActions = React.useCallback(
    () => (
      <SBTopToolbarCustomActions
        isLoading={isFetching}
        label={t("allCustomers")}
        onClick={() => {
          refetch();
        }}
      />
    ),
    [refetch, t, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const customerId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (customerId) {
                  history.push(`/customers/${customerId}/show`);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (customerId) {
                  history.push(`/customers/${customerId}/edit`);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (customerId) {
                  openDeleteCustomerModal(customerId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    [data, history]
  );

  return (
    <SBMainListWrapper>
      <CustomerHeader />
      <SBTable
        columnVisibilityStoreName="CustomerListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="customer"
          open={deleteDialogOpen}
          selectedValue={deleteDialogId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteDialogId) {
              handleDeleteCustomer(deleteDialogId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default CustomerList;
