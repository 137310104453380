import {
  IClosingReport,
  IGeneratedReport,
} from "../models/reports/IClosingReport";
import { IReportKeyStatus } from "../models/reports/IReport";
import API from "./api";

const BASE_URL = "features/booking_details/beach_chair_bookings";
const FEATURE_BASE_URL = "features/reports";

const FEATURE_DOCUMENTS_URL = "features/documents";

export interface IReportKeyStatusRequest {
  count: number;
  items: IReportKeyStatus[];
}

export interface IClosingReportKeyStatusRequest {
  count: number;
  items: IGeneratedReport[];
}

export const getReportKeyStatus = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IReportKeyStatusRequest>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const changeReportKeyStatus = (id: number, data: any) => {
  return API.put<IReportKeyStatus>(`${BASE_URL}/${id}`, data);
};

export const putClosingReports = (
  startDate: string,
  endDate: string,
  reportType: string,
  lang: string,
  userId?: string
) => {
  return API.post<IClosingReport>(
    `${FEATURE_BASE_URL}?startDate=${startDate}&endDate=${endDate}&reportType=${reportType}${
      reportType === "CASH_BALANCE" ? `&userReport=${userId}` : ``
    }`,
    {},
    { headers: { "x-custom-lang": lang } }
  );
};

export const putBeachChairReports = (
  startDate: string,
  endDate: string,
  reportType: string,
  lang: string,
  sortBy: string,
  createdArPeriod: boolean
) => {
  return API.post<IClosingReport>(
    `${FEATURE_BASE_URL}?startDate=${startDate}&endDate=${endDate}&reportType=${reportType}&sortBy=${sortBy}&createdAtPeriod=${createdArPeriod}`,
    {},
    { headers: { "x-custom-lang": lang } }
  );
};

export const getReports = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IClosingReportKeyStatusRequest>(
    `${FEATURE_DOCUMENTS_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );
