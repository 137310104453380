import { useVendorExtraTextsForm } from "../../utils/customHooks/useVendorExtraTextsForm";
import { useTranslation } from "react-i18next";
import ExtraTextHeader from "./extraTextHeader/extraTextHeader";
import MailIcon from "@mui/icons-material/Mail";
import CancelIcon from "@mui/icons-material/Cancel";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { Box } from "@mui/material";
import SBMainComponentGridWrapper from "../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBColumnGridWrapper from "../SBComponents/SBForms/SBColumnGridWrapper";
import SBTextArea from "../SBComponents/SBForms/SBTextArea";
import { SBButtonGridStyle } from "../SBComponents/styles/SBStyles";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import { Save } from "@mui/icons-material";

const ExtraText = () => {
  const { t } = useTranslation(["common"]);
  const { control, onSubmit, errors } = useVendorExtraTextsForm();

  return (
    <SBMainComponentGridWrapper>
      <ExtraTextHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBColumnGridWrapper>
          <SBTextArea
            name="extraText.emailText"
            label={t("extraText.emailText")}
            control={control}
            type={"edit"}
            icon={<MailIcon />}
            isLoading={false}
            error={
              errors?.extraText &&
              (errors.extraText as any)?.emailText &&
              t((errors.extraText as any)?.emailText?.message)
            }
          />
          <SBTextArea
            name="extraText.invoiceText"
            label={t("extraText.invoiceText")}
            control={control}
            type={"edit"}
            icon={<CancelIcon />}
            isLoading={false}
            error={
              errors?.extraText &&
              (errors.extraText as any)?.invoiceText &&
              t((errors.extraText as any)?.invoiceText?.message)
            }
          />
          <SBTextArea
            name="extraText.cancellationText"
            label={t("extraText.cancellationText")}
            control={control}
            type={"edit"}
            icon={<ReceiptIcon />}
            isLoading={false}
            error={
              errors?.extraText &&
              (errors.extraText as any)?.cancellationText &&
              t((errors.extraText as any)?.cancellationText?.message)
            }
          />
        </SBColumnGridWrapper>
        <Box
          sx={{
            ...SBButtonGridStyle,
          }}
        >
          <SBLoadingButton
            label={t("save")}
            type="blue"
            icon={<Save />}
            isLoading={false}
          />
        </Box>
      </form>
    </SBMainComponentGridWrapper>
  );
};

export default ExtraText;
