import React, { useState } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "../../utils/i18n/i18n";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { SBTextFieldStyle } from "../SBComponents/styles/SBStyles";

export interface IDialogProps {
  open: boolean;
  namespace: string;
  selectedValue?: number | string;
  onClose: (id?: number | string) => void;
  onAccept: (id?: number | string) => void;
}

const DeleteDialog = ({ open, onClose, onAccept, namespace }: IDialogProps) => {
  const { t } = useTranslation(["common"]);
  const [check, setCheck] = useState("");

  return (
    <I18nextProvider i18n={i18n}>
      <Dialog
        disableEscapeKeyDown
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          {t(`deleteDialog.${namespace}.deleteDialogTitle`)}
        </DialogTitle>
        <DialogContent
          dividers
          sx={{
            height: "140px",
            display: "grid",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>
            {t(`deleteDialog.${namespace}.deleteDialogContent`)}
          </Typography>
          <Box sx={{ padding: "20px" }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {t(`deleteCheck`)}
            </Typography>
            <TextField
              variant="outlined"
              label=""
              sx={{
                ...SBTextFieldStyle,
              }}
              fullWidth
              value={check}
              onChange={(a) => {
                setCheck(a.target.value);
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              onClose();
            }}
            color="primary"
          >
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              onAccept();
            }}
            color="primary"
            disabled={check !== "LÖSCHEN"}
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </I18nextProvider>
  );
};

export default DeleteDialog;
