import { useQuery } from "@tanstack/react-query";
import { BookingSearchResponseDto } from "../../../api-client/generated";
import axios, { AxiosError } from "axios";
import { bookingsApi } from "../../../api/apiTanStack";
import { ReactQueryRefetch } from "../../../types";

// It looks like the generated BookingSearchResponseDto is missing the firstName and lastName fields.
export interface BookingSearchItem extends BookingSearchResponseDto {
  firstName: string;
  lastName: string;
}

export interface UseSearchBookings {
  bookings: BookingSearchItem[];
  isFetching: boolean;
  refetch: ReactQueryRefetch<BookingSearchItem[]>;
}

export const useSearchBookings = (searchPhrase: string = ""): UseSearchBookings => {
  const {
    data,
    isFetching,
    refetch
  } = useQuery<BookingSearchItem[], AxiosError>(
    ["searchBookings", searchPhrase],
    ({ signal }) => {
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      signal?.addEventListener("abort", () => {
        source.cancel();
      });

      return bookingsApi
        .bookingsControllerSearch(
          { search: searchPhrase },
          { cancelToken: source.token }
        )
        .then(({ data }) => data as BookingSearchItem[]);
    },
    {
      enabled: !!searchPhrase
    }
  );

  return {
    bookings: data || [],
    isFetching,
    refetch
  };
};
