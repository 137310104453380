import { IFiltersLock, ILock } from "../../models/lock/ILock";
import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import { AppState } from "./rootReducer";
import {
  DeleteLockRequestPayload,
  DeleteLockSuccessPayload,
  GetLockRequestPayload,
  GetLockSuccessPayload,
  LocksFailurePayload,
  PostLockRequestPayload,
  PostLockSuccessPayload,
  PutLockRequestPayload,
  PutLockSuccessPayload,
} from "../actionPayload/lockPayloads";
import { IFilterData } from "../../models/dataTable/IFilterData";

interface IPage {
  key: string;
  dataIds: number[];
}

const locksAdapter = createEntityAdapter<ILock>({
  selectId: (lock) => lock.id,
});

const pageAdapter = createEntityAdapter<IPage>({
  selectId: (page: IPage) => page.key,
  sortComparer: false,
});

interface ILockState {
  pending: boolean;
  error: string;
  locks: EntityState<ILock>;
  page: EntityState<IPage>;
  filterLocksData: IFilterData[];
  filterLocks?: IFiltersLock;
  locksCount: number;
}

const INITIAL_STATE: ILockState = {
  pending: false,
  error: "",
  locks: locksAdapter.getInitialState({}),
  page: pageAdapter.getInitialState({}),
  filterLocksData: [],
  filterLocks: undefined,
  locksCount: 0,
};

const lockSlice = createSlice({
  name: "locks",
  initialState: INITIAL_STATE,
  reducers: {
    getLockRequest(state, action: PayloadAction<GetLockRequestPayload>) {
      state.pending = true;
    },
    getLockSuccess(state, action: PayloadAction<GetLockSuccessPayload>) {
      state.pending = false;
      locksAdapter.upsertOne(state.locks, action.payload.lock);
    },
    postLockRequest(state, action: PayloadAction<PostLockRequestPayload>) {
      state.pending = true;
    },
    postLockSuccess(state, action: PayloadAction<PostLockSuccessPayload>) {
      state.pending = false;
      locksAdapter.upsertOne(state.locks, action.payload.lock);
      pageAdapter.removeAll(state.page);
    },
    putLockRequest(state, action: PayloadAction<PutLockRequestPayload>) {
      state.pending = true;
    },
    putLockSuccess(state, action: PayloadAction<PutLockSuccessPayload>) {
      state.pending = false;
      locksAdapter.updateOne(state.locks, {
        id: action.payload.lock.id,
        changes: action.payload.lock,
      });
    },
    deleteLockRequest(state, action: PayloadAction<DeleteLockRequestPayload>) {
      state.pending = true;
    },
    deleteLockSuccess(state, action: PayloadAction<DeleteLockSuccessPayload>) {
      state.pending = false;
      pageAdapter.removeAll(state.page);
    },
    locksFailure(state, action: PayloadAction<LocksFailurePayload>) {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  selectAll: selectLocks,
  selectById: selectLockById,
} = locksAdapter.getSelectors<AppState>((state) => state.locks.locks);

export const {
  selectAll: getLocksPages,
  selectById: getLockPages,
} = pageAdapter.getSelectors<AppState>((state) => state.locks.page);

export const {
  getLockRequest,
  getLockSuccess,
  postLockRequest,
  postLockSuccess,
  putLockRequest,
  putLockSuccess,
  deleteLockRequest,
  deleteLockSuccess,
  locksFailure,
} = lockSlice.actions;

export default lockSlice.reducer;
