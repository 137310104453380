import { ILock } from "../models/lock/ILock";
import API from "./api";

const BASE_URL = `/bookings/locks`;

export interface IResponse<T> {
  count: number;
  items: T[];
}

/**
 * Locks API CRUD
 */

export const getLocks = (
  itemsPerPage: number,
  page = 0,
  filter?: string,
  orderBy?: string,
  orderDir?: string
) =>
  API.get<IResponse<ILock>>(
    `${BASE_URL}?page=${page}&itemsPerPage=${itemsPerPage}${
      filter ? `&${filter}` : ``
    }${
      orderBy
        ? `&orderBy=${orderBy}&orderDir=${orderDir?.toLocaleUpperCase()}`
        : ``
    }`
  );

export const getLock = (id: number) =>
  API.get<ILock>(`${BASE_URL}/${id}`);

export const putLock = (id: number, data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.put<ILock>(`${BASE_URL}/${id}`, data, config);
};

export const postLock = (data: any) => {
  const config = { headers: { "Content-Type": "application/json" } };
  return API.post<ILock>(`${BASE_URL}`, data, config);
};

export const deleteLock = (id: number) =>
  API.delete(`${BASE_URL}/${id}`);
