import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(backend)
  .use(HttpApi)
  .use(LanguageDetector)
  .init(
    {
      ns: ["common"],
      defaultNS: "common",
      interpolation: {
        escapeValue: false,
      },
      supportedLngs: ["en", "de"],
    },
    (err, t) => {}
  );

export default i18next;
