import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  getCurrentUserSelector,
  getVendorsSelector,
} from "../../store/selectors/authSelectors";
import { getVendorsRequest } from "../../store/reducers/authReducer";

export function useAdminChangeVendor() {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUserSelector);
  const vendors = useSelector(getVendorsSelector);

  React.useEffect(() => {
    vendors.length === 0 && dispatch(getVendorsRequest({}));
    // eslint-disable-next-line
  }, [vendors]);

  return {
    vendorId: currentUser?.vendorId,
    vendors,
  };
}
