import React from "react";
import { MenuItem, Select } from "@mui/material";
import i18next from "i18next";
import ReactCountryFlag from "react-country-flag";
import { SBColor } from "../SBComponents/styles/SBStyles";
import { autocomplete } from "../../utils/customStyles/globalStyles";

enum Language {
  EN = "en",
  DE = "de",
}
const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = React.useState<Language>(
    () => {
      const localData = localStorage.getItem("i18nextLng");
      return localData ? (localData as Language) : Language.DE;
    }
  );

  const handleChange = (event) => {
    setSelectedLanguage(event.target.value);
    i18next.changeLanguage(event.target.value);
  };

  return (
    <Select
      value={selectedLanguage}
      onChange={handleChange}
      defaultValue={selectedLanguage}
      variant="outlined"
      sx={{
        "& .MuiOutlinedInput-notchedOutline": {
          color: SBColor.white,
          borderColor: SBColor.white,
        },
        ":hover .MuiOutlinedInput-notchedOutline": {
          color: SBColor.white,
          borderColor: SBColor.white,
        },
        "& .MuiSelect-icon": {
          color: SBColor.blue,
        },
      }}
    >
      <MenuItem value={Language.DE}>
        <ReactCountryFlag countryCode="DE" svg />
      </MenuItem>
      <MenuItem value={Language.EN}>
        <ReactCountryFlag countryCode="US" svg />
      </MenuItem>
    </Select>
  );
};

export default LanguageSelector;
