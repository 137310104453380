import { useMutation } from "@tanstack/react-query";
import { AuthState, useAuthStore } from "./authStore";
import { useDispatch } from "react-redux";
import { featuresApi } from "../../../api/apiTanStack";
import { getCurrentUserRequest } from "../../../store/reducers/authReducer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const useLoginMutation = () => {
  const { actions } = useAuthStore();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation(
    ["authMutation"],
    (data: {
      email: string;
      password: string;
      stayLoggedIn: boolean;
      newPassword?: string;
    }) =>
      featuresApi
        .usersControllerAuthenticateWithRefresh({
          userCredentialsDto: {
            email: String(data.email),
            password: String(data.password),
            newPassword: data.newPassword
              ? String(data.newPassword)
              : undefined,
          },
        })
        .then((response) => response.data),
    {
      onSuccess(data, variables) {
        actions.setAccessToken(
          data.jwt,
          variables.stayLoggedIn,
          variables.email
        );
        actions.setRefreshToken(data.refresh, variables.stayLoggedIn);
        dispatch(getCurrentUserRequest({}));
      },
      onError(error: any, variables) {
        if (error?.response?.data?.error === "NewPasswordRequired") {
          actions.setEmail(variables.email);
          actions.setOldPassword(variables.password);
          actions.setAuthState(AuthState.FIRST_LOG_IN);
        } else {
          actions.clearTokens();
          toast.error(t("email or password is invalid"));
        }
      },
    }
  );
};

export const usePasswordReset = () => {
  const { actions } = useAuthStore();
  const { t } = useTranslation();
  return useMutation(
    ["usePasswordReset"],
    (data: { email: string }) =>
      featuresApi
        .usersControllerResetPassword({
          resendConfirmRegistrationDto: {
            email: String(data.email),
          },
        })
        .then((response) => response.data),
    {
      onSuccess(_, variables) {
        toast.success(t("instructions with code was send to your email"));
        actions.setEmail(variables.email);
        actions.setAuthState(AuthState.FORGET);
      },
      onError() {
        toast.error(t("email is invalid"));
      },
    }
  );
};

export const usePasswordResetConfirm = () => {
  const { actions } = useAuthStore();
  const { t } = useTranslation();
  return useMutation(
    ["usePasswordResetConfirm"],
    (data: { email: string; password: string; code: string }) =>
      featuresApi
        .usersControllerConfirmPassword({
          confirmPasswordDto: {
            email: String(data.email),
            password: String(data.password),
            code: String(data.code),
          },
        })
        .then((response) => response.data),
    {
      onSuccess(_, variables) {
        toast.success(t("password set properly"));
        actions.setEmail(variables.email);
        actions.setAuthState(AuthState.LOGIN);
      },
      onError() {
        toast.error(t("code or password is invalid"));
      },
    }
  );
};
