import { LoadingButton } from "@mui/lab";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Stack,
  Chip,
  ButtonGroup,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import { useModals } from "../../../hooks";
import { formatPrice } from "../../../utils";
import { useAdminChangeVendor } from "../../../utils/customHooks/useAdminChangeVendor";
import { useGetVendor } from "../../vendor";
import { useGetCashPointBalance, useOpenCashPoint } from "../hooks/";
import { DepositModal, WithdrawModal } from "./CashOperationModal";
import { ConfirmCloseCashpointModal } from "./ConfirmCloseCashpointModal";
import CashPointBalanceHeader from "./CashPointBalanceHeader";
import { componentRoot } from "../../../utils/customStyles/globalStyles";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";

enum FiskalyCashPointClosingState {
  PENDING = "PENDING",
  WORKING = "WORKING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  DELETED = "DELETED",
  ERROR = "ERROR",
}

enum FiskalyCashRegisterState {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  CLOSING = "CLOSING",
}

enum CashPointModals {
  WITHDRAW_MODAL = "WITHDRAW_MODAL",
  DEPOSIT_MODAL = "DEPOSIT_MODAL",
  CLOSE_CASHPOINT = "CLOSE_CASHPOINT",
}

export const CashPointBalance: React.FC = () => {
  const { locationId } = useParams<{ locationId?: string }>();
  const queryClient = useQueryClient();

  const {
    state: modalState,
    showModal,
    hideModal,
  } = useModals<CashPointModals>({
    WITHDRAW_MODAL: false,
    DEPOSIT_MODAL: false,
    CLOSE_CASHPOINT: false,
  });

  const { t } = useTranslation(["common"]);

  const { vendorId } = useAdminChangeVendor();
  const { data: vendor, isFetching: isVendorLoading } = useGetVendor(vendorId);

  const { cashPointBalance, isFetching, isFetched } =
    useGetCashPointBalance(locationId);

  const { openCashPoint, isLoading: isOpeningCashPoint } = useOpenCashPoint();

  const isOpen =
    cashPointBalance?.registerState === FiskalyCashRegisterState.OPEN;

  const hasAccess = vendor?.configuration?.hasTSE;

  if (isFetched && !hasAccess) {
    return <Redirect to="/no-access" />;
  }

  const showCloseCashPointButton = [
    FiskalyCashRegisterState.OPEN,
    FiskalyCashRegisterState.CLOSING,
  ].includes(
    (cashPointBalance?.registerState || "") as FiskalyCashRegisterState
  );

  const handleOpenCashPoint = () => {
    openCashPoint(
      { locationId: Number(locationId) },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([
            "cashPointBalance",
            Number(locationId),
          ]);
        },
      }
    );
  };

  return (
    <SBMainComponentGridWrapper>
      <CashPointBalanceHeader />
      <Box sx={{ justifyContent: "center", width: "50%" }}>
        {(isFetching || isVendorLoading) && <CircularProgress />}
        {!isFetching && hasAccess && (
          <Box
            sx={{
              padding: 10,
              backgroundColor: "white",
              width: "15vw",
              height: "25vh",
              "&:hover": { boxShadow: "0 0 11px rgba(33,33,33,.2)" },
            }}
          >
            <Stack alignItems="center" spacing={4}>
              <Chip
                label={t(`cashPointStates.${cashPointBalance?.registerState}`)}
                color={isOpen ? "success" : "primary"}
                variant={isOpen ? "filled" : "outlined"}
              />

              <Box>
                <Typography fontWeight="bold">
                  {t("cashPointMenu.cashPointBalance")}
                </Typography>
                <Typography fontWeight="bold" sx={{ textAlign: "center" }}>
                  {formatPrice(cashPointBalance?.cashBalance || 0)}
                </Typography>
              </Box>
              {showCloseCashPointButton && (
                <Button
                  onClick={() => showModal(CashPointModals.CLOSE_CASHPOINT)}
                  variant="contained"
                >
                  {t("cashPointButtons.CLOSE_CASH_POINT")}
                </Button>
              )}
              {cashPointBalance?.registerState ===
                FiskalyCashRegisterState.CLOSED && (
                  <LoadingButton
                    loading={isOpeningCashPoint}
                    onClick={handleOpenCashPoint}
                    variant="contained"
                  >
                    {t("cashPointButtons.OPEN_CASH_POINT")}
                  </LoadingButton>
                )}

              <Box>
                  {cashPointBalance?.registerState ===
                    FiskalyCashRegisterState.OPEN && (
                      <Box sx={{display: "flex", gap: "10px"}}>
                        
                        <LoadingButton
                    loading={isOpeningCashPoint}
                    onClick={() =>
                      showModal(CashPointModals.WITHDRAW_MODAL)
                    }
                    variant="contained"
                  >
                    {t("cashPointButtons.CASH_POINT_DATA_WITHDRAW")}
                  </LoadingButton>
                        <LoadingButton
                    loading={isOpeningCashPoint}
                          onClick={() => showModal(CashPointModals.DEPOSIT_MODAL)}
                          variant="contained"
                        >
                          {t("cashPointButtons.CASH_POINT_DATA_DEPOSIT")}
                        </LoadingButton>
                      </Box>
                    )}
              </Box>
            </Stack>
          </Box>
        )}

        {/* Closing cashpoint confirmation modal */}
        <ConfirmCloseCashpointModal
          isOpen={modalState[CashPointModals.CLOSE_CASHPOINT]}
          onClose={() => hideModal(CashPointModals.CLOSE_CASHPOINT)}
          isLoading={
            cashPointBalance?.registerState ===
            FiskalyCashRegisterState.CLOSING ||
            cashPointBalance?.closingState ===
            FiskalyCashPointClosingState.PENDING
          }
        />

        {/* Cash Operations */}
        <WithdrawModal
          isOpen={modalState[CashPointModals.WITHDRAW_MODAL]}
          onClose={() => hideModal(CashPointModals.WITHDRAW_MODAL)}
        />

        <DepositModal
          isOpen={modalState[CashPointModals.DEPOSIT_MODAL]}
          onClose={() => hideModal(CashPointModals.DEPOSIT_MODAL)}
        />
      </Box>
    </SBMainComponentGridWrapper>
  );
};
