import { useQuery } from "@tanstack/react-query";
import { bookingsApi } from "../../../../api/apiTanStack";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export const useBookigHistory = (id?: number) => {
  const { t } = useTranslation(["common"]);
  return useQuery({
    queryKey: ["useBookigHistory", Number(id)],
    queryFn: async () => {
      return bookingsApi
        .bookingsControllerGetHistory({
          id: id ?? 0,
        })
        .then(
          (response) =>
            (response.data as any)?.map((log) => {
              return {
                id: log.auditLogId,
                updatedAt: dayjs(log.updatedAt).format("DD.MM.YYYY HH:mm"),
                user: `${
                  log.context.user?.name
                    ? log.context.user?.lastName
                      ? `${log.context.user.name} ${log.context.user.lastName}`
                      : `${log.context.user?.email}`
                    : `${t("undefinedUser")}`
                }`,
                action: log.action,
              };
            }) as {
              id: number;
              updatedAt: string;
              user: string;
              action: string;
            }[]
        );
    },
    enabled: !!id,
    retry: 0,
    cacheTime: 100,
    keepPreviousData: false,
    refetchOnMount: true,
  });
};
