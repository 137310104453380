import { useFieldArray, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { dateToProperDateFormat } from "../conversions/dataConversion";
import { useGetBeachChair } from "../../components/beachChairList/hooks/useGetBeachChair";
import { beachChairToFormData } from "../conversions/beachChairToFormData";
import { useEditBeachChair } from "../../components/beachChairList/hooks/useEditBeachChair";
import { useNewBeachChair } from "../../components/beachChairList/hooks/useNewBeachChair";
import { toast } from "react-toastify";

export function useBeachChairForm(id: number, type: string) {
  const { t } = useTranslation(["common"]);
  const lockChanges = useRef<boolean>(true);
  const {
    data: beachChair,
    isLoading,
    isInitialLoading,
  } = useGetBeachChair(id);
  const editBeachChair = useEditBeachChair();
  const newBeachChair = useNewBeachChair();

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        publicNumber: yup.string().required("publicNumber"),
        buyingDate: yup.date().typeError("buyingDate"),
        sectionId: yup
          .number()
          .typeError("section")
          .moreThan(0, "section")
          .required("section"),
        rowId: yup.number().typeError("row").moreThan(0, "row").required("row"),
        geoJson: yup.string(),
        status: yup.string().required("status"),
        lockId: yup
          .number()
          .nullable(true)
          .transform((v) => (v === "" || Number.isNaN(v) ? null : v)),
        model: yup.string().not([0, "0"], "model").required("model"),
        afterHourBooking: yup.boolean().nullable(true),
        onlineBooking: yup.boolean().nullable(true),
        seasonBeachChair: yup.boolean().nullable(true),
        internalNote: yup.string().nullable(true),
        description: yup.string().nullable(true),
        attributes: yup.object().shape({
          color: yup.string().nullable(true),
        }),
        details: yup.object().shape({
          extraInformation: yup.object().shape({
            de: yup.string().nullable(true),
            en: yup.string().nullable(true),
          }),
        }),
      }),
    []
  );

  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const {
    append: imagesAppend,
    remove: imagesRemove,
    fields: imagesFields,
  } = useFieldArray({ name: "images", control });

  const { append, remove, update, fields } = useFieldArray({
    name: "stopSaleDates",
    control,
  });

  useEffect(() => {
    if (beachChair) {
      const data = beachChairToFormData(beachChair);
      setValue("publicNumber", data.publicNumber);
      setValue("buyingDate", dateToProperDateFormat(data.buyingDate));
      setValue("sectionId", data.sectionId);
      setValue("rowId", data.rowId);
      setValue("geoJson", data.geoJson);
      setValue("status", data.status);
      setValue("lockId", data.lockId || 0);
      setValue("model", data.model);
      setValue("description", data.description || "");
      setValue("internalNote", data.internalNote || "");
      setValue("afterHourBooking", data.afterHourBooking);
      setValue("onlineBooking", data.onlineBooking);
      setValue("seasonBeachChair", data.seasonBeachChair);
      setValue("attributes", { color: data.attributes.color });
      setValue(
        "details.extraInformation.de",
        data?.details?.extraInformation?.de || ""
      );
      setValue(
        "details.extraInformation.en",
        data?.details?.extraInformation?.en || ""
      );
      setValue(
        "images",
        data?.images?.map((element: string) => {
          return { value: element };
        }) || []
      );
      setValue("stopSaleDates", data.stopSaleDates || []);
      lockChanges.current = false;
    }
    // eslint-disable-next-line
  }, [beachChair]);

  const onSubmit = useCallback(
    (formValues) => {
      const date = new Date(formValues.buyingDate);
      const isoString = date.toISOString();
      formValues.buyingDate = isoString;
      formValues.images =
        formValues.images?.map((items: { value: string }) => items.value) || [];
      if (type === "new") {
        newBeachChair.mutate({ formData: formValues });
        lockChanges.current = false;
      } else {
        editBeachChair.mutate({ id, formData: formValues });
        lockChanges.current = false;
      }
    },
    [id, type, newBeachChair, editBeachChair]
  );

  const onError = useCallback(
    (error) => {
      if (error) {
        if ((error as any).rowId) {
          toast.error(t("toastMessages.PleaseChooseRow"));
        }
      }
    },
    [t]
  );

  const handleReset = useCallback(() => {
    setValue("publicNumber", "");
    setValue("buyingDate", dateToProperDateFormat(new Date().toISOString()));
    setValue("sectionId", 0);
    setValue("rowId", 0);
    setValue("geoJson", "0, 0");
    setValue("status", "ACTIVE");
    setValue("lockId", 0);
    setValue("model", "2_SEAT_RECLINER");
    setValue("description", "");
    setValue("internalNote", "");
    setValue("afterHourBooking", false);
    setValue("onlineBooking", false);
    setValue("images", []);
    setValue("seasonBeachChair", false);
    setValue("stopSaleDates", []);
    setValue("attributes", {});
    setValue("details", {});
  }, [setValue]);

  return {
    watch,
    isInitialLoading,
    isLoading,
    control,
    errors,
    setValue,
    append,
    remove,
    fields,
    update,
    onReset: handleReset,
    onSubmit: handleSubmit(onSubmit, onError),
    imagesAppend,
    imagesRemove,
    imagesFields,
    signedLock: beachChair?.lockId,
  };
}
