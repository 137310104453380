import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CashPointApiCashPointControllerCloseCashPointRequest as Variables } from "../../../api-client/generated";
import { cashPointApi } from "../../../api/apiTanStack";

enum FiskalyCashRegisterState {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  CLOSING = "CLOSING",
}

export const useCloseCashPoint = () => {
  const queryClient = useQueryClient();
  let seconds = 1;
  const closeCashPointMutation = useMutation(
    ["closeCashPoint"],
    ({ locationId }: Variables) =>
      cashPointApi
        .cashPointControllerCloseCashPoint({
          locationId: Number(locationId),
        })
        .then((response) => response.data),
    {
      onSuccess: (data, variables) => {
        if (data.state === FiskalyCashRegisterState.CLOSED) {
          seconds = 1;
          queryClient.invalidateQueries([
            "cashPointBalance",
            Number(variables.locationId),
          ]);
        } else {
          setTimeout(() => {
            closeCashPointMutation.mutateAsync({
              locationId: variables.locationId,
            });
          }, seconds * 1000);
          seconds *= 2;
        }
      },
    }
  );

  return closeCashPointMutation;
};
