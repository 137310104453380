import React, { useState } from "react";
import { Box, Button, Card, CircularProgress, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import API from "../../../api/api";
import { FieldArrayMethodProps } from "react-hook-form/dist/types/fieldArray";
import { UseFormSetValue } from "react-hook-form";
import {
  SBImageCard,
  SBInsideImageCard,
  SBInsideImageCardRow,
  SBLoadingButtonBlueStyle,
  SBLoadingButtonLabelStyle,
  SBLoadingButtonStyle,
} from "../styles/SBStyles";
import ImageIcon from "@mui/icons-material/Image";
import { toast } from "react-toastify";

interface ISBImageUploaderProps {
  disabled: boolean;
  append?: (
    value: Partial<unknown> | Partial<unknown>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  setValue?: UseFormSetValue<any>;
  width?: string;
  height?: string;
}

const SBImageUploader = ({
  disabled,
  append,
  setValue,
  width,
  height,
}: ISBImageUploaderProps) => {
  const { t } = useTranslation(["common"]);
  const [progress, setProgress] = useState<boolean>(false);
  const [images, setImages] = useState<[{ preview: string; raw?: File }]>([
    {
      preview: "",
      raw: undefined,
    },
  ]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      if (e.target.files[0].size > 1048576) {
        toast.info(t(`toastMessages.ImageIsToLarge`));
        e.target.files = null;
        return;
      }
      let files: [{ preview: string; raw?: File }] = [
        {
          preview: "",
          raw: undefined,
        },
      ];
      Array.from(e.target.files).forEach((file) => {
        files.push({
          preview: URL.createObjectURL(file),
          raw: file,
        });
      });
      setImages(files);
      Array.from(e.target.files).forEach((file) => {
        handleUpload(file);
      });
    }
    e.target.files = null;
  };

  const handleUpload = async (raw?: File) => {
    if (raw) {
      setProgress(true);
      const formData = new FormData();
      formData.append("file", raw, raw.name);
      try {
        await API.post("/features/images?width=960", formData, {
          onUploadProgress: (progressEvent: any) => {
            setProgress(true);
          },
        })
          .then((res: any) => {
            const { data } = res;
            append && append({ value: data.url });
            setValue && setValue("details.logo", data.url);
            handleCancel();
          })
          .catch((e: any) => {
            handleCancel();
            toast.error(t(`toastMessages.ImageIsToLarge`));
          });
      } catch (e: any) {
        handleCancel();
      }
    }
  };

  const handleCancel = async () => {
    setImages([
      {
        preview: "",
        raw: undefined,
      },
    ]);
    setProgress(false);
  };

  return (
    <Card sx={{ ...SBImageCard, width, height }} data-testid="image">
      <Box sx={{ ...SBInsideImageCard }}>
        <Box sx={{ ...SBInsideImageCardRow }}>
          {images &&
            images.map(
              (image) =>
                image.preview &&
                image.raw && (
                  <img
                    className="imageSquare"
                    key={image.raw?.name}
                    src={image.preview}
                    alt={image.raw?.name || "ImagePhoto"}
                  />
                )
            )}
          <Button
            disabled={disabled}
            sx={{
              ...SBLoadingButtonStyle,
              ...SBLoadingButtonBlueStyle,
            }}
            variant="contained"
            component="label"
            startIcon={<ImageIcon />}
          >
            <Typography sx={{ ...SBLoadingButtonLabelStyle }}>
              {t("clickToSelectImage")}
            </Typography>
            <input
              hidden
              accept="image/*"
              multiple
              type="file"
              id="upload-button"
              onChange={handleChange}
            />
          </Button>
          {progress && <CircularProgress />}
        </Box>
      </Box>
    </Card>
  );
};

export default SBImageUploader;
