import React from "react";
import { Box, IconButton, Link } from "@mui/material";
import { Delete, Remove } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  BookingDetailsResponseDto,
  BookingStatus,
} from "../../../../api-client/generated";
import { setGlobalBookingId } from "../../../../store/reducers/globalBookingReducer";
import { useTranslation } from "react-i18next";
import {
  useGetBooking,
  useRemoveBookingExtras,
  useUpdateBookingExtrasAmount,
} from "../hooks";
import { MaterialReactTable } from "material-react-table";
import { SBColor } from "../../../SBComponents/styles/SBStyles";
import SBLoadingButton from "../../../SBComponents/SBForms/SBLoadingButton";
import AddIcon from "@mui/icons-material/Add";
import SBLabel from "../../../SBComponents/SBForms/SBLabel";
import { useQueryClient } from "@tanstack/react-query";

const BookingExtras: React.FC<{
  bookingId: number;
  type: string;
  booking?: BookingDetailsResponseDto;
}> = ({ bookingId, type, booking }) => {
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  const disabledRef = React.useRef<boolean>(type === "show");
  const { updateBookingExtrasAmount } = useUpdateBookingExtrasAmount();
  const { removeBookingExtras } = useRemoveBookingExtras();
  const dispatch = useDispatch();

  const increaseExtrasAmount = React.useCallback(
    (extrasBookingId: number) => {
      const extras = booking?.extras.find(({ id }) => id === extrasBookingId);
      if (extras) {
        updateBookingExtrasAmount(
          { id: bookingId, extrasBookingId, amount: extras.amount + 1 },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(["booking"]);
            },
          }
        );
      }
    },
    [booking, updateBookingExtrasAmount, bookingId]
  );

  const decreaseExtrasAmount = React.useCallback(
    (extrasBookingId: number) => {
      const extras = booking?.extras.find(
        ({ id: rowId }) => rowId === extrasBookingId
      );
      if (extras) {
        if (extras.amount <= 1) {
          removeBookingExtras(
            { id: bookingId, extrasBookingId },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(["booking"]);
              },
            }
          );
        } else {
          updateBookingExtrasAmount(
            { id: bookingId, extrasBookingId, amount: extras.amount - 1 },
            {
              onSuccess: () => {
                queryClient.invalidateQueries(["booking"]);
              },
            }
          );
        }
      }
    },
    [booking, removeBookingExtras, bookingId, updateBookingExtrasAmount]
  );

  const removeExtras = React.useCallback(
    (extrasBookingId: number) => {
      removeBookingExtras(
        { id: bookingId, extrasBookingId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["booking"]);
          },
        }
      );
    },
    [removeBookingExtras, bookingId]
  );

  const handleAddExtras = () => {
    dispatch(setGlobalBookingId({ id: bookingId }));
    history.push(`/extras`);
  };

  const renderDetailPanel = React.useCallback(() => {
    const extras = booking?.extras ?? [];
    const renderRowActions = (cell: any) => {
      const extraId = extras[cell.row.index].id;
      const status = booking?.status;
      return (
        <Box
          sx={{
            float: "right",
            display: "flex",
            justifyContent: "end",
            minWidth: "150px",
          }}
        >
          {(status === "PENDING" || status === "OPEN") &&
            !disabledRef.current && (
              <IconButton
                sx={{ color: "#000000" }}
                onClick={() => {
                  if (extraId) {
                    removeExtras(extraId);
                  }
                }}
              >
                <Delete />
              </IconButton>
            )}
          {(status === "PENDING" || status === "OPEN") && (
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (extraId) {
                  increaseExtrasAmount(extraId);
                }
              }}
            >
              <AddIcon />
            </IconButton>
          )}
          {(status === "PENDING" || status === "OPEN") && (
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (extraId) {
                  decreaseExtrasAmount(extraId);
                }
              }}
            >
              <Remove />
            </IconButton>
          )}
        </Box>
      );
    };

    const columns: any[] = [
      {
        accessorKey: "extraId",
        header: `${t("extraId")}`,
        maxSize: 20,
        minSize: 10,
        Cell: (row: any) => (
          <Link
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "bold",
              textDecoration: "none",
            }}
            href={`/extras/${row.renderedCellValue}/show`}
          >
            {row.renderedCellValue}
          </Link>
        ),
      },
      {
        accessorKey: "name",
        header: `${t("name")}`,
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "type",
        header: `${t("type")}`,
        accessorFn: (originalRow: any) => `${t(`types.${originalRow.type}`)}`,
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "amount",
        header: `${t("amount")}`,
        accessorFn: (originalRow: any) =>
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(originalRow.amount),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "totalPrice",
        header: `${t("totalPrice")}`,
        accessorFn: (originalRow: any) =>
          new Intl.NumberFormat("de-DE", {
            style: "currency",
            currency: "EUR",
          }).format(originalRow.totalPrice),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "vat",
        header: `${t("vat")}`,
        accessorFn: (originalRow: any) => `${originalRow.vat} %`,
        maxSize: 20,
        minSize: 10,
      },
    ];

    return (
      <Box>
        {extras && extras.length > 0 ? (
          <MaterialReactTable
            initialState={{
              showColumnFilters: false,
              density: "compact",
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": { maxSize: 8, minSize: 8 },
              "mrt-row-expand": { maxSize: 1, minSize: 1 },
            }}
            muiTableProps={{
              sx: {
                tableLayout: "auto",
                overflow: "auto",
              },
            }}
            muiTablePaperProps={{
              sx: {
                width: {
                  lg: "100%",
                  md: "100%",
                  sm: "100%",
                  xs: "calc(90vw - 60px)",
                },
                overflow: "auto",
              },
            }}
            muiPaginationProps={{
              sx: {
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: SBColor.scroll,
                },
              },
            }}
            muiTableContainerProps={{
              sx: {
                "&::-webkit-scrollbar": {
                  width: "2px",
                  height: "2px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: SBColor.scroll,
                },
              },
            }}
            muiDetailPanelProps={{ sx: { padding: 0 } }}
            data={extras}
            columns={columns}
            enableEditing={true}
            positionActionsColumn="last"
            renderRowActions={renderRowActions}
            enableSorting={false}
            enableGlobalFilter={false}
            enableFilters={false}
            enableTopToolbar={false}
            enablePagination={false}
            enableHiding={false}
            enableBottomToolbar={false}
            enableTableFooter={false}
            enableColumnActions={false}
          />
        ) : (
          <SBLabel label={t("filterNoOption")} />
        )}
      </Box>
    );
    // eslint-disable-next-line
  }, [t, booking, history]);

  return (
    <Box
      sx={{
        padding: "10px",
        display: "grid",
      }}
    >
      {renderDetailPanel()}
      {booking?.status === BookingStatus.Open && !disabledRef.current && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <SBLoadingButton
            label={t("addExtras")}
            type="blue"
            icon={<AddIcon />}
            onClick={handleAddExtras}
            isLoading={false}
          />
        </Box>
      )}
    </Box>
  );
};

export default BookingExtras;
