import { useCallback, useState } from "react";
import { useHistory, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import ExtrasHeader from "../extrasHeader.tsx/extrasHeader";
import { useExtraForm } from "../../../utils/customHooks/useExtraForm";
import { useDeleteExtra } from "../hooks/useDeleteExtra";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import NumbersIcon from "@mui/icons-material/Numbers";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import DescriptionIcon from "@mui/icons-material/Description";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import PercentIcon from "@mui/icons-material/Percent";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import EuroIcon from "@mui/icons-material/Euro";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import SBNumberField from "../../SBComponents/SBForms/SBNumberField";

const Extra = () => {
  const { id, type } = useParams<{ id: string; type: string }>();
  const { t } = useTranslation(["common"]);
  const deleteExtra = useDeleteExtra();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { control, onSubmit, onReset, isLoading, errors } = useExtraForm(
    +id,
    type
  );

  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteExtra.mutateAsync({ id: id }).finally(() => {
        setDialog(false);
        history.push("/extras");
      });
    },
    [history, deleteExtra]
  );

  return (
    <SBMainComponentGridWrapper>
      <ExtrasHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="name"
              label={t("name")}
              control={control}
              type={type}
              icon={<NumbersIcon />}
              isLoading={isLoading}
              error={errors?.name && t((errors.name as any)?.message)}
            />
            <SBSelect
              name="type"
              label={t("type")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<AssignmentTurnedInIcon />}
              isLoading={isLoading}
              additionalOptions={[
                { id: "FOOD", value: `${t(`types.FOOD`)}` },
                { id: "ACCESSOIRES", value: `${t(`types.ACCESSOIRES`)}` },
                { id: "TOYS", value: `${t(`types.TOYS`)}` },
                { id: "BEVERAGES", value: `${t(`types.BEVERAGES`)}` },
                {
                  id: "ALCOHOLIC_BEVERAGES",
                  value: `${t(`types.ALCOHOLIC_BEVERAGES`)}`,
                },
                { id: "OTHER", value: `${t(`types.OTHER`)}` },
              ]}
              defaultValue={"FOOD"}
              error={errors?.type && t((errors.type as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBNumberField
              name="price"
              label={t("price")}
              control={control}
              type={type}
              icon={<EuroIcon />}
              isLoading={isLoading}
              defaultValue={0}
              endAdornmentSymbol="€"
              error={errors?.price && t((errors.price as any)?.message)}
            />
            <SBNumberField
              name="vat"
              label={t("vat")}
              control={control}
              type={type}
              icon={<PercentIcon />}
              isLoading={isLoading}
              defaultValue={0}
              endAdornmentSymbol="%"
              error={errors?.vat && t((errors.vat as any)?.message)}
            />
            <SBTextArea
              name="description"
              label={t("comment")}
              control={control}
              type={type}
              icon={<DescriptionIcon />}
              isLoading={false}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBCheckBox
              name="onlineBooking"
              label={t("onlineBooking")}
              control={control}
              type={type}
              isLoading={isLoading}
              error={
                errors?.onlineBooking &&
                t((errors.onlineBooking as any)?.message)
              }
            />
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/extras/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/extras/${id}/show`)}
          type={type}
          isLoading={isLoading}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="beachchairs"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default Extra;
