import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { extrasApi } from "../../../api/apiTanStack";
import { useTranslation } from "react-i18next";

export const useDeleteExtra = () => {
  const { t } = useTranslation(["common"]);
  const queryClient = useQueryClient();
  return useMutation(
    ["deleteExtra"],
    ({ id }: { id: number }) =>
      extrasApi.externalServicesControllerDelete({ id: id }),
    {
      onSuccess: () => {
        toast.success(t(`toastMessages.DeleteExtraSuccess`));
        queryClient.refetchQueries(["extras"]);
      },
      onError: () => {
        toast.error(t(`toastMessages.DeleteExtraFailure`));
      },
    }
  );
};
