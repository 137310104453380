import axios from "axios";
import { apiConfig } from "../utils/cfg/config";

const { publicUrl, apiPrefix } = apiConfig;

export const shortUrlApi = axios.create();
export const shortInvoiceUrlApi = axios.create();

export default axios.create({
  baseURL: `${apiPrefix?.includes("http") ? "" : publicUrl}${apiPrefix}`,
  withCredentials: true,
  headers: {
    appVersion: "Backoffice",
  },
});
