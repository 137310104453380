import React, { useMemo, useState } from "react";
import { SBTextFieldType } from "../../../SBComponents/SBForms/SBTextField";
import EditLocationIcon from "@mui/icons-material/EditLocation";
import { Box, FormControl, InputLabel, TextField } from "@mui/material";
import {
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../../../SBComponents/styles/SBStyles";
import SBCircularProgress from "../../../SBComponents/SBForms/SBCircularProgress";
import { Controller, useController } from "react-hook-form";
import SBFormHelperText from "../../../SBComponents/SBForms/SBFormHelperText";
import SBGeoLocationMapModal from "./SBGeoLocationMapModal";
import { useSection } from "../../../bookingsList/vendorBooking/hooks/useSection";

export interface SBGeoLocationPrimary {
  sectionId?: number;
}

export type SBGeoLocationType = SBTextFieldType & SBGeoLocationPrimary;

const SBGeoLocation: React.FC<SBGeoLocationType> = ({
  sectionId,
  name,
  label,
  control,
  type,
  isLoading,
  error,
}) => {
  const { data: sectionData } = useSection(sectionId);
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });
  const geoLocation = useMemo(() => {
    const value = field?.value?.split(" ");
    return { lat: value ? value[0] : 0, lng: value ? value[1] : 0 };
  }, [field]);
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>
        <EditLocationIcon />
      </Box>
      <Box
        onClick={() => {
          setShowModal(true);
        }}
      >
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue="0, 0"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  label=""
                  sx={{ ...SBTextFieldStyle }}
                  InputProps={{ readOnly: true }}
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
      {showModal && !!sectionData ? (
        <SBGeoLocationMapModal
          showModal={showModal}
          setShowModal={setShowModal}
          geoJson={sectionData?.geoJson}
          geoLocation={geoLocation}
          field={field}
        />
      ) : null}
    </Box>
  );
};

export default SBGeoLocation;
