import { all, fork } from "redux-saga/effects";

import beachChairsSaga from "./beachChairsSaga";
import bookingsSaga from "./bookingsSaga";
import availabilitySaga from "./availabilitySaga";
import customer from "./customersSaga";
import authSaga from "./authSaga";
import pricesSaga from "./pricesSaga";
import invoicesSaga from "./invoicesSaga";
import localesSaga from "./localesSaga";
import vendorSaga from "./vendorsSaga";
import routerSaga from "./routerSaga";
import searchSaga from "./searchSaga";
import extrasSaga from "./extrasSaga";
import reportSaga from "./reportSaga";
import adminSaga from "./adminSaga";
import destinationSaga from "./destinationSaga";
import shortUrlsSaga from "./shortUrlsSaga";
import LocksSaga from "./lockSaga";

export function* rootSaga() {
  yield all([
    fork(adminSaga),
    fork(beachChairsSaga),
    fork(bookingsSaga),
    fork(availabilitySaga),
    fork(customer),
    fork(authSaga),
    fork(pricesSaga),
    fork(vendorSaga),
    fork(invoicesSaga),
    fork(localesSaga),
    fork(routerSaga),
    fork(extrasSaga),
    fork(reportSaga),
    fork(searchSaga),
    fork(shortUrlsSaga),
    fork(LocksSaga),
    fork(destinationSaga),
  ]);
}
