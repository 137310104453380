import SubPageHeader from "../../subPageHeader/subPageHeader";
import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import SBBreadcrumbsWrapper from "../../SBComponents/SBLayout/SBBreadcrumbsWrapper";
import SBHeaderLink from "../../SBComponents/SBLayout/SBHeaderLink";
import SBHeaderTypography from "../../SBComponents/SBLayout/SBHeaderTypography";
import { useHistory } from "react-router";
import SBHeaderActionButtonsWrapper from "../../SBComponents/SBForms/SBHeaderActionButtonsWrapper";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import { Download } from "@mui/icons-material";
import { useFiltersForCSVStore } from "../hooks/useFiltersForCSVStore";
import { useGetCsvBookings } from "../../bookingsList/bookingsListHeader/hooks/useGetCsvBookings";

const BeachChairBookingsListHeader = () => {
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const { columnFilters } = useFiltersForCSVStore();
  const getCsv = useGetCsvBookings();

  const filterString = useMemo(
    () =>
      columnFilters
        .map((filter) => `filters%5B${filter.id}%5D=${filter.value}`)
        .join("&"),
    [columnFilters]
  );

  const getTransactionData = React.useCallback(async () => {
    getCsv.mutate({ filters: filterString });
  }, [filterString]);

  return (
    <SubPageHeader
      headerTitle={
        t("bookingsMenu.beachChairsBookings") + " - " + t("overview")
      }
    >
      <SBBreadcrumbsWrapper>
        <SBHeaderLink
          label={t("home")}
          onClick={() => {
            history.push(`/`);
          }}
        />
        <SBHeaderTypography label={t("bookingsMenu.beachChairsBookings")} />
      </SBBreadcrumbsWrapper>
      <SBHeaderActionButtonsWrapper>
        <SBLoadingButton
          label={t("download")}
          type="blue"
          icon={<Download />}
          onClick={() => getTransactionData()}
          isLoading={getCsv.isLoading}
        />
      </SBHeaderActionButtonsWrapper>
    </SubPageHeader>
  );
};

export default React.memo(BeachChairBookingsListHeader);
