import { useMutation, UseMutateFunction, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { CashPointApiCashPointControllerDepositCashRequest as Variables } from "../../../api-client/generated";
import { cashPointApi } from "../../../api/apiTanStack";

interface UseCashPointDeposit {
  deposit: UseMutateFunction<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useCashPointDeposit = (): UseCashPointDeposit => {
  const queryClient = useQueryClient();

  const { mutate: deposit, isLoading } = useMutation<
    AxiosResponse<void>,
    AxiosError,
    Variables
  >(
    ["cashPointDeposit"],
    (variables: Variables) => cashPointApi.cashPointControllerDepositCash(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries(['cashPointBalance', Number(variables.cashPointWithdraw.locationId)]);
      }
    }
  );

  return {
    deposit,
    isLoading
  };
};
