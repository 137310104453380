import { create } from "zustand";
import dayjs, { Dayjs } from "dayjs";
import { DateRange } from "@mui/x-date-pickers-pro/internals/models";

interface AvailabilitiesDatesStore {
  availabilitiesDateRange: DateRange<Dayjs>;
  setAvailabilitiesDateRange: (
    availabilitiesDateRange: DateRange<Dayjs>
  ) => void;
}

export const useAvailabilitiesDatesStore = create<AvailabilitiesDatesStore>(
  (set) => {
    return {
      availabilitiesDateRange: [dayjs(new Date()), dayjs(new Date())],
      setAvailabilitiesDateRange: (availabilitiesDateRange: DateRange<Dayjs>) =>
        set((state) => ({
          ...state,
          availabilitiesDateRange,
        })),
    };
  }
);
