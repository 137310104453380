import React, { useEffect, useState } from "react";
import AvailabilitiesBeachChair from "../availabilities/availabilitiesBeachChair/availabilitiesBeachChair";
import { IAvailableBeachChair } from "../../models/availability/IAvailableBeachChair";
import { useDispatch } from "react-redux";
import { putPositionRequest } from "../../store/reducers/availabilityReducer";
import { ReactSortable } from "react-sortablejs";
import dayjs from "dayjs";
import { useMenuZustand } from "../layout/Navigation/hooks/useMenuZustand";

export interface IPositionProps {
  items: IAvailableBeachChair[];
  rowId: number;
}

const Position = ({ items, rowId }: IPositionProps) => {
  const [state, setState] = useState(items);
  const dispatch = useDispatch();
  const { open } = useMenuZustand();
  useEffect(() => {
    dispatch(
      putPositionRequest({
        availableBeachChairs: state,
        rowId: rowId,
        date: dayjs(new Date()).toISOString(),
      })
    );
    // eslint-disable-next-line
  }, [rowId, state]);

  return (
    <ReactSortable
      setList={setState}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(70px, 70px))",
        minWidth: "calc(100vw - 380px)",
        maxWidth: open ? "calc(100vw - 330px)" : "calc(100vw - 100px)",

        gap: 10,
      }}
      list={state.map((x) => ({ ...x, chosen: true }))}
    >
      {state.length > 0 &&
        state
          .filter((x) => x !== null)
          .map((item) => (
            <AvailabilitiesBeachChair
              hasLock={false}
              isSeasonBeachChair={false}
              isInBooking={false}
              key={item.id}
              id={`${item.publicNumber}`}
              model={item.model}
              timeEnum={item.timeEnum}
              draggable
              attributes={item.attributes}
            />
          ))}
    </ReactSortable>
  );
};

export default Position;
