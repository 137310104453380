import { IMetaNavgationItem } from "../../../models/menu/IMetaNavgationItem";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Link, Typography } from "@mui/material";
import { customStyle } from "../../../utils/customStyles/customStyles";

export const Footer: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const metaNavigationItems: IMetaNavgationItem[] = [
    /*{
      text: t("metaNaviFAQ"),
      href: "/pages/faq",
      keyId: "link-faq",
    },*/
    {
      text: t("metaNaviSupport"),
      href: "/pages/support",
      keyId: "link-support",
    },
    {
      text: t("metaNaviPrivacy"),
      href: "/pages/privacy",
      keyId: "link-datenschutz",
    },
    {
      text: t("metaNaviTC"),
      href: "/pages/terms",
      keyId: "link-nutzungsbedingungen",
    },
  ];

  return (
    <Box
      sx={{
        height: "70px",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
        marginLeft: "20px",
      }}
    >
      <Link
        sx={{
          textDecoration: "none",
        }}
        rel="noopener noreferrer"
        href="https://strandkorbjetzt.atlassian.net/wiki/external/M2FhYTM4MTIyY2U3NDc3ZjhiNjg4YzI0OTAxMzhkMmE"
        target="_blank"
      >
        <Typography
          sx={{
            color: customStyle.mainColor,
            fontWeight: "bolder",
            fontSize: {
              lg: "16px",
              md: "10px",
              sm: "10px",
              xs: "10px",
            },
          }}
        >
          {t("metaNaviFAQ")}
        </Typography>
      </Link>
      {metaNavigationItems.map(({ text, href, keyId }: IMetaNavgationItem) => (
        <Link
          sx={{
            textDecoration: "none",
          }}
          href="#"
          onClick={() => {
            history.push(href || "");
          }}
          key={keyId}
          data-testid={keyId}
        >
          <Typography
            sx={{
              color: customStyle.mainColor,
              fontWeight: "bolder",
              fontSize: {
                lg: "16px",
                md: "10px",
                sm: "10px",
                xs: "10px",
              },
            }}
          >
            {text}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};
