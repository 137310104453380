import { useCallback, useEffect, useMemo, useState } from "react";
import { IIdentifier } from "../../../models/common/IIdentifier";
import BeachChairListHeader from "../beachChairListHeader/beachChairListHeader";
import { useBeachChairForm } from "../../../utils/customHooks/useBeachChairForm";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../dialog/DeleteDialog";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";
import NumbersIcon from "@mui/icons-material/Numbers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import NotesIcon from "@mui/icons-material/Notes";
import DescriptionIcon from "@mui/icons-material/Description";
import EmailIcon from "@mui/icons-material/Email";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { getVendorChangeContextCompleteSelector } from "../../../store/selectors/adminSelectors";
import { EColor } from "../../../models/beachChairs/EColors";
import { Box, Tooltip } from "@mui/material";
import { useDeleteBeachChair } from "../hooks/useDeleteBeachChair";
import { useGetBeachChairsFilters } from "../hooks/useGetBeachChairsFilters";
import SBTextField from "../../SBComponents/SBForms/SBTextField";
import SBTextArea from "../../SBComponents/SBForms/SBTextArea";
import SBSelect from "../../SBComponents/SBForms/SBSelect";
import SBTextAreaDatePicker from "../../SBComponents/SBForms/SBTextAreaDatePicker";
import SBTextAreaStopSaleDates from "../../SBComponents/SBForms/SBTextAreaStopSaleDates";
import SBCheckBox from "../../SBComponents/SBForms/SBCheckBox";
import { SBButtonGridStyle, SBColor } from "../../SBComponents/styles/SBStyles";
import SBLoadingButton from "../../SBComponents/SBForms/SBLoadingButton";
import SBToggleButtonGroup from "../../SBComponents/SBForms/SBToggleButtonGroup";
import cloneDeep from "lodash/cloneDeep";
import SBButtonActionGrid from "../../SBComponents/SBForms/SBButtonActionGrid";
import SBFormGridWrapper from "../../SBComponents/SBForms/SBFormGridWrapper";
import SBMainComponentGridWrapper from "../../SBComponents/SBForms/SBMainComponentGridWrapper";
import SBImageUploader from "../../SBComponents/SBForms/SBImageUploader";
import SBCheckboxGrid from "../../SBComponents/SBForms/SBCheckboxGrid";
import SBColumnGridWrapper from "../../SBComponents/SBForms/SBColumnGridWrapper";
import SBGeoLocation from "./geoLocation/SBGeoLocation";

const BeachChair: React.FC = () => {
  const { t } = useTranslation(["common"]);
  const vendorChangeContextComplete = useSelector(
    getVendorChangeContextCompleteSelector
  );
  const { data: filters, isLoading: filterIsLoading } =
    useGetBeachChairsFilters();
  const [dialog, setDialog] = useState<boolean>(false);
  const history = useHistory();
  const { id, type } = useParams<{ id: string; type: string }>();
  const {
    watch,
    isLoading: isBeachChairLoading,
    isInitialLoading,
    control,
    setValue,
    onSubmit,
    onReset,
    fields,
    append,
    remove,
    update,
    imagesAppend,
    imagesRemove,
    imagesFields,
    signedLock,
    errors,
  } = useBeachChairForm(+id, type);
  const selectedSectionId = watch("sectionId");
  const deleteBeachChair = useDeleteBeachChair();
  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteBeachChair.mutateAsync({ id: id, isList: false }).finally(() => {
        setDialog(false);
      });
    },
    [deleteBeachChair]
  );
  useEffect(() => {
    vendorChangeContextComplete === false &&
      setTimeout(() => {
        history.push(`/beach_chairs`);
      }, 500);
    // eslint-disable-next-line
  }, [vendorChangeContextComplete, history]);
  const lockOptions: IIdentifier[] = cloneDeep(
    filters?.unassignedLocks ?? []
  ).sort((a, b) => a.value?.localeCompare(b.value));

  const isLoading = useMemo(
    () => isBeachChairLoading && isInitialLoading,
    [isBeachChairLoading, isInitialLoading]
  );

  return (
    <SBMainComponentGridWrapper>
      <BeachChairListHeader />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <SBFormGridWrapper>
          <SBColumnGridWrapper>
            <SBTextField
              name="publicNumber"
              label={t("publicNumber")}
              control={control}
              type={type}
              icon={<NumbersIcon />}
              isLoading={isLoading}
              error={
                errors?.publicNumber &&
                `${t((errors.publicNumber as any)?.message)} ${t(
                  "isMandatory"
                )}`
              }
            />
            <SBTextAreaDatePicker
              name="buyingDate"
              label={t("buyingDate")}
              control={control}
              type={type}
              icon={<CalendarTodayIcon />}
              isLoading={isLoading}
              error={
                errors?.buyingDate &&
                `${t((errors.buyingDate as any)?.message)} ${t("isMandatory")}`
              }
            />
            <SBSelect
              name="sectionId"
              label={t("section")}
              noOptionLabel={t("NO_SECTION")}
              control={control}
              type={type}
              icon={<ViewModuleIcon />}
              isLoading={isLoading || filterIsLoading}
              setValue={setValue}
              setValueId="rowId"
              additionalOptions={filters?.sections}
              defaultValue={0}
              error={
                errors?.sectionId &&
                `${t((errors.sectionId as any)?.message)} ${t("isMandatory")}`
              }
            />
            <SBSelect
              name="rowId"
              label={
                <Tooltip title={`${t("section")} ${t("isMandatory")}`}>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "5px" }}
                  >
                    {t("row")}
                    <Box color={SBColor.red}>*</Box>
                  </Box>
                </Tooltip>
              }
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<MenuOpenIcon />}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={filters?.sections
                .filter((section) => section.id === selectedSectionId)
                .flatMap((section) => {
                  return section.rows
                    .slice()
                    .sort((r, o) => {
                      return r.value.localeCompare(o.value);
                    })
                    .flatMap((row) => {
                      return { id: row.id, value: row.value };
                    });
                })}
              defaultValue={0}
              error={
                errors?.rowId &&
                `${t((errors.rowId as any)?.message)} ${t("isMandatory")}`
              }
            />
            <SBGeoLocation
              name="geoJson"
              label={t("geoJson")}
              control={control}
              type={type}
              isLoading={isLoading}
              error={errors?.geoJson && t((errors.geoJson as any)?.message)}
              sectionId={selectedSectionId}
            />
            <SBSelect
              name="status"
              label={t("status")}
              noOptionLabel={t("NO_ROW")}
              control={control}
              type={type}
              icon={<AssignmentTurnedInIcon />}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={[
                { id: "ACTIVE", value: `${t(`states.ACTIVE`)}` },
                { id: "INACTIVE", value: `${t(`states.INACTIVE`)}` },
              ]}
              defaultValue={"ACTIVE"}
              error={errors?.status && t((errors.status as any)?.message)}
            />
          </SBColumnGridWrapper>
          <SBColumnGridWrapper>
            <SBSelect
              name="lockId"
              label={t("lock")}
              noOptionLabel={t("noLock")}
              icon={<LockIcon />}
              control={control}
              type={type}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={[
                ...lockOptions,
                {
                  id: signedLock,
                  value: `${
                    filters?.locks.find(({ id }) => id === signedLock)?.value ??
                    ""
                  }`,
                },
              ]}
              defaultValue={0}
              error={errors?.lockId && t((errors.lockId as any)?.message)}
            />
            <SBSelect
              name="model"
              label={t("model")}
              noOptionLabel={t("noModel")}
              control={control}
              type={type}
              icon={<BeachAccessIcon />}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={filters?.models.flatMap((option) => {
                return { id: option, value: `${t(`models.${option}`)}` };
              })}
              defaultValue={0}
              error={
                errors?.model &&
                `${t((errors.model as any)?.message)} ${t("isMandatory")}`
              }
            />
            <SBSelect
              name="attributes.color"
              label={t("color")}
              noOptionLabel={t("no_color")}
              control={control}
              type={type}
              icon={<ColorLensIcon />}
              isLoading={isLoading || filterIsLoading}
              additionalOptions={Object.keys(EColor).flatMap((option) => {
                return { id: option, value: `${t(`colors.${option}`)}` };
              })}
              defaultValue={0}
              error={
                errors?.attributes &&
                (errors.attributes as any)?.color &&
                t((errors.attributes as any)?.color?.message)
              }
            />
            <SBTextArea
              name="description"
              label={t("description")}
              control={control}
              type={type}
              icon={<DescriptionIcon />}
              isLoading={isLoading}
              error={
                errors?.description && t((errors.description as any)?.message)
              }
            />
            <SBTextArea
              name="internalNote"
              label={t("internalNote")}
              control={control}
              type={type}
              icon={<NotesIcon />}
              isLoading={isLoading}
              error={
                errors?.internalNote && t((errors.internalNote as any)?.message)
              }
            />
            <SBToggleButtonGroup
              optionFirstName="details.extraInformation.de"
              optionSecondName="details.extraInformation.en"
              label={t("extraInformation")}
              control={control}
              type={type}
              icon={<EmailIcon />}
            />
            <SBImageUploader
              label={t("destinations.images")}
              imagesFields={imagesFields}
              imagesAppend={imagesAppend}
              imagesRemove={imagesRemove}
              type={type}
            />
            {(type === "edit" || type === "new") && (
              <Box
                sx={{
                  ...SBButtonGridStyle,
                }}
              >
                <SBLoadingButton
                  label={t("addNewStopSaleDate")}
                  type="blue"
                  icon={<AddIcon />}
                  onClick={() => {
                    append({
                      start: new Date().toISOString(),
                      end: new Date().toISOString(),
                    });
                  }}
                />
              </Box>
            )}
            {fields.map((item, index) => (
              <SBTextAreaStopSaleDates
                label={`${t("stopSale")}`}
                item={item}
                index={index}
                remove={remove}
                update={update}
                icon={<DateRangeIcon />}
                type={type}
                key={`SBTextAreaStopSaleDates${index}`}
              />
            ))}
            <SBCheckboxGrid>
              <SBCheckBox
                name="afterHourBooking"
                label={t("afterHourBooking")}
                control={control}
                type={type}
                isLoading={isLoading}
                error={
                  errors?.afterHourBooking &&
                  t((errors.afterHourBooking as any)?.message)
                }
              />
              <SBCheckBox
                name="onlineBooking"
                label={t("onlineBooking")}
                control={control}
                type={type}
                isLoading={isLoading}
                error={
                  errors?.onlineBooking &&
                  t((errors.onlineBooking as any)?.message)
                }
              />
              <SBCheckBox
                name="seasonBeachChair"
                label={t("seasonBeachChair")}
                control={control}
                type={type}
                isLoading={isLoading}
                error={
                  errors?.seasonBeachChair &&
                  t((errors.seasonBeachChair as any)?.message)
                }
              />
            </SBCheckboxGrid>
          </SBColumnGridWrapper>
        </SBFormGridWrapper>
        <SBButtonActionGrid
          editOnClick={() => history.push(`/beach_chairs/${id}/edit`)}
          deleteOnClick={() => {
            setDialog(true);
          }}
          resetOnClick={() => {
            onReset();
          }}
          watchOnClick={() => history.push(`/beach_chairs/${id}/show`)}
          type={type}
          isLoading={isLoading}
        />
      </form>
      {dialog && (
        <DeleteDialog
          namespace="beachChairs"
          open={dialog}
          selectedValue={+id}
          onClose={() => {
            setDialog(false);
          }}
          onAccept={() => {
            setDialog(false);
            if (id) {
              handleDeleteAfterModalConfirm(+id);
            }
          }}
        />
      )}
    </SBMainComponentGridWrapper>
  );
};

export default BeachChair;
