import React, { useCallback, useEffect, useMemo, useState } from "react";
import BeachChairListHeader from "./beachChairListHeader/beachChairListHeader";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../dialog/DeleteDialog";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useGetBeachChairs } from "./hooks/useGetBeachChairs";
import Status from "../status/status";
import { useGetBeachChairsFilters } from "./hooks/useGetBeachChairsFilters";
import { useHistory } from "react-router";
import { useDeleteBeachChair } from "./hooks/useDeleteBeachChair";
import SBTopToolbarCustomActions from "../SBComponents/SBLayout/SBTopToolbarCustomActions";
import SBMainListWrapper from "../SBComponents/SBLayout/SBMainListWrapper";
import { Check, ContentCopy, SaveAs } from "@mui/icons-material";
import { useDuplicateBeachChairMutation } from "./hooks/useDuplicateBeachChairMutation";
import { useSBTablePersists } from "../../utils/customHooks/useSBTablePersists";
import { dateToProperPrintDateFormat } from "../../utils/conversions/dataConversion";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { deDE } from "@mui/x-date-pickers/locales";
import { EColor } from "../../models/beachChairs/EColors";
import SBLoadingButton from "../SBComponents/SBForms/SBLoadingButton";
import { MRT_RowSelectionState } from "material-react-table";
import { useBulkIdsStore } from "./beachChair/hooks/useBulkIdsStore";
import SBTableBulk from "../SBTable/SBTableBulk";
import dayjs from "dayjs";
import { SBColor } from "../SBComponents/styles/SBStyles";
import SBRedirectLabel from "../SBComponents/SBLayout/SBRedirectLabel";

const createNewPublicNumber = (oldPublicNumber: string) => {
  if (oldPublicNumber.includes("(") && oldPublicNumber.includes(")")) {
    const substrings = oldPublicNumber.match(/\((.*?)\)/g);
    if (substrings) {
      let number = +substrings[0].replace("(", "").replace(")", "");
      number += 1;
      const newPublicNumber = oldPublicNumber.split("(")[0];
      return `${newPublicNumber}(${number})`;
    }
    return `${oldPublicNumber}`;
  }
  return `${oldPublicNumber}(1)`;
};

const BeachChairsList = () => {
  const { t } = useTranslation(["common"]);
  const { changeIds } = useBulkIdsStore();
  const history = useHistory();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | undefined>();
  const duplicate = useDuplicateBeachChairMutation();
  const {
    columnFilters,
    pagination,
    sorting,
    setColumnFilters,
    setPagination,
    setSorting,
  } = useSBTablePersists("beach_chairs");
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const handleOnEditClick = useCallback(
    (id: number) => {
      history.push(`/beach_chairs/${id}/edit`);
    },
    [history]
  );

  const handleOnShowClick = useCallback(
    (id: number) => {
      history.push(`/beach_chairs/${id}/show`);
    },
    [history]
  );

  const handleOnDeleteClick = useCallback(
    (id) => {
      setDeleteId(id);
      setDeleteDialogOpen(true);
    },
    [setDeleteDialogOpen]
  );

  const deleteBeachChair = useDeleteBeachChair();
  const handleDeleteAfterModalConfirm = useCallback(
    (id: number) => {
      deleteBeachChair
        .mutateAsync({ id: id, isList: true })
        .finally(() => {
          setDeleteDialogOpen(false);
        })
        .catch(() => {
          setDeleteDialogOpen(false);
        });
    },
    [deleteBeachChair]
  );

  const { data: filters } = useGetBeachChairsFilters();
  const { data, isError, isFetching, isLoading, refetch } = useGetBeachChairs(
    columnFilters,
    globalFilter,
    pagination,
    sorting
  );

  useEffect(() => {
    setRowSelection({});
  }, [pagination]);

  const columns = useMemo<any>(() => {
    return [
      {
        accessorKey: "id",
        header: `${t("beachChairsDataTable.id")}`,
        maxSize: 50,
        minSize: 20,
      },
      {
        accessorKey: "publicNumber",
        header: `${t("beachChairsDataTable.publicNumber")}`,
        maxSize: 50,
        minSize: 20,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              if (row?.row?.original?.id) {
                handleOnShowClick(row?.row?.original?.id);
              }
            }}
          >
            <SBRedirectLabel label={row.renderedCellValue}/>
          </Box>
        ),
      },
      {
        accessorKey: "model",
        header: `${t("beachChairsDataTable.model")}`,
        filterVariant: "select",
        filterSelectOptions: filters?.models
          .map((model) => {
            return { text: `${t(`models.${model}`)}`, value: model };
          })
          .sort((a, b) => a.text?.localeCompare(b.text)),
        maxSize: 50,
        minSize: 20,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>
              {row.renderedCellValue?.props
                ? t(`models.${row.renderedCellValue.props["aria-label"]}`)
                : t(`models.${row.renderedCellValue}`)}
            </Typography>
          </Box>
        ),
      },
      {
        accessorKey: "sectionId",
        accessorFn: (originalRow: any) => originalRow.section?.value,
        header: `${t("beachChairsDataTable.sectionId")}`,
        filterVariant: "select",
        filterSelectOptions: filters?.sections
          .map((section) => {
            return { text: section.value, value: section.id };
          })
          .sort((a, b) => a.text?.localeCompare(b.text)),
        maxSize: 50,
        minSize: 20,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "rowId",
        accessorFn: (originalRow: any) => originalRow.row?.value,
        header: `${t("beachChairsDataTable.rowId")}`,
        filterVariant: "select",
        filterSelectOptions: filters?.sections
          .flatMap((section) =>
            section.rows.map((row) => {
              return {
                text: `${row.value} (${section.value}) `,
                value: row.id,
              };
            })
          )
          .sort((a, b) => a.text?.localeCompare(b.text)),
        maxSize: 50,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "lockId",
        accessorFn: (originalRow: any) => originalRow.lock?.value,
        sortDescFirst: true,
        header: `${t("beachChairsDataTable.lock")}`,
        filterVariant: "select",
        filterSelectOptions: filters?.locks
          .map((lock) => {
            return { text: lock.value, value: lock.id };
          })
          .sort((a, b) => a.text?.localeCompare(b.text)),
        maxSize: 50,
        minSize: 20,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography>{row.renderedCellValue}</Typography>
          </Box>
        ),
      },
      {
        accessorKey: "status",
        header: `${t("status")}`,
        maxSize: 50,
        minSize: 20,
        filterVariant: "select",
        filterSelectOptions: filters?.statuses.map((status: any) => {
          return { text: `${t(`filters.${status}`)}`, value: status };
        }),
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {row.renderedCellValue?.props ? (
              <Status
                value={`${t(
                  `states.${row.renderedCellValue.props["aria-label"]}`
                )}`}
              />
            ) : (
              <Status value={`${t(`states.${row.renderedCellValue}`)}`} />
            )}
          </Box>
        ),
      },
      {
        accessorKey: "buyingDate",
        header: `${t("buyingDate")}`,
        accessorFn: (originalRow: any) =>
          dateToProperPrintDateFormat(originalRow.buyingDate),
        maxSize: 40,
        minSize: 10,
        filterVariant: "date-range",
        Filter: ({ column, rangeFilterIndex }: any) => (
          <LocalizationProvider
            adapterLocale="de"
            dateAdapter={AdapterDayjs}
            localeText={
              deDE.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue((oldValues: any) => {
                  const newValues = oldValues ?? ["", ""];
                  newValues[rangeFilterIndex] =
                    rangeFilterIndex === 1
                      ? dayjs(newValue).endOf("day")
                      : newValue;
                  return newValues;
                });
              }}
              value={dayjs(column.getFilterValue()?.[rangeFilterIndex] || null)}
              sx={{
                width: "150px",
                "& .MuiOutlinedInput-root ": {
                  fontSize: "11px",
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "attributes.color",
        accessorFn: (originalRow: any) => originalRow?.attributes?.color,
        sortDescFirst: true,
        header: `${t("color")}`,
        maxSize: 50,
        minSize: 20,
        filterVariant: "select",
        filterSelectOptions: Object.keys(EColor).flatMap((option) => {
          return { text: `${t(`colors.${option}`)}`, value: option };
        }),
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && row.renderedCellValue !== "0" && (
              <Typography>{`${t(
                `colors.${row.renderedCellValue}`
              )}`}</Typography>
            )}
          </Box>
        ),
      },
      {
        accessorKey: "internalNote",
        accessorFn: (originalRow: any) => originalRow?.internalNote,
        header: `${t("internalNote")}`,
        filterVariant: "checkbox",
        sortDescFirst: true,
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue ? <Check /> : null}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "images",
        accessorFn: (originalRow: any) => originalRow?.images,
        header: `${t("destinations.images")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && row.renderedCellValue.length > 0 && (
              <Check />
            )}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "stopSaleDates",
        accessorFn: (originalRow: any) => originalRow?.stopSaleDates,
        header: `${t("stopSaleLabel")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && row.renderedCellValue.length > 0 && (
              <Check />
            )}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "afterHourBooking",
        header: `${t("afterHourBookingLabel")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && <Check />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "seasonBeachChair",
        header: `${t("seasonBeachChair")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && <Check />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "onlineBooking",
        header: `${t("onlineBooking")}`,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && <Check />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
      {
        accessorKey: "geoJson",
        header: `${t("geoJson")}`,
        accessorFn: (originalRow: any) => originalRow?.geoJson?.coordinates,
        filterVariant: "checkbox",
        Cell: (row: any) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {row.renderedCellValue && row.renderedCellValue[0] !== 0 && row.renderedCellValue[0] !== 0 && <Check />}
          </Box>
        ),
        maxSize: 20,
        minSize: 10,
      },
    ];
  }, [t, filters]);
  const renderTopToolbarCustomActions = React.useCallback(
    (table?) => (
      <Box sx={{ display: "grid", gridTemplateColumns: "200px auto" }}>
        <SBTopToolbarCustomActions
          isLoading={isFetching}
          label={t("allBeachChairs")}
          onClick={() => {
            refetch();
          }}
        />
        {Object.keys(rowSelection).length > 0 && (
          <SBLoadingButton
            icon={<SaveAs />}
            type="orange"
            label={t("bulk", { selected: Object.keys(rowSelection).length })}
            onClick={() => {
              const selectedRows = table?.table?.getSelectedRowModel()?.rows;
              if (selectedRows) {
                const ids = selectedRows.map((row) => `${row?.original?.id}`);
                changeIds(ids);
                history.push(`/beach_chairs/bulk`);
              }
            }}
          />
        )}
      </Box>
    ),
    [refetch, t, rowSelection, isFetching]
  );

  const renderRowActions = React.useCallback(
    (cell: any) => {
      const beachChairId = data?.items[cell.row.index].id;
      return (
        <Box sx={{ float: "right" }}>
          <Tooltip title={t("eye")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (beachChairId) {
                  handleOnShowClick(beachChairId);
                }
              }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("pen")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (beachChairId) {
                  handleOnEditClick(beachChairId);
                }
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("duplicate")}>
            <IconButton
              sx={{ color: "#000000" }}
              disabled={false}
              onClick={() => {
                if (beachChairId) {
                  const {
                    id,
                    publicNumber,
                    vendor,
                    section,
                    row,
                    location,
                    lock,
                    ...createBeachChairBodyDto
                  } = data?.items[cell.row.index];

                  const newPublicNumber = createNewPublicNumber(publicNumber);

                  const changes = {
                    publicNumber: newPublicNumber,
                    vendorId: vendor.id,
                    sectionId: section.id,
                    rowId: row.id,
                  };

                  const duplicateBeachChair = {
                    ...createBeachChairBodyDto,
                    ...changes,
                  };

                  duplicate.mutate({
                    createBeachChairBodyDto: duplicateBeachChair,
                  });
                }
              }}
            >
              <ContentCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("bin")}>
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                if (beachChairId) {
                  handleOnDeleteClick(beachChairId);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>
      );
    },
    // eslint-disable-next-line
    [data]
  );

  return (
    <SBMainListWrapper>
      <BeachChairListHeader />
      <SBTableBulk
        columnVisibilityStoreName="BeachChairListColumnVisibilityStore"
        data={data}
        columns={columns}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        pagination={pagination}
        setPagination={setPagination}
        sorting={sorting}
        setSorting={setSorting}
        renderRowActions={renderRowActions}
        renderTopToolbarCustomActions={renderTopToolbarCustomActions}
        enableRowSelection={true}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
      />
      {deleteDialogOpen ? (
        <DeleteDialog
          namespace="beachChairs"
          open={deleteDialogOpen}
          selectedValue={deleteId}
          onClose={() => {
            setDeleteDialogOpen(false);
          }}
          onAccept={() => {
            setDeleteDialogOpen(false);
            if (deleteId) {
              handleDeleteAfterModalConfirm(+deleteId);
            }
          }}
        />
      ) : null}
    </SBMainListWrapper>
  );
};

export default BeachChairsList;
