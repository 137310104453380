import moment from "moment";
import { createSelector } from "reselect";
import {
  allAvailabileBeachChairs,
  getAllAvailabilities,
  getAllChangeBeachChairRows,
  getAllRows,
  getAvailabileBeachChairById,
  getAvailability,
} from "../reducers/availabilityReducer";

import { AppState } from "../reducers/rootReducer";

const getPending = (state: AppState) => state.availabilities.pending;

const getState = (state: AppState) => state;

const getAvailabilitiesCurrentDateString = (state: AppState) =>
  state.availabilities.currentDate;

const getAvailabilitiesSectionStartDateString = (state: AppState) =>
  state.availabilities.availabilitySectionStartDate;

const getAvailabilitiesSectionEndDateString = (state: AppState) =>
  state.availabilities.availabilitySectionEndDate;

const getAvailabilitiesStartDateString = (state: AppState) =>
  state.availabilities.startDate;

const getAvailabilitiesEndDateString = (state: AppState) =>
  state.availabilities.endDate;

const getAvailabilitiesStartTimeString = (state: AppState) =>
  state.availabilities.startTime;

const getAvailabilitiesEndTimeString = (state: AppState) =>
  state.availabilities.endTime;

const getError = (state: AppState) => state.availabilities.error;

const getAvailableBeachChairs = (state: AppState) => state.availabilities.rows;

const getIsGrid = (state: AppState) => state.availabilities.isGrid;

const getIsPosition = (state: AppState) => state.availabilities.isPosition;

const getAvabilityLayout = (state: AppState) =>
  state.availabilities.avabilityLayout;

const getGridData = (state: AppState) => state.availabilities.grid;

const getGridTime = (state: AppState) => state.availabilities.gridTime;

export const getAvailabilitiesCurrentDate = createSelector(
  getAvailabilitiesCurrentDateString,
  (date) => date
);

export const getAvailabilitiesStartDate = createSelector(
  getAvailabilitiesStartDateString,
  (date) => date
);

export const getAvailabilitiesEndDate = createSelector(
  getAvailabilitiesEndDateString,
  (date) => date
);

export const getAvailabilitiesSectionStartDate = createSelector(
  getAvailabilitiesSectionStartDateString,
  (date) => date
);

export const getAvailabilitiesSectionEndDate = createSelector(
  getAvailabilitiesSectionEndDateString,
  (date) => date
);

export const getAvailabilitiesStartTime = createSelector(
  getAvailabilitiesStartTimeString,
  (time) => time
);

export const getAvailabilitiesEndTime = createSelector(
  getAvailabilitiesEndTimeString,
  (time) => time
);

export const getAvailabilitiesCurrentDateSelector = createSelector(
  getAvailabilitiesCurrentDateString,
  (date) => new Date(date)
);

export const getAvailabilitiesSelector = createSelector(
  getAllAvailabilities,
  (availabilities) => availabilities
);

export const getAvailabilitiesByIdSelector = (id: number) =>
  createSelector(getState, (state) => getAvailability(state, id));

export const getAvailableBeachChairsSelector = createSelector(
  getAvailableBeachChairs,
  (availableBeachChairs) => availableBeachChairs
);

export const getAvailableBeachChairRow = createSelector(getState, (state) =>
  getAllRows(state)
);

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getBeachChairFilters = createSelector(
  getState,
  (state) => state.availabilities.beachchairFilters
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getIsGridSelector = createSelector(getIsGrid, (isGrid) => isGrid);

export const getIsPositionSelector = createSelector(
  getIsPosition,
  (isPosition) => isPosition
);

export const getAvabilityLayoutSelector = createSelector(
  getAvabilityLayout,
  (avabilityLayout) => avabilityLayout
);

export const getGridDataSelector = createSelector(getGridData, (grid) => {
  let i = 0;
  return {
    groups: grid.groups.map((group) => {
      return {
        id: +group.id.toFixed(2),
        title: group.title,
      };
    }),
    items: grid.items.map((item) => {
      i += 1; // for uniqe id
      return {
        id: +`${i}00${item.id}`,
        group: item.group,
        title: item.title,
        start_time: moment(item.start_time),
        end_time: moment(item.end_time),
        type: item.type,
      };
    }),
  };
});

export const getGridTimeSelector = createSelector(
  getGridTime,
  (gridTime) => gridTime
);

export const getChangeBeachChairsSelector = createSelector(
  getAllChangeBeachChairRows,
  (beachChairs) => beachChairs
);

export const getAllAvailabileBeachChairsSelector = createSelector(
  allAvailabileBeachChairs,
  (beachChairs) => beachChairs
);

export const getAvailabileBeachChairByIdSelector = (id: number) =>
  createSelector(getState, (state) => getAvailabileBeachChairById(state, id));
