import { createTheme } from "@mui/material/styles";
import { deDE as coreDeDE } from "@mui/material/locale";
import { deDE } from "@mui/x-date-pickers/locales";
export const theme = createTheme(
  {
    typography: {
      fontFamily: `"NunitoMedium", monospace`,
    },
    palette: {
      primary: {
        main: "#233f91",
        light: "#4d6edd",
        dark: "#001a5e",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ed6b17",
        light: "#ffa04c",
        dark: "#b23c00",
        contrastText: "#ffffff",
      },
    },
  },
  deDE,
  coreDeDE
);
