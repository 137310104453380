import { UseMutateFunction, useMutation } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingResponseDto,
  CreateBeachChairBookingBodyDto,
} from "../../../api-client/generated";
import { featuresApi } from "../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../bookingsList/store/useBookingZustand";

interface Variables extends CreateBeachChairBookingBodyDto {
  bookingId: number;
  isExtended: boolean;
}

interface UseCreateStripeCheckoutSession {
  addBeachChairToBooking: UseMutateFunction<
    AxiosResponse<BookingResponseDto>,
    AxiosError,
    Variables
  >;
  isLoading: boolean;
}

export const useAddBeachChairToBooking = (): UseCreateStripeCheckoutSession => {
  const { setBookingInProgress } = useBookingInProgressStore();
  const { mutate: addBeachChairToBooking, isLoading } = useMutation<
    AxiosResponse<BookingResponseDto>,
    AxiosError,
    Variables
  >(
    ["addBeachChairToBooking"],
    ({ bookingId, isExtended, ...beachChairDetails }: Variables) =>
      featuresApi.bookingDetailsControllerAddToBooking({
        id: bookingId,
        createBeachChairBookingBodyDto: beachChairDetails,
      }),
    {
      onSuccess: (data, variables) => {
        if (!variables.isExtended) {
          setBookingInProgress(data.data);
        }
      },
    }
  );

  return {
    addBeachChairToBooking,
    isLoading,
  };
};
