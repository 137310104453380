import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import { customStyle } from "../../../../utils/customStyles/customStyles";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { useRemoveBeachChairBooking } from "../../../../utils/customHooks/useRemoveBeachChairBooking";
import {
  errorText,
  inputRoot,
  instantBookingsCardControls,
} from "../../../../utils/customStyles/globalStyles";

export interface ISelectedBookingBeachChairProps {
  id: number;
}

const RemoveBeachChairBooking = ({ id }: ISelectedBookingBeachChairProps) => {
  const { t } = useTranslation(["common"]);
  const { beachChairBookings, errors, onSubmit, control } =
    useRemoveBeachChairBooking(id);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Box
        sx={{
          marginTop: "20%",
          marginLeft: "20px",
          marginRight: "20px",
          height: "100%",
          display: "grid",
          gridTemplateRows: "50px auto auto auto",
        }}
        border={0}
      >
        <Typography
          variant="h4"
          sx={{
            gridRow: 1,
          }}
        >
          {t(`removeBeachChairBookingTitle`)}
        </Typography>
        <Typography
          sx={{
            gridRow: 2,
          }}
        >
          {t(`removeBeachChairBookingTitleSecond`)}
        </Typography>
        <FormControl error={!!errors.id}>
          <Controller
            control={control}
            name="id"
            render={({ field }) => (
              <Autocomplete
                options={beachChairBookings}
                getOptionLabel={(option) => `${option.label}`}
                onChange={(_, data) => field.onChange(data?.value || 0)}
                renderInput={(params) => (
                  <TextField
                    sx={{ ...inputRoot, ...instantBookingsCardControls }}
                    {...params}
                    label={t(`beachChair`)}
                    placeholder={t("instantBooking.pleaseChoose")}
                    variant="standard"
                  />
                )}
              />
            )}
          />
          <FormHelperText sx={{ ...errorText }}>
            {errors.id && (errors.id?.message as any)}
          </FormHelperText>
        </FormControl>
        <Button
          type="submit"
          variant="contained"
          sx={{
            gridRow: 5,
            height: "35px",
            fontSize: "12px",
            color: "white",
            backgroundColor: customStyle.mainColor,
          }}
          endIcon={<ChevronRightIcon />}
          data-testid="update-button"
        >
          {t("removeBeachChairBooking")}
        </Button>
      </Box>
    </form>
  );
};

export default RemoveBeachChairBooking;
