import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import {
  SBEndAdornment,
  SBFormControlStyle,
  SBLabelStyle,
  SBTextFieldGridStyle,
  SBTextFieldIconStyle,
  SBTextFieldStyle,
} from "../styles/SBStyles";
import SBCircularProgress from "./SBCircularProgress";
import SBFormHelperText, { SBFormHelperTextPrimary } from "./SBFormHelperText";

export interface SBTextFieldPrimary {
  name: string;
  label: React.ReactNode;
  isLoading?: boolean;
  control?: Control<FieldValues, any>;
  type?: string;
  icon?: React.ReactNode;
  endAdornmentSymbol?: string;
  defaultValue?: string | number;
}

export type SBTextFieldType = SBTextFieldPrimary & SBFormHelperTextPrimary;

const SBNumberField: React.FC<SBTextFieldType> = ({
  type,
  icon,
  label,
  isLoading,
  name,
  control,
  endAdornmentSymbol,
  defaultValue,
  error,
}) => {
  const isType = type ?? "edit";
  const isDisabled = isType === "show";
  return (
    <Box sx={{ ...SBTextFieldGridStyle }}>
      <Box sx={{ ...SBTextFieldIconStyle }}>{icon}</Box>
      <Box>
        <InputLabel sx={{ ...SBLabelStyle }}>{label}</InputLabel>
        <FormControl fullWidth sx={{ ...SBFormControlStyle }}>
          {isLoading ? (
            <SBCircularProgress />
          ) : (
            <Controller
              control={control}
              name={name}
              defaultValue={defaultValue}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  variant="outlined"
                  disabled={isDisabled}
                  label=""
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography sx={{ ...SBEndAdornment }}>
                          {endAdornmentSymbol}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ ...SBTextFieldStyle }}
                  type="number"
                />
              )}
            />
          )}
          <SBFormHelperText error={error} />
        </FormControl>
      </Box>
    </Box>
  );
};

export default SBNumberField;
