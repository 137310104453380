import { createSelector } from "reselect";
import { AppState } from "../reducers/rootReducer";
import { getLocations, getLocationById } from "../reducers/vendorsReducer";

const getState = (state: AppState) => state;

const getPending = (state: AppState) => state.vendors.pending;

const getError = (state: AppState) => state.vendors.error;

const getVendor = (state: AppState) => state.vendors.vendor;

export const getPendingSelector = createSelector(
  getPending,
  (pending) => pending
);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getVendorSelector = createSelector(getVendor, (vendor) => vendor);

export const getLocationSelector = (locationId?: number) =>
  createSelector(getState, (state) =>
    locationId ? getLocationById(state, locationId) : undefined
  );

export const getLocationsSelector = createSelector(
  getLocations,
  (locations) => locations
);
