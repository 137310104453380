import {
  UseMutateFunction,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import {
  BookingDetailsResponseDto as Response,
  FeaturesApiBookingDetailsControllerConfirmBookingRequest as Payload,
} from "../../../../api-client/generated";
import { featuresApi } from "../../../../api/apiTanStack";
import { useBookingInProgressStore } from "../../store/useBookingZustand";

interface UsePayForBooking {
  confirmBooking: UseMutateFunction<
    AxiosResponse<Response>,
    AxiosError,
    Payload
  >;
  isLoading: boolean;
}

export const useConfirmBooking = (): UsePayForBooking => {
  const queryClient = useQueryClient();
  const { clearBookingInProgress } = useBookingInProgressStore();
  const { mutate: confirmBooking, isLoading } = useMutation<
    AxiosResponse<Response>,
    AxiosError,
    Payload
  >({
    mutationFn: (variables: Payload) =>
      featuresApi.bookingDetailsControllerConfirmBooking(variables),
    onSuccess: () => {
      clearBookingInProgress();
      queryClient.refetchQueries(["bookingList"]);
    },
  });

  return {
    confirmBooking,
    isLoading,
  };
};
