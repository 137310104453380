import React from "react";
import { useCreateBookingAddExtra } from "../../components/bookingsList/booking";
import { useAddExtrasToBooking } from "../../components/extras/hooks";
import { useCallback, useEffect, useMemo } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useBookingInProgressStore } from "../../components/bookingsList/store/useBookingZustand";
import { useGetExtra } from "../../components/extras/hooks/useGetExtra";

export interface ISelectedExtras {
  id: number;
  name: string;
  type: string;
  amount: number;
  price: number;
}

export function useSelectedExtraForm(id: number) {
  const { data: extra, isLoading } = useGetExtra(id);
  const addExtrasToBooking = useAddExtrasToBooking();
  const createBooking = useCreateBookingAddExtra();

  const { bookingInProgress } = useBookingInProgressStore();
  const globalBookingId = React.useMemo(
    () => bookingInProgress?.id,
    [bookingInProgress]
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        id: yup.number(),
        name: yup.string(),
        type: yup.string(),
        amount: yup.number(),
        price: yup.number(),
      }),
    []
  );

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setValue("id", extra ? extra.id : id);
    setValue("name", extra ? extra.name : "");
    setValue("type", extra ? extra.type : "");
    setValue("price", extra ? extra.price : 0);
    setValue("amount", 1);
  }, [id, extra, setValue]);

  const onSubmit = useCallback(
    async ({ id: extraId, amount }) => {
      if (globalBookingId) {
        addExtrasToBooking.mutateAsync({
          id: Number(globalBookingId),
          amount,
          extraId,
          isContinue: false,
        });
      } else {
        createBooking.mutateAsync({ isContinue: false, amount, extraId });
      }
    },
    [globalBookingId, addExtrasToBooking, createBooking]
  );

  return {
    isLoading,
    control,
    setValue,
    getValues,
    errors,
    onSubmit: handleSubmit(onSubmit as any),
  };
}
