import {
  deleteBeachChair,
  getBeachChair,
  getCalendarBeachChair,
  getFiltersBeachChairs,
  postNewBeachChair,
  putBeachChair,
} from "./../../api/beachChairApi";
import { all, call, put, select, takeLatest } from "typed-redux-saga";
import { getBeachChairs } from "../../api/beachChairApi";
import history from "../../utils/history/history";
import { IGeoPoint } from "../../models/common/IGeoPoint";
import { Action } from "@reduxjs/toolkit";
import {
  deleteBeachChairFailure,
  deleteBeachChairRequest,
  deleteBeachChairSuccess,
  fetchBeachChairFailure,
  fetchBeachChairRequest,
  fetchBeachChairsFailure,
  fetchBeachChairsRequest,
  fetchBeachChairsSuccess,
  fetchBeachChairSuccess,
  filterBeachChairsFailure,
  filterBeachChairsRequest,
  filterBeachChairsSuccess,
  filterCancelBeachChairsSuccess,
  getBeachChairCalendarFailure,
  getBeachChairCalendarRequest,
  getBeachChairCalendarSuccess,
  getFiltersBeachChairFailure,
  getFiltersBeachChairRequest,
  getFiltersBeachChairSuccess,
  postBeachChairFailure,
  postBeachChairRequest,
  postBeachChairSuccess,
  postNewBeachChairFailure,
  postNewBeachChairRequest,
  postNewBeachChairSuccess,
  putBeachChairFailure,
  putBeachChairRequest,
  putBeachChairSuccess,
  routerRedirectBeachChair,
  routerRedirectBeachChairPage,
} from "../reducers/beachChairsReducer";
import { filterDataToString } from "../../utils/conversions/filterDataToString";
import { toast } from "react-toastify";
import { dataTableUpdateBeachChairsFilters } from "../reducers/dataTableColumnsReducer";
import { getBeachChairCalendarMonthSelector } from "../selectors/beachChairsSelectors";
import {
  IBeachChairAvability,
  IBeachChairCalendarDay,
} from "../../models/beachChairs/IBeachChairCalendarDay";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  differenceInDays,
} from "date-fns";
import { EAvailabilityTime } from "../../models/availability/EAvailabilityTime";
import { getLocations } from "../../api/vendorApi";
import i18next from "i18next";

function* fetchBeachChairsSaga(action: Action) {
  try {
    if (fetchBeachChairsRequest.match(action)) {
      const { page, itemsPerPage, filterData, order, field } = action.payload;
      const filterString: string = filterDataToString(filterData || []);
      const { data } = yield* call(
        getBeachChairs,
        itemsPerPage,
        page,
        filterString,
        field,
        order
      );
      yield* put(
        fetchBeachChairsSuccess({
          beachChairs: data.items,
          count: data.count,
          itemsPerPage: itemsPerPage,
          page: page,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchBeachChairsFailure({
        error: e.message,
      })
    );
  }
}

function* fetchBeachChairSaga(action: Action) {
  try {
    if (fetchBeachChairRequest.match(action)) {
      const { beachChairId } = action.payload;
      const { data } = yield* call(getBeachChair, beachChairId);
      yield* put(getFiltersBeachChairRequest({}));
      yield* put(
        fetchBeachChairSuccess({
          beachChair: data,
        })
      );
    }
  } catch (e: any) {
    yield* put(
      fetchBeachChairFailure({
        error: e.message,
      })
    );
  }
}

function* filterCancelSaga() {
  try {
    yield put(filterCancelBeachChairsSuccess({}));
  } catch (e: any) {
    yield* put(
      filterBeachChairsFailure({
        error: e.message,
      })
    );
  }
}

function* filterBeachChairsSaga(action: Action) {
  try {
    if (filterBeachChairsRequest.match(action)) {
      const { filterData, itemsPerPage, page } = action.payload;
      const filterString: string = filterDataToString(filterData);
      if (filterString.length > 0) {
        const { data } = yield* call(
          getBeachChairs,
          itemsPerPage,
          page,
          filterString
        );
        yield put(
          filterBeachChairsSuccess({
            beachChairs: data.items,
            count: data.count,
            itemsPerPage: itemsPerPage,
            page: page,
            filterPhrase: filterString,
          })
        );
      }
    } else {
      yield* call(filterCancelSaga);
    }
  } catch (e: any) {
    yield* put(
      filterBeachChairsFailure({
        error: e.message,
      })
    );
  }
}

function* putBeachChairSaga(action: Action) {
  try {
    if (putBeachChairRequest.match(action)) {
      const { formData, beachChairId } = action.payload;
      const coordinates = formData.geoJson
        ? formData.geoJson.split(" ").map((value) => +value)
        : [0, 0];
      const geoPoint: IGeoPoint = {
        type: "Point",
        coordinates: coordinates,
      };
      const newBeachChair = {
        model: formData.model,
        sectionId: formData.sectionId,
        rowId: formData.rowId === 0 ? null : formData.rowId,
        lockId: formData.lockId === 0 ? null : formData.lockId,
        publicNumber: formData.publicNumber,
        geoJson: geoPoint,
        description: formData.description,
        internalNote: formData.internalNote,
        buyingDate: formData.buyingDate,
        status: formData.status,
        afterHourBooking: formData.afterHourBooking,
        onlineBooking: formData.onlineBooking,
        seasonBeachChair: formData.seasonBeachChair,
        images: formData.images,
        stopSaleDates: formData.stopSaleDates,
        attributes: formData.attributes,
        details: formData.details,
      };
      const { data } = yield* call(putBeachChair, beachChairId, newBeachChair);
      yield* put(routerRedirectBeachChair({ id: beachChairId, type: "show" }));
      yield* put(putBeachChairSuccess({ beachChair: data }));
      toast.success(`${i18next.t(`toastMessages.PutBeachChairSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      putBeachChairFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutBeachChairFailure`)}`);
  }
}

function* postNewBeachChairSaga(action: Action) {
  try {
    if (postNewBeachChairRequest.match(action)) {
      const { formData } = action.payload;
      const coordinates = formData.geoJson
        ? formData.geoJson.split(" ").map((value) => +value)
        : [0, 0];
      const geoPoint: IGeoPoint = {
        type: "Point",
        coordinates: coordinates,
      };
      const newBeachChair = {
        vendorId: 1,
        model: formData.model,
        sectionId: formData.sectionId,
        rowId: formData.rowId === 0 ? null : formData.rowId,
        lockId: formData.lockId === 0 ? null : formData.lockId,
        publicNumber: formData.publicNumber,
        geoJson: geoPoint,
        description: formData.description,
        internalNote: formData.internalNote,
        buyingDate: formData.buyingDate,
        status: formData.status,
        afterHourBooking: formData.afterHourBooking,
        onlineBooking: formData.onlineBooking,
        images: formData.images,
        stopSaleDates: formData.stopSaleDates,
        attributes: formData.attributes,
        details: formData.details,
      };
      const { data } = yield* call(postNewBeachChair, newBeachChair);
      yield* put(routerRedirectBeachChair({}));
      yield* put(postNewBeachChairSuccess({ beachChair: data }));
      toast.success(`${i18next.t(`toastMessages.PutBeachChairSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      postNewBeachChairFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.PutBeachChairFailure`)}`);
  }
}

function* postBeachChairSaga(action: Action) {
  try {
    if (postBeachChairRequest.match(action)) {
      const { formData } = action.payload;
      const coordinates = formData.geoJson
        ? formData.geoJson.split(" ").map((value) => +value)
        : [0, 0];
      const geoPoint: IGeoPoint = {
        type: "Point",
        coordinates: coordinates,
      };
      const newBeachChair = {
        model: formData.model,
        sectionId: formData.sectionId,
        rowId: formData.rowId === 0 ? null : formData.rowId,
        lockId: formData.lockId === 0 ? null : formData.lockId,
        publicNumber: formData.publicNumber,
        geoJson: geoPoint,
        description: formData.description,
        internalNote: formData.internalNote,
        buyingDate: formData.buyingDate,
        status: formData.status,
        afterHourBooking: formData.afterHourBooking,
        onlineBooking: formData.onlineBooking,
      };
      const { data } = yield* call(postNewBeachChair, newBeachChair);
      yield* put(postBeachChairSuccess({ beachChair: data }));
    }
  } catch (e: any) {
    yield* put(
      postBeachChairFailure({
        error: e.message,
      })
    );
  }
}

function* getFiltersBeachChairSaga(action: Action) {
  try {
    if (getFiltersBeachChairRequest.match(action)) {
      const { data } = yield* call(getFiltersBeachChairs);
      const allLocations = yield* call(getLocations);
      const filterLocation = allLocations.data.items.map((item) => {
        return { id: item.id, value: item.name };
      });
      data.filter.locks.sort((a, b) => (+a.value > +b.value ? 1 : -1));
      data.filter.locations = filterLocation;
      yield* put(
        getFiltersBeachChairSuccess({
          filters: data,
        })
      );
      yield* put(dataTableUpdateBeachChairsFilters({ filters: data }));
    }
  } catch (e: any) {
    yield* put(
      getFiltersBeachChairFailure({
        error: e.message,
      })
    );
  }
}

function* deleteBeachChairSaga(action: Action) {
  try {
    if (deleteBeachChairRequest.match(action)) {
      const { beachChairId, isList } = action.payload;
      yield* call(deleteBeachChair, beachChairId);
      if (!isList) {
        yield* put(routerRedirectBeachChair({}));
      }
      yield* put(deleteBeachChairSuccess({ beachChairId: beachChairId }));
      toast.success(`${i18next.t(`toastMessages.DeleteBeachChairSuccess`)}`);
    }
  } catch (e: any) {
    yield* put(
      deleteBeachChairFailure({
        error: e.message,
      })
    );
    toast.error(`${i18next.t(`toastMessages.DeleteBeachChairFailure`)}`);
  }
}

function* getBeachChairCalendarSaga(action: Action) {
  try {
    if (getBeachChairCalendarRequest.match(action)) {
      const { id } = action.payload;
      const month = yield* select(getBeachChairCalendarMonthSelector);
      const fromDate = new Date(month);
      let availability: IBeachChairCalendarDay[] = [];
      const initialTime = startOfMonth(fromDate),
        endTime = endOfMonth(fromDate);
      const weekStart = startOfWeek(initialTime, { weekStartsOn: 1 }),
        weekEnd = endOfWeek(endTime, { weekStartsOn: 1 });

      const differenceStart = differenceInDays(initialTime, weekStart);
      const differenceEnd = differenceInDays(weekEnd, endTime);
      const { data } = yield* call(
        getCalendarBeachChair,
        id,
        initialTime.toISOString(),
        endTime.toISOString()
      );
      for (let i = 0; i < differenceStart; i++) {
        availability.push({
          date: "",
          beachChairAvailabilityName: EAvailabilityTime.Free,
        });
      }
      for (let d = initialTime; d <= endTime; d.setDate(d.getDate() + 1)) {
        const actualProcessedDate = format(new Date(d), "yyyy-MM-dd");
        availability.push({
          date: actualProcessedDate,
          beachChairAvailabilityName:
            data.find((available) =>
              available.date.includes(actualProcessedDate)
            )?.beachChairAvailabilityName || EAvailabilityTime.Free,
        });
      }
      for (let i = 0; i < differenceEnd; i++) {
        availability.push({
          date: "",
          beachChairAvailabilityName: EAvailabilityTime.Free,
        });
      }
      const calendar: IBeachChairAvability = {
        key: `${id}-${month}`,
        availability: availability,
      };
      yield* put(getBeachChairCalendarSuccess({ calendar }));
    }
  } catch (e: any) {
    yield* put(
      getBeachChairCalendarFailure({
        error: e.message,
      })
    );
  }
}

function* routerRedirectBeachChairSaga(action: Action) {
  if (routerRedirectBeachChair.match(action)) {
    const { id, type } = action.payload;
    yield* call(
      [history, history.push],
      `/beach_chairs${id ? `/${id}` : ``}${type ? `/${type}` : ``}`
    );
  }
}

function* routerRedirectBeachChairPageSaga(action: Action) {
  if (routerRedirectBeachChairPage.match(action)) {
    const { id, page } = action.payload;
    yield* call(
      [history, history.push],
      `/beach_chairs${id ? `/${id}` : ``}/${page}`
    );
  }
}

function* BeachChairsSaga() {
  yield* all([
    takeLatest(fetchBeachChairsRequest.type, fetchBeachChairsSaga),
    takeLatest(fetchBeachChairRequest.type, fetchBeachChairSaga),
    takeLatest(filterBeachChairsRequest.type, filterBeachChairsSaga),
    takeLatest(putBeachChairRequest.type, putBeachChairSaga),
    takeLatest(postBeachChairRequest.type, postBeachChairSaga),
    takeLatest(routerRedirectBeachChair.type, routerRedirectBeachChairSaga),
    takeLatest(getFiltersBeachChairRequest.type, getFiltersBeachChairSaga),
    takeLatest(postNewBeachChairRequest.type, postNewBeachChairSaga),
    takeLatest(deleteBeachChairRequest.type, deleteBeachChairSaga),
    takeLatest(getBeachChairCalendarRequest.type, getBeachChairCalendarSaga),
    takeLatest(
      routerRedirectBeachChairPage.type,
      routerRedirectBeachChairPageSaga
    ),
  ]);
}

export default BeachChairsSaga;
